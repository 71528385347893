import { makeStyles } from 'tss-react/mui';

export const useSlidesListViewStyles = makeStyles()(() => ({
  title: {
    paddingTop: '1rem',
    fontSize: '2rem',
  },
  formInput: {
    marginTop: '0.25rem',
    padding: '0.5rem',
    fontSize: '14px',
    width: '30%',
    display: 'inline-block',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    marginBottom: '1rem',
  },
  floatingThumbnail: {
    position: 'absolute',
    right: '0px',
    bottom: '-10px',
    zIndex: 5,
    width: '12%',
  },
  floatingPaper: {
    height: '100%',
    width: '100%',
  },
  floatingImage: {
    width: '100%',
    height: '100%',
  },
  compareButton: { marginLeft: '1rem' },
  buttonGrouping: { display: 'inline-block', marginRight: '1rem' },
}));
