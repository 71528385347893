import { histowizTheme } from 'themes/defaultMuiTheme';
import { makeStyles } from 'tss-react/mui';
import { HISTOWIZ_PRIMARY_COLOR } from 'utilities/colors';

export const circularProgressContainerStyles = {
  position: 'fixed',
  zIndex: 1000,
  background: '#00000050',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  top: 0,
  left: 0,
};

export const useProcessedSlideStyles = makeStyles()(() => ({
  slideView: {
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: 'transparent',
  },
}));

export const useSlidesListViewStyles = makeStyles()({
  formInput: {
    marginTop: '0.25rem',
    padding: '0.5rem',
    fontSize: '14px',
    width: '50%',
    display: 'inline-block',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    marginBottom: '1rem',
  },
  exportToExcelButton: {
    float: 'right',
    marginLeft: '1rem',
  },
  compareSlidesBtn: {
    display: 'inline-block',
    marginLeft: '1rem',
    marginBottom: '0.4rem',
  },
  count: { float: 'right', marginTop: '0.5rem' },
  showingSlidesLabel: {
    float: 'right',
    marginTop: '0.5rem',
  },
  viewButton: {
    fontSize: '1.5rem',
    marginRight: '3rem',
    cursor: 'pointer',
  },
  underline: {
    borderBottom: '1px solid black',
  },
  primaryColor: {
    color: HISTOWIZ_PRIMARY_COLOR,
  },
});

export const useExpansionStyles = makeStyles()({
  alternativeHeader: {
    backgroundColor: histowizTheme.palette.secondary.light,
    color: histowizTheme.palette.common.white,
    borderColor: histowizTheme.palette.secondary.light,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    marginTop: '0.2rem',
  },
  noPaddingAccordion: {
    padding: 0,
  },
  defaultMargin: {
    margin: '1rem',
  },
  annotationTableContainer: {
    padding: '0 10rem',
    maxHeight: '30rem',
    overflow: 'auto',
  },
});

export const useDropzoneStyles = makeStyles()(() => ({
  dropZoneContainer: {
    marginTop: '1rem',
    border: '1px',
    borderStyle: 'dashed',
    borderColor: 'black',
  },
  dropZoneLabel: {
    margin: '1rem',
  },
  dropZone: {
    width: '100%',
  },
}));
