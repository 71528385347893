import { makeStyles } from 'tss-react/mui';
import chroma from 'chroma-js';

export const useSlideNavigatorStyles = makeStyles()((theme) => ({
  marginBottomStyle: { marginBottom: '0.5rem' },
  inlineBlockBoldStyle: { display: 'inline-block', fontWeight: 'bold' },
  inlineBlockStyle: { display: 'inline-block', overflowWrap: 'anywhere' },
  search: {
    padding: 0,
    margin: 0,
    background: 'white',

    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      minHeight: '38px',
    },
  },
  paper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: '1rem',
    padding: '2rem 1rem',
  },
  imageWrapper: {
    height: '16rem',
    width: '12rem',
  },
  image: {
    height: '100%',
    objectFit: 'contain',
  },
  textWrapper: {
    width: '7rem',
    marginRight: '1rem',
    marginLeft: '1rem',
    marginTop: '-0.5rem',
  },
  formLabel: {
    fontSize: '1.5rem',
    color: 'black',
    display: 'block',
  },
  slideNameLabel: {
    fontSize: '2rem',
    margin: 0,
    padding: 0,
    color: 'black',
    display: 'block',
  },
  slidesContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  spinner: {
    position: 'fixed',
    zIndex: 1000,
    background: '#00000050',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
  },
  isBrief: {
    padding: '0',
    margin: '0',
  },
}));

export const selectColourStyles = {
  multiValue: (styles) => {
    const color = chroma('blue');
    return {
      ...styles,
      'z-index': 999,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};
