import { makeStyles } from 'tss-react/mui';
import { ACTION_LINK_COLOR } from 'utilities/colors';

export const useTableActionsStyles = makeStyles()((theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    marginBottom: '2rem',
  },
  tableTitle: { marginLeft: '-.5rem' },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  deleteButton: {
    marginTop: theme.spacing(3),
    float: 'right',
  },
  actionsButton: {
    cursor: 'pointer',
  },
  typographyActionStyle: {
    cursor: 'pointer',
    color: ACTION_LINK_COLOR,
    fontSize: 14,
    marginBottom: '0.5rem',
  },
  typographyMarginBottom: { marginBottom: '1rem' },
  shipBackTitleActionStyle: {
    //cursor: "pointer",
    //color: "blue",
    fontSize: 18,
    marginBottom: '1rem',
  },
  shipBackButtonStyle: {
    marginBottom: '1rem',
    display: 'block',
  },
  selectForm: {
    marginBottom: '1rem',
    marginTop: '1rem',
    marginRight: '2rem',
  },
  actionIcon: {
    height: '1.25rem',
    width: '1.25rem',
    marginRight: '0.5rem',
    color: ACTION_LINK_COLOR,
    cursor: 'pointer',
  },
}));
