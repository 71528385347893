import { useState } from 'react';
import { TipsAndUpdates } from '@mui/icons-material';
import { SidebarList } from 'components/Layout/Sidebar/SidebarList';
import { SidebarListItem } from 'components/Layout/Sidebar/SidebarListItem';
import { SuggestFeatureModal } from 'components/Modals/SuggestFeatureModal/SuggestFeatureModal';

export const SuggestFeature = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <SidebarList>
      <SidebarListItem
        Icon={TipsAndUpdates}
        name="Request Feature"
        handler={handleOpen}
        withoutLink
      />
      <SuggestFeatureModal onClose={handleClose} open={open} />
    </SidebarList>
  );
};
