import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import React, { useEffect, useState } from 'react';
import { useSlideMetricsCardStyles } from 'components/TeamOverview/components/ManageDataCard/styles';
import { ManageDataCardDefaultHeader } from 'components/TeamOverview/components/ManageDataCard/ManageDataCardDefaultHeader';
import { ConfirmActionModal } from 'components/TeamOverview/components/ConfirmActionModal/ConfirmActionModal';
import { useSlidesForOrder } from 'components/TeamOverview/hooks';
import { SubmitActionTypes } from 'components/TeamOverview/constants';
import { ManageDataCardOrderPreviewHeader } from 'components/TeamOverview/components/ManageDataCard/ManageDataCardOrderPreviewHeader';
import { TeamOrdersOverview } from 'components/TeamOverview/components/ManageDataCard/TeamOrdersOverview';
import { OrderSlidesOverview } from 'components/TeamOverview/components/ManageDataCard/OrderSlidesOverview';
import { ConfirmDataOnHardDiskModal } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/ConfirmDataOnHardDiskModal';
import { ChangeLog } from 'components/TeamOverview/components/ManageDataCard/ChangeLog';
import {
  CHANGELOG_VIEW,
  DEFAULT_VIEW,
  ORDER_PREVIEW_VIEW,
} from 'components/UserProfile/constants';

export const ManageDataCard = ({
  isOrdersLoading,
  orders,
  team,
  selectedOrdersType,
  setSelectedOrdersType,
  isDemoAccount,
  ordersForHistory,
  viewMode,
  setViewMode,
}) => {
  const { classes } = useSlideMetricsCardStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [
    isConfirmDataOnHardDiskModalOpen,
    setIsConfirmDataOnHardDiskModalOpen,
  ] = useState(false);
  const [openedModalType, setOpenedModalType] = useState(null);
  const [orderForPreview, setOrderForPreview] = useState(null);

  const [activeAction, setActiveAction] = useState(null);

  const { fetchSlidesForOrder, selectedRowsSlides } = useSlidesForOrder();

  const actionHandlers = {
    [SubmitActionTypes.EXPORT_ACTION]: () =>
      setIsConfirmDataOnHardDiskModalOpen(true),
    [SubmitActionTypes.ARCHIVE_ACTION]: () =>
      setOpenedModalType(SubmitActionTypes.ARCHIVE_ACTION),
    [SubmitActionTypes.CONTRIBUTE_ACTION]: () =>
      setOpenedModalType(SubmitActionTypes.CONTRIBUTE_ACTION),
  };

  const handleContinue = () => {
    actionHandlers[activeAction]?.();
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [activeAction, selectedOrdersType]);

  const headers = {
    [DEFAULT_VIEW]: (
      <ManageDataCardDefaultHeader
        setSelectedOrdersType={setSelectedOrdersType}
        selectedOrdersType={selectedOrdersType}
        handleDeselect={() => setSelectedRows([])}
        selectedRowsCount={selectedRows.length}
        isDemoAccount={isDemoAccount}
        activeAction={activeAction}
        setActiveAction={setActiveAction}
        handleContinue={handleContinue}
        setViewMode={setViewMode}
      />
    ),
    [ORDER_PREVIEW_VIEW]: (
      <ManageDataCardOrderPreviewHeader
        handleBack={() => setViewMode(DEFAULT_VIEW)}
        order={orderForPreview}
      />
    ),
    [CHANGELOG_VIEW]: null,
  };

  const content = {
    [DEFAULT_VIEW]: (
      <TeamOrdersOverview
        isOrdersLoading={isOrdersLoading}
        team={team}
        orders={orders}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        fetchSlidesForOrder={fetchSlidesForOrder}
        setOrderForPreview={setOrderForPreview}
        setViewMode={setViewMode}
        activeAction={activeAction}
      />
    ),
    [ORDER_PREVIEW_VIEW]: (
      <OrderSlidesOverview
        order={orderForPreview}
        fetchSlidesForOrder={fetchSlidesForOrder}
        selectedRowsSlides={selectedRowsSlides}
        isSlidesLoading={true}
      />
    ),
    [CHANGELOG_VIEW]: (
      <ChangeLog
        team={team}
        setViewMode={setViewMode}
        ordersForHistory={ordersForHistory}
      />
    ),
  };

  return (
    <Card cardClass={classes.manageDataCard} header={headers[viewMode]}>
      {content[viewMode]}
      <ConfirmActionModal
        actionType={openedModalType}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectedRowsSlides={selectedRowsSlides}
        isOpen={!!openedModalType}
        onClose={() => setOpenedModalType(null)}
        team={team}
      />
      <ConfirmDataOnHardDiskModal
        selectedOrders={selectedRows}
        selectedRowsSlides={selectedRowsSlides}
        isOpen={isConfirmDataOnHardDiskModalOpen}
        onClose={() => setIsConfirmDataOnHardDiskModalOpen(false)}
        team={team}
      />
    </Card>
  );
};
