import { DefaultPathologyMapTypography } from 'components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography';
import { HEADER_TYPOGRAPHY_PROPS } from 'components/PathologyMap/constants/styles';

export const HeaderPathologyMapTypography = ({ children, ...props }) => {
  return (
    <DefaultPathologyMapTypography {...HEADER_TYPOGRAPHY_PROPS} {...props}>
      {children}
    </DefaultPathologyMapTypography>
  );
};
