import { Box, Typography } from '@mui/material';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { Check } from '@mui/icons-material';
import React, { useMemo } from 'react';
import {
  getColorIndicatorStyle,
  getSelectedAIModelCheckIconStyle,
  getSelectedAIModelItemContainerBGColor,
  getSelectedAIModelItemContainerStyle,
  getSelectedAIModelTextStyle,
  useSelectableItemStyles,
} from 'components/AITools/constants/styles';
import { LIGHT_GREY_3 } from 'utilities/colors';

export const SlideAIModelSelectableItem = ({
  name,
  color,
  handleChange,
  isSelected,
  isDisabled,
  isLoading,
  uuid,
  showQuantifications,
}) => {
  const { classes } = useSelectableItemStyles();

  const handleClick = (e) => {
    if (!isDisabled) {
      handleChange(e);
    }
  };

  const quantification = useMemo(() => {
    if (showQuantifications) {
      try {
        // @todo: connect quantification when backend will be ready
        const result = {};

        if (!result) return null;

        return `(${result.percentage}%, ${result.count})`;
      } catch {
        return null;
      }
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showQuantifications, uuid]);

  const formattedColor = isDisabled && color ? LIGHT_GREY_3 : color;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={1}
      p={1}
      bgcolor={getSelectedAIModelItemContainerBGColor(
        isSelected,
        isDisabled,
        isLoading,
      )}
      style={getSelectedAIModelItemContainerStyle(isDisabled, isLoading)}
      className={classes.wrapper}
      onClick={handleClick}
    >
      <Typography
        className={classes.selectableItem}
        style={getSelectedAIModelTextStyle(isDisabled)}
      >
        {color && <span style={getColorIndicatorStyle(formattedColor)} />}
        {name}
        {quantification}
      </Typography>
      {isLoading ? (
        <Spinner size={12} color="secondary" />
      ) : (
        <Check
          style={getSelectedAIModelCheckIconStyle(isSelected, isDisabled)}
          fontSize="1rem"
        />
      )}
    </Box>
  );
};
