import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller } from 'components/IFViewer/components/Controller/Controller';
import Draggable from 'react-draggable';
import React, { useState, memo, useEffect } from 'react';
import { ChannelsLegend } from 'components/IFViewer/components/ChannelsLegend';

export const DraggableController = memo(
  ({
    slideData,
    wrapperRef,
    classes,
    tableWidth,
    hasTable,
    isIFSocialSharingPage,
  }) => {
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
    const [x, setX] = useState(100);
    const [y, setY] = useState(60);

    useEffect(() => {
      setX((prev) => prev - (hasTable ? tableWidth : -tableWidth));
    }, [hasTable]);

    const toggleAccordion = () => {
      setIsAccordionExpanded((prev) => !prev);
    };

    //This useEffect triggers only once after first render and need to set default x offset for draggable <Controller/>
    useEffect(() => {
      if (!wrapperRef.current) return;
      const CONTROLLER_WIDTH_PX = 450;
      const viewerWidth = wrapperRef.current?.clientWidth;
      const defaultMenuOffset = viewerWidth - CONTROLLER_WIDTH_PX;
      setX(defaultMenuOffset);
    }, [wrapperRef.current]);

    const handleStop = (event, dragElement) => {
      setX(dragElement.x);
      setY(dragElement.y);
    };

    return (
      <>
        <Draggable
          onStop={handleStop}
          position={{ x: x, y: y }}
          bounds="parent"
          defaultPosition={{ x: 100, y: 100 }}
          // We need this offset to allow user move controller outside the viewer
          positionOffset={{ x: '0%', y: '-80px' }}
          cancel={'.cancel-drag'}
        >
          <Accordion
            expanded={isAccordionExpanded}
            className={classes.accordion}
            aria-controls="panel1a-content"
          >
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={
                <ExpandMoreIcon
                  fontSize={'large'}
                  onClick={toggleAccordion}
                  sx={{
                    pointerEvents: 'auto',
                  }}
                />
              }
            >
              <Typography variant={'h6'} color={'inherit'}>
                Options
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cancel-drag">
                <Controller
                  slideData={slideData}
                  isIFSocialSharingPage={isIFSocialSharingPage}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </Draggable>
        {!isAccordionExpanded && <ChannelsLegend />}
      </>
    );
  },
);
