import { makeStyles } from 'tss-react/mui';
import signupBackground from 'images/signup_background_4_optimized.jpg';

export const useResetPasswordPageStyles = makeStyles()((theme) => ({
  paperContainer: {
    backgroundImage: `url(${signupBackground})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
  },
  loginHeader: {
    position: 'relative',
    top: '-2rem',
    width: '80%',
    margin: 'auto',
    backgroundColor: theme.palette.secondary.main,
    paddingTop: '2rem',
    paddingBottom: '2rem',
    color: 'white',
  },
  input: {
    marginLeft: '2rem',
    marginTop: '1rem',
    marginRight: '2rem',
    width: '80%',
  },
  resetButton: {
    float: 'right',
  },
  resetButtonContainer: {
    margin: '2rem',
    width: '80%',
  },
}));
