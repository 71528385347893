import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

export const SelectedUserChip = ({ classes, user, handleDelete }) => {
  return (
    <Box className={classes.chipContainer}>
      <Typography className={classes.chipText}>
        {`${user.first_name} ${user.last_name}`}
      </Typography>
      <CloseIcon
        className={classes.chipIcon}
        onClick={handleDelete}
        cursor={'pointer'}
      />
    </Box>
  );
};
