import React from 'react';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import { LOGIN_URL } from 'constants/urls';
import { LoginIcon } from 'components/icons/LoginIcon';
import { useLoginComponentStyles } from './styles';

export const LoginComponent = () => {
  const history = useHistory();
  const { classes } = useLoginComponentStyles();
  const handleClick = () => history.push(LOGIN_URL);

  return (
    <Button color="inherit" onClick={handleClick}>
      <LoginIcon className={classes.loginIcon} />
      <span className={classes.email}>Sign in</span>
    </Button>
  );
};
