import { generateRandomColor } from 'utilities/general';
import {
  ACTIVE_ORDERS,
  ALL_ORDERS,
  ARCHIVED_ORDERS,
  CONTRIBUTED_ORDERS,
  DELIVERY_COST_USD,
  MB_IN_TB,
  ONE_TB_COST_USD,
  ORDERS_ON_HARD_DISK,
  SlideType,
} from 'components/TeamOverview/constants';
import { transformDataUsage } from 'components/UserProfile/utilities';
import { MB } from 'components/UserProfile/constants';
import { buildContactUsLink } from 'components/Layout/Sidebar/utilities';
import React from 'react';

export const stringAvatar = (firstName, lastName) => {
  return {
    sx: {
      bgcolor: `rgb(${generateRandomColor()})`,
    },
    children: `${firstName[0] || ''}${lastName[0] || ''}`,
  };
};

const UNUSED_DATA_COLOR = 'rgb(220, 220, 220)';

export const flatOrderSlides = ({ slides = [], ifSlides = [] }) => [
  ...slides,
  ...ifSlides,
];

export const prepareDataUsage = (team) => {
  if (!team) return [];
  const total = team?.total_storage_used;

  const toPercent = (value) => parseFloat((100 * value) / total);

  const dataUsage = [
    {
      name: SlideType.H_AND_E,
      percent: toPercent(team.total_he_storage_used).toFixed(1),
      data: team.total_he_storage_used,
      color: 'rgb(50, 206, 57)',
    },
    {
      name: SlideType.IF,
      percent: toPercent(team.total_if_storage_used).toFixed(1),
      data: team.total_if_storage_used,
      color: 'rgb(205, 135, 53)',
    },
    {
      name: SlideType.IHC,
      percent: toPercent(team.total_ihc_storage_used).toFixed(1),
      data: team.total_ihc_storage_used,
      color: 'rgb(74, 53, 205)',
    },
  ];

  const miscellaneousData = dataUsage.reduce((acc, v) => acc - v.data, total);
  const miscellaneousDataPercent = dataUsage.reduce(
    (acc, v) => acc - v.percent,
    100,
  );

  return [
    ...dataUsage,
    {
      name: 'Miscellaneous',
      percent: miscellaneousDataPercent.toFixed(1),
      data: miscellaneousData,
      color: UNUSED_DATA_COLOR,
    },
  ];
};

export const prepareContributedData = (team) => {
  if (!team) return [];
  const total = team?.total_contributed_storage_used;

  const toPercent = (value) => parseFloat((100 * value) / total);

  const dataUsage = [
    {
      name: SlideType.H_AND_E,
      percent: toPercent(team.total_he_contributed_storage_used).toFixed(1),
      data: team.total_he_contributed_storage_used,
      color: 'rgb(50, 206, 57)',
    },
    {
      name: SlideType.IF,
      percent: toPercent(team.total_if_contributed_storage_used).toFixed(1),
      data: team.total_if_contributed_storage_used,
      color: 'rgb(205, 135, 53)',
    },
    {
      name: SlideType.IHC,
      percent: toPercent(team.total_ihc_contributed_storage_used).toFixed(1),
      data: team.total_ihc_contributed_storage_used,
      color: 'rgb(74, 53, 205)',
    },
  ];

  const miscellaneousData = dataUsage.reduce((acc, v) => acc - v.data, total);
  const miscellaneousDataPercent = dataUsage.reduce(
    (acc, v) => acc - v.percent,
    100,
  );

  return [
    ...dataUsage,
    {
      name: 'Miscellaneous',
      percent: miscellaneousDataPercent.toFixed(1),
      data: miscellaneousData,
      color: UNUSED_DATA_COLOR,
    },
  ];
};

export const parseLegendIntoDatasets = (legend) => {
  const values = [];
  const colors = [];

  legend.forEach(({ data, color }) => {
    values.push(data);
    colors.push(color);
  });

  return {
    data: values,
    backgroundColor: colors,
  };
};

export const parseSlides = (slides) =>
  slides
    ? slides.map(
        ({ sample, source_file_size_mb, name, antibody, stain_type }) => ({
          dataUsed: transformDataUsage(source_file_size_mb, MB, 2),
          slideName: name,
          fixativeTime: sample?.fixation_time_hours,
          antibodyName: antibody?.name,
          sampleName: sample?.name,
          speciesName: sample?.species?.name,
          stainType: stain_type,
          organName: sample?.organ?.name,
        }),
      )
    : [];

export const navigateToContactUs = (userDetails) => {
  const additionalParams = {
    title: 'Assistance with Contribution / Archiving Data',
  };

  const link = buildContactUsLink(userDetails, additionalParams);
  window.open(link, '_blank');
};

export const ORDER_TYPE_FILTERS = {
  [ALL_ORDERS]: (orders) => orders,
  [ACTIVE_ORDERS]: (orders) =>
    orders.filter(
      (order) => !order.pathology_map_consent && !order.is_storage_archived,
    ),
  [CONTRIBUTED_ORDERS]: (orders) =>
    orders.filter((order) => order.pathology_map_consent),
  [ARCHIVED_ORDERS]: (orders) =>
    orders.filter((order) => order.is_storage_archived),
  [ORDERS_ON_HARD_DISK]: (orders) => orders.filter((order) => order.exported),
};

export const buildAddressLine = (address) => {
  const addressFields = ['street_1', 'street_2', 'city', 'state', 'zip_code'];
  const addressParts = addressFields
    .map((field) => address[field])
    .filter((part) => part?.length);
  return addressParts.join(', ');
};

export const getOrderLabel = (order) => (
  <span>
    {order?.project?.name} / <b>{order?.name}</b>
  </span>
);

export const getSelectedSlidesCount = (selectedOrders, ordersSlides) =>
  selectedOrders.reduce(
    (accumulator, order) =>
      accumulator + flatOrderSlides(ordersSlides.get(order) || {}).length || 0,
    0,
  );

export const calculateTotalDataValue = (orders) =>
  orders.reduce(
    (accumulator, order) =>
      accumulator + order.slides_data_used + order.if_slides_data_used,
    0,
  );

export const getOrderDataUsageLabel = (order) =>
  order?.slides_data_used || order.if_slides_data_used
    ? transformDataUsage(
        order?.slides_data_used + order.if_slides_data_used,
        MB,
      )
    : '-';

export const getTotalCostItems = ({ orders }) => {
  const dataUsage = calculateTotalDataValue(orders);
  //@TODO: Uncomment when needed (HIS_2043)
  // const dataUsageLabel =
  //   dataUsage > 0 ? transformDataUsage(dataUsage, MB) : "0 MB";

  const dataUsageInTB = +(dataUsage / MB_IN_TB).toFixed(5);
  const dataUsageLimit = Math.ceil(dataUsageInTB);
  const totalCost = dataUsageLimit * ONE_TB_COST_USD + DELIVERY_COST_USD;

  return [
    {
      label: 'COST',
      value: `$${ONE_TB_COST_USD}/TB + SHIPPING`,
    },
    {
      label: 'SELECTED DATA',
      value: `${dataUsageInTB}TB/${dataUsageLimit}TB`,
    },
    {
      label: 'SHIPPING',
      value: `$ ${DELIVERY_COST_USD}.00`,
    },
    {
      label: 'TOTAL COST',
      value: `$ ${totalCost.toFixed(2)}`,
    },
  ];
};

export const renderCellValue = (value) => value || '-';
