import { makeStyles } from 'tss-react/mui';

const SIZE_STYLES = {
  small: {
    fontSize: '1.25rem',
    padding: '0 5px',
    letterSpacing: 0,
  },
  medium: {
    fontSize: '1.5rem',
    padding: '0 10px',
  },
  large: {
    fontSize: '1.875rem',
    padding: '0 10px',
    letterSpacing: '0.1875rem',
  },
};

export const useBreadcrumbsStyles = makeStyles()((_, { size }) => ({
  wrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  item: {
    fontSize: SIZE_STYLES[size].fontSize,
    letterSpacing: SIZE_STYLES[size].letterSpacing,

    '& > span': {
      padding: SIZE_STYLES[size].padding,
      cursor: 'default',
    },
  },
}));
