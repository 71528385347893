import { makeStyles } from 'tss-react/mui';

export const useConsultationActionsModalStyles = makeStyles()(() => ({
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 16px 0 32px',
  },
}));

export const buttonStyle = { marginBottom: '3px' };
