import { useCallback, useState } from 'react';
import { getCurrentLocation } from 'services/resources/googleMaps';
import { parseActualAddress } from 'utilities/general';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { GET_CURRENT_LOCATION_OPTION } from 'components/Shared/constants';

export const useCurrentLocation = () => {
  const { showWarning } = useSnackbar();
  const [isCurrentLocationLoading, setIsCurrentLocationLoading] =
    useState(false);
  const [currentLocation, setCurrentLocation] = useState({});

  const getActualAddress = () => {
    setIsCurrentLocationLoading(true);

    const handleSuccess = (pos) => {
      const crd = pos.coords;
      const lat = crd.latitude.toString();
      const lng = crd.longitude.toString();

      getCurrentLocation(lat, lng)
        .then((response) => {
          const formattedResponse = response.data;
          const actualUserAddress = parseActualAddress(formattedResponse);
          setCurrentLocation({ ...actualUserAddress });
        })
        .catch((error) =>
          showWarning(
            `An Error Occurred While Getting Your Location. ${error}`,
          ),
        )
        .finally(() => setIsCurrentLocationLoading(false));
    };

    const handleError = () => {
      setIsCurrentLocationLoading(false);
      showWarning('An Error Occurred While Getting Your Location.');
    };

    navigator.geolocation.getCurrentPosition(
      handleSuccess,
      handleError,
      GET_CURRENT_LOCATION_OPTION,
    );
  };

  const detectCurrentLocation = useCallback(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'prompt') {
        showWarning('Please Allow Permissions to Fill Address');
      } else if (result.state === 'denied') {
        showWarning(
          'We were unable to get your location. Please allow permissions to fill your address.',
        );
      }

      result.state !== 'denied' && getActualAddress();
    });
  }, []);

  return {
    detectCurrentLocation,
    currentLocation,
    setCurrentLocation,
    isLoading: isCurrentLocationLoading,
  };
};
