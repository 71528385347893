import { Box, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useMemo, useState } from 'react';
import { useGetAllSlidesQuery } from 'store/apis/slidesApi';

import { FilteredSlides } from 'components/pages/AppLibrary/FilteredSlides';
import { useActionButtonsStyles } from 'components/pages/AppLibrary/styles';
import { CompletedSlidesButton } from 'components/pages/AppLibrary/components/CompletedSlidesButton';
import { ProcessingButton } from 'components/pages/AppLibrary/components/ProcessingButton';
import { FILTER_BUTTONS } from 'components/pages/AppLibrary/constants';
import { useAiModelSlidesStyles } from 'components/pages/AppLibrary/AiModel/styles';
import { checkIfMLApplicable } from 'components/pages/AppLibrary/utilities';

export const SLIDES_INCREMENT = 12;

export const AiModelSlides = ({ model }) => {
  const { classes } = useAiModelSlidesStyles();
  const { classes: buttonsClasses } = useActionButtonsStyles();

  const [activeFilter, setActiveFilter] = useState(FILTER_BUTTONS.applicable);
  const [currentIndex, setCurrentIndex] = useState(SLIDES_INCREMENT);

  const { data: initialSlides, isLoading } = useGetAllSlidesQuery();

  const applicableSlides = useMemo(() => initialSlides?.filter(checkIfMLApplicable), [initialSlides]);

  const getButtonClasses = (currentButton) =>
    currentButton === activeFilter
      ? `${buttonsClasses.button} ${buttonsClasses.activeButton}`
      : buttonsClasses.button;

  const activeFilterHandler = (currentButton) => {
    if (currentButton === activeFilter) return;
    setCurrentIndex(SLIDES_INCREMENT);
    setActiveFilter(currentButton);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.buttonWrapper}>
        <Button
          className={getButtonClasses(FILTER_BUTTONS.applicable)}
          onClick={() => activeFilterHandler(FILTER_BUTTONS.applicable)}
          variant="contained"
          startIcon={<SearchIcon />}
          disabled={isLoading}
        >
          {applicableSlides?.length || 0} APPLICABLE SLIDES
        </Button>

        <CompletedSlidesButton
          onClick={() => activeFilterHandler(FILTER_BUTTONS.completed)}
          className={getButtonClasses(FILTER_BUTTONS.completed)}
          disabled={isLoading}
        />

        <ProcessingButton
          onClick={() => activeFilterHandler(FILTER_BUTTONS.processing)}
          className={getButtonClasses(FILTER_BUTTONS.processing)}
          disabled={isLoading}
        />
      </Box>

      <FilteredSlides
        initialSlides={applicableSlides}
        isLoading={isLoading}
        activeFilter={activeFilter}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        model={model}
      />
    </Box>
  );
};
