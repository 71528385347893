import { difference } from 'lodash';
import {
  GridArrayValueSelector,
  IsTumorGetter,
  IsTumorValueSetter,
  OrganValueSetter,
  SamplesGridSimpleFormatter,
  SimpleValueNameGetterV3,
  SpeciesValueSetter,
  SubmittedInValueSetter,
} from 'components/utilities/gridDataSerializers';
import {
  EXPERIMENT_TYPES_VALUES,
  IF_SERVICE,
  REQUESTED_ANTIBODIES_FIELD_VALUE,
  REQUESTED_PANELS_FIELD_VALUE,
  REQUESTED_SERVICES_FIELD_NAME,
  REQUESTED_SERVICES_OPTIONS,
  REQUESTED_SPECIAL_STAINS_FIELD_VALUE,
  serializedTrueFalseOptionsToSelectInput,
  SERVICE_NAME_TO_REQUESTED_SERVICE_MAP,
  SPECIALIZED_BLOCK_SERVICE,
} from 'components/OrderForm/constants';
import {
  getOrderAntibodies,
  getOrgans,
  getSampleSubmissions,
  getSimplePanels,
  getSpecialStains,
  getSpecies,
} from 'services/resources/commonResources';
import {
  ALL_HUMAN_AND_NON_HUMAN_COLUMNS,
  COLUMN_TOOLTIPS,
  COLUMNS_LIST_VALIDATION_CRITERIA,
  NONHUMAN_SPECIFIC_COLUMNS,
} from 'components/OrderFormV2/SampleScienceInfoV2/ScienceInfoConstants';
import { setColumnVisibleIfValueExists } from 'components/utilities/grid';
import { CheckboxSamples } from 'components/SampleEditor/CheckboxSamples';
import { ColumnHeader } from 'components/SampleEditor/components/ColumnHeader';
import { KEYS_CODES } from 'constants/keyboard';

export const getNameFromData = (data) =>
  data.map((result) => {
    return result.name;
  });

export const getDisplayNameFromData = (
  data,
  AddAntibodyButton,
  handleAntibodiesListModalOpen,
) => {
  const displayNames = data.map(({ display_name }) => display_name);

  return AddAntibodyButton
    ? [
        <AddAntibodyButton
          handleAntibodiesListModalOpen={handleAntibodiesListModalOpen}
        />,
        ...displayNames,
      ]
    : displayNames;
};

export const getOrganColumnsData = async () => {
  const _organData = await getOrgans();

  return _organData.filter((result) => {
    return result.is_in_order_form;
  });
};

export const getAntibodiesColumnData = async (order) => {
  return await getOrderAntibodies(order.uuid);
};

export const getSampleSubmissionsColumnData = async (order, isStaff) => {
  const _apiResponse = await getSampleSubmissions(order);

  const validData = isStaff
    ? _apiResponse
    : _apiResponse.filter((result) => {
        return result.is_in_order_form;
      });

  return validData;
};

const getSamplesSpecialStainsColumnData = async () => {
  const _apiResponse = await getSpecialStains();
  return _apiResponse;
};

const getSamplesPanelsColumnData = async () => {
  const _apiResponse = await getSimplePanels();
  return _apiResponse;
};

export const toolTipValueGetter = (params) => {
  const fieldName = params?.colDef?.field;
  const helpText = COLUMN_TOOLTIPS[fieldName] ? COLUMN_TOOLTIPS[fieldName] : '';
  return helpText;
};

const TEXT_AREA_CELL_EDITOR_PARAMS = {
  cellEditor: 'agLargeTextCellEditor',
  cellEditorPopup: true,
  cellEditorParams: {
    rows: 3,
  },
};

export const getSampleScienceInfoColumns = ({ fieldName }) => {
  const columnDetails = {
    field: fieldName,
    headerComponent: () => <ColumnHeader name={fieldName} />,
    filter: 'agTextColumnFilter',
    editable: true,
    // some columns in sample science info have long names
    // force a min to prevent auto-adjusting that makes it hard to read
    minWidth: 200,
    ...(fieldName === 'Notes' && {
      ...TEXT_AREA_CELL_EDITOR_PARAMS,
    }),
  };

  if (COLUMNS_LIST_VALIDATION_CRITERIA[fieldName]) {
    columnDetails['cellEditor'] = 'agRichSelectCellEditor';
    columnDetails['cellEditorParams'] = {
      values: COLUMNS_LIST_VALIDATION_CRITERIA[fieldName],
    };
    columnDetails['cellEditorPopup'] = true;
  }

  return columnDetails;
};

export const suppressKeyboardEvent = (params) => {
  // ideally, but im not sure how a backspace or delete should also close the popup editor ...
  if (!params.editing) {
    const isBackspaceKey = params.event.keyCode === KEYS_CODES.BACKSPACE;
    const isDeleteKey = params.event.keyCode === KEYS_CODES.DELETE;

    if (isBackspaceKey || isDeleteKey) {
      const columnName = params.column.colId;
      const currentData = params.data[columnName];

      // if the current data is an array type, fill an empty array when backspace/delete,
      // otherwise, make an empty string
      const emptyResult = Array.isArray(currentData) ? [] : '';
      params.data[columnName] = emptyResult;

      // clears the dropdown options
      const { api } = params;
      api.refreshCells();
      return true;
    }
  }
};

//specialized block should be the only service selected
export const handleSettingSpecializedBlockService = (e) => {
  const isSpecializedBlockSelected = e.newValue?.includes(
    SPECIALIZED_BLOCK_SERVICE,
  );

  if (!isSpecializedBlockSelected) {
    return;
  }

  // it's needed to prevent loop of setting value
  if (isSpecializedBlockSelected && e.newValue.length === 1) {
    return;
  }

  e.node.setDataValue(
    REQUESTED_SERVICES_FIELD_NAME,
    [SPECIALIZED_BLOCK_SERVICE],
    'resetting services',
  );
};

export const getRequestedServicesOptions = ({ isStaff }) => {
  const requestedServicesOptions = [
    ...REQUESTED_SERVICES_OPTIONS,
    ...(isStaff ? [SPECIALIZED_BLOCK_SERVICE] : []),
  ];

  return requestedServicesOptions;
};

export const handleServicesColumnValueChange = (e) => {
  setIFPanelsColumnVisible(e);
  handleSettingSpecializedBlockService(e);
};

export const setIFPanelsColumnVisible = (e) => {
  if (e.columnApi.columnModel) {
    setColumnVisibleIfValueExists(e, REQUESTED_PANELS_FIELD_VALUE, IF_SERVICE);
  }
};

export const getScienceInfoSamplesColumns = ({ order }) => {
  // check on the order if the species is for human, if so include additional columns
  // because on legacy orders, we didn't store species on the order, this will not always be available
  const isHumanSpeciesOrder = !!order.species?.name
    ?.toLowerCase()
    .includes('human');

  const additionalScienceInfoColumns = isHumanSpeciesOrder
    ? ALL_HUMAN_AND_NON_HUMAN_COLUMNS
    : NONHUMAN_SPECIFIC_COLUMNS;

  // by default, all grid columns should have sample name
  const scienceInfoGridColumns = [
    {
      headerName: 'Sample Name',
      field: 'name',
      filter: 'agTextColumnFilter',
      enableRowGroup: false,
      checkboxSelection: true,
      suppressSizeToFit: true,
      editable: false,
      pinned: 'left',
      minWidth: 125,
    },
  ];

  additionalScienceInfoColumns.forEach((fieldName) => {
    const fieldNameColumn = getSampleScienceInfoColumns({
      fieldName: fieldName,
    });
    scienceInfoGridColumns.push(fieldNameColumn);
  });

  return scienceInfoGridColumns;
};

export const handleDynamicColumnsVisibility = (
  e,
  needGetValuesFromApi = false,
) => {
  if (e.api.columnModel) {
    for (let serviceName in SERVICE_NAME_TO_REQUESTED_SERVICE_MAP) {
      const serviceDetailsFieldName =
        SERVICE_NAME_TO_REQUESTED_SERVICE_MAP[serviceName];

      if (needGetValuesFromApi) {
        e.api.forEachNode(({ data }) => {
          const newValue = data[serviceDetailsFieldName];
          setColumnVisibleIfValueExists(
            {
              api: e.api,
              columnApi: e.columnApi,
              newValue,
            },
            serviceDetailsFieldName,
            serviceName,
          );
        });
      } else {
        setColumnVisibleIfValueExists(e, serviceDetailsFieldName, serviceName);
      }
    }
  }
};

export const handleClearColumnValues = (e) => {
  if (e.newValue?.length < e.oldValue?.length) {
    const removedServices = difference(e.oldValue, e.newValue);
    const columnsToClear = removedServices.map(
      (serviceName) => SERVICE_NAME_TO_REQUESTED_SERVICE_MAP[serviceName],
    );
    columnsToClear.forEach(
      (column) => column && e.node.setDataValue(column, []),
    );
  }
};

export const handleSampleServicesValueChanged = (e) => {
  handleDynamicColumnsVisibility(e);
  handleClearColumnValues(e);
  handleSettingSpecializedBlockService(e);
};

const getPanelsTooltips = (panelsData) => {
  return panelsData.map((panel) => {
    const antibodiesNames = getDisplayNameFromData(panel.antibodies).join(', ');
    return {
      value: panel.display_name,
      title: antibodiesNames,
    };
  });
};

const getDefaultSampleColumns = ({
  organData,
  sampleSubmissionData,
  specialStainsData,
  panelsData,
  isStaff,
  checkboxHandler,
  speciesData,
  AddAntibodyButton,
  handleAntibodiesListModalOpen,
}) => {
  const sampleColumns = [
    {
      field: 'checkbox',
      headerName: '',
      width: 40,
      cellRenderer: ({ data: { uuid, name }, node }) => {
        return name ? (
          <CheckboxSamples
            id={uuid}
            checked={node.selected}
            handler={() => checkboxHandler(node)}
          />
        ) : null;
      },
    },
    {
      field: 'name',
      headerName: 'Sample Name',
      headerComponent: () => <ColumnHeader name={'Sample Name'} required />,
      filter: 'agTextColumnFilter',
      enableRowGroup: false,
      suppressSizeToFit: true,
      editable: (params) => !params.node.data.previous_sample,
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: 'Submitted In',
      headerComponent: () => <ColumnHeader name={'Submitted In'} required />,
      filter: 'agTextColumnFilter',
      field: 'submission',
      valueGetter: SimpleValueNameGetterV3,
      valueSetter: SubmittedInValueSetter(sampleSubmissionData),
      enableRowGroup: true,
      editable: true,
      minWidth: 200,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: getNameFromData(sampleSubmissionData),
      },
      cellEditorPopup: true,
      flex: 1,
    },
    {
      headerName: 'Services',
      headerComponent: () => <ColumnHeader name={'Services'} required />,
      field: REQUESTED_SERVICES_FIELD_NAME,
      valueFormatter: SamplesGridSimpleFormatter,
      valueParser: GridArrayValueSelector,
      cellEditor: 'reactSelectEditor',
      cellEditorParams: {
        values: getRequestedServicesOptions({ isStaff }),
      },
      cellEditorPopup: true,
      minWidth: 200,
      editable: true,
      flex: 1,
      suppressKeyboardEvent: suppressKeyboardEvent,
      onCellValueChanged: handleSampleServicesValueChanged,
    },
    {
      headerName: 'IF Panels',
      headerComponent: () => <ColumnHeader name={'IF Panels'} />,
      valueFormatter: SamplesGridSimpleFormatter,
      valueParser: GridArrayValueSelector,
      field: REQUESTED_PANELS_FIELD_VALUE,
      filter: 'agTextColumnFilter',
      editable: true,
      cellEditor: 'reactSelectEditor',
      cellEditorParams: {
        values: getDisplayNameFromData(panelsData),
        tooltips: getPanelsTooltips(panelsData),
      },
      cellEditorPopup: true,
      minWidth: 200,
      suppressKeyboardEvent: suppressKeyboardEvent,
      suppressColumnsToolPanel: true,
      flex: 1,
      cellClass: 'selectCell',
    },
    {
      headerName: 'Special Stains',
      headerComponent: () => <ColumnHeader name={'Special Stains'} />,
      valueFormatter: SamplesGridSimpleFormatter,
      valueParser: GridArrayValueSelector,
      field: REQUESTED_SPECIAL_STAINS_FIELD_VALUE,
      filter: 'agTextColumnFilter',
      minWidth: 200,
      editable: true,
      cellEditor: 'reactSelectEditor',
      cellEditorParams: {
        values: getNameFromData(specialStainsData),
      },
      cellEditorPopup: true,
      suppressKeyboardEvent: suppressKeyboardEvent,
      flex: 1,
      cellClass: 'selectCell',
    },
    {
      headerName: 'IHC Antibodies',
      headerComponent: () => <ColumnHeader name={'IHC Antibodies'} />,
      valueFormatter: SamplesGridSimpleFormatter,
      valueParser: GridArrayValueSelector,
      field: REQUESTED_ANTIBODIES_FIELD_VALUE,
      filter: 'agTextColumnFilter',
      editable: true,
      cellEditor: 'reactSelectEditor',
      cellEditorParams: {
        values: getDisplayNameFromData(
          [],
          AddAntibodyButton,
          handleAntibodiesListModalOpen,
        ),
      },
      cellEditorPopup: true,
      minWidth: 220,
      suppressKeyboardEvent: suppressKeyboardEvent,
      flex: 1,
      cellClass: 'selectCell',
    },
    {
      headerName: 'Organ',
      headerComponent: () => <ColumnHeader name={'Organ'} required />,
      field: 'organ',
      valueGetter: SimpleValueNameGetterV3,
      valueSetter: OrganValueSetter(organData),
      enableRowGroup: true,
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: getNameFromData(organData),
      },
      cellEditorPopup: true,
      minWidth: 200,
      flex: 1,
      cellClass: 'selectCell',
    },
    {
      headerName: 'Species',
      headerComponent: () => <ColumnHeader name={'Species'} required />,
      field: 'species',
      valueGetter: SimpleValueNameGetterV3,
      valueSetter: SpeciesValueSetter(speciesData),
      enableRowGroup: true,
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: getNameFromData(speciesData),
      },
      cellEditorPopup: true,
      minWidth: 200,
      flex: 1,
      cellClass: 'selectCell',
    },
    {
      headerName: 'Control Type',
      headerComponent: () => <ColumnHeader name={'Control Type'} required />,
      field: 'control_type',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      editable: true,
      minWidth: 200,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: EXPERIMENT_TYPES_VALUES,
      },
      cellEditorPopup: true,
      flex: 1,
    },

    {
      headerName: 'Is Tumor',
      headerComponent: () => <ColumnHeader name={'Is Tumor'} required />,
      field: 'is_tumor',
      valueGetter: IsTumorGetter,
      valueSetter: IsTumorValueSetter,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: ['Unknown', 'True', 'False'],
      },
      cellEditorPopup: true,
      enableRowGroup: true,
      editable: true,
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: 'Sample UUID',
      field: 'uuid',
      hide: true,
      minWidth: 200,
      flex: 1,
    },
  ];

  return sampleColumns;
};

export const getSamplesColumnsData = async ({
  order,
  isStaff,
  checkboxHandler,
  AddAntibodyButton,
  handleAntibodiesListModalOpen,
}) => {
  // TODO - Some antibodies may not show up in the order form if no longer public ...
  // For now, we're going to keep the previous method where a staff member
  // will create the IHC Antibody and the user will be able to select it later
  return Promise.all([
    getOrganColumnsData(),
    getAntibodiesColumnData(order),
    getSampleSubmissionsColumnData(order, isStaff),
    getSamplesSpecialStainsColumnData(),
    getSamplesPanelsColumnData(),
    getSpecies(),
  ]).then((response) => {
    const [
      organData,
      antibodies,
      sampleSubmissionData,
      specialStainsData,
      panelsData,
      speciesData,
    ] = response;
    const sampleColumns = getDefaultSampleColumns({
      organData,
      sampleSubmissionData,
      specialStainsData,
      panelsData,
      isStaff,
      checkboxHandler,
      speciesData,
      AddAntibodyButton,
      handleAntibodiesListModalOpen,
    });

    const details = {
      antibodies,
      submissions: sampleSubmissionData,
      specialStains: specialStainsData,
      organs: organData,
      panels: panelsData,
      columns: sampleColumns,
    };

    return details;
  });
};

export const handleAddNewColumnOption = ({
  gridAPI,
  setLookupCache,
  setColumns,
  createdOption,
  columnName,
  columnId,
}) => {
  setLookupCache((prevState) => ({
    ...prevState,
    [columnName]: [...prevState[columnName], createdOption],
  }));

  const columns = gridAPI.getColumnDefs();

  columns.forEach((column) => {
    if (column.colId === columnId) {
      column.cellEditorParams.values.push(createdOption.display_name);
    }
  });

  setColumns(columns);
};

export const getAllGridData = (gridAPI) => {
  const allData = [];
  if (gridAPI) {
    gridAPI.forEachNode((node) => {
      allData.push(node.data);
    });
  }

  return allData.filter((data) => data?.name);
};

export const getBoneDecalcificationFormDefaultValues = () => ({
  bone_decalcification: serializedTrueFalseOptionsToSelectInput[false],
});

export const getUpdatedArrWithEmptyLines = (prevSamples, newSamples) => {
  const firstEmptyRowIndex = prevSamples.findIndex(
    (item) =>
      Object.keys(item).length === 2 &&
      item.hasOwnProperty('id') &&
      item.hasOwnProperty('order_uuid'),
  );

  const updatedArray = [
    ...prevSamples.slice(0, firstEmptyRowIndex),
    ...newSamples,
    ...prevSamples.slice(firstEmptyRowIndex),
  ];
  return updatedArray;
};
