import { Button, Typography, Box } from '@mui/material';
import { SelectableAddressCard } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/SelectableAddressCard';
import AddIcon from '@mui/icons-material/Add';
import { BottomButtonsSection } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/BottomButtonsSection';
import { useShippingStepStyles } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/styles';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import React from 'react';

export const SelectAddress = ({
  addresses,
  isAddressesLoading,
  handleChangeMode,
  setSelectedAddress,
  selectedAddress,
  handlePrevStep,
  handleNextStep,
}) => {
  const { classes } = useShippingStepStyles();

  return (
    <>
      <Typography className={classes.headingText}>
        Select delivery address
      </Typography>
      <Typography className={classes.inputLabel}>Select Address*</Typography>
      <Box className={classes.addressesList}>
        {isAddressesLoading ? (
          <Box className={classes.noDataContainer}>
            <Spinner size={40} />
          </Box>
        ) : (
          addresses.map((address) => (
            <Box className={classes.addressCardWrapper} key={address.uuid}>
              <SelectableAddressCard
                isSelected={
                  selectedAddress
                    ? address.uuid === selectedAddress.uuid
                    : false
                }
                address={address}
                handleSelect={() => setSelectedAddress(address)}
              />
            </Box>
          ))
        )}
      </Box>
      <Box className={classes.divider} />
      <Button
        variant="outlined"
        fullWidth={true}
        className={classes.addAddressButton}
        onClick={handleChangeMode}
      >
        <AddIcon className={classes.plusIcon} />
        ADD NEW ADDRESS
      </Button>
      <BottomButtonsSection
        cancelButtonClick={handlePrevStep}
        confirmButtonClick={handleNextStep}
        isConfirmButtonDisabled={!selectedAddress}
      />
    </>
  );
};
