import { AntibodiesListView } from 'components/AntibodiesList/AntibodiesListView';
import { DefaultModal } from 'components/Modals/DefaultModal';

export const AntibodiesListModal = ({
  handleAddToOrder,
  isAntibodiesListModalOpen,
  handleAntibodiesListModalClose,
}) => {
  return (
    <DefaultModal
      open={isAntibodiesListModalOpen}
      onClose={handleAntibodiesListModalClose}
      title="Add Antibodies"
      size="large"
      styles={{ width: '90%' }}
    >
      <AntibodiesListView isForOrderForm handleAddToOrder={handleAddToOrder} />
    </DefaultModal>
  );
};
