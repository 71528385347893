import { useAddressCardStyles } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/styles';
import { Typography, Box, Checkbox } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { buildAddressLine } from 'components/TeamOverview/utilities';
import { memo } from 'react';
import { DATA_MANAGEMENT_BLUE } from 'utilities/colors';

const compareProps = (prevProps, nextProps) => {
  return (
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.address === nextProps.address
  );
};

export const SelectableAddressCard = memo(
  ({ isSelected = false, handleSelect, address }) => {
    const { classes } = useAddressCardStyles();

    if (!address) return null;

    return (
      <Box
        className={`${classes.container} ${
          isSelected && classes.selectedContainer
        }`}
      >
        <Checkbox
          onChange={handleSelect}
          sx={{
            '&.MuiCheckbox-root': {
              padding: 0,
              marginTop: '2px',
              marginRight: '1rem',
            },
            '&.MuiCheckbox-colorPrimary': {
              color: 'rgba(128, 128, 128, 1)',
            },
            '&.Mui-checked': {
              color: `${DATA_MANAGEMENT_BLUE} !important`,
            },
          }}
          color="primary"
          checked={isSelected}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
        />
        <Box>
          <Typography className={classes.company}>{address.name}</Typography>
          <Typography className={classes.address}>
            {buildAddressLine(address)}
          </Typography>
        </Box>
      </Box>
    );
  },
  compareProps,
);
