import { useNotifyTeamMembersStyles } from 'components/OrderForm/NotifyTeamMembersSelector/styles';
import { MenuItem, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

export const TeamUsersDropdownSelectorItem = ({
  user,
  isSelected,
  isNotified,
  handleSelect,
}) => {
  const { classes } = useNotifyTeamMembersStyles();

  return (
    <MenuItem
      classes={{
        root: classes.menuItemRoot,
      }}
      onClick={() => handleSelect(user)}
    >
      <Checkbox
        disabled={isNotified}
        checked={isSelected || isNotified}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <Typography
        className={isNotified ? classes.disabledRow : ''}
      >{` ${user.first_name} ${user.last_name}`}</Typography>
    </MenuItem>
  );
};
