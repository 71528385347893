import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useProgressItemCardStyles } from 'components/OrderDetails/Tabs/OrderProgressTab/styles';

export const ProgressItemCard = ({ label, width = 800, children }) => {
  const { classes } = useProgressItemCardStyles();

  return (
    <Box className={classes.container} style={{ width }}>
      <Box className={classes.cardHeader}>
        <Typography variant={'h5'} className={classes.label}>
          {label}
        </Typography>
      </Box>
      <Box className={classes.cardContent}>{children}</Box>
    </Box>
  );
};
