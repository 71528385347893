import { DefaultModal } from 'components/Modals/DefaultModal';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  ConfirmDataOnHardDiskModalStyles,
  useConfirmDataOnHardDiskModalStyles,
} from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/styles';
import { ProgressStepper } from 'components/OrderDetails/Tabs/OrderProgressTab/ProgressStepper';
import { OrdersListStep } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/OrdersListStep';
import { ShippingStep } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/ShippingStep';
import {
  CONFIRM_EXPORT_DATA_ON_HARD_DISK_STEPPER,
  INVOICE_STEP,
  ORDER_STEP,
  SHIPPING_STEP,
} from 'components/TeamOverview/constants';
import { CommonReviewBox } from 'components/TeamOverview/components/ConfirmActionModal/CommonReviewBox';
import { HardDriveIcon } from 'images/icons/HardDriveIcon';
import {
  calculateTotalDataValue,
  getSelectedSlidesCount,
} from 'components/TeamOverview/utilities';
import { InvoiceStep } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/InvoiceStep';
import { useOrdersActions } from 'components/TeamOverview/hooks';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { EXPORT_ORDERS_ON_HARD_DISK_ERROR_MESSAGE } from 'constants/errorMessages';
import { EXPORT_ORDERS_SUCCESS_MESSAGE } from 'constants/successMessages';

export const ConfirmDataOnHardDiskModal = ({
  isOpen,
  onClose,
  selectedOrders,
  selectedRowsSlides,
  team,
}) => {
  const { showSuccess, showError } = useSnackbar();
  const { classes } = useConfirmDataOnHardDiskModalStyles();
  const [currentStepIDX, setCurrentStepIDX] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { exportOrders } = useOrdersActions();

  const stepNames = Object.keys(CONFIRM_EXPORT_DATA_ON_HARD_DISK_STEPPER.steps);

  const handleNextStep = () => {
    setCurrentStepIDX((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    setCurrentStepIDX((prev) => prev - 1);
  };

  const handleSubmitExportDataOnHardDisk = () => {
    exportOrders(selectedOrders, team.uuid, selectedAddress.uuid)
      .then(() => {
        onClose();
        setCurrentStepIDX(0);
        showSuccess(EXPORT_ORDERS_SUCCESS_MESSAGE);
      })
      .catch(() => {
        showError(EXPORT_ORDERS_ON_HARD_DISK_ERROR_MESSAGE);
      });
  };

  const steps = {
    [ORDER_STEP]: {
      name: 'Order',
      component: (
        <OrdersListStep
          orders={selectedOrders}
          handlePrevStep={onClose}
          handleNextStep={handleNextStep}
        />
      ),
    },
    [SHIPPING_STEP]: {
      name: 'Shipping',
      component: (
        <ShippingStep
          team={team}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      ),
    },
    [INVOICE_STEP]: {
      name: 'Invoice',
      component: (
        <InvoiceStep
          selectedAddress={selectedAddress}
          handlePrevStep={handlePrevStep}
          selectedOrders={selectedOrders}
          handleSubmit={handleSubmitExportDataOnHardDisk}
        />
      ),
    },
  };

  const currentStepName = stepNames[currentStepIDX];
  const currentStepContent = steps[currentStepName].component;
  const stepperSettings = {
    ...CONFIRM_EXPORT_DATA_ON_HARD_DISK_STEPPER,
    activeStepIDX: currentStepIDX,
  };

  const selectedSlidesCount = getSelectedSlidesCount(
    selectedOrders,
    selectedRowsSlides,
  );

  const warningText = `YOU’RE TRANSFERRING TOTAL OF ${selectedSlidesCount} SLIDES FROM ${selectedOrders.length} PROJECTS ON TO HARD DISK`;

  return (
    <DefaultModal
      open={isOpen}
      onClose={onClose}
      styles={ConfirmDataOnHardDiskModalStyles}
      closeIcon
    >
      <Card
        cardClass={classes.cardContainer}
        header={
          <Box padding="1.375rem" width="100%">
            <Typography className={classes.cardTitle}>
              CONFIRM DATA ON HARD DISK
            </Typography>
          </Box>
        }
      >
        <Box className={classes.cardContent}>
          <Box className={classes.stepperWrapper}>
            <ProgressStepper stepper={stepperSettings} />
          </Box>
          <Box marginBottom={'2rem'} width={'100%'}>
            <CommonReviewBox
              Icon={HardDriveIcon}
              text={warningText}
              weight={calculateTotalDataValue(selectedOrders)}
            />
            <Typography className={classes.note}>
              Please note: The minimum charge for this service is $500 +
              Shipping. Need immediate help? Call us at 1-833-PATHLAB or{' '}
              <a
                href="http://home.histowiz.com/"
                target="_blank"
                rel="noreferrer"
              >
                chat
              </a>{' '}
              with us during business hours.
            </Typography>
          </Box>
          {currentStepContent}
        </Box>
      </Card>
    </DefaultModal>
  );
};
