import React from 'react';
import { Link } from 'react-router-dom';

import {
  DATE_REQUEST_FORMAT,
  formatDatetimeStringToDates,
} from 'utilities/dates_and_times';

// generates Link with url like "https://app.histowiz.com/orders/4cb396fa-4b88-4ec2-b67e-aaacd73f50d8/#processed_slides"
export const getAppOrderDetailsLink = (orderUUID, text, anchor) => (
  <Link
    to={`/orders/${orderUUID}/#${anchor}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </Link>
);

export const getFormattedDate = (date) =>
  formatDatetimeStringToDates(date, DATE_REQUEST_FORMAT);

// getting order data for ag-grid
export const getOrderDataForGrid = ({
  name,
  created,
  state_label,
  uuid,
  slides_count,
  if_slides_count,
}) => ({
  order: { name, uuid },
  slides: { count: slides_count, uuid },
  ifSlides: { count: if_slides_count, uuid },
  date: getFormattedDate(created),
  state: state_label,
});

// getting project data for ag-grid
export const getProjectDataForGrid = ({
  name,
  orders,
  team,
  modified,
  created,
  uuid,
}) => {
  const { name: teamName, organization } = team ?? {};
  const { name: organizationName } = organization ?? {};

  const formattedModified = getFormattedDate(modified);
  const formattedCreated = getFormattedDate(created);

  return {
    uuid,
    projectName: name,
    orders: orders.length,
    organization: organizationName,
    team: teamName,
    modified: formattedModified,
    created: formattedCreated,
    ordersData: orders,
    toggle: '',
  };
};

// hide/show orders table in ag-grid depending on the orders length
export const isRowMaster = (data) => !!data?.ordersData?.length;
