import React, { useEffect, useState } from 'react';
import { SamplesPageV2 } from 'components/OrderForm/SamplesFormV2/SamplesPageV2';
import { SamplesPageV1 } from 'components/OrderForm/SamplesFormV1/SamplesPageV1';
import { Box, Stack, Switch, Typography } from '@mui/material';
import { BetaIcon } from 'components/Shared/Icons/BetaIcon';
import { useStyles } from 'components/OrderForm/SamplesPageWrapper/styles';
import { getFromLS, saveToLS } from 'components/OrderSlidesList/utilities';
import { SAMPLES_PAGE_V2_VIEW } from 'components/OrderForm/constants';
import { useGetOrderByUUIDQuery } from 'store/apis/orderApi';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import { FETCH_ORDER_ERROR_MESSAGE } from 'constants/errorMessages';
import { useRouteMatch } from 'react-router';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { getOrderStatus } from 'components/OrdersList/utilities/helpers';
import { UNSUBMITTED_ORDER } from 'components/OrdersList/constants';
import { WarningModal } from 'components/OrderForm/components/WarningModal';
import { useSimpleModal } from 'components/OrderForm/components/hooks';
import { useSelector } from 'react-redux';
import { isStaffSelector } from 'store/slices/userDetailsSlice';

export const SamplesPageWrapper = () => {
  const { classes } = useStyles();

  const match = useRouteMatch();
  const matchOrderUUID = match.params.orderUUID;
  const isStaff = useSelector(isStaffSelector);

  const [isEditBlocked, setIsEditBlocked] = useState(false);
  const [orderUUID, setOrderUUID] = useState(matchOrderUUID);
  const { isOpen, toggleModal } = useSimpleModal();
  const unmountIgnore = useUnmountIgnore();

  const { data: order = null, error } = useGetOrderByUUIDQuery(orderUUID);
  useQueryError(error, FETCH_ORDER_ERROR_MESSAGE);

  useEffect(() => {
    setOrderUUID(matchOrderUUID);
  }, [matchOrderUUID]);

  useEffect(() => {
    if (!order) {
      return;
    }
    //TODO: uncomment after changes in business logic
    // const orderStatus = getOrderStatus(order);
    // if (!isStaff && orderStatus !== UNSUBMITTED_ORDER) {
    //   setIsEditBlocked(true);
    //   toggleModal();
    // }
  }, [order, unmountIgnore]);

  const pageViewFromLS =
    typeof getFromLS(SAMPLES_PAGE_V2_VIEW) === 'object'
      ? false
      : getFromLS(SAMPLES_PAGE_V2_VIEW);
  const [isV2, setIsV2] = useState(pageViewFromLS);

  const onChangeSwitch = (event) => {
    saveToLS(SAMPLES_PAGE_V2_VIEW, event.target.checked);
    setIsV2(event.target.checked);
  };

  const SamplesPage = isV2 ? SamplesPageV2 : SamplesPageV1;
  return (
    <Box className={classes.root}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        className={classes.switchWrapper}
      >
        <Typography>Legacy Order Form</Typography>
        <Switch size="small" checked={isV2} onChange={onChangeSwitch} />
        <Typography className={classes.betaWrapper}>
          <Box>New Order Form </Box> <BetaIcon />
        </Typography>
      </Stack>
      <SamplesPage
        orderUUID={orderUUID}
        order={order}
        isEditBlocked={isEditBlocked}
      />
      <WarningModal isOpen={isOpen} handleClose={toggleModal} />
    </Box>
  );
};
