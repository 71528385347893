import { memo } from 'react';
import { ZoomButtons } from 'components/IFViewer/components/ZoomButtons';
import { ControlButtons } from 'components/IFViewer/components/ControlButtons';
import {
  DEFAULT_ZOOM_LEVEL,
  LEFT_VIEW_LAYER,
  RIGHT_VIEW_LAYER,
} from 'components/IFViewer/constants';

export const Toolbars = memo(
  ({
    isFullscreen,
    classes,
    useLinkedView,
    zoomLeft,
    zoomRight,
    currentZoomLevel,
    setPan,
    updateZoomLevel,
    getCurrentZoom,
    setCurrentZoomLevel,
    fullScreenHandle,
    viewerRef,
    handleZoomIn,
    handleZoomOut,
  }) => {
    let classNameCurrentZoomLeft = classes.currentZoom;
    let classNameCurrentZoomRight = `${classes.currentZoom} ${classes.currentZoomRight}`;

    if (isFullscreen) {
      classNameCurrentZoomLeft += ` ${classes.currentZoomLeftFullscreen}`;
      classNameCurrentZoomRight += ` ${classes.currentZoomRightFullscreen}`;
    }

    return (
      <>
        <ZoomButtons
          useLinkedView={useLinkedView}
          pan={useLinkedView && LEFT_VIEW_LAYER}
          classes={classes}
          zoomLeft={zoomLeft}
          zoomRight={zoomRight}
          currentZoomLevel={currentZoomLevel}
          setPan={setPan}
          updateZoomLevel={updateZoomLevel}
        />
        {useLinkedView && (
          <ZoomButtons
            useLinkedView
            isFullscreen={isFullscreen}
            pan={RIGHT_VIEW_LAYER}
            classes={classes}
            zoomLeft={zoomLeft}
            zoomRight={zoomRight}
            currentZoomLevel={currentZoomLevel}
            setPan={setPan}
            updateZoomLevel={updateZoomLevel}
          />
        )}
        <div className={classNameCurrentZoomLeft}>
          Zoom:{' '}
          {useLinkedView
            ? zoomLeft?.toFixed(1) || DEFAULT_ZOOM_LEVEL
            : currentZoomLevel?.toFixed(1) || DEFAULT_ZOOM_LEVEL}
          x
        </div>
        {useLinkedView && (
          <div className={classNameCurrentZoomRight}>
            Zoom: {zoomRight?.toFixed(1) || DEFAULT_ZOOM_LEVEL}x
          </div>
        )}
        <ControlButtons
          useLinkedView={useLinkedView}
          pan={LEFT_VIEW_LAYER}
          isFullscreen={isFullscreen}
          classes={classes}
          setPan={setPan}
          getCurrentZoom={getCurrentZoom}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          updateZoomLevel={updateZoomLevel}
          setCurrentZoomLevel={setCurrentZoomLevel}
          fullScreenHandle={fullScreenHandle}
          viewerRef={viewerRef}
        />
        {useLinkedView && (
          <ControlButtons
            isFullscreen={isFullscreen}
            useLinkedView
            pan={RIGHT_VIEW_LAYER}
            classes={classes}
            setPan={setPan}
            getCurrentZoom={getCurrentZoom}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            updateZoomLevel={updateZoomLevel}
            setCurrentZoomLevel={setCurrentZoomLevel}
            fullScreenHandle={fullScreenHandle}
            viewerRef={viewerRef}
          />
        )}
      </>
    );
  },
);
