import {
  TEAM_BLOCK_RESOURCE_NAME,
  TEAM_BLOCKS_RESOURCE_NAME,
  TEAM_UNMERGE_SAMPLES_RESOURCE_NAME,
  TEAM_PREFERENCES,
  BLOCK_ORDER_RESOURCE_NAME,
  BLOCK_NEW_ORDER_RESOURCE_NAME,
  TEAM_SAMPLES_TO_BLOCK_RESOURCE_NAME,
  TEAM_CREATE_BLOCK_FROM_SAMPLE_RESOURCE_NAME,
} from 'services/resources/apiConstants';
import { baseCreateApi } from 'store/apis/baseCreateApi';
import { defaultActionAsyncHandler } from 'store/utilities';
import { setTeams } from 'store/slices/teamsSlice';

export const teamsApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query({
      query: () => ({ url: 'teams/', method: 'GET' }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler({ dispatch, queryFulfilled }, setTeams);
      },
      providesTags: ['getTeams'],
    }),
    getTeamBlocks: builder.query({
      query: ({ teamUUID, internal = false }) => {
        let url = TEAM_BLOCKS_RESOURCE_NAME.replace(':teamUUID', teamUUID);

        if (internal) url += '?internal=1';

        return {
          url,
          method: 'GET',
        };
      },
    }),
    unmergeBlockSamples: builder.mutation({
      query: ({ sample_uuids }) => ({
        url: TEAM_UNMERGE_SAMPLES_RESOURCE_NAME,
        method: 'POST',
        body: { sample_uuids },
      }),
    }),
    updateBlock: builder.mutation({
      query: ({ teamUUID, blockUUID, body }) => ({
        url: TEAM_BLOCK_RESOURCE_NAME.replace(':teamUUID', teamUUID).replace(
          ':blockUUID',
          blockUUID,
        ),
        method: 'PATCH',
        body,
      }),
    }),
    mergeSamplesToBlock: builder.mutation({
      query: ({ blockUUID, samples, teamUUID }) => ({
        url: TEAM_SAMPLES_TO_BLOCK_RESOURCE_NAME.replace(':teamUUID', teamUUID),
        method: 'POST',
        body: {
          sample_uuids: samples?.map((sample) => sample.uuid),
          block_uuid: blockUUID,
        },
      }),
    }),
    createBlockFromSample: builder.mutation({
      query: ({ teamUUID, sampleUUID }) => ({
        url: TEAM_CREATE_BLOCK_FROM_SAMPLE_RESOURCE_NAME.replace(
          ':teamUUID',
          teamUUID,
        ),
        method: 'POST',
        body: { sample_uuid: sampleUUID },
      }),
    }),
    createOrderWithBlocks: builder.mutation({
      query: ({ blocksUUIDs }) => ({
        url: BLOCK_NEW_ORDER_RESOURCE_NAME,
        method: 'POST',
        body: {
          block_uuids: blocksUUIDs,
        },
      }),
    }),
    addBlocksToOrder: builder.mutation({
      query: ({ blocksUUIDs, orderUUID }) => ({
        url: BLOCK_ORDER_RESOURCE_NAME,
        method: 'POST',
        body: {
          block_uuids: blocksUUIDs,
          order_uuid: orderUUID,
        },
      }),
    }),
    getTeamPreferences: builder.query({
      query: () => ({ url: TEAM_PREFERENCES, method: 'GET' }),
    }),
    setTeamPreferences: builder.mutation({
      query: ({ body }) => ({ url: TEAM_PREFERENCES, method: 'PATCH', body }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            teamsApi.util.updateQueryData(
              'getTeamPreferences',
              undefined,
              () => data,
            ),
          );
        } catch (error) {
          console.error('Failed to update TeamPreferences cache:', error);
        }
      },
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useGetTeamBlocksQuery,
  useLazyGetTeamBlocksQuery,
  useUnmergeBlockSamplesMutation,
  useMergeSamplesToBlockMutation,
  useCreateBlockFromSampleMutation,
  useUpdateBlockMutation,
  useGetTeamPreferencesQuery,
  useSetTeamPreferencesMutation,
  useCreateOrderWithBlocksMutation,
  useAddBlocksToOrderMutation,
} = teamsApi;
