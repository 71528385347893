import React from 'react';
import { ConditionalComponent } from 'components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';

export const ShowPathologistUserOnly = ({ children }) => {
  const { isStaff, isPathologist } = useSelector(userDetailsSelector);

  return (
    <ConditionalComponent condition={isStaff || isPathologist}>
      {children}
    </ConditionalComponent>
  );
};
