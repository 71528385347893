import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import { ConditionalComponent } from 'components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent';
import {
  ACTIONS_TAB_VALUE,
  GROUP_TAB_VALUE,
  SLIDE_METADATA_TAB_VALUE,
} from 'components/PathologyMap/constants/common';
import { SlideGroupsVerticalContainer } from 'components/SlideGroups/Modals/components/SlideGroupsVerticalContainer';
import { Button } from '@mui/material';
import { Actions } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/Actions';
import { CreateSlideGroupModal } from 'components/SlideGroups/Modals/CreateSlideGroupModal';
import { SlideMetadataWithScienceInfo } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/SlideMetadataWithScienceInfo';

export const SlideInfoCardContent = ({ classes, currentTabValue, slide }) => {
  const [slideGroupModalOpen, setSlideGroupModalOpen] = useState(false);

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="calc(100% - 60px)"
        paddingX="1rem"
      >
        <ConditionalComponent
          condition={currentTabValue === SLIDE_METADATA_TAB_VALUE}
        >
          <Box className={classes.slideInfoTabContent}>
            <SlideMetadataWithScienceInfo slide={slide} />
          </Box>
        </ConditionalComponent>
        <ConditionalComponent condition={currentTabValue === GROUP_TAB_VALUE}>
          <SlideGroupsVerticalContainer
            selectedSlides={[slide]}
            customClasses={classes}
          />
          <Button
            variant={'contained'}
            onClick={() => setSlideGroupModalOpen(true)}
            className={classes.createGroupButton}
          >
            Create Slide Group
          </Button>
        </ConditionalComponent>
        <ConditionalComponent condition={currentTabValue === ACTIONS_TAB_VALUE}>
          <Box padding={1}>
            <Actions currentSlide={slide} classes={classes} />
          </Box>
        </ConditionalComponent>
      </Box>
      <CreateSlideGroupModal
        open={slideGroupModalOpen}
        onClose={() => setSlideGroupModalOpen(false)}
      />
    </Fragment>
  );
};
