import React, { Fragment, useEffect } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';

import { LARGE_MODAL_SIZE } from 'components/Modals/constants';
import { PathologyConsultationInfoView } from 'components/PathologyConsultations/InfoView';
import { DefaultDialog } from 'components/Modals/DefaultDialog';
import { useSelector } from 'react-redux';
import { currentUserEmailSelector } from 'store/slices/userDetailsSlice';
import {
  ACCEPT_ASSIGNED_CONSULTATION_URL,
  DECLINE_ASSIGNED_CONSULTATION_URL,
  FINISH_CONSULTATION_URL,
  START_CONSULTATION_URL,
} from 'constants/urls';
import { getItemSharedUrl } from 'components/Share/helpers/functions';
import { backendAPI } from 'services/backendAPI';
import {
  getPathologyConsultationFinalReportAttachments,
  getPathologyConsultationTeamFileAttachments,
  uploadFinalReportTeamFileAttachmentsToPathologyConsultations,
} from 'services/resources/pathologyConsultations';
import { FILE_ATTACHMENTS_FAILED_TO_UPLOAD } from 'components/OrderDetails/constants';
import { errorLogger } from 'utilities/loggers';
import { FinalReportTeamFileAttachmentsTable } from 'components/PathologyConsultations/OrderForm/TeamFileAttachments';
import Dropzone from 'react-dropzone';
import UploadIcon from '@mui/icons-material/Upload';
import { RenderServicesRequested } from 'components/PathologyConsultations/RenderConsultationServicesRequested';
import { ORDER_NAME_FOR_SHARE } from 'components/Share/helpers/constants';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useConsultationActionsModalStyles } from './styles';
import { useLazyGetSamplesServicesRequestedQuery } from 'store/apis/samplesApi';
import { getSlideGroupViewerURL } from 'components/SlideGroups/utilities/utilities';
import {
  canRenderFinishRequest,
  canRenderReviewRequest,
  canRenderStartRequest,
} from 'components/PathologyConsultations/utilities';
import { GETTING_DATA_ERROR } from 'constants/errorMessages';

export const ConsultationActionsModal = ({ open, onClose, consultation }) => {
  const { classes, cx } = useConsultationActionsModalStyles();
  const email = useSelector(currentUserEmailSelector);
  const { showSuccess, showError } = useSnackbar();
  const [fileAttachments, setFileAttachments] = React.useState([]);
  const [finalReportAttachments, setFinalReportAttachments] = React.useState(
    [],
  );
  const [shareURL, setShareURL] = React.useState('');
  const [slideGroupURL, setSlideGroupURL] = React.useState('');

  const [getSamplesServicesRequested, { data: servicesRequested }] =
    useLazyGetSamplesServicesRequestedQuery();

  const getOrderServicesRequested = () => {
    if (!consultation.order) {
      return;
    }

    getSamplesServicesRequested({
      orderUUID: consultation.order.uuid,
    }).catch(() => showError(GETTING_DATA_ERROR));
  };

  const getUpdatedConsultationAttachments = () => {
    if (!consultation) {
      return;
    }

    getOrderServicesRequested();

    getPathologyConsultationTeamFileAttachments({
      consultUUID: consultation.uuid,
    }).then((response) => {
      setFileAttachments(response);
    });

    getPathologyConsultationFinalReportAttachments({
      consultUUID: consultation.uuid,
    }).then((response) => {
      setFinalReportAttachments(response);
    });
  };

  useEffect(() => {
    if (!consultation) {
      return;
    }

    getUpdatedConsultationAttachments();

    if (consultation?.order?.share_enabled) {
      const share_uuid = consultation?.order?.share_uuid;
      const url = getItemSharedUrl(ORDER_NAME_FOR_SHARE, share_uuid);
      setShareURL(url);
    }

    if (consultation?.slide_group?.slides?.length > 0) {
      const slide_group_uuid = consultation?.slide_group?.uuid;
      const url = getSlideGroupViewerURL({ slide_group_uuid });
      setSlideGroupURL(url);
    }
  }, [consultation?.uuid]);

  const acceptConsultationClick = () => {
    const url = ACCEPT_ASSIGNED_CONSULTATION_URL.replace(
      ':consultUUID',
      consultation.uuid,
    );

    backendAPI.post(url).then(() => {
      onClose();
      showSuccess(`${consultation.name} has been accepted successfully!`);

      window.location.reload();
    });
  };

  const declineConsultationClick = () => {
    const url = DECLINE_ASSIGNED_CONSULTATION_URL.replace(
      ':consultUUID',
      consultation.uuid,
    );

    backendAPI.post(url).then(() => {
      onClose();

      showSuccess(`${consultation.name} has been declined!`);

      window.location.reload();
    });
  };

  const startConsultationClick = () => {
    const url = START_CONSULTATION_URL.replace(
      ':consultUUID',
      consultation.uuid,
    );

    backendAPI.post(url).then(() => {
      onClose();

      showSuccess(`${consultation.name} has been started successfully!`);

      window.location.reload();
    });
  };

  const finishConsultationClick = () => {
    const url = FINISH_CONSULTATION_URL.replace(
      ':consultUUID',
      consultation.uuid,
    );

    backendAPI.post(url).then(() => {
      onClose();

      showSuccess(`${consultation.name} has been finished successfully!`);

      window.location.reload();
    });
  };

  const renderDeclineAction = () => {
    const shouldRender = canRenderReviewRequest({ consultation });
    if (!shouldRender) {
      return <Fragment />;
    }

    return (
      <Button color="primary" onClick={declineConsultationClick}>
        Decline
      </Button>
    );
  };

  const renderAcceptConsultationAction = () => {
    const shouldRender = canRenderReviewRequest({ consultation });
    if (!shouldRender) {
      return <Fragment />;
    }

    return (
      <Button
        color="secondary"
        variant={'contained'}
        onClick={acceptConsultationClick}
      >
        Accept Consultation Request
      </Button>
    );
  };

  const renderStartConsultationAction = () => {
    const shouldRender = canRenderStartRequest({ consultation });
    if (!shouldRender) {
      return <Fragment />;
    }

    return (
      <Button
        color="secondary"
        variant={'contained'}
        onClick={startConsultationClick}
      >
        Start Consultation
      </Button>
    );
  };

  const renderFinishConsultationAction = () => {
    const shouldRender = canRenderFinishRequest({ consultation });
    if (!shouldRender) {
      return <Fragment />;
    }

    const finalReportAttachmentsUploaded = finalReportAttachments.length > 0;
    const buttonDisabled = !finalReportAttachmentsUploaded;

    return (
      <Button
        color="secondary"
        variant={'contained'}
        disabled={buttonDisabled}
        onClick={finishConsultationClick}
      >
        Finish Consultation
      </Button>
    );
  };

  const renderOrderOrSlideGroupActions = () => {
    // if there's a slide group on the path consult, we should just show that instead
    if (slideGroupURL) {
      return renderViewSlideGroupAction();
    } else if (shareURL) {
      return renderViewSlidesAction();
    }
  };

  const renderViewSlidesAction = () => {
    const shouldRender = shareURL;
    if (!shouldRender) {
      return null;
    }

    return (
      <Button
        color="primary"
        variant={'contained'}
        onClick={() => window.open(shareURL, '_blank')}
      >
        View Slides
      </Button>
    );
  };

  const renderViewSlideGroupAction = () => {
    const shouldRender = slideGroupURL;
    if (!shouldRender) {
      return null;
    }

    return (
      <Button
        color="primary"
        variant={'contained'}
        onClick={() => window.open(slideGroupURL, '_blank')}
      >
        View Slide Group
      </Button>
    );
  };

  const modalActions = () => {
    return (
      <div className={classes.actions}>
        {renderAcceptConsultationAction()}
        {renderStartConsultationAction()}
        {renderFinishConsultationAction()}
        {renderDeclineAction()}
        {renderOrderOrSlideGroupActions()}
      </div>
    );
  };

  const onFilesDrop = (acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append('files', file);
    });

    if (!consultation) {
      return;
    }

    formData.append('consultUUID', consultation.uuid);

    const requestDetails = {
      consultUUID: consultation.uuid,
      postParams: formData,
    };

    uploadFinalReportTeamFileAttachmentsToPathologyConsultations(requestDetails)
      .then(() => {
        showSuccess('Final Report Files Uploaded Successfully!');
        // now after success, get the most updated attachments
        getUpdatedConsultationAttachments();
      })
      .catch((error) => {
        showError(FILE_ATTACHMENTS_FAILED_TO_UPLOAD);
        errorLogger('error when uploading', error.response);
      });
  };

  const renderFinalReportAttachments = () => {
    return (
      <Fragment>
        <FinalReportTeamFileAttachmentsTable
          attachments={finalReportAttachments}
        />

        <Box mb={2}>
          <Dropzone
            className={classes.dropZone}
            onDrop={onFilesDrop}
            multiple={true}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={cx('dropzone', {
                    'dropzone--isActive': isDragActive,
                  })}
                >
                  <input {...getInputProps()} />
                  <Box
                    sx={{
                      p: 2,
                      border: '1px dashed grey',
                      width: '100%',
                    }}
                  >
                    <Button startIcon={<UploadIcon />}>
                      Choose a file(s) to upload
                    </Button>
                  </Box>
                </div>
              );
            }}
          </Dropzone>
        </Box>
        {/*<Divider />*/}
      </Fragment>
    );
  };

  const renderModal = () => {
    const titleText = `Review Consultation: ${consultation.name} ${email}`;
    return (
      <DefaultDialog
        open={open}
        onClose={onClose}
        actions={modalActions}
        title={titleText}
        size={LARGE_MODAL_SIZE}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <PathologyConsultationInfoView
                consultation={consultation}
                attachments={fileAttachments}
              />
            </Grid>
            <Grid item xs={7}>
              <Typography variant={'h6'} gutterBottom={true}>
                Consultation Services Requested
              </Typography>

              <RenderServicesRequested
                consultation={consultation}
                servicesRequested={servicesRequested}
              />
              {/*<RenderSlideGroupList  />*/}
              <Box mt={2}>
                <Typography variant={'body1'}>
                  <a
                    href={
                      'https://storage.googleapis.com/production-histowiz-public/website/HistoWiz-Pathology-Report-Template-2023.docx'
                    }
                  >
                    Download Pathology Consultation Template (docx)
                  </a>
                </Typography>
              </Box>
              <Box mt={2} mb={1}>
                {renderFinalReportAttachments()}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />
      </DefaultDialog>
    );
  };

  return <Fragment>{renderModal()}</Fragment>;
};
