export const HistoWizRefinementTypeList = [
  { title: 'Stain Type', refinementType: 'stain_type_name', size: 1 },
  { title: 'Antibodies', refinementType: 'antibody_name', size: 1 },
  { title: 'Antibody Catalog #', refinementType: 'catalog_number', size: 1 },
  { title: 'Special Stains', refinementType: 'special_stain_name', size: 1 },
  { title: 'Species', refinementType: 'species_name', size: 1 },
  { title: 'Organ', refinementType: 'organ_name', size: 1 },
  { title: 'Fixative Name', refinementType: 'fixative_name', size: 1 },
  { title: 'Samples', refinementType: 'sample_name' },
  { title: 'Orders', refinementType: 'order_id', size: 1 },
  { title: 'Projects', refinementType: 'project_name', size: 1 },
  {
    title: 'Gene Expression',
    refinementType: 'science_info.gene_expression',
    size: 1,
  },
  { title: 'Tumor Type', refinementType: 'science_info.tumor_type', size: 1 },
  { title: 'Biosystems', refinementType: 'science_info.biosystems', size: 1 },
  {
    title: 'Mouse Strains',
    refinementType: 'science_info.mouse_strains',
    size: 1,
  },
  {
    title: 'Experiments',
    refinementType: 'science_info.experiment_types',
    size: 1,
  },
  { title: 'Xenografts', refinementType: 'science_info.xenografts', size: 1 },
  {
    title: 'GEMM',
    refinementType: 'science_info.genetically_engineered_mouse_model',
    size: 1,
  },
  { title: 'Promoters', refinementType: 'science_info.promoters', size: 1 },
  { title: 'Reporters', refinementType: 'science_info.reporters', size: 1 },
  //{ title: "MPath",   refinementType: "science_info.mpath", size: 1 },
  { title: 'Age', refinementType: 'science_info.age', size: 1 },
  { title: 'Gender', refinementType: 'science_info.gender', size: 1 },
  { title: 'Failed QC Reason', refinementType: 'failed_qc_reason', size: 1 },
  { title: 'Needs Redo Type', refinementType: 'needs_redo_type', size: 1 },
];

// this has access to everything
//const AlgoliaPrimaryReadAPIKey = "9156a68daa475fce22debdafb5f2383c";

// public/demo key
//const AlgoliaDemoAPIKey =
//  "Y2FmNWQwNGNjMzNhZjVkMTg0ODAxZDA4YTc5Y2RiMDk2MjkwZDEwNDY3MDFmMGEyNDU5YTNjNzhkOWJiNzQyMWZpbHRlcnM9YWNjZXNzX2dyb3VwcyUzQXB1YmxpYw==";

// pathology_map key
//const AlgoliaPathologyMapKey =
//  "MTc3YWQ2YmY0NzA4NGFkNDI4Mzk1ODc1YjExZDIzNTFlNTQ1YjQ4YTQ1ZjVmOTRkODllYzcxMDE2MDkwNWZiZGZpbHRlcnM9YWNjZXNzX2dyb3VwcyUzQXBhdGhvbG9neV9tYXA=";

// staff key (sees everything)
//const AlgoliaStaffKey = "Mzk4OTJlNWJhOTZhMzU3MGJjMDU3N2U5Y2MyNjZhNDYyNjZjMGI0MzBiNzU4MWFlNWI0ZDU2MDAxMDVlZGEzZmZpbHRlcnM9YWNjZXNzX2dyb3VwcyUzQXN0YWZm"

export const indexName = 'production_pathology_map';
