import React, { Fragment } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DefaultDialog } from 'components/Modals/DefaultDialog';
import { slideNameSerializedRemoveOrderRemoveSVS } from 'components/OrderSlidesList/utilities';
import { CenteredComponent } from 'components/utilities/CenteredComponent';
import { useSlideImageComponentStyles } from './styles';

const SlideImageComponent = ({ slide, onImgClick }) => {
  const { classes } = useSlideImageComponentStyles();
  const slideNameSerialized = slideNameSerializedRemoveOrderRemoveSVS({
    slide,
  });

  return (
    <Fragment>
      <Grid item xs={4}>
        <div
          onClick={() => onImgClick(slide)}
          className={classes.slideImageContainer}
        >
          <CenteredComponent>
            <img
              src={slide.medium_thumbnail_url}
              alt={'Macro'}
              className={classes.slideImage}
            />
          </CenteredComponent>
          <Typography align={'center'}>{slideNameSerialized}</Typography>
        </div>
      </Grid>
    </Fragment>
  );
};

export const ViewSlidesListModal = ({
  isOpen,
  slides,
  onImgClick,
  handleCloseModal,
}) => {
  const { classes } = useSlideImageComponentStyles();

  const modalActions = () => {
    return (
      <Typography align={'right'}>
        <Button color="primary" onClick={handleCloseModal}>
          Close
        </Button>
      </Typography>
    );
  };

  if (!isOpen) return null;

  const renderModal = () => {
    const dialogTitle = `Slides list`;

    return (
      <DefaultDialog
        open={isOpen}
        onClose={handleCloseModal}
        actions={modalActions}
        title={dialogTitle}
      >
        <Box height={'70vh'} width={'70vw'}>
          <Grid container className={classes.container}>
            {slides.map((slide) => (
              <SlideImageComponent
                slide={slide}
                key={slide.uuid}
                onImgClick={onImgClick}
                imgHeight={'300px'}
              />
            ))}
          </Grid>
        </Box>
      </DefaultDialog>
    );
  };

  return <Fragment>{renderModal()}</Fragment>;
};
