import { QC_SCORE_COLORS } from 'components/AITools/constants/common';
import { getRGBAWithOpacity } from 'components/AITools/models/AITissueModel';
import { BaseAIModel } from 'components/AITools/models/BaseAIModel';
import { ML_QC_OVERLAY_PADDING_COEFFICIENT } from 'components/ImageViewer/constants';
import {
  getTopQCRegionsByScoreName,
  isQCScoreFailed,
  isQCScorePassed,
} from 'components/ImageViewer/utilities';

// Converts QC model contours to a standardized AI contours format.
export const convertQCContoursToAIContoursFormat = (viewer) => (qcPolygon) => {
  const boxSideLength = qcPolygon.patch_bbox[2] - qcPolygon.patch_bbox[0];
  const pad = boxSideLength * ML_QC_OVERLAY_PADDING_COEFFICIENT;
  const px = qcPolygon.patch_bbox[1] + pad;
  const py =
    viewer.source.height - qcPolygon.patch_bbox[0] - boxSideLength - pad;
  const dimension = boxSideLength - pad * 2;

  return {
    ...qcPolygon,
    type: 'fill',
    polygon: [
      px,
      py,
      px + dimension,
      py,
      px + dimension,
      py + dimension,
      px,
      py + dimension,
    ],
  };
};

export class AIQualityControlModel extends BaseAIModel {
  getQCModelStrokeStyleByPolygon(polygon) {
    const fillStyle = this.getFillStyle();
    const opacity = this.getOpacity();
    const score = polygon.patch_score_converted;

    const colorMap = {
      [QC_SCORE_COLORS.FAILED]: fillStyle.FAILED,
      [QC_SCORE_COLORS.PASSED]: fillStyle.PASSED,
      [QC_SCORE_COLORS.DEFAULT]: fillStyle.INCONCLUSIVE,
    };

    let colorKey = QC_SCORE_COLORS.DEFAULT;
    if (isQCScoreFailed(score)) colorKey = QC_SCORE_COLORS.FAILED;
    else if (isQCScorePassed(score)) colorKey = QC_SCORE_COLORS.PASSED;

    return getRGBAWithOpacity(colorMap[colorKey], opacity);
  }

  applyStylesFilled(polygon) {
    this.ctx.fillStyle = 'transparent';
    this.ctx.strokeStyle = this.getQCModelStrokeStyleByPolygon(polygon);
    this.ctx.lineWidth = 2;
    this.ctx.stroke();
    this.ctx.fill();
  }

  getTissueContours() {
    if (!this.slide?.additional_data?.[this.modelName]?.regions) return [];

    let contours = getTopQCRegionsByScoreName(
      this.slide,
      this.modelName,
      1000,
    ).map(convertQCContoursToAIContoursFormat(this.viewer));

    if (typeof contours === 'string' || contours === undefined) {
      return [];
    }

    contours = this.processContours(contours);

    return contours;
  }

  getOpacity() {
    return super.getOpacity().qc;
  }
}
