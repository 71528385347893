import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAiComputeTransactionsCardStyles } from 'components/TeamOverview/components/AiComputeTransactionsCard/styles';
import { DataRow } from 'components/TeamOverview/components/AiComputeTransactionsCard/DataRow';
import { Search } from 'components/TeamOverview/components/AiComputeTransactionsCard/Search';

import { useMlModels } from 'components/TeamOverview/components/AiComputeTransactionsCard/hooks';
import { HeaderTabs } from 'components/UserProfile/components/HeaderTabs';
import { AI_COMPUTE_TRANSACTIONS_TABS_LIST } from 'components/TeamOverview/components/AiComputeTransactionsCard/constants';
import { ALL_ORDERS_TAB_VALUE } from 'components/PathologyMap/constants/common';
import { Spinner } from 'components/Loading/Spinner/Spinner';

export const AiComputeTransactionsCard = ({ team }) => {
  const { classes } = useAiComputeTransactionsCardStyles();

  const [tabValue, setTabValue] = useState(ALL_ORDERS_TAB_VALUE);
  const [filter, setFilter] = useState('');

  const { isLoading, data: models } = useMlModels(team, tabValue, filter);

  // Temporary commented
  // const [project, setProject] = useState('app');
  // const handleProjectChange = (event) => {
  //   setProject(event.target.value);
  // };

  const handleTabChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  return (
    <Card
      header={
        <Box
          paddingY="1rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            height="max-content"
            fontWeight={500}
            fontSize={'1.125rem'}
            letterSpacing="0.1em"
          >
            AI COMPUTE TRANSACTIONS
          </Typography>
          {/*Temporary commented*/}
          {/*<FormControl*/}
          {/*  classes={{*/}
          {/*    root: classes.yearSelector,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Select*/}
          {/*    variant="outlined"*/}
          {/*    value={project}*/}
          {/*    onChange={handleProjectChange}*/}
          {/*    style={{ height: 40 }}*/}
          {/*  >*/}
          {/*    <MenuItem value={'app'}>AI APP LIBRARY</MenuItem>*/}
          {/*    <MenuItem value={'app2'}>AI APP LIBRARY 2</MenuItem>*/}
          {/*    <MenuItem value={'app3'}>AI APP LIBRARY 3</MenuItem>*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
        </Box>
      }
    >
      <Box className={classes.tableHeader}>
        <HeaderTabs
          currentTabValue={tabValue}
          handleTabChange={handleTabChange}
          tabsList={AI_COMPUTE_TRANSACTIONS_TABS_LIST}
        />
        <Search classes={classes} setFilter={setFilter} />
      </Box>

      <Grid container className={classes.row}>
        <Grid item xs={1.5}>
          <Typography className={classes.columnLabel}>ORDER</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.columnLabel}>SLIDE NAME</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography className={classes.columnLabel}>TOOL TYPE</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.columnLabel}>COST</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.columnLabel}>STATUS</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.columnLabel}>PROCESSED ON</Typography>
        </Grid>
      </Grid>

      {isLoading ? (
        <Box className={`${classes.spinnerContainer} ${classes.tableBody}`}>
          <Spinner />
        </Box>
      ) : (
        <Box className={classes.tableBody}>
          {models.length ? (
            models.map((transaction) => (
              <DataRow
                key={transaction.uuid}
                {...transaction}
                classes={classes}
              />
            ))
          ) : (
            <Box className={`${classes.spinnerContainer} ${classes.tableBody}`}>
              <Typography>No data</Typography>
            </Box>
          )}
        </Box>
      )}
    </Card>
  );
};
