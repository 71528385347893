import Box from '@mui/material/Box';
import { Fade, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SHORTCUTS } from 'components/OrderSlidesList/constants';
import { useShortcutsPromptStyles } from 'components/OrderSlidesList/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentUserPreferencesSelector,
  updatePreferences,
} from 'store/slices/userDetailsSlice';
import { SHORTCUTS_PROMPT_SWITCH_FIELD } from 'components/ImageViewer/constants';
import { updateCurrentUserPreferences } from 'services/resources/commonResources';
import { UPDATE_USER_DETAILS_MESSAGE } from 'constants/errorMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const ShortcutsPrompt = () => {
  const { classes } = useShortcutsPromptStyles();
  const dispatch = useDispatch();
  const { showError } = useSnackbar();

  const preferences = useSelector(currentUserPreferencesSelector);
  const isShortcutsPromptVisible = preferences[SHORTCUTS_PROMPT_SWITCH_FIELD];

  const handleClose = () => {
    const updatedPreferences = {
      ...preferences,
      [SHORTCUTS_PROMPT_SWITCH_FIELD]: false,
    };

    dispatch(updatePreferences({ [SHORTCUTS_PROMPT_SWITCH_FIELD]: false }));
    updateCurrentUserPreferences({ preferences: updatedPreferences }).catch(
      () => {
        showError(UPDATE_USER_DETAILS_MESSAGE);
      },
    );
  };

  return (
    <Fade in={isShortcutsPromptVisible} timeout={400}>
      <Box className={classes.container}>
        <Box className={classes.row}>
          <Typography variant="h6">Shortcuts</Typography>
          <CloseIcon onClick={handleClose} cursor="pointer" />
        </Box>
        {SHORTCUTS.map((shortcut) => (
          <Box key={shortcut.title} className={classes.row}>
            <Typography className={classes.keyboardName}>
              {shortcut.title}
            </Typography>
            <Box className={classes.keyIconWrapper}>{shortcut.keyIcon}</Box>
          </Box>
        ))}
      </Box>
    </Fade>
  );
};
