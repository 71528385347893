import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { ContributeBlocksModal } from 'components/BlocksList/BlocksModals/ContributeBlocksModal';
import { DisposeBlocksModal } from 'components/BlocksList/BlocksModals/DisposeBlocksModal';
import {
  selectBlockActionOptions,
  SLIDES_SECTION_TITLE,
  SubmitBlocksTypes,
} from 'components/BlocksList/constants';
import { ImageSection } from 'components/BlocksList/ImageSection';
import {
  blocksSelectStyles,
  useBlocksPageV2Styles,
} from 'components/BlocksList/styles';
import {
  ClearIndicator,
  DropdownIndicator,
  Option,
  SingleValue,
} from 'components/common/Select/SelectComponents';
import { SlideCard } from 'components/SlideNavigator/SlideCard';
import { selectStyles } from 'components/TeamOverview/components/ManageDataCard/styles';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { handleOpenAsGroup } from 'components/OrderSlidesList/utilities';

export const BlocksImagesTab = ({ selectedSamples, selectedSlides }) => {
  const { classes } = useBlocksPageV2Styles();
  const [currentIndex, setCurrentIndex] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalType, setModalType] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option || null);
  };

  const actionHandlers = {
    [SubmitBlocksTypes.DISPOSE]: () => setModalType(SubmitBlocksTypes.DISPOSE),
    [SubmitBlocksTypes.CONTRIBUTE]: () =>
      setModalType(SubmitBlocksTypes.CONTRIBUTE),
    [SubmitBlocksTypes.SHIP_BACK]: () =>
      setModalType(SubmitBlocksTypes.SHIP_BACK),
  };

  const handleContinue = () => {
    actionHandlers[selectedOption?.value]?.();
  };

  const handleModalClose = () => {
    setModalType(null);
  };

  useEffect(() => {
    setCurrentIndex(1);
  }, [selectedSamples]);

  if (!selectedSamples?.length) {
    return (
      <Box className={classes.tabContainer}>
        <Typography className={classes.noData}>
          Click on any sample with slides
        </Typography>
      </Box>
    );
  }

  const currentSample = selectedSamples[currentIndex - 1];
  const isPrevDisabled = currentIndex === 1;
  const isNextDisabled = currentIndex === selectedSamples.length;

  const handlePrevClick = () => {
    if (isPrevDisabled) return;

    setCurrentIndex((index) => index - 1);
  };

  const handleNextClick = () => {
    if (isNextDisabled) return;

    setCurrentIndex((index) => index + 1);
  };

  return (
    <Box className={classes.imagesTab}>
      <Box className={classes.tabContainer}>
        <Box className={classes.imagesTools}>
          <Box className={classes.imagesNavigation}>
            <KeyboardDoubleArrowLeft
              className={`${classes.imagesArrow} ${
                isPrevDisabled && 'disabled'
              }`}
              onClick={handlePrevClick}
            />
            <Typography className={classes.imagesCurrentPage}>
              {currentIndex}
            </Typography>
            of
            <Typography className={classes.imagesTotalPages}>
              {selectedSamples.length}
            </Typography>
            <KeyboardDoubleArrowRight
              className={`${classes.imagesArrow} ${
                isNextDisabled && 'disabled'
              }`}
              onClick={handleNextClick}
            />
          </Box>
          <Box>
            {/*// @todo: enable when backend ready*/}
            {/*<Box ml={2} width="15rem" position="relative">*/}
            {/*  <Select*/}
            {/*    value={selectedOption}*/}
            {/*    onChange={handleChange}*/}
            {/*    options={selectBlockActionOptions(selectedOption)}*/}
            {/*    placeholder="SELECT ACTION"*/}
            {/*    styles={{ ...selectStyles, ...blocksSelectStyles }}*/}
            {/*    isClearable*/}
            {/*    components={{*/}
            {/*      DropdownIndicator,*/}
            {/*      ClearIndicator,*/}
            {/*      Option,*/}
            {/*      SingleValue,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*  <Button*/}
            {/*    variant="contained"*/}
            {/*    size="small"*/}
            {/*    className={classes.continueButton}*/}
            {/*    onMouseDown={(e) => e.stopPropagation()}*/}
            {/*    onClick={handleContinue}*/}
            {/*    disabled={!selectedOption || !selectedSamples?.length}*/}
            {/*  >*/}
            {/*    Review*/}
            {/*  </Button>*/}
            {/*</Box>*/}
          </Box>
        </Box>
        {currentSample && (
          <Typography className={classes.imagesHeader}>
            {currentSample.projectName} / {currentSample.name}
          </Typography>
        )}
        <Box className={classes.scrollableContainer}>
          <ImageSection
            classes={classes}
            selectedSlides={selectedSlides}
            currentSample={currentSample}
          />
          <ImageSection
            classes={classes}
            Content={SlideCard}
            title={SLIDES_SECTION_TITLE}
            selectedSlides={selectedSlides}
            slidesCount={selectedSlides.length}
            onHeaderClick={handleOpenAsGroup}
            currentSample={currentSample}
          />
          {!selectedSlides.length && (
            <Typography className={classes.noData}>
              There are no slides in this block
            </Typography>
          )}
        </Box>
      </Box>
      <DisposeBlocksModal
        open={modalType === SubmitBlocksTypes.DISPOSE}
        handleClose={handleModalClose}
        selectedRows={selectedSamples}
      />
      <ContributeBlocksModal
        open={modalType === SubmitBlocksTypes.CONTRIBUTE}
        handleClose={handleModalClose}
        selectedRows={selectedSamples}
      />
    </Box>
  );
};
