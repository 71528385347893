import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { SHOW_TEAM_OVERVIEW_MODAL_FIELD } from 'components/PathologyMap/constants/common';
import { useChangeModalStyles } from 'components/UserProfile/components/styles';
import { CONTENT_DO_NOT_SHOW_AGAIN_ERROR_MESSAGE } from 'constants/errorMessages';
import { CONTENT_DO_NOT_SHOW_AGAIN_SUCCESS_MESSAGE } from 'constants/successMessages';
import teamOverviewImage from 'images/newFeatures/team_overview.png';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUserPreferencesMutation } from 'store/apis/userDetailsApi';
import { currentUserPreferencesSelector } from 'store/slices/userDetailsSlice';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

const advantages = [
  'Direct Image Uploads in New Formats',
  'Enhanced Search 2.0 with Custom Metadata',
  'Expanded Annotation & Quantification Tools',
  'One-click AI Analysis from Leading Companies',
];

export const ChangesModal = ({
  dataUsage,
  price,
  open,
  forceOpen,
  onClose,
  teamName,
}) => {
  const { classes } = useChangeModalStyles();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const preferences = useSelector(currentUserPreferencesSelector);
  const { showError, showSuccess } = useSnackbar();
  const showTutorial =
    (preferences[SHOW_TEAM_OVERVIEW_MODAL_FIELD] && open) || forceOpen;
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();

  const handleDoNotShowAgainChange = (e) => {
    setDoNotShowAgain(e.target.checked);
  };

  const handleClose = () => {
    if (doNotShowAgain) {
      const postParams = {
        [SHOW_TEAM_OVERVIEW_MODAL_FIELD]: false,
      };
      setDoNotShowAgain(false);

      updateUserPreferences({ postParams, partial: true })
        .unwrap()
        .then(() => showSuccess(CONTENT_DO_NOT_SHOW_AGAIN_SUCCESS_MESSAGE))
        .catch(() => showError(CONTENT_DO_NOT_SHOW_AGAIN_ERROR_MESSAGE));
    }
    onClose();
  };

  return (
    <>
      <DefaultModal
        open={showTutorial}
        isLimitedHeight
        styles={{ padding: 0, maxHeight: '80vh' }}
        onClose={handleClose}
      >
        <Box className={classes.container}>
          <Box className={classes.storage}>
            <Typography className={classes.newMark}>New ✨</Typography>
            <Typography className={classes.storageTitle}>
              PathologyMap 2.0
            </Typography>
            <img
              className={classes.storageImage}
              src={teamOverviewImage}
              alt="Team Overview"
            />
            <Box className={classes.storageUsage}>
              <Typography className={classes.storageUsageLabel}>
                Your team’s Current Data Usage
              </Typography>
              <Typography className={classes.storageUsageValue}>
                {dataUsage} TB
              </Typography>
              <Typography className={classes.storageUsageLabel}>
                Price effective from September 1, 2024
              </Typography>
              <Typography className={classes.storageUsageValue}>
                ${price}/month
              </Typography>
            </Box>
            <Box className={classes.advantagesContainer}>
              {advantages.map((advantage, index) => (
                <Box key={index} className={classes.advantage}>
                  <CheckCircle
                    fontSize="small"
                    color="success"
                    className={classes.advantageIcon}
                  />
                  <Typography className={classes.advantageText}>
                    {advantage}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Button
              className={classes.learnMoreButton}
              variant="contained"
              color="default"
              onClick={handleClose}
            >
              View Dashboard for Details
            </Button>
            {!forceOpen && (
              <Grid item className={classes.checkbox}>
                <Checkbox
                  size="small"
                  color="default"
                  checked={doNotShowAgain}
                  onChange={handleDoNotShowAgainChange}
                />
                <Typography className={classes.checkboxLabel}>
                  Don't show again
                </Typography>
              </Grid>
            )}
          </Box>
          <Box className={classes.changes}>
            <Typography className={classes.changesTitle}>
              Upcoming Changes to Your Account
            </Typography>
            <Typography className={classes.changesText}>
              Hello {teamName},
            </Typography>
            <Typography className={classes.changesText}>
              On August 1st, 2024, we will launch our powerful next-generation{' '}
              <Link
                href="https://home.histowiz.com/pathology_map"
                target="blank"
              >
                PathologyMap 2.0
              </Link>{' '}
              platform. From automated full-service histology to dynamic data
              management to seamless expert analysis, PathologyMap 2.0 is the
              industry’s first end-to-end histology solution for pre-clinical
              research.
            </Typography>
            <Typography className={classes.changesList}>
              Some new tools included in the August 1st release:
            </Typography>
            <ul className={classes.changesList}>
              <li>
                Search 2.0 – A discovery engine providing an ultra-responsive
                interface for exploring entire repositories of histology data
                including user-generated categories
              </li>
              <li>
                Flexible Metadata Integration – digitize your lab notebooks,
                analysis annotations, and compare quantifications with your
                slide images
              </li>
              <li>
                Directly upload third-party histology datasets and digitize
                entire glass slide collections easily
              </li>
              <li>
                One-click AI Analysis - Features cutting-edge tools including
                automated quality control for detecting commonly occurring QC
                issues, and tissue segmentation
              </li>
            </ul>
            <Typography className={classes.changesList}>
              Upcoming Features include:
            </Typography>
            <ul className={classes.changesList}>
              <li>
                Several AI applications from validated AI partners such as AIRA
                Matrix, Aiosyn and others
              </li>
              <li>
                Native compatibility with dozens of digital formats including
                DICOM, OME-TIFF, CZI, and more
              </li>
              <li>
                Search through your slides with our AI Image Similarity Engine
              </li>
              <li>Cell segmentation and region-based quantifications</li>
              <li>
                Centralize your image analysis, pathologist, and AI generated
                annotations with Import & Export platform features which are
                compatible across multiple analysis platforms such as HALO,
                Visiopharm, etc.
              </li>
            </ul>
            <Typography className={classes.changesText}>
              To continue providing collaborative cloud slide management,
              feature development, and our ongoing partnerships and integrations
              with top AI providers,{' '}
              <u>
                beginning September 1st, 2024, HistoWiz customers will be
                charged a monthly data management fee to host all slides and
                associated data
              </u>
              . The charge will be equal to $49 for each terabyte of data
              managed per month; usage under 50GB will remain free.
            </Typography>
            <Typography className={classes.changesText}>
              <u>Histology services will not be affected</u> — 60 days of data
              management will be included with all lab and human analysis
              services at no additional cost.
            </Typography>
            <Typography className={classes.changesText}>
              For more information on new features and one-click AI Tools, visit{' '}
              <Link
                href="https://home.histowiz.com/pathology_map"
                target="blank"
              >
                https://home.histowiz.com/pathology_map/
              </Link>
              . If you have additional questions or would like to request a
              demo, please contact our customer support team at{' '}
              <Link href="mailto:getsupport@histowiz.com">
                getsupport@histowiz.com
              </Link>{' '}
              or (833)-PATHLAB.
            </Typography>
          </Box>
        </Box>
      </DefaultModal>
    </>
  );
};
