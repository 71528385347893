import {
  useAntibodiesLegendStyles,
  useLegendItemStyles,
} from 'components/AntibodiesList/styles';
import Box from '@mui/material/Box';
import { ANTIBODIES_TABLE_LEGEND } from 'components/AntibodiesList/constants';

const LegendItem = ({ legendItem }) => {
  const { classes } = useLegendItemStyles({ color: legendItem.color });
  return (
    <Box className={classes.legendItem}>
      <Box className={classes.legendItemColor} />
      <Box>{legendItem.label}</Box>
    </Box>
  );
};

export const AntibodiesListLegend = () => {
  const { classes } = useAntibodiesLegendStyles();
  return (
    <Box className={classes.legendContainer}>
      {ANTIBODIES_TABLE_LEGEND.map((legendItem, index) => (
        <LegendItem key={index} legendItem={legendItem} />
      ))}
    </Box>
  );
};
