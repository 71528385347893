import { HASH_PARAMS_DELIMITER } from 'components/IFViewer/constants';
import {
  bookMarkParamsList,
  expandedFiltersWithReset,
  SLIDE_ID_KEY,
  X_KEY,
  Y_KEY,
  ZOOM_KEY,
} from 'components/OrderSlidesList/constants';
import {
  FETCH_ORDER_ERROR_MESSAGE,
  FETCH_SHARED_GROUP_ERROR_MESSAGE,
  FETCH_SHARED_ORDER_ERROR_MESSAGE,
} from 'constants/errorMessages';
import {
  getOrderByUUID,
  getSharedGroupByUUID,
  getSharedOrderByUUID,
} from 'services/resources/orders';
import {
  SLIDES_FOR_TEMP_GROUP,
  TEMP_GROUP_PLACEHOLDER,
} from 'components/ImageViewer/constants';
import { PATHOLOGY_MAP_SEARCH_2, SLIDE_GROUPS_ROUTE } from 'constants/urls';
import { appURL } from 'services/backendAPI';
import { IHC_SERVICE } from 'components/OrderForm/constants';
import {
  ANTIBODY_NAME_REFINEMENT_TYPE,
  ORGAN_NAME_REFINEMENT_TYPE,
  SAMPLE_NAME_REFINEMENT_TYPE,
  SPECIES_REFINEMENT_TYPE,
  STAIN_TYPE_REFINEMENT_TYPE,
} from 'components/PathologyMap/constants/common';

export const LOCAL_STORAGE_SLIDES_GRID_LAYOUT =
  'LOCAL_STORAGE_SLIDES_GRID_LAYOUT_KEY';

export const SLIDES_BOOKMARKS = 'SLIDES_BOOKMARKS_KEY';

export function saveToLS(key, value) {
  return global.localStorage
    ? global.localStorage.setItem(key, JSON.stringify(value))
    : {};
}

export function getFromLS(key) {
  return (
    (global.localStorage && JSON.parse(global.localStorage.getItem(key))) || {}
  );
}

export function getLayoutFromLS(key) {
  return getFromLS(LOCAL_STORAGE_SLIDES_GRID_LAYOUT)[key];
}

export async function saveLayoutToLS(key, value) {
  if (global.localStorage) {
    const allLayouts = getFromLS(LOCAL_STORAGE_SLIDES_GRID_LAYOUT);
    allLayouts[key] = value;

    await global.localStorage.setItem(
      LOCAL_STORAGE_SLIDES_GRID_LAYOUT,
      JSON.stringify(allLayouts),
    );

    return JSON.parse(
      global.localStorage.getItem(LOCAL_STORAGE_SLIDES_GRID_LAYOUT),
    )[key];
  }
}

export const slideNameSerializedRemoveOrderRemoveSVS = ({ slide }) => {
  const orderID = slide?.order?.id;
  return (
    slide?.name
      ?.replace(orderID, '')
      .replace('.svs', '')
      .replace('. svs', '')
      // replaces the character if it starts with a $
      .replace(/^\$/, '')
  );
};

export const getSlideViewerPageTitle = (slide, isProject = false) => {
  if (isProject) {
    const project = slide?.project?.name;

    return project ? `${project} - HistoWiz` : document.title;
  }

  const slideNameSerialized = slideNameSerializedRemoveOrderRemoveSVS({
    slide,
  });

  const pageTitle = slideNameSerialized
    ? `${slideNameSerialized} - ${
        slide.order?.id || 'Temporary Group'
      } - HistoWiz`
    : document.title;

  return pageTitle;
};

export const getDefaultValueForBookmarkParam = (value) => {
  return {
    [ZOOM_KEY]: 1,
    [X_KEY]: 0.5,
    [Y_KEY]: 0.5,
    [SLIDE_ID_KEY]: '123',
  }[value];
};

const getMissedParams = (parsedParamsKeys) => {
  let missedKeys = [...bookMarkParamsList];

  parsedParamsKeys.forEach((key) => {
    missedKeys = missedKeys.filter((bookMarkParam) => bookMarkParam !== key);
  });

  return missedKeys.reduce((result, missedKey) => {
    result[missedKey] = getDefaultValueForBookmarkParam(missedKey);

    return result;
  }, {});
};

// This function parse single slide params into object
export const parseSlide = (slidesCoordinate) => {
  let params = {};
  const slidesCoordinateParts = slidesCoordinate.split('&');

  slidesCoordinateParts.forEach((part) => {
    const subparts = part.split('=');

    const key = subparts[0];
    if (!key) {
      console.error(`Key doesn't exist for part: ${part}`);
    } else {
      const rawValue = subparts[1]
        ? subparts[1]
        : getDefaultValueForBookmarkParam(key);
      const parsedValue =
        key === SLIDE_ID_KEY ? rawValue : parseFloat(rawValue);
      params[key] = parsedValue;
    }
  });

  const parsedParamsKeys = Object.keys(params);

  if (parsedParamsKeys.length !== bookMarkParamsList.length) {
    const missedParams = getMissedParams(parsedParamsKeys);
    params = { ...params, ...missedParams };
  }

  return params;
};

export const parseSlidesCoordinatesFromUrl = () => {
  const clearedHash = window.location.hash.replace(/^#/, '');
  if (!clearedHash) return [];

  const slidesCoordinates = clearedHash.split(';');
  if (!slidesCoordinates.length) return [];

  return slidesCoordinates.map((slidesCoordinate) =>
    parseSlide(slidesCoordinate),
  );
};

export const parseParamsForIFFromUrl = () => {
  const clearedHash = window.location.hash.replace(/^#/, '');
  if (!clearedHash) return [];

  const paramsArray = clearedHash.split(HASH_PARAMS_DELIMITER);

  const paramsObject = paramsArray.reduce((obj, item) => {
    const splittedParam = item.split('=');
    const key = splittedParam[0];
    return { ...obj, [key]: splittedParam[1] };
  }, {});

  return paramsObject;
};

export const updateHash = (newHashParams) => {
  const parsedParams = parseParamsForIFFromUrl();
  const updatedParams = { ...parsedParams, ...newHashParams };

  const newHash = Object.keys(updatedParams)
    .map((key) => `${key}=${updatedParams[key]}`)
    .join(HASH_PARAMS_DELIMITER);

  return `#${newHash}`;
};

export const saveSlideToLS = (slideParams) => {
  const slideId = slideParams.slideId;
  if (!slideId) return;

  const bookmarksFromLS = getFromLS(SLIDES_BOOKMARKS);
  bookmarksFromLS[slideId] = slideParams;
  saveToLS(SLIDES_BOOKMARKS, bookmarksFromLS);
};
export const buildSlide = (slide) => {
  const urlParams = Object.keys(slide).reduce((acc, key) => {
    return [...acc, `${key}=${slide[key]}`];
  }, []);
  return urlParams.join(HASH_PARAMS_DELIMITER);
};

export const buildSlidesIntoHash = (slides) => {
  const slidesAsString = slides
    .map((slide) => {
      saveSlideToLS(slide);
      return buildSlide(slide);
    })
    .join(';');

  return `#${slidesAsString}`;
};

export const withResetFilters = (columns, filtersWithResetList = []) => {
  return columns.map((col) => {
    const filterName = col.filter;
    const expandedFilter = expandedFiltersWithReset[filterName];
    if (filtersWithResetList.includes(filterName) && expandedFilter) {
      col.floatingFilterComponent = expandedFilter;
    }

    return col;
  });
};

export const getOrderOrGroup = (orderUUID, shareUUID, isSharedGroup) => {
  const getSharedByUUID = isSharedGroup
    ? getSharedGroupByUUID
    : getSharedOrderByUUID;

  return orderUUID ? getOrderByUUID(orderUUID) : getSharedByUUID({ shareUUID });
};

export const getSlidesListError = (orderUUID, isSharedGroup) => {
  return orderUUID
    ? FETCH_ORDER_ERROR_MESSAGE
    : isSharedGroup
    ? FETCH_SHARED_GROUP_ERROR_MESSAGE
    : FETCH_SHARED_ORDER_ERROR_MESSAGE;
};

const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatLabelKey = (key) => {
  const words = key.replace(/_/g, ' ').split(' ');

  return words.map((word) => capitalizeString(word)).join(' ');
};

export const getSampleScienceInfo = (data) =>
  Object.entries(data).map(([key, value]) => {
    const newValue = Array.isArray(value) ? value.join(', ') : value;

    return {
      label: formatLabelKey(key),
      value: newValue,
      keyTooltip: key,
      valueTooltip: newValue,
    };
  });

export const handleOpenAsGroup = (slidesToView) => {
  saveToLS(SLIDES_FOR_TEMP_GROUP, slidesToView);

  window.open(
    `${SLIDE_GROUPS_ROUTE.replace(':slideGroupUUID', TEMP_GROUP_PLACEHOLDER)}`,
    '_blank',
  );
};

export const FILTER_FIELDS_TO_INCLUDE_IN_SEARCH = {
  [SPECIES_REFINEMENT_TYPE]: (slide) => slide.sample?.species?.name,
  [ORGAN_NAME_REFINEMENT_TYPE]: (slide) => slide.sample?.organ?.name,
  [STAIN_TYPE_REFINEMENT_TYPE]: (slide) => slide.stain_type.replace('_', ' '),
  [ANTIBODY_NAME_REFINEMENT_TYPE]: (slide) =>
    slide.stain_type?.toUpperCase() === IHC_SERVICE && slide.antibody?.name,
  // [SAMPLE_NAME_REFINEMENT_TYPE]: (slide) => slide.sample?.name,
};

export const buildSearchLinkWithProps = (slide) => {
  let searchUrlWithProps = `${appURL}${PATHOLOGY_MAP_SEARCH_2}?`;

  for (const [key, valueGetter] of Object.entries(
    FILTER_FIELDS_TO_INCLUDE_IN_SEARCH,
  )) {
    const value = valueGetter(slide);

    if (value) {
      searchUrlWithProps = searchUrlWithProps.concat(
        '&',
        `production_pathology_map[refinementList][${key}][0]=${value}`,
      );
    }
  }
  return searchUrlWithProps;
};
