import { backendAPI } from 'services/backendAPI';
import { getGenericResource } from 'services/resources/commonResources.js';
import FileSaver from 'file-saver';
import {
  ORDER_REQUEST_FOR_CUSTOM_QUOTE,
  STRIPE_CARD_RESOURCE_NAME,
  ADD_USER_TO_TEAM_RESOURCE_NAME,
  ALL_ORDERS_IDS_NAME,
  BILLING_RESOURCE_NAME,
  ORDER_FIXATIVES_RESOURCE_NAME,
  ORDER_REQUEST_BACK_SHIPPING,
  ORDER_RESOURCE_NAME,
  ORDER_SHIPMENTS_RESOURCE_NAME,
  SAMPLES_SCIENCE_INFO_RESOURCE_NAME,
  SHARED_ORDER_RESOURCE_NAME,
  SUBMIT_ORDER_FORM_RESOURCE_NAME,
  ORDER_NEWSFEED_RESOURCE_NAME,
  PURCHASE_ORDERS,
  ORDER_HISTORY_RESOURCE_NAME,
  SLIDE_GROUPS_RESOURCE_NAME,
  SHARED_SLIDES_GROUP_RESOURCE_NAME,
  WALLETS,
} from 'services/resources/apiConstants';

export const getBlanketPurchaseOrders = () => {
  return backendAPI.get(PURCHASE_ORDERS).then((res) => res.data);
};

export const getOrderFixatives = () => {
  return backendAPI.get(ORDER_FIXATIVES_RESOURCE_NAME);
};

export const postShipBackOrder = ({ orderUUID, postParams }) => {
  const url = ORDER_REQUEST_BACK_SHIPPING.replace(':orderUUID', orderUUID);

  return backendAPI.post(url, postParams);
};

export const createOrder = (postParams) =>
  backendAPI.post(ORDER_RESOURCE_NAME, postParams);

export const updateOrder = (orderUUID, putParams) => {
  const orderDetailsURL = `${ORDER_RESOURCE_NAME}${orderUUID}/`;

  return backendAPI.put(orderDetailsURL, putParams);
};

export const submitOrder = ({ orderUUID }) => {
  const postParams = {
    order_uuid: orderUUID,
  };

  const submitOrderURL = `${ORDER_RESOURCE_NAME}${orderUUID}/${SUBMIT_ORDER_FORM_RESOURCE_NAME}`;

  return backendAPI
    .put(submitOrderURL, postParams)
    .then((response) => response.data);
};

export const getOrderByUUID = (orderUUID) => {
  const orderDetailsURL = `${ORDER_RESOURCE_NAME}${orderUUID}/`;
  return backendAPI
    .get(orderDetailsURL, { cache: false })
    .then((response) => response.data);
};

export const makeOrderShareable = ({ orderUUID, isShareable }) => {
  const url = `${ORDER_RESOURCE_NAME}${orderUUID}/share/`;
  const postParams = {
    share_enabled: isShareable,
  };
  return backendAPI.post(url, postParams).then((response) => response.data);
};

export const makeGroupShareable = ({ groupUUID, isShareable }) => {
  const url = `${SLIDE_GROUPS_RESOURCE_NAME}${groupUUID}/`;
  const postParams = {
    share_enabled: isShareable,
  };
  return backendAPI.post(url, postParams).then((response) => response.data);
};

export const getSharedOrderByUUID = ({ shareUUID }) => {
  const orderDetailsURL = `${SHARED_ORDER_RESOURCE_NAME}${shareUUID}/`;
  return backendAPI.get(orderDetailsURL).then((response) => response.data);
};

export const getSharedGroupByUUID = ({ shareUUID }) => {
  const groupDetailsURL = `${SHARED_SLIDES_GROUP_RESOURCE_NAME}${shareUUID}/`;
  return backendAPI.get(groupDetailsURL).then((response) => response.data);
};

export const getOrderBilling = (orderUUID) => {
  const billingURL = `${ORDER_RESOURCE_NAME}${orderUUID}/${BILLING_RESOURCE_NAME}`;
  return backendAPI.get(billingURL).then((response) => response.data);
};

export const postStripeCard = (postParams) => {
  const url = `${STRIPE_CARD_RESOURCE_NAME}`;

  return backendAPI.post(url, postParams).then((response) => response.data);
};

export const getOrders = () => {
  return backendAPI.get(ORDER_RESOURCE_NAME, { cacheEnabled: true });
};

export const getOrderNewsfeed = ({ order }) => {
  const url = ORDER_NEWSFEED_RESOURCE_NAME.replace(':orderUUID', order.uuid);

  return backendAPI
    .get(url, { cacheEnabled: true })
    .then((response) => response.data);
};

export const setUserToTeam = (teamInviteUUID) => {
  return backendAPI.post(
    ADD_USER_TO_TEAM_RESOURCE_NAME.replace(':teamInviteUUID', teamInviteUUID),
  );
};

export const getOrderShipments = (orderUUID) => {
  const url = `${ORDER_RESOURCE_NAME}${orderUUID}/${ORDER_SHIPMENTS_RESOURCE_NAME}`;

  return getGenericResource(url);
};

export const postOrderShipment = (orderUUID, postParams) => {
  const url = `${ORDER_RESOURCE_NAME}${orderUUID}/${ORDER_SHIPMENTS_RESOURCE_NAME}`;

  return backendAPI.post(url, postParams).then((response) => response.data);
};
//unused page
export const getSampleScienceInfo = ({ order }) => {
  const url = `${ORDER_RESOURCE_NAME}${order.uuid}/${SAMPLES_SCIENCE_INFO_RESOURCE_NAME}`;
  const config = { responseType: 'blob' };

  backendAPI.get(url, config).then((response) => {
    const contentDisposition = response.headers['content-disposition'];
    const fileName = contentDisposition.replace('attachment; filename=', '');
    FileSaver.saveAs(new Blob([response.data]), fileName);
  });
};

export const getOrderIDtoUUIDMapping = ({ orderID }) => {
  const url = `${ORDER_RESOURCE_NAME}${orderID}/mapping/`;

  return backendAPI.get(url).then((response) => response.data);
};

export const getCustomQuoteForOrder = ({ orderUUID }) => {
  const url = `${ORDER_RESOURCE_NAME}${orderUUID}/${ORDER_REQUEST_FOR_CUSTOM_QUOTE}/`;

  return backendAPI.post(url).then((response) => response.data);
};

export const getAllOrdersIds = (orderIdStartsWith) => {
  const url = `${ORDER_RESOURCE_NAME}${ALL_ORDERS_IDS_NAME}`;

  const params = {
    id_startswith: orderIdStartsWith,
  };

  return backendAPI.get(url, { params }).then((response) => response.data);
};

export const getOrderHistory = ({ orderUUID }) => {
  const url = `${ORDER_RESOURCE_NAME}${orderUUID}/${ORDER_HISTORY_RESOURCE_NAME}`;

  return backendAPI.get(url).then((response) => response.data);
};
