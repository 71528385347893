import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { getWhiteLabelAppURL } from 'utilities/whitelabel';
import { getLocalToken, getBearerToken } from 'utilities/token';

export const backendURL = process.env.REACT_APP_API_URL;
export const appURL = getWhiteLabelAppURL();

export const loginAPI = axios.create({
  baseURL: backendURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const backendServiceDecorator = (func) => {
  return (...args) => {
    const hasToken = !!getLocalToken();
    const backendAPIService = hasToken ? backendAPI : UnauthenticatedAPI;
    return func(backendAPIService, ...args);
  };
};

export const UnauthenticatedAPI = axios.create({
  // sometimes we have certain pages that don't need to be logged in
  baseURL: backendURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// This file serves as one API that determines how all traffic is done
export const backendAPI = setupCache(
  axios.create({
    baseURL: backendURL,
    headers: {
      Authorization: {
        toString() {
          return getLocalToken() || getBearerToken();
        },
      },
      Accept: 'application/json, text/plain',
    },
  }),
);

// catch all 401 - unauthorized and send them back to a login screen
backendAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    // used require to avoid circular deps issues
    const { BasicRouteDetailsKeys } = require('constants/routes');
    const { clearLS } = require('utilities/general');
    const status = ((error || {}).response || {}).status || {};

    const currentPath = window.location.pathname;
    const isBasicRoute = BasicRouteDetailsKeys.includes(currentPath);

    if (status === 401) {
      console.log('Invalid API Token');
      clearLS();
      const { forceLogout } = require('utilities/auth');
      forceLogout(isBasicRoute);
    }

    return Promise.reject(error);
  },
);

backendAPI.interceptors.request.use((config) => {
  const { cacheEnabled } = config;
  if (!cacheEnabled) {
    config.cache = false;
  }

  return config;
});

export const clearCache = () => {
  Object.keys(backendAPI.storage.data).forEach((key) => {
    backendAPI.storage.remove(key);
  });
};
