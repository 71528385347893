import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCommonStyles } from 'components/TeamOverview/styles';
import { CreateAddressForm } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/CreateAddressForm';
import { SelectAddress } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/SelectAddress';
import { useTeamAddresses } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/hooks';
import { CREATE_ADDRESS_ERROR_MESSAGE } from 'constants/errorMessages';
import { CREATE_ADDRESS_SUCCESS_MESSAGE } from 'constants/successMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const ShippingStep = ({
  team,
  handlePrevStep,
  handleNextStep,
  selectedAddress,
  setSelectedAddress,
}) => {
  const { classes: commonClasses } = useCommonStyles();
  const [createAddressMode, setCreateAddressMode] = useState(false);
  const { showSuccess, showError } = useSnackbar();

  const { fetchTeamAddresses, submitNewAddress, addresses, isLoading } =
    useTeamAddresses(team);

  useEffect(() => {
    fetchTeamAddresses();
  }, []);

  const changeMode = () => {
    setCreateAddressMode((prev) => !prev);
  };

  const handleCreateNewAddress = (data) => {
    submitNewAddress(data)
      .then(() => {
        changeMode();
        showSuccess(CREATE_ADDRESS_SUCCESS_MESSAGE);
      })
      .catch(() => {
        showError(CREATE_ADDRESS_ERROR_MESSAGE);
      });
  };

  return (
    <Box className={commonClasses.stepContainer}>
      {createAddressMode ? (
        <CreateAddressForm
          team={team}
          handlePrevStep={changeMode}
          handleCreateNewAddress={handleCreateNewAddress}
        />
      ) : (
        <SelectAddress
          addresses={addresses}
          isAddressesLoading={isLoading}
          handleChangeMode={changeMode}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        />
      )}
    </Box>
  );
};
