import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  columnDefs,
  components,
  defaultColDef,
  detailColumnDefs,
} from './constants';
import './customAgTheme.css';
import { resizeColumnsToFitScreen } from '../utilities/grid';

const agStyle = {
  height: '700px',
  width: '100%',
  fontSize: '100%',
  backgroundColor: 'transparent',
  marginTop: '1rem',
  marginLeft: '1rem',
};

const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: detailColumnDefs,
    defaultColDef: defaultColDef,
    onFirstDataRendered: resizeColumnsToFitScreen,
  },
  getDetailRowData: function (params) {
    const extraStains = [
      ...params.data.ihc_extra.objects,
      ...params.data.special_stains_extra.objects,
    ];

    params.successCallback(extraStains);
  },
};

export const SampleDetails = ({ samples }) => {
  const isRowMaster = (sample) => {
    const extraAttrs = ['ihc_extra', 'special_stains_extra'];
    const isMaster = extraAttrs.some((attr) => !sample[attr].fulfilled);

    return isMaster;
  };

  return (
    <div className="ag-theme-balham" style={agStyle}>
      <AgGridReact
        masterDetail
        detailRowHeight={210}
        isRowMaster={isRowMaster}
        components={components}
        rowData={samples}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onColumnGroupOpened={resizeColumnsToFitScreen}
        detailCellRendererParams={detailCellRendererParams}
      />
    </div>
  );
};
