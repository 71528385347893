export const CREATED_DATE_SORT = 'created_date';
export const MODIFIED_DATE_SORT = 'modified_date';
export const NAME_SORT = 'name';
export const SLIDES_COUNT_SORT = 'slides_count';

export const ORDER_NAME_SORT_FIELD = 'order_name_sort';
export const ORGAN_SORT_FIELD = 'organ_sort';
export const SPECIES_FIELD = 'spices_sort';
export const ANTIBODIES_FIELD = 'antibodies_sort';
export const XENOGRAFTS_FIELD = 'xenografts_sort';
export const STAIN_TYPE_SORT_FIELD = 'stain_type_sort';

export const FAVORITE_SLIDES_URL_KEY = 'favorite_slides';

const ADD = 'add';
const REMOVE = 'remove';

export const modalWindowMode = {
  ADD,
  REMOVE,
};

export const LIST_VIEW = 'list_view';
export const CARD_VIEW = 'card_view';

export const ViewTypes = {
  LIST_VIEW,
  CARD_VIEW,
};

export const DISPLAYED_ROWS_COUNT = 10;
