import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aiTools: [],
};

const cmsDataSlice = createSlice({
  name: 'cmsData',
  initialState,
  reducers: {
    setAITools: (state, { payload }) => {
      state.aiTools = payload;
    },
  },
});

export const { setAITools } = cmsDataSlice.actions;

export const cmsDataReducer = cmsDataSlice.reducer;

export const aiToolsSelector = (state) => state.cmsData.aiTools;
