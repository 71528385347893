import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

const useStyles = makeStyles()(() => ({
  formInput: {
    marginTop: '0.25rem',
    padding: '0.5rem',
    fontSize: '14px',
    width: '30%',
    display: 'inline-block',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    marginBottom: '1rem',
  },
}));

export const FormInput = ({ onChange, placeholder, className }) => {
  const { classes } = useStyles();

  return (
    <input
      className={className ? className : classes.formInput}
      type="text"
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

FormInput.defaultProps = {
  placeholder: 'Type text to filter...',
};
FormInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};
