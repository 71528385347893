import { Grid } from '@mui/material';
import React from 'react';
import { ComputeWalletCard } from 'components/TeamOverview/components/ComputeWalletCard/ComputeWalletCard';
import { AiComputeTransactionsCard } from 'components/TeamOverview/components/AiComputeTransactionsCard/AiComputeTransactionsCard';

export const ComputeSection = ({ team }) => {
  return (
    <Grid item container spacing={2}>
      <Grid item xs={3}>
        <ComputeWalletCard team={team} />
      </Grid>
      <Grid item xs={9}>
        <AiComputeTransactionsCard team={team} />
      </Grid>
    </Grid>
  );
};
