import { Grid, Typography } from '@mui/material';
import { SlideViewerHeader } from 'components/ImageViewer/SlidesViewerHeader';
import { ActionPanelsContainer } from 'components/OrderSlidesList/SlideActionTabs/ActionPanelsContainer/ActionPanelsContainer';
import { useScrollStyles } from 'components/OrderSlidesList/SlideActionTabs/styles';
import Box from '@mui/material/Box';
import { DefaultImageViewer } from 'components/ImageViewer/DefaultSlidesViewer';
import { useStyles } from 'components/OrderSlidesList/styles';
import React, { useState } from 'react';

export const RenderAdjustableGridOrderSlideList = ({
  slidesViewerProps,
  slidesTableProps,
  slideCommentsProps,
  setAnnotationsCount,
  showFavoriteIcon,
  annotationsCount,
  slidesGroupProps,
  isSingleViewer,
  currentSlide,
  handleNextSlide,
  handlePreviousSlide,
  isMultipleSelected,
}) => {
  const { classes } = useStyles();
  const { classes: scrollClasses } = useScrollStyles();
  const [annotationsLegend, setAnnotationsLegend] = useState(null);
  const isArchivedSlideSelected = currentSlide?.is_storage_archived;

  return (
    <Grid className={classes.root}>
      <>
        {isArchivedSlideSelected ? (
          <Box className={classes.slideTableWrapper} minHeight={300}>
            <Typography align="center" display="flex" flexDirection="row">
              <Box color="red" marginRight="1rem">
                [ ! ]
              </Box>
              You are viewing an archived slide. This is a static screenshot of
              the original WSI, and certain features may be unavailable.
            </Typography>
            <Box className={classes.thumbnailContainer}>
              <img
                src={currentSlide?.large_thumbnail_url}
                alt="slide-thumbnail"
                className={classes.thumbnail}
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.viewerContainer}>
            <SlideViewerHeader
              currentSlide={currentSlide}
              showFavoriteIcon={showFavoriteIcon}
              annotationsCount={annotationsCount}
              slidesViewerProps={slidesViewerProps}
              handleNextSlide={handleNextSlide}
              handlePreviousSlide={handlePreviousSlide}
              isSingleViewer={isSingleViewer}
            />
            <Box p={1} height="100%" className={scrollClasses.root}>
              <DefaultImageViewer
                {...slidesViewerProps}
                isBookmarkFeatureEnabled
                setAnnotationsCount={setAnnotationsCount}
                isSingleViewer={isSingleViewer}
                gridApi={slidesTableProps?.gridApi}
                setAnnotationsLegend={setAnnotationsLegend}
                currentSlide={currentSlide}
              />
            </Box>
          </Box>
        )}
        <ActionPanelsContainer
          slidesViewerProps={slidesViewerProps}
          slideCommentsProps={slideCommentsProps}
          slidesTableProps={slidesTableProps}
          slidesGroupProps={slidesGroupProps}
          currentSlide={currentSlide}
          isSingleViewer={isSingleViewer}
          annotationsLegend={annotationsLegend}
          isMultipleSelected={isMultipleSelected}
        />
      </>
    </Grid>
  );
};
