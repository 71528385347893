import { Button } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { TooltipWithMedia } from 'components/UserProfile/components/TooltipWithMedia';
import React from 'react';
import { useButtonWithIconStyles } from 'components/UserProfile/components/styles';

export const HelpButtonWithTooltip = ({ title, fileSrc }) => {
  const { classes } = useButtonWithIconStyles();

  return (
    <TooltipWithMedia title={title} fileSrc={fileSrc} placement="bottom" arrow>
      <Button
        variant="contained"
        size={'small'}
        color="primary"
        className={classes.pinkButtonWithIcon}
      >
        <QuestionMarkIcon />
      </Button>
    </TooltipWithMedia>
  );
};
