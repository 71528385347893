import React, { useCallback } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import debounce from 'lodash/debounce';
import shallow from 'zustand/shallow';

import { range, getMultiSelectionStats } from 'components/IFViewer/utils';
import {
  useChannelsStore,
  useViewerStore,
  useImageSettingsStore,
  useLoader,
} from 'components/IFViewer/state';
import { SLIDER_DEBOUNCE_TIME } from 'components/IFViewer/constants';

const sliderStyle = { marginTop: '7px' };

export const GlobalSelectionSlider = (props) => {
  const { size, label } = props;
  const [selections, setPropertiesForChannel] = useChannelsStore(
    (store) => [store.selections, store.setPropertiesForChannel],
    shallow,
  );
  const loader = useLoader();
  const [globalSelection, brightnessCutOff] = useViewerStore((store) => [
    store.globalSelection,
    store.brightnessCutOff,
  ]);

  const changeSelection = useCallback(
    debounce(
      (event, newValue) => {
        useViewerStore.setState({
          isChannelLoading: selections.map(() => true),
        });
        const newSelections = [...selections].map((sel) => ({
          ...sel,
          [label]: newValue,
        }));
        getMultiSelectionStats({
          loader,
          selections: newSelections,
          use3d: false,
          brightnessCutOff,
        }).then(({ domains, contrastLimits }) => {
          unstable_batchedUpdates(() => {
            range(newSelections.length).forEach((channel, j) =>
              setPropertiesForChannel(channel, {
                domains: domains[j],
                contrastLimits: contrastLimits[j],
              }),
            );
          });
          unstable_batchedUpdates(() => {
            useImageSettingsStore.setState({
              onViewportLoad: () => {
                useImageSettingsStore.setState({
                  onViewportLoad: () => {},
                });
                useViewerStore.setState({
                  isChannelLoading: selections.map(() => false),
                });
              },
            });
            range(newSelections.length).forEach((channel, j) =>
              setPropertiesForChannel(channel, {
                selections: newSelections[j],
              }),
            );
          });
        });
      },
      SLIDER_DEBOUNCE_TIME,
      { trailing: true },
    ),
    [loader, selections],
  );
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={1}>
        {label}:
      </Grid>
      <Grid item xs={11}>
        <Slider
          value={globalSelection[label]}
          onChange={(event, newValue) => {
            useViewerStore.setState({
              globalSelection: {
                ...globalSelection,
                [label]: newValue,
              },
            });
            if (event.type === 'keydown') {
              changeSelection(event, newValue);
            }
          }}
          onChangeCommitted={changeSelection}
          valueLabelDisplay="auto"
          getAriaLabel={() => `${label} slider`}
          marks={range(size).map((val) => ({ value: val }))}
          min={0}
          size="small"
          max={size}
          orientation="horizontal"
          style={sliderStyle}
          step={null}
        />
      </Grid>
    </Grid>
  );
};
