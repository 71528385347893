import { Grid } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useMemo } from 'react';
import { PathologyMapResultsCounter } from 'components/PathologyMap/Shared/PathologyMapResultsCounter';
import { useSelector } from 'react-redux';
import { currentRefinementTypeSelector } from 'store/slices/pathologyMapSlice';
import { DefaultPathologyMapTypography } from 'components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography';
import { usePathologyMapFilterPropertiesItemStyles } from './styles';

export const PathologyMapFilterPropertiesItem = ({
  value,
  count,
  selectedFilters,
  refine,
}) => {
  const { classes } = usePathologyMapFilterPropertiesItemStyles();

  const currentRefinementType = useSelector(currentRefinementTypeSelector);

  const isSelected = useMemo(() => {
    return selectedFilters.some(
      (item) =>
        item.attribute === currentRefinementType &&
        item.refinements.some((refinement) => refinement.value === value),
    );
  }, [selectedFilters, currentRefinementType, value]);

  const handleClick = () => {
    refine(currentRefinementType, value);
  };

  const iconStyles = {
    visibility: isSelected ? 'visible' : 'hidden',
  };

  return (
    <Grid
      item
      container
      alignItems="center"
      className={`${classes.filterItem} ${isSelected && classes.selectedItem}`}
      onClick={handleClick}
      justifyContent="space-between"
    >
      <Grid item xs={8}>
        <DefaultPathologyMapTypography textTransform="uppercase">
          {value}
        </DefaultPathologyMapTypography>
      </Grid>
      <Grid item container justifyContent="center" xs={2}>
        <PathologyMapResultsCounter count={count} />
      </Grid>
      <Grid item xs className={classes.icon} style={iconStyles}>
        <Check fontSize="small" />
      </Grid>
    </Grid>
  );
};
