import { Button } from '@mui/material';
import { SAMPLE_EDITOR_URL } from 'constants/urls';
import { useEditButtonStyles } from 'components/SampleEditor/components/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const EditSampleButton = ({ sampleId }) => {
  const { classes } = useEditButtonStyles();

  const handleEditRedirect = () => {
    window.open(`${SAMPLE_EDITOR_URL}?search=${sampleId}`);
  };

  return sampleId ? (
    <Button
      disableRipple
      className={classes.editButton}
      onClick={handleEditRedirect}
    >
      Edit Sample Metadata
      <OpenInNewIcon className={classes.editButtonIcon} />
    </Button>
  ) : null;
};
