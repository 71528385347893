import { CheckboxSamples } from 'components/SampleEditor/CheckboxSamples';
import {
  ORGAN_NAME_FIELD,
  SAMPLE_FIELDS,
  TEXT_AREA_CELL_EDITOR_PARAMS,
} from 'components/SampleEditor/helpers/constants';
import { ColumnHeader } from 'components/SampleEditor/components/ColumnHeader';
import { ProjectCellRenderer } from 'components/SampleEditor/components/ProjectCellRenderer';
import { OrderCellRenderer } from 'components/SampleEditor/components/OrderCellRenderer';
import {
  formatDisplayEmptyValue,
  getKeyByValue,
} from 'components/SampleEditor/helpers/functions';
import {
  MONTH_DAY_YEAR_WITH_COMMA_FORMAT,
  formatDatetimeStringToDates,
} from 'utilities/dates_and_times';

export const getSamplesColumns = (
  setCollapsedOrdersList,
  setCollapsedProjectsList,
  collapsedOrdersList,
  collapsedProjectsList,
  species,
  organs,
  checkboxHandler,
) => [
  {
    field: 'checkbox',
    pinned: 'left',
    headerName: '',
    minWidth: 40,
    width: 40,
    resizable: true,
    cellRenderer: ({ data: { uuid }, node }) => (
      <CheckboxSamples
        id={uuid}
        checked={node.selected}
        handler={() => checkboxHandler(node)}
      />
    ),
  },
  {
    field: 'project_name',
    headerName: SAMPLE_FIELDS.project,
    minWidth: 200,
    resizable: true,
    filter: 'agTextColumnFilter',
    cellRenderer: ({ data }) => (
      <ProjectCellRenderer
        cellData={data}
        setCollapsedProjectsList={setCollapsedProjectsList}
        collapsedProjectsList={collapsedProjectsList}
      />
    ),
  },
  {
    field: 'order_id',
    headerName: SAMPLE_FIELDS.order,
    minWidth: 200,
    resizable: true,
    filter: 'agTextColumnFilter',
    cellRenderer: ({ data }) => (
      <OrderCellRenderer
        cellData={data}
        collapsedOrdersList={collapsedOrdersList}
        setCollapsedOrdersList={setCollapsedOrdersList}
        collapsedProjectsList={collapsedProjectsList}
      />
    ),
  },
  {
    headerName: SAMPLE_FIELDS.name,
    field: 'name',
    pinned: 'left',
    filter: 'agTextColumnFilter',
    minWidth: 200,
    resizable: true,
    cellRenderer: ({ data }) => (
      <span id="no-edit">{formatDisplayEmptyValue(data.name)}</span>
    ),
  },
  {
    headerName: SAMPLE_FIELDS.sampleId,
    field: 'id',
    headerComponent: () => <ColumnHeader name={SAMPLE_FIELDS.sampleId} />,
    minWidth: 200,
    resizable: true,
    cellRenderer: ({ data }) => (
      <span id="no-edit">{formatDisplayEmptyValue(data.id)}</span>
    ),
  },
  {
    headerName: SAMPLE_FIELDS.fixationTime,
    field: 'fixation_time_hours',
    headerComponent: () => <ColumnHeader name={SAMPLE_FIELDS.fixationTime} />,
    minWidth: 200,
    resizable: true,
    cellRenderer: ({ data }) => (
      <span id="no-edit">
        {formatDisplayEmptyValue(data.fixation_time_hours)}
      </span>
    ),
  },
  {
    headerName: SAMPLE_FIELDS.organ,
    field: ORGAN_NAME_FIELD,
    headerComponent: () => <ColumnHeader name={SAMPLE_FIELDS.organ} />,
    minWidth: 200,
    resizable: true,
    editable: true,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: { values: organs.map((item) => item.name) },
    cellRenderer: ({ data }) => <span>{data.organ?.name}</span>,
    cellClass: 'selectCell',
  },
  {
    headerName: SAMPLE_FIELDS.species,
    field: 'species.name',
    headerComponent: () => <ColumnHeader name={SAMPLE_FIELDS.species} />,
    minWidth: 200,
    resizable: true,
    editable: true,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: species.map((item) => item.name),
    },
    cellRenderer: ({ data }) => <span>{data.species?.name}</span>,
    cellClass: 'selectCell',
  },
  {
    headerName: SAMPLE_FIELDS.teamSampleName,
    field: 'alias',
    headerComponent: () => <ColumnHeader name={SAMPLE_FIELDS.teamSampleName} />,
    minWidth: 200,
    resizable: true,
    cellRenderer: ({ data }) => (
      <span>{formatDisplayEmptyValue(data.alias)}</span>
    ),
    ...TEXT_AREA_CELL_EDITOR_PARAMS,
  },
  {
    headerName: SAMPLE_FIELDS.submissionType,
    field: getKeyByValue(SAMPLE_FIELDS.submissionType),
    headerComponent: () => <ColumnHeader name={SAMPLE_FIELDS.submissionType} />,
    valueGetter: ({ data }) => {
      const submissionTypeFormatted = data.submission?.service_type.replaceAll(
        '_',
        ' ',
      );

      return formatDisplayEmptyValue(submissionTypeFormatted);
    },
    minWidth: 200,
    resizable: true,
    cellRenderer: ({ data }) => {
      const submissionTypeFormatted = data.submission?.service_type.replaceAll(
        '_',
        ' ',
      );

      return (
        <span id="no-edit">
          {formatDisplayEmptyValue(submissionTypeFormatted)}
        </span>
      );
    },
  },
  {
    headerName: SAMPLE_FIELDS.submittedDate,
    field: getKeyByValue(SAMPLE_FIELDS.submittedDate),
    headerComponent: () => <ColumnHeader name={SAMPLE_FIELDS.submittedDate} />,
    minWidth: 200,
    resizable: true,
    cellRenderer: ({ data }) => (
      <span id="no-edit">
        {formatDatetimeStringToDates(
          data.modified,
          MONTH_DAY_YEAR_WITH_COMMA_FORMAT,
        )}
      </span>
    ),
  },
  {
    headerName: 'Sample UUID',
    field: 'uuid',
    hide: true,
    minWidth: 200,
    flex: 1,
  },
];
