import { BoltOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { SlideAIModelModal } from 'components/AITools/components/SlideAIModelModal';
import { SlideAIModelSelectableItem } from 'components/AITools/components/SlideAIModelSelectableItem';
import {
  AI_MODEL_CHANGE_ACTIONS,
  AI_MODELS,
  MAP_MODEL_ID_TO_SPECIFIC_ORGAN,
} from 'components/AITools/constants/common';
import {
  MAP_AI_MODEL_TO_DISPLAY_COLOR,
  useContentStyles,
} from 'components/AITools/constants/styles';
import { isModelExists, isQCModel } from 'components/AITools/utilities/common';
import { PercentageSlider } from 'components/Shared/PercantageSlider';
import { PERMISSIONS } from 'constants/permissions';
import { APP_LIBRARY } from 'constants/urls';
import logo from 'images/AITools/histowiz.png';
import { DownloadIcon } from 'images/icons/DownloadIcon';
import { memo, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyDownloadSlideAnnotationsQuery } from 'store/apis/slidesApi';
import {
  addCurrentSlideAIModel,
  removeCurrentSlideAIModel,
  selectCurrentSlideAIModels,
  setLastChangedAIModelOptions,
  setMLDataLoadingMap,
} from 'store/slices/aiToolsSlice';
import {
  forceOpenModelSelector,
  setForceOpenModel,
} from 'store/slices/slidesListSlice/slidesListSlice';
import { isStaffSelector } from 'store/slices/userDetailsSlice';
import { useCheckPermission } from 'utilities/hooks/useCheckPermission/useCheckPermission';

export const ToolsContent = ({
  slide,
  slidesTableProps,
  model,
  opacityOptions,
}) => {
  const { classes } = useContentStyles();
  const [downloadSlideML, { isFetching }] =
    useLazyDownloadSlideAnnotationsQuery();
  const forceOpenModel = useSelector(forceOpenModelSelector);
  const currentSlideAIModels = useSelector(selectCurrentSlideAIModels);
  const dispatch = useDispatch();
  const [modelModalOpen, setModelModalOpen] = useState('');
  const isStaff = useSelector(isStaffSelector);
  const hasModelPermission = useCheckPermission(PERMISSIONS[model.id]);

  const slides = useMemo(
    () =>
      slidesTableProps?.selectedSlides.length
        ? slidesTableProps?.selectedSlides
        : [slide],
    [slide, slidesTableProps],
  );

  const isSelected = (modelName) => currentSlideAIModels.includes(modelName);

  const handleAIModelChange = (modelName) => () => {
    let changeType = AI_MODEL_CHANGE_ACTIONS.ADD;
    const isQC = isQCModel(modelName);

    if (!isSelected(modelName)) {
      dispatch(addCurrentSlideAIModel(modelName));
      if (!isQC) {
        // necessary to avoid blinking of tick before spinner
        dispatch(setMLDataLoadingMap({ modelName, isLoading: true }));
      }
    } else {
      dispatch(removeCurrentSlideAIModel(modelName));
      changeType = 'remove';
    }

    dispatch(setLastChangedAIModelOptions({ modelName, changeType }));
  };

  const handleModelModalOpen = (id, e) => {
    e.stopPropagation();
    setModelModalOpen(id);
  };

  const handleModelModalClose = () => {
    setModelModalOpen('');
  };

  const handleLearnMore = (e) => {
    e.stopPropagation();
    window.open(`${APP_LIBRARY}/${model.id}`, 'blank');
  };

  useEffect(() => {
    if (forceOpenModel === model.id) {
      setModelModalOpen(forceOpenModel);
      dispatch(setForceOpenModel(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceOpenModel]);

  const isModelExistsForAll = useMemo(
    () => slides.every((currentSlide) => isModelExists(currentSlide, model)),
    [model, slides],
  );

  const slidesOrgans = slides.map(({ sample }) => sample?.organ?.name);

  const isAvailable = useMemo(() => {
    if (!hasModelPermission && !isStaff) {
      return false;
    }

    const specificOrgan = MAP_MODEL_ID_TO_SPECIFIC_ORGAN[model.id];

    // this logic is applied only for AIOSYN and AIRA models currently
    if (!specificOrgan) {
      return true;
    }

    return slidesOrgans.some((organ) => organ === specificOrgan);
  }, [slidesOrgans, model, isStaff, hasModelPermission]);

  const handleDownloaSdlideML = async () => {
    const mlData = await downloadSlideML({
      slideUUID: slide.uuid,
      modelId: model.id,
    }).unwrap();

    const jsonString = JSON.stringify(mlData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${model.id}-${slide.name.replace('.svs', '')}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Grid
      container
      direction="column"
      className={!isAvailable && classes.disabledContent}
    >
      {modelModalOpen && (
        <SlideAIModelModal
          slides={slidesTableProps?.slides}
          selectedSlides={slides}
          open={modelModalOpen}
          isProcessed={isModelExistsForAll}
          onClose={handleModelModalClose}
          model={model}
        />
      )}
      <Accordion className={classes.accordion} disableGutters>
        <AccordionSummary className={classes.accordionSummary}>
          <Box className={classes.mlModels}>
            <Typography className={classes.mlModelsHeader}>
              {model?.title}{' '}
              <Typography
                component="span"
                className={classes.learnMore}
                onClick={handleLearnMore}
              >
                Learn More
              </Typography>
            </Typography>
            <Box>
              <Box display="flex" justifyContent="center">
                <img
                  src={model.logo || logo}
                  alt="logo"
                  className={classes.logo}
                />
              </Box>
            </Box>
            <PercentageSlider
              className={classes.percentageSliderWrapper}
              value={opacityOptions.opacity}
              handleChange={opacityOptions.handleChange}
            />
            <Button
              color="secondary"
              variant={isModelExists ? 'outlined' : 'contained'}
              startIcon={isModelExists ? null : <BoltOutlined />}
              className={classes.runButton}
              sx={{
                padding: isModelExists
                  ? '0.625rem 2.68rem'
                  : '0.625rem 2.06rem',
              }}
              onClick={(e) => handleModelModalOpen(model.id, e)}
              title={
                slides.length === 1
                  ? undefined
                  : 'Currently, models can only be run on one slide at a time.'
              }
              disabled={slides.length !== 1} // TODO: single slide only for this release
            >
              {isModelExistsForAll ? 'Order Details' : 'RUN MODEl'}
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid
            item
            container
            spacing={2}
            className={classes.aiModelsListWrapper}
          >
            <Grid
              container
              item
              xs={12}
              className={classes.optionsList}
              spacing={2}
            >
              <Button
                className={classes.downloadButton}
                endIcon={<DownloadIcon />}
                color="secondary"
                variant="contained"
                onClick={handleDownloaSdlideML}
                disabled={!isModelExists(slide, model) || isFetching}
              >
                <Typography className={classes.downloadText}>
                  Download {model.name} ANNOTATIONS
                </Typography>
              </Button>
              {model.options.map((item) => (
                <Grid
                  key={item.name}
                  xs={12}
                  className={classes.gridWrapperItem}
                >
                  <SlideAIModelSelectableItem
                    color={
                      model.id !== AI_MODELS.autoQC &&
                      MAP_AI_MODEL_TO_DISPLAY_COLOR[item.value]
                    }
                    key={item.value}
                    uuid={slide.uuid}
                    slideName={slide.name}
                    // disabled showing quantification for all models for a while
                    showQuantifications={false}
                    name={item.name}
                    isSelected={isSelected(item.value)}
                    handleChange={handleAIModelChange(item.value)}
                    isDisabled={opacityOptions.disabled(item.value)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export const SlideAIToolsContent = memo(ToolsContent);
