import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import {
  AntibodyColumnDefs,
  SpecialStainsColumnDefs,
} from 'components/utilities/AgGridCols/AgGridColumns';
import { antibodiesDetailGridDefaultColDefs } from 'components/AntibodiesList/constants';
import { resizeColumnsToFitScreen } from 'components/utilities/grid';
import { useAGGridOverlayControl } from 'components/utilities/hooks/grid/useAGGridOverlayControl';
import { columnsWithDiscountTooltip } from 'components/AntibodiesList/utilities';
import { AntibodyTableTooltip } from 'components/AntibodiesList/AntibodyTableTooltip';

const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: AntibodyColumnDefs,
    defaultColDef: antibodiesDetailGridDefaultColDefs,
    domLayout: 'autoHeight',
  }, // (notice the comma here!) detailGridOptions is passed onto getDetailRowData
  getDetailRowData: function (params) {
    params.successCallback(params.data.antibodies);
  },
};

export const SpecialStainsTable = ({
  rowData,
  isLoading,
  setDisplayRowData,
  quickFilterText,
}) => {
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = ({ api }) => {
    setGridApi(api);
  };

  useAGGridOverlayControl(isLoading, rowData, gridApi);

  const columnsWithTooltip = columnsWithDiscountTooltip(
    SpecialStainsColumnDefs,
    rowData,
  );

  const defaultColumnsDef = {
    tooltipComponent: AntibodyTableTooltip,
  };

  return (
    <AgGridReact
      onGridReady={onGridReady}
      tooltipMouseTrack={true}
      tooltipShowDelay={100}
      onModelUpdated={setDisplayRowData}
      enableSorting={true}
      enableFilter={true}
      quickFilterText={quickFilterText}
      enableColResize={true}
      defaultColDef={defaultColumnsDef}
      columnDefs={columnsWithTooltip}
      detailCellRendererParams={detailCellRendererParams}
      detailRowHeight={250}
      rowData={rowData}
      onFirstDataRendered={resizeColumnsToFitScreen}
      overlayNoRowsTemplate={'<span>No data</span>'}
      suppressNoRowsOverlay={isLoading}
    />
  );
};
