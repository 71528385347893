import React, { useCallback, useState } from 'react';
import { SlideInfoCardContent } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/SlideInfoCardContent';
import { SlideInfoTabs } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/SlideInfoTabs';
import { NewCard } from 'components/PathologyMap/Modals/FullSlideInfoModal/components/NewCard';
import { SLIDE_METADATA_TAB_VALUE } from 'components/PathologyMap/constants/common';
import { useSlideInfoSectionStyles } from 'components/PathologyMap/Modals/FullSlideInfoModal/styles';

export const SlideInfoCard = ({ slide }) => {
  const { classes } = useSlideInfoSectionStyles();
  const [currentTabValue, setCurrentTabValue] = useState(
    SLIDE_METADATA_TAB_VALUE,
  );

  const handleTabChange = useCallback((_event, tabValue) => {
    setCurrentTabValue(tabValue);
  }, []);

  if (!slide) return null;

  return (
    <NewCard
      header={
        <SlideInfoTabs
          classes={classes}
          currentTabValue={currentTabValue}
          handleTabChange={handleTabChange}
        />
      }
      content={
        <SlideInfoCardContent
          classes={classes}
          slide={slide}
          currentTabValue={currentTabValue}
        />
      }
    />
  );
};
