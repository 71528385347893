import React from 'react';
import Button from '@mui/material/Button';
import shallow from 'zustand/shallow';

import {
  useImageSettingsStore,
  useViewerStore,
} from 'components/IFViewer/state';

export const ZoomLockToggle = () => {
  const [toggleZoomLock, zoomLock] = useImageSettingsStore(
    (store) => [store.toggleZoomLock, store.zoomLock],
    shallow,
  );
  const isViewerLoading = useViewerStore((store) => store.isViewerLoading);
  return (
    <Button
      id={`if-${zoomLock ? 'un' : ''}lock-zoom`}
      disabled={isViewerLoading}
      onClick={toggleZoomLock}
      variant="outlined"
      size="small"
      fullWidth
    >
      {zoomLock ? 'Unlock' : 'Lock'} Zoom
    </Button>
  );
};
