import { Box, Typography } from '@mui/material';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { useActionButtonsStyles } from 'components/pages/AppLibrary/styles';
import { AiTool } from 'components/pages/AppLibrary/components/AiTool';
import { CompletedSlidesButton } from 'components/pages/AppLibrary/components/CompletedSlidesButton';
import { ProcessingButton } from 'components/pages/AppLibrary/components/ProcessingButton';
import { useState } from 'react';
import {
  ERROR_FETCHING_AI_TOOLS_MESSAGE,
  FILTER_BUTTONS,
} from 'components/pages/AppLibrary/constants';
import { AiToolsButton } from 'components/pages/AppLibrary/components/AiToolsButton';
import { FilteredSlides } from 'components/pages/AppLibrary/FilteredSlides';
import { SLIDES_INCREMENT } from 'components/pages/AppLibrary/AiModel/AiModelSlides';
import { useAppLibraryHomeStyles } from 'components/pages/AppLibrary/HomePage/styles';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import { useGetAIToolsContentQuery } from 'store/apis/cmsApi';
import { useSelector } from 'react-redux';
import { aiToolsSelector } from 'store/slices/cmsSlice';
import { getLocalToken } from 'utilities/token';
import { ShowStaffUserOnlyPage } from 'components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnlyPage';

export const AppLibrary = () => {
  useTitle('HistoWiz - App Library');

  const isLoggedIn = !!getLocalToken(); // TODO: temp solution for deploy preview, need to fix auth0
  const { error: AIContentError } = useGetAIToolsContentQuery();
  const aiToolsData = useSelector(aiToolsSelector);
  useQueryError(AIContentError, ERROR_FETCHING_AI_TOOLS_MESSAGE);

  const { classes } = useAppLibraryHomeStyles();
  const { classes: buttonClasses } = useActionButtonsStyles();

  const [activeFilter, setActiveFilter] = useState(FILTER_BUTTONS.aiTools);
  const [currentIndex, setCurrentIndex] = useState(SLIDES_INCREMENT);
  // const [sort, setSort] = useState(SELECT_SORT_OPTIONS.mostMatches);

  // const sortHandler = (event) => {
  //   setSort(event.target.value);
  // };

  const activeFilterHanlder = (currentButton) => {
    if (currentButton === activeFilter) return;
    setCurrentIndex(SLIDES_INCREMENT);
    setActiveFilter(currentButton);
  };

  const getButtonClasses = (currentButton) =>
    currentButton === activeFilter
      ? `${buttonClasses.button} ${buttonClasses.activeButton}`
      : buttonClasses.button;

  const getHeaderTitle = () => {
    switch (activeFilter) {
      case FILTER_BUTTONS.aiTools:
        return 'TOOLS';
      case FILTER_BUTTONS.completed:
        return 'COMPLETED SLIDES';
      case FILTER_BUTTONS.processing:
        return 'IN-PROCESSING';
      default:
        return '';
    }
  };

  return (
    <ShowStaffUserOnlyPage>
      <MiniDrawerWithContext header="App Library">
        <Box className={classes.root}>
          <Box className={classes.titleWrapper}>
            <Typography className={classes.title}>
              AI APP LIBRARY: {getHeaderTitle()}
            </Typography>
            {/* TODO: uncomment when feature will be ready */}

            {/* <Box>
            <span>SORT BY:</span>
            <Select
              value={sort}
              variant="outlined"
              className={classes.select}
              onChange={sortHandler}
            >
              <MenuItem value={SELECT_SORT_OPTIONS.latest}>
                Latest (Modified)
              </MenuItem>
              <MenuItem value={SELECT_SORT_OPTIONS.mostMatches}>
                Most matches
              </MenuItem>
              <MenuItem value={SELECT_SORT_OPTIONS.oldest}>Oldest</MenuItem>
            </Select>
          </Box> */}
          </Box>

          {isLoggedIn && (
            <Box className={classes.buttonsWrapper}>
              <AiToolsButton
                onClick={() => activeFilterHanlder(FILTER_BUTTONS.aiTools)}
                className={getButtonClasses(FILTER_BUTTONS.aiTools)}
              />
              <CompletedSlidesButton
                onClick={() => activeFilterHanlder(FILTER_BUTTONS.completed)}
                className={getButtonClasses(FILTER_BUTTONS.completed)}
              />
              <ProcessingButton
                onClick={() => activeFilterHanlder(FILTER_BUTTONS.processing)}
                className={getButtonClasses(FILTER_BUTTONS.processing)}
              />
            </Box>
          )}
          {activeFilter === FILTER_BUTTONS.aiTools ? (
            <Box className={classes.modelsWrapper}>
              {aiToolsData.map((tool) => (
                <AiTool key={tool.id} aiTool={tool} />
              ))}
            </Box>
          ) : (
            <FilteredSlides
              activeFilter={activeFilter}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              isAppLibrary
            />
          )}
        </Box>
      </MiniDrawerWithContext>
    </ShowStaffUserOnlyPage>
  );
};
