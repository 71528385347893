import React from 'react';
import PolicyIcon from '@mui/icons-material/Policy';
import { BetaIcon } from 'components/Shared/Icons/BetaIcon';

export const PathologyMapSearchIcon = () => {
  return (
    <BetaIcon>
      <PolicyIcon />
    </BetaIcon>
  );
};
