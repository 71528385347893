import { makeStyles } from 'tss-react/mui';
import { ERROR_ICON } from 'utilities/colors';

export const useInviteTeamMembersModalStyles = makeStyles()((theme) => ({
  personIcon: {
    marginRight: '0.75rem',
  },
  modalLabel: {
    fontWeight: 500,
    letterSpacing: '0.1em',
    lineHeight: 1.65,
  },
  modal: {
    width: '1000px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  intro: { marginBottom: '1rem' },
  inviteTable: {
    marginTop: '3rem',
  },
  inputForm: { width: 300 },
  buttonStyle: {
    height: '32px',
    width: 300,
    marginTop: '3rem',
  },
  emailInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorMessage: {
    marginTop: '0.5rem',
    color: ERROR_ICON,
  },
  cardBody: {
    padding: '1rem 1.75rem 1.75rem 1.75rem',
  },
}));
