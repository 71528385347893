export const CHANGES_SAVED_MESSAGE = 'Changes saved';

export const IF_SERVICE_TYPE = 'Immunofluorescence';
export const CHANNEL_FILTER_TYPE = 'Channel Filters';
export const IHC_SERVICE_TYPE = 'IHC';
export const SPECIAL_STAIN_TYPE = 'Special Stains';

export const REQUESTED_H_AND_E_STAIN_COUNT = 'requested_h_and_e_stain_count';
export const REQUESTED_H_AND_E_STAIN_PATH_CONSULT_COUNT =
  'requested_h_and_e_stain_pathology_consultation_count';
export const REQUESTED_H_AND_E_STAIN_IMG_ANALYSIS_COUNT =
  'requested_h_and_e_stain_image_analysis_count';
export const REQUESTED_UNSTAINED_COUNT = 'requested_unstained_count';
export const REQUESTED_SLIDE_SCANNING_ONLY_COUNT =
  'requested_slide_scanning_only_count';
export const REQUESTED_IF_SLIDE_SCANNING_ONLY_COUNT =
  'requested_if_slide_scanning_only_count';
export const REQUESTED_IMAGE_ANALYSIS_ONLY_COUNT =
  'requested_image_analysis_only_count';
export const REQUESTED_PATHOLOGY_CONSULTATION_ONLY_COUNT =
  'requested_pathology_consultation_only_count';

export const REQUESTED_PATHOLOGY_CONSULTATION =
  'requested_pathology_consultation';
export const REQUESTED_IMAGE_ANALYSIS = 'requested_image_analysis';
export const REQUESTED_GROSS_PROCESS_EMBED_ONLY = 'gross_process_embed_only';
export const REQUESTED_SPECIALIZED_BLOCK = 'requested_specialized_block';
export const IA_ATTACHMENT = 'image_analysis_attachment';
export const PATHOLOGY_CONSULTATION_ATTACHMENT =
  'pathology_consultation_attachment';

export const UNSTAINED_TYPE = 'Unstained';
export const H_AND_E_TYPE = 'H&E';
