import moment from 'moment';
import { FULL_DAY_MONTH_YEAR_WITH_COMMA_FORMAT } from 'utilities/dates_and_times';
import { prettifyTrackerState } from 'components/OrderDetails/Tabs/OrderProgressTab/utilities';
import {
  ALL_TAB,
  COMPLETED_STATUSES,
  COMPLETED_TAB,
  IN_PROGRESS_STATUSES,
  IN_PROGRESS_TAB,
  PROCESSING_LOG_STATUS,
  REQUESTED_LOG_STATUS,
} from 'components/TeamOverview/components/AiComputeTransactionsCard/constants';
import { ORDERS_SLIDE_LIST_URL } from 'constants/urls';

export const parseMlModels = (mlModels) =>
  mlModels.reduce((acc, mlModel) => {
    const logs = mlModel.slide_ml_processing_logs;
    const transactions = logs.map((log) => ({
      uuid: log.uuid,
      orderName: log.slide?.order?.name,
      slideName: log.slide?.name,
      toolType: prettifyTrackerState(mlModel.processing_type),
      cost: mlModel.price_per_slide,
      status: log.state,
      slideViewerUrl:
        log.state === COMPLETED_TAB
          ? ORDERS_SLIDE_LIST_URL.replace(':orderUUID', log.slide?.order?.uuid)
          : '',
      precessedOnDate: moment(log.finished_datetime).format(
        FULL_DAY_MONTH_YEAR_WITH_COMMA_FORMAT,
      ),
    }));

    return [...acc, ...transactions];
  }, []);

export const filterMlModelsBySubstring = (stringifiedModelsMap, filter) => {
  const lowerCaseFilter = filter.toLowerCase();

  return stringifiedModelsMap
    .entries()
    .reduce(
      (acc, [modelObject, stringifiedModel]) =>
        stringifiedModel.includes(lowerCaseFilter)
          ? [...acc, modelObject]
          : acc,
      [],
    );
};

export const filterModelsByState = (models, status) =>
  ({
    [ALL_TAB]: models,
    [COMPLETED_TAB]: models.filter((model) =>
      COMPLETED_STATUSES.includes(model.status),
    ),
    [IN_PROGRESS_TAB]: models.filter((model) =>
      IN_PROGRESS_STATUSES.includes(model.status),
    ),
  }[status]);

export const stringifyModels = (models) => {
  const modelsMap = new Map();
  models.forEach((model) => {
    modelsMap.set(model, JSON.stringify(model).toLowerCase());
  });
  return modelsMap;
};
