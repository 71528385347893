import { Box, Typography } from '@mui/material';
import { DataRow } from 'components/TeamOverview/components/ConfirmActionModal/DataRow';
import { useOrderSlidesOverviewStyles } from 'components/TeamOverview/components/ConfirmActionModal/styles';
import React, { useEffect } from 'react';
import {
  flatOrderSlides,
  parseSlides,
} from 'components/TeamOverview/utilities';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { LOADING_STATE } from 'components/TeamOverview/constants';

export const OrderSlidesOverview = ({
  order,
  fetchSlidesForOrder,
  selectedRowsSlides,
}) => {
  const { classes } = useOrderSlidesOverviewStyles();

  useEffect(() => {
    if (!selectedRowsSlides.has(order)) {
      fetchSlidesForOrder(order);
    }
  }, []);

  const prepareSlidesForOrder = (order) => {
    const slides = selectedRowsSlides.get(order);
    return slides === LOADING_STATE
      ? LOADING_STATE
      : parseSlides(flatOrderSlides(slides));
  };

  const parsedSlides = selectedRowsSlides.has(order)
    ? prepareSlidesForOrder(order)
    : [];

  const isSlidesLoading = parsedSlides === LOADING_STATE;

  return (
    <Box>
      <DataRow
        dataUsed="USED"
        slideName="SLIDE NAME"
        fixativeTime="FIXATIVE TIME"
        antibodyName="ANTIBODY"
        sampleName="SAMPLE"
        speciesName="SPECIES"
        stainType="STAIN TYPE"
        organName="ORGAN"
      />
      <Box className={classes.rowsScrollableContainer}>
        {isSlidesLoading ? (
          <Box className={classes.centeredContentContainer}>
            <Spinner size={40} />
          </Box>
        ) : parsedSlides.length ? (
          parsedSlides.map((slide) => <DataRow {...slide} />)
        ) : (
          <Box className={classes.centeredContentContainer}>
            <Typography>No slides</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
