import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { Box, Button, Typography } from '@mui/material';

import {
  useDeleteOrderMutation,
  useDuplicateOrderMutation,
} from 'store/apis/orderApi';

import {
  ORDER_CHART_URL,
  ORDER_DETAILS_URL,
  PLACE_ORDER_INFO_URL,
} from 'constants/urls';
import { DefaultModal } from 'components/Modals/DefaultModal';
import {
  CustomTableCell,
  ORDER_FORM_SUBMITTED_TRACKER_STATE,
  ORDER_NEW_TRACKER_STATE,
  unsubmittedOrderStates,
} from 'components/OrdersList/constants';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Pending as PendingIcon,
  ContentCopy as ContentCopyIcon,
  Timeline as TimelineIcon,
  Equalizer as EqualizerIcon,
  Payment as PaymentIcon,
  Help as HelpIcon,
} from '@mui/icons-material';

import {
  DELETE_ORDER_ERROR_MESSAGE,
  DUPLICATE_ORDER_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { getHashByTabValue } from 'utilities/general';
import { ORDER_PROGRESS_TAB_VALUE } from 'components/OrderDetails/constants';
import { useTableActionsStyles } from 'components/OrdersList/Actions/tableActionsStyles';
import { UpdatePOModal } from 'components/Modals/UpdatePOModal/UpdatePOModal';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import { Link } from 'react-router-dom';
import {
  buildPropsForOrderQuestionsForm,
  buildUrl,
} from 'components/OrdersList/utilities';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const OrderCustomTabActions = ({ row }) => {
  const [duplicateOrderModalOpen, setDuplicateOrderModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updatePOModalOpen, setUpdatePOModalOpen] = useState(false);
  const { showError } = useSnackbar();
  const userDetails = useSelector(userDetailsSelector);

  const { classes } = useTableActionsStyles();
  const history = useHistory();

  const [deleteOrder] = useDeleteOrderMutation();
  const [duplicateOrder] = useDuplicateOrderMutation();

  const {
    uuid: orderUUID,
    requested_image_analysis: isImageAnalysisRequested,
    image_analysis_attachment: isIAFileAttached,
    state: orderState,
    name: orderName,
  } = row;

  const isChartLinkDisplayed = isImageAnalysisRequested && !!isIAFileAttached;

  const deletableOrder = orderState === ORDER_NEW_TRACKER_STATE;
  const isSubmittedOrder = orderState === ORDER_FORM_SUBMITTED_TRACKER_STATE;

  const toggleDeleteModal = () => setDeleteModalOpen((prevState) => !prevState);
  const toggleDuplicateModal = () =>
    setDuplicateOrderModalOpen((prevState) => !prevState);
  const toggleUpdatePOModal = () =>
    setUpdatePOModalOpen((prevState) => !prevState);

  const onDeleteAction = () => {
    deleteOrder(orderUUID)
      .then(() => toggleDeleteModal())
      .catch(() => showError(DELETE_ORDER_ERROR_MESSAGE));
  };

  const duplicateOrderAction = () => {
    toggleDuplicateModal();
    duplicateOrder(orderUUID)
      .then(({ data }) => {
        const duplicatedOrderUUID = data.uuid;
        const url = PLACE_ORDER_INFO_URL.replace(
          ':orderUUID',
          duplicatedOrderUUID,
        );
        history.push(url);
      })
      .catch(() => showError(DUPLICATE_ORDER_ERROR_MESSAGE));
  };

  const onEditAction = () => {
    const url = PLACE_ORDER_INFO_URL.replace(':orderUUID', orderUUID);
    history.push(url);
  };

  const onViewAction = () => {
    const url = ORDER_DETAILS_URL.replace(':orderUUID', orderUUID);
    history.push(url);
  };

  const onTrackOrderAction = () => {
    const orderDetailsURL = ORDER_DETAILS_URL.replace(':orderUUID', orderUUID);
    const hash = getHashByTabValue(ORDER_PROGRESS_TAB_VALUE);

    history.push(orderDetailsURL + hash);
  };

  const onViewChart = () => {
    const url = ORDER_CHART_URL.replace(':orderUUID', orderUUID);
    history.push(url);
  };

  const DuplicateOrderModal = () => (
    <DefaultModal open={duplicateOrderModalOpen} onClose={toggleDuplicateModal}>
      <Typography
        variant="h4"
        id="modal-title"
        className={classes.typographyMarginBottom}
      >
        Duplicate {orderName}?
      </Typography>
      <Typography variant="h5" id="simple-modal-description">
        This will create a NEW UNSUBMITTED order with details from this order.
      </Typography>
      <span className={classes.deleteButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={duplicateOrderAction}
        >
          Duplicate Order
        </Button>
      </span>
    </DefaultModal>
  );

  const DeleteOrderModal = () => (
    <DefaultModal open={deleteModalOpen} onClose={toggleDeleteModal}>
      <Typography
        variant="h6"
        id="modal-title"
        className={classes.typographyMarginBottom}
      >
        Delete {orderName}?
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        Warning : This will permanently delete this order.
      </Typography>
      <span className={classes.deleteButton}>
        <Button variant="contained" color="primary" onClick={onDeleteAction}>
          Delete Order
        </Button>
      </span>
    </DefaultModal>
  );

  const OrderActionBox = ({ id = '', action, icon, title }) => (
    <Box id={id} onClick={action} display={'flex'} flexDirection={'row'}>
      {icon}
      <Typography className={classes.typographyActionStyle}>{title}</Typography>
    </Box>
  );

  const orderQuestionLink = useMemo(() => {
    const orderFormLinkProps = buildPropsForOrderQuestionsForm(
      userDetails,
      row,
    );

    return buildUrl(orderFormLinkProps);
  }, [userDetails, row]);

  const isOrderQuestionsLinkVisible =
    !unsubmittedOrderStates.includes(orderState);

  return (
    <CustomTableCell>
      {isSubmittedOrder || deletableOrder ? (
        <OrderActionBox
          action={onEditAction}
          icon={<EditIcon className={classes.actionIcon} />}
          title="Edit order"
        />
      ) : (
        <OrderActionBox
          action={onViewAction}
          icon={<PendingIcon className={classes.actionIcon} />}
          title="Details"
        />
      )}
      {deletableOrder && (
        <OrderActionBox
          action={toggleDeleteModal}
          icon={<DeleteIcon className={classes.actionIcon} />}
          title="Delete order"
        />
      )}
      <OrderActionBox
        action={toggleDuplicateModal}
        icon={<ContentCopyIcon className={classes.actionIcon} />}
        title="Duplicate Order"
      />
      {!deletableOrder && (
        <>
          <OrderActionBox
            id="track-order-page"
            action={onTrackOrderAction}
            icon={<TimelineIcon className={classes.actionIcon} />}
            title="Track order"
          />
          <OrderActionBox
            id="submit-po"
            action={toggleUpdatePOModal}
            icon={<PaymentIcon className={classes.actionIcon} />}
            title="Submit PO"
          />
          <UpdatePOModal
            open={updatePOModalOpen}
            onClose={toggleUpdatePOModal}
            orderUUID={row.uuid}
          />
        </>
      )}
      {isChartLinkDisplayed && (
        <OrderActionBox
          action={onViewChart}
          icon={<EqualizerIcon className={classes.actionIcon} />}
          title="Slides Chart"
        />
      )}
      {isOrderQuestionsLinkVisible && (
        <Link to={{ pathname: orderQuestionLink }} target={'_blank'}>
          <Box display={'flex'} flexDirection={'row'}>
            <HelpIcon className={classes.actionIcon} />
            <Typography className={classes.typographyActionStyle}>
              Order Question
            </Typography>
          </Box>
        </Link>
      )}
      <DuplicateOrderModal />
      <DeleteOrderModal />
    </CustomTableCell>
  );
};
