import { EMAIL_VALIDATION_MESSAGE } from 'constants/errorMessages';
import { EMAIL_VALIDATION_REGULAR } from 'utilities/auth';
import { string, object } from 'yup';

export const stringRequirements = string().trim();

export const contactsSchema = object().shape({
  billingContactPONumber: stringRequirements.trim().required('Required!'),
  billingContactName: stringRequirements.required('Required!'),
  billingContactPhoneNumber: stringRequirements.required('Required!'),
  billingContactEmail: stringRequirements
    .required('Required!')
    .matches(EMAIL_VALIDATION_REGULAR, EMAIL_VALIDATION_MESSAGE),
});
