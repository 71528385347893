import { Grid, Typography, Box } from '@mui/material';
import { CircularChart } from 'components/TeamOverview/components/CircularChartOverview/CircularChart';
import React from 'react';
import { useCircularChartOverviewStyles } from 'components/TeamOverview/components/CircularChartOverview/styles';

const UsageLabel = ({ classes, color, label, value }) => (
  <Box className={classes.legendRow}>
    <Box style={{ backgroundColor: color }} className={classes.square} />
    <Box>
      <Typography fontSize="0.75rem">{label} Slides</Typography>
      <Typography fontSize="0.75rem" fontWeight={500}>
        {value}%
      </Typography>
    </Box>
  </Box>
);

export const CircularChartOverview = ({
  dataset,
  totalData,
  totalDataLabel,
  legendData,
}) => {
  const { classes } = useCircularChartOverviewStyles();

  return (
    <Grid container className={classes.dataUsageCardContent}>
      <Grid className={classes.chartWrapper}>
        <Box className={classes.commonData}>
          <Typography fontSize={'1rem'} className={classes.greyText}>
            {totalDataLabel}
          </Typography>
          <Typography fontSize={'1rem'} fontWeight={500}>
            {totalData}
          </Typography>
        </Box>
        <CircularChart dataset={dataset} />
      </Grid>
      <Grid className={classes.legendWrapper} height={'max-content'}>
        {legendData.map(({ name, color, percent }) => (
          <UsageLabel
            key={name}
            classes={classes}
            color={color}
            label={name}
            value={percent}
          />
        ))}
      </Grid>
    </Grid>
  );
};
