export const FILTER_BUTTONS = {
  applicable: 'applicable',
  completed: 'completed',
  processing: 'in_progress',
  aiTools: 'aiTools',
};

export const SELECT_SORT_OPTIONS = {
  mostMatches: 'most matches',
  latest: 'latest',
  oldest: 'oldest',
};

export const ML_COMPLETION_MARKER_FIELDS = [
  'ml_tissue_data_state',
  'ml_inflammation_data_state',
  'ml_necrosis_data_state',
  'ml_stroma_data_state',
  'ml_tumor_data_state',
];

export const ERROR_FETCHING_AI_TOOLS_MESSAGE = 'Error fetching AI tools';

export const COMPUTE_COST_LABEL = 'Compute Cost';
