import {
  AI_MODEL_EVENTS,
  AI_MODELS,
  AI_MODELS_STATES,
  AIOSYN_KIDNEY_AI_MODELS,
  AIRA_LIVER_AI_MODELS,
  QC_AI_MODELS,
  TISSUE_AI_MODELS,
} from 'components/AITools/constants/common';

export const getShortModelName = (modelName) =>
  modelName.replace(/^ml_/, '').replace(/_data$/, '');

export const isSlideTissueMLDataAvailable = (slide, model) => {
  const attribute = `${model}_state`;

  return slide[attribute] === AI_MODELS_STATES.EXISTS;
};

export const isQCModelExists = (slide, modelName) =>
  !!slide?.additional_data?.[modelName]?.regions;

export const isQCModel = (modelName) =>
  Object.values(QC_AI_MODELS).includes(modelName);

export const isTissueModel = (modelName) =>
  Object.values(TISSUE_AI_MODELS).includes(modelName);

export const isAiraModel = (modelName) =>
  Object.values(AIRA_LIVER_AI_MODELS).includes(modelName);

export const isAiosynModel = (modelName) =>
  Object.values(AIOSYN_KIDNEY_AI_MODELS).includes(modelName);

export const raiseOpacityChangeEvent = ({
  airaOpacity,
  aiosynOpacity,
  tissueOpacity,
  qcOpacity,
  segmentationOpacity,
}) => {
  document.dispatchEvent(
    new CustomEvent(AI_MODEL_EVENTS.OPACITY_CHANGE, {
      detail: {
        airaOpacity,
        aiosynOpacity,
        tissueOpacity,
        qcOpacity,
        segmentationOpacity,
      },
    }),
  );
};

export const getAnalysisType = (modelId) => {
  switch (modelId) {
    case AI_MODELS.aira:
      return 'aira_liver';
    case AI_MODELS.aiosyn:
      return 'aiosyn_kidney';
    case AI_MODELS.tissueSegmentation:
      return 'tissue_segmentation';
    case AI_MODELS.autoQC:
      return 'quality_control';
    case AI_MODELS.semanticSegmentation:
      return 'histomorpho';
    default:
      return '';
  }
};

export const filterComputedSlides = (data, state, model) => {
  const filterData = model
    ? data.filter((log) => log.processing_type === getAnalysisType(model))
    : data;

  const slides =
    filterData
      ?.filter((log) => log.state === state)
      .map((log) => log.slides)
      .flat() || [];

  const uniqueSlidesMap = slides.reduce((a, c) => {
    a.set(c.uuid, c);

    return a;
  }, new Map());

  return [...uniqueSlidesMap.values()];
};

export const isModelExists = (slide, model) => {
  if (model.id === AI_MODELS.autoQC) {
    return isQCModelExists(slide, model.options[0].value);
  }

  const modelState = `${model?.options?.[0]?.value}_state`;

  return slide[modelState] === AI_MODELS_STATES.EXISTS;
};
