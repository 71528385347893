export const AtrIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_728_322"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_728_322)">
      <path
        d="M6 20C5.16667 20 4.45833 19.7083 3.875 19.125C3.29167 18.5417 3 17.8333 3 17C3 16.1667 3.29167 15.4583 3.875 14.875C4.45833 14.2917 5.16667 14 6 14C6.83333 14 7.54167 14.2917 8.125 14.875C8.70833 15.4583 9 16.1667 9 17C9 17.8333 8.70833 18.5417 8.125 19.125C7.54167 19.7083 6.83333 20 6 20ZM18 20C17.1667 20 16.4583 19.7083 15.875 19.125C15.2917 18.5417 15 17.8333 15 17C15 16.1667 15.2917 15.4583 15.875 14.875C16.4583 14.2917 17.1667 14 18 14C18.8333 14 19.5417 14.2917 20.125 14.875C20.7083 15.4583 21 16.1667 21 17C21 17.8333 20.7083 18.5417 20.125 19.125C19.5417 19.7083 18.8333 20 18 20ZM12 10C11.1667 10 10.4583 9.70833 9.875 9.125C9.29167 8.54167 9 7.83333 9 7C9 6.16667 9.29167 5.45833 9.875 4.875C10.4583 4.29167 11.1667 4 12 4C12.8333 4 13.5417 4.29167 14.125 4.875C14.7083 5.45833 15 6.16667 15 7C15 7.83333 14.7083 8.54167 14.125 9.125C13.5417 9.70833 12.8333 10 12 10Z"
        fill={color}
      />
    </g>
  </svg>
);
