export const DEFAULT_ORDER_DETAILS = {
  ready: true,
  orderUUID: null,
  turnaroundTime: { label: 'Ten Days', value: 10 },
  sectionsPerSlide: 1,
  fixationTimeHours: '',
  materials_at_histowiz: { label: 'No', value: false },
  cutting_instructions: { label: 'N/A', value: 'n/a' },
  additionalStepsPerSample: 0,
  sectionThicknessMicrons: 4,
  ihc_optimization_type: { value: '', label: 'N/A' },
  project: { value: '', label: '' },
};

export const EXPECTED_PHENOTYPE = 'expected_phenotype';
export const EXPERIMENTAL_TREATMENT = 'experimental_treatment';
export const EXPECTED_STAINING_PATTERN = 'expected_staining_pattern';
export const CELL_TARGET = 'cell_target';

export const CREATE_PROJECT_ACTION = 'CREATE_PROJECT';

export const NO_TEAMS_ERROR_MESSAGE =
  'To update/create an order you should be assigned to at least one team. Please contact help at 347-221-1020.';

export const UNABLE_CREATE_ORDER_MESSAGE =
  'Order was unable to be updated/created. Please contact help at 347-221-1020.';

export const SHOW_CUTTING_INSTRUCTIONS_MODAL = 'showCuttingInstructionsModal';
export const SHOW_PATHOLOGY_MAP_MODAL = 'showPathologyMapModal';
