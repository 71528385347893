import {
  CREATED_DATE_SORT,
  DISPLAYED_ROWS_COUNT,
  FAVORITE_SLIDES_URL_KEY,
  MODIFIED_DATE_SORT,
  NAME_SORT,
  SLIDES_COUNT_SORT,
} from 'components/SlideGroups/utilities/constants';
import {
  PATHOLOGY_MAP_SEARCH_2,
  SLIDE_GROUPS_ROUTE,
  SLIDES_LIST_URL_2,
} from 'constants/urls';
import { FAVORITE_SLIDES_GROUP, sortOrder } from 'utilities/constants';

export const getSlideGroupViewerURL = ({ slide_group_uuid }) => {
  const currentUrl = window.location.origin;

  const slideGroupURL = SLIDE_GROUPS_ROUTE.replace(
    ':slideGroupUUID',
    slide_group_uuid,
  );
  return `${currentUrl}${slideGroupURL}`;
};

export const slideGroupsSorter = (sortBy, a, b) => {
  const sorts = {
    [CREATED_DATE_SORT]: new Date(b.created) - new Date(a.created),
    [MODIFIED_DATE_SORT]: new Date(b.modified) - new Date(a.modified),
    [NAME_SORT]: b.name?.localeCompare(a.name),
    [SLIDES_COUNT_SORT]: b.slides?.length - a.slides?.length,
  };

  if (a.name === FAVORITE_SLIDES_GROUP) return -1;

  if (b.name === FAVORITE_SLIDES_GROUP) return 1;

  return sorts[sortBy];
};

export const prepareVisibleItems = (slides) => {
  if (slides) {
    return slides.reduce((acc, slide) => {
      acc[slide.uuid] = false;
      return acc;
    }, {});
  } else {
    return {};
  }
};

export const checkIfSlideGroupContainSelectedSlides = (
  slideGroup,
  selectedSlides,
) =>
  selectedSlides.every(
    (slideFromGroup) =>
      !!slideGroup.slides.find((slide) => slideFromGroup.uuid === slide.uuid),
  );

export const filterSlideGroupsThatContainSelectedSlides = (
  slideGroups,
  selectedSlides,
) =>
  slideGroups.filter((slideGroup) =>
    checkIfSlideGroupContainSelectedSlides(slideGroup, selectedSlides),
  );

export const handleClickOnSlideGroupCard = (slideGroup, isStaff) => {
  const { slides, isFavorite } = slideGroup;

  let redirectUrl;

  if (!slides.length) {
    redirectUrl = isStaff ? PATHOLOGY_MAP_SEARCH_2 : SLIDES_LIST_URL_2;
  } else {
    redirectUrl = getSlideGroupViewerURL({
      slide_group_uuid: isFavorite ? FAVORITE_SLIDES_URL_KEY : slideGroup.uuid,
    });
  }

  window.open(redirectUrl, '_blank');
};

export const handleSortClick = (setSort, field) => {
  const reverseOrder = (order) => {
    return order === sortOrder.DESC ? sortOrder.ASC : sortOrder.DESC;
  };

  setSort((prevSort) => ({
    field,
    order:
      prevSort.field === field ? reverseOrder(prevSort.order) : prevSort.order,
  }));
};

export const ORDER_NAME_SORT_FIELD = 'order_name_sort';
export const ORGAN_SORT_FIELD = 'organ_sort';
export const SPECIES_FIELD = 'spices_sort';
export const ANTIBODIES_FIELD = 'antibodies_sort';
export const XENOGRAFTS_FIELD = 'xenografts_sort';
export const STAIN_TYPE_SORT_FIELD = 'stain_type_sort';

export const slidesSorter = (sortBy, a, b) => {
  const sorts = {
    [ORDER_NAME_SORT_FIELD]: b.name?.localeCompare(a.name),
    [STAIN_TYPE_SORT_FIELD]: b.stain_type?.localeCompare(a.stain_type),
    [ORGAN_SORT_FIELD]: b.organ?.name?.localeCompare(a.organ?.name),
    [SPECIES_FIELD]: b.species?.name?.localeCompare(a.species?.name),
    [ANTIBODIES_FIELD]: b.antibody?.name?.localeCompare(a.antibody?.name),
    [XENOGRAFTS_FIELD]: b.sample?.science_info?.xenografts?.[0]?.localeCompare(
      a.sample?.science_info?.xenografts?.[0],
    ),
  };

  return sorts[sortBy];
};

export const makeSlidesExpandable = (slides) => {
  const moreRowsCount = slides.length - DISPLAYED_ROWS_COUNT;
  const visibleRows = slides.slice(0, DISPLAYED_ROWS_COUNT);
  const invisibleRows = slides.slice(-1 * moreRowsCount);

  return {
    visibleRows,
    invisibleRows,
  };
};
