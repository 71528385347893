import { Box, Typography } from '@mui/material';
import { useAnalysisModelsStyles } from 'components/pages/AppLibrary/styles';
import { useEffect, useRef, useState } from 'react';

export const Blog = ({ model }) => {
  const { classes } = useAnalysisModelsStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef();
  const showToggleValue =
    wrapperRef.current?.scrollHeight > wrapperRef.current?.offsetHeight;
  const [showToggle, setShowToggle] = useState(showToggleValue);

  const expandHandler = () => {
    setIsExpanded(true);
  };

  const collapseHandler = () => {
    setIsExpanded(false);
    wrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const wrapperStyles = isExpanded
    ? `${classes.wrapper} ${classes.expandedText}`
    : classes.wrapper;

  useEffect(() => {
    setShowToggle(showToggleValue);
  }, [isExpanded, wrapperRef]);

  return (
    <Box>
      <Box id="#description" className={wrapperStyles} ref={wrapperRef}>
        <h1>Description</h1>
        <p>{model.description}</p>
        {!!model.toolReferences?.length && (
          <>
            <h2>Reference(s):</h2>
            <ul>
              {model.toolReferences.map(({ reference }) => (
                <li key={reference}>
                  <a href={reference}>{reference}</a>
                </li>
              ))}
            </ul>
          </>
        )}
      </Box>
      {showToggle && !isExpanded && (
        <Typography onClick={expandHandler} className={classes.readMore}>
          Read more...
        </Typography>
      )}
      {isExpanded && (
        <Typography onClick={collapseHandler} className={classes.readMore}>
          show less...
        </Typography>
      )}
    </Box>
  );
};
