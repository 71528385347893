import React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useGlpFormStyles } from 'components/GlpForm/styles';
import { getGlpDate } from 'components/GlpForm/helpers/functions';

export const GlpDate = ({ title, value, onChange }) => {
  const { classes } = useGlpFormStyles();
  const { field } = classes;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={dayjs(value)}
        onChange={(value) => onChange(getGlpDate(value))}
        className={field}
        label={`${title} *`}
      />
    </LocalizationProvider>
  );
};
