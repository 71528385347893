import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  useImageSettingsStore,
  useViewerStore,
} from 'components/IFViewer/state';
import { useImage } from 'components/IFViewer/hooks';
import { DARK_IF_THEME, useIfViewerStyles } from 'components/IFViewer/styles';
import {
  DEFAULT_PRESETS,
  DEFAULT_ZOOM_LEVEL,
  LEFT_VIEW_LAYER,
  RIGHT_VIEW_LAYER,
  ZOOM_LEVELS,
  ZOOM_STEP,
} from 'components/IFViewer/constants';
import debounce from 'lodash/debounce';
import { Viewer } from 'components/IFViewer/components/Viewer';
import {
  useLazyGetIFSlideByUUIDQuery,
  useLazyGetSharedIFSlideByUUIDQuery,
  useGetIFSlidesByOrderQuery,
} from 'store/apis/ifSlideApi';
import { DraggableController } from 'components/IFViewer/components/Controller/DraggableController';
import { useHistory } from 'react-router-dom';
import { Toolbars } from 'components/IFViewer/components/Toolbars';
import { AgGridReact } from 'ag-grid-react';
import {
  agGridDefaultColDefFloatingFilter,
  selectGridRow,
} from 'components/utilities/grid';
import {
  IFSlideAdminColumnsDefs,
  IFSlideColumnsDefs,
} from 'components/utilities/AgGridCols/AgGridColumns';
import Grid from '@mui/material/Grid';
import {
  getMultiContextMenuItemsIFViewer,
  useGetSingleContextMenuItemsIFGrid,
} from 'components/OrderDetails/Tabs/utilities';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import { getOrderByUUID } from 'services/resources/orders';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { IFHeader } from 'components/IFViewer/IFHeader/IFHeader';
import {
  areLayersLoaded,
  makeAndDownloadScreenshot,
} from 'components/IFViewer/helpers/functions';
import {
  useIFInitSource,
  useIFSlideShare,
  useIFTable,
} from 'components/IFViewer/helpers/hooks';
import { IF_VIEWER_URL } from 'constants/urls';

export const IFViewer = () => {
  const [selectedSlide, setSelectedSlide] = useState(null);

  const { slideUUID, orderUUID, shareUUID } = useParams();
  const { showError } = useSnackbar();
  const { tableRef, tableWidth, setHasTable, hasTable } = useIFTable();

  const currentOrderId = orderUUID ?? selectedSlide?.order?.uuid;
  const { data: slides } = useGetIFSlidesByOrderQuery(currentOrderId, {
    skip: !currentOrderId,
  });

  const share = useIFSlideShare(selectedSlide, orderUUID);
  const history = useHistory();
  const deckRef = useRef(null);
  const wrapperRef = useRef(null);
  const userDetails = useSelector(userDetailsSelector);
  const [isStaff, setIsStaff] = useState(false);
  const [order, setOrder] = useState();
  const [areLayersLoading, setAreLayersLoading] = useState(true);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [gridApi, setGridApi] = React.useState(null);
  const [pan, setPan] = useState();
  const [zoomLeft, setZoomLeft] = useState();
  const [zoomRight, setZoomRight] = useState();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const fullScreenHandle = useFullScreenHandle();
  const isIFSocialSharingPage =
    history.location.pathname.includes('shared_social');

  useEffect(() => {
    // ag-grid caches a some functions, which causes an issue because by default for context we say is not a staff
    if (userDetails.isStaff) {
      setIsStaff(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (currentOrderId && !isIFSocialSharingPage) {
      getOrderByUUID(currentOrderId).then((r) => setOrder(r));
    }
  }, [currentOrderId]);

  useEffect(() => {
    setIsFullscreen(fullScreenHandle.active);
  }, [fullScreenHandle.active]);

  const [getIFSlide] = useLazyGetIFSlideByUUIDQuery();
  const [getSharedIFSlide] = useLazyGetSharedIFSlideByUUIDQuery();

  const getSlide = slideUUID ? getIFSlide : getSharedIFSlide;

  const updateSelectedSlideAndPreferences = (slide) => {
    if (!slide) return;

    setSelectedSlide(slide);

    const { preferences } = slide?.order ?? {};

    if (preferences) {
      useViewerStore.setState({
        customChannelOptions: { ...DEFAULT_PRESETS, ...preferences },
      });
    }
  };

  useEffect(() => {
    if ((!slides?.length || selectedSlide) && !shareUUID) return;
    const isSlideUUIDValid = !!slides?.find((s) => s.uuid === slideUUID);
    const defaultIfSlideUUID = isSlideUUIDValid ? slideUUID : null;

    if (defaultIfSlideUUID || shareUUID) {
      getSlide(defaultIfSlideUUID ?? shareUUID)
        .then(({ data }) => {
          updateSelectedSlideAndPreferences(data);
        })
        .catch((err) => showError(err));
    } else {
      updateSelectedSlideAndPreferences(slides[0]);
    }
  }, [slideUUID, shareUUID, slides]);

  const isViewerLoading = useViewerStore((store) => store.isViewerLoading);
  const source = useViewerStore((store) => store.source);
  const useLinkedView = useViewerStore((store) => store.useLinkedView);
  const loaderError = useViewerStore((store) => store.loaderError);
  const [zoomLock] = useImageSettingsStore((store) => [store.zoomLock]);

  const viewerWidth = wrapperRef.current?.clientWidth;

  const { classes } = useIfViewerStyles({ viewerWidth: viewerWidth });

  const getSingleContextMenuItemsIFGrid = useGetSingleContextMenuItemsIFGrid();

  const viewerRef = useRef();
  const selectedRowUuid = selectedSlide?.uuid ?? slideUUID;

  // TODO: refactor useImage
  useImage(source, selectedRowUuid);
  useIFInitSource(selectedSlide);

  const handleSelectRow = ({ api }) => {
    if (!api) return;

    selectGridRow(api, ({ id, data }) => {
      if (data.uuid === selectedRowUuid) {
        api.ensureIndexVisible(+id);
        return true;
      }

      return false;
    });
  };

  const onViewerRender = () => {
    const areAllLayersLoaded = areLayersLoaded(deckRef);
    areAllLayersLoaded === areLayersLoading &&
      setAreLayersLoading(!areAllLayersLoaded);
  };

  const getContextMenuItems = () => (params) => {
    if (!params.node?.data) {
      return [];
    }

    const row = params.node.data;
    const rowsSelected = params.api.getSelectedRows();

    if (rowsSelected.length > 1) {
      return getMultiContextMenuItemsIFViewer({
        rows: rowsSelected,
      });
    } else {
      return getSingleContextMenuItemsIFGrid({ row, isStaff });
    }
  };

  const updateZoomLevel = (pan, zoomLevel) => {
    switch (pan) {
      case LEFT_VIEW_LAYER:
        setZoomLeft(zoomLevel);
        zoomLock && setZoomRight(zoomLevel);
        break;
      case RIGHT_VIEW_LAYER:
        setZoomRight(zoomLevel);
        zoomLock && setZoomLeft(zoomLevel);
        break;
      default:
        setCurrentZoomLevel(zoomLevel);
    }
  };

  const getCurrentZoom = (pan) => {
    switch (pan) {
      case LEFT_VIEW_LAYER:
        return zoomLeft || DEFAULT_ZOOM_LEVEL;
      case RIGHT_VIEW_LAYER:
        return zoomRight || DEFAULT_ZOOM_LEVEL;
      default:
        return currentZoomLevel || DEFAULT_ZOOM_LEVEL;
    }
  };

  const onRowSelected = ({ node, data }) => {
    if (!selectedSlide || !data || selectedSlide?.uuid === data?.uuid) return;

    const newPath = IF_VIEWER_URL.replace(':slideUUID', data.uuid).replace(
      ':orderUUID',
      currentOrderId,
    );
    setAreLayersLoading(true);
    history.replace(newPath);
    node.selected && data && setSelectedSlide(data);
  };

  const handleZoomIn = useCallback(
    (pan) => {
      setPan(pan);
      const zoomLevel = getCurrentZoom(pan);

      const maxZoomLevel = ZOOM_LEVELS[ZOOM_LEVELS.length - 1];
      const updatedZoomLevel =
        zoomLevel >= maxZoomLevel ? maxZoomLevel : zoomLevel + ZOOM_STEP;

      updateZoomLevel(pan, updatedZoomLevel);
    },
    [zoomRight, zoomLeft, currentZoomLevel],
  );

  const handleZoomOut = useCallback(
    (pan) => {
      setPan(pan);
      const zoomLevel = getCurrentZoom(pan);

      const minZoomLevel = ZOOM_LEVELS[0];
      const updatedZoomLevel =
        zoomLevel <= minZoomLevel ? minZoomLevel : zoomLevel - ZOOM_STEP;
      updateZoomLevel(pan, updatedZoomLevel);
    },
    [zoomRight, zoomLeft, currentZoomLevel],
  );

  useEffect(() => {
    if (gridApi) {
      handleSelectRow({ api: gridApi });
    }
  }, [slides, selectedSlide, gridApi]);

  return (
    <MiniDrawerWithContext
      header="HistoWiz - Order IF Viewer"
      hideDrawer={isIFSocialSharingPage}
    >
      {order && (
        <IFHeader
          slide={selectedSlide}
          order={order}
          share={share}
          setHasTable={setHasTable}
          hasTable={hasTable}
          screenshot={() =>
            makeAndDownloadScreenshot(
              deckRef,
              setAreLayersLoading,
              order,
              selectedSlide,
              setIsDownloadLoading,
              showError,
            )
          }
          isDownloadLoading={isDownloadLoading}
          areLayersLoading={areLayersLoading}
        />
      )}
      <Grid container className={classes.container}>
        <ThemeProvider theme={DARK_IF_THEME}>
          <Grid ref={wrapperRef} item md={hasTable && order ? 9 : 12}>
            <div className={classes.wrapper}>
              {areLayersLoading && (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              )}
              <div className={classes.headerContainer}>
                <Toolbars
                  isFullscreen={isFullscreen}
                  classes={classes}
                  useLinkedView={useLinkedView}
                  zoomLeft={zoomLeft}
                  zoomRight={zoomRight}
                  currentZoomLevel={currentZoomLevel}
                  setPan={setPan}
                  handleZoomIn={handleZoomIn}
                  handleZoomOut={handleZoomOut}
                  updateZoomLevel={updateZoomLevel}
                  getCurrentZoom={getCurrentZoom}
                  setCurrentZoomLevel={setCurrentZoomLevel}
                  fullScreenHandle={fullScreenHandle}
                  viewerRef={viewerRef}
                />
              </div>
              {!isIFSocialSharingPage && (
                <div className={classes.backBtnContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.backButton}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Go Back
                  </Button>
                </div>
              )}
              {!loaderError && !isViewerLoading && (
                <div
                  onMouseDown={(e) => {
                    // Force default cursor style on rmb press
                    if (e.button === 2) {
                      const overlayWrapper =
                        document.getElementById('deckgl-overlay');
                      overlayWrapper.style.cursor = 'crosshair';
                    }
                  }}
                  onMouseUp={(e) => {
                    if (e.button === 2) {
                      const overlayWrapper =
                        document.getElementById('deckgl-overlay');
                      overlayWrapper.style.cursor = '';
                    }
                  }}
                  className={classes.viewerContainer}
                >
                  <FullScreen handle={fullScreenHandle}>
                    {fullScreenHandle.active && (
                      <Toolbars
                        isFullscreen={isFullscreen}
                        classes={classes}
                        useLinkedView={useLinkedView}
                        zoomLeft={zoomLeft}
                        zoomRight={zoomRight}
                        currentZoomLevel={currentZoomLevel}
                        setPan={setPan}
                        updateZoomLevel={updateZoomLevel}
                        getCurrentZoom={getCurrentZoom}
                        handleZoomIn={handleZoomIn}
                        handleZoomOut={handleZoomOut}
                        setCurrentZoomLevel={setCurrentZoomLevel}
                        fullScreenHandle={fullScreenHandle}
                        viewerRef={viewerRef}
                      />
                    )}
                    <Viewer
                      isFullscreen={isFullscreen}
                      ref={viewerRef}
                      deckRef={deckRef}
                      onRerender={debounce(onViewerRender, 250)}
                      pan={pan}
                      zoomLevelForUI={currentZoomLevel}
                      setZoomLevelForUI={setCurrentZoomLevel}
                      updateZoomLevel={updateZoomLevel}
                      zoomLevelLeftForUI={zoomLeft}
                      zoomLevelRightForUI={zoomRight}
                    />
                  </FullScreen>
                  <DraggableController
                    wrapperRef={wrapperRef}
                    classes={classes}
                    slideData={selectedSlide}
                    tableWidth={tableWidth}
                    hasTable={hasTable}
                    isIFSocialSharingPage={isIFSocialSharingPage}
                  />
                </div>
              )}
              {/*Temporary commented */}
              {/*{!useLinkedView && <Footer />}*/}
            </div>
          </Grid>
        </ThemeProvider>
        {order && (
          <Grid ref={tableRef} item md={hasTable ? 3 : 0}>
            <div
              className={`${classes.table} ${
                !hasTable ? classes.hiddenTable : ''
              } ag-theme-balham`}
            >
              <AgGridReact
                onGridReady={({ api }) => setGridApi(api)}
                columnDefs={
                  isStaff ? IFSlideAdminColumnsDefs : IFSlideColumnsDefs
                }
                defaultColDef={agGridDefaultColDefFloatingFilter}
                rowData={slides}
                enableColResize={true}
                sideBar={false}
                rowSelection="single"
                rowGroupPanelShow="always"
                enableSorting={true}
                enableFilter={true}
                getContextMenuItems={getContextMenuItems({ order })}
                onFirstDataRendered={handleSelectRow}
                onRowSelected={onRowSelected}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </MiniDrawerWithContext>
  );
};
