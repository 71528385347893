import { CircularProgress, Typography, Box } from '@mui/material';
import { OrdersListHeader } from 'components/TeamOverview/components/ManageDataCard/OrdersListHeader';
import { OrdersListRow } from 'components/TeamOverview/components/ManageDataCard/OrdersListRow';
import React, { useMemo, useState } from 'react';
import { useTeamOrdersOverviewStyles } from 'components/TeamOverview/components/ManageDataCard/styles';
import { ORDER_TYPE_FILTERS } from 'components/TeamOverview/utilities';
import { ACTIVE_ORDERS } from 'components/TeamOverview/constants';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { ORDER_PREVIEW_VIEW } from 'components/UserProfile/constants';

export const TeamOrdersOverview = ({
  team,
  isOrdersLoading,
  orders,
  selectedRows,
  setSelectedRows,
  fetchSlidesForOrder,
  setOrderForPreview,
  activeAction,
  setViewMode,
}) => {
  const [isSlidesLoading, setIsSlidesLoading] = useState(false);
  const { classes } = useTeamOrdersOverviewStyles();
  const { showError } = useSnackbar();
  const handleCheckboxClick = (order, isSelected) => {
    if (isSelected) {
      setSelectedRows((prev) => prev.filter((row) => row.uuid !== order.uuid));
    } else {
      setSelectedRows((prev) => [order, ...prev]);
      fetchSlidesForOrder(order);
    }
  };

  const checkIfSelected = (uuid) =>
    !!selectedRows.find((row) => row.uuid === uuid);

  const activeOrders = useMemo(
    () => ORDER_TYPE_FILTERS[ACTIVE_ORDERS](orders),
    [orders],
  );

  const isAllRowsSelected = selectedRows.length === activeOrders.length;

  const handleHeaderCheckboxClick = () => {
    if (isAllRowsSelected) {
      setSelectedRows([]);
    } else {
      setIsSlidesLoading(true);
      //@todo: update when backend will accept batch fetching
      Promise.all(activeOrders.map((order) => fetchSlidesForOrder(order)))
        .then(() => {
          setSelectedRows([...activeOrders]);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          setIsSlidesLoading(false);
        });
    }
  };

  return (
    <>
      <Box
        paddingX={4}
        paddingY={2.5}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box className={classes.slidesCountOverview}>
          <Typography className={classes.slideType}>H&E Slide Count</Typography>
          <Typography className={classes.slidesCount}>
            {team?.total_he_slides_count}
          </Typography>
        </Box>
        <Box className={classes.slidesCountOverview}>
          <Typography className={classes.slideType}>IHC Slide Count</Typography>
          <Typography className={classes.slidesCount}>
            {team?.total_ihc_slides_count}
          </Typography>
        </Box>
        <Box className={classes.slidesCountOverview}>
          <Typography className={classes.slideType}>IF Slide Count</Typography>
          <Typography className={classes.slidesCount}>
            {team?.total_if_slides_count}
          </Typography>
        </Box>
      </Box>
      <OrdersListHeader
        isSlidesLoading={isSlidesLoading}
        handleHeaderCheckboxClick={handleHeaderCheckboxClick}
        isAllRowsSelected={isAllRowsSelected}
        activeAction={activeAction}
        ordersCount={activeOrders.length}
      />
      <Box className={classes.rowsContainer}>
        {isOrdersLoading ? (
          <Box
            height={'100%'}
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress size={40} />
          </Box>
        ) : (
          orders.map((order) => (
            <OrdersListRow
              key={order.uuid}
              order={order}
              handleCheckboxClick={handleCheckboxClick}
              handleOrderSelect={() => {
                setOrderForPreview(order);
                setViewMode(ORDER_PREVIEW_VIEW);
              }}
              isSelected={checkIfSelected(order.uuid)}
              activeAction={activeAction}
            />
          ))
        )}
      </Box>
    </>
  );
};
