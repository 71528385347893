import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { IHC_ANTIBODIES_EXTERNAL_URL, LOGIN_URL } from 'constants/urls';
import { LoginPage } from 'components/Login/LoginPage';
import {
  AuthenticatedRouteDetails,
  AuthenticatedRouteDetailsKeys,
  BasicRouteDetails,
  BasicRouteDetailsKeys,
} from 'constants/routes';
import { useGetSlideGroupsQuery } from 'store/apis/slideGroupsApi';
import { getLocalToken } from 'utilities/token';
import {
  useGetTeamDetailsQuery,
  useGetUserDetailsQuery,
} from 'store/apis/userDetailsApi';
import { FETCH_TEAMS_ERROR_MESSAGE } from 'constants/errorMessages';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import { AntibodiesPage } from 'components/AntibodiesList/AntibodiesPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAuthToken,
  userDetailsAuthTokenSelector,
} from 'store/slices/userDetailsSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenFetchingPage } from 'components/Login/TokenFetchingPage';
import { ProductFruits } from 'react-product-fruits';
import {useProductFruits} from "utilities/hooks/useProductFruits/useProductFruits";

const hist = createBrowserHistory();

const AuthenticatedRoute = ({
  component: Component,
  isLoading,
  isTokenObtaining,
  ...rest
}) => {
  const isAuthenticatedWithLocalToken = !!getLocalToken();
  const authToken = useSelector(userDetailsAuthTokenSelector);

  const isJWTFetching =
    !isAuthenticatedWithLocalToken && (isLoading || isTokenObtaining);

  return isJWTFetching ? (
    <TokenFetchingPage />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedWithLocalToken || authToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_URL,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export const RouterRoutes = () => {
  const {
    isAuthenticated: isAuthenticatedWithJWT,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const isToken = getLocalToken();

  const authToken = useSelector(userDetailsAuthTokenSelector);
  const dispatch = useDispatch();
  const isAuthenticatedWithLocalToken = !!getLocalToken();

  const {
    isLoading: isProductFruitLoading,
    userConfig,
    workspaceCode,
  } = useProductFruits();

  useGetUserDetailsQuery(undefined, { skip: !isToken && !authToken });
  const { error } = useGetTeamDetailsQuery(undefined, {
    skip: !isToken && !authToken,
  });
  useGetSlideGroupsQuery(undefined, { skip: !isToken });
  useQueryError(error, FETCH_TEAMS_ERROR_MESSAGE);

  const isAuthenticated =
    isAuthenticatedWithLocalToken || isAuthenticatedWithJWT;

  const isTokenObtaining = isAuthenticatedWithJWT && !authToken;

  const getJWT = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        aud: 'django-backend',
      });

      dispatch(setAuthToken(accessToken));
    } catch (e) {
      // There are cases when it's an expected behaviour so no need to show a toast
      console.log(e);
    }
  };

  useEffect(() => {
    if (authToken || isAuthenticatedWithLocalToken) return;

    getJWT();
  }, [authToken, isAuthenticatedWithLocalToken]);

  return (
    <Router history={hist}>
      {!isProductFruitLoading && workspaceCode?.length && (
        <ProductFruits
          workspaceCode={workspaceCode}
          user={userConfig}
          language="en"
          // lifeCycle="unmount" TODO: investigate if this prop affects performance
        />
      )}
      <Switch>
        {BasicRouteDetailsKeys.map((path) => {
          const component = BasicRouteDetails[path];
          return <Route exact path={path} component={component} key={path} />;
        })}
        {AuthenticatedRouteDetailsKeys.map((path) => {
          const component = AuthenticatedRouteDetails[path];
          return (
            <AuthenticatedRoute
              exact
              path={path}
              component={component}
              key={path}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              isTokenObtaining={isTokenObtaining}
              isAuthenticatedWithLocalToken={isAuthenticatedWithLocalToken}
            />
          );
        })}
        <Route path={IHC_ANTIBODIES_EXTERNAL_URL} component={AntibodiesPage} />
        <Route path="/" component={LoginPage} />
      </Switch>
    </Router>
  );
};
