import { Box, Button, Typography } from '@mui/material';
import { useActionButtonsStyles } from 'components/pages/AppLibrary/styles';
import { InformationMenu } from 'components/pages/AppLibrary/components/InformationMenu';
import { useInformationMenuStyles } from 'components/pages/AppLibrary/components/styles';
import { ShareSlideModal } from 'components/Modals/ShareSlideModal/ShareSlideModal';
import { useState } from 'react';
import {
  PlaylistAddCheck,
  Help as HelpIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import { buildContactUsLink } from 'components/Layout/Sidebar/utilities';

export const AiModelMenu = ({ model }) => {
  const { classes } = useInformationMenuStyles({ isSmall: false });
  const { classes: buttonClasses } = useActionButtonsStyles();
  const userDetails = useSelector(userDetailsSelector);

  const [openShare, setOpenShare] = useState(false);

  const openModalHandler = () => {
    setOpenShare(true);
  };

  const closeModalHandler = () => {
    setOpenShare(false);
  };

  const handleValidateClick = () => {
    if (model?.validation?.props?.href) {
      window.open(model.validation.props.href, '_blank');
    } else {
      document.getElementById('#description').scrollIntoView();
    }
  };

  const handleHelp = () => {
    const contactUsUrl = buildContactUsLink(userDetails);
    window.open(contactUsUrl, '_blank');
  };

  return (
    <>
      <ShareSlideModal
        isOpen={openShare}
        onClose={closeModalHandler}
        item={{ itemName: 'AI Model' }}
      />

      <Box>
        <Box display="flex" width="100%" justifyContent="center">
          <img
            src={model.companyLogo.url}
            alt="histowiz"
            className={classes.logo}
          />
        </Box>
        <Typography className={classes.companyDescription}>
          {model.companyDescription}
        </Typography>
        {/* TODO: uncomment when ai test order will be ready */}
        {/* <Button */}
        {/*  variant="contained" */}
        {/*  startIcon={<DvrIcon />} */}
        {/*  className={buttonClasses.button} */}
        {/*  classes={{ startIcon: buttonClasses.buttonIcon }} */}
        {/* > */}
        {/*  TRY IN SLIDE VIEWER */}
        {/* </Button> */}
        <Button
          variant="contained"
          startIcon={<PlaylistAddCheck />}
          className={`${buttonClasses.button} ${buttonClasses.noMargin}`}
          classes={{ startIcon: buttonClasses.buttonIcon }}
          onClick={handleValidateClick}
        >
          VIEW VALIDATION
        </Button>
        <InformationMenu model={model} className={classes.informationMenu} />
        <Box className={classes.flexWrapper}>
          {/* ${buttonClasses.actionButton} - add when uncomment help button */}
          <Button
            className={`${buttonClasses.button} ${buttonClasses.actionButton}`}
            startIcon={<ShareIcon />}
            classes={{ startIcon: buttonClasses.buttonIcon }}
            onClick={openModalHandler}
          >
            Share
          </Button>

          <Button
            className={`${buttonClasses.button} ${buttonClasses.actionButton}`}
            startIcon={<HelpIcon />}
            classes={{ startIcon: buttonClasses.buttonIcon }}
            onClick={handleHelp}
          >
            Help
          </Button>
        </Box>
      </Box>
    </>
  );
};
