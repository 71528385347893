import {
  getTeamAddresses,
  postTeamAddress,
} from 'services/resources/commonResources';
import { SUCCESS_TEAM_ADDRESS_UPDATE_MESSAGE } from 'components/Shared/TeamAddress/constants';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useState } from 'react';

export const useTeamAddresses = (team) => {
  const { showSuccess, showError } = useSnackbar();
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTeamAddresses = () => {
    setIsLoading(true);
    return getTeamAddresses()
      .then((response) => {
        const currentTeamAddresses = response.filter(
          (address) => address.team.uuid === team.uuid,
        );
        setAddresses(currentTeamAddresses);
      })
      .catch((err) => {
        showError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitNewAddress = (data) => {
    const postParams = {
      team_uuid: team.uuid,
      ...data,
    };
    return postTeamAddress(postParams)
      .then((response) => {
        setAddresses((prevAddresses) => [...prevAddresses, response]);
        showSuccess(SUCCESS_TEAM_ADDRESS_UPDATE_MESSAGE);
      })
      .catch((err) => {
        showError(err.message);
      });
  };

  return {
    fetchTeamAddresses,
    submitNewAddress,
    addresses,
    isLoading,
  };
};
