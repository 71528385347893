export const TEXT_AREA_CELL_EDITOR_PARAMS = {
  editable: true,
  cellEditor: 'agLargeTextCellEditor',
  cellEditorPopup: true,
  cellEditorParams: {
    rows: 3,
  },
};

export const SAMPLE_FIELDS = {
  project: 'Project',
  order: 'Order #',
  name: 'Histowiz Sample Name',
  sampleId: 'Sample Id',
  antibody: 'Antibody',
  fixationTime: 'Fixation Time',
  organ: 'Organ',
  species: 'Species',
  submissionType: 'Submission Type',
  submittedDate: 'Submitted Date',
  teamSampleName: 'Team Sample Name',
  sex: 'Sex',
  speciesStrain: 'Species Strain / Background Name',
  genotype: 'Genotype',
  originHost: 'Origin of Host',
  age: 'Age (Months)',
  engineeredModel: 'Genetically Engineered Model',
  technique: 'Technique',
  typeOfImplantation: 'Location / Type of Implantation',
  expression: 'Expression',
  drugStudyName: 'Drug Study Name(s)',
  drugDosingSchedule: 'Drug Dosing Schedule',
  drugDuration: 'Drug Duration',
  drugDosage: 'Drug Dosage',
  drugAdministration: 'Drug Administration',
  outcomeExamination: 'Outcome By Gross Examination',
  survivalStudy: 'Survival Study',
  instanceId: 'Instance ID',
  accessioningId: 'Accessioning ID',
  experimentId: 'Experiment ID',
  passageId: 'Passage ID',
  animalId: 'Animal ID',
  surgeryType: 'Surgery Type',
  associatedSlides: 'Associated Slides',
  notes: 'Notes',
};

export const MODIFIED_SORT = 'modified';
export const CREATED_SORT = 'created';
export const PAGE_PARAM = 'page';
export const SEARCH_PARAM = 'search';
export const ORDERING_PARAM = 'ordering';
export const SUBMITTED_PARAM = 'is_allow_sample_edit';

export const ORGAN_NAME_FIELD = 'organ.name';
