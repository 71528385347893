import { useAntibodyTableTooltipStyles } from 'components/AntibodiesList/styles';
import { Typography, Box } from '@mui/material';

export const AntibodyTableTooltip = ({ value }) => {
  const { title, color, text } = value;

  const { classes } = useAntibodyTableTooltipStyles({ color });
  return (
    <Box className={classes.tooltip}>
      <Box className={classes.pointer} />
      <Typography className={classes.tooltipTitle}>{title}</Typography>
      <Typography className={classes.tooltipText}>{text}</Typography>
    </Box>
  );
};
