import { Grid } from '@mui/material';
import { PathologyMapResultsCounter } from 'components/PathologyMap/Shared/PathologyMapResultsCounter';
import { useCurrentRefinementsSumCount } from 'components/PathologyMap/utilities/hooks';
import { PathologyMapSectionHeaderWrapper } from 'components/PathologyMap/Shared/PathologyMapSectionHeaderWrapper';
import { HeaderPathologyMapTypography } from 'components/PathologyMap/Shared/PathologyMapTypography/HeaderPathologyMapTypography';

export const PathologyMapFilterPropertiesHeader = () => {
  const selectedFiltersSumCount = useCurrentRefinementsSumCount();

  return (
    <PathologyMapSectionHeaderWrapper>
      <Grid item>
        <HeaderPathologyMapTypography>
          FILTER PROPERTIES
        </HeaderPathologyMapTypography>
      </Grid>
      <Grid item>
        <PathologyMapResultsCounter count={selectedFiltersSumCount} />
      </Grid>
    </PathologyMapSectionHeaderWrapper>
  );
};
