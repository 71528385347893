import React, { useState } from 'react';
import { Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';

import { formatSlideComponentUserTimestampLabel } from 'components/AllSlidesComments/utilities';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { useSlideCommentStyles } from 'components/AllSlidesComments/styles';
import { useDeleteSlideCommentMutation } from 'store/apis/slideCommentsApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const SlideCommentComponentV2 = ({ comment, handleDeleteComment }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showComment, setShowComment] = useState(true);
  const { showError } = useSnackbar();
  const [deleteSlideComment] = useDeleteSlideCommentMutation();

  const { classes } = useSlideCommentStyles();
  const { text } = comment;

  const label = formatSlideComponentUserTimestampLabel({ comment });

  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const closeComment = () => {
    setShowComment(false);
    closeDeleteModal();
  };

  const deleteCommentHandler = () => {
    handleDeleteComment(comment);
    closeComment();
  };

  const deleteCommentRequest = () =>
    deleteSlideComment({
      slideUUID: comment.slide.uuid,
      commentUUID: comment.uuid,
    })
      .unwrap()
      .then(closeComment)
      .catch(() => {
        showError('Error deleting comment');
      });

  const handleDelete = () =>
    handleDeleteComment ? deleteCommentHandler() : deleteCommentRequest();

  return showComment ? (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.commentHeader}>
          <Typography className={classes.label}>{label}</Typography>
          <Box
            onClick={() => setIsDeleteModalOpen(true)}
            display="inline"
            className={classes.deleteButton}
          >
            <DeleteIcon fontSize="small" color="inherit" />
          </Box>
        </Box>
        <Typography className={classes.text}>{text}</Typography>
      </Box>
      {isDeleteModalOpen && (
        <DeleteModal
          handleDeleteResult={handleDelete}
          onClose={closeDeleteModal}
          open
        >
          {label} - {text}
        </DeleteModal>
      )}
    </>
  ) : null;
};
