import { Grid } from '@mui/material';
import { OrdersCard } from 'components/UserProfile/OrdersCard/OrdersCard';
import { OrderTrackingCard } from 'components/UserProfile/OrderTrackingCard/OrderTrackingCard';
import { LatestCommentsCard } from 'components/UserProfile/LatestCommentsCard/LatestCommentsCard';
import React, { useEffect, useState } from 'react';
import { useTeamNewsFeed } from 'components/UserProfile/hooks';
import { UploadSection } from 'components/UserProfile/Upload/UploadForOrder';
import {
  ORDER_HISTORY_SECTION,
  ORDERS_VIEW,
  UPLOAD_VIEW,
} from 'components/TeamOverview/constants';
import { useHistory } from 'react-router';

export const OrderHistorySection = ({ selectedTeam }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedProjectForUpload, setSelectedProjectForUpload] =
    useState(null);
  const [selectedOrderForUpload, setSelectedOrderForUpload] = useState(null);
  const [currentView, setCurrentView] = useState(ORDERS_VIEW);
  const [uploadKey, setUploadKey] = useState(null);
  const { newsfeeds } = useTeamNewsFeed(selectedTeam);
  const history = useHistory();

  const isDefaultView = currentView === ORDERS_VIEW;

  const handleChangeView = (view) => {
    setCurrentView(view);

    const hash =
      view === UPLOAD_VIEW && selectedOrderForUpload
        ? `#${ORDER_HISTORY_SECTION}?upload-order=${selectedOrderForUpload.uuid}`
        : `#${ORDER_HISTORY_SECTION}`;

    history.push({
      hash,
    });
  };

  useEffect(() => {
    const { hash } = history.location;
    const paramsString = hash.split('?')[1];
    const params = new URLSearchParams(paramsString);
    const uploadOrderUUID = params.get('upload-order');
    const isSelected = selectedOrderForUpload?.uuid === uploadOrderUUID;

    if (uploadOrderUUID && !isSelected) {
      setSelectedOrderForUpload(
        uploadOrderUUID ? { uuid: uploadOrderUUID } : null,
      );
    }

    setCurrentView(uploadOrderUUID ? UPLOAD_VIEW : ORDERS_VIEW);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Grid item container xs={12} spacing={2} direction="row">
        <Grid item xs={12} lg={isDefaultView ? 8 : 12}>
          {isDefaultView ? (
            <OrdersCard
              setSelectedOrder={setSelectedOrder}
              selectedOrder={selectedOrder}
              selectedTeam={selectedTeam}
              setCurrentView={handleChangeView}
              setUploadKey={setUploadKey}
              selectedProjectForUpload={selectedProjectForUpload}
              setSelectedProjectForUpload={setSelectedProjectForUpload}
              selectedOrderForUpload={selectedOrderForUpload}
              setSelectedOrderForUpload={setSelectedOrderForUpload}
            />
          ) : (
            <UploadSection
              setCurrentView={handleChangeView}
              selectedOrderForUpload={selectedOrderForUpload}
              uploadKey={uploadKey}
            />
          )}
        </Grid>
        {isDefaultView && (
          <Grid item xs={12} lg={4}>
            {!!selectedOrder && <OrderTrackingCard order={selectedOrder} />}
          </Grid>
        )}
      </Grid>
      {isDefaultView && (
        <Grid item xs={12} marginTop={2}>
          <LatestCommentsCard newsFeed={newsfeeds?.get(selectedTeam)} />
        </Grid>
      )}
    </>
  );
};
