import React, { Fragment, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { ViewSlideModal } from 'components/Modals/ViewSlideModal';
import Box from '@mui/material/Box';
import { formatSlideComponentUserTimestampLabel } from 'components/AllSlidesComments/utilities';
import { ViewSlidesListModal } from 'components/Modals/ViewSlidesListModal';
import { useSlideCommentComponentStyles } from './styles';

export const OrderCommentComponent = ({ orderComment }) => {
  const { classes } = useSlideCommentComponentStyles();
  const order = orderComment?.order;

  const orderCommentSlides = orderComment.slides;

  const [viewSlideModalOpen, setViewSlideModalOpen] = useState(false);
  const [viewAllSlidesModalOpen, setViewAllSlidesModalOpen] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(null);

  const onImgClick = (slide) => {
    setViewSlideModalOpen(true);
    setSelectedSlide(slide);
  };

  const openSlidesList = () => {
    setViewAllSlidesModalOpen(true);
  };

  const handleCloseViewSlideModal = () => {
    setViewSlideModalOpen(false);
  };

  const handleCloseSlidesListModal = () => {
    setViewAllSlidesModalOpen(false);
  };

  const renderSlideAdditionalActions = () => {
    return (
      <Button
        variant="contained"
        color={'secondary'}
        onClick={handleCloseViewSlideModal}
        className={classes.backButton}
      >
        Back
      </Button>
    );
  };

  const renderViewSlideModal = () => {
    if (!selectedSlide) {
      return null;
    }

    if (!viewSlideModalOpen) {
      return null;
    }

    return (
      <ViewSlideModal
        slide={selectedSlide}
        isOpen={viewSlideModalOpen}
        handleCloseModal={handleCloseViewSlideModal}
        additionalActions={renderSlideAdditionalActions}
      />
    );
  };

  const renderViewAllSlidesModal = () => {
    if (!viewAllSlidesModalOpen) {
      return null;
    }

    return (
      <ViewSlidesListModal
        slides={orderCommentSlides}
        onImgClick={onImgClick}
        isOpen={viewAllSlidesModalOpen}
        handleCloseModal={handleCloseSlidesListModal}
      />
    );
  };

  const getOptimizationDilutionText = () => {
    if (!orderCommentSlides.length) {
      return '';
    }

    const firstSlide = orderCommentSlides[0];
    // if this contains text in this field, then it should be displayed
    const firstSlideOptimizationDilution = firstSlide.optimization_dilution;
    return firstSlideOptimizationDilution;
  };

  const renderOptimizationDilutionSection = () => {
    const optimizationDilutionText = getOptimizationDilutionText();

    if (!optimizationDilutionText) {
      return null;
    }

    return (
      <Fragment>
        <Box m={1}>
          <Typography color={'secondary'} variant={'h5'} align={'right'}>
            Optimization Dilution : {optimizationDilutionText}
          </Typography>
        </Box>
      </Fragment>
    );
  };

  const renderOrderCommentText = () => {
    // label of when the user made teh comment
    const userTimestampLabel = formatSlideComponentUserTimestampLabel({
      comment: orderComment,
    });

    return (
      <Fragment>
        <Box m={1}>
          <Paper className={classes.slideComment} elevation={5}>
            <Box>
              <Typography variant={'subtitle2'} gutterBottom={true}>
                {userTimestampLabel}
              </Typography>
              <Typography variant={'subtitle1'}>{orderComment.text}</Typography>
            </Box>
          </Paper>
        </Box>
      </Fragment>
    );
  };

  const slidesForRendering =
    orderCommentSlides.length <= 4
      ? orderCommentSlides
      : orderCommentSlides.slice(0, 3);

  return (
    <Fragment key={orderComment.uuid}>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.commentHeader}>
              <Box>
                <Typography variant={'h5'} color={'primary'} display={'inline'}>
                  {order.name}{' '}
                </Typography>
                <Typography variant={'h5'} display={'inline'}>
                  - Order Comment
                </Typography>
              </Box>
              {renderOptimizationDilutionSection()}
            </div>
          </Grid>
          <Grid
            container
            className={classes.imagesWrapper}
            onClick={openSlidesList}
          >
            {slidesForRendering.map((slide) => (
              <Grid
                item
                className={
                  slidesForRendering.length === 1
                    ? classes.singleImageWrapper
                    : classes.slideImageWrapper
                }
              >
                <img
                  src={slide.medium_thumbnail_url}
                  alt={'Macro'}
                  className={classes.slideImagePreview}
                />
              </Grid>
            ))}
            {orderCommentSlides.length > 4 && (
              <Grid dis item xs={6} className={classes.slideImageWrapper}>
                <Typography display={'inline'}>
                  {orderCommentSlides.length - 3} more slides
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.commentTextContainer}>
            <Box ml={1}>{renderOrderCommentText()}</Box>
          </Grid>
        </Grid>
        {renderViewSlideModal()}
        {renderViewAllSlidesModal()}
      </div>
    </Fragment>
  );
};
