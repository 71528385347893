import { makeStyles } from 'tss-react/mui';

export const useContributedDataStyles = makeStyles()({
  noDataContainer: {
    height: '19vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contributedDataCard: {
    marginTop: '1rem',
  },
  contributedDataCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 1.25rem',
  },
  cardTitle: {
    fontWeight: '600',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
  yearSelector: {
    width: '30%',
  },
  hintBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingInline: '1.5rem',
    paddingBottom: '1rem',
  },
  noDataHintBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '1rem',
    borderRadius: '5px',
    marginInline: '1.75rem',
    marginBottom: '1.75rem',
  },
  hintText: {
    marginLeft: '1rem',
    fontSize: '0.8rem',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  commonData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  greyText: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  contributedDataContainer: {
    position: 'relative',
    width: '50%',
    maxWidth: '180px',
  },
});
