import auth0 from 'auth0-js';

import { LOGIN_URL, LOGOUT_URL } from 'constants/urls';
import { clearCache } from 'services/backendAPI';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_REDIRECT_URI,
  REACT_APP_NETLIFY_CALLBACK_URL,
  REACT_APP_LOGOUT_REDIRECT_URI,
} = process.env;

const logoutCallbackUrl =
  REACT_APP_LOGOUT_REDIRECT_URI ||
  `${REACT_APP_NETLIFY_CALLBACK_URL}${LOGIN_URL}`;

const webAuth = new auth0.WebAuth({
  domain: REACT_APP_AUTH0_DOMAIN,
  clientID: REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: REACT_APP_AUTH0_REDIRECT_URI || REACT_APP_NETLIFY_CALLBACK_URL,
  responseType: 'token id_token',
});

export const loginWithAuth0 = async (email, password) =>
  new Promise((resolve, reject) => {
    webAuth.login(
      {
        email,
        password,
        realm: 'HistoWiz-Backend',
      },
      (error, result) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(result);
      },
    );
  });

export const logout = (isAuthenticated, history) => {
  isAuthenticated
    ? webAuth.logout({
        returnTo: logoutCallbackUrl,
      })
    : history.push(LOGOUT_URL);
  clearCache();
};

export const forceLogout = (isBasicRoute) => {
  webAuth.logout({
    returnTo: isBasicRoute ? window.location.origin : logoutCallbackUrl,
  });
};

export const EMAIL_VALIDATION_REGULAR =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmailValid = (email) => EMAIL_VALIDATION_REGULAR.test(email);
