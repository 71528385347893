export const orderBarcodeColumns = [
  {
    field: 'sampleName',
    headerName: 'Sample Name',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset', 'apply'],
      debounceMs: 200,
    },
    enableRowGroup: true,
    rowGroup: false,
    minWidth: 300,
  },
  {
    field: 'stainName',
    headerName: 'Stain Name',
    filter: 'agSetColumnFilter',
    filterParams: {
      buttons: ['reset', 'apply'],
      debounceMs: 200,
    },
    minWidth: 100,
  },
  {
    field: 'slideNumber',
    headerName: 'Slide Number',
    filter: 'agNumberColumnFilter',
    filterParams: {
      buttons: ['reset', 'apply'],
      debounceMs: 200,
    },
    minWidth: 50,
  },
  {
    field: 'sampleFullDetail',
    headerName: 'Barcode',
    minWidth: 500,
  },
];

export const barcodesDefaultColumnSettings = {
  flex: 1,
  minWidth: 50,
  resizable: true,
  sortable: true,
};
