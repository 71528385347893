import { makeStyles } from 'tss-react/mui';
import { MEDIUM_GREY } from 'utilities/colors';

export const useCardsMenuSectionStyles = makeStyles()(() => ({
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 28px 0',
    height: '100%',
    justifyContent: 'space-between',
    boxShadow: `0.5rem 0.5rem 0.5rem 0.5rem ${MEDIUM_GREY}`,
    '&:hover': {
      backgroundColor: '#d2e0ee',
      border: 'none',
    },
  },
  fullHeight: {
    height: '100%',
  },
  iconBox: {
    borderRadius: '5px',
    width: '42px',
    height: '42px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.active': {
      backgroundColor: 'rgba(193, 209, 242, 1) !important',
      border: '1px solid rgba(109, 106, 245, 0.8)',
    },

    '&.yellow': {
      backgroundColor: 'rgba(240, 159, 1, 0.2)',
    },

    '&.green': {
      background: 'rgba(175, 243, 151, 0.2)',
    },

    '&.red': {
      backgroundColor: 'rgba(248, 166, 166, 0.2)',
    },

    '&.purple': {
      backgroundColor: 'rgba(248, 166, 230, 0.2)',
    },
  },
  cardBodyTitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  mainText: {
    fontWeight: 400,
    marginTop: '0.5rem',
  },

  mainTextBold: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '1.75rem',
  },
  menuCard: {
    cursor: 'pointer',
    height: '100%',
  },
  linkIcon: {
    marginLeft: '2px',
    marginBottom: '4px',
  },
  underlined: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
  },
  downIcon: {
    fontSize: '1.5rem',
    alignSelf: 'center',
    marginTop: '1rem',
  },
  orderHistoryLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    width: 'max-content',
  },
}));
