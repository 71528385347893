import signupBackground from 'images/algolia_background.png';
import { makeStyles } from 'tss-react/mui';

export const usePathologyMapHeroContainerStyles = makeStyles()(() => {
  return {
    heroContainer: {
      padding: '1rem',
      color: 'white',
    },
    histowizIcon: {
      width: '50px',
      height: '50px',
      marginRight: '.5rem',
    },
  };
});

export const usePathologyMapSearchStyles = makeStyles()((theme) => {
  return {
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    paperContainer: {
      backgroundImage: `url(${signupBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: '0px -150px',
      width: '100%',
      height: '25vh',
      //top: "300px"
    },
    topGridContainer: {
      height: '100%',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      backgroundColor: 'white',
      padding: 1,
    },
    floatingSearch: {
      position: 'absolute',
      zIndex: '999',
    },
    refinementGrid: {
      width: 'auto',
      //maxWidth: "300%",
    },
  };
});
