import { yupResolver } from '@hookform/resolvers/yup';
import { useBillingShippingStyles } from 'components/OrderForm/BillingShipping/styles';
import { contactsSchema } from 'components/OrderForm/BillingShipping/utilities/contactsSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { postBillingContact } from 'services/resources/commonResources';
import {
  buildCreateBillingContactParams,
  serializeBillingContact,
} from 'components/OrderForm/BillingShipping/utilities/serializers';
import { BillingContactPONumberInput } from 'components/OrderForm/components/AddBillingContactFormInputs';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { POAttachmentSection } from 'components/OrderForm/BillingShipping/BillingShippingInputs';
import { useIsDemoUser } from 'components/utilities/hooks/useIsDemoUser';
import { POST_BILLING_CONTACTS_ERROR_MESSAGE } from 'constants/errorMessages';
import { DEFAULT_BILLING_CONTACT_VALUES } from 'components/OrderForm/BillingShipping/utilities/constants';
import { FormInputV2 } from 'components/OrderForm/components/InfoFormInputs';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { billingShippingSelectors } from 'store/slices/billingShippingSlice/billingShippingSlice';

export const AddBillingContactForm = ({ onAfterSuccessSubmit }) => {
  const { classes } = useBillingShippingStyles();
  const isDemoAccount = useIsDemoUser();
  const addLabel = isDemoAccount ? 'Demo User' : 'Add PO Number';
  const { showError } = useSnackbar();
  const { validated, order, teamUUID } = useSelector(
    billingShippingSelectors.billingContactForm,
  );

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_BILLING_CONTACT_VALUES,
    resolver: yupResolver(contactsSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const isErrorForm = !!Object.keys(errors).length;
  const disableSubmitButon = isSubmitting || isDemoAccount || isErrorForm;

  const onSubmit = (values) => {
    const postParams = buildCreateBillingContactParams(values, teamUUID);
    postBillingContact(postParams)
      .then((response) => {
        const billingContactSelected = serializeBillingContact({
          billingContact: response,
        });

        onAfterSuccessSubmit(billingContactSelected);
      })
      .catch(() => showError(POST_BILLING_CONTACTS_ERROR_MESSAGE));
  };

  return (
    <div className={classes.container}>
      <div className={classes.formItem}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BillingContactPONumberInput
              name="billingContactPONumber"
              checkmark
              validated={validated}
            />
            <FormInputV2
              name={'billingContactName'}
              formLabel={'Billing Contact Name'}
              formPlaceholder={'Name ...'}
              formInput={'text'}
              checkmark
              validated={validated}
            />
            <FormInputV2
              name={'billingContactPhoneNumber'}
              formLabel={'Billing Contact Phone Number'}
              formPlaceholder={'Phone Number ...'}
              formInput={'text'}
              checkmark
              validated={validated}
            />
            <FormInputV2
              name={'billingContactEmail'}
              formLabel={'Billing Contact Email'}
              formPlaceholder={'Email ...'}
              formInput={'text'}
              checkmark
              validated={validated}
            />
            <Box display={'flex'} justifyContent={'flex-end'} width={'100%'}>
              <Box mt={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disableSubmitButon}
                >
                  {addLabel}
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
        <POAttachmentSection orderUUID={order.uuid} />
      </div>
    </div>
  );
};
