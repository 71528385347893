import { makeStyles } from 'tss-react/mui';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';

export const useTermsAndConditionsReviewModalStyles = makeStyles()(() => ({
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '1.125rem',
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.75rem',
  },
  inputLabel: {
    fontSize: '0.875rem',
    width: '8rem',
  },
  cardContainer: {
    width: '100%',
  },
  cardContent: {
    padding: '1rem',
  },
  buttonsSection: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: '1rem',
    width: '10rem',
  },
  termAndConditionsContainer: {
    height: '500px',
    width: '100%',
    overflowY: 'auto',
    ...VERTICAL_SCROLL_STYLE,
  },
  termsPage: {
    marginInline: 'auto',
  },
}));
