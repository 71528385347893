import React from 'react';
import IconButton from '@mui/material/IconButton';
import LensIcon from '@mui/icons-material/Lens';
import { COLOR_PALLETE } from 'components/IFViewer/constants';
import { useColorPaletteStyles } from 'components/IFViewer/styles';

export const ColorPalette = ({ handleColorSelect }) => {
  const { classes } = useColorPaletteStyles();
  return (
    <div className={classes.container} aria-label="color-swatch">
      {COLOR_PALLETE.map((color) => {
        return (
          <IconButton
            className={classes.button}
            key={color}
            onClick={() => handleColorSelect(color)}
          >
            <LensIcon
              fontSize="small"
              style={{ color: `rgb(${color})` }}
              className={classes.icon}
            />
          </IconButton>
        );
      })}
    </div>
  );
};
