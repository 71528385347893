import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TABS_BLUE } from 'utilities/colors';
import { EditSampleButton } from 'components/SampleEditor/components/EditSampleButton';
import { useSlideMetadataWithScienceInfoStyles } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/styles';

const TabLabel = ({ classes, name, itemsCount }) => (
  <Box display="flex">
    <Typography className={classes.tabLabel}>{name}</Typography>
    <Typography component="span" className={classes.itemsCount}>
      {itemsCount || 0}
    </Typography>
  </Box>
);

export const SlideMetadataHeader = ({
  slide,
  currentTab,
  handleTabsChange,
  standardMetadataItemsCount,
  scienceInfoItemsCount,
}) => {
  const { classes } = useSlideMetadataWithScienceInfoStyles();

  return (
    <Tabs
      value={currentTab}
      onChange={handleTabsChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: TABS_BLUE,
        },
      }}
      className={classes.tabs}
    >
      <Tab
        label={
          <TabLabel
            classes={classes}
            name={'Standard Metadata'}
            itemsCount={standardMetadataItemsCount}
          />
        }
        id="standard-metadata"
      />
      <Tab
        label={
          <TabLabel
            classes={classes}
            name={'Science Info'}
            itemsCount={scienceInfoItemsCount}
          />
        }
        id="science-info"
      />
      <EditSampleButton sampleId={slide?.sample?.id} />
    </Tabs>
  );
};
