import { makeStyles } from 'tss-react/mui';
import { getWhiteLabelSignupBackground } from 'utilities/whitelabel';

import { keyframes } from '@mui/system';
import { LIGHT_GREY } from 'utilities/colors';

const signupBackground = getWhiteLabelSignupBackground();
const keyframeAnimation = keyframes`
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(2);
  }
`;

const dotsAnimation = keyframes`
  to {
    clip-path: inset(0 -1ch 0 0)
  }
`;

export const useLoginPageStyles = makeStyles()((theme) => ({
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: '2',
  },
  loadingState: {
    filter: 'blur(2px)',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formDiv: {
    width: '100%',
  },
  paperContainer: {
    backgroundImage: `url(${signupBackground})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
  },
  loginContainer: {
    width: '100%',
    marginTop: '1rem',
  },
  loginHeader: {
    position: 'relative',
    top: '-2rem',
    width: '80%',
    margin: 'auto',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    paddingTop: '2rem',
    paddingBottom: '2rem',
    color: 'white !important',
  },
  input: {
    marginLeft: 'auto',
    marginTop: '2rem',
    marginRight: 'auto',
    width: '80%',
    justifyContent: 'center',
  },
  signinButton: {
    marginLeft: 'auto',
    marginTop: '2rem',
    marginRight: 'auto',
    marginBottom: '2rem',
    width: '80%',
  },
  signinButtonContainer: {
    textAlign: 'center',
  },
  signInRightButton: {
    marginLeft: '.5rem !important',
  },
  oauthSigninButton: {
    width: '13rem',
    margin: '2rem',
  },
  signUpLink: {
    textDecoration: 'none',
    color: 'black',
    marginRight: '0.5rem',
  },
  resetPasswordLink: {
    textDecoration: 'none',
    color: 'black',
  },
  fullHeight: {
    height: '100%',
  },
  authLoadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signupBackground})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
  },
  authContent: {
    background: LIGHT_GREY,
    opacity: '0.7',
    width: '35rem',
    height: '15rem',
    border: '1px solid black',
    borderRadius: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  authText: {
    color: theme.palette.default.black,
    marginTop: '2rem',
    fontSize: '2rem',
    clipPath: 'inset(0 1ch 0 0)',
    animation: `${dotsAnimation} 1s steps(3) infinite`,
  },
  authIcon: {
    color: theme.palette.secondary.main,
    marginTop: '3rem',
    animation: `${keyframeAnimation} 2s infinite`,
  },
}));
