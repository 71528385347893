import Typography from '@mui/material/Typography';
import React from 'react';
import { Paper } from '@mui/material';
import { useHeaderAndContentStyles, useQuestionsStyles } from './styles';

export const PlaceOrderHeader = () => {
  const { classes } = useQuestionsStyles();

  return (
    <div>
      <Typography
        align="center"
        variant={'subtitle1'}
        className={classes.subHeader}
      >
        Questions? Contact us Monday through Friday 9AM-5PM at 347-221-1020.
      </Typography>
    </div>
  );
};

export const HeaderAndContent = ({ children }) => {
  const { classes } = useHeaderAndContentStyles();

  return (
    <div>
      <Paper className={classes.root} elevation={3}>
        <PlaceOrderHeader />
        {children}
      </Paper>
    </div>
  );
};
