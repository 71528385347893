import { Grid, Typography } from '@mui/material';
import React from 'react';

export const HeaderItem = ({ xs, text, className, classes }) => (
  <Grid item xs={xs} className={className}>
    <Typography fontSize="0.875rem" className={classes.orderRowHeader}>
      {text}
    </Typography>
  </Grid>
);
