import {
  ORDER_ACCEPTED_TRACKER_STATE,
  ORDER_CLIENT_UPLOADING_SLIDES_STATE,
  ORDER_COMPLETE_TRACKER_STATE,
  ORDER_FORM_SUBMITTED_TRACKER_STATE,
  ORDER_PREPARE_TO_SHIP_TRACKER_STATE,
  ORDER_PROCESSING_TRACKER_STATE,
  ORDER_QC_TRACKER_STATE,
  ORDER_RECEIVED_TRACKER_STATE,
  ORDER_REVIEW_TRACKER_STATE,
  ORDER_SHIPPED_TRACKER_STATE,
} from 'components/OrdersList/constants';
import {
  HISTOWIZ_PRIMARY_COLOR,
  PRIMARY_BLUE,
  PRIMARY_GREEN,
  PRIMARY_PURPLE,
  SECONDARY_BLUE,
} from 'utilities/colors';

export const ORDER_FULFILLMENT_TIME_EXPLANATIONS = [
  <span>
    Our fulfillment timer starts after we receive <b>and</b> review your order.
  </span>,
  <span>Fulfillment time is in business days.</span>,
  <span>
    For example, if you selected 10 day turnaround time, your order will be
    fulfilled <b>10 business days</b> after we review your order.
  </span>,
];

export const CONTACT_US_TEXT =
  'Questions? Contact us Monday through Friday 9AM-5PM at 347-221-1020.';

const PROCESSING_COMPLETE_MOCKED_STATE = 'processing_complete';

export const STEPS_INDEXES_IN_CHRONOLOGICAL_ORDER = [
  ORDER_FORM_SUBMITTED_TRACKER_STATE,
  ORDER_ACCEPTED_TRACKER_STATE,
  ORDER_SHIPPED_TRACKER_STATE,
  ORDER_RECEIVED_TRACKER_STATE,
  ORDER_REVIEW_TRACKER_STATE,
  ORDER_PROCESSING_TRACKER_STATE,
  PROCESSING_COMPLETE_MOCKED_STATE,
  ORDER_QC_TRACKER_STATE,
  ORDER_COMPLETE_TRACKER_STATE,
  ORDER_PREPARE_TO_SHIP_TRACKER_STATE,
];

export const IA_STEPPER_LABEL = 'Image Analysis';
export const PC_STEPPER_LABEL = 'Pathology Consultation';

export const IA_AND_PC_STEPPER = {
  name: '',
  color: PRIMARY_BLUE,
  steps: ['Analysis', 'Analysis Complete'],
};

export const SHIPPING_STEPPER = {
  name: 'Return Shipping',
  color: PRIMARY_PURPLE,
  steps: ['Preparing to Ship', 'Shipping', 'Shipping Complete'],
};

export const PRE_PROCESSING_NON_LINEAR_STEPPER = {
  name: 'Pre-processing',
  color: SECONDARY_BLUE,
  steps: {
    [ORDER_FORM_SUBMITTED_TRACKER_STATE]: 'Order Form Submitted',
    [ORDER_ACCEPTED_TRACKER_STATE]: 'Order Accepted',
    [ORDER_SHIPPED_TRACKER_STATE]: 'Shipped',
    [ORDER_RECEIVED_TRACKER_STATE]: 'Received',
  },
};

export const HISTOLOGY_STEPPER = {
  name: 'Histology',
  color: HISTOWIZ_PRIMARY_COLOR,
  steps: {
    [ORDER_REVIEW_TRACKER_STATE]: 'Review',
    [ORDER_PROCESSING_TRACKER_STATE]: 'Processing',
    [PROCESSING_COMPLETE_MOCKED_STATE]: 'Complete',
  },
};

export const POST_PROCESSING_STEPPER = {
  name: 'Post-processing',
  color: PRIMARY_GREEN,
  steps: {
    [ORDER_QC_TRACKER_STATE]: 'Quality Control',
    [ORDER_COMPLETE_TRACKER_STATE]: 'Order Completed',
  },
};

export const SHORTENED_ORDER_STATES_LIST = [
  {
    label: 'New',
    state: 'new',
    description: 'Your order was created on :date!',
    isDateRequired: true,
  },
  {
    label: 'Order Form Submitted',
    state: 'order_form_submitted',
    description: 'Your order was submitted on :date!',
    isDateRequired: true,
  },
  {
    label: 'Order Accepted',
    state: 'accepted',
    description: 'Your order has been accepted on :date!',
    isDateRequired: true,
  },
  {
    label: 'Shipped',
    state: 'shipped',
    description: 'Your order shipped on :date!',
    isDateRequired: true,
  },
  {
    label: 'Received',
    state: 'received',
    description: 'Your order was received on :date!',
    isDateRequired: true,
  },
  {
    label: 'Slides Uploading',
    state: ORDER_CLIENT_UPLOADING_SLIDES_STATE,
    description: 'Your slides are being uploaded at :date!',
    isDateRequired: true,
  },
  {
    label: 'Review',
    state: 'review',
    description: 'Your order is being reviewed and examined in our lab!',
    isDateRequired: false,
  },
  {
    label: 'Processing',
    state: 'processing',
    description:
      'Your order is currently being processed by a HistoWiz lab technician!',
    isDateRequired: false,
  },
  {
    label: 'Quality Control',
    state: 'quality_control',
    description: 'Your order is being checked for quality by our QC team',
    isDateRequired: false,
  },
  {
    label: 'Analysis',
    state: 'analysis',
    description: 'Your slides are undergoing analysis by our team of experts!',
    isDateRequired: false,
  },
  {
    label: 'Preparing to Ship',
    state: 'prepared_to_ship',
    description: 'Your slides are being prepared for return shipping!',
    isDateRequired: false,
  },
  {
    label: 'Order Fulfilled',
    state: 'complete',
    description: 'Your order was fulfilled on :date!',
    isDateRequired: true,
  },
  {
    label: 'Cancelled',
    state: 'cancelled',
    description: 'Your order was cancelled on :date',
    isDateRequired: true,
  },
];
