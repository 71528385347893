import { ConditionalComponent } from 'components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent';
import React from 'react';

export const IS_PATH_V2_ENABLED = true;

export const RenderPathV2Component = ({ children }) => {
  return (
    <ConditionalComponent condition={IS_PATH_V2_ENABLED}>
      {children}
    </ConditionalComponent>
  );
};
