import React from 'react';
import AddHome from '@mui/icons-material/AddHome';

import { PATHOLOGIST_WORKLIST_URL } from 'constants/urls';
import { RenderPathV2Component } from 'constants/featureFlags';
import { ShowPathologistUserOnly } from 'components/ConditionalWrappers/ShowPathologistUserOnly';
import { SidebarListItem } from 'components/Layout/Sidebar/SidebarListItem';
import { SidebarList } from 'components/Layout/Sidebar/SidebarList';

export const PathologistList = () => (
  <RenderPathV2Component>
    <ShowPathologistUserOnly>
      <SidebarList>
        <SidebarListItem
          Icon={AddHome}
          name="Consultations Worklist"
          path={PATHOLOGIST_WORKLIST_URL}
        />
      </SidebarList>
    </ShowPathologistUserOnly>
  </RenderPathV2Component>
);
