import { makeStyles } from 'tss-react/mui';

const LINK = {
  alignItems: 'center',
  color: '#666',
  display: 'flex',
  gap: '12px',
  height: '3rem',
  textDecoration: 'none',
  padding: '0 24px',
  transition: 'all 0.3s ease-in-out',
  width: '100%',
  '&:hover': { backgroundColor: '#00000008' },
  cursor: 'pointer',
};

const SIDEBAR = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px 0 24px',
  transition: 'gap 0.3s ease-in-out',
};

export const useSidebarStyles = makeStyles()(() => ({
  sidebar: SIDEBAR,
  sidebarCollapsed: {
    ...SIDEBAR,
    gap: 0,
    '& span': { opacity: 0 },
  },
  link: LINK,
  linkActive: {
    ...LINK,
    pointerEvents: 'none',
    backgroundColor: '#e1e3f0',
    border: '1px solid #6a7ff4',
    borderRadius: '0.5rem',
  },
  text: {
    color: '#000',
    transition: 'opacity 0.3s ease-in-out',
  },
  listItem: { padding: 0 },
  list: { padding: 0, margin: 0 },
  appVersion: {
    position: 'absolute',
    marginLeft: '1.5rem',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    bottom: '0.5rem',
  },
  tooltipPopper: {
    zIndex: 3200,
  },
}));
