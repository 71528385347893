import {
  getAppOrderDetailsLink,
  getOrderDataForGrid,
} from 'components/Projects/helpers/functions';
import { NameEdit } from 'components/Projects/NameEdit/NameEdit';
import { PROJECT_SLIDES_URL, SLIDES_LIST_URL_2 } from 'constants/urls';

export const getContextMenu = (params) => [
  'copy',
  'copyWithHeaders',
  'copyWithGroupHeaders',
  'paste',
  'separator',
  {
    name: 'Open in Slides List',
    action: () => {
      window.open(
        PROJECT_SLIDES_URL.replace(':projectUUID', params.node.data.uuid),
        '_blank',
      );
    },
    icon: '<i class="fa fa-list context-font" />',
  },
  {
    name: 'View Project in Search 2.0',
    action: () => {
      window.open(
        `${SLIDES_LIST_URL_2}?production_pathology_map[refinementList][project_name][0]=${params.node.data.projectName}`,
        '_blank',
      );
    },
    icon: '<i class="fa fa-search context-font" />',
  },
  'separator',
  'export',
];

export const ORDERS_COLUMNS = [
  {
    field: 'order',
    cellRenderer: ({ value: { name, uuid } }) =>
      getAppOrderDetailsLink(uuid, name, 'summary'),
  },
  {
    field: 'slides',
    cellRenderer: ({ value: { count, uuid } }) =>
      getAppOrderDetailsLink(uuid, count, 'processed_slides'),
  },
  {
    field: 'ifSlides',
    headerName: 'IF slides',
    cellRenderer: ({ value: { count, uuid } }) =>
      getAppOrderDetailsLink(uuid, count, 'processed_if_slides'),
  },
  { field: 'state' },
];

export const PROJECTS_COLUMNS = [
  {
    field: 'toggle',
    headerName: '',
    cellRenderer: 'agGroupCellRenderer',
    maxWidth: 30,
  },
  {
    field: 'projectName',
    headerName: 'Project Name',
    sortable: true,
    unSortIcon: true,
    minWidth: 400,
    suppressKeyboardEvent: () => true,
    cellRenderer: ({ value, data }) => (
      <NameEdit name={value} uuid={data.uuid} />
    ),
  },
  { field: 'orders', headerName: '# of Orders', sortable: true, maxWidth: 110 },
  { field: 'organization', minWidth: 150 },
  { field: 'team' },
  { field: 'modified', sortable: true, unSortIcon: true, sort: 'desc' },
  { field: 'created', sortable: true, unSortIcon: true },
];

export const RESPONSIVE_GRID = {
  flex: 1,
  resizable: true,
};

export const ORDERS_TABLE_PARAMS = {
  detailGridOptions: {
    defaultColDef: RESPONSIVE_GRID,
    columnDefs: ORDERS_COLUMNS,
  },
  getDetailRowData: ({ data, successCallback }) =>
    successCallback(data.ordersData.map(getOrderDataForGrid)),
};
