import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { formatCommentLabelForSlideSearch } from 'components/AllSlidesComments/utilities';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { memo } from 'react';

const compareProps = (prev, next) => {
  return prev.comment === next.comment;
};

export const Comment = memo(
  ({ classes, comment, onDeleteClick, isDeleteEnabled }) => {
    const label = formatCommentLabelForSlideSearch({ comment });

    return (
      <Box
        display="flex"
        flexDirection="column"
        className={classes.commentContainer}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography className={classes.commentCaption}>{label}</Typography>
          {isDeleteEnabled && (
            <Box onClick={onDeleteClick} component="div" display="inline">
              <DeleteForeverIcon
                fontSize="small"
                className={classes.deleteButton}
              />
            </Box>
          )}
        </Box>
        <Typography className={classes.commentText}>{comment.text}</Typography>
      </Box>
    );
  },
  compareProps,
);
