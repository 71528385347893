import { useEffect, useState } from 'react';

import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { getAvailableDataTypes } from 'services/resources/chartData';
import { getOrderByUUID } from 'services/resources/orders';
import { replaceDollarSign } from './utils';
import { useLazyGetSlidesByOrderQuery } from 'store/apis/slidesApi';
import { FETCHING_SLIDES_ERROR_MESSAGE } from 'constants/errorMessages';

export const useChartSources = (orderUUID) => {
  const [sources, setSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { showError } = useSnackbar();

  useEffect(() => {
    setIsLoading(true);

    getAvailableDataTypes(orderUUID)
      .then(({ data }) => setSources(data))
      .catch(({ message }) => showError(message))
      .finally(() => setIsLoading(false));
  }, [orderUUID]);

  return { sources, isLoading };
};

export const useGetSlides = (orderUUID) => {
  const [currentOrder, setCurrentOrder] = useState({});
  const [slides, setSlides] = useState([]);
  const [getSlidesByOrder] = useLazyGetSlidesByOrderQuery();

  const { showError } = useSnackbar();

  const getSlides = async () => {
    try {
      const order = await getOrderByUUID(orderUUID);

      setCurrentOrder(order);

      const slides = await getSlidesByOrder({ orderUUID }).unwrap();
      setSlides(slides.map(replaceDollarSign));
    } catch (e) {
      showError(FETCHING_SLIDES_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    if (orderUUID) {
      getSlides();
    }
  }, [orderUUID]);

  return {
    currentOrder,
    slides,
  };
};
