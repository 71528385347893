import { useCurrentPathologyMapManager } from 'components/PathologyMap/utilities/hooks';
import { PathologyMapFilterPropertiesHeader } from 'components/PathologyMap/PathologyMapFilterProperties/PathologyMapFilterPropertiesHeader';
import { PathologyMapFilterPropertiesItems } from 'components/PathologyMap/PathologyMapFilterProperties/PathologyMapFilterPropertiesItems';
import { PathologyMapSectionWrapper } from 'components/PathologyMap/Shared/PathologyMapSectionWrapper';
import { PathologyMapFilterPropertiesSearch } from 'components/PathologyMap/PathologyMapFilterProperties/PathologyMapFilterPropertiesSearch';
import { PathologyMapVirtualFilterPropertiesList } from 'components/PathologyMap/PathologyMapFilterProperties/PathologyMapVirtualFilterPropertiesList';
import { hideInvisibleCategories } from 'components/PathologyMap/utilities/common';

export const PathologyMapFilterPropertiesSection = ({ presetIndex }) => {
  const { categoriesList, presets } = useCurrentPathologyMapManager();
  const categoriesFromPreset = presets[presetIndex] || [];

  const preparedCategories = hideInvisibleCategories(
    categoriesList,
    categoriesFromPreset,
  );

  return (
    <PathologyMapSectionWrapper>
      <PathologyMapFilterPropertiesHeader />
      <PathologyMapFilterPropertiesSearch />
      {/*need to render all categories otherwise multiple filters will not work*/}
      {preparedCategories.map(({ refinementType }) => (
        <PathologyMapVirtualFilterPropertiesList
          key={refinementType}
          attribute={refinementType}
        />
      ))}
      <PathologyMapFilterPropertiesItems />
    </PathologyMapSectionWrapper>
  );
};
