import { CenteredComponent } from 'components/utilities/CenteredComponent';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { FavoriteSlideIconButton } from 'components/utilities/FavoriteSlide/FavoriteSlideIconButton';

const styles = () => ({
  iconWrapper: { cursor: 'pointer' },
  groupedLabel: { color: 'red' },
});

const useStyles = makeStyles()(styles);

export const FavoriteCell = (params) => {
  const { data, value } = params;
  const { classes } = useStyles();

  return (
    <CenteredComponent>
      {value !== undefined ? (
        <div className={classes.iconWrapper}>
          {/*when slides are grouped by favorite it's needed to convert value to bool*/}
          <FavoriteSlideIconButton
            isStarred={value.toString() === 'true'}
            slideUUID={data?.uuid}
          />
        </div>
      ) : (
        <div className={classes.groupedLabel}>Grouped</div>
      )}
    </CenteredComponent>
  );
};
