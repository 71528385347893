import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Grid, Typography } from '@mui/material';
import {
  ALL_ORDERS_TAB_VALUE,
  DATA_MANAGEMENT_HISTORY_TABS_IDS,
} from 'components/PathologyMap/constants/common';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { useOrdersTabContentStyles } from 'components/UserProfile/styles';
import { SlideTypes } from 'components/UserProfile/constants';
import { usePrepareChangeLog } from 'components/UserProfile/hooks';
import {
  getHeaderColumns,
  onSlidesClick,
} from 'components/UserProfile/utilities';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';

import { useSlideInfoForPathologyMapStyles } from 'components/PathologyMap/constants/styles';

import { ChangeLogRow } from 'components/TeamOverview/components/ManageDataCard/ChangeLogRow';
import { ChangeLogHeader } from 'components/TeamOverview/components/ManageDataCard/ChangeLogHeader';
import { HeaderItem } from 'components/TeamOverview/components/ManageDataCard/HeaderItem';

export const ChangeLog = ({ setViewMode, ordersForHistory }) => {
  const { classes: classesFromPathologyMap } =
    useSlideInfoForPathologyMapStyles();
  const { classes: orderTabStyles } = useOrdersTabContentStyles();
  const classes = { ...classesFromPathologyMap, ...orderTabStyles };
  const history = useHistory();
  const [currentTabValue, setCurrentTabValue] = useState(ALL_ORDERS_TAB_VALUE);

  const { selectedTabOrders, isOrdersLoading } = usePrepareChangeLog(
    DATA_MANAGEMENT_HISTORY_TABS_IDS[currentTabValue],
    ordersForHistory,
  );

  const handleTabChange = useCallback((_event, tabValue) => {
    setCurrentTabValue(tabValue);
  }, []);

  return (
    <Card
      header={
        <ChangeLogHeader
          classes={classes}
          handleTabChange={handleTabChange}
          setViewMode={setViewMode}
          currentTabValue={currentTabValue}
        />
      }
    >
      <Box className={classes.bodyColumn}>
        <Grid container className={classes.container}>
          {getHeaderColumns(classes).map((item, index) => (
            <HeaderItem
              key={index}
              xs={item.xs}
              text={item.text}
              className={classes.column}
              classes={classes}
            />
          ))}
        </Grid>
        <Box className={classes.ordersList} sx={{ minWidth: 600 }}>
          {isOrdersLoading ? (
            <Box className={classes.spinnerContainer}>
              <Spinner />
            </Box>
          ) : selectedTabOrders.length ? (
            selectedTabOrders.map((event) => (
              <ChangeLogRow
                key={event.id}
                onIFSlidesClick={() =>
                  onSlidesClick(event.order, history, SlideTypes.IF)
                }
                onDefaultSlidesClick={() =>
                  onSlidesClick(event.order, history, SlideTypes.IHC)
                }
                event={event}
                classes={classes}
              />
            ))
          ) : (
            <Box height="100%" display="flex" alignItems="center">
              <Typography>No events</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};
