import {
  PATHOLOGIST_WORKLIST_ORDERS,
  PATHOLOGY_CONSULTATION_FINAL_REPORT_TEAM_FILE_ATTACHMENTS,
  PATHOLOGY_CONSULTATION_ORDER_INSTANCE_NAME,
  PATHOLOGY_CONSULTATION_ORDERS,
  PATHOLOGY_CONSULTATION_PROTOCOL_FILE_ATTACHMENTS,
  PATHOLOGY_CONSULTATION_TEAM_FILE_ATTACHMENTS,
} from 'services/resources/apiConstants';
import { backendAPI } from 'services/backendAPI';
import { getCachedGenericResource } from 'services/resources/commonResources';

export const createPathologyConsultationOrder = ({ postParams }) => {
  const url = `${PATHOLOGY_CONSULTATION_ORDERS}`;
  return backendAPI.post(url, postParams).then((response) => {
    if (response.status === 200) {
      return response.data;
    }
  });
};

export const updatePathologyConsultationOrder = ({
  consultUUID,
  postParams,
}) => {
  const url = `${PATHOLOGY_CONSULTATION_ORDERS}${consultUUID}/`;

  return backendAPI.post(url, postParams).then((response) => {
    if (response.status === 200) {
      return response.data;
    }
  });
};
export const getPathologyConsultationInstance = ({ consultUUID }) => {
  const url = PATHOLOGY_CONSULTATION_ORDER_INSTANCE_NAME.replace(
    ':consultUUID',
    consultUUID,
  );
  return backendAPI.get(url).then((response) => {
    if (response.status === 200) {
      return response.data;
    }
  });
};
export const getPathologyConsultationOrders = () => {
  return getCachedGenericResource(PATHOLOGY_CONSULTATION_ORDERS);
};

export const getPathologistWorklistOrders = () => {
  return getCachedGenericResource(PATHOLOGIST_WORKLIST_ORDERS);
};

export const getPathologyConsultationTeamFileAttachments = ({
  consultUUID,
}) => {
  const url = PATHOLOGY_CONSULTATION_TEAM_FILE_ATTACHMENTS.replace(
    ':consultUUID',
    consultUUID,
  );
  return backendAPI.get(url).then((response) => {
    if (response.status === 200) {
      return response.data;
    }
  });
};

export const getPathologyConsultationProtocolFileAttachments = ({
  consultUUID,
}) => {
  const url = PATHOLOGY_CONSULTATION_PROTOCOL_FILE_ATTACHMENTS.replace(
    ':consultUUID',
    consultUUID,
  );
  return backendAPI.get(url).then((response) => {
    if (response.status === 200) {
      return response.data;
    }
  });
};

export const getPathologyConsultationFinalReportAttachments = ({
  consultUUID,
}) => {
  const url = PATHOLOGY_CONSULTATION_FINAL_REPORT_TEAM_FILE_ATTACHMENTS.replace(
    ':consultUUID',
    consultUUID,
  );
  return backendAPI.get(url).then((response) => {
    if (response.status === 200) {
      return response.data;
    }
  });
};

export const uploadTeamFileAttachmentsToPathologyConsultations = ({
  consultUUID,
  postParams,
}) => {
  const url = PATHOLOGY_CONSULTATION_TEAM_FILE_ATTACHMENTS.replace(
    ':consultUUID',
    consultUUID,
  );

  return backendAPI.post(url, postParams).then((response) => response.data);
};

export const uploadFinalReportTeamFileAttachmentsToPathologyConsultations = ({
  consultUUID,
  postParams,
}) => {
  const url = PATHOLOGY_CONSULTATION_FINAL_REPORT_TEAM_FILE_ATTACHMENTS.replace(
    ':consultUUID',
    consultUUID,
  );

  return backendAPI.post(url, postParams).then((response) => response.data);
};

export const uploadProtocolsTeamFileAttachmentsToPathologyConsultations = ({
  consultUUID,
  postParams,
}) => {
  const url = PATHOLOGY_CONSULTATION_PROTOCOL_FILE_ATTACHMENTS.replace(
    ':consultUUID',
    consultUUID,
  );

  return backendAPI.post(url, postParams).then((response) => response.data);
};
