import { useTitle } from 'components/utilities/hooks/useTitle';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIHCOptimizationStyles } from 'components/IHCOptimizationViewer/IhcOptimizationStyles';
import { useHistory, useRouteMatch } from 'react-router';
import { AppContext } from 'services/context';
import { slideDataSerializer } from 'components/utilities/gridDataSerializers';
import { getLisaSlideLink } from 'components/utilities/getLinks';
import { SLIDE_VIEWER_URL } from 'constants/urls';
import {
  Button,
  Divider,
  IconButton,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import { agGridDefaultColDef, selectFirstRow } from 'components/utilities/grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/Shared/TabPanel';
import { AgGridReact } from 'ag-grid-react';
import { IHCOptimizationSlideColumnDefs } from 'components/utilities/AgGridCols/AgGridColumns';
import Grid from '@mui/material/Grid';
import { SingleQCSlideView } from 'components/QCViewer/QCViewerComponents';
import Container from '@mui/material/Container';
import { LockOpenRounded, LockRounded } from '@mui/icons-material';
import GridLayout from 'react-grid-layout';
import Box from '@mui/material/Box';
import { SlideSelectionModal } from 'components/Modals/IHCOptimization/SlideSelectionModal';
import { RenderOrderComment } from 'components/IHCOptimizationViewer/RenderOrderComment';
import {
  agGridStyle,
  getIHCSlideOptimizationLayout,
  resizeHandles,
  rowHeight,
  rowSelection,
} from 'components/IHCOptimizationViewer/utilities';
import {
  IHCOptimizationSlideSelectionOpenModalName,
  OrderCommentsNotifyClientModalName,
} from 'components/Modals/constants';
import { NotifyOrderCommentsModal } from 'components/Modals/IHCOptimization/NotifyOrderCommentsModal';
import { GoToOrderComponent } from 'components/OrdersList/GoToOrderComponent';
import { ShowStaffUserOnly } from 'components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly';
import {
  useGetOrderByUUIDQuery,
  useLazyGetOrderCommentsQuery,
} from 'store/apis/orderApi';
import {
  FETCH_ORDER_ERROR_MESSAGE,
  FETCHING_COMMENTS_ERROR_MESSAGE,
  FETCHING_SLIDES_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { MultiSlideViewItem } from 'components/ImageViewer/MultiSlideViewItem';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import {
  useLazyGetNeedsQCSlideQuery,
  useLazyGetSlidesByOrderQuery,
} from 'store/apis/slidesApi';
import { dividerStyle } from 'components/IHCOptimizationViewer/styles';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

const slidesViewerTabIndexConstant = 0;
const orderCommentsTabIndexConstant = 1;

export const IHCOptimizationView = () => {
  const { classes } = useIHCOptimizationStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const ref = useRef(null);

  const [rowData, setRowData] = useState([]);

  // for the order comments, let a user select which comments to email the client
  const [selectedOrderComments, setSelectedOrderComments] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [showFloatingMacroImage, setShowFloatingMacroImage] = useState(false);
  const [thumbnail, setThumbnail] = useState('');

  const [gridApi, setGridApi] = useState(null);
  const [currentTab, setCurrentTab] = useState(slidesViewerTabIndexConstant);

  const [draggable, setDraggable] = useState(false);

  const orderUUID = match.params.orderUUID;

  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const context = useContext(AppContext);
  const { handleContextModalChange } = context;

  const { error, data: order } = useGetOrderByUUIDQuery(orderUUID, {
    skip: !orderUUID,
  });
  const [getNeedsQCSlide] = useLazyGetNeedsQCSlideQuery();
  const [getOrderComments, { data: orderComments }] =
    useLazyGetOrderCommentsQuery();
  const [getSlidesByOrder] = useLazyGetSlidesByOrderQuery();

  const { showError } = useSnackbar();

  useQueryError(error, FETCH_ORDER_ERROR_MESSAGE);

  const pageTitle = order?.name
    ? `${order.name} - IHC Optimization`
    : 'IHC Optimization';
  useTitle(pageTitle);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const _updateSelectedRows = () => {
    if (gridApi) {
      const currentSelectedRows = gridApi.getSelectedRows();
      setSelectedRows(currentSelectedRows);
    }
  };

  const updateSelectedRows = useCallback(_updateSelectedRows, [gridApi]);

  useEffect(() => {
    // if gridAPI not loaded yet, don't run
    if (!gridApi) {
      return;
    }

    if (!orderUUID) {
      return;
    }

    const updateSlides = () => {
      getSlidesByOrder({ orderUUID: orderUUID })
        .unwrap()
        .then((response) => {
          if (response) {
            const rows = response.map(slideDataSerializer);
            setRowData(rows);
          }
        })
        .catch(() => showError(FETCHING_SLIDES_ERROR_MESSAGE));

      getOrderComments({ orderUUID: orderUUID });
    };

    updateSlides();
  }, [gridApi, orderUUID, updateSelectedRows]);

  const getGridContextMenuItems = (params) => {
    // if there is no data, dont show any context, otherwise errors
    if (!params.node?.data) {
      return [];
    }

    const row = params.node.data;
    const lisaSlideLink = getLisaSlideLink({ slide: row });
    const modalOnChange = handleContextModalChange(
      IHCOptimizationSlideSelectionOpenModalName,
    );

    return [
      {
        name: 'Add Order Comment',
        action: () => {
          if (row) {
            modalOnChange();
          }
        },
      },
      {
        name: 'View Slide',
        action: () => {
          if (row) {
            const url = SLIDE_VIEWER_URL.replace(':slideUUID', row.uuid);
            history.push(url);
          }
        },
      },
      {
        name: 'View Slide (LISA)',
        action: () => {
          if (row) {
            window.open(lisaSlideLink, '_blank');
          }
        },
      },
    ];
  };

  const handleFloatingThumbnailChange = () => {
    setShowFloatingMacroImage(!showFloatingMacroImage);
  };

  const isSlideFullInfoNeeded = (slide) => !slide.additional_data;

  const handleRowDeselect = (slide) => {
    setSelectedRows((selectedSlides) =>
      selectedSlides.filter(
        (selectedSlide) => selectedSlide.uuid !== slide.uuid,
      ),
    );
  };

  const onRowSelected = ({ node, data, api }) => {
    if (!node.selected) {
      handleRowDeselect(data);
      return;
    }

    const { uuid } = data;
    const slidePromise = isSlideFullInfoNeeded(data)
      ? getNeedsQCSlide({ uuid }).unwrap()
      : Promise.resolve(data);

    slidePromise
      .then((slide) => {
        if (api && isSlideFullInfoNeeded(data)) {
          api.updateRowData?.({
            update: [slide],
          });
        }

        updateSelectedRows();
        const thumbnail = slide.large_macro_url || '';
        setThumbnail(thumbnail);
      })
      .catch(() => showError(FETCHING_SLIDES_ERROR_MESSAGE));
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPostOrderCommentSuccess = () => {
    getOrderComments({ orderUUID: orderUUID })
      .unwrap()
      .catch(() => showError(FETCHING_COMMENTS_ERROR_MESSAGE))
      .finally(setCurrentTab(orderCommentsTabIndexConstant));
  };

  const renderOrderCommentsNotifyClientModal = () => {
    if (!selectedRows) {
      return null;
    }

    if (!context[OrderCommentsNotifyClientModalName]) {
      return null;
    }

    return (
      <NotifyOrderCommentsModal
        order={order}
        orderComments={selectedOrderComments}
        setOrderComments={setSelectedOrderComments}
        onPostSuccess={onPostOrderCommentSuccess}
      />
    );
  };

  const renderBulkIHCOptimizationSlideCommentModal = () => {
    if (!selectedRows) {
      return null;
    }

    if (!context[IHCOptimizationSlideSelectionOpenModalName]) {
      return null;
    }

    return (
      <SlideSelectionModal
        orderUUID={orderUUID}
        order={order}
        selectedRows={selectedRows}
        onPostOrderCommentSuccess={onPostOrderCommentSuccess}
      />
    );
  };

  const renderFloatingThumbnail = () => {
    if (!thumbnail) {
      return null;
    }

    if (!showFloatingMacroImage) {
      return null;
    }

    return (
      <div className={classes.floatingThumbnail}>
        <Paper className={classes.floatingPaper} elevation={1}>
          <img
            className={classes.floatingImage}
            src={thumbnail}
            alt={'Thumbnail'}
          />
        </Paper>
      </div>
    );
  };

  const openSlideSelectionModal = (params) => {
    const { data } = params.node;
    const modalOnChange = handleContextModalChange(
      IHCOptimizationSlideSelectionOpenModalName,
    );
    // if you group by something, you aren't able to navigate to the slide viewer
    if (data && data.uuid) {
      modalOnChange();
    }
  };

  const openOrderCommentsNotifyClientModal = () => {
    const modalOnChange = handleContextModalChange(
      OrderCommentsNotifyClientModalName,
    );
    modalOnChange();
  };

  const getRowId = (slide) => slide.data.uuid;

  const handleRowDataChanged = ({ api }) => {
    selectFirstRow(api);
  };

  const renderSlidesOrderCommentsData = () => {
    return (
      <Fragment>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabsChange}
        >
          <Tab label="Slides" />
          <Tab label="Order Comments" />
        </Tabs>
        <TabPanel value={currentTab} index={slidesViewerTabIndexConstant}>
          <span className={classes.thumbnailSpan}>
            <Switch
              checked={showFloatingMacroImage}
              onChange={handleFloatingThumbnailChange}
              value="showFloatingMacroImage"
            />
            Display Thumbnail
          </span>
          <Typography align={'left'}>
            Showing {rowData.length} Slides
          </Typography>
          <div className="ag-theme-balham" style={agGridStyle}>
            <AgGridReact
              enableSorting={true}
              enableFilter={true}
              enableColResize={true}
              rowDragManaged={true}
              rowGroupPanelShow={'always'}
              rowData={rowData}
              defaultColDef={agGridDefaultColDef}
              columnDefs={IHCOptimizationSlideColumnDefs}
              rowSelection={rowSelection}
              onGridReady={onGridReady}
              onRowDataChanged={handleRowDataChanged}
              onCellDoubleClicked={openSlideSelectionModal}
              onRowSelected={onRowSelected}
              getContextMenuItems={getGridContextMenuItems}
              getRowId={getRowId}
            />
          </div>
        </TabPanel>
        <TabPanel value={currentTab} index={orderCommentsTabIndexConstant}>
          <Fragment>{renderOrderCommentsTab()}</Fragment>
        </TabPanel>
      </Fragment>
    );
  };

  const renderOrderCommentsTab = () => {
    return (
      <Box mb={1} p={1}>
        {orderComments?.map((orderComment) => {
          return (
            <Fragment key={orderComment.uuid}>
              <RenderOrderComment
                orderComment={orderComment}
                orderCommentsSelected={selectedOrderComments}
                setOrderCommentsSelected={setSelectedOrderComments}
              />
              <Divider style={dividerStyle} />
            </Fragment>
          );
        })}
      </Box>
    );
  };

  const onCommentsTab = currentTab === orderCommentsTabIndexConstant;

  const renderMultiSlideSection = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {selectedRows.map((row) => (
          <MultiSlideViewItem slide={row} key={row.uuid} />
        ))}
      </Grid>
    );
  };

  const handleDragLockClick = () => {
    setDraggable((prevState) => !prevState);
  };

  const renderTitle = () => {
    return (
      <div className={classes.commentHeader}>
        <Typography className={classes.pageTitle} variant={'h4'}>
          {pageTitle}
        </Typography>
        <Box mr={5}>
          <ShowStaffUserOnly>
            <GoToOrderComponent />
          </ShowStaffUserOnly>
        </Box>
      </div>
    );
  };

  const renderOrderSlidesSection = () => {
    const useMultiSlide = selectedRows.length > 1;

    return (
      <div key={0}>
        <Box p={2} height={'100%'}>
          {useMultiSlide
            ? renderMultiSlideSection()
            : selectedRows.map((row) => (
                <SingleQCSlideView slide={row} key={row.uuid} />
              ))}
        </Box>
      </div>
    );
  };

  const renderSlidesOrderCommentsSection = () => {
    return (
      <div key={1}>
        <Box p={2} height={'100%'}>
          {/*have this here so it's always on the top of the page*/}
          {onCommentsTab ? (
            <Grid container justifyContent="flex-end">
              <Box mr={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size={'small'}
                  disabled={selectedOrderComments.length === 0}
                  onClick={openOrderCommentsNotifyClientModal}
                >
                  Send Attached ({selectedOrderComments.length}) to Client
                </Button>
              </Box>
            </Grid>
          ) : null}
          {renderSlidesOrderCommentsData()}
        </Box>
      </div>
    );
  };

  const currentHeight = ref.current ? ref.current.offsetHeight : 800;
  const layout = getIHCSlideOptimizationLayout(currentHeight);

  return (
    <div className={classes.root}>
      <Container ref={ref} maxWidth={false} disableGutters={true}>
        <IconButton
          title="Drag'n'Drop Lock"
          size="small"
          className={classes.lockButton}
          color="secondary"
          onClick={handleDragLockClick}
        >
          {draggable ? <LockOpenRounded /> : <LockRounded />}
        </IconButton>
        <Grid item direction="row">
          <Grid item xs={12}>
            {renderTitle()}
          </Grid>
          <Grid item>
            <GridLayout
              className="layout"
              layout={layout}
              resizeHandles={resizeHandles}
              cols={12}
              rowHeight={rowHeight}
              draggableCancel={'.ag-theme-balham'}
              width={ref.current ? ref.current.offsetWidth : 800}
              isDraggable={draggable}
            >
              {renderOrderSlidesSection()}
              {renderSlidesOrderCommentsSection()}
            </GridLayout>
          </Grid>
        </Grid>
      </Container>
      {renderFloatingThumbnail()}
      {renderBulkIHCOptimizationSlideCommentModal()}
      {renderOrderCommentsNotifyClientModal()}
    </div>
  );
};
