import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { LOGIN_URL } from 'constants/urls';

export const LogoutPage = () => {
  useEffect(() => {
    Object.keys(localStorage).forEach((key) => localStorage.removeItem(key));
  }, []);

  return <Redirect to={LOGIN_URL} />;
};
