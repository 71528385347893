import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSamplesStatusBarStyles } from 'components/OrderForm/SamplesFormV2/components/SamplesStatusBar/styles';

export const SamplesStatusBar = memo(({ count = 0 }) => {
  const { classes } = useSamplesStatusBarStyles();

  return (
    <Box className={classes.root}>
      <Typography component="span">Samples Added:&nbsp;</Typography>
      <Typography component="span" className={classes.value}>
        {count}
      </Typography>
    </Box>
  );
});
