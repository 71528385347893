import { ArrowOutward } from '@mui/icons-material';
import Box from '@mui/material/Box';

export const AddAntibodyButton = ({ handleAntibodiesListModalOpen }) => {
  const labelStyles = {
    display: 'flex',
    gap: '4px',
    textTransform: 'uppercase',
    fontSize: '11px',
    cursor: 'pointer',
    alignItems: 'center',
  };

  const iconStyles = { fontSize: '17px' };

  return (
    <Box onClick={handleAntibodiesListModalOpen} sx={labelStyles}>
      SELECT FROM ANTIBODIES CATALOG
      <ArrowOutward sx={iconStyles} />
    </Box>
  );
};
