import { Grid } from '@mui/material';
import { ManageDataCard } from 'components/TeamOverview/components/ManageDataCard/ManageDataCard';
import { DataUsageCard } from 'components/TeamOverview/components/DataUsageCard/DataUsageCard';
import { ContributedDataCard } from 'components/TeamOverview/components/ContributedDataCard/ContributedDataCard';
import { useOrdersForDataManagement } from 'components/TeamOverview/hooks';
import { useState } from 'react';
import { getListItems } from 'components/TeamOverview/constants';
import { useIsDemoUser } from 'components/utilities/hooks/useIsDemoUser';
import { CHANGELOG_VIEW, DEFAULT_VIEW } from 'components/UserProfile/constants';

export const DataManagementSection = ({
  team,
  slideType,
  setSlideType,
  isTeamsLoading,
}) => {
  const [selectedOrdersType, setSelectedOrdersType] = useState(
    getListItems(slideType)[0].value,
  );
  const [viewMode, setViewMode] = useState(DEFAULT_VIEW);
  const isDemoAccount = useIsDemoUser();
  const { isLoading, orders, ordersForHistory } = useOrdersForDataManagement(
    team,
    selectedOrdersType,
    isDemoAccount,
  );

  const isChangeLog = viewMode === CHANGELOG_VIEW;

  return (
    <Grid container spacing={2}>
      <Grid item xs={isChangeLog ? 12 : 8.5}>
        <ManageDataCard
          orders={orders}
          ordersForHistory={ordersForHistory}
          isOrdersLoading={isLoading}
          team={team}
          slideType={slideType}
          setSlideType={setSlideType}
          setSelectedOrdersType={setSelectedOrdersType}
          selectedOrdersType={selectedOrdersType}
          isDemoAccount={isDemoAccount}
          viewMode={viewMode}
          setViewMode={setViewMode}
        />
      </Grid>
      {!isChangeLog && (
        <Grid item xs={3.5}>
          <DataUsageCard isLoading={isTeamsLoading} team={team} />
          <ContributedDataCard isLoading={isTeamsLoading} team={team} />
        </Grid>
      )}
    </Grid>
  );
};
