import {
  serializedTrueFalseOptionsToSelectInput,
  serializedTurnaroundTime,
} from 'components/OrderForm/constants';
import { object, string } from 'yup';

export const getAdditionalQuestionsFormDefaultValues = (order) => ({
  ship_back_samples:
    serializedTrueFalseOptionsToSelectInput[order.ship_back_samples],
  turnaround_image_analysis_pathology_consultation_days:
    order.turnaround_image_analysis_pathology_consultation_days
      ? serializedTurnaroundTime[
          order.turnaround_image_analysis_pathology_consultation_days
        ]
      : { label: 'Ten Days', value: 10 },
  ship_back_unstained_slides:
    serializedTrueFalseOptionsToSelectInput[order.ship_back_unstained_slides],
  ship_back_stained_slides:
    serializedTrueFalseOptionsToSelectInput[order.ship_back_stained_slides],
  client_fedex_account: order.client_fedex_account,
});

export const getLatestBillingContact = (contacts) => {
  const sortedContacts = [...contacts].sort(
    (a, b) => new Date(b.created) - new Date(a.created),
  );
  return sortedContacts[0];
};

export const additionalReviewValidationSchema = (unstainedSlidesRequested) =>
  object().shape({
    ship_back_unstained_slides: unstainedSlidesRequested
      ? object().required('This field is required!').nullable()
      : object().nullable(),
    ship_back_samples: object().nullable(),
    ship_back_stained_slides: object().nullable(),
    client_fedex_account: string().when(
      [
        'ship_back_samples',
        'ship_back_unstained_slides',
        'ship_back_stained_slides',
      ],
      {
        is: (
          shipBackSamples,
          shipBackUnstainedSlides,
          shipBackStainedSlides,
        ) => {
          return (
            shipBackSamples?.value ||
            shipBackUnstainedSlides?.value ||
            shipBackStainedSlides?.value
          );
        },
        then: string()
          .required('Please specify a Client FedEx number!')
          .nullable(),
        otherwise: string().nullable(),
      },
    ),
  });
