import { createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_AIOSYN_MODELS_OPACITY,
  DEFAULT_AIRA_MODELS_OPACITY,
  DEFAULT_QC_MODELS_OPACITY,
  DEFAULT_SEGMENTATION_MODELS_OPACITY,
  DEFAULT_TISSUE_MODELS_OPACITY,
} from 'components/AITools/constants/common';

const initialState = {
  currentSlideAIModels: [],
  lastChangedAIModelOptions: null,
  airaModelsOpacity: DEFAULT_AIRA_MODELS_OPACITY,
  aiosynModelsOpacity: DEFAULT_AIOSYN_MODELS_OPACITY,
  qcModelsOpacity: DEFAULT_QC_MODELS_OPACITY,
  tissueModelsOpacity: DEFAULT_TISSUE_MODELS_OPACITY,
  segmentationModelsOpacity: DEFAULT_SEGMENTATION_MODELS_OPACITY,
  mlDataLoadingMap: {},
};

const aiToolsSlice = createSlice({
  name: 'aiTools',
  initialState,
  reducers: {
    setCurrentSlideAIModels: (state, { payload }) => {
      state.currentSlideAIModels = payload;
    },
    setLastChangedAIModelOptions: (state, { payload }) => {
      state.lastChangedAIModelOptions = payload;
    },
    setAiraModelsOpacity: (state, { payload }) => {
      state.airaModelsOpacity = payload;
    },
    setAiosynModelsOpacity: (state, { payload }) => {
      state.aiosynModelsOpacity = payload;
    },
    setQCModelsOpacity: (state, { payload }) => {
      state.qcModelsOpacity = payload;
    },
    setTissueModelsOpacity: (state, { payload }) => {
      state.tissueModelsOpacity = payload;
    },
    setSegmentationModelsOpacity: (state, { payload }) => {
      state.segmentationModelsOpacity = payload;
    },
    addCurrentSlideAIModel: (state, { payload }) => {
      state.currentSlideAIModels.push(payload);
    },
    removeCurrentSlideAIModel: (state, { payload }) => {
      state.currentSlideAIModels = state.currentSlideAIModels.filter(
        (model) => model !== payload,
      );
    },
    setMLDataLoadingMap: (state, { payload }) => {
      const { modelName, isLoading } = payload;
      state.mlDataLoadingMap = {
        ...state.mlDataLoadingMap,
        [modelName]: isLoading,
      };
    },
  },
});

export const {
  setCurrentSlideAIModels,
  setLastChangedAIModelOptions,
  setAiraModelsOpacity,
  setAiosynModelsOpacity,
  setQCModelsOpacity,
  setTissueModelsOpacity,
  setSegmentationModelsOpacity,
  addCurrentSlideAIModel,
  removeCurrentSlideAIModel,
  setMLDataLoadingMap,
} = aiToolsSlice.actions;

export const aiToolsReducer = aiToolsSlice.reducer;

export const selectCurrentSlideAIModels = (state) =>
  state.aiTools.currentSlideAIModels;

export const selectLastChangedAIModelOptions = (state) =>
  state.aiTools.lastChangedAIModelOptions;

export const selectAiraModelsOpacity = (state) =>
  state.aiTools.airaModelsOpacity;

export const selectAiosynModelsOpacity = (state) =>
  state.aiTools.aiosynModelsOpacity;

export const selectQCModelsOpacity = (state) => state.aiTools.qcModelsOpacity;

export const selectTissueModelsOpacity = (state) =>
  state.aiTools.tissueModelsOpacity;

export const selectSegmentationModelsOpacity = (state) =>
  state.aiTools.segmentationModelsOpacity;

export const selectMlDataLoadingMap = (state) => state.aiTools.mlDataLoadingMap;
