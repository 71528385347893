import { makeStyles } from 'tss-react/mui';

export const useQCViewerStyles = makeStyles()((theme) => ({
  root: {
    height: '98vh',
    display: 'flex',
    width: 'calc(100% - 24px)',
    // somehow adding a margin left stops the OSD from shaking randomly
    marginLeft: '0.5rem',
  },
  floatingThumbnail: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    'z-index': 5,
    width: '14%',
  },
  floatingPaper: {
    height: '100%',
    width: '100%',
  },
  floatingImage: {
    width: '100%',
    height: '100%',
  },
  gridContainer: {
    height: '100%',
  },
  thumbnailSpan: {
    display: 'inline-block',
  },
  lockGrid: {
    maxWidth: theme.spacing(3),
  },
  lockButton: {
    border: 'none',
    outline: 'none!important',
    padding: 0,
    'z-index': 1,
    top: '1.75rem',
    position: 'absolute',
  },
}));

export const agGridStyle = {
  backgroundColor: 'transparent',
  marginTop: '0.24rem',
  height: 'calc(100% - 168px)',
  width: '100%',
};
