export const DiscountIcon = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#a)" fill="#EC6A7A">
        <path d="m19.798 9.5014-1.9765-2.0429 0.3983-2.8149c0.0542-0.38283-0.205-0.73958-0.5859-0.80638l-2.7999-0.49092-1.3323-2.5113c-0.1812-0.34164-0.6008-0.47764-0.9479-0.30801l-2.5541 1.2487-2.5539-1.2486c-0.34747-0.16982-0.76672-0.033543-0.94801 0.30801l-1.3323 2.5113-2.7999 0.49092c-0.38083 0.0667-0.6401 0.42354-0.58591 0.80638l0.39822 2.8149-1.9766 2.0428c-0.26883 0.27781-0.26883 0.71884 0 0.99674l1.9765 2.043-0.39822 2.815c-0.05409 0.3827 0.20508 0.7395 0.58582 0.8063l2.8 0.4913 1.3324 2.5109c0.1812 0.3416 0.60054 0.4779 0.94792 0.308l2.5539-1.2482 2.5542 1.2482c0.1012 0.0495 0.2084 0.073 0.3142 0.073 0.2572 0 0.5052-0.139 0.6336-0.381l1.3324-2.5109 2.8-0.4913c0.3807-0.0668 0.64-0.4236 0.5858-0.8063l-0.3983-2.815 1.9766-2.043c0.2689-0.2779 0.2689-0.71884-1e-4 -0.99674zm-3.2503 2.2949c-0.1537 0.1588-0.2255 0.3798-0.1945 0.5988l0.3502 2.4753-2.4621 0.432c-0.2177 0.0382-0.4057 0.1747-0.5093 0.37l-1.1716 2.208-2.246-1.0976c-0.0993-0.0486-0.207-0.0729-0.31477-0.0729-0.1077 0-0.2154 0.0243-0.31469 0.0729l-2.2458 1.0976-1.1716-2.208c-0.10359-0.1953-0.29157-0.3318-0.50927-0.37l-2.4621-0.432 0.35015-2.4753c0.03096-0.2189-0.04081-0.4399-0.19457-0.5987l-1.738-1.7966 1.738-1.7964c0.15367-0.15883 0.22553-0.37987 0.19457-0.59871l-0.35015-2.4752 2.462-0.43167c0.21779-0.03813 0.40577-0.17479 0.50946-0.37003l1.1715-2.2083 2.2457 1.098c0.19859 0.09719 0.43076 0.09719 0.62956 0l2.2459-1.098 1.1716 2.2083c0.1036 0.19524 0.2916 0.3319 0.5094 0.37003l2.4621 0.43167-0.3503 2.4752c-0.031 0.21894 0.0408 0.43988 0.1946 0.59881l1.7379 1.7962-1.7379 1.7965z" />
        <path d="m7.7239 9.4584c0.95699 0 1.7355-0.77848 1.7355-1.7353 0-0.95718-0.77847-1.7358-1.7355-1.7358-0.95709 0-1.7357 0.77867-1.7357 1.7358 0 0.9569 0.77867 1.7353 1.7357 1.7353zm0-2.3243c0.32463 0 0.58868 0.26424 0.58868 0.58906 0 0.32454-0.26414 0.58849-0.58868 0.58849-0.32473 0-0.58888-0.26404-0.58888-0.58849 0-0.32482 0.26415-0.58906 0.58888-0.58906z" />
        <path d="m13.463 6.5381c-0.2238-0.22391-0.587-0.22401-0.8108 0l-6.1131 6.113c-0.22391 0.2239-0.22391 0.587 0 0.8109 0.112 0.112 0.25869 0.1679 0.40548 0.1679 0.14669 0 0.29348-0.056 0.40548-0.1679l6.113-6.113c0.2238-0.22391 0.2238-0.58697-1e-4 -0.81087z" />
        <path d="m12.278 10.541c-0.9569 0-1.7352 0.7785-1.7352 1.7353 0 0.9572 0.7784 1.7358 1.7352 1.7358 0.9572 0 1.7359-0.7786 1.7359-1.7358 0-0.9568-0.7787-1.7353-1.7359-1.7353zm0 2.3244c-0.3244 0-0.5884-0.2642-0.5884-0.589 0-0.3245 0.264-0.5885 0.5884-0.5885 0.3248 0 0.5891 0.264 0.5891 0.5885 0 0.3247-0.2642 0.589-0.5891 0.589z" />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="20" height="20" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
