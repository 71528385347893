import { useEffect, useMemo, useState } from 'react';
import {
  filterMlModelsBySubstring,
  filterModelsByState,
  parseMlModels,
  stringifyModels,
} from 'components/TeamOverview/components/AiComputeTransactionsCard/utilities';
import { ALL_TAB } from 'components/TeamOverview/components/AiComputeTransactionsCard/constants';
import { getMLModels } from 'services/resources/commonResources';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { FAIL_GET_SLIDE_ML_DATA } from 'constants/errorMessages';

export const useMlModels = (team, tabValue = ALL_TAB, filter = '') => {
  const [mlModels, setMlModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stringifedModels, setStringifiedModels] = useState(null);
  const { showError } = useSnackbar();

  useEffect(() => {
    setIsLoading(true);
    getMLModels()
      .then((res) => {
        const filteredByTeamModels = res.filter(
          (model) => model.team.uuid === team.uuid,
        );
        const parsedMlModels = parseMlModels(filteredByTeamModels);
        setMlModels(parsedMlModels);
        setStringifiedModels(stringifyModels(parsedMlModels));
        setIsLoading(false);
      })
      .catch(() => showError(FAIL_GET_SLIDE_ML_DATA));
  }, []);

  const filteredModels = useMemo(() => {
    const filteredBySubstringModels = filter
      ? filterMlModelsBySubstring(stringifedModels, filter)
      : mlModels;

    const filteredByStateModels = filterModelsByState(
      filteredBySubstringModels,
      tabValue,
    );

    return filteredByStateModels;
  }, [tabValue, filter, mlModels]);

  return {
    isLoading,
    data: filteredModels,
  };
};
