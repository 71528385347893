import { FETCH_ORDERS_ERROR_MESSAGE } from 'constants/errorMessages';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentUserEmailSelector } from 'store/slices/userDetailsSlice';
import { useGetOrdersQuery } from 'store/apis/orderApi';
import { getOrderHistory } from 'services/resources/orders';
import { prepareShortenedOrderHistory } from 'components/OrderDetails/Tabs/OrderProgressTab/utilities';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';

export const useOtherOrders = (currentOrder) => {
  const email = useSelector(currentUserEmailSelector);
  const { data: orders, isLoading, error } = useGetOrdersQuery();
  useQueryError(error, FETCH_ORDERS_ERROR_MESSAGE);

  const userOrders = useMemo(() => {
    if (!orders) return [];

    return orders.filter((data) => {
      return (
        data.submitted_user?.email === email && data.uuid !== currentOrder.uuid
      );
    });
  }, [orders, email, currentOrder]);

  return {
    otherOrders: userOrders,
    isLoading,
  };
};

export const useOrderHistory = (order) => {
  const [orderHistory, setOrderHistory] = useState([]);

  useEffect(() => {
    if (!order) return;

    getOrderHistory({ orderUUID: order.uuid }).then((response) => {
      const shortenedHistory = prepareShortenedOrderHistory(response);
      setOrderHistory(shortenedHistory);
    });
  }, [order]);

  return {
    orderHistory,
  };
};
