import { getShortModelName } from 'components/AITools/utilities/common';
import { useDispatch } from 'react-redux';
import { useLazyGetSlideMLDataQuery } from 'store/apis/slidesApi';
import {
  removeSlideAttributeFromList,
  updateSelectedRow,
} from 'store/slices/slidesListSlice/slidesListSlice';
import { removeSlideAttribute, updateSlide } from 'store/slices/slidesSlice';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const useSlidesMlData = () => {
  const dispatch = useDispatch();
  const { showError } = useSnackbar();

  const [getSlideMLData] = useLazyGetSlideMLDataQuery();

  const getSlidesMLData = (slides, modelName) =>
    Promise.all(
      slides.map((slide) => {
        if (slide[modelName]) {
          return Promise.resolve();
        }

        return getSlideMLData({ slideUUID: slide.uuid, model: modelName })
          .unwrap()
          .then((mlData) => {
            const updatedSlide = { ...slide, ...mlData };
            dispatch(updateSlide(updatedSlide));
            dispatch(updateSelectedRow(updatedSlide));
          })
          .catch(() => {
            showError(
              `Failed to get ML data for slide ${slide.name} ${modelName}`,
            );
          });
      }),
    );

  const removeSlidesMLData = (slides, modelName) => {
    slides.forEach((currentSlide) => {
      const model = getShortModelName(modelName);
      dispatch(
        removeSlideAttributeFromList({
          uuid: currentSlide.uuid,
          attribute: model,
        }),
      );
      dispatch(
        removeSlideAttribute({ uuid: currentSlide.uuid, attribute: model }),
      );
    });
  };

  return { getSlidesMLData, removeSlidesMLData };
};
