import { Cancel, CheckCircle, OpenInNew } from '@mui/icons-material';
import { Box, Button, Link, Typography } from '@mui/material';
import { SlideAIModalConfirmed } from 'components/AITools/components/SlideAIModalConfirmed';
import { SlideAIModalPaymentInfo } from 'components/AITools/components/SlideAIModalPaymentInfo';
import { useAIModelModalStyles } from 'components/AITools/constants/styles';
import { isModelExists } from 'components/AITools/utilities/common';
import { InformationMenu } from 'components/pages/AppLibrary/components/InformationMenu';
import { SlidesGallery } from 'components/pages/AppLibrary/SlidesGallery';
import { RUN_MODEL_ERROR_MESSAGE } from 'constants/errorMessages';
import logo from 'images/logos/lowResolutionLogo.png';
import { useEffect, useMemo, useState } from 'react';
import { APP_LIBRARY, SLIDE_VIEWER_URL } from 'constants/urls';
import { useGetTeamsQuery } from 'store/apis/teamsApi';
import { useConfirmAIModelMutation } from 'store/apis/walletApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const SlideAIModalContent = ({
  currentModel,
  isProcessed,
  onClose,
  selectedSlides,
  handleReportOpen,
  model,
}) => {
  const { classes } = useAIModelModalStyles();
  const [confirmPayment, { isLoading: isConfirming }] =
    useConfirmAIModelMutation();
  const { showError } = useSnackbar();
  const [team, setTeam] = useState();
  const { data: teams, isLoading: isTeamsLoading } = useGetTeamsQuery();
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(isProcessed);

  const handleConfirmPayment = () => {
    if (!team) return;

    const slidesUUIDs = selectedSlides.map((slide) => slide.uuid);

    confirmPayment({
      teamUUID: team.uuid,
      walletUUID: team.wallet.uuid,
      modelId: currentModel.toolId,
      slidesUUIDs,
    })
      .unwrap()
      .then(() => {
        setIsPaymentConfirmed(true);
      })
      .catch((e) => {
        const error = e?.data?.[0] || e?.data || RUN_MODEL_ERROR_MESSAGE;
        showError(error);
      });
  };

  const handleViewMore = () => {
    window.open(`${APP_LIBRARY}/${currentModel.toolId}`, 'blank');
  };

  const handleClose = () => {
    setIsPaymentConfirmed(false);
    onClose();
  };

  const isLoading = isConfirming || isTeamsLoading;

  useEffect(() => {
    if (teams?.length) {
      const { uuid, team_wallet: wallet } = teams[0];

      setTeam({ uuid, wallet });
    }
  }, [teams, setTeam]);

  const compatibleSlides = useMemo(
    () => selectedSlides?.filter((slide) => !isModelExists(slide, model)),
    [selectedSlides, model],
  );

  return (
    <Box className={classes.container}>
      {isPaymentConfirmed ? (
        <SlideAIModalConfirmed
          handleClose={handleClose}
          isProcessed={isProcessed}
          handleReportOpen={handleReportOpen}
        />
      ) : (
        <Box display="flex" className={classes.halfContainer} mb="1rem">
          <Box className={classes.confirmTable}>
            <Box className={classes.confirmSlide}>
              <Typography className={classes.confirmHeader}>
                Slide Name
              </Typography>
              <Typography className={classes.confirmHeader} textAlign="center">
                Is Compatible?
              </Typography>
            </Box>
            <Box className={classes.confirmSlides}>
              {selectedSlides?.map((slide) => (
                <Box className={classes.confirmSlide} key={slide.name}>
                  <Link
                    href={SLIDE_VIEWER_URL.replace(':slideUUID', slide.uuid)}
                    target="_blank"
                    className={classes.confirmName}
                  >
                    {slide.name}
                  </Link>
                  <Typography className={classes.confirmIcon}>
                    {!isModelExists(slide, model) ? (
                      <CheckCircle color="success" fontSize="small" />
                    ) : (
                      <Cancel color="default" fontSize="small" />
                    )}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <SlideAIModalPaymentInfo
            model={currentModel}
            slidesCount={compatibleSlides?.length}
            handleConfirmPayment={handleConfirmPayment}
            isConfirming={isLoading}
            balance={+(team?.wallet?.balance || 0)}
          />
        </Box>
      )}
      <Box className={!isPaymentConfirmed ? classes.infoSection : undefined}>
        <Typography className={classes.title}>
          Learn More About {currentModel.title}
        </Typography>
        <Box className={classes.halfContainer}>
          <Box className={classes.galleryWrapper}>
            <SlidesGallery
              showThumbs={false}
              maxWidth="100%"
              showArrows
              content={[
                currentModel.video,
                ...currentModel.toolImages[0].toolImage,
              ]}
            />
          </Box>
          <Box>
            <InformationMenu
              isSmall
              model={{
                ...currentModel,
                developer: (
                  <img src={logo} alt="HistoWiz" className={classes.logo} />
                ),
              }}
            />
            <Button
              variant="contained"
              color="default"
              className={classes.confirmButton}
              endIcon={<OpenInNew fontSize="small" />}
              onClick={handleViewMore}
            >
              View Details
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
