import { DefaultTabWrapper } from 'components/OrderDetails/Tabs/utilities';
import React from 'react';
import { OrderProgress } from 'components/OrderDetails/Tabs/OrderProgressTab/OrderProgress';

export const OrderProgressTab = (props) => {
  const { order, samples } = props;

  return (
    <DefaultTabWrapper>
      <OrderProgress order={order} samples={samples} />
    </DefaultTabWrapper>
  );
};
