import { FormControl, Select, MenuItem, Skeleton } from '@mui/material';
import { memo } from 'react';

export const TeamSelector = memo(({ teams, selectedTeam, setSelectedTeam }) =>
  selectedTeam ? (
    <FormControl>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedTeam?.uuid}
        label="Age"
        onChange={(e) =>
          setSelectedTeam(teams.find((team) => team.uuid === e.target.value))
        }
      >
        {teams?.map((team) => (
          <MenuItem key={team.uuid} value={team.uuid}>
            {team.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <Skeleton variant="rectangular" width={125.2} height={26.25} />
  ),
);
