import { Carousel } from 'react-responsive-carousel';
import { useGalleryStyles } from 'components/pages/AppLibrary/styles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useRef, useState } from 'react';
import { SlideLabel } from './components/SlideLabel';
import { Box } from '@mui/material';

export const SlidesGallery = ({
  showThumbs = true,
  maxWidth = '65vw',
  maxHeight,
  showArrows = false,
  thumbWidth = 200,
  content = [],
}) => {
  const videoRef = useRef();
  const [playing, setPlaying] = useState(false);

  const { classes } = useGalleryStyles({ maxWidth, maxHeight, playing });

  const videoHandler = (control, e) => {
    e.preventDefault();

    if (control === 'play') {
      videoRef.current.play();
      setPlaying(true);
    } else if (control === 'pause') {
      videoRef.current.pause();
      setPlaying(false);
    }
  };

  const isVideo = (item) => item?.includes?.('/videos/');

  return content.length ? (
    <Carousel
      showStatus={false}
      showIndicators={false}
      showArrows={showArrows}
      swipeable
      emulateTouch
      className={classes.root}
      thumbWidth={thumbWidth}
      dynamicHeight={false}
      showThumbs={showThumbs}
      renderThumbs={() => {
        return content.map((item, i) => {
          return isVideo(item) ? (
            <video key={i} className={classes.video}>
              <source src={item} type="video/mp4" />
            </video>
          ) : (
            <img key={item.id} src={item.image?.url} alt="slide_img" />
          );
        });
      }}
    >
      {content.map((item) => {
        return isVideo(item) ? (
          <span key={item}>
            {!playing && (
              <PlayCircleIcon
                className={classes.playButton}
                onClick={(e) => videoHandler('play', e)}
              />
            )}
            <video
              className={classes.video}
              controls
              ref={videoRef}
              onClick={(e) => videoHandler('pause', e)}
              onEnded={() => setPlaying(false)}
            >
              <source src={item} type="video/mp4" />
            </video>
          </span>
        ) : (
          <Box key={item.id} className={classes.slideImgWrapper}>
            <img src={item.image?.url} alt="slide_img" />
            {item.label && <SlideLabel label={item.label} />}
          </Box>
        );
      })}
    </Carousel>
  ) : null;
};
