import React, { Fragment, useContext, useMemo, useState } from 'react';
import { AppContext } from 'services/context';
import moment from 'moment';
import {
  ML_QC_OPTIONS_RECORDS,
  QCFailReasonOptions,
} from 'components/QCViewer/constants';
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import Select from 'react-select';
import { DefaultDialog } from 'components/Modals/DefaultDialog';
import Box from '@mui/material/Box';
import { trueFalseOptions } from 'components/OrderForm/constants';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import { currentUserEmailSelector } from 'store/slices/userDetailsSlice';
import { useSelector } from 'react-redux';
import { ShowSpecialInstructions } from 'components/QCViewer/QCViewerComponents';
import { ViewAttachmentsModal } from 'components/ImageViewer/ViewAttachmentsModal';
import {
  getCheckBoxesForBulkQCModal,
  serializeDataForCheckboxes,
} from 'components/utilities/gridDataSerializers';
import Checkbox from '@mui/material/Checkbox';
import { useQCBulkPassFailModalStyles } from './styles';
import { usePostBulkQCSlideMutation } from 'store/apis/slidesApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const REVIEW_SPECIAL_INSTRUCTIONS_TEXT =
  'Please Review Special Instructions';
export const REVIEW_ACTIVE_ORDER_NOTES_TEXT =
  'Please Review Active Order Notes';

const modalContextName = 'modalBulkQCPassFailOpen';

export const QCBulkPassFailModal = ({ selectedRows, handleQCReviewed }) => {
  const { classes } = useQCBulkPassFailModalStyles();
  const context = useContext(AppContext);
  const email = useSelector(currentUserEmailSelector);
  const { handleContextModalChange } = context;

  const { showError } = useSnackbar();

  const [passed_qc, setPassedQC] = useState({ value: true, label: 'Yes' });
  const [internal_notes, setInternalNotes] = useState('');
  const [external_comment, setExternalComment] = useState('');
  const [failed_qc_reason, setFailedQCReason] = useState();
  const [isActiveOrderNotesChecked, setIsActiveOrderNotesChecked] =
    useState(false);

  const [specialInstructionsCheckBoxes, setSpecialInstructionsCheckBoxes] =
    useState(getCheckBoxesForBulkQCModal());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [postBulkQCSlide] = usePostBulkQCSlideMutation();

  const rowLength = selectedRows.length;

  const isSpecialInstructionsPdfExist = useMemo(
    () =>
      selectedRows.find(
        (slide) => slide?.order.special_instructions_attachment,
      ),
    [selectedRows.length],
  );

  const isSpecialInstructionsTextExist = useMemo(
    () =>
      selectedRows.find(
        (slide) => slide?.order.special_instructions?.length > 0,
      ),
    [selectedRows.length],
  );

  const isActiveOrderNotesExist = useMemo(
    () =>
      selectedRows.find(
        (slide) =>
          slide?.order.project?.active_order_notes ||
          slide?.order.project?.team?.active_order_notes ||
          slide?.order.project?.team?.organization?.active_order_notes,
      ),
    [selectedRows.length],
  );

  const handleSelectChange = (name) => (selectedOption) => {
    const mapSelectNameToSetter = {
      passed_qc: setPassedQC,
      failed_qc_reason: setFailedQCReason,
    };

    const setter = mapSelectNameToSetter[name];
    setter(selectedOption);
  };

  const handleTextChange = (name) => (event) => {
    const mapInputNameToSetter = {
      external_comment: setExternalComment,
      internal_notes: setInternalNotes,
    };

    const setter = mapInputNameToSetter[name];
    setter(event.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    const postParams = getPostParams();

    postBulkQCSlide({ postParams })
      .unwrap()
      .then(() => {
        handleQCReviewed({ slides: selectedRows });
        // turn off modals after success
        handleContextModalChange(modalContextName)();
      })
      .catch(() => showError('Error submitting bulk QC'))
      .finally(() => setIsSubmitting(false));
  };

  const getPostParams = () => {
    const passedQC = passed_qc.value;
    const slideUUIDs = selectedRows.map((row) => row.uuid);

    const postParams = {
      internal_notes,
      slide_uuids: slideUUIDs,
      ...(external_comment && { external_comment }),
      ...(passedQC && { passed_qc_datetime: moment().toISOString() }),
      ...(!passedQC && getFailedQCPostParams()),
    };

    return postParams;
  };

  const getFailedQCPostParams = () => {
    const [primaryFailReason, redoAction] = getPrimaryFailReasonAndRedoAction();

    const params = {
      failed_qc_reason: primaryFailReason,
      needs_redo: true,
      needs_redo_type: redoAction,
      failed_qc_datetime: moment().toISOString(),
    };

    return params;
  };

  const getPrimaryFailReasonAndRedoAction = () => {
    const qcOption = ML_QC_OPTIONS_RECORDS.find(
      (option) => failed_qc_reason.value === option.failConditionReasonName,
    );
    return [failed_qc_reason.value, qcOption?.redoAction];
  };

  const modalActions = () => {
    let submitLabel, buttonStyle;

    // if this hasn't been filled out, don't show actions
    // this is just checking if there's a value
    // aka in an object (it's not a true/false value)
    if (!passed_qc) {
      return null;
    }

    const passed_qc_parsed_value = passed_qc.value;
    // if it hasn't passed qc, make sure the other reasons are input -- use
    // this as a cheap way for validation
    if (!passed_qc_parsed_value && !failed_qc_reason) {
      return null;
    }

    if (passed_qc && passed_qc_parsed_value) {
      submitLabel = `BULK QC PASSED (${rowLength})`;
      buttonStyle = classes.passedQCButton;
    } else {
      submitLabel = `BULK QC FAILED (${rowLength})`;
      buttonStyle = classes.failedQCButton;
    }

    const isPdfSpecialInstructionChecked = isSpecialInstructionsPdfExist
      ? specialInstructionsCheckBoxes.special_instructions_pdf
      : true;

    const isTextSpecialInstructionChecked = isSpecialInstructionsTextExist
      ? specialInstructionsCheckBoxes.special_instructions_text
      : true;

    const isSpecialInstructionsChecked =
      isPdfSpecialInstructionChecked && isTextSpecialInstructionChecked;

    const submitTooltipTitle = isSpecialInstructionsChecked
      ? isActiveOrderNotesExist && isActiveOrderNotesChecked
        ? ''
        : REVIEW_ACTIVE_ORDER_NOTES_TEXT
      : REVIEW_SPECIAL_INSTRUCTIONS_TEXT;

    return (
      <Typography align={'right'} className={classes.buttonTypography}>
        <span>
          <Button
            color="primary"
            onClick={handleContextModalChange(modalContextName)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Tooltip title={submitTooltipTitle}>
            <span>
              <Button
                variant="contained"
                color="secondary"
                className={buttonStyle}
                onClick={handleSubmit}
                disabled={
                  isSubmitting ||
                  !isSpecialInstructionsChecked ||
                  (isActiveOrderNotesExist && !isActiveOrderNotesChecked)
                }
              >
                <SubmittingIcon submitting={isSubmitting} size={10} />
                {submitLabel}
              </Button>
            </span>
          </Tooltip>
        </span>
      </Typography>
    );
  };

  const renderPassedFormInputs = () => {
    // if its not loaded yet, dont show
    // passed_qc is stored as {value: false, label: "No"}
    if (!passed_qc) {
      return null;
    }

    // if it has failed_qc, we don't let people update this value
    if (!passed_qc.value) {
      return null;
    }

    return (
      <Fragment>
        <TextField
          id="standard-multiline-flexible"
          label="External Slide Comment"
          className={classes.externalTextField}
          placeholder="External Slide Comment"
          fullWidth={true}
          multiline
          onChange={handleTextChange('external_comment')}
        />
      </Fragment>
    );
  };

  const renderFailFormInputs = () => {
    // if it's not populated yet, don't show anything
    if (!passed_qc) {
      return null;
    }

    // if we have this populated and it's true, then don't show the other questions
    if (passed_qc && passed_qc.value) {
      return null;
    }

    return (
      <Fragment>
        <br />
        <Typography variant={'body1'} color={'inherit'}>
          QC Fail Reason
        </Typography>
        <Select
          value={failed_qc_reason}
          onChange={handleSelectChange('failed_qc_reason')}
          options={QCFailReasonOptions}
          placeholder={'Failed QC Reason ... '}
        />
        <TextField
          id="standard-multiline-flexible"
          label="External Slide Comment"
          placeholder="External Slide Comment"
          fullWidth={true}
          multiline
          onChange={handleTextChange('external_comment')}
          className={classes.externalTextField}
        />
      </Fragment>
    );
  };

  const SlideThumbnail = ({ slide }) => {
    const [isAttachmentsModalVisible, setIsAttachmentsModalVisible] =
      useState(false);

    if (!slide) return;
    const isSpecialInstructionExist =
      slide.order && serializeDataForCheckboxes(slide.order).length > 0;

    return (
      <Fragment key={slide.uuid}>
        {isSpecialInstructionExist && (
          <ShowSpecialInstructions
            setIsAttachmentsModalVisible={setIsAttachmentsModalVisible}
            classes={classes.warningIcon}
          />
        )}
        <img
          src={slide.medium_macro_url}
          width={'150px'}
          alt={'Macro Slide'}
          className={classes.slideThumbnailImage}
        />
        <ViewAttachmentsModal
          open={isAttachmentsModalVisible}
          onClose={() => setIsAttachmentsModalVisible(false)}
          order={slide.order}
        />
      </Fragment>
    );
  };

  const renderCheckBoxes = () => {
    return (
      <Grid container direction="column">
        <Grid container direction="row" alignItems="center">
          <Checkbox
            style={
              isSpecialInstructionsPdfExist
                ? {
                    color:
                      specialInstructionsCheckBoxes.special_instructions_pdf
                        ? 'green'
                        : 'red',
                  }
                : {}
            }
            checked={specialInstructionsCheckBoxes.special_instructions_pdf}
            disabled={!isSpecialInstructionsPdfExist}
            onChange={() =>
              setSpecialInstructionsCheckBoxes((prev) => {
                return {
                  ...prev,
                  special_instructions_pdf: !prev.special_instructions_pdf,
                };
              })
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          I've checked special instructions pdf
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Checkbox
            style={
              isSpecialInstructionsTextExist
                ? {
                    color:
                      specialInstructionsCheckBoxes.special_instructions_text
                        ? 'green'
                        : 'red',
                  }
                : {}
            }
            checked={specialInstructionsCheckBoxes.special_instructions_text}
            disabled={!isSpecialInstructionsTextExist}
            onChange={() =>
              setSpecialInstructionsCheckBoxes((prev) => {
                return {
                  ...prev,
                  special_instructions_text: !prev.special_instructions_text,
                };
              })
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          I've checked special instructions text
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Checkbox
            style={
              isActiveOrderNotesExist
                ? {
                    color:
                      isActiveOrderNotesExist && isActiveOrderNotesChecked
                        ? 'green'
                        : 'red',
                  }
                : {}
            }
            checked={isActiveOrderNotesChecked}
            disabled={!isActiveOrderNotesExist}
            onChange={() => setIsActiveOrderNotesChecked((prev) => !prev)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          I've checked active order notes
        </Grid>
      </Grid>
    );
  };

  const renderModal = () => {
    const isOpen = context[modalContextName];
    const onClose = handleContextModalChange(modalContextName);

    if (!(selectedRows && isOpen)) {
      return null;
    }

    return (
      <DefaultDialog
        open={isOpen}
        onClose={onClose}
        actions={modalActions}
        title={'Bulk QC/Pass Fail'}
      >
        <Box height={'75vh'} width={'75vw'}>
          <Grid
            container
            spacing={1}
            justifyContent="space-around"
            className={classes.grid}
          >
            <Grid container gridTemplateColumns="repeat(4, 1fr)" xs={8}>
              {selectedRows.map((row) => (
                <Grid item xs={3}>
                  <SlideThumbnail key={row.uuid} slide={row} />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1} justifyContent="space-around">
                <Grid item xs={12}>
                  <Typography
                    color={'inherit'}
                    className={classes.qcReviewedText}
                    variant={'h5'}
                  >
                    BULK Quality Control Review by {email}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    color={'inherit'}
                    className={classes.passedQCText}
                  >
                    Passed QC?
                  </Typography>
                  <Select
                    value={passed_qc}
                    onChange={handleSelectChange('passed_qc')}
                    options={trueFalseOptions}
                    placeholder={'Passed QC? '}
                  />
                  {renderFailFormInputs()}
                  <TextField
                    id="standard-multiline-flexible"
                    label="Internal Slide Notes / Change Reason"
                    className={classes.externalTextField}
                    placeholder="Internal Slide Notes / Change Reason"
                    fullWidth={true}
                    multiline
                    onChange={handleTextChange('internal_notes')}
                  />
                  {renderPassedFormInputs()}
                  {renderCheckBoxes()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DefaultDialog>
    );
  };

  return <Fragment>{renderModal()}</Fragment>;
};
