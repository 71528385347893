import { useDispatch, useSelector } from 'react-redux';
import {
  currentUserPreferencesSelector,
  updatePreferences,
} from 'store/slices/userDetailsSlice';
import {
  ANNOTATION_DRAW_MODE_ENABLED_FIELD,
  ANNOTATIONS_SWITCH_FIELD,
  DISTANCE_MEASURE_ENABLED_FIELD,
} from 'components/ImageViewer/constants';
import React, { useState } from 'react';
import { useUpdateUserPreferencesMutation } from 'store/apis/userDetailsApi';
import Grid from '@mui/material/Grid';
import { ReactComponent as AnnotationIcon } from 'images/icons/AnnotationIcon.svg';
import { RulerIcon } from 'images/icons/RulerIcon';
import { DrawingToolsButtonGroup } from 'components/ImageViewer/components/ImageViewerToolbar/Buttons/DrawingToolsButtonGroup';
import { MeasurementToolTutorialModal } from 'components/Modals/TutorialModals/MeasurementToolTutorialModal';
import { SlideAnnotationTutorialModal } from 'components/Modals/TutorialModals/SlideAnnotationTutorialModal';
import {
  formatAnnotationsCount,
  useAnnotationsCountNotification,
} from 'components/ImageViewer/components/ImageViewerToolbar/utilities';
import { SlideViewerToolbarToggleButton } from 'components/ImageViewer/components/ImageViewerToolbar/Buttons/SlideViewerToolbarToggleButton';
import { AnnotationsCountIconButton } from 'components/ImageViewer/components/ImageViewerToolbar/Buttons/AnnotationsCountIconButton';
import { HelpIconButton } from 'components/ImageViewer/components/ImageViewerToolbar/Buttons/HelpIconButton';
import { DrawingModeButton } from 'components/ImageViewer/components/ImageViewerToolbar/Buttons/DrawingModeButton';
import { SlidesNavigationButtonsGroup } from 'components/ImageViewer/components/ImageViewerToolbar/Buttons/SlidesNavigationButtonsGroup';
import {
  SLIDE_ANNOTATION_TUTORIAL_TOOLTIP,
  SLIDE_ANNOTATIONS_ENABLE_TOOLTIP,
  SLIDE_MEASURE_TOOLTIP,
  SLIDE_MEASURE_TUTORIAL_TOOLTIP,
} from 'components/ImageViewer/components/ImageViewerToolbar/constants';

export const ImageViewerToolbar = ({
  selectedDrawTool,
  setDrawTool,
  handleSetDrawModeEnabled,
  drawModeEnabled,
  annotationsCount,
  slidesViewerProps,
  currentSlide,
  handleNextSlide,
  handlePreviousSlide,
  showSlidesNavigation,
}) => {
  const preferences = useSelector(currentUserPreferencesSelector);
  const isMeasureEnabled = preferences[DISTANCE_MEASURE_ENABLED_FIELD];

  const [annotationTutorialModalOpen, setAnnotationTutorialModalOpen] =
    useState(false);
  const [
    forceAnnotationTutorialModalOpen,
    setForceAnnotationTutorialModalOpen,
  ] = useState(false);

  const [measureTutorialModalOpen, setMeasureTutorialModalOpen] =
    useState(false);
  const [forceMeasureTutorialModalOpen, setForceMeasureTutorialModalOpen] =
    useState(false);

  const [hasSeenMeasureTutorial, setHasSeenMeasureTutorial] = useState(false);
  const [hasSeenSlideAnnotationTutorial, setHasSeenSlideAnnotationTutorial] =
    useState(false);

  const [updateUserPreferences] = useUpdateUserPreferencesMutation();

  const isAnnotationsEnabled = preferences[ANNOTATIONS_SWITCH_FIELD];
  const dispatch = useDispatch();

  const showAnnotationsCount = useAnnotationsCountNotification(
    annotationsCount,
    currentSlide,
    slidesViewerProps,
  );

  const handleDrawModeChange = () => {
    if (!(hasSeenSlideAnnotationTutorial || drawModeEnabled)) {
      setAnnotationTutorialModalOpen(true);
      setHasSeenSlideAnnotationTutorial(true);
    }

    return handleSetDrawModeEnabled(!drawModeEnabled);
  };

  const handleDrawerToolChange = (e, value) => {
    if (!value) {
      return;
    }

    setDrawTool(value);
  };

  const handleAnnotationModalClose = () => {
    setAnnotationTutorialModalOpen(false);
    setForceAnnotationTutorialModalOpen(false);
  };

  const handleMeasureModalClose = () => {
    setMeasureTutorialModalOpen(false);
    setForceMeasureTutorialModalOpen(false);
  };

  const handleMeasureChange = () => {
    if (!(hasSeenMeasureTutorial || isMeasureEnabled)) {
      setMeasureTutorialModalOpen(true);
      setHasSeenMeasureTutorial(true);
    }

    const updatedPreference = {
      [DISTANCE_MEASURE_ENABLED_FIELD]: !isMeasureEnabled,
      ...(!isMeasureEnabled && { [ANNOTATION_DRAW_MODE_ENABLED_FIELD]: false }),
    };

    updateUserPreferences({ postParams: updatedPreference, partial: true });
  };

  const annotationsCountText = formatAnnotationsCount(
    annotationsCount?.count,
    slidesViewerProps?.slides.length,
  );

  const handleAnnotationsChange = () => {
    dispatch(
      updatePreferences({
        [ANNOTATIONS_SWITCH_FIELD]: !isAnnotationsEnabled,
        ...(!isMeasureEnabled && { [DISTANCE_MEASURE_ENABLED_FIELD]: false }),
        ...(!isMeasureEnabled && {
          [ANNOTATION_DRAW_MODE_ENABLED_FIELD]: false,
        }),
      }),
    );
  };

  return (
    <>
      <Grid
        container
        item
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Grid container item spacing={2} width="fit-content">
          <Grid container item width="fit-content">
            <Grid item>
              <SlideViewerToolbarToggleButton
                value="annotationsEnabled"
                onClick={handleAnnotationsChange}
                title={SLIDE_ANNOTATIONS_ENABLE_TOOLTIP}
                isSelected={isAnnotationsEnabled}
              >
                <AnnotationIcon width="1.7rem" height="1.5rem" />
              </SlideViewerToolbarToggleButton>
            </Grid>

            {showAnnotationsCount && (
              <Grid item>
                <AnnotationsCountIconButton
                  onClick={handleDrawModeChange}
                  count={annotationsCountText}
                />
              </Grid>
            )}
          </Grid>

          {isAnnotationsEnabled && (
            <Grid item container width="fit-content">
              <Grid item>
                <SlideViewerToolbarToggleButton
                  value="measure"
                  title={SLIDE_MEASURE_TOOLTIP}
                  onClick={handleMeasureChange}
                  isSelected={isMeasureEnabled}
                >
                  <RulerIcon />
                </SlideViewerToolbarToggleButton>
              </Grid>
              {isMeasureEnabled && (
                <Grid item>
                  <HelpIconButton
                    title={SLIDE_MEASURE_TUTORIAL_TOOLTIP}
                    onClick={() => setForceMeasureTutorialModalOpen(true)}
                  />
                </Grid>
              )}
            </Grid>
          )}

          {isAnnotationsEnabled && (
            <Grid item container width="fit-content">
              <Grid item>
                <DrawingModeButton
                  onClick={handleDrawModeChange}
                  drawModeEnabled={drawModeEnabled}
                />
              </Grid>
              {drawModeEnabled && (
                <Grid item>
                  <HelpIconButton
                    title={SLIDE_ANNOTATION_TUTORIAL_TOOLTIP}
                    onClick={() => setForceAnnotationTutorialModalOpen(true)}
                  />
                </Grid>
              )}
            </Grid>
          )}

          <Grid item width="fit-content">
            {isAnnotationsEnabled && drawModeEnabled && (
              <DrawingToolsButtonGroup
                handleDrawerToolChange={handleDrawerToolChange}
                selectedDrawTool={selectedDrawTool}
              />
            )}
          </Grid>
        </Grid>

        {showSlidesNavigation && (
          <Grid container item width="fit-content">
            <Grid item container>
              <SlidesNavigationButtonsGroup
                handlePreviousSlide={handlePreviousSlide}
                handleNextSlide={handleNextSlide}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <MeasurementToolTutorialModal
        open={measureTutorialModalOpen}
        onClose={handleMeasureModalClose}
        forceOpen={forceMeasureTutorialModalOpen}
      />

      <SlideAnnotationTutorialModal
        open={annotationTutorialModalOpen}
        onClose={handleAnnotationModalClose}
        forceOpen={forceAnnotationTutorialModalOpen}
      />
    </>
  );
};
