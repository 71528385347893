import { Autocomplete, Grid, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { US_STATES } from 'components/SignupForm/constants';
import classNames from 'classnames';

export const StateSelection = ({ name, classes }) => {
  const { control } = useFormContext();

  const {
    field: { onChange, value, onBlur, ref },
    formState: { touchedFields, submitCount, errors },
  } = useController({
    name,
    control,
  });

  const shouldDisplayError = (fieldName) => {
    return errors[fieldName] && (touchedFields[fieldName] || submitCount);
  };

  const isError = shouldDisplayError('state') && errors['state'].message;

  return (
    <Grid item xs={4}>
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={true}
            error={!!isError}
            className={classNames(classes.textField)}
            placeholder="State ... "
            inputRef={ref}
          />
        )}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onBlur={onBlur}
        onChange={(e, option) => onChange(option?.name)}
        options={US_STATES}
        value={value ? { name: value } : null}
      />
      <div className={classes.errorText}>{isError}</div>
    </Grid>
  );
};
