import { useState } from 'react';

export const useSimpleModal = (isOpenByDefault = false) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const toggleModal = () => setIsOpen((prev) => !prev);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return {
    isOpen,
    toggleModal,
    open,
    close,
  };
};
