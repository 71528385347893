import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFloatingFilterStyles } from 'components/OrderSlidesList/styles';

export const FloatingFilterWithReset = memo(
  forwardRef((props, ref) => {
    const [currentValue, setCurrentValue] = useState('');
    const inputRef = useRef(null);
    const { classes } = useFloatingFilterStyles();

    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          if (!parentModel) {
            inputRef.current.value = '';
            setCurrentValue('');
          } else {
            inputRef.current.value = parentModel.filter;
            setCurrentValue(parentModel.filter);
          }
        },
      };
    });

    useEffect(() => {
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged(
          'contains',
          currentValue?.length ? currentValue : '',
        );
      });
    }, [currentValue]);

    return (
      <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper">
        <input
          maxLength={15}
          ref={inputRef}
          className="ag-input-field-input ag-text-field-input"
          value={currentValue}
          onInput={(e) => setCurrentValue(e.target.value)}
        />
        {currentValue ? (
          <CloseIcon
            className={classes.resetFilterIcon}
            fontSize="small"
            cursor="pointer"
            onClick={() => setCurrentValue('')}
          />
        ) : null}
      </div>
    );
  }),
);
