import { useForm } from 'react-hook-form';
import { BONE_DECALCIFICATION } from 'components/OrderForm/SamplesFormV2/constants';
import { useEffect } from 'react';
import { serializedTrueFalseOptionsToSelectInput } from 'components/OrderForm/constants';
import { getBoneDecalcificationFormDefaultValues } from 'components/OrderForm/SamplesFormV2/utilities';

export const useBoneDecalcificationForm = (order) => {
  const methods = useForm({
    defaultValues: getBoneDecalcificationFormDefaultValues(),
  });

  const { setValue } = methods;

  const [bone_decalcification] = methods.watch([BONE_DECALCIFICATION]);

  useEffect(() => {
    if (!order) {
      return;
    }

    setValue(
      BONE_DECALCIFICATION,
      serializedTrueFalseOptionsToSelectInput[order.bone_decalcification],
    );
  }, [order]);

  return {
    bone_decalcification,
    methods,
  };
};
