import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  collapseGridRows,
  selectFirstRow,
  selectGridRow,
} from 'components/utilities/grid';
import { validatedSlidesColumnDefs } from 'components/utilities/AgGridCols/AgGridColumns';
import {
  antibodiesDetailGridDefaultColDefs,
  antibodiesTablesDefaultColDef,
} from 'components/AntibodiesList/constants';
import { backendURL } from 'services/backendAPI';
import { useAGGridOverlayControl } from 'components/utilities/hooks/grid/useAGGridOverlayControl';
import { useHistory } from 'react-router';
import { IHC_ANTIBODIES_EXTERNAL_URL } from 'constants/urls';
import {
  checkIfDiscountExists,
  columnsWithDiscountTooltip,
} from 'components/AntibodiesList/utilities';

export const SingleAntibodyTable = ({
  rowData,
  isLoading,
  quickFilterText,
  columnDefs,
  getContextMenuItems,
  setDisplayRowData,
  isStaff,
  gridApi,
  setSlide,
  setGridApi,
  setDetailGridApi,
  selectedAntibody,
  setSelectedAntibody,
  isForOrderForm,
}) => {
  useAGGridOverlayControl(isLoading, rowData, gridApi);

  const history = useHistory();

  const nodesCount = gridApi?.getDisplayedRowCount();

  const getNodeByUUID = (gridAPI, uuid) => {
    let matchingRowNode;

    gridAPI.forEachNode((node) => {
      if (node.data?.uuid === uuid) {
        matchingRowNode = node;
      }
    });

    return matchingRowNode;
  };

  const hasSlides = (antibody) => antibody?.validated_slides?.length;

  const isRowMaster = (data) => hasSlides(data);

  const onGridReady = ({ api }) => {
    setGridApi(api);
    api.suppressNoRowsOverlay = true;
    api.hideOverlay();
  };

  const handleRowSelectionChanged = ({ node, api }) => {
    if (node.selected) {
      collapseGridRows(api, node);
      node.setExpanded(true);
    } else if (isForOrderForm) {
      setSelectedAntibody(undefined);
      const selectedRows = api.getSelectedRows();
      if (selectedRows.length) {
        const selectedRow = selectedRows[selectedRows.length - 1];
        setSelectedAntibody(selectedRow);
      }
      api.refreshCells();
    } else if (!api.getSelectedRows().length) {
      setSlide(undefined);
    }
  };

  const handleDetailGridReady = ({ api }) => {
    setDetailGridApi(api);
  };

  const handleFirstDetailGridDataRendered = ({ api }) => {
    selectFirstRow(api);
  };

  const handleDetailGridRowSelectionChanged = ({ node }) => {
    if (node.selected) {
      setSlide(node.data);
    }
  };

  const updateURL = (node) => {
    const selectedAntibodyURL = IHC_ANTIBODIES_EXTERNAL_URL.replace(
      ':antibodyUUID?',
      node.data?.uuid,
    );
    history.push(selectedAntibodyURL);
  };

  const handleRowGroupDisplayChanged = ({ node }) => {
    if (node.expanded) {
      node.setSelected(true);
      if (isForOrderForm) {
        setSelectedAntibody(node.data);
      } else {
        updateURL(node);
      }
    }
  };

  const getSingleContextMenuItemSlide = ({ row }) => {
    const slideID = row.id;
    const lisaLink = `${backendURL}/lisa/core/slide/${slideID}/change/`;
    const menuContext = [
      {
        name: 'Open Slide in LISA',
        action: () => {
          if (row) {
            window.open(lisaLink, '_blank');
          }
        },
      },
    ];

    return menuContext;
  };

  const getContextMenuItemSlide = (params) => {
    const row = params?.node?.data;

    if (!row || !isStaff) {
      return null;
    }

    return getSingleContextMenuItemSlide({ row });
  };

  const detailAntibodyRendererParams = {
    detailGridOptions: {
      columnDefs: validatedSlidesColumnDefs,
      defaultColDef: antibodiesDetailGridDefaultColDefs,
      rowSelection: 'single',
      onGridReady: handleDetailGridReady,
      onFirstDataRendered: handleFirstDetailGridDataRendered,
      onRowSelected: handleDetailGridRowSelectionChanged,
      getContextMenuItems: getContextMenuItemSlide,
    },
    getDetailRowData: (params) => {
      params.successCallback(params.data.validated_slides);
    },
  };

  const handleFirstDataRendered = ({ api }) => {
    if (selectedAntibody && nodesCount) {
      const matchingRowNode = getNodeByUUID(gridApi, selectedAntibody?.uuid);

      if (matchingRowNode) {
        matchingRowNode.setExpanded(true);
        const index = matchingRowNode.rowIndex;
        // Scroll a bit more to view expanded row
        const newIndex = index > nodesCount - 2 ? index : index + 1;
        // Avoid flushSync errors
        queueMicrotask(() => {
          gridApi.ensureIndexVisible(newIndex);
        });
      }

      return;
    }

    selectGridRow(api, ({ data }) => hasSlides(data));
  };

  const handleRowClicked = ({ data }) => {
    if (!data) return;
    const { uuid } = data;
    const node = getNodeByUUID(gridApi, uuid);
    if (isForOrderForm) {
      setSelectedAntibody(node.data);
      setSlide(node.data?.validated_slides?.[0]);
    } else {
      node.setSelected(true);
      updateURL(node);
    }
  };
  const groupDisplayType = 'groupRows';

  const columnsWithTooltip = columnsWithDiscountTooltip(
    columnDefs,
    rowData,
    isForOrderForm,
  );

  const isDiscountExists = checkIfDiscountExists(rowData);

  return (
    <AgGridReact
      tooltipMouseTrack
      tooltipShowDelay={100}
      includeHiddenColumnsInQuickFilter
      onModelUpdated={setDisplayRowData}
      masterDetail
      isRowMaster={isRowMaster}
      detailRowHeight={210}
      enableSorting
      enableFilter
      enableBrowserTooltips
      quickFilterText={quickFilterText}
      enableColResize
      rowDragManaged
      onGridReady={onGridReady}
      rowGroupPanelShow="always"
      columnDefs={columnsWithTooltip}
      rowData={rowData}
      defaultColDef={antibodiesTablesDefaultColDef}
      context={{ isStaff }}
      getContextMenuItems={getContextMenuItems}
      groupDisplayType={groupDisplayType}
      onRowSelected={handleRowSelectionChanged}
      detailCellRendererParams={detailAntibodyRendererParams}
      onRowGroupOpened={handleRowGroupDisplayChanged}
      groupRemoveSingleChildren
      animateRows
      rowSelection="multiple"
      rowClassRules={{
        'antibody-has-control-slides': (data) => !data.data,
        'antibody-has-children': (data) => !data.data,
        'left-padding-exists': (data) => !data.data && isDiscountExists,
        'antibody-has-parent': (data) => data.node.parent.rowIndex,
      }}
      onFirstDataRendered={handleFirstDataRendered}
      onRowClicked={handleRowClicked}
      suppressRowClickSelection={isForOrderForm}
      overlayNoRowsTemplate="<span>No data</span>"
      suppressNoRowsOverlay={isLoading}
    />
  );
};
