import { useGetUserDetailsQuery } from 'store/apis/userDetailsApi';

export const useProductFruits = () => {
  const { data: userDetails = {}, isLoading } = useGetUserDetailsQuery();

  const userConfig = {
    username: `${userDetails.email}`,
  };

  const workspaceCode = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE;

  return {
    isLoading,
    userConfig,
    workspaceCode,
  };
};
