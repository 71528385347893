const generalSpinnerThemeStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const gridSpinnerThemeStyle = {
  height: '450px',
  ...generalSpinnerThemeStyle,
};

export const pageWithHeaderSpinnerThemeStyle = {
  height: 'calc(100% - 80px)',
  ...generalSpinnerThemeStyle,
};

export const spinnerThemeStyle = {
  height: '100%',
  ...generalSpinnerThemeStyle,
};
