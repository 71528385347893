import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import React from 'react';

export const DefaultSubmitButton = ({ children, ...props }) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={false}
          type={'submit'}
          {...props}
        >
          {children}
        </Button>
      </Box>
    </Box>
  );
};
