import { useMainToolbarStyles } from 'components/ImageViewer/styles';
import React from 'react';
import { SlideViewerToolbarButton } from 'components/ImageViewer/components/ImageViewerToolbar/Buttons/SlideViewerToolbarButton';

export const SlideViewerToolbarToggleButton = ({
  className = '',
  isSelected,
  ...props
}) => {
  const { classes } = useMainToolbarStyles();
  const selectedClasses = 'Mui-selected';

  const classNames = `${className} ${isSelected ? selectedClasses : ''} ${
    classes.toggleButton
  }`;

  return (
    <SlideViewerToolbarButton
      variant="contained"
      color="default"
      className={classNames}
      {...props}
    />
  );
};
