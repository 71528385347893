import { histowizTheme } from 'themes/defaultMuiTheme';
import { makeStyles } from 'tss-react/mui';

import {
  ACTION_LINK_COLOR,
  BACKGROUND_GREY_LIGHT,
  HISTOWIZ_SECONDARY_COLOR,
  WHITE_SMOKE,
} from 'utilities/colors';

export const useSamplesEditorStyles = makeStyles()(() => ({
  agGridWrapper: {
    backgroundColor: 'transparent',
    width: '100%',
    '.ag-root-wrapper-body': {
      height: 'calc(100vh - 250px)',
    },

    '.ag-header-row': {
      backgroundColor: '#D9D9D9',
      fontSize: '12px',
      color: 'black',
      padding: '0.625rem 0',
    },

    '.ag-row-hover': {
      backgroundColor: `${WHITE_SMOKE} !important`,
      cursor: 'pointer',
    },
    '.ag-cell-focus': { borderColor: 'transparent !important' },

    '.ag-row-odd': {
      backgroundColor: BACKGROUND_GREY_LIGHT,
    },

    '.ag-cell': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      //apply style only for editable cell
      '&:not(:has(#no-edit))': {
        cursor: 'pointer',

        '&:hover': {
          border: `1px solid ${HISTOWIZ_SECONDARY_COLOR}`,
        },
      },
      //apply style only for uneditable cell
      '&:has(#no-edit)': {
        opacity: '0.6',
        cursor: 'default',
      },

      //apply style only for checkbox cell
      '&:has(#no-edit-checkbox)': {
        opacity: 1,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'transparent',
        },
      },
    },
  },
  bottomWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '15px',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '5px',
    },
  },
  pagination: {
    ' > ul': {
      justifyContent: 'flex-end',
      '& .Mui-selected': {
        borderColor: histowizTheme.palette.secondary.main,
      },
    },
  },
  hideGrid: {
    display: 'none',
  },
}));

export const useToolbarStyles = makeStyles()((_, { activeSave }) => ({
  viewButton: {
    marginRight: '1rem',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.625rem',
  },
  button: {
    color: 'black',
    backgroundColor: 'rgba(66, 148, 213, 0.2)',
    border: activeSave ? '1px solid #5470FF' : 0,

    ':hover': {
      backgroundColor: 'rgba(66, 148, 213, 0.2)',
    },
  },
  excelButton: {
    ':first-of-type': {
      marginRight: '0.625rem',
    },
    '.MuiButton-endIcon > div': {
      fontSize: 'inherit',
    },
  },
  buttonsWrapper: {
    display: 'flex',
  },
  changelog: {
    border: '2px solid rgba(0, 0, 0, 0.26)',
    borderRadius: '4px',
    marginLeft: '10px',
  },
}));

export const useSaveChangesModalStyles = makeStyles()(() => ({
  modal: {
    ' > div ': {
      padding: '1.563rem',
      ' div > div:nth-last-of-type(2)': {
        borderBottom: 0,
        paddingBottom: 0,
      },
      ' > h2': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
        position: 'relative',
        '& .MuiButtonBase-root': {
          padding: 0,
          position: 'absolute',
          right: '-10px',
          top: '-10px',
        },
      },
    },
  },
  listWrapper: {
    maxHeight: '50vh',
    overflowY: 'auto',
  },
  buttonsWrapper: {
    paddingTop: '2.5rem',
    paddingBottom: '1.563rem',
    display: 'flex',
    justifyContent: 'right',
    '> button': {
      padding: '0.625rem 1.875rem',
      fontSize: '1 rem',
      ':first-of-type': {
        marginRight: '1.25rem',
      },
    },
  },
  sampleWrapper: {
    borderBottom: '1px solid grey',
    padding: '0.938rem 0',
    fontSize: '12px',
    '& > div:first-of-type': {
      paddingBottom: '0.625rem',
    },
  },
  sampleField: {
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  oldValue: {
    fontSize: '12px',
    textDecoration: 'line-through',
    color: 'red',
  },
  newValue: {
    fontSize: '12px',
    color: 'green',
  },
  fieldsWrapper: {
    paddingBottom: '0.313rem',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  associatedLink: {
    color: ACTION_LINK_COLOR,
    cursor: 'pointer',
  },
}));

export const useSortPanelStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    padding: '10px 30px 10px 7px',
    backgroundColor: '#ffffff',
    border: '1px solid #bdc3c7',
    borderBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div:first-of-type': {
      width: '40%',
    },
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  sortItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&>p:hover': {
      cursor: 'pointer',
    },
    '&:last-child': {
      paddingRight: '0',
    },
  },
  sortTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  activeSort: {
    textDecoration: 'underline',
  },

  filterInput: {
    fontSize: '12px',
    ':before, :after, :hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #D9D9D9',
    },
  },
  toggle: {
    marginLeft: 0,
    '& .MuiTypography-root': {
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  },
}));

export const useColumnModalStyles = makeStyles()(() => ({
  modal: {
    ' > div ': {
      padding: '1.563rem',

      ' > h2': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
        paddingBottom: 10,
        position: 'relative',
        '& .MuiButtonBase-root': {
          padding: 0,
          position: 'absolute',
          right: '-10px',
          top: '-10px',
        },
      },
    },
  },
  addButton: {
    marginTop: '15px',
    float: 'right',
  },
}));
