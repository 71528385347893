import { makeStyles } from 'tss-react/mui';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import { MODAL_WITHOUT_BACKGROUND } from 'components/SlideGroups/utilities/styles';
import { DATA_MANAGEMENT_BLUE } from 'utilities/colors';

export const useConfirmDataOnHardDiskModalStyles = makeStyles()((theme) => ({
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '1.125rem',
  },
  cardContent: {
    width: '100%',
    paddingInline: '2.5rem',
    paddingBottom: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepperWrapper: {
    width: '450px',
  },
  note: {
    marginTop: '1rem',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    fontWeight: 'bold',
    textAlign: 'center',
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

export const useShippingStepStyles = makeStyles()(() => ({
  headingText: {
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
  inputLabel: {
    fontSize: '0.75rem',
    marginBottom: '0.5rem',
  },
  divider: {
    height: '1px',
    backgroundColor: 'rgb(220, 220, 220)',
    width: '100%',
    marginTop: '1.5rem',
    marginBottom: '1rem',
  },
  plusIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '0.5rem',
  },
  addAddressButton: {
    borderColor: 'rgba(0, 0, 0, 0.6)',
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: '1rem',
  },
  addressesList: {
    height: '30vh',
    overflowY: 'auto',
    ...VERTICAL_SCROLL_STYLE,
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  listTitle: {
    fontSize: '0.75rem',
    marginBottom: '0.5rem',
  },
  addressCardWrapper: {
    marginTop: '1rem',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
}));

export const useAddressCardStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderRadius: '0.75rem',
    padding: '1rem',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  company: {
    fontSize: '1.125rem',
  },
  address: {
    marginTop: '0.75rem',
    fontSize: '0.875rem',
  },
  phoneNumber: {
    fontSize: '0.875rem',
  },
  selectedContainer: {
    border: `1px solid ${DATA_MANAGEMENT_BLUE}`,
  },
  divider: {
    borderTop: '1px solid rgb(220, 220, 220)',
  },
}));

export const useInvoiceStepStyles = makeStyles()(() => ({
  title: {
    fontSize: '0.75rem',
    marginBottom: '0.5rem',
  },
  invoiceStepContainer: {
    paddingTop: '4rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  subtitle: {
    marginBottom: '0.625rem',
    fontSize: '0.75rem',
  },
  verticalDivider: {
    borderRight: '1px solid rgb(220, 220, 220)',
    marginInline: '2.5rem',
  },
  invoiceInfoRow: {
    borderTop: '0.75px solid rgba(0, 0, 0, 0.5)',
    paddingBlock: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  invoiceInfoLabel: {
    textTransform: 'uppercase',
    opacity: 0.6,
    fontSize: '0.75rem',
    letterSpacing: 0.1,
    fontWeight: 700,
  },
  invoiceInfoValue: {
    opacity: 0.8,
    fontSize: '0.875rem',
    fontWeight: 700,
  },
}));

export const useOrdersListStepStyles = makeStyles()(() => ({
  ordersListContainer: {
    height: '38vh',
    overflowY: 'auto',
    ...VERTICAL_SCROLL_STYLE,
  },
}));

export const ConfirmDataOnHardDiskModalStyles = {
  ...MODAL_WITHOUT_BACKGROUND,
  height: 'min-content',
  maxWidth: '1200px',
  minHeight: '83vh',
  width: '75%',
};
