import { SLIDES_LIST_HEADER_HEIGHT } from 'components/OrderSlidesList/OrderSlidesListViewV2Styles';
import { makeStyles } from 'tss-react/mui';
import {
  HISTOWIZ_PRIMARY_COLOR,
  IDEXX_PRIMARY_COLOR,
  LIGHT_GREY_2,
  MEDIUM_GREY,
  PRIMARY_BLUE,
} from 'utilities/colors';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';

export const useLegendStyles = makeStyles()(() => ({
  tableContainer: {
    marginTop: '0.75rem',
  },
  table: {
    minWidth: '100%',
    background:
      'linear-gradient(180deg, rgba(108, 162, 187, 0.2), rgba(108, 162, 187, 0.05))',
  },
  cell: {
    padding: '0.5rem',
  },
  headerCell: {
    padding: '0.75rem 1rem 0.5rem 1rem',
    borderBottom: `1px solid ${PRIMARY_BLUE}`,
  },
}));

export const useExportHaloAnnotationsConfirmationModalStyles = makeStyles()(
  (theme) => ({
    title: {
      fontWeight: 500,
      fontSize: '1.25rem',
      textAlign: 'center',
    },
    container: {
      minHeight: '300px',
      maxHeight: '500px',
      overflowY: 'scroll',
      width: '100%',
      ...VERTICAL_SCROLL_STYLE,
    },
    modalBackground: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      borderRadius: '0.25rem',
    },
    cell: {
      display: 'flex',
      alignItems: 'center',
    },
    greyText: {
      color: LIGHT_GREY_2,
    },
  }),
);

export const useShortcutsPromptStyles = makeStyles()(() => ({
  container: {
    padding: '1rem',
    right: '1rem',
    bottom: '1rem',
    position: 'fixed',
    width: '330px',
    height: 'max-content',
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderColor: '#0000000d',
    borderRadius: '4px',
    boxShadow: '0px 8px 20px 15px #0000000d',
  },
  keyboardName: {
    height: '1.25rem',
  },
  row: {
    borderBottom: '1px solid rgba(22, 24, 35, 0.12);',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.5rem',
  },
  keyIconWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.12);',
    backgroundColor: '#eee',
    borderRadius: '3px',
    boxShadow:
      '0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset',
    height: '1.5rem',
    padding: '0.25rem',
    display: 'flex',
    alignItems: 'center',
  },
  keyLabel: {
    fontWeight: 600,
  },
}));

export const useStyles = makeStyles()({
  root: {
    display: 'grid',
    height: '100%',
    width: '100%',
    gridTemplateColumns: '1fr 24px min-content',
  },
  viewerContainer: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    height: '100%',
    maxHeight: `calc(100vh - ${SLIDES_LIST_HEADER_HEIGHT})`,
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '1rem',
  },
  thumbnail: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

export const useFloatingMacroStyles = makeStyles()(() => ({
  floatingPaper: {
    backgroundColor: 'transparent',
    border: '0px',
    overflow: 'hidden',

    '&:hover': {
      '& .hover-visible': {
        background: HISTOWIZ_PRIMARY_COLOR,

        '& svg': {
          display: 'block',
        },
      },
    },
  },
  floatingThumbnail: {
    position: 'fixed',
    right: '0.5rem',
    bottom: '0.25rem',
    zIndex: 1,
    objectFit: 'cover',
    objectPosition: '0 0',
    width: '15.625rem',
    height: '15.625rem',
  },
  closeBackground: {
    position: 'fixed',
    right: '0rem',
    bottom: '15.375rem',
    opacity: 0.7,
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: MEDIUM_GREY,
    cursor: 'pointer',
    zIndex: 2,
  },
  closeIcon: {
    width: '0.75rem',
    height: '0.75rem',
    display: 'none',
    color: 'white',
  },
}));

export const useFloatingFilterStyles = makeStyles()(() => ({
  resetFilterIcon: {
    position: 'absolute',
    right: '0px',
  },
}));

export const useAddSlideCommentStyles = makeStyles()(() => ({
  newSlideComment: {
    marginBottom: '0.5rem',
    marginTop: '1.25rem',

    '& .MuiInputBase-root': {
      padding: '0.5rem',
    },
  },
  commentRedPlaceholder: {
    '& textarea::placeholder': {
      color: IDEXX_PRIMARY_COLOR,
    },
  },
  submit: {
    padding: '0.2rem 0.5rem',
    fontSize: '0.875rem',
    fontWeight: 500,
    marginRight: '0.5rem',
  },
}));

export const useOrderSlideBreadcrumbsHeaderStyles = makeStyles()((theme) => ({
  root: {
    alignItems: 'flex-end',
    display: 'flex',

    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
      maxWidth: '100%',
    },
  },
  container: {
    maxWidth: '100%',
  },
  starIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '1.5rem',
      height: '1.5rem',
      marginTop: '-0.25rem',
    },
  },
  iconButton: {
    padding: 0,

    '&.MuiIconButton-root': {
      [theme.breakpoints.down('lg')]: {
        padding: '0.4rem 0.2rem 0.2rem',
      },
    },
  },
}));
