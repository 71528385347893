import { useLazyGetAIToolByIdQuery } from 'store/apis/cmsApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { ERROR_FETCHING_AI_TOOLS_MESSAGE } from 'components/pages/AppLibrary/constants';

export const useGetCurrentAiModel = () => {
  const [getModel] = useLazyGetAIToolByIdQuery();
  const { showError } = useSnackbar();
  const getCurrentModel = (model, setCurrentModel) =>
    getModel(model)
      .unwrap()
      .then((res) => {
        if (res?.length) {
          setCurrentModel(res[0]);
        }
      })
      .catch(() => {
        showError(ERROR_FETCHING_AI_TOOLS_MESSAGE);
      });

  return { getCurrentModel };
};
