import React from 'react';

import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { PathologyConsultationForm } from 'components/PathologyConsultations/OrderForm/PathologyConsultationForm';

export const PathologyConsultationOrderFormPage = () => (
  <MiniDrawerWithContext header={'Pathology Consultation Questionnaire'}>
    <PathologyConsultationForm />
  </MiniDrawerWithContext>
);
