import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { useShippingLabelsStyles } from 'components/OrderForm/BillingShipping/styles';

export const ShippingLabelsTab = ({ shippingLabels }) => {
  const { classes } = useShippingLabelsStyles();

  return shippingLabels.map((shippingLabel) => {
    const { tracking_number, shippo_url, uuid } = shippingLabel;

    return (
      <Box key={uuid} mb={2} className={classes.labelsTable}>
        <div>Tracking #: {tracking_number}</div>
        <a href={shippo_url} target="_blank" rel="noopener noreferrer">
          <Button variant="contained" color="secondary" size="small">
            View Shipping Label
          </Button>
        </a>
      </Box>
    );
  });
};
