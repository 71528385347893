import { useEffect } from 'react';

export const useAGGridOverlayControl = (isLoading, rowData, gridApi) => {
  useEffect(() => {
    if (!gridApi) {
      return;
    }

    const isRowDataValid = rowData && rowData.length === 0;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else {
      if (isRowDataValid) {
        gridApi.suppressNoRowsOverlay = false;
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [isLoading, rowData, gridApi]);
};
