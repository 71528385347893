import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from 'tss-react/mui';
import shallow from 'zustand/shallow';

import { useViewerStore } from 'components/IFViewer/state';

const useStyles = makeStyles()((theme) => ({
  root: {
    maxHeight: (props) => `${props.maxHeight - theme.spacing(4)}px`,
    width: '390px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
    },
    scrollbarWidth: 'none',
  },
  typography: {
    fontSize: '.8rem',
  },
  paper: {
    backgroundColor: 'rgba(25, 25, 25, 1)',
    borderRadius: 2,
  },
  item: { width: '100%' },
  divider: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
}));

export const Menu = ({ children, ...props }) => {
  const { classes } = useStyles(props, {
    props: props,
  });

  const [isControllerOn, toggleIsControllerOn] = useViewerStore(
    (store) => [store.isControllerOn, store.toggleIsControllerOn],
    shallow,
  );

  return isControllerOn ? (
    <Box right={0} top={10} className={classes.root}>
      <Paper className={classes.paper}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {children.map((child, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={i} className={classes.item}>
                {child}
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Box>
  ) : (
    <Box position="absolute" right={0} top={5} m={2}>
      <Button
        variant="outlined"
        size="small"
        endIcon={<SettingsIcon />}
        onClick={toggleIsControllerOn}
        aria-label="show-menu"
      >
        Show Menu
      </Button>
    </Box>
  );
};
