import React from 'react';
import { Bar, Pie, Line } from 'react-chartjs-2';

import { BAR, LINE, PIE } from 'components/OrderSlidesChart/helpers/constants';
import { getChartOptions } from 'components/OrderSlidesChart/helpers/utils';

export const OrderSlidesChartBody = ({
  chartType,
  labels,
  datasets,
  orderName,
  handleOpenSlideModal,
  showValues,
  slides,
  chartRef,
  chartData,
  params,
  slideNameParameter,
}) => {
  const data = { labels, datasets };
  const options = getChartOptions(
    orderName,
    handleOpenSlideModal,
    showValues,
    slides,
    chartType === PIE,
  );
  const pieLabels = Object.keys(params ?? {}).filter((param) => params[param]);
  const pieDataSets = labels.map((slide) => {
    const slideData = chartData.find(
      (slideData) => slideData[slideNameParameter] === slide,
    );
    return {
      label: slide,
      data: pieLabels.map((label) => slideData[label]),
      backgroundColor: datasets.map(({ backgroundColor }) => backgroundColor),
    };
  });

  switch (chartType) {
    case PIE:
      return (
        <Pie
          id="pieChartBody"
          options={options}
          data={{ labels: pieLabels, datasets: pieDataSets }}
          type={PIE}
          ref={chartRef}
        />
      );
    case LINE:
      return (
        <Line
          id="lineChartBody"
          options={options}
          data={data}
          type={LINE}
          ref={chartRef}
        />
      );
    default:
      return (
        <Bar
          id="barChartBody"
          options={options}
          data={data}
          type={BAR}
          ref={chartRef}
        />
      );
  }
};
