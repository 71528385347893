import {
  useAddSlideToGroup,
  useDeleteSlidesFromSlideGroup,
} from 'components/SlideGroups/utilities/hooks';
import { YELLOW_MARKED } from 'components/utilities/constants';
import { IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/StarRounded';
import StarBorderIcon from '@mui/icons-material/StarBorderRounded';
import React from 'react';
import {
  favoriteGroupUUIDSelector,
  favoriteSlidesSelector,
} from 'store/slices/slideGroupsSlice';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';

const useStyles = makeStyles()(() => ({
  iconButton: {
    padding: 0,
  },
}));

export const FavoriteSlideIconButton = ({
  slideUUID,
  filledIconProps = {},
  outlineIconProps = {},
  className,
}) => {
  const { classes } = useStyles();
  const favoriteGroupUUID = useSelector(favoriteGroupUUIDSelector);
  const { addSlideToSlideGroup } = useAddSlideToGroup();
  const { deleteSlidesFromSlideGroup } = useDeleteSlidesFromSlideGroup();
  const favoriteSlides = useSelector(favoriteSlidesSelector);
  const isStarred = favoriteSlides?.[slideUUID] || false;

  const handleClick = (e) => {
    e.stopPropagation();

    if (!slideUUID || !favoriteGroupUUID) {
      return;
    }

    const group = { uuid: favoriteGroupUUID, isFavorite: true };
    const slides = [{ uuid: slideUUID }];

    isStarred
      ? deleteSlidesFromSlideGroup(group, slides, true)
      : addSlideToSlideGroup(group, slides, true);
  };

  return (
    <IconButton
      size="large"
      onClick={handleClick}
      title={isStarred ? 'Unmark as favorite' : 'Mark as favorite'}
      className={className || classes.iconButton}
    >
      {isStarred ? (
        <StarIcon htmlColor={YELLOW_MARKED} {...filledIconProps} />
      ) : (
        <StarBorderIcon {...outlineIconProps} />
      )}
    </IconButton>
  );
};
