import { Box, Button, Typography } from '@mui/material';
import {
  DEFAULT_VIEW,
  ORDERS_HISTORY_TABS_LIST,
} from 'components/UserProfile/constants';
import {
  ChevronLeft as ChevronLeftIcon,
  Update as UpdateIcon,
} from '@mui/icons-material';
import { HeaderTabs } from 'components/UserProfile/components/HeaderTabs';
import React from 'react';

export const ChangeLogHeader = ({
  classes,
  setViewMode,
  currentTabValue,
  handleTabChange,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box
        onClick={() => setViewMode(DEFAULT_VIEW)}
        className={classes.backContainer}
      >
        <ChevronLeftIcon fontSize="large" />
        <Typography className={classes.backLabel}>BACK</Typography>
        <Typography className={classes.cardTitle}>Change Log</Typography>
      </Box>
      <Box display="flex">
        <HeaderTabs
          currentTabValue={currentTabValue}
          handleTabChange={handleTabChange}
          tabsList={ORDERS_HISTORY_TABS_LIST}
        />
        <Button
          variant="contained"
          size="small"
          className={classes.changeLogButton}
          onClick={() => setViewMode(DEFAULT_VIEW)}
        >
          <UpdateIcon className={classes.changeLogIcon} />
        </Button>
      </Box>
    </Box>
  );
};
