import { SnackbarWithContext } from 'components/Modals/DefaultSnackbar';
import React from 'react';

export const CreditCardSnackbar = () => {
  return (
    <SnackbarWithContext
      snackbarString={'snackbarCreatedCreditCard'}
      message={
        'Your credit card has been saved, please select it from the dropdown menu under Existing!'
      }
    />
  );
};
