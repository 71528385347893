import { Box } from '@mui/material';
import { useSlideLabelStyles } from 'components/pages/AppLibrary/styles';

export const SlideLabel = ({ label }) => {
  const { classes } = useSlideLabelStyles();
  return label ? (
    <Box className={classes.root}>
      <Box className={classes.title}>{label}</Box>
    </Box>
  ) : null;
};
