import React from 'react';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { CreateSlideGroupForm } from 'components/SlideGroups/Modals/components/CreateSlideGroupForm';
import CloseIcon from '@mui/icons-material/Close';
import { ModalSize } from 'components/Modals/constants';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';

const cursorPointerStyle = { cursor: 'pointer' };

export const CreateSlideGroupModal = ({ open, onClose }) => {
  return (
    <>
      <DefaultModal open={open} onClose={onClose} size={ModalSize.SIMPLE}>
        <Box width={'25vw'}>
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={1}>
              <CloseIcon onClick={onClose} style={cursorPointerStyle} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom align={'center'}>
                New Slide Group Name?
              </Typography>
            </Grid>
          </Grid>
          <CreateSlideGroupForm onClose={onClose} />
        </Box>
      </DefaultModal>
    </>
  );
};
