import React, { useContext } from 'react';
import { AntibodiesListView } from './AntibodiesListView';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import 'components/AntibodiesList/styles.css';
import { AppContext } from 'services/context';
import highResolutionLogo from 'images/logos/logoHighResolution.png';
import { useAntibodiesPageStyles } from 'components/AntibodiesList/antibodiesListStyles';

export const AntibodiesPage = () => {
  const context = useContext(AppContext);
  const isLogged = context.isLoggedIn;

  const { classes } = useAntibodiesPageStyles();

  return isLogged ? (
    <MiniDrawerWithContext header={'Antibodies List'} withoutBackground>
      <div className={classes.antibodiesListWrapper}>
        <AntibodiesListView />
      </div>
    </MiniDrawerWithContext>
  ) : (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.logoWrapper}>
          <a href={'https://www.histowiz.com'}>
            <img
              className={classes.logo}
              src={highResolutionLogo}
              alt="HistoWiz Logo"
            />
          </a>
        </div>
        <AntibodiesListView />
      </main>
    </div>
  );
};
