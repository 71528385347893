import Button from '@mui/material/Button';
import React from 'react';

import { useSnackbar } from 'notistack';
import { WHITE } from 'utilities/colors';

export const SnackbarActionCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const handleCloseClick = () => closeSnackbar(snackbarKey);

  return (
    <Button style={{ color: WHITE }} onClick={handleCloseClick} size="small">
      Close
    </Button>
  );
};
