import { makeStyles } from 'tss-react/mui';

export const useCommonStyles = makeStyles()(() => ({
  table: {
    overflow: 'hidden',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  row: {
    fontSize: '0.875rem',
    padding: '0.5rem 1rem 0.5rem 1rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',

    '&:first-of-type': {
      borderTop: 'none',
    },
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    lineHeight: 2,
    fontSize: '0.875em',
    cursor: 'pointer',
  },
  textWithLimitedWidth: {
    width: 'calc(100% - 0.5rem)',
  },
  stepContainer: {
    width: '520px',
  },
}));
