import { Box } from '@mui/material';
import moment from 'moment';
import { useAiToolStyles } from 'components/pages/AppLibrary/components/styles';
import { InformationMenu } from 'components/pages/AppLibrary/components/InformationMenu';
import { SlidesGallery } from 'components/pages/AppLibrary/SlidesGallery';
import { useHistory, generatePath } from 'react-router';
import { AI_MODEL } from 'constants/urls';
import { MONTH_DAY_YEAR_SHORT_FORMAT } from 'utilities/dates_and_times';

export const AiTool = ({ aiTool }) => {
  const history = useHistory();
  const { classes } = useAiToolStyles();

  const redirectHandler = () => {
    const url = generatePath(AI_MODEL, { model: aiTool.toolId });

    history.push(url);
  };

  return (
    <Box>
      <Box className={classes.title} onClick={redirectHandler}>
        <span>{aiTool.title}</span>
        <span className={classes.lastUpdated}>
          Last Updated: <br />
          {moment(aiTool.updatedAt).format(MONTH_DAY_YEAR_SHORT_FORMAT)}
        </span>
      </Box>
      <Box className={classes.body}>
        <SlidesGallery
          showThumbs={false}
          content={[aiTool.video, ...aiTool.toolImages[0].toolImage]}
          maxWidth="100%"
          maxHeight="400px"
          showArrows
        />
        <Box className={classes.menuWrapper} onClick={redirectHandler}>
          <InformationMenu model={aiTool} className={classes.informationMenu} />
        </Box>
      </Box>
    </Box>
  );
};
