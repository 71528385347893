import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { ColumnModal } from 'components/SampleEditor/ColumnModal';
import { useColumnHeaderStyles } from 'components/SampleEditor/components/styles';

export const ColumnHeader = ({
  name,
  column,
  gridApi,
  setColumns,
  removable,
  required,
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const { classes } = useColumnHeaderStyles();

  return (
    <div className={classes.wrapper}>
      <span className={classes.nameWrapper}>
        {name}
        {required && <span className={classes.requiredIcon}>*</span>}
      </span>
      {removable && (
        <DeleteIcon
          onClick={() => setIsDeleteOpen(true)}
          className={classes.trashIcon}
        />
      )}
      {isDeleteOpen && (
        <ColumnModal
          handleClose={() => setIsDeleteOpen(false)}
          gridApi={gridApi}
          setColumns={setColumns}
          columnForDelete={column}
        />
      )}
    </div>
  );
};
