import React from 'react';
import { Grid, Typography, Checkbox, Box, Tooltip } from '@mui/material';
import { useOrdersListStyles } from 'components/TeamOverview/components/ManageDataCard/styles';
import {
  buildSubmittedUserLabel,
  getIAReportLink,
  getPathologyLink,
} from 'components/UserProfile/utilities';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';
import { ApprovalDelegationIcon } from 'images/icons/ApprovalDelegationIcon';
import {
  getOrderDataUsageLabel,
  getOrderLabel,
} from 'components/TeamOverview/utilities';
import { DeleteIcon } from 'images/icons/DeleteIcon';
import { SubmitActionTypes } from 'components/TeamOverview/constants';
import { TRANSPARENT_BLACK_26 } from 'utilities/colors';
import _ from 'lodash';
import { HardDriveIcon } from 'images/icons/HardDriveIcon';

const compareProps = (prev, next) => {
  return (
    _.isEqual(prev.order, next.order) &&
    prev.isSelected === next.isSelected &&
    prev.activeAction === next.activeAction
  );
};

export const OrdersListRow = React.memo(
  ({
    order,
    handleCheckboxClick,
    isSelected,
    handleOrderSelect,
    activeAction,
  }) => {
    const { classes } = useOrdersListStyles();

    const submittedUserLabel = buildSubmittedUserLabel(
      order.submitted_user,
      order.created,
    );

    const isAllowedStatus = (activeAction, isArchived, isContributed) => {
      if (
        isArchived ||
        (activeAction === SubmitActionTypes.ARCHIVE_ACTION && isContributed) ||
        (activeAction === SubmitActionTypes.CONTRIBUTE_ACTION && isContributed)
      ) {
        return false;
      }

      return !(
        activeAction === SubmitActionTypes.CONTRIBUTE_ACTION && isArchived
      );
    };

    const isContributed = order.pathology_map_consent;
    const isArchived = order.is_storage_archived;
    const isOnHardDisk = order.exported;

    const OrderStatusesIcons = () => (
      <Box display="flex" justifyContent="space-evenly" width={50}>
        {isContributed && (
          <Tooltip title="This item has been contributed" arrow>
            <span>
              <ApprovalDelegationIcon size={18} color={TRANSPARENT_BLACK_26} />
            </span>
          </Tooltip>
        )}
        {isArchived && (
          <Tooltip title="This item has been deleted" arrow>
            <span>
              <DeleteIcon size={18} color={TRANSPARENT_BLACK_26} />
            </span>
          </Tooltip>
        )}
        {isOnHardDisk && (
          <Tooltip title="Hard drive ordered" arrow>
            <span>
              <HardDriveIcon size={18} color={TRANSPARENT_BLACK_26} />
            </span>
          </Tooltip>
        )}
      </Box>
    );

    return (
      <Grid
        container
        className={`${classes.container} ${classes.rowHovered} ${
          isSelected && classes.selectedRow
        }`}
      >
        <Grid
          item
          xs={0.25}
          className={`${classes.column} ${classes.horizontallyCenteredCell}`}
        >
          {activeAction && (
            <Checkbox
              size="small"
              checked={isSelected}
              onChange={() => handleCheckboxClick(order, isSelected)}
              disabled={
                !isAllowedStatus(activeAction, isArchived, isContributed)
              }
            />
          )}
        </Grid>
        <Grid
          item
          xs={0.75}
          className={`${classes.column} ${classes.horizontallyCenteredCell}`}
        >
          {isContributed || isArchived || isOnHardDisk ? (
            <Box
              height="38px"
              width="55px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <OrderStatusesIcons />
            </Box>
          ) : (
            '-'
          )}
        </Grid>
        <Grid
          item
          xs={1}
          className={`${classes.column} ${classes.horizontallyCenteredCell}`}
        >
          <Typography>{getOrderDataUsageLabel(order)}</Typography>
        </Grid>
        <Grid
          item
          xs={3.25}
          className={classes.column}
          onClick={handleOrderSelect}
        >
          <TextWithLimitedWidth
            value={getOrderLabel(order)}
            containerClass={classes.textWithLimitedWidth}
            textClass={classes.orderInfo}
            tooltipPlacement={'bottom'}
          />
        </Grid>
        <Grid item xs={1.75} className={classes.column}>
          <TextWithLimitedWidth
            value={order.state_label}
            containerClass={classes.textWithLimitedWidth}
            tooltipPlacement={'bottom'}
          />
        </Grid>
        <Grid item xs={2} className={classes.column}>
          <TextWithLimitedWidth
            value={submittedUserLabel}
            containerClass={classes.textWithLimitedWidth}
            tooltipPlacement={'bottom'}
          />
        </Grid>
        <Grid
          item
          xs={0.5}
          className={`${classes.column} ${classes.horizontallyCenteredCell}`}
        >
          <Typography>{order.slides_count}</Typography>
        </Grid>
        <Grid
          item
          xs={0.75}
          className={`${classes.column} ${classes.horizontallyCenteredCell}`}
        >
          <Typography>{order.if_slides_count}</Typography>
        </Grid>
        <Grid item xs={1.75} className={classes.horizontallyCenteredCell}>
          {getPathologyLink(order, 'Pathology')}
          {getIAReportLink(order, 'Image Analysis')}
        </Grid>
      </Grid>
    );
  },
  compareProps,
);
