import { useUpdateOrderMutation } from 'store/apis/orderApi';

export const useNotifyTeamMembers = (order) => {
  const [updateOrderMutation, { isLoading }] = useUpdateOrderMutation();

  const notifyTeamMembers = (users) => {
    return updateOrderMutation({
      orderUUID: order.uuid,
      postParams: {
        external_subscribers_uuids: users.map((user) => user.uuid),
      },
    });
  };

  return {
    isLoading,
    notifyTeamMembers,
  };
};
