import React, { useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import { useTextWithLimitedWidthStyles } from 'components/SlideGroups/utilities/styles';

export const TextWithLimitedWidth = ({
  value,
  containerClass = null,
  textClass = null,
  tooltipPlacement = 'left',
}) => {
  const { classes } = useTextWithLimitedWidthStyles();
  const [wrapperRef, setWrapperRef] = useState();
  const [valueRef, setValueRef] = useState();

  const centeredContainerWidth = wrapperRef?.offsetWidth;
  const cellContentWidth = valueRef?.offsetWidth;

  return (
    <Tooltip
      title={cellContentWidth >= centeredContainerWidth ? value : ''}
      placement={tooltipPlacement}
    >
      <div
        ref={(newRef) => setWrapperRef(newRef)}
        className={classNames(classes.alignToLeftContainer, containerClass)}
      >
        <Typography
          className={classNames(classes.slideName, textClass)}
          ref={(newRef) => setValueRef(newRef)}
        >
          {value}
        </Typography>
      </div>
    </Tooltip>
  );
};
