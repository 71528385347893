export const ORDER_SLIDES_CHART_TITLE = 'Order Slides Chart';

export const CHART_COLORS = [
  '#38A9DA',
  '#10A16E',
  '#F9B171',
  '#F9E85C',
  '#FA5F68',
  '#6F4E7C',
  '#8DDDD0',
];

export const MAIN_SLIDES_PARAMETERS = ['Name', 'Image Tag', 'Image'];

export const BAR = 'bar';
export const PIE = 'pie';
export const LINE = 'line';

export const NO_DATA_AVAILABLE = 'No data available';
export const URL_PARAM_SLIDES = 'slides';
export const URL_PARAM_PARAMETERS = 'parameters';
export const URL_PARAM_SOURCE = 'source';
export const URL_PARAM_TYPE = 'type';
export const URL_PARAM_SELECT_ALL_SLIDES = 'selectAllSlides';
export const URL_PARAM_FILTER_SLIDES_BY = 'filterBySlideName';

export const AVAILABLE_PARAMETERS = [
  URL_PARAM_SLIDES,
  URL_PARAM_PARAMETERS,
  URL_PARAM_SOURCE,
  URL_PARAM_TYPE,
  URL_PARAM_FILTER_SLIDES_BY,
];

export const IMAGE_TAG_KEY = 'Image Tag';
export const ANALYSIS_KEY = 'Analysis Region';
export const SLIDE_DEFINITION_ID = 'slide-definition';

export const SHOW_CHARTS_DEMO_MODAL_FIELD = 'showChartsDemoModal';
export const MODAL_HEADER = 'Slide Charts Demo';
