import { Button, MenuItem, TextField, Typography } from '@mui/material';
import { DefaultDialog } from 'components/Modals/DefaultDialog';
import {
  ShippingSpeedOptions,
  UPDATE_PO_ERROR_MESSAGE,
  UPDATE_PO_SUCCESS_MESSAGE,
} from 'components/OrderForm/BillingShipping/utilities/constants';
import React, { Fragment } from 'react';
import {
  POAttachment,
  QuoteAttachment,
} from 'components/OrderForm/BillingShipping/BillingShippingComponents';
import { POUploadAttachment } from 'components/OrderForm/POAttachment';
import { QuoteUploadAttachment } from 'components/OrderForm/QuoteAttachment/QuoteAttachment';
import { useDispatch, useSelector } from 'react-redux';
import { useGetOrderByUUIDQuery } from 'store/apis/orderApi';
import { useUpdatePOAttachmentMutation } from 'store/apis/attachmentsApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useBillingShippingStyles } from 'components/OrderForm/BillingShipping/styles';
import {
  billingShippingSelectors,
  setOrder,
} from 'store/slices/billingShippingSlice/billingShippingSlice';

export const FedexDimensionsModal = ({
  isOpen,
  onClose,
  shippingOption,
  setShippingOption,
}) => {
  const { classes } = useBillingShippingStyles();
  const modalActions = () => {
    return (
      <Typography align={'right'} className={classes.buttonTypography}>
        <Button
          className={classes.closeModalButton}
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
      </Typography>
    );
  };

  return (
    <DefaultDialog
      open={isOpen}
      onClose={onClose}
      actions={modalActions}
      title={'FedEx Shipping Options - Label Generation'}
    >
      <Typography variant="h6" id="modal-title" color={'secondary'}>
        Shipping Speed
      </Typography>
      <TextField
        id="standard-select"
        select
        label="Select"
        className={classes.textField}
        value={shippingOption}
        onChange={(e) => setShippingOption(e.target.value)}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        helperText="Please Select Shipping Speed"
        margin="normal"
      >
        {ShippingSpeedOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </DefaultDialog>
  );
};

export const POAttachmentSection = ({ orderUUID }) => {
  const { showError, showSuccess } = useSnackbar();

  const { data: order } = useGetOrderByUUIDQuery(orderUUID);

  const [updatePOAttachment] = useUpdatePOAttachmentMutation();

  const handleUpdatePOAttachment = (formData) => {
    updatePOAttachment(formData).then((response) => {
      if (response.error) {
        return showError(UPDATE_PO_ERROR_MESSAGE);
      }
      showSuccess(UPDATE_PO_SUCCESS_MESSAGE);
    });
  };

  const handlePODelete = () => {
    const postParams = {
      file: '',
      file_name: '',
      order_uuid: order.uuid,
      team_uuid: order.team.uuid,
    };
    const formData = new FormData();

    Object.keys(postParams).forEach((key) => {
      formData.append(key, postParams[key]);
    });

    handleUpdatePOAttachment(formData);
  };

  if (!order) return null;

  return (
    <Fragment>
      <POUploadAttachment
        handleUpdatePOAttachment={handleUpdatePOAttachment}
        label={'Click Here to Upload A New PO Document'}
        additionalContext={{
          order_uuid: order.uuid,
          team_uuid: order.team.uuid,
        }}
      />
      <POAttachment order={order} handlePODelete={handlePODelete} />
    </Fragment>
  );
};

export const QuoteAttachmentSection = () => {
  const order = useSelector(billingShippingSelectors.order);
  const dispatch = useDispatch();

  const setFieldValue = () => (value) => {
    dispatch(setOrder(value));
  };

  return (
    <Fragment>
      <QuoteUploadAttachment
        setFieldValue={setFieldValue}
        label={'Click Here to Upload A New Order Quote'}
        additionalContext={{
          order_uuid: order.uuid,
          team_uuid: order.team.uuid,
        }}
      />
      {!!order?.quote_attachment && <QuoteAttachment order={order} />}
    </Fragment>
  );
};
