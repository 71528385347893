import { viewSelectedSlides } from 'components/OrderDetails/Tabs/utilities';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconButton, Paper, Typography } from '@mui/material';
import { SLIDE_VIEWER_URL } from 'constants/urls';
import { downloadMultiSlideSource } from 'services/resources/slides';
import { AgGridReact } from 'ag-grid-react';
import { FormInput } from '../FormInputs/FormInput';
import { AppContext } from 'services/context';
import {
  QCSlideColumnDefs,
  ResetQCSlideColumnDefs,
} from 'components/utilities/AgGridCols/AgGridColumns';
import {
  QCViewerWarning,
  SingleQCSlideView,
} from 'components/QCViewer/QCViewerComponents';
import { useHistory } from 'react-router';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import GridLayout from 'react-grid-layout';
import Grid from '@mui/material/Grid';
import { QCPassFailModalHook } from 'components/Modals/QCPassFailModalHook';
import {
  SelectNextRow,
  agGridDefaultColDefFloatingFilter,
  selectFirstRow,
  selectGridSingleRow,
} from 'components/utilities/grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { QCBulkPassFailModal } from 'components/Modals/QCBulkPassFailModal';
import { QCBulkResetModal } from 'components/Modals/QCBulkResetModal';
import { slideDataSerializer } from 'components/utilities/gridDataSerializers';
import { getLisaSlideLink } from 'components/utilities/getLinks';
import { LockOpenRounded, LockRounded } from '@mui/icons-material';
import { QCToolbar } from 'components/QCViewer/QCToolbar';
import { TabPanel } from 'components/Shared/TabPanel';
import {
  DEFAULT_QC_SCORE,
  FETCHING_QC_SLIDES_ERROR_MESSAGE,
  FETCHING_RECENTLY_CHANGED_SLIDES_ERROR_MESSAGE,
  QC_BULK_REVIEW_GLP_SLIDE_SELECTED_ERROR_MESSAGE,
} from 'components/QCViewer/constants';
import { openMultiSlideParamsNewWindow } from 'components/ImageViewer/utilities';
import { MultiSlideViewItem } from 'components/ImageViewer/MultiSlideViewItem';
import { checkIfDateWithinNMinutes } from 'utilities/dates_and_times';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import {
  getLastChangedSlide,
  getSlideChangesDate,
  isAnySlideFromGLPOrder,
  isGLPSlide,
} from 'utilities/slides';
import { DEFAULT_OVERLAYS_NUMBER } from 'components/ImageViewer/constants';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { agGridStyle, useQCViewerStyles } from './styles';
import {
  useLazyGetNeedsQCSlideQuery,
  useLazyGetNeedsQCSlidesQuery,
  useLazyGetRecentlyChangedSlidesQuery,
} from 'store/apis/slidesApi';

const resizeHandles = ['nw', 'sw', 'se', 'ne'];

// this is just used by the grid-layout, it's an arbitrary meaning of how many pixels each height unit is
const rowHeight = 50;

const getLayout = (heightPixel) => {
  const calculatedHeight = Math.floor(heightPixel / rowHeight);

  // because we have 100vh, sometimes it's too much, so adjust it by 150 pixels
  const calculatedHeightOffset = calculatedHeight - 3;

  const layout = [
    { i: '0', x: 0, y: 0, w: 8, h: calculatedHeightOffset, resizeable: true },
    { i: '1', x: 8, y: 0, w: 4, h: calculatedHeightOffset, resizeable: true },
  ];

  return layout;
};

const rowSelection = 'multiple';

export const QCViewer = () => {
  const { classes } = useQCViewerStyles();
  const { showError, showWarning } = useSnackbar();

  const [slide, setSlide] = useState();
  const [isSlideLoading, setIsSlideLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  // we use this as a filter to not show qcReviewedSlides in the main view
  const [qcReviewedSlides, setQcReviewedSlides] = useState([]);
  const [recentlyChangedSlides, setRecentlyChangedSlides] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [showFloatingMacroImage, setShowFloatingMacroImage] = useState(false);
  const [isQCViewerModifying, setIsQCViewerModifying] = useState(false);
  const [userWhoChangedSlideLastTime, setUserWhoChangedSlideLastTime] =
    useState(null);

  const [thumbnail, setThumbnail] = useState('');
  const history = useHistory();
  const [gridApi, setGridApi] = useState(null);
  const ref = useRef(null);
  const [currentTab, setCurrentTab] = useState(0);

  const [draggable, setDraggable] = useState(false);
  const [showOverlay, setUseOverlay] = useState(true);
  const [scoreToDisplay, setScoreToDisplay] = useState(DEFAULT_QC_SCORE.value);
  const userDetails = useSelector(userDetailsSelector);

  const [getNeedsQCSlides] = useLazyGetNeedsQCSlidesQuery();
  const [getNeedsQCSlide] = useLazyGetNeedsQCSlideQuery();
  const [getRecentlyChangedSlides] = useLazyGetRecentlyChangedSlidesQuery();

  const handleOverlayChange = () => setUseOverlay((prevState) => !prevState);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);

    setSlide(null);
    setSelectedRows([]);
  };

  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState(null);

  const context = useContext(AppContext);

  const { handleContextModalChange } = context;

  const onRemoveSelected = ({ slide }) => {
    gridApi.applyTransaction({ remove: [slide] });
  };

  const onRemoveSelectedBulk = ({ slides }) => {
    gridApi.applyTransaction({ remove: slides });
  };

  const handleNextSlide = () => {
    // ie, for [slide1, slide2, slide3], the max location would be 2 because of 0 index
    const maxLocation = selectedRows.length - 1;
    const selectedRowsUUIDs = selectedRows.map((slide) => slide.uuid);
    const currentSelectedLocation = selectedRowsUUIDs.indexOf(slide.uuid);
    const nextLocation = currentSelectedLocation + 1;
    if (nextLocation > maxLocation) {
      return;
    } else {
      const newSelectedSlide = selectedRows[nextLocation];
      setSlide(newSelectedSlide);
    }
  };

  const handlePreviousSlide = () => {
    // ie, for [slide1, slide2, slide3], the max location would be 2 because of 0 index
    const minLocation = 0;
    const selectedRowsUUIDs = selectedRows.map((slide) => slide.uuid);

    const currentSelectedLocation = selectedRowsUUIDs.indexOf(slide.uuid);
    const nextLocation = currentSelectedLocation - 1;
    if (nextLocation < minLocation) {
      return;
    } else {
      const newSelectedSlide = selectedRows[nextLocation];
      setSlide(newSelectedSlide);
    }
  };

  const _updateSelectedRows = () => {
    if (gridApi) {
      const currentSelectedRows = gridApi.getSelectedRows();
      setSelectedRows(currentSelectedRows);
    }
  };

  const updateSelectedRows = useCallback(_updateSelectedRows, [gridApi]);

  const _updateRecentlyChangedSlides = () => {
    getRecentlyChangedSlides()
      .unwrap()
      .then((response) => {
        const rows = response.map(slideDataSerializer);
        setRecentlyChangedSlides(rows);
      })
      .catch(() => showError(FETCHING_RECENTLY_CHANGED_SLIDES_ERROR_MESSAGE));
  };

  const updateRecentlyChangedSlides = useCallback(
    _updateRecentlyChangedSlides,
    [],
  );

  useEffect(() => {
    if (!recentlyChangedSlides.length) return;

    const lastChangedSlide = recentlyChangedSlides.reduce(getLastChangedSlide);
    const lastChangedSlideDate = getSlideChangesDate(lastChangedSlide);
    const userWhoChangedSlide =
      lastChangedSlide?.failed_qc_user || lastChangedSlide?.passed_qc_user;

    if (!lastChangedSlideDate || !userWhoChangedSlide) return;

    setUserWhoChangedSlideLastTime(userWhoChangedSlide);
    const isCurrentUserChangedSlide =
      userDetails &&
      userWhoChangedSlide.email?.localeCompare(userDetails.email) === 0;
    const wasSlideChangedInLast5Minutes = checkIfDateWithinNMinutes(
      lastChangedSlideDate,
      5,
    );

    if (
      !isCurrentUserChangedSlide &&
      wasSlideChangedInLast5Minutes &&
      userWhoChangedSlide
    ) {
      if (!isQCViewerModifying) {
        showWarning(
          userWhoChangedSlide &&
            userWhoChangedSlide.first_name.length &&
            userWhoChangedSlide.last_name.length
            ? `${userWhoChangedSlide.first_name} ${userWhoChangedSlide.last_name} is currently doing QC`
            : 'Somebody is currently doing QC',
          '',
          { autoHideDuration: 60000 },
        );
      }
      setIsQCViewerModifying(true);
    } else {
      setIsQCViewerModifying(false);
    }
  }, [recentlyChangedSlides]);

  useEffect(() => {
    const refetchInterval = 5 * 60 * 1000;
    let interval = setInterval(() => {
      updateRecentlyChangedSlides();
    }, refetchInterval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // if gridAPI not loaded yet, don't run
    if (!gridApi) {
      return;
    }

    const updateSlides = () => {
      getNeedsQCSlides()
        .unwrap()
        .then((response) => {
          const rows = response.map(slideDataSerializer);
          setRowData(rows);
        })
        .catch(() => showError(FETCHING_QC_SLIDES_ERROR_MESSAGE));
    };
    updateSlides();
    updateRecentlyChangedSlides();
  }, [gridApi, updateSelectedRows, updateRecentlyChangedSlides]);

  const handleQCReviewed = ({ slide }) => {
    const updatedSlides = [...qcReviewedSlides, slide];
    setQcReviewedSlides(updatedSlides);

    onRemoveSelected({ slide });
  };

  const handleQCReviewedBulk = ({ slides }) => {
    const updatedSlides = [...qcReviewedSlides, ...slides];
    setQcReviewedSlides(updatedSlides);

    onRemoveSelectedBulk({ slides });
  };

  const getQCViewerContextMenuItems = ({ node, api }) => {
    // if there is no data, dont show any context, otherwise errors
    if (!node) {
      return [];
    }

    const row = node.data;
    const lisaSlideLink = getLisaSlideLink({ slide: row });
    const qcModalOnChange = handleContextModalChange('modalQCPassFailOpen');
    const qcBulkModalOnChange = handleContextModalChange(
      'modalBulkQCPassFailOpen',
    );

    const handleBulkQCReview = () => {
      if (!selectedRows.length) {
        return;
      }

      const isAnyGLPSlideSelected = isAnySlideFromGLPOrder(selectedRows);

      if (isAnyGLPSlideSelected) {
        showError(QC_BULK_REVIEW_GLP_SLIDE_SELECTED_ERROR_MESSAGE);
        return;
      }

      qcBulkModalOnChange();
    };

    return [
      {
        name: 'Review QC',
        action: () => {
          if (row) {
            fetchFullSlide(row, api);
            qcModalOnChange();
          }
        },
      },
      {
        name: 'Review QC (BULK)',
        action: handleBulkQCReview,
      },
      {
        name: 'View Slide',
        action: () => {
          if (row) {
            const url = SLIDE_VIEWER_URL.replace(':slideUUID', row.uuid);
            history.push(url);
          }
        },
      },
      {
        name: 'View Slide (LISA)',
        action: () => {
          if (row) {
            window.open(lisaSlideLink, '_blank');
          }
        },
      },
      {
        name: 'Compare Selected Slides (New Tab)',
        action: () => {
          if (selectedRows) {
            openMultiSlideParamsNewWindow({ rows: selectedRows });
          }
        },
      },
      {
        name: `View ${selectedRows?.length} Selected Slides (New Tab)`,
        disabled: !selectedRows,
        action: () => viewSelectedSlides({ selectedRows }),
      },
      {
        name: 'Download Slides',
        disabled: !selectedRows,
        action: () => {
          downloadMultiSlideSource({ slides: selectedRows });
        },
      },
    ];
  };

  const getBulkUndoContextMenuItems = (params) => {
    // if there is no data, dont show any context, otherwise errors
    if (!params.node) {
      return [];
    }

    const row = params.node.data;
    const lisaSlideLink = getLisaSlideLink({ slide: row });
    const qcBulkModalResetChange = handleContextModalChange(
      'modalBulkResetQCOpen',
    );

    return [
      {
        name: 'Reset QC',
        action: () => {
          if (row) {
            qcBulkModalResetChange();
          }
        },
      },
      {
        name: 'View Slide (New Tab)',
        action: () => {
          if (row) {
            const url = SLIDE_VIEWER_URL.replace(':slideUUID', row.uuid);
            window.open(url, '_blank');
          }
        },
      },
      {
        name: 'View Slide (LISA)',
        action: () => {
          if (row) {
            window.open(lisaSlideLink, '_blank');
          }
        },
      },
      {
        name: 'Compare Selected Slides (New Tab)',
        action: () => {
          if (selectedRows) {
            openMultiSlideParamsNewWindow({ rows: selectedRows });
          }
        },
      },
      {
        name: `View ${selectedRows?.length} Selected Slides (New Tab)`,
        disabled: !selectedRows,
        action: () => viewSelectedSlides({ selectedRows }),
      },
      {
        name: 'Download Slides',
        disabled: !selectedRows,
        action: () => {
          downloadMultiSlideSource({ slides: selectedRows });
        },
      },
    ];
  };

  const handleFloatingThumbnailChange = () => {
    setShowFloatingMacroImage(!showFloatingMacroImage);
  };

  const onQuickFilterText = (event) => {
    setQuickFilterText(event.target.value);
  };

  const isSlideFullInfoNeeded = (slide) => {
    const isFullInfoNeeded = !slide.additional_data;
    return isFullInfoNeeded;
  };

  const handleRowDeselect = (slide) => {
    setSelectedRows(
      (selectedSlides) =>
        selectedSlides?.filter(
          (selectedSlide) => selectedSlide.uuid !== slide.uuid,
        ) || [],
    );
  };

  const fetchFullSlide = (data, api) => {
    if (!data) return;

    const { uuid } = data;
    setIsSlideLoading(true);

    const slidePromise = isSlideFullInfoNeeded(data)
      ? getNeedsQCSlide({ uuid }).unwrap()
      : Promise.resolve(data);

    return slidePromise
      .then((slide) => {
        const updatedSlide = {
          ...slide,
          order: { ...slide.order, ...data.order },
          stain: slide.stain || data.stain,
        };
        if (api && isSlideFullInfoNeeded(data)) {
          api.applyTransaction({
            update: [updatedSlide],
          });
        }

        updateSelectedRows();
        setSlide(updatedSlide);
        setIsSlideLoading(false);

        const thumbnail = slide.large_macro_url || '';
        setThumbnail(thumbnail);
      })
      .catch(() => showError(FETCHING_QC_SLIDES_ERROR_MESSAGE));
  };

  const onRowSelected = ({ node, data, api }) => {
    if (!node.selected) {
      handleRowDeselect(data);
      return;
    }

    fetchFullSlide(data, api);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const renderQCPassModal = () => {
    if (!slide) {
      return null;
    }

    if (!context.modalQCPassFailOpen) {
      return null;
    }

    return (
      <Fragment>
        <QCPassFailModalHook
          isSlideLoading={isSlideLoading}
          slide={slide}
          handleQCReviewed={handleQCReviewed}
          key={slide.uuid}
          handleNextSlide={handleNextSlide}
          handlePreviousSlide={handlePreviousSlide}
          selectedRows={selectedRows}
          getNextRow={getNextRow}
        />
      </Fragment>
    );
  };

  const renderBulkQCPassModal = () => {
    if (!selectedRows) {
      return null;
    }

    if (!context.modalBulkQCPassFailOpen) {
      return null;
    }

    return (
      <Fragment>
        <QCBulkPassFailModal
          selectedRows={selectedRows}
          handleQCReviewed={handleQCReviewedBulk}
        />
      </Fragment>
    );
  };

  const renderBulkQCResetModal = () => {
    if (!selectedRows) {
      return null;
    }

    if (!context.modalBulkResetQCOpen) {
      return null;
    }

    return (
      <Fragment>
        <QCBulkResetModal
          selectedRows={selectedRows}
          handleQCReviewed={handleQCReviewedBulk}
          updateRecentlyChangedSlides={updateRecentlyChangedSlides}
        />
      </Fragment>
    );
  };

  const renderFloatingThumbnail = () => {
    if (!thumbnail) {
      return null;
    }

    if (!showFloatingMacroImage) {
      return null;
    }

    return (
      <div className={classes.floatingThumbnail}>
        <Paper className={classes.floatingPaper} elevation={1}>
          <img
            className={classes.floatingImage}
            src={thumbnail}
            alt={'Thumbnail'}
          />
        </Paper>
      </div>
    );
  };

  const openQCModal = (params) => {
    const { data } = params.node;
    const qcModalOnChange = handleContextModalChange('modalQCPassFailOpen');
    // if you group by something, you aren't able to navigate to the slide viewer
    if (data && data.uuid) {
      qcModalOnChange();
    }
  };

  const getRowNodeId = ({ data }) => {
    return data.uuid;
  };

  const displayedRowCount = gridApi ? gridApi.getDisplayedRowCount() : null;

  const handleFirstDataRendered = ({ api }) => {
    selectFirstRow(api);
  };

  // cells are not being rerendered without directly calling .refreshCells()
  // it's needed to make custom ag-grid valueGetters work properly with grouped rows
  const handleColumnGroupOpened = ({ api }) => {
    api.refreshCells();
  };

  const handleNavigateToNextCell = ({ api, nextCellPosition }) => {
    selectGridSingleRow(
      api,
      ({ rowIndex }) => rowIndex === nextCellPosition?.rowIndex,
    );
    return nextCellPosition;
  };

  const renderSlidesList = () => {
    return (
      <Fragment>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabsChange}
        >
          <Tab label="Needs QC" />
          <Tab label="Recently Changed" />
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <FormInput
            onChange={onQuickFilterText}
            placeholder="Filter text ..."
          />
          <Typography align={'left'}>
            Showing {displayedRowCount} of {rowData.length} Slides QC
          </Typography>
          <div className="ag-theme-balham" style={agGridStyle}>
            <AgGridReact
              enableSorting={true}
              enableFilter={true}
              quickFilterText={quickFilterText}
              enableColResize={true}
              rowDragManaged={true}
              rowGroupPanelShow={'always'}
              rowData={rowData}
              defaultColDef={agGridDefaultColDefFloatingFilter}
              columnDefs={QCSlideColumnDefs}
              rowSelection={rowSelection}
              onGridReady={onGridReady}
              onColumnGroupOpened={handleColumnGroupOpened}
              onFirstDataRendered={handleFirstDataRendered}
              onCellDoubleClicked={openQCModal}
              onRowSelected={onRowSelected}
              getContextMenuItems={getQCViewerContextMenuItems}
              getRowId={getRowNodeId}
              navigateToNextCell={handleNavigateToNextCell}
            />
          </div>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <div className="ag-theme-balham" style={agGridStyle}>
            <AgGridReact
              enableSorting={true}
              enableFilter={true}
              enableColResize={true}
              rowDragManaged={true}
              rowGroupPanelShow={'always'}
              rowData={recentlyChangedSlides}
              defaultColDef={agGridDefaultColDefFloatingFilter}
              columnDefs={ResetQCSlideColumnDefs}
              rowSelection={rowSelection}
              onGridReady={onGridReady}
              onFirstDataRendered={handleFirstDataRendered}
              onRowSelected={onRowSelected}
              getContextMenuItems={getBulkUndoContextMenuItems}
              getRowId={getRowNodeId}
            />
          </div>
        </TabPanel>
      </Fragment>
    );
  };

  const reviewedSlideUUIDs = qcReviewedSlides.map((slide) => {
    return slide.uuid;
  });

  const selectedRowsNotReviewed = selectedRows
    ? selectedRows.filter((row) => {
        return !reviewedSlideUUIDs.includes(row.uuid);
      })
    : [];

  const useMultiSlide = selectedRowsNotReviewed.length > 1;

  const currentHeight = ref.current ? ref.current.offsetHeight : 800;
  const layout = getLayout(currentHeight);

  const getNextRow = () => {
    if (useMultiSlide) {
      return;
    }

    SelectNextRow(gridApi);
  };

  const [overlaysNumber, setOverlaysNumber] = useState(DEFAULT_OVERLAYS_NUMBER);
  const handleOverlaysNumberChange = (e, value) => {
    setOverlaysNumber(value);
  };

  const renderMultiSlideSection = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {selectedRowsNotReviewed.map((row) => (
          <MultiSlideViewItem
            slide={row}
            showOverlay={!isGLPSlide(row) && showOverlay}
            key={row.uuid}
            scoreToDisplay={scoreToDisplay}
            isAttachmentsViewerFeatureEnabled
            isFavoriteSlideFeatureEnabled={false}
            isQCViewer={true}
          />
        ))}
      </Grid>
    );
  };

  const handleDragLockClick = () => {
    setDraggable((prevState) => !prevState);
  };

  const handleDisplayedScoresChange = (scoreName) => {
    setScoreToDisplay(scoreName);
  };

  return (
    <div className={classes.root}>
      <Container ref={ref} maxWidth={false} disableGutters={true}>
        <Grid container direction="row">
          <Grid container item direction="column" className={classes.lockGrid}>
            <Grid item>
              <IconButton
                title="Drag'n'Drop Lock"
                size="small"
                className={classes.lockButton}
                color="secondary"
                onClick={handleDragLockClick}
              >
                {draggable ? <LockOpenRounded /> : <LockRounded />}
              </IconButton>
              {isQCViewerModifying && (
                <QCViewerWarning user={userWhoChangedSlideLastTime} />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <GridLayout
            className="layout"
            layout={layout}
            resizeHandles={resizeHandles}
            cols={12}
            rowHeight={rowHeight}
            draggableCancel={'.ag-theme-balham'}
            width={ref.current ? ref.current.offsetWidth : 800}
            isDraggable={draggable}
          >
            <div key={0}>
              {/*40px is approximate slide name height*/}
              <Box p={2} height={'calc(100% - 40px)'}>
                {useMultiSlide
                  ? renderMultiSlideSection()
                  : selectedRowsNotReviewed.map((row) => (
                      <SingleQCSlideView
                        slide={row}
                        showOverlay={!isGLPSlide(row) && showOverlay}
                        key={row.uuid}
                        overlaysNumber={overlaysNumber}
                        scoreToDisplay={scoreToDisplay}
                        isAttachmentsViewerFeatureEnabled
                        isQCViewer={true}
                      />
                    ))}
              </Box>
            </div>
            <div key={1}>
              <Box p={2} height={'calc(100% - 40px)'}>
                <QCToolbar
                  showOverlay={!isGLPSlide(slide) && showOverlay}
                  handleOverlayChange={handleOverlayChange}
                  overlaysNumber={overlaysNumber}
                  handleOverlaysNumberChange={handleOverlaysNumberChange}
                  handleDisplayFloatingThumbnailChange={
                    handleFloatingThumbnailChange
                  }
                  showFloatingMacroImage={showFloatingMacroImage}
                  handleDisplayedScoreChange={handleDisplayedScoresChange}
                />
                {renderSlidesList()}
              </Box>
            </div>
          </GridLayout>
        </Grid>
      </Container>
      {renderQCPassModal()}
      {renderFloatingThumbnail()}
      {renderBulkQCPassModal()}
      {renderBulkQCResetModal()}
    </div>
  );
};
