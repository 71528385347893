import { useDispatch, useSelector } from 'react-redux';
import {
  currentSearchQuerySelector,
  setCurrentSearchQuery,
} from 'store/slices/pathologyMapSlice';
import { Button, Input } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useCurrentPathologyMapManager } from 'components/PathologyMap/utilities/hooks';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import Box from '@mui/material/Box';
import { DefaultPathologyMapTypography } from 'components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography';
import { PATHOLOGY_MAP_SEARCH_BUTTON_TYPOGRAPHY_PROPS } from 'components/PathologyMap/constants/styles';
import { KEYS } from 'constants/keyboard';
import { usePathologyMapSearchBarStyles } from './styles';

export const PathologyMapSearchBar = () => {
  const { refine } = useSearchBox();

  const { classes } = usePathologyMapSearchBarStyles();
  const dispatch = useDispatch();

  const currentSearchQuery = useSelector(currentSearchQuerySelector);
  const { searchPlaceholder } = useCurrentPathologyMapManager();

  const handleSearchClick = () => {
    refine(currentSearchQuery);
  };

  const handleInputChange = (e) => {
    dispatch(setCurrentSearchQuery(e.target.value));
  };

  const handleInputKeyDown = (e) => {
    if (e.key === KEYS.ENTER) {
      handleSearchClick();
    }
  };

  return (
    <Box>
      <Input
        fullWidth
        disableUnderline
        className={classes.searchBarContainer}
        placeholder={searchPlaceholder}
        value={currentSearchQuery}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        startAdornment={<Search className={classes.searchIcon} />}
        endAdornment={
          <Button className={classes.searchButton} onClick={handleSearchClick}>
            <DefaultPathologyMapTypography
              {...PATHOLOGY_MAP_SEARCH_BUTTON_TYPOGRAPHY_PROPS}
            >
              Search
            </DefaultPathologyMapTypography>
          </Button>
        }
      />
    </Box>
  );
};
