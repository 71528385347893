import { FavoriteCell } from 'components/utilities/FavoriteSlide/FavoriteCell';
import { FloatingFilterWithReset } from 'components/OrderSlidesList/FloatingFilterWithReset';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const slideViewerKey = 'SLIDE_VIEWER_KEY';
export const slideMacroView = 'SLIDE_MACRO_KEY';
export const slidesListKey = 'SLIDES_LIST_KEY';
export const slideCommentsKey = 'SLIDE_COMMENTS_KEY';

export const SLIDES_LIST_CONTAINER_STYLES = {
  height: '100%',
  width: '100%',
};

export const ORDER_SLIDES_AG_GRID_COMPONENTS = {
  favoriteCell: FavoriteCell,
};

export const SLIDE_ID_KEY = 'slideId';
export const X_KEY = 'x';
export const Y_KEY = 'y';
export const ZOOM_KEY = 'zoom';

export const bookMarkParamsList = [ZOOM_KEY, X_KEY, Y_KEY, SLIDE_ID_KEY];

export const AG_GRID_TEXT_COLUMN_FILTER = 'agTextColumnFilter';

export const expandedFiltersWithReset = {
  [AG_GRID_TEXT_COLUMN_FILTER]: FloatingFilterWithReset,
};

export const SHORTCUTS = [
  {
    title: 'Move (Pan)',
    keyIcon: <Typography fontWeight={600}>W, A, S, D</Typography>,
  },
  {
    title: 'Jump To Next Tile',
    keyIcon: <Typography fontWeight={600}>Ctrl + ↑, ↓, ←, →</Typography>,
  },
  {
    title: 'Zoom',
    keyIcon: <Typography fontWeight={600}>+, -</Typography>,
  },
  {
    title: 'Prev Slide',
    keyIcon: <ArrowBackIcon />,
  },
  {
    title: 'Next Slide',
    keyIcon: <ArrowForwardIcon />,
  },
  {
    title: 'Rotate Clockwise',
    keyIcon: <Typography fontWeight={600}>E</Typography>,
  },
  {
    title: 'Rotate Counterclockwise',
    keyIcon: <Typography fontWeight={600}>Q</Typography>,
  },
  {
    title: 'Screenshot',
    keyIcon: <Typography fontWeight={600}>P</Typography>,
  },
  {
    title: 'Full Screen',
    keyIcon: <Typography fontWeight={600}>F</Typography>,
  },
  {
    title: 'Exit Full-Screen Mode',
    keyIcon: <Typography fontWeight={600}>Esc</Typography>,
  },
];
