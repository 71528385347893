import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { SvgIcon } from '@mui/material';
import {
  BarChart as BarChartIcon,
  Biotech as BiotechIcon,
} from '@mui/icons-material';
import {
  DETAILS_VIEW_MODE,
  VIEWER_VIEW_MODE,
} from 'components/AntibodiesList/constants';

export const ToggleButtons = ({ handleToggle, classes, selected }) => {
  const isDetailsMode = selected === DETAILS_VIEW_MODE;

  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleToggle}
      aria-label="text alignment"
      className={classes.tabs}
    >
      <ToggleButton
        value={DETAILS_VIEW_MODE}
        className={classes.toggleButton}
        selected={isDetailsMode}
        disabled={isDetailsMode}
      >
        <SvgIcon component={BarChartIcon} className={classes.icon} />
        Details
      </ToggleButton>
      <ToggleButton
        value={VIEWER_VIEW_MODE}
        className={classes.toggleButton}
        selected={!isDetailsMode}
        disabled={!isDetailsMode}
      >
        <SvgIcon component={BiotechIcon} className={classes.icon} />
        Viewer
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
