import { APP_LIBRARY } from 'constants/urls';
import { object, string } from 'yup';
import airaLogo from 'images/AITools/aira.png';
import aiosynLogo from 'images/AITools/aiosyn.png';
import { ORGAN_NAMES } from 'constants/common';

export const QC_AI_MODELS = {
  ML_QC_BLURRY_DATA: 'ml_qc_blurry_data',
  ML_QC_FOLDS_TEARS_DATA: 'ml_qc_folds_data',
  ML_QC_BLADE_MARKS_DATA: 'ml_qc_blade_marks_data',
  ML_QC_DIRTY_DATA: 'ml_qc_dirty_data',
  ML_QC_STAIN_DATA: 'ml_qc_stain_data',
  ML_QC_COVERSLIP_DATA: 'ml_qc_coverslip_data',
  ML_QC_TISSUE_SEPARATION_DATA: 'ml_qc_tissue_separation_data',
  ML_QC_MICROVIBRATIONS_DATA: 'ml_qc_microvibrations_data',
  ML_QC_TISSUE_CRACKING_DATA: 'ml_qc_tissue_cracking_data',
  ML_QC_FLOATERS_DATA: 'ml_qc_floaters_data',
  ML_QC_CUT_DEEPER_DATA: 'ml_qc_cut_deeper_data',
  ML_QC_SCANNER_ARTIFACTS_DATA: 'ml_qc_scanner_artifacts_data',
};

export const AIRA_LIVER_AI_MODELS = {
  ML_BILE_DUCT_DATA: 'ml_aira_liver_bile_duct_data',
  ML_EMH_DATA: 'ml_aira_liver_emh_data',
  ML_MICROGRANULOMA_DATA: 'ml_aira_liver_microgranuloma_data',
  ML_SINGLE_CELL_NECROSIS_DATA: 'ml_aira_liver_single_cell_necrosis_data',
  ML_VACUOLATION_DATA: 'ml_aira_liver_vacuolation_data',
  ML_NECROSIS_DATA: 'ml_aira_liver_necrosis_data',
  ML_PMNC_INFILTRATION_DATA: 'ml_aira_liver_pmnc_infiltration_data',
};

export const AIOSYN_KIDNEY_AI_MODELS = {
  ML_GLOMERULI_DATA: 'ml_aiosyn_kidney_glomeruli_data',
  ML_DILATED_GLOMERULI_DATA: 'ml_aiosyn_kidney_dilated_glomeruli_data',
  ML_ATROPHIC_TUBULI_DATA: 'ml_aiosyn_kidney_atrophic_tubuli_data',
  ML_NORMAL_TUBULI_DATA: 'ml_aiosyn_kidney_normal_tubuli_data',
};

export const TISSUE_AI_MODELS = {
  ML_TUMOR_DATA: 'ml_tumor_data',
  ML_STROMA_DATA: 'ml_stroma_data',
  ML_INFLAMMATION_DATA: 'ml_inflammation_data',
  ML_NECROSIS_DATA: 'ml_necrosis_data',
};

export const TISSUE_SEGMENTATION_MODELS = {
  ML_TISSUE_DATA: 'ml_tissue_data',
};

export const TISSUE_MODELS_ITEM_MENU_OPTIONS = [
  {
    name: 'Tumor',
    color: 'rgba(255, 0, 0, 0.35)',
    value: TISSUE_AI_MODELS.ML_TUMOR_DATA,
  },
  {
    name: 'Stroma',
    color: 'rgba(152, 245, 90, 0.35)',
    value: TISSUE_AI_MODELS.ML_STROMA_DATA,
  },
  {
    name: 'Inflammation',
    color: 'rgba(245, 90, 201, 0.35)',
    value: TISSUE_AI_MODELS.ML_INFLAMMATION_DATA,
  },
  {
    name: 'Tissue',
    color: 'rgba(0, 0, 255, 0.35)',
    value: TISSUE_AI_MODELS.ML_TISSUE_DATA,
  },
  {
    name: 'Necrosis',
    color: 'rgba(255, 213, 0, 0.35)',
    value: TISSUE_AI_MODELS.ML_NECROSIS_DATA,
  },
];

export const AI_MODELS = {
  aira: 'aira_matrix',
  aiosyn: 'aiosyn',
  autoQC: 'auto-qc',
  tissueSegmentation: 'tissue-segmentation',
  semanticSegmentation: 'semantic-segmentation',
};

export const MAP_MODEL_ID_TO_SPECIFIC_ORGAN = {
  [AI_MODELS.aira]: ORGAN_NAMES.LIVER,
  [AI_MODELS.aiosyn]: ORGAN_NAMES.KIDNEY,
};

export const AI_MODELS_ITEMS_MENU_OPTIONS = [
  {
    id: AI_MODELS.aira,
    logo: airaLogo,
    title: 'AIRA MATRIX LIVER ANALYSIS ',
    detailsUrl: `${APP_LIBRARY}/aira_matrix`,
    options: [
      {
        name: 'Bile Duct',
        value: AIRA_LIVER_AI_MODELS.ML_BILE_DUCT_DATA,
      },
      {
        name: 'EMH',
        value: AIRA_LIVER_AI_MODELS.ML_EMH_DATA,
      },
      {
        name: 'Microgranuloma',
        value: AIRA_LIVER_AI_MODELS.ML_MICROGRANULOMA_DATA,
      },
      {
        name: 'Single Cell Necrosis',
        value: AIRA_LIVER_AI_MODELS.ML_SINGLE_CELL_NECROSIS_DATA,
      },
      {
        name: 'Vacuolation',
        value: AIRA_LIVER_AI_MODELS.ML_VACUOLATION_DATA,
      },
      {
        name: 'PMNC Infiltration',
        value: AIRA_LIVER_AI_MODELS.ML_PMNC_INFILTRATION_DATA,
      },
      {
        name: 'Necrosis',
        value: AIRA_LIVER_AI_MODELS.ML_NECROSIS_DATA,
      },
    ],
  },
  {
    id: AI_MODELS.aiosyn,
    logo: aiosynLogo,
    title: 'AIOSYN PRECLINICAL KIDNEY ANALYSIS',
    detailsUrl: `${APP_LIBRARY}/aiosyn`,
    options: [
      {
        name: 'Glomeruli',
        value: AIOSYN_KIDNEY_AI_MODELS.ML_GLOMERULI_DATA,
      },
      {
        name: 'Dilated Glomeruli',
        value: AIOSYN_KIDNEY_AI_MODELS.ML_DILATED_GLOMERULI_DATA,
      },
      {
        name: 'Atrophic Tubuli',
        value: AIOSYN_KIDNEY_AI_MODELS.ML_ATROPHIC_TUBULI_DATA,
      },
      {
        name: 'Normal Tubuli',
        value: AIOSYN_KIDNEY_AI_MODELS.ML_NORMAL_TUBULI_DATA,
      },
    ],
  },
  {
    id: AI_MODELS.autoQC,
    title: 'HISTOWIZ AUTO-QC TOOL',
    detailsUrl: `${APP_LIBRARY}/auto-qc`,
    options: [
      { name: 'Out-of-focus', value: QC_AI_MODELS.ML_QC_BLURRY_DATA },
      {
        name: 'Tissue Seperation',
        value: QC_AI_MODELS.ML_QC_TISSUE_SEPARATION_DATA,
      },
      { name: 'Folds', value: QC_AI_MODELS.ML_QC_FOLDS_TEARS_DATA },
      { name: 'Coverslip', value: QC_AI_MODELS.ML_QC_COVERSLIP_DATA },
      {
        name: 'Microvibrations',
        value: QC_AI_MODELS.ML_QC_MICROVIBRATIONS_DATA,
      },
      { name: 'Dirty Slide', value: QC_AI_MODELS.ML_QC_DIRTY_DATA },
      {
        name: 'Tissue Cracking',
        value: QC_AI_MODELS.ML_QC_TISSUE_CRACKING_DATA,
      },
    ],
  },
  {
    id: AI_MODELS.tissueSegmentation,
    title: 'HISTOWIZ TISSUE SEGMENTATION TOOL',
    detailsUrl: `${APP_LIBRARY}/tissue-segmentation`,
    options: [
      {
        name: 'Tissue Segmentation',
        value: TISSUE_SEGMENTATION_MODELS.ML_TISSUE_DATA,
      },
    ],
  },
  {
    id: AI_MODELS.semanticSegmentation,
    title: 'HISTOWIZ SEMANTIC SEGMENTATION TOOL',
    detailsUrl: `${APP_LIBRARY}/semantic-segmentation`,
    options: [
      {
        name: 'Tumor',
        value: TISSUE_AI_MODELS.ML_TUMOR_DATA,
      },
      {
        name: 'Stroma',
        value: TISSUE_AI_MODELS.ML_STROMA_DATA,
      },
      {
        name: 'Inflammation',
        value: TISSUE_AI_MODELS.ML_INFLAMMATION_DATA,
      },
      {
        name: 'Necrosis',
        value: TISSUE_AI_MODELS.ML_NECROSIS_DATA,
      },
    ],
  },
];

export const QC_MODELS_ITEM_MENU_OPTIONS = [
  { name: 'Out-of-focus', value: QC_AI_MODELS.ML_QC_BLURRY_DATA },
  {
    name: 'Tissue Seperation',
    value: QC_AI_MODELS.ML_QC_TISSUE_SEPARATION_DATA,
  },
  { name: 'Folds', value: QC_AI_MODELS.ML_QC_FOLDS_TEARS_DATA },
  { name: 'Coverslip', value: QC_AI_MODELS.ML_QC_COVERSLIP_DATA },
  { name: 'Microvibrations', value: QC_AI_MODELS.ML_QC_MICROVIBRATIONS_DATA },
  { name: 'Dirty Slide', value: QC_AI_MODELS.ML_QC_DIRTY_DATA },
  { name: 'Tissue Cracking', value: QC_AI_MODELS.ML_QC_TISSUE_CRACKING_DATA },
];

export const DEFAULT_AIRA_MODELS_OPACITY = 55;
export const DEFAULT_AIOSYN_MODELS_OPACITY = 55;
export const DEFAULT_QC_MODELS_OPACITY = 100;
export const DEFAULT_TISSUE_MODELS_OPACITY = 55;
export const DEFAULT_SEGMENTATION_MODELS_OPACITY = 55;

export const AI_MODEL_CHANGE_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
};

export const AI_MODEL_EVENTS = {
  OPACITY_CHANGE: 'modelOpacityChange',
};

export const SHAPE_DRAW_TYPES = {
  FILL: 'fill',
};

export const QC_SCORE_COLORS = {
  DEFAULT: 'default',
  FAILED: 'failed',
  PASSED: 'passed',
};

export const AI_MODELS_STATES = {
  NA: 'n/a',
  REQUESTED: 'requested',
  FAILED: 'failed',
  NOT_FOUND: 'not_found',
  EXISTS: 'exists',
};

export const AI_PROCESS_STATES = {
  COMPLETED: 'completed',
  IN_PROCESS: 'in_progress',
};

const stringRequirements = (field) => string().label(field).trim().required();

export const reportIssueSchema = object().shape({
  type: object().required().label('Type of issue'),
  description: stringRequirements('Description'),
});

export const reportIssueTypes = [
  { value: 'Didn’t Approve Purchase', label: 'Didn’t Approve Purchase' },
  { value: 'Clicked by Mistake', label: 'Clicked by Mistake' },
  {
    value: 'Inaccurate Model Description',
    label: 'Inaccurate Model Description',
  },
  { value: 'Inaccurate Result', label: 'Inaccurate Result' },
  { value: 'Missing Some Results', label: 'Missing Some Results' },
  { value: 'Hallucination', label: 'Hallucination' },
  { value: 'Other', label: 'Other (Please Describe)' },
];
