import { makeStyles } from 'tss-react/mui';
import {
  BLACK_ICON,
  BORDER_GEY_2,
  BUTTON_GREY,
  DARK_GREY_2,
  HISTOWIZ_PRIMARY_COLOR,
  PRIMARY_PINK,
  SECONDARY_BLUE,
  SECONDARY_BLUE_2,
  SECONDARY_BLUE_2_TRANSPARENT,
  TRANSPARENT_BLACK_15,
  WHITE,
  WHITE_SMOKE_2,
} from 'utilities/colors';
import {
  HORIZONTAL_SCROLL_STYLE,
  VERTICAL_SCROLL_STYLE,
} from 'themes/defaultMuiTheme';
import {
  GREEN_ICON_COLOR,
  GREEN_TRANSPARENT_COLOR,
  PURPLE_ICON_COLOR,
  PURPLE_TRANSPARENT_COLOR,
  RED_ICON_COLOR,
  RED_TRANSPARENT_COLOR,
} from 'components/TeamOverview/CardsMenuSection/CardsMenuSection';

export const useOrderTrackingCardStyles = makeStyles()(() => ({
  card: {
    height: '100%',
  },
}));

export const useTooltipWithImageStyles = makeStyles()(() => ({
  spinnerContainer: {
    width: '700px',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    marginBottom: '0.25rem',
  },
  tooltip: {
    maxWidth: '1200px',
    padding: '0.5rem',
  },
  content: {
    width: '700px',
    display: 'block',
  },
  invisibleContent: {
    display: 'none',
  },
}));

const _OrderTypesTabsStyles = () => ({
  tab: {
    textTransform: 'uppercase',
    minWidth: 'min-content',
    padding: '0.25rem 0.5rem',
    marginInline: '0.5rem',
    height: '2.3rem',
    minHeight: '2.3rem',
    fontWeight: 500,
    opacity: 1,
    border: '1px solid transparent',
    '&:hover': {
      color: SECONDARY_BLUE_2,
      border: `1px solid ${SECONDARY_BLUE_2}`,
      borderRadius: '4px',
    },
  },
  tabs: {
    height: '2.3rem',
    minHeight: '2.3rem',
    marginLeft: '1rem',
  },
  indicator: {
    display: 'none',
  },
  selectedTab: {
    backgroundColor: SECONDARY_BLUE_2,
    color: 'white !important',
    borderRadius: '4px',
  },
});

export const useOrderTypesTabsStyles = makeStyles()(_OrderTypesTabsStyles);

const _OrdersTabContentStyles = (theme) => ({
  uploadButtonsContainer: {
    display: 'flex',
    minWidth: '12rem',
  },
  ordersList: {
    height: '645px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...VERTICAL_SCROLL_STYLE,

    [theme.breakpoints.down('lg')]: {
      height: 'auto',
    },
  },
  withPointer: {
    cursor: 'pointer',
  },
  slideInfoRow: {
    padding: '0.5rem 1rem 0.5rem 2rem',
  },
  spinnerContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  commentText: {
    width: '100%',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
  },
  cellWithLink: {
    color: SECONDARY_BLUE,
    cursor: 'pointer',
  },
  commentContainer: {
    maxWidth: '100000px',
  },
  selectedRow: {
    backgroundColor: 'rgba(212, 226, 240, 1) !important',
    border: `1px solid ${SECONDARY_BLUE_2} !important`,
  },
  teamMembersContainer: {
    maxHeight: '74vh',
    overflowY: 'scroll',
    ...VERTICAL_SCROLL_STYLE,
  },
  bodyColumn: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    ...HORIZONTAL_SCROLL_STYLE,
  },
  changeLogButton: {
    backgroundColor: HISTOWIZ_PRIMARY_COLOR,
    borderRadius: '4px',
    height: '2.3rem',
    padding: '0 !important',
    minWidth: '2.5rem',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: PRIMARY_PINK,
      color: WHITE,
      '> svg': {
        color: WHITE,
      },
    },
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  backLabel: {
    lineHeight: '0.875rem',
    fontWeight: 500,
    marginLeft: '0.25rem',
    marginRight: '1.75rem',
  },
  cardTitle: {
    fontWeight: '600',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
  container: {
    fontSize: '0.875rem',
    padding: '0.325rem 1rem 0.325rem 1rem',
    minWidth: 600,
    borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
    height: '100%',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  orderRow: {
    padding: '1rem',
    borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
    fontSize: '0.75rem',
    alignItems: 'center',
  },
  orderCell: {
    padding: '0.25rem 0.5rem',
    color: '#000000',
  },
  orderRowHeader: {
    width: '100%',
    padding: '0.5rem',
    color: '#000000',
  },
  centeredCell: {
    textAlign: 'center',
  },
  actionButton: {
    border: 'none',
    borderRadius: '4px',
    height: '2.3rem',
    padding: '0 !important',
    minWidth: '100%',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      cursor: 'default',
      border: 'none',
    },
  },
  contributeLabel: {
    backgroundColor: PURPLE_TRANSPARENT_COLOR,
    color: PURPLE_ICON_COLOR,
    '&:hover': {
      backgroundColor: PURPLE_TRANSPARENT_COLOR,
      color: PURPLE_ICON_COLOR,
    },
  },
  deleteLabel: {
    backgroundColor: RED_TRANSPARENT_COLOR,
    color: RED_ICON_COLOR,
    '&:hover': {
      backgroundColor: RED_TRANSPARENT_COLOR,
      color: RED_ICON_COLOR,
    },
  },
  exportLabel: {
    backgroundColor: GREEN_TRANSPARENT_COLOR,
    color: GREEN_ICON_COLOR,
    '&:hover': {
      backgroundColor: GREEN_TRANSPARENT_COLOR,
      color: GREEN_ICON_COLOR,
    },
  },
  button: {
    height: '2.5rem',
    boxShadow: 'none',
    textTransform: 'uppercase',
    borderRadius: '4px',
    padding: '0 !important',
    border: 'none',
    marginTop: '1.25rem',
  },
  uploadButton: {
    color: SECONDARY_BLUE_2,
    backgroundColor: SECONDARY_BLUE_2_TRANSPARENT,
    marginRight: '1rem',
    width: '8rem',
    '&:hover': {
      cursor: 'pointer',
      border: `1px solid ${SECONDARY_BLUE_2}`,
      backgroundColor: SECONDARY_BLUE_2_TRANSPARENT,
    },
  },
  iframe: {
    width: '100%',
    height: '48rem',
    border: 'none',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'calc(70% - 1.25rem) 30%',
    gridGap: '1.25rem',
  },
  servicesTitle: {
    fontSize: '1.125rem',
    fontWeight: 500,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  servicesSubtitle: {
    margin: '1.125rem 1.5rem',
  },
  servicesContainer: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content auto min-content',
    height: '100%',
  },
  proceedButton: {
    width: '100%',
    boxSizing: 'border-box',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.1em',
    borderRadius: '10px',
    marginTop: '0.75rem',
    backgroundColor: SECONDARY_BLUE_2,
    paddingBottom: '0.51rem',
    paddingTop: '0.51rem',

    '&:hover': {
      backgroundColor: SECONDARY_BLUE,
    },
  },
  proceedText: {
    marginTop: '0.2rem',
  },
  proceedContainer: {
    padding: '0 2.625rem 2.625rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  proceedNote: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    lineHeight: '1.4rem',
    fontWeight: 400,
    margin: '1.125rem 1.5rem 0.75rem',
    padding: '0.78rem 0.875rem',
    gap: '11px',
    borderRadius: '10px',
    border: `1px solid ${TRANSPARENT_BLACK_15}`,
  },
  serviceButton: {
    padding: '1.5rem 0.875rem 1.75rem',
    backgroundColor: WHITE_SMOKE_2,
    display: 'flex',
    flexDirection: 'column',
    gap: '2.25rem',
    alignItems: 'start',
    textAlign: 'left',
    borderRadius: '1rem',
    color: 'black',
    boxShadow: '0px 0px 20px 3px #0000001A',
    border: `1px solid ${WHITE}`,

    '&:hover': {
      border: `1px solid ${SECONDARY_BLUE_2}`,
      backgroundColor: SECONDARY_BLUE_2_TRANSPARENT,
      boxShadow: 'none',
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: SECONDARY_BLUE,
    },
  },
  activeServiceButton: {
    color: SECONDARY_BLUE_2,
    border: `1px solid ${SECONDARY_BLUE_2}`,
    backgroundColor: SECONDARY_BLUE_2_TRANSPARENT,
    boxShadow: 'none',
  },
  errorServiceButton: {
    border: `1px solid ${RED_ICON_COLOR}`,
  },
  services: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '2.125rem',
    padding: '0 1.5rem 1.75rem',
  },
  serviceIcon: {
    backgroundColor: BUTTON_GREY,
    fill: BLACK_ICON,
    borderRadius: '50%',
    width: '2.75rem',
    height: '2.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all ease 0.5s',
  },
  activeServiceIcon: {
    backgroundColor: SECONDARY_BLUE_2,
    color: WHITE,
  },
  serviceText: {
    maxWidth: 100,
    fontWeight: 500,
    lineHeight: '1.125rem',
    fontSize: '1rem',
  },
  uploadedContainer: {
    border: `1px solid ${BORDER_GEY_2}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0.75rem 2.375rem 0.75rem 1.1875rem',
    borderRadius: '10px',
    color: DARK_GREY_2,
  },
  uploadedText: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  summary: {
    margin: 0,
    minHeight: 'unset',

    '& .MuiAccordionSummary-content': {
      margin: 6,
    },
  },
  details: {
    padding: 0,

    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  errorText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#D40505',
    paddingBottom: '0.75rem',
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

export const useOrdersTabContentStyles = makeStyles()(_OrdersTabContentStyles);

export const TEAM_OVERVIEW_HEADER_SUBTITLE_TYPOGRAPHY_PROPS = {
  color: 'rgba(0,0,0,0.5)',
  fontSize: '0.8rem',
  fontStyle: 'normal',
  lineHeight: 'normal',
};

export const TEAM_OVERVIEW_HEADER_TITLE_TYPOGRAPHY_PROPS = {
  fontSize: '1.125rem',
  letterSpacing: '2.4px',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 600,
};

const teamOverviewCommentsStyles = {
  image: {
    width: '100%',
    maxHeight: 150,
    paddingRight: '2rem',
  },
};

export const useTeamOverviewCommentsStyles = makeStyles()(
  teamOverviewCommentsStyles,
);
