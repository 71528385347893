import { Box } from '@mui/material';
import { useState } from 'react';
import { useSlideMetadataWithScienceInfoStyles } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/styles';
import { SlideInfoTable } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/SlideInfoTable';
import { getSlideAttributesFromSlide } from 'components/PathologyMap/utilities/common';
import { getSampleScienceInfo } from 'components/OrderSlidesList/utilities';
import { SlideMetadataHeader } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/SlideMetadataHeader';

export const SlideMetadataWithScienceInfo = ({ slide }) => {
  const { classes } = useSlideMetadataWithScienceInfoStyles();
  const scienceInfo = slide?.sample?.science_info || {};

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const standardMetadataItems = getSlideAttributesFromSlide({ slide });
  const scienceInfoItems = getSampleScienceInfo(scienceInfo);
  const isStandardMetadataTab = currentTab === 0;
  const isScienceInfoTab = currentTab === 1;

  const slideData = isStandardMetadataTab
    ? standardMetadataItems
    : scienceInfoItems;

  return (
    <>
      <SlideMetadataHeader
        slide={slide}
        currentTab={currentTab}
        handleTabsChange={handleTabsChange}
        standardMetadataItemsCount={standardMetadataItems.length}
        scienceInfoItemsCount={scienceInfoItems.length}
      />
      {isStandardMetadataTab && (
        <SlideInfoTable slide={slide} dataItems={slideData} />
      )}
      {isScienceInfoTab &&
        (slideData.length ? (
          <SlideInfoTable slide={slide} dataItems={slideData} />
        ) : (
          <Box className={classes.noInfo}>No science info available</Box>
        ))}
    </>
  );
};
