import { Box, Button, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  ConsultationsTeamFileAttachmentsTable,
  ProtocolTeamFileAttachmentsTable,
} from 'components/PathologyConsultations/OrderForm/TeamFileAttachments';
import { OrderFileAttachmentsDropzone } from 'components/OrderDetails/OrderFileAttachmentsDropzone';
import {
  AdditionalDetailsDescriptionInput,
  BaseTextInput,
  GoalBriefDescriptionInput,
} from 'components/PathologyConsultations/OrderForm/PathologyConsultationsOrderFormInputs';
import { FormInputTeamSelection } from 'components/OrderForm/components/InfoSelectDropdowns';
import { ShowStaffUserOnly } from 'components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly';
import { BaseOrderDropdownSelections } from 'components/OrderForm/components/BaseSelectDropdowns';
import { OrderTurnaroundTimeOptions } from 'components/OrderForm/constants';
import { PathologySpecialitiesOptionsDropdown } from 'components/PathologyConsultations/OrderForm/DropdownSelections';
import Dropzone from 'react-dropzone';
import UploadIcon from '@mui/icons-material/Upload';
import React, { useEffect, useState } from 'react';
import {
  getPathologyConsultationProtocolFileAttachments,
  getPathologyConsultationTeamFileAttachments,
  uploadProtocolsTeamFileAttachmentsToPathologyConsultations,
  uploadTeamFileAttachmentsToPathologyConsultations,
} from 'services/resources/pathologyConsultations';
import { FILE_ATTACHMENTS_FAILED_TO_UPLOAD } from 'components/OrderDetails/constants';
import { errorLogger } from 'utilities/loggers';
import {
  GLPError,
  pathologyFields,
} from 'components/PathologyConsultations/OrderForm/utils/constants';
import { ErrorMessage } from 'components/OrderForm/components/InfoFormInputs';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { usePathologyConsultationFormContentStyles } from './styles';

export const PathologyConsultationFormContent = ({
  isGLPConsultation,
  setIsGLPConsultation,
  consultUUID,
  defaultFormProps,
  consultation,
  createConsultationForUploadedFiles,
  setConsultUUID,
  selectableTeams,
  team,
  showTeamSelectionOptions,
  submitCount,
  protocolAttachments,
  setProtocolAttachments,
}) => {
  const { classes, cx } = usePathologyConsultationFormContentStyles();
  const { showSuccess, showError } = useSnackbar();
  const [fileAttachments, setFileAttachments] = useState([]);

  const toggleShowTeamSelection = showTeamSelectionOptions && !consultation;

  const getUpdatedConsultationAttachments = () => {
    if (!consultUUID) {
      return;
    }

    getPathologyConsultationTeamFileAttachments({ consultUUID }).then(
      (response) => {
        setFileAttachments(response);
      },
    );

    getPathologyConsultationProtocolFileAttachments({ consultUUID }).then(
      (response) => {
        setProtocolAttachments(response);
      },
    );
  };

  const glpSwitchParameters = {
    name: 'glp_consultation',
    checked: isGLPConsultation,
    onChange: (e) => {
      setIsGLPConsultation(e.target.checked);
    },
  };

  const uploadProtocolsFromRequestDetails = ({ requestDetails }) => {
    uploadProtocolsTeamFileAttachmentsToPathologyConsultations(requestDetails)
      .then((response) => {
        showSuccess('File Attachments Uploaded Successfully!');

        const newProtocolAttachments = [...response, ...protocolAttachments];
        setProtocolAttachments(newProtocolAttachments);
      })
      .catch((error) => {
        showError(FILE_ATTACHMENTS_FAILED_TO_UPLOAD);
        errorLogger('error when uploading', error.response);
      });
  };

  const onTeamFileAttachmentsFilesDrop = (acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append('files', file);
    });

    if (!consultation) {
      return;
    }

    formData.append('consultUUID', consultation.uuid);

    const requestDetails = {
      consultUUID: consultation.uuid,
      postParams: formData,
    };

    uploadTeamFileAttachmentsToPathologyConsultations(requestDetails)
      .then(() => {
        showSuccess('File Attachments Uploaded Successfully!');
        // now after success, get the most updated attachments
        getUpdatedConsultationAttachments();
      })
      .catch((error) => {
        showError(FILE_ATTACHMENTS_FAILED_TO_UPLOAD);
        errorLogger('error when uploading', error.response);
      });
  };

  const onProtocolTeamAttachmentFileDrop = (acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append('files', file);
    });

    // if someone is trying to upload, try to create an order on their behalf
    // have a consultation that will then be reflected and used to update
    if (!consultation) {
      createConsultationForUploadedFiles().then((createdConsultation) => {
        if (createdConsultation?.uuid) {
          setConsultUUID(createdConsultation.uuid);

          formData.append('consultUUID', createdConsultation.uuid);
          const requestDetails = {
            consultUUID: createdConsultation.uuid,
            postParams: formData,
          };

          uploadProtocolsFromRequestDetails({ requestDetails });
        }
      });
    } else {
      formData.append('consultUUID', consultation.uuid);
      const requestDetails = {
        consultUUID: consultation.uuid,
        postParams: formData,
      };

      uploadProtocolsFromRequestDetails({ requestDetails });
    }
  };

  useEffect(() => {
    if (!consultUUID) {
      return;
    }

    getUpdatedConsultationAttachments();
  }, [consultUUID]);

  const isGPLError = submitCount && !protocolAttachments.length;

  return (
    <Box>
      <GoalBriefDescriptionInput name="goal_description" />
      {!!toggleShowTeamSelection && (
        <Box>
          <FormInputTeamSelection
            name="selectedTeam"
            selectableTeams={selectableTeams}
            team={team}
          />
        </Box>
      )}
      <Grid container spacing={2}>
        <ShowStaffUserOnly>
          <Grid item xs={12}>
            <Box mt={1} mb={-2}>
              <Switch {...glpSwitchParameters} /> Is this a pre-clinical study
              that requires GLP? A GLP Protocols Document will be required if
              this option is selected.
            </Box>
          </Grid>
        </ShowStaffUserOnly>
        <Grid item xs={12}>
          {isGLPConsultation && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ProtocolTeamFileAttachmentsTable
                    attachments={protocolAttachments}
                  />
                  {isGPLError ? <ErrorMessage error={GLPError} /> : ''}
                </Grid>
                <Grid item xs={6}>
                  <Box mt={-2}>
                    <OrderFileAttachmentsDropzone
                      onFilesDrop={onProtocolTeamAttachmentFileDrop}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Typography>
                  <b>A GLP Protocols Document is required for GLP orders.</b> If
                  you need any assistance please contact our project managers.
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <BaseOrderDropdownSelections
            name="turnaround_days"
            title="Turnaround Time Request (Days)"
            options={OrderTurnaroundTimeOptions}
            checkmark
            isDisabled={isGLPConsultation}
          />
        </Grid>
        <Grid item xs={6}>
          <PathologySpecialitiesOptionsDropdown
            name="specialities"
            isDisabled={isGLPConsultation}
          />
        </Grid>
        {pathologyFields.map((field) => (
          <Grid item xs={6} key={field.name}>
            <BaseTextInput
              {...defaultFormProps}
              {...field}
              disabled={isGLPConsultation}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <AdditionalDetailsDescriptionInput
            name="additional_details"
            isDisabled={isGLPConsultation}
          />
        </Grid>
        <Grid item xs={12} mt={-2}>
          <BaseTextInput
            name="reference_papers"
            label={
              'URL Links to Reference Papers and/or Previous Pathology Reports'
            }
            description={
              'URL Links to papers and/or previous pathology reports (optional). Alternatively, you can upload any reference documents / papers below.'
            }
            {...defaultFormProps}
            checkmark={false}
            disabled={isGLPConsultation}
          />
        </Grid>
        <Grid item xs={6}>
          <ConsultationsTeamFileAttachmentsTable
            attachments={fileAttachments}
            isDisabled={isGLPConsultation}
          />
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Dropzone
              onDrop={onTeamFileAttachmentsFilesDrop}
              multiple
              disabled={isGLPConsultation}
            >
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    {...getRootProps()}
                    className={cx('dropzone', {
                      'dropzone--isActive': isDragActive,
                      [classes.disabledCursor]: isGLPConsultation,
                    })}
                  >
                    <input {...getInputProps()} />
                    <Box
                      sx={{
                        p: 2,
                        border: '1px dashed grey',
                        width: '100%',
                      }}
                    >
                      <Button
                        startIcon={<UploadIcon />}
                        disabled={isGLPConsultation}
                      >
                        Choose a file(s) to upload
                      </Button>
                    </Box>
                  </div>
                );
              }}
            </Dropzone>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={1}>
            <BaseTextInput
              name="special_instructions"
              label="Special Instructions (If Applicable)"
              description="Optional"
              {...defaultFormProps}
              checkmark={false}
              disabled={isGLPConsultation}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
