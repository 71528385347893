import {
  AddShoppingCart,
  ArrowOutward,
  Update as UpdateIcon,
} from '@mui/icons-material';
import { AgGridReact } from 'ag-grid-react';
import {
  Button,
  Input,
  Skeleton,
  Switch,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import { BlocksActionPanel } from 'components/BlocksList/BlocksActionPanel/BlocksActionPanel';
import { useBlocksPage } from 'components/BlocksList/hooks/useBlocksPage';
import { TeamSelector } from 'components/common/TeamSelector/TeamSelector';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { useSlideInfoForPathologyMapStyles } from 'components/PathologyMap/constants/styles';
import { OrdersSortTeamPage } from 'components/UserProfile/components/OrdersSortTeamPage';
import { OrderInfo } from 'components/UserProfile/OrdersCard/OrderInfo';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { useBlocksPageV2Styles } from 'components/BlocksList/styles';
import { getDataPath } from 'components/BlocksList/utilities';
import {
  autoGroupColumnDef,
  columnDefs,
  defaultColDef,
} from 'components/BlocksList/constants';

import { handleOpenAsGroup } from 'components/OrderSlidesList/utilities';

export const BlocksPageV2 = () => {
  useTitle('HistoWiz - Blocks List');
  const { classes } = useBlocksPageV2Styles();
  const { classes: classesFromPathologyMap } =
    useSlideInfoForPathologyMapStyles();

  const {
    selectedTeam,
    teamInfo,
    setSelectedTeam,
    teams,
    tableTitle,
    selectedRows,
    handleAddToQueue,
    onQuickFilterText,
    handleSwitchChange,
    filteredRowData,
    checkRowSelectable,
    onGridReady,
    onRowSelected,
    quickFilterText,
    isOnlyWithSlides,
    processingQueue,
    setProcessingQueue,
    handleCreateNewOrder,
    activeTab,
    setActiveTab,
    selectedSamples,
    setSelectedSamples,
    blocksModalOpen,
    handleBlockModalClose,
    orders,
    isOrdersLoading,
    handleBlockModalOpen,
    sort,
    setSort,
    handleAddToNewOrder,
    selectedSlides,
    handleFirstDataRendered,
    onRowGroupOpened,
  } = useBlocksPage();

  return (
    <>
      <Box className={classes.pageHeader}>
        <Typography className={classes.title}>Blocks Manager</Typography>
        <Box className={classes.teamSelect}>
          {selectedTeam ? (
            <Typography className={classes.title}>{teamInfo}</Typography>
          ) : (
            <Skeleton variant="text" width={200} />
          )}
          <TeamSelector
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            teams={teams}
          />
        </Box>
        <Box className={classes.header}>
          <Typography className={classes.subtitle}>
            BLOCKS IN STORAGE:
          </Typography>
          <Box className={classes.blocksCounter}>
            {filteredRowData?.length || 0}
          </Box>
        </Box>
      </Box>
      <Grid container className={classes.container}>
        <Grid item xs={8} className={classes.tableWrapper}>
          <Box>
            <div className={`ag-theme-balham ${classes.table}`}>
              <Box className={classes.tableHeader}>
                <Typography className={classes.tableTitle}>
                  {tableTitle}
                </Typography>
                <Box display="flex">
                  {selectedSlides.length && selectedRows.length ? (
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.processButton}
                      endIcon={<ArrowOutward />}
                      onClick={() => handleOpenAsGroup(selectedSlides)}
                    >
                      View {selectedSlides.length} associated slides
                    </Button>
                  ) : null}
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.processButton}
                    startIcon={<AddShoppingCart />}
                    disabled={!selectedRows?.length}
                    onClick={handleAddToQueue}
                  >
                    Add{' '}
                    <Box className={classes.processCounter}>
                      {selectedRows?.length}
                    </Box>{' '}
                    samples to queue
                  </Button>
                  <IconButton className={classes.changelog}>
                    <UpdateIcon className={classes.changeIcon} />
                  </IconButton>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Input
                  onChange={onQuickFilterText}
                  className={classes.searchInput}
                  placeholder="Enter text to filter..."
                  disableUnderline
                />
                <Box className={classes.switch}>
                  <Switch
                    size="small"
                    checked={isOnlyWithSlides}
                    onChange={handleSwitchChange}
                  />
                  Show blocks with slides only
                </Box>
              </Box>
              <AgGridReact
                rowData={filteredRowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                isRowSelectable={checkRowSelectable}
                enableBrowserTooltips
                treeData
                animateRows
                quickFilterText={quickFilterText}
                onGridReady={onGridReady}
                onRowSelected={onRowSelected}
                onFirstDataRendered={handleFirstDataRendered}
                groupDefaultExpanded={0}
                rowSelection="multiple"
                groupSelectsChildren
                getDataPath={getDataPath}
                getRowId={({ data }) => data?.uuid}
                overlayNoRowsTemplate={'<span>No data</span>'}
                onRowGroupOpened={onRowGroupOpened}
              />
            </div>
          </Box>
        </Grid>
        <BlocksActionPanel
          processingQueue={processingQueue}
          setProcessingQueue={setProcessingQueue}
          openBlocksModal={handleBlockModalOpen}
          handleCreateOrder={handleCreateNewOrder}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          selectedSamples={selectedSamples}
          selectedRows={selectedRows}
          setSelectedSamples={setSelectedSamples}
          selectedSlides={selectedSlides}
        />
      </Grid>
      <DefaultModal open={blocksModalOpen} onClose={handleBlockModalClose}>
        <Box className={classes.formWrapper}>
          <OrdersSortTeamPage sort={sort} setSort={setSort} />
          {isOrdersLoading ? (
            <Box className={classes.spinnerContainer}>
              <Spinner />
            </Box>
          ) : orders.length ? (
            orders.map((order) => (
              <OrderInfo
                onRowClick={() => handleAddToNewOrder(order.uuid)}
                key={order.uuid}
                order={order}
                classes={classesFromPathologyMap}
              />
            ))
          ) : (
            <Box height="100%" display="flex" alignItems="center">
              <Typography>No such orders</Typography>
            </Box>
          )}
        </Box>
      </DefaultModal>
    </>
  );
};
