import { makeStyles } from 'tss-react/mui';
import { SECONDARY_WHITE } from 'utilities/colors';

export const useStyles = makeStyles()(() => ({
  root: {
    backgroundColor: SECONDARY_WHITE,
  },
  switchWrapper: {
    position: 'absolute',
    top: '8rem',
    left: 20,
  },
  betaWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      paddingRight: 5,
    },
  },
}));
