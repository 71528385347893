import { FormInput } from 'components/FormInputs/FormInput';
import React from 'react';
import { useSearchStyles } from 'components/TeamOverview/components/AiComputeTransactionsCard/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';

export const Search = ({ setFilter }) => {
  const { classes } = useSearchStyles();

  const onQuickFilterText = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box className={classes.container}>
      <SearchIcon className={classes.icon} />
      <FormInput
        className={classes.searchField}
        onChange={onQuickFilterText}
        placeholder="Search by order, slide name etc"
      />
    </Box>
  );
};
