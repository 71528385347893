import { useEffect } from 'react';

export const useResetScrollOnValueChange = (containerRef, ...values) => {
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
      containerRef.current.scrollLeft = 0;
    }
  }, [containerRef.current, ...values]);
};
