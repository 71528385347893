import { Typography } from '@mui/material';
import React from 'react';

export const PleaseCallHistoWiz = () => {
  return (
    <Typography align="center" variant="body2">
      Questions? Please feel free to call us at 347-221-1020 or email us w/this
      page link.
    </Typography>
  );
};
