import { makeStyles } from 'tss-react/mui';

export const useColumnHeaderStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
    overflow: 'hidden',
  },
  nameWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  requiredIcon: {
    color: 'red',
  },
  trashIcon: {
    color: '#cd0000',
    cursor: 'pointer',
    height: '16px',
    minWidth: '16px',
  },
}));

export const useExcelStyles = makeStyles()(() => ({
  excelButton: {
    '.MuiButton-endIcon > div': {
      fontSize: 'inherit',
    },
    '.MuiButton-startIcon > div > .MuiCircularProgress-root': {
      color: '#808080',
      display: 'flex',
      alignItems: 'center',
    },
  },
  excelInput: {
    display: 'none',
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
}));

export const useEditButtonStyles = makeStyles()(() => ({
  editButton: {
    alignItems: 'center',
    marginLeft: 'auto',
    color: '#4F84FF',
    textDecoration: 'underline',
    display: 'flex',
    gap: '0.2rem',
    fontSize: '0.75rem',
    textTransform: 'capitalize',
    ' > svg': {
      width: '1rem',
      height: '1rem',
      transform: 'translateY(-10%)',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  editButtonIcon: {
    marginTop: '0.125rem',
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: '0.6',
  },
}));
