import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Pagination from '@mui/material/Pagination';
import { MiniDrawerWithContextMemo } from 'components/Layout/drawer';
import { SLIDE_NAVIGATOR_SLIDE_TYPES } from 'components/OrderForm/constants';
import { TextField, Grid, CircularProgress, Box } from '@mui/material';
import { slideDataSerializer } from 'components/utilities/gridDataSerializers';
import {
  getOptionsFromObjectMapping,
  getValueFromObject,
} from 'components/SlideNavigator/utils';
import { SlideCard } from 'components/SlideNavigator/SlideCard';
import {
  selectColourStyles,
  useSlideNavigatorStyles,
} from 'components/SlideNavigator/styles';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import {
  OFFSET_LIMIT,
  SLIDE_NAVIGATOR_HEADER_TITLE,
  SLIDE_NAVIGATOR_TITLE,
} from 'components/SlideNavigator/constants';
import { useLazyGetAllSlidesQuery } from 'store/apis/slidesApi';
import { FETCHING_SLIDES_ERROR_MESSAGE } from 'constants/errorMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

const SlideNavigator = () => {
  const { showDrawer } = useSelector(userDetailsSelector);
  useTitle(SLIDE_NAVIGATOR_TITLE);
  const { classes } = useSlideNavigatorStyles();
  const unmountIgnore = useUnmountIgnore();

  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [slideNameFilter, setSlideNameFilter] = useState('');
  const [orderOptions, setOrderOptions] = useState([]);
  const [sampleOptions, setSampleOptions] = useState([]);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [organOptions, setOrganOptions] = useState([]);
  const [stainNameOptions, setStainNameOptions] = useState([]);
  const [filterState, setFilterState] = useState({
    orderFilter: '',
    sampleFilter: [],
    speciesFilter: [],
    organFilter: [],
    stainTypeFilter: [],
    stainNameFilter: [],
  });

  const {
    orderFilter,
    sampleFilter,
    speciesFilter,
    organFilter,
    stainTypeFilter,
    stainNameFilter,
  } = filterState;
  const [getAllSlides, { isFetching: isLoading }] = useLazyGetAllSlidesQuery();

  const { showError } = useSnackbar();

  const updateSlides = useCallback(() => {
    getAllSlides()
      .unwrap()
      .then((response) => {
        if (unmountIgnore.current || !response) return;

        const rows = response.map(slideDataSerializer);

        const orderUUIDToNameMapping = {};
        const sampleUUIDToNameMapping = {};
        const speciesUUIDToNameMapping = {};
        const organUUIDToNameMapping = {};
        const stainNameMapping = {};
        const stainTypeMapping = {};

        rows.forEach((row) => {
          const { order, sample, stain_type, stain } = row;

          if (stain_type) {
            stainTypeMapping[stain_type] = stain_type;
          }

          if (stain) {
            stainNameMapping[stain] = stain;
          }

          if (order) {
            const orderUUID = order.uuid;
            orderUUIDToNameMapping[orderUUID] = order.name;
          }

          if (sample) {
            const sampleUUID = sample.uuid;
            sampleUUIDToNameMapping[sampleUUID] = sample.name;

            if (sample.species) {
              const speciesUUID = sample.species.uuid;
              speciesUUIDToNameMapping[speciesUUID] = sample.species.name;
            }

            if (sample.organ) {
              const organUUID = sample.organ.uuid;
              organUUIDToNameMapping[organUUID] = sample.organ.name;
            }
          }
        });

        setOrderOptions(getOptionsFromObjectMapping(orderUUIDToNameMapping));
        setSampleOptions(getOptionsFromObjectMapping(sampleUUIDToNameMapping));
        setOrganOptions(getOptionsFromObjectMapping(organUUIDToNameMapping));
        setSpeciesOptions(
          getOptionsFromObjectMapping(speciesUUIDToNameMapping),
        );
        setStainNameOptions(getOptionsFromObjectMapping(stainNameMapping));
        setRowData(rows);
      })
      .catch(() => showError(FETCHING_SLIDES_ERROR_MESSAGE));
  }, [showDrawer, unmountIgnore]);

  const handleChange = (e, p) => {
    setPageNumber(p);
  };

  useEffect(() => {
    updateSlides();
  }, [updateSlides]);

  const handleSelectChange = (selectType) => (value) => {
    setFilterState((prevState) => ({ ...prevState, [selectType]: value }));
    setPageNumber(1);
  };

  const handleSlideNameFilter = (event) => {
    setSlideNameFilter(event.target.value);
    setPageNumber(1);
  };

  let validRows = rowData;

  if (orderFilter.length) {
    const validOrderUUIDs = orderFilter.map(getValueFromObject);

    validRows = validRows.filter((row) => {
      return validOrderUUIDs.includes(row.order.uuid);
    });
  }

  if (sampleFilter.length) {
    const validSampleUUIDs = sampleFilter.map(getValueFromObject);

    validRows = validRows.filter((row) => {
      if (row.sample) {
        return validSampleUUIDs.includes(row.sample.uuid);
      }
      return false;
    });
  }

  if (speciesFilter.length) {
    const validSpeciesUUIDs = speciesFilter.map(getValueFromObject);

    validRows = validRows.filter((row) => {
      if (row.sample && row.sample.species) {
        return validSpeciesUUIDs.includes(row.sample.species.uuid);
      }
      return false;
    });
  }

  if (organFilter.length) {
    const validOrganUUIDs = organFilter.map(getValueFromObject);

    validRows = validRows.filter((row) => {
      if (row.sample && row.sample.organ) {
        return validOrganUUIDs.includes(row.sample.organ.uuid);
      }
      return false;
    });
  }

  if (stainTypeFilter.length) {
    const selectedStainType = stainTypeFilter.map(getValueFromObject);
    validRows = validRows.filter((row) => {
      return selectedStainType.includes(row.stain_type);
    });
  }

  if (stainNameFilter.length) {
    const validStainNames = stainNameFilter.map(getValueFromObject);

    validRows = validRows.filter((row) => {
      return validStainNames.includes(row.stain);
    });
  }

  if (slideNameFilter) {
    const upperSlideNameFilter = slideNameFilter.toUpperCase();
    validRows = validRows.filter((row) => {
      return row.name.toUpperCase().includes(upperSlideNameFilter);
    });
  }
  const startOffset = (pageNumber - 1) * OFFSET_LIMIT;
  const endOffset = startOffset + OFFSET_LIMIT;
  const rowsToShow = validRows.slice(startOffset, endOffset);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <label className={classes.slideNameLabel}>Slide Name Search</label>
      </Grid>
      <Grid item xs={4} sm={3} lg={3}>
        <label className={classes.formLabel}>Search</label>
        <TextField
          id="standard-full-width"
          variant="outlined"
          size="small"
          className={classes.search}
          placeholder="Type here..."
          onChange={handleSlideNameFilter}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={4} sm={3} lg={3}>
        <label className={classes.formLabel}>Order</label>
        <Select
          options={orderOptions}
          onChange={handleSelectChange('orderFilter')}
          value={orderFilter}
          isMulti
          styles={selectColourStyles}
        />
      </Grid>
      <Grid item xs={4} sm={3} lg={3}>
        <label className={classes.formLabel}>Samples</label>
        <Select
          options={sampleOptions}
          onChange={handleSelectChange('sampleFilter')}
          isMulti
          styles={selectColourStyles}
        />
      </Grid>
      <Grid item xs={4} sm={3} lg={3}>
        <label className={classes.formLabel}>Species</label>
        <Select
          options={speciesOptions}
          onChange={handleSelectChange('speciesFilter')}
          isMulti
          styles={selectColourStyles}
        />
      </Grid>
      <Grid item xs={4} sm={3} lg={3}>
        <label className={classes.formLabel}>Organ</label>
        <Select
          options={organOptions}
          onChange={handleSelectChange('organFilter')}
          isMulti
          styles={selectColourStyles}
        />
      </Grid>
      <Grid item xs={4} sm={3} lg={3}>
        <label className={classes.formLabel}>Stain Type</label>
        <Select
          options={SLIDE_NAVIGATOR_SLIDE_TYPES}
          onChange={handleSelectChange('stainTypeFilter')}
          isMulti
          styles={selectColourStyles}
        />
      </Grid>
      <Grid item xs={4} sm={3} lg={3}>
        <label className={classes.formLabel}>Stain Name</label>
        <Select
          options={stainNameOptions}
          onChange={handleSelectChange('stainNameFilter')}
          isMulti
          styles={selectColourStyles}
        />
      </Grid>
      {isLoading ? (
        <div className={classes.spinner}>
          <CircularProgress size={30} />
        </div>
      ) : validRows.length ? (
        <Grid item xs={12} className={classes.pageInfo}>
          <Box>
            Showing {rowsToShow.length} of {validRows.length} Slides
          </Box>
          <Box className={classes.paginationContainer}>
            Page
            <Pagination
              size="small"
              count={Math.ceil(validRows.length / OFFSET_LIMIT)}
              page={pageNumber}
              onChange={handleChange}
            />
          </Box>
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          pt="1.563rem"
          fontSize="1.563rem"
        >
          No Slides
        </Box>
      )}
      <Grid container spacing={6} className={classes.slidesContainer}>
        {rowsToShow.map((slide) => (
          <SlideCard slide={slide} key={slide.uuid} />
        ))}
      </Grid>
    </Grid>
  );
};

export const SlideNavigatorPage = () => (
  <MiniDrawerWithContextMemo header={SLIDE_NAVIGATOR_HEADER_TITLE}>
    <SlideNavigator />
  </MiniDrawerWithContextMemo>
);
