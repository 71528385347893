import React from 'react';
import { PricesReviewView } from 'components/OrderForm/PricesReviewView/PricesReviewView';
import { ReviewView } from 'components/OrderForm/Review/ReviewView';
import { ReviewViewV2 } from 'components/OrderFormV2/ReviewV2/ReviewViewV2';
import { PlaceOrderBaseView } from '../OrderForm/WizardNavigator/WizardNavigatorPage';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { BillingShippingView } from 'components/OrderForm/BillingShipping/BillingShippingView';

export const PlaceOrderPriceReviewView = () => (
  <PlaceOrderBaseView label={'Item Review'}>
    <PricesReviewView />
  </PlaceOrderBaseView>
);

export const PlaceOrderBillingView = () => {
  useTitle('Place Order - Billing & Shipping');

  return (
    <PlaceOrderBaseView label={'Billing Review'}>
      <BillingShippingView />
    </PlaceOrderBaseView>
  );
};

export const PlaceOrderReviewViewV2 = () => (
  <PlaceOrderBaseView label={'Billing Review'}>
    <ReviewViewV2 />
  </PlaceOrderBaseView>
);

export const PlaceOrderReviewView = () => (
  <PlaceOrderBaseView label={'Billing Review'}>
    <ReviewView />
  </PlaceOrderBaseView>
);
