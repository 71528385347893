import { useEffect, useState } from 'react';
import { FETCH_PROJECTS_ERROR_MESSAGE } from 'constants/errorMessages';
import { getProjects } from 'services/resources/commonResources';
import { serializeTeamName } from 'utilities/api';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const useSerializedProjects = () => {
  const unmountIgnore = useUnmountIgnore();
  const { showError } = useSnackbar();
  const [selectableProjectOptions, setSelectableProjectOptions] = useState([]);

  useEffect(() => {
    getProjects()
      .then((response) => {
        const selectableProjectOptions = response.map(serializeTeamName);
        if (!unmountIgnore.current) {
          setSelectableProjectOptions(selectableProjectOptions);
        }
      })
      .catch(() => showError(FETCH_PROJECTS_ERROR_MESSAGE));
  }, [unmountIgnore]);

  return {
    selectableProjectOptions,
  };
};
