import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const CompletedSlidesButton = ({ onClick, disabled, className }) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant="contained"
      startIcon={<CheckCircleIcon />}
      disabled={disabled}
    >
      VIEW COMPLETED SLIDES
    </Button>
  );
};
