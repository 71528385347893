import { makeStyles } from 'tss-react/mui';

export const useCircularChartOverviewStyles = makeStyles()({
  dataUsageCardContent: {
    alignItems: 'center',
    padding: '1rem 1.5rem',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  chartWrapper: {
    maxHeight: 'max-content',
    position: 'relative',
    width: '50%',
    maxWidth: '180px',
  },
  commonData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  greyText: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  legendWrapper: {
    width: 'max-content',
    maxWidth: '50%',
    paddingLeft: '2rem',
  },
  legendRow: {
    display: 'flex',
    flexDirection: 'row',

    '&:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  square: {
    width: '1rem',
    height: '1rem',
    marginRight: '1rem',
    marginTop: '3px',
  },
});
