import { Box } from '@mui/material';
import { filterComputedSlides } from 'components/AITools/utilities/common';
import { FILTER_BUTTONS } from 'components/pages/AppLibrary/constants';
import { useFilteredSlidesStyles } from 'components/pages/AppLibrary/styles';
import { useEffect, useMemo } from 'react';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { SlidesList } from 'components/pages/AppLibrary/SlidesList';
import { useGetMlComputeOrdersQuery } from 'store/apis/walletApi';

const SLIDES_INCREMENT = 12;
const OFFSET_HEIGHT_MARGIN = 1;

export const FilteredSlides = ({
  activeFilter,
  currentIndex,
  setCurrentIndex,
  initialSlides,
  isLoading,
  model,
  isAppLibrary,
}) => {
  const { classes } = useFilteredSlidesStyles();
  const { data, isLoading: isLoadingLogs } = useGetMlComputeOrdersQuery();

  const isDataLoading =
    activeFilter === FILTER_BUTTONS.applicable ? isLoading : isLoadingLogs;

  const filteredSlides = useMemo(
    () =>
      activeFilter === FILTER_BUTTONS.applicable
        ? initialSlides
        : filterComputedSlides(data, activeFilter, model),
    [model, activeFilter, data, initialSlides],
  );

  useEffect(() => {
    const onScroll = () => {
      if (!filteredSlides?.length) return;

      setCurrentIndex((prev) => {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - OFFSET_HEIGHT_MARGIN
        ) {
          return prev + SLIDES_INCREMENT;
        }

        return prev;
      });
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [filteredSlides, setCurrentIndex]);

  const currentSlides = filteredSlides?.slice(0, currentIndex);

  return (
    <Box>
      {!isLoading && !filteredSlides?.length && (
        <Box className={classes.noSlides}>No slides</Box>
      )}
      {isDataLoading ? (
        <Box className={classes.spinner}>
          <Spinner />
        </Box>
      ) : (
        <SlidesList
          classes={classes}
          resultsCount={filteredSlides?.length}
          slides={currentSlides}
          isAppLibrary={isAppLibrary}
        />
      )}
    </Box>
  );
};
