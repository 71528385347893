import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import { makeStyles } from 'tss-react/mui';
import {
  AIOSYN_KIDNEY_AI_MODELS,
  AIRA_LIVER_AI_MODELS,
  QC_AI_MODELS,
  TISSUE_AI_MODELS,
  TISSUE_SEGMENTATION_MODELS,
} from 'components/AITools/constants/common';
import {
  HISTOWIZ_PRIMARY_COLOR,
  LINK_COLOR,
  TRANSPARENT_BLACK_15,
  TRANSPARENT_BLACK_26,
} from 'utilities/colors';

const QC_CANVAS_COLORS = {
  PASSED: 'rgba(0, 255, 0, 1)',
  FAILED: 'rgba(255, 0, 0, 1)',
  INCONCLUSIVE: 'rgba(255, 255, 0, 1)',
};

export const MAP_AI_MODEL_TO_DISPLAY_COLOR = {
  [TISSUE_SEGMENTATION_MODELS.ML_TISSUE_DATA]: 'rgba(0, 0, 255, 0.35)',
  [TISSUE_AI_MODELS.ML_TUMOR_DATA]: 'rgba(255, 0, 0, 0.35)',
  [TISSUE_AI_MODELS.ML_STROMA_DATA]: 'rgba(152, 245, 90, 0.35)',
  [TISSUE_AI_MODELS.ML_INFLAMMATION_DATA]: 'rgba(245, 90, 201, 0.35)',
  [TISSUE_AI_MODELS.ML_NECROSIS_DATA]: 'rgba(255, 213, 0, 0.35)',

  [AIRA_LIVER_AI_MODELS.ML_BILE_DUCT_DATA]: 'rgba(70, 130, 180, 1)',
  [AIRA_LIVER_AI_MODELS.ML_EMH_DATA]: 'rgba(25, 25, 112, 1)',
  [AIRA_LIVER_AI_MODELS.ML_MICROGRANULOMA_DATA]: 'rgba(255, 229, 194, 1)',
  [AIRA_LIVER_AI_MODELS.ML_SINGLE_CELL_NECROSIS_DATA]: 'rgba(75, 217, 146, 1)',
  [AIRA_LIVER_AI_MODELS.ML_VACUOLATION_DATA]: 'rgba(255, 255, 32, 1)',
  [AIRA_LIVER_AI_MODELS.ML_PMNC_INFILTRATION_DATA]: 'rgba(149, 244, 216, 1)',
  [AIRA_LIVER_AI_MODELS.ML_NECROSIS_DATA]: 'rgba(255, 32, 32, 1)',

  [AIOSYN_KIDNEY_AI_MODELS.ML_GLOMERULI_DATA]: 'rgba(255, 0, 0, 0.35)',
  [AIOSYN_KIDNEY_AI_MODELS.ML_NORMAL_TUBULI_DATA]: 'rgba(0, 255, 0, 0.35)',
  [AIOSYN_KIDNEY_AI_MODELS.ML_ATROPHIC_TUBULI_DATA]: 'rgba(0, 0, 255, 0.35)',
  [AIOSYN_KIDNEY_AI_MODELS.ML_DILATED_GLOMERULI_DATA]:
    'rgba(255, 255, 0, 0.35)',

  [QC_AI_MODELS.ML_QC_BLURRY_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_TISSUE_SEPARATION_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_BLADE_MARKS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_COVERSLIP_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_CUT_DEEPER_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_DIRTY_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_FLOATERS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_SCANNER_ARTIFACTS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_FOLDS_TEARS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_STAIN_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_MICROVIBRATIONS_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
  [QC_AI_MODELS.ML_QC_TISSUE_CRACKING_DATA]: {
    PASSED: QC_CANVAS_COLORS.PASSED,
    FAILED: QC_CANVAS_COLORS.FAILED,
    INCONCLUSIVE: QC_CANVAS_COLORS.INCONCLUSIVE,
  },
};

export const useSelectableItemStyles = makeStyles()((theme) => ({
  selectableItem: {
    color: '#757575',
    fontFamily: 'Courier',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    height: '100%',
    borderColor: TRANSPARENT_BLACK_26,

    [theme.breakpoints.down('xl')]: {
      padding: '0.375rem',
    },
  },
}));

export const useContentStyles = makeStyles()((theme) => ({
  aiPanel: {
    overflowY: 'auto',
    height: '100%',
    flexWrap: 'nowrap',
    ...VERTICAL_SCROLL_STYLE,
  },
  mlModelsHeader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    letterSpacing: '1px',
    lineHeight: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 700,
  },
  percentageSliderWrapper: {
    paddingTop: '0.25rem !important',
    maxWidth: '13rem',
  },
  aiModelsListWrapper: {
    paddingTop: 0,
    maxWidth: '100% !important',
    marginTop: 0,
    marginLeft: 0,
  },
  headerContainer: {
    display: 'flex',
    padding: '0.5rem',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D1D1D1',
  },
  aiHeaderText: {
    fontSize: '1rem',
    lineHeight: '1rem',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  aiHeaderValue: {
    color: 'black',
    fontWeight: 500,
  },
  logo: {
    objectFit: 'cover',
    margin: '0 auto',
  },
  learnMore: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    lineHeight: '1.25rem',
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  learMoreIcon: {
    width: '0.875rem',
    height: '0.875rem',
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionSummary: {
    padding: 0,
    borderBottom: '1px solid #D1D1D1',
    '&:hover, &.Mui-expanded': {
      backgroundColor: '#D4E2F0',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      padding: '20px',
      '& .Mui-expanded': {
        margin: '0px !important',
      },
    },
    backgroundColor: '#F6F6F6',
  },
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      padding: 0,
      paddingBottom: '1rem',
      borderBottom: '1px solid #D1D1D1',
    },
  },
  optionsList: {
    padding: '1rem 1.5rem 0 !important',
    marginLeft: 0,
  },
  mlModels: {
    width: '100%',
    marginTop: '0',
    marginLeft: '2.5rem',
    paddingRight: '1.125rem',
    display: 'grid',
    gap: '0.625rem 15%',
    gridTemplateColumns: 'auto min-content',
  },
  runButton: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.1em',
    whiteSpace: 'nowrap',

    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
  rightAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  gridWrapperItem: {
    [theme.breakpoints.down('lg')]: {
      minWidth: '155px',
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  expandButton: {
    minWidth: 'unset',
    marginLeft: '0.75rem',

    '&>svg': {
      width: '1.25rem',
      height: '1.25rem',
    },

    [theme.breakpoints.down('xl')]: {
      padding: '0.2rem 0.2rem',

      '&>svg': {
        width: '1rem',
        height: '1rem',
      },
    },
  },
  modelsButton: {
    width: '100%',
    borderRadius: '0',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textTransform: 'capitalize',
    fontSize: '1rem',
  },
  modelsActiveButton: {
    borderBottom: '3px solid',
  },
  inactiveBorder: {
    borderBottom: `1px solid ${TRANSPARENT_BLACK_26}`,
  },
  downloadButton: {
    width: '100%',
    marginTop: '1.25rem',
    fill: 'white',
    padding: '0.625rem 1rem',
  },
  downloadText: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.1em',
    fontWeight: 500,
    textTransform: 'uppercase',
    paddingTop: '0.25rem',
  },
  disabledContent: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

export const AI_PROCESSING_ACTION_MENU_STYLE = {
  padding: 0,
};

export const getColorIndicatorStyle = (color) => ({
  width: '0.8rem',
  height: '0.8rem',
  marginRight: '0.5rem',
  backgroundColor: color,
  display: 'inline-block',
});

export const SELECTED_AI_MODEL_ITEM_BG_COLOR = 'rgba(66, 148, 213, 0.20)';

export const getSelectedAIModelItemContainerStyle = (isDisabled, isLoading) => {
  const isClickable = isDisabled || isLoading;

  return {
    cursor: isClickable ? 'default' : 'pointer',
  };
};

export const getSelectedAIModelItemContainerBGColor = (
  isSelected,
  isDisabled,
  isLoading,
) => {
  const isSelectable = isSelected && !(isLoading || isDisabled);

  return isSelectable ? SELECTED_AI_MODEL_ITEM_BG_COLOR : 'white';
};

export const getSelectedAIModelCheckIconStyle = (isSelected, isDisabled) => {
  const isSelectable = isSelected && !isDisabled;

  return {
    visibility: isSelectable ? 'visible' : 'hidden',
  };
};

export const DISABLED_AI_MODEL_ITEM_BG_COLOR = 'rgba(117, 117, 117, 0.60)';

export const getSelectedAIModelTextStyle = (isDisabled) =>
  isDisabled ? { color: DISABLED_AI_MODEL_ITEM_BG_COLOR } : {};

export const useAIModelModalStyles = makeStyles()(() => ({
  container: {
    padding: '1rem 1.5rem',
    width: '62rem',
    height: '44.5rem',
    maxWidth: '80vw',
    maxHeight: '83vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...VERTICAL_SCROLL_STYLE,
  },
  halfSection: {
    paddingBottom: '1rem',
  },
  title: {
    fontSize: '1rem',
    letterSpacing: 0.1,
    fontWeight: 700,
    opacity: 0.6,
    textTransform: 'uppercase',
  },
  logo: {
    width: '7rem',
  },
  confirmButton: {
    marginTop: '0.375rem',
    width: '100%',
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: 0.1,

    '& svg': {
      fontSize: '1rem !important',
      opacity: 0.4,
    },
  },
  infoSection: {
    paddingTop: '0.875rem',
    borderTop: '1px solid #ccc',
  },
  halfContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.5rem',
  },
  galleryWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginTop: '0.625rem',
  },
  orderButton: {
    fontSize: '0.875rem',
    letterSpacing: 0.1,
    textTransform: 'uppercase',
    marginBottom: '1rem',
    opacity: 0.8,
    position: 'relative',
    width: '100%',
  },
  arrowIcon: {
    position: 'absolute',
    right: '1.5rem',
  },
  confirmed: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    textAlign: 'center',
    height: '20rem',
  },
  successIcon: {
    display: 'inline-block',
    width: '3.75rem',
    height: '3.75rem',
    borderRadius: '50%',
    backgroundColor: '#078D44',
    position: 'relative',
    marginBottom: '1rem',
  },
  checkMark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '1.5rem',
    width: '0.75rem',
    transform: 'translate(-50%, -50%) rotate(45deg)',
    borderBottom: '0.25rem solid white',
    borderRight: '0.25rem solid white',
  },
  confirmTitle: {
    fontSize: '1rem',
    letterSpacing: 0.1,
    fontWeight: 700,
  },
  confirmText: {
    fontSize: '1rem',
    letterSpacing: 0.1,
    fontWeight: 400,
    opacity: 0.8,
  },
  timerText: {
    fontSize: '1rem',
    opacity: 0.3,
    marginTop: '-1rem',
    marginBottom: '2rem',
  },
  reportButton: {
    marginTop: '2rem',
    letterSpacing: '0.1em',
  },
  confirmTable: {
    marginBottom: '1rem',
    maxHeight: '16rem',
    border: `1px solid ${TRANSPARENT_BLACK_15}`,
    borderRadius: '10px',
    letterSpacing: '0.1em',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },
  confirmSlides: {
    overflowY: 'auto',

    ...VERTICAL_SCROLL_STYLE,
  },
  confirmSlide: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '70% 30%',
    gap: '1rem',
    padding: '0.675rem 1.25rem',
    borderBottom: `1px solid ${TRANSPARENT_BLACK_15}`,
  },
  confirmName: {
    fontSize: '0.75rem',
    color: LINK_COLOR,
  },
  confirmIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmHeader: {
    fontSize: '0.75rem',
    fontWeight: 500,
    color: 'black',
  },
}));

export const useAIModalRowStyles = makeStyles()(() => ({
  text: {
    fontSize: '0.75rem',
    letterSpacing: 0.1,
    color: 'rgba(0, 0, 0, 0.6)',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '0.5rem',
    borderBottom: '1px solid black',
    marginBottom: '0.5rem',
    textTransform: 'uppercase',
  },
  value: {
    fontSize: '0.875rem',
    letterSpacing: 0.1,
    color: 'rgba(0, 0, 0, 0.8)',
    fontWeight: 700,
    textAlign: 'right',
  },
}));

export const useAIReportIssueStyles = makeStyles()(() => ({
  container: {
    padding: '1rem 1.5rem',
    width: '62rem',
    maxWidth: '80vw',
    maxHeight: '83vh',
    height: '44.5rem',
    overflowY: 'auto',
    position: 'relative',
    ...VERTICAL_SCROLL_STYLE,
  },
  formContainer: {
    padding: '20px',
    maxWidth: '30rem',
    margin: '0 auto',
    letterSpacing: 0.1,
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: '4rem',
    color: HISTOWIZ_PRIMARY_COLOR,
    textAlign: 'center',
  },
  label: {
    fontSize: '0.875rem',
    marginTop: '2rem',
    marginBottom: '0.5rem',
  },
  required: {
    color: 'red',
  },
  formControl: {
    marginBottom: '15px',
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  buttonGroup: {
    display: 'flex',
    gap: '0.75rem',
    width: '100%',
    color: '#5E5E5E',
  },
  button: {
    borderRadius: '1rem',
    padding: '0.25rem 1rem',
    textTransform: 'capitalize',
  },
  submitButton: {
    marginTop: '3rem',
    color: 'white',
    width: '100%',
  },
  backButton: {
    fontSize: '0.875rem',
    cursor: 'pointer',
    letterSpacing: 0.1,
    position: 'absolute',
    top: '2.25rem',
    left: '1.5rem',
    color: 'black',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
}));
