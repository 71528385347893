import { makeStyles } from 'tss-react/mui';
import {
  BORDER_GREY,
  DATA_MANAGEMENT_BLUE,
  PRIMARY_PINK,
  SECONDARY_BLUE_2,
} from 'utilities/colors';
import { MODAL_WITHOUT_BACKGROUND } from 'components/SlideGroups/utilities/styles';

export const useUploadModalStyles = makeStyles()(() => ({
  generateLinkButton: {
    margin: '0.5rem 0',
    width: '30rem',
    height: '3.25rem',
    letterSpacing: '0.1rem',
    borderRadius: '0.3rem',
    fontSize: '1.125rem',
    color: 'white',
    justifyContent: 'left',
    paddingLeft: '1.5rem',
  },
  linkIcon: {
    transform: 'rotate(-45deg)',
    color: 'white',
  },
  disclaimerText: {
    marginTop: '0.25rem',
    textAlign: 'center',
  },
  link: {
    color: DATA_MANAGEMENT_BLUE,
    textDecoration: 'underline',
  },
  spinner: {
    position: 'absolute',
    top: '55%',
    right: '29vw',
  },
  successMark: { fontSize: 80, color: 'green' },
  button: {
    minWidth: '15rem',
    width: '15rem',
    height: '2.5rem',
    marginTop: '5rem',
    letterSpacing: '0.1rem',
    fontSize: '1rem',
    borderRadius: '0.2rem',
  },
  proceedButton: {
    width: '18.5rem',
    height: '2.5rem',
    marginTop: '5rem',
    letterSpacing: '0.1rem',
    fontSize: '1.25rem',
    borderRadius: '0.2rem',
  },
  createOrderButton: {
    width: '30rem',
    height: '3.5rem',
    borderRadius: '0.2rem',
    textTransform: 'none',
    fontSize: '1.125rem',
  },
  stepperWrapper: {
    width: '44rem',
    margin: '0 auto',
  },
  selector: {
    marginTop: '2rem',
    height: '3.5rem',
    width: '30rem',
    border: `1px solid ${BORDER_GREY}`,
    '&:hover': {
      borderColor: SECONDARY_BLUE_2,
    },
    '& fieldset': {
      border: 'none',
    },
  },
  divider: {
    margin: '0.75rem auto',
    width: '13.5rem',
    backgroundColor: BORDER_GREY,
  },
  input: {
    height: '3rem',
    width: '30rem',
    borderColor: `${BORDER_GREY} !important`,
    '&:hover': {
      borderColor: SECONDARY_BLUE_2,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: BORDER_GREY,
        height: '4rem',
      },
      '&:hover fieldset': {
        borderColor: SECONDARY_BLUE_2,
      },
    },
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '1.125rem',
  },
  cardContainer: {
    width: '100%',
  },
  container: {
    padding: '28px',
  },
  topMenuItem: {
    position: 'sticky',
    top: 0,
    borderBottom: `1px solid ${BORDER_GREY}`,
    backgroundColor: '#fbeff0',
    zIndex: '99999',
    pointerEvents: 'auto',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  newProjectButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    fontSize: '1.125rem',
    color: PRIMARY_PINK,
    fontWeight: 500,
    width: '100%',
    margin: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuList: {
    paddingTop: 0,
  },
  menuPaper: {
    maxWidth: '30rem',
    maxHeight: '20rem',
  },
}));

export const uploadModalWrapperStyles = {
  ...MODAL_WITHOUT_BACKGROUND,
  height: 'min-content',
  minHeight: '67vh',
  width: '60%',
};

export const useInstructionsSectionStyles = makeStyles()(() => ({
  instructionsContainer: {
    paddingTop: '2rem',
  },
  instruction: {
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  instructionCapture: {
    fontSize: '1.125rem',
    fontWeight: 500,
    textAlign: 'center',
  },
  instructionText: {
    textAlign: 'center',
  },
}));
