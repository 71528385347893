import { OrderFormWizardNavigator } from './WizardNavigatorView';
import React from 'react';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { HeaderAndContent } from 'components/Shared/QuestionsContact';

export const PlaceOrderBaseView = (props) => (
  <MiniDrawerWithContext header={props.label}>
    <HeaderAndContent>
      <OrderFormWizardNavigator>{props.children}</OrderFormWizardNavigator>
    </HeaderAndContent>
  </MiniDrawerWithContext>
);
