import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ConsultationsTeamFileAttachmentsTable } from 'components/PathologyConsultations/OrderForm/TeamFileAttachments';
import React from 'react';
import { DATE_REQUEST_FORMAT } from 'utilities/dates_and_times';
import moment from 'moment';

const boldStyle = { fontWeight: 'bold' };
const RenderConsultationAttribute = ({ attribute }) => {
  const { label, value } = attribute;

  return (
    <Box mb={2}>
      <Typography variant={'h6'} style={boldStyle}>
        {label}
      </Typography>
      <Box>
        <Typography variant="body1">{value ? value : 'N/A'}</Typography>
      </Box>
    </Box>
  );
};
export const PathologyConsultationInfoView = ({
  consultation,
  attachments,
}) => {
  if (!consultation) {
    return null;
  }

  let due_date_label = '';
  if (consultation.expected_due_date) {
    due_date_label = moment(consultation.expected_due_date).format(
      DATE_REQUEST_FORMAT,
    );
  }

  const basicConsultationAttributesToRender = [
    {
      value: consultation.name,
      label: 'Consultation Name',
    },
    {
      value: consultation.order?.name ? consultation.order.name : 'N/A',
      label: 'Histology Order',
    },
    {
      value: consultation.submitted_user.email,
      label: 'Submitted User',
    },
    {
      value: consultation.turnaround_days,
      label: 'Turnaround Time Requested (Days)',
    },
    {
      value: due_date_label,
      label: 'Expected Due Date',
    },
    {
      value: consultation.expected_number_of_slides,
      label: 'Expected Slide Count',
    },
    {
      value: consultation.expected_pathologist_pay_rate,
      label: 'Pathologist Payment (USD)',
    },
    { value: consultation.description, label: 'Description' },
    { value: consultation.specialities, label: 'Specialities' },
    {
      value: consultation.desired_pathology_expertise,
      label: 'Desired Pathology Expertise',
    },
    { value: consultation.species_text, label: 'Species' },
    {
      value: consultation.type_of_analysis_scoring_required,
      label: 'Type of Analysis Scoring Required',
    },
    { value: consultation.anticipated_findings, label: 'Anticipated Findings' },
    { value: consultation.control_animal, label: 'Control Animal' },
    { value: consultation.reference_papers, label: 'Reference Papers' },
    { value: consultation.special_instructions, label: 'Special Instructions' },
  ];

  return (
    <div>
      {basicConsultationAttributesToRender.map((attribute) => {
        return (
          <RenderConsultationAttribute
            key={attribute.label}
            attribute={attribute}
          />
        );
      })}
      <ConsultationsTeamFileAttachmentsTable attachments={attachments} />
    </div>
  );
};
