import {
  consultationLabOrderTurnaroundDueDatetimeFormatter,
  consultationReadyForPathologyConsultationsDatetimeFormatter,
  consultationTurnaroundDueDateTimeFormatter,
  consultationTurnaroundStartDateTimeFormatter,
  OrderNameFromConsultationFormatter,
  OrganizationNameFromConsultationFormatter,
} from 'components/PathologyConsultations/AgGridColumnsUtilities';
import { useConsultationActions } from 'components/PathologyConsultations/hooks';

export const PathologistWorklistColumnDefs = [
  {
    headerName: 'Consultation',
    field: 'name',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: false,
    checkboxSelection: true,
  },
  {
    headerName: 'State',
    field: 'state_label',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: false,
    width: 250,
  },
  {
    headerName: 'Lab Order',
    field: 'order',
    valueFormatter: OrderNameFromConsultationFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 250,
  },
  {
    headerName: 'Org.',
    field: 'organization_name',
    valueFormatter: OrganizationNameFromConsultationFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Actions',
    field: 'actions',
    cellRenderer: useConsultationActions,
    filter: 'agTextColumnFilter',
    autoHeight: true,
    width: 300,
  },
  //{
  //  headerName: "Accept Date",
  //  field: "pathologists_accepted_datetime",
  //  valueFormatter: consultationPathologistAcceptedDatetimeFormatter,
  //  filter: "agTextColumnFilter",
  //  width: 175,
  //},
  //{
  //  headerName: "Lab Start Date",
  //  field: "turnaround_start_datetime",
  //  valueFormatter: consultationLabOrderTurnaroundStartDateTimeFormatter,
  //  filter: "agTextColumnFilter",
  //  width: 150,
  //},
  {
    headerName: 'Ready Date',
    field: 'ready_for_pathology_consultation_datetime',
    valueFormatter: consultationReadyForPathologyConsultationsDatetimeFormatter,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Lab Due Date',
    field: 'turnaround_due_datetime',
    valueFormatter: consultationLabOrderTurnaroundDueDatetimeFormatter,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'PC Start Date',
    field: 'turnaround_start_datetime',
    valueFormatter: consultationTurnaroundStartDateTimeFormatter,
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    headerName: 'PC Due Date',
    field: 'turnaround_due_datetime',
    valueFormatter: consultationTurnaroundDueDateTimeFormatter,
    filter: 'agTextColumnFilter',
    width: 200,
  },
];
