import { MenuItem, FormControl, Select, Typography, Box } from '@mui/material';
import { useCustomDropdownStyles } from 'components/TeamOverview/components/ManageDataCard/styles';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getListItems } from 'components/TeamOverview/constants';
import {
  HISTOWIZ_PRIMARY_COLOR,
  MEDIUM_GREY,
  PRIMARY_PINK,
  PRIMARY_PINK_TRANSPARENT_8,
} from 'utilities/colors';

export const CustomDropdown = ({ value, setValue }) => {
  const { classes } = useCustomDropdownStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        variant={'outlined'}
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          width: '14rem',
          height: '2.5rem',
          backgroundColor: PRIMARY_PINK_TRANSPARENT_8,
          color: PRIMARY_PINK,
          border: `1px solid ${PRIMARY_PINK}`,
          '& .MuiSelect-icon': {
            color: PRIMARY_PINK,
          },
          '& .MuiSelect-select': {
            display: 'flex',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
            fontWeight: 500,
          },
          '& fieldset': {
            border: 'none',
          },
        }}
        value={value}
        onChange={handleChange}
        renderValue={(selected) => {
          const selectedItem = getListItems(selected).find(
            (item) => item.value === selected,
          );
          return (
            <Box className={classes.selectedItem}>
              {selectedItem.icon}
              <Typography marginLeft={1}>{selectedItem.label}</Typography>
            </Box>
          );
        }}
      >
        {getListItems(value).map((item) => {
          const isSelected = item.value === value;
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              selected={isSelected}
              sx={{
                '& .MuiButtonBase-root': {
                  backgroundColor: 'blue',
                },
                '& .MuiTypography-root': {
                  color: isSelected ? HISTOWIZ_PRIMARY_COLOR : MEDIUM_GREY,
                },
              }}
              classes={{
                root: classes.menuItemRoot,
                selected: classes.menuItemSelected,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 1,
                }}
              >
                {item.icon}
                <Typography>{item.label}</Typography>
              </Box>
              {isSelected && (
                <CheckIcon
                  fontSize="small"
                  color={isSelected ? 'primary' : 'black'}
                />
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
