import { Box, Grid } from '@mui/material';
import { BlocksActionHeaderTab } from 'components/BlocksList/BlocksActionPanel/BlocksActionHeaderTab';
import { BlocksImagesTab } from 'components/BlocksList/BlocksActionPanel/BlocksImagesTab';
import { ProcessingQueueTab } from 'components/BlocksList/BlocksActionPanel/ProcessingQueueTab';
import { blockActions } from 'components/BlocksList/constants';
import { useBlocksPageV2Styles } from 'components/BlocksList/styles';

export const BlocksActionPanel = ({
  processingQueue,
  setProcessingQueue,
  openBlocksModal,
  handleCreateOrder,
  activeTab,
  setActiveTab,
  selectedSamples,
  setSelectedSamples,
  selectedRows,
  selectedSlides,
}) => {
  const { classes } = useBlocksPageV2Styles();
  const tabs = Object.keys(blockActions);

  const getTabCounter = (tab) => {
    switch (tab) {
      case blockActions.imageData:
        return selectedRows.length;
      case blockActions.processingQueue:
        return processingQueue.length;
      default:
        return 0;
    }
  };

  return (
    <Grid item xs={4} className={classes.imagesWrapper}>
      <Box className={classes.tabs}>
        {tabs.map((tab) => (
          <BlocksActionHeaderTab
            tab={tab}
            key={tab}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            getTabCounter={getTabCounter}
          />
        ))}
      </Box>
      {activeTab === blockActions.imageData && (
        <BlocksImagesTab
          selectedSamples={selectedRows}
          selectedSlides={selectedSlides}
        />
      )}
      {activeTab === blockActions.processingQueue && (
        <ProcessingQueueTab
          processingQueue={processingQueue}
          setProcessingQueue={setProcessingQueue}
          openBlocksModal={openBlocksModal}
          handleCreateOrder={handleCreateOrder}
          selectedSamples={selectedSamples}
          setSelectedSamples={setSelectedSamples}
        />
      )}
    </Grid>
  );
};
