import Box from '@mui/material/Box';
import { Avatar, Button, Grid, Skeleton, Typography } from '@mui/material';
import { useTeamCardStyles } from 'components/TeamOverview/components/TeamCard/styles';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import { stringAvatar } from 'components/TeamOverview/utilities';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { useHistory } from 'react-router';
import { InviteTeamMembersModal } from 'components/Modals/InviteTeamMembersModal/InviteTeamMembersModal';

const UsersList = ({ classes, title, subtitle, users }) => {
  return (
    <Grid container className={classes.usersList}>
      <Grid item xs={5}>
        <Typography fontSize="1.125rem" fontWeight={600} marginY="0.75rem">
          {title}
        </Typography>
        <Typography fontSize="1.125rem" className={classes.darkGray}>
          {subtitle}
        </Typography>
      </Grid>
      <Grid item container xs={7}>
        <Grid item container className={classes.infoRow}>
          <Grid item xs={6}>
            <Typography fontWeight={500}>Name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>Email-id</Typography>
          </Grid>
        </Grid>
        <Box className={classes.usersContainer}>
          {users.map((user) => (
            <UserInfoRow
              email={user.email}
              firstName={user.first_name}
              lastName={user.last_name}
              classes={classes}
              key={user.uuid}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

const UserInfoRow = ({ firstName, lastName, email, classes }) => {
  const fullName = `${firstName} ${lastName}`;

  return (
    <Grid item container className={classes.infoRow}>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar {...stringAvatar(firstName, lastName)} />
          <Typography fontWeight={500} marginLeft={1.5}>
            {fullName}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} display="flex" alignItems="center">
        <Typography fontWeight={500}>{email}</Typography>
      </Grid>
    </Grid>
  );
};

export const TeamCard = ({ users, isLoading, team }) => {
  const { classes } = useTeamCardStyles();
  const [isModalOpen, setIsModalOpen] = useState();

  if (!users) return;

  const { admins, members } = users;

  const clearedMembers = members
    ? members.filter((member) => {
        return !admins?.find((admin) => admin.uuid === member.uuid);
      })
    : [];

  const handleInvite = () => {
    setIsModalOpen(true);
    // history.push(TEAM_USER_INVITE_URL);
  };

  return (
    <Card
      header={
        <Box
          paddingY="1rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={'100%'}
        >
          <Typography fontWeight={500} fontSize="1.125rem" height="max-content">
            {team ? (
              <>
                <Typography
                  component="span"
                  fontWeight={500}
                  fontSize="1.25rem"
                >
                  Team -{' '}
                </Typography>
                {team.name}
              </>
            ) : (
              <Skeleton
                height="1.6875rem"
                width="15rem"
                variant="rectangular"
              />
            )}
          </Typography>
          <Button variant="contained" onClick={handleInvite}>
            <AddIcon className={classes.plusIcon} />
            invite new member
          </Button>
        </Box>
      }
    >
      {isLoading ? (
        <Box className={classes.spinnerContainer}>
          <Spinner size={40} color="secondary" />
        </Box>
      ) : (
        <>
          {admins?.length ? (
            <UsersList
              classes={classes}
              title="Admin Users"
              subtitle="Admin users can add and remove users."
              users={admins}
            />
          ) : null}
          {clearedMembers.length ? (
            <UsersList
              classes={classes}
              title="Account Users"
              subtitle="Account users can access, edit all the files."
              users={clearedMembers}
            />
          ) : (
            <Box className={classes.spinnerContainer}>
              <Typography fontSize={'1.25rem'}>No regular users</Typography>
            </Box>
          )}
        </>
      )}
      <InviteTeamMembersModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </Card>
  );
};
