import { useBillingShippingStyles } from 'components/OrderForm/BillingShipping/styles';
import { CheckmarkProvider } from 'components/OrderForm/components/CheckmarkProvider';
import { SHIPPING_OPTIONS } from 'components/OrderForm/constants';
import { createBorderStylesForReactSelect } from 'components/OrderForm/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { billingShippingSelectors } from 'store/slices/billingShippingSlice/billingShippingSlice';

export const SelectedShippingMethodTab = ({
  onChange,
  shippingMethodSelected,
}) => {
  const { classes } = useBillingShippingStyles();
  const validated = useSelector(billingShippingSelectors.validated);
  const [isTouched, setTouched] = useState(false);
  const isCheckmarkDisplayed = validated || isTouched;
  const isValid = !!shippingMethodSelected;

  const styles = {
    control: (base) =>
      isCheckmarkDisplayed
        ? createBorderStylesForReactSelect(base, isValid)
        : base,
  };

  const onBlur = () => {
    setTouched(true);
  };

  return (
    <div className={classes.selectForm}>
      <CheckmarkProvider
        isValid={isValid}
        validated={isCheckmarkDisplayed}
        visible
      >
        <Select
          styles={styles}
          options={SHIPPING_OPTIONS}
          isMulti={false}
          onChange={onChange}
          onBlur={onBlur}
          value={shippingMethodSelected}
          clearable={false}
          className={classes.selectForm}
        />
      </CheckmarkProvider>
    </div>
  );
};
