import { baseCreateApi } from 'store/apis/baseCreateApi';
import { defaultActionAsyncHandler } from 'store/utilities';
import {
  createSlideGroup,
  deleteSlideGroup,
  setSlideGroups,
  slideGroupsSlice,
  updateSlideGroup,
  updateSlideGroupCache,
} from 'store/slices/slideGroupsSlice';

export const slideGroupsApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getSlideGroups: builder.query({
      query: () => ({
        url: 'slide_groups/',
        method: 'GET',
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler({ dispatch, queryFulfilled }, setSlideGroups);
      },
    }),
    getSlideGroup: builder.query({
      query: ({ slideGroupUUID }) => ({
        url: `slide_groups/${slideGroupUUID}`,
        method: 'GET',
      }),
    }),
    createSlideGroup: builder.mutation({
      query: (postParams) => ({
        url: 'slide_groups/',
        method: 'POST',
        body: postParams,
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        const { data: newSlide } = await queryFulfilled;

        dispatch(createSlideGroup(newSlide));
        dispatch(
          baseCreateApi.util.updateQueryData(
            'getSlideGroups',
            undefined,
            (draft) => {
              draft.push(newSlide);
            },
          ),
        );
      },
    }),
    addSlidesToGroup: builder.mutation({
      query: ({ slideGroupUUID, postParams }) => ({
        url: `slide_groups/${slideGroupUUID}/add/`,
        method: 'POST',
        body: postParams,
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        const { data: updatedSlideGroup } = await queryFulfilled;

        dispatch(updateSlideGroup(updatedSlideGroup));
        dispatch(
          baseCreateApi.util.updateQueryData(
            'getSlideGroups',
            undefined,
            (draft) => {
              slideGroupsSlice.caseReducers.updateSlideGroupCache(
                draft,
                updateSlideGroupCache(updatedSlideGroup),
              );
            },
          ),
        );
      },
    }),
    updateSlideGroup: builder.mutation({
      query: ({ slideGroupUUID, postParams }) => ({
        url: `slide_groups/${slideGroupUUID}/`,
        method: 'POST',
        body: postParams,
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        const { data: updatedSlide } = await queryFulfilled;

        dispatch(updateSlideGroup(updatedSlide));
        dispatch(
          baseCreateApi.util.updateQueryData(
            'getSlideGroups',
            undefined,
            (draft) => {
              slideGroupsSlice.caseReducers.updateSlideGroupCache(
                draft,
                updateSlideGroupCache(updatedSlide),
              );
            },
          ),
        );
      },
    }),
    deleteSlideFromGroup: builder.mutation({
      query: ({ slideGroupUUID, postParams }) => ({
        url: `slide_groups/${slideGroupUUID}/remove/`,
        method: 'POST',
        body: postParams,
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        const { data: updatedSlide } = await queryFulfilled;

        dispatch(updateSlideGroup(updatedSlide));
        dispatch(
          baseCreateApi.util.updateQueryData(
            'getSlideGroups',
            undefined,
            (draft) => {
              slideGroupsSlice.caseReducers.updateSlideGroupCache(
                draft,
                updateSlideGroupCache(updatedSlide),
              );
            },
          ),
        );
      },
    }),
    deleteSlideGroup: builder.mutation({
      query: (slideGroupUUID) => ({
        url: `slide_groups/${slideGroupUUID}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        const deletedSlide = {
          uuid: queryArgs,
        };

        dispatch(
          baseCreateApi.util.updateQueryData(
            'getSlideGroups',
            undefined,
            (draft) => {
              slideGroupsSlice.caseReducers.deleteSlideGroup(
                draft,
                deleteSlideGroup(deletedSlide),
              );
            },
          ),
        );
      },
    }),
  }),
});

export const {
  useGetSlideGroupsQuery,
  useLazyGetSlideGroupQuery,
  useLazyGetSlideGroupsQuery,
  useCreateSlideGroupMutation,
  useAddSlidesToGroupMutation,
  useDeleteSlideFromGroupMutation,
  useDeleteSlideGroupMutation,
  useUpdateSlideGroupMutation,
} = slideGroupsApi;
