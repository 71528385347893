import { Box, Typography } from '@mui/material';
import { Counter } from 'components/SlideGroups/components/Counter';
import React, { Fragment } from 'react';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';

export const SelectedSlidesSections = ({ classes, selectedSlides }) => {
  const slideNames = selectedSlides.map((slide) => {
    return slide.name;
  });

  return (
    <Fragment>
      <Box mb={2} display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h5" color={'gray'}>
          Selected
        </Typography>
        <Counter count={slideNames.length} containerClass={classes.counter} />
      </Box>
      <Box className={classes.slideGroupVerticalContainer}>
        {slideNames.map((name) => (
          <Box className={classes.selectedSlideRow}>
            <TextWithLimitedWidth key={name} value={name} />
          </Box>
        ))}
      </Box>
    </Fragment>
  );
};
