import { makeStyles } from 'tss-react/mui';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import { MODAL_WITHOUT_BACKGROUND } from 'components/SlideGroups/utilities/styles';
import {
  DATA_MANAGEMENT_BLUE,
  TRANSPARENT_BLACK_50,
  WARNING_RED,
} from 'utilities/colors';

export const useConfirmDeleteModalStyles = makeStyles()(() => ({
  stepperWrapper: {
    width: '28rem',
    margin: '0 auto',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '1.125rem',
  },
  alertBox: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  warning: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '1.125rem',
    marginBottom: '0.5rem',
  },
  cardContainer: {
    width: '100%',
  },
  container: {
    padding: '28px',
  },
  agreementText: {
    lineHeight: '1.5rem',
    verticalAlign: 'middle',
  },
  middleButtonsSection: {
    marginTop: '2.5rem',
    marginBottom: '3rem',
    display: 'flex',
    justifyContent: 'center',
  },
  bigButton: {
    padding: '0.75rem 2rem',
  },
  smallButton: {
    paddingInline: '3rem',
  },
  bottomButtonsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  confirmButton: {
    marginLeft: '1.5rem',
  },
  termsAndConditionsLink: {
    marginTop: '1rem',
    color: WARNING_RED,
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'center',
    fontSize: '1.125rem',
  },
  grayText: {
    color: TRANSPARENT_BLACK_50,
  },
}));

export const useOrderInfoStepStyles = makeStyles()(() => ({
  centeredContentContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orderInfo: {
    fontSize: '1rem',
    color: DATA_MANAGEMENT_BLUE,
  },
  table: {
    overflow: 'hidden',
    marginTop: '0.5rem',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  rowsScrollableContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    height: '43vh',
    overflowY: 'auto',
    ...VERTICAL_SCROLL_STYLE,
  },
  pagination: {
    marginBlock: '0.75rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  currentPage: {
    paddingInline: '0.5rem',
    marginRight: '0.5rem',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '2px',
  },
  pagesCount: {
    marginLeft: '0.5rem',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  buttonsSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  nextButton: {
    marginLeft: '1rem',
  },
}));

export const useOrderSlidesOverviewStyles = makeStyles()(() => ({
  orderInfo: {
    fontSize: '1rem',
    color: DATA_MANAGEMENT_BLUE,
  },
  noSlidesLabel: {
    textAlign: 'center',
    marginTop: '18vh',
  },
  centeredContentContainer: {
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowsScrollableContainer: {
    height: '62vh',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    overflowY: 'scroll',
    ...VERTICAL_SCROLL_STYLE,
  },
  pagination: {
    marginBlock: '0.75rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  currentPage: {
    paddingInline: '0.5rem',
    marginRight: '0.5rem',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '2px',
  },
  pagesCount: {
    marginLeft: '0.5rem',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  buttonsSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  nextButton: {
    marginLeft: '1rem',
  },
}));

export const useCommonReviewBoxStyles = makeStyles()(() => ({
  commonReview: {
    borderRadius: '0.625rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(223, 31, 31, 0.05)',
    border: '0.8px solid rgb(223, 31, 31)',
    padding: '0.75rem 2rem',
  },
  warningText: {
    marginTop: '0.125rem',
    color: 'rgba(223, 31, 31, 1)',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.1em',
  },
}));
export const ConfirmActionModalStyles = {
  ...MODAL_WITHOUT_BACKGROUND,
  height: 'min-content',
  maxWidth: '1200px',
  minHeight: '71vh',
  width: '75%',
};
