import { Close } from '@mui/icons-material';
import { useResizeableThumbnailStyles } from 'components/ThumbnailQRCode/styles';
import _ from 'lodash';
import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';

import { getFromLS, saveToLS } from 'components/OrderSlidesList/utilities';
import { THUMBNAIL_SWITCH_FIELD } from 'components/ImageViewer/constants';
import { CollapsedBar } from 'components/common/CollapsedBar/CollapsedBar';

const DEFAULT_THUMBNAIL_WIDTH = 150;
const DEFAULT_THUMBNAIL_HEIGHT = 550;
const DEFAULT_IS_RESIZEABLE = false;
const RESIZEABLE_THUMBNAIL_SIZE = 'RESIZEABLE_THUMBNAIL_SIZE';

export const ResizeableThumbnail = ({ thumbnail, isVisible = true }) => {
  const resizeableThumbnailsParamsFromLS = getFromLS(RESIZEABLE_THUMBNAIL_SIZE);
  const initialThumbnailParams = !_.isEmpty(resizeableThumbnailsParamsFromLS)
    ? resizeableThumbnailsParamsFromLS
    : {
        height: DEFAULT_THUMBNAIL_HEIGHT,
        width: DEFAULT_THUMBNAIL_WIDTH,
        isResizeable: DEFAULT_IS_RESIZEABLE,
      };

  const [
    resizeableThumbnailCurrentParams,
    setResizeableThumbnailCurrentParams,
  ] = useState(initialThumbnailParams);

  const [isThumbnailCollapsed, setIsThumbnailCollapsed] = useState(false);

  const { classes } = useResizeableThumbnailStyles();

  useEffect(() => {
    saveToLS(RESIZEABLE_THUMBNAIL_SIZE, resizeableThumbnailCurrentParams);
  }, [resizeableThumbnailCurrentParams]);

  if (!thumbnail || !isVisible) {
    return null;
  }

  const handleResizeableThumbnailClick = (shouldBeResizeable) => {
    setResizeableThumbnailCurrentParams((prevState) => {
      return {
        ...prevState,
        isResizeable: shouldBeResizeable,
      };
    });
  };

  const displayMinimizedThumbnail = () => {
    return isThumbnailCollapsed ? (
      <CollapsedBar
        onExpand={() => setIsThumbnailCollapsed(false)}
        preferencesField={THUMBNAIL_SWITCH_FIELD}
        title="Thumbnail"
      />
    ) : (
      <Paper className={classes.floatingPaper} elevation={1}>
        <img
          className={`${classes.floatingThumbnail} rounded-border`}
          src={thumbnail}
          alt={'Thumbnail'}
          onClick={() => handleResizeableThumbnailClick(true)}
        />
        <Box
          className={`${classes.closeBackground} hover-visible`}
          onClick={() => setIsThumbnailCollapsed(true)}
        >
          <Close className={classes.closeIcon} />
        </Box>
      </Paper>
    );
  };

  const displayResizeableThumbnail = () => {
    return (
      <Paper elevation={1}>
        <div
          className={classes.floatingResizeableThumbnail}
          onClick={() => handleResizeableThumbnailClick(false)}
        >
          {resizeableThumbnailCurrentParams && (
            <Resizable
              defaultSize={{
                width: resizeableThumbnailCurrentParams?.width,
                height: resizeableThumbnailCurrentParams?.height,
              }}
              minHeight={DEFAULT_THUMBNAIL_HEIGHT}
              maxHeight={window.screen.height * 0.8}
              onResizeStop={(e, direction, ref, delta) => {
                setResizeableThumbnailCurrentParams((prevState) => {
                  const updatedHeight = prevState.height + delta.height;
                  const updatedWidth = prevState.width + delta.width;

                  return {
                    ...prevState,
                    height: updatedHeight,
                    width: updatedWidth,
                  };
                });
              }}
              lockAspectRatio={true}
            >
              <img
                className={`${classes.image} rounded-border`}
                src={thumbnail}
                alt={'Thumbnail'}
              />
            </Resizable>
          )}
        </div>
      </Paper>
    );
  };

  const isThumbnailResizeable = resizeableThumbnailCurrentParams.isResizeable;

  return isThumbnailResizeable
    ? displayResizeableThumbnail()
    : displayMinimizedThumbnail();
};
