import { SECONDARY_WHITE, LIGHT_GREY } from 'utilities/colors';
import chroma from 'chroma-js';

const GREEN_BORDER = '1px solid green';
const RED_BORDER = '1px solid red';
const GREEN_SHADOW = '0 0 2px green';
const RED_SHADOW = '0 0 2px red';

export const inputStyles = (theme) => ({
  formLabel: {
    fontSize: '14px',
    display: 'inline-block',
    marginTop: '8px',
    marginBottom: '0.25rem',
  },
  button: {
    float: 'right',
  },
  formInput: {
    padding: '8px 10px',
    color: 'hsl(0,0%,20%)',
    fontSize: '1rem',
    width: '100%',
    display: 'block',
    borderRadius: '0.25rem',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    backgroundColor: '#f8f9fa',
    resize: 'vertical',

    '&:disabled': {
      backgroundColor: LIGHT_GREY,
      cursor: 'not-allowed',
    },
  },
  error: {
    border: RED_BORDER,
    boxShadow: RED_SHADOW,
    paddingRight: '1.6rem',
  },
  validated: {
    border: GREEN_BORDER,
    boxShadow: GREEN_SHADOW,
    paddingRight: '1.6rem',
  },
  errorFeedback: {
    color: 'red',
    marginTop: '0.25rem',
    fontSize: '14px',
  },
  requiredAsterisk: {
    fontSize: '14px',
    color: 'red',
    display: 'inline-block',
    paddingLeft: '.25rem',
  },
  inputWrapper: {
    width: '100%',
  },
  alignTop: {
    top: '0.3rem',
    transform: 'unset',
  },
  disabledCursor: {
    cursor: 'not-allowed',
  },
  helpIcon: {
    marginLeft: '0.25rem',
    marginTop: '0.25rem',
    width: '1rem',
    height: '1rem',
    color: theme.palette.primary.main,
  },
});

export const styles = () => {
  return {
    formLabel: {
      fontSize: '14px',
      color: 'black',
      display: 'inline-block',
      marginTop: '1rem',
      marginBottom: '0.25rem',
    },
    errorFeedback: {
      color: 'red',
      marginTop: '0.25rem',
      fontSize: '14px',
    },
    requiredAsterisk: {
      fontSize: '14px',
      color: 'red',
      display: 'inline-block',
      marginTop: '8px',
      paddingLeft: '.25rem',
      marginBottom: '0.25rem',
    },
    requiredLabel: {
      fontSize: '14px',
      display: 'inline-block',
      marginTop: '8px',
      marginBottom: '0.25rem',
    },
  };
};

export const selectStyles = {
  control: (base) => ({
    ...base,
    background: '#f8f9fa',
  }),
};

export const controlValidationStyles = (isValid) => ({
  border: isValid ? GREEN_BORDER : RED_BORDER,
  '&:hover': {
    border: isValid ? GREEN_BORDER : RED_BORDER,
  },
  boxShadow: isValid ? GREEN_SHADOW : RED_SHADOW,
  paddingRight: '1.3rem',
});

export const controlDisabledStyles = {
  background: LIGHT_GREY,
  cursor: 'not-allowed',
};

export const baseSelectStyles = (isValid, isDisplayValidation, isDisabled) => ({
  control: (base) => {
    const styles = {
      ...base,
      background: '#f8f9fa',
    };

    if (isDisabled) {
      return {
        ...styles,
        ...controlDisabledStyles,
      };
    }

    if (isDisplayValidation) {
      return {
        ...styles,
        ...controlValidationStyles(isValid),
      };
    }

    return styles;
  },
  // Use this to style multi-select and make it not be this super boring gray
  multiValue: (styles) => {
    const color = chroma('orange');
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
});

export const createBorderStylesForReactSelect = (base, value, name) => {
  const isValid = Array.isArray(value) ? !!value.length : !!value;
  switch (name) {
    case 'specialInstructionsAttachment':
      return {
        ...base,
        background: '#f8f9fa',
        border: GREEN_BORDER,
        '&:hover': {
          border: GREEN_BORDER,
        },
        boxShadow: GREEN_SHADOW,
        paddingRight: '1.3rem',
      };
    default:
      return {
        ...base,
        background: '#f8f9fa',
        border: isValid ? GREEN_BORDER : RED_BORDER,
        '&:hover': {
          border: isValid ? GREEN_BORDER : RED_BORDER,
        },
        boxShadow: isValid ? GREEN_SHADOW : RED_SHADOW,
        paddingRight: '1.3rem',
      };
  }
};

export const createBorderStylesForMUI = ({ label }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: '1.3rem',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: label ? GREEN_BORDER : RED_BORDER,
    boxShadow: label ? GREEN_SHADOW : RED_SHADOW,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: label ? GREEN_BORDER : RED_BORDER,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: label ? GREEN_BORDER : RED_BORDER,
  },
});

export const checkMarkStyles = {
  icon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '.3rem',
    fontSize: 'large',
    width: '1.25rem',
    height: '1.25rem',
  },
  top: {
    top: '.3rem',
  },
  container: {
    position: 'relative',
  },
};

export const projectSelectionStyles = {
  errorFeedback: {
    color: 'red',
    marginTop: '0.25rem',
  },
  projectTextInput: {
    position: 'relative',
    marginTop: 0,
    backgroundColor: SECONDARY_WHITE,
  },
  formLabel: {
    fontSize: '14px',
    display: 'inline-block',
  },
  requiredAsterisk: {
    fontSize: '14px',
    color: 'red',
    display: 'inline-block',
    marginTop: '1rem',
    paddingLeft: '.25rem',
    marginBottom: '.25rem',
  },
  projectSelectionLabel: {
    marginBottom: '-1rem',
  },
  icon: {
    top: '2.83rem',
  },
  helperText: {
    marginLeft: '0px',
  },
};
