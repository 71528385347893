import React, { Fragment } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DefaultDialog } from 'components/Modals/DefaultDialog';
import { useDeleteModalStyles } from './styles';

export const DeleteModal = ({
  label = 'Are you sure you want to delete this record?',
  open,
  handleDeleteResult,
  onClose,
  children,
}) => {
  const { classes } = useDeleteModalStyles();

  if (!open) return null;

  const modalActions = () => {
    return (
      <div className={classes.actions}>
        <Button color="primary" onClick={onClose}>
          No
        </Button>
        <Button color="secondary" onClick={handleDeleteResult}>
          Yes
        </Button>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <DefaultDialog open={true} onClose={onClose} actions={modalActions}>
        <Box width={'20vw'}>
          <Typography variant="h6" align={'center'}>
            {label}
          </Typography>
          <Box m={2}>
            <Typography align={'center'}>{children}</Typography>
          </Box>
        </Box>
      </DefaultDialog>
    );
  };

  return <Fragment>{renderModal()}</Fragment>;
};
