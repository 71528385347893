import React from 'react';
import {
  DEFAULT_MODAL_SIZE,
  LARGE_MODAL_SIZE,
  SIMPLE_MODAL_SIZE,
} from 'components/Modals/constants';
import { IconButton, Modal } from '@mui/material';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';

const overflowStyle = {
  overflow: 'auto',
};
const getBaseStyles = (theme) => {
  return {
    position: 'absolute',
    'z-index': 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    ...VERTICAL_SCROLL_STYLE,
  };
};

const defaultModalStyles = (theme) => ({
  modalPaper: {
    ...getBaseStyles(theme),
  },
  limitedHeight: {
    maxHeight: '93vh',
    overflowY: 'auto',
    padding: '0.5rem',
  },
});

const largeModalStyles = (theme) => ({
  modalPaper: {
    width: theme.spacing(70),
    ...getBaseStyles(theme),
  },
});

const simpleModalStyles = (theme) => ({
  modalPaper: {
    ...getBaseStyles(theme),
  },
});

const customModalStyles = (theme, width) => ({
  modalPaper: {
    width,
    ...getBaseStyles(theme),
  },
});

const getStyles = (size, width) => {
  const styles = width
    ? (theme) => customModalStyles(theme, width)
    : {
        [DEFAULT_MODAL_SIZE]: defaultModalStyles,
        [LARGE_MODAL_SIZE]: largeModalStyles,
        [SIMPLE_MODAL_SIZE]: simpleModalStyles,
      }[size];

  return styles || defaultModalStyles;
};

export const DefaultModal = ({
  onClose,
  open,
  size = DEFAULT_MODAL_SIZE,
  children,
  width = null,
  isLimitedHeight,
  styles,
  closeIcon,
  ...props
}) => {
  const useStyles = makeStyles()(getStyles(size, width));
  const { classes } = useStyles();

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onClose}
      width={width}
      style={overflowStyle}
      {...props}
    >
      <div
        className={`${classes.modalPaper} ${
          isLimitedHeight ? classes.limitedHeight : ''
        }`}
        style={styles}
      >
        {closeIcon && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
              color: '#1C1B1F',
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {children}
      </div>
    </Modal>
  );
};
