import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import 'css/dataGrid.css';

import { getOrderByUUID, updateOrder } from 'services/resources/orders';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import { DemoUserWarnIfEmailMatchDemo } from 'components/OrderForm/components/DemoUserOrderWarning';
import { Button, Typography } from '@mui/material';
import { LAST_ORDER_FORM_PAGE } from 'components/OrderForm/constants';
import { useTitle } from 'components/utilities/hooks/useTitle';
import {
  FETCH_ORDER_ERROR_MESSAGE,
  GETTING_DATA_ERROR,
  UPDATE_ORDER_ERROR_MESSAGE,
} from 'constants/errorMessages';
import {
  useSubmitIFSlides,
  useSubmitIHCSlides,
  useSubmitRegularSlides,
  useSubmitSpecialStains,
} from 'components/OrderForm/SlideServicesForm/apiHelper';
import {
  checkAllRowsValid,
  checkIfImageAnalysisRequestedColumnNeeded,
  checkIfPathologyConsultationRequestedColumnNeeded,
  getColumns,
  serializeSlideServicesRowDataFromAPIResponse,
  useGetSlidesData,
} from 'components/OrderForm/SlideServicesForm/utilities';
import { AgGridReact } from 'ag-grid-react';
import { CHANGES_SAVED_MESSAGE } from 'components/OrderForm/SlideServicesForm/constants';
import {
  agGridDefaultColDef,
  handleFillHandleDoubleClicked,
} from 'components/utilities/grid';

import {
  AdditionalSlideServicesFootnote,
  SlideServicesExportToExcel,
} from 'components/OrderForm/SlideServicesForm/components';

import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useOrderSlidesViewStyles } from './SlideServicesViewStyles';
import { useSimpleModal } from 'components/OrderForm/components/hooks';
import { getOrderStatus } from 'components/OrdersList/utilities/helpers';
import { UNSUBMITTED_ORDER } from 'components/OrdersList/constants';
import { makeColumnsNonEditable } from 'components/OrderForm/SamplesFormV1/utilities';
import { WarningModal } from 'components/OrderForm/components/WarningModal';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';

export const OrderSlidesView = (props) => {
  useTitle('Place Order - Slide Quantity Services');

  const { showError, showSuccess } = useSnackbar();
  const match = useRouteMatch();
  const userDetails = useSelector(userDetailsSelector);
  const orderUUID = match.params['orderUUID'];
  const [order, setOrder] = useState();

  const history = useHistory();
  const { email } = props;

  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState(null);
  const [isAllRowsValid, setIsAllRowsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBlocked, setIsEditBlocked] = useState(false);

  const [gridApi, setGridApi] = useState(null);
  // use gridColumnAPI to potentially resize columns in the future
  const [, setGridColumnApi] = useState(null);

  const getSlidesData = useGetSlidesData();
  const submitRegularSlides = useSubmitRegularSlides();
  const submitIHCSlides = useSubmitIHCSlides();
  const submitSpecialStains = useSubmitSpecialStains();
  const submitIFSlides = useSubmitIFSlides();

  const { isOpen, open, close } = useSimpleModal();

  const { classes } = useOrderSlidesViewStyles();

  useEffect(() => {
    if (!orderUUID) {
      return;
    }

    const getOrder = getOrderByUUID(orderUUID);
    const errorMessage = FETCH_ORDER_ERROR_MESSAGE;

    getOrder
      .then((orderDetails) => {
        setOrder(orderDetails);

        document.title = `${orderDetails.name} Slide Services List - HistoWiz`;
      })
      .catch(() => showError(errorMessage));
  }, [orderUUID]);

  useEffect(() => {
    if (!order || !userDetails.email) {
      return;
    }
    //TODO: uncomment after changes in business logic
    // const orderStatus = getOrderStatus(order);
    //
    // if (!userDetails.isStaff && orderStatus !== UNSUBMITTED_ORDER) {
    //   setIsEditBlocked(true);
    //   open();
    // }
  }, [order, userDetails]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    getSlidesData(orderUUID)
      .then((data) => {
        if (!isMounted) return;

        // go through the slides data response and see if we need to make dynamic columns
        // for image analysis or pathology consultation requested
        const isPathologyConsultationColumnNeeded =
          checkIfPathologyConsultationRequestedColumnNeeded(data.simpleSamples);
        const isImageAnalysisColumnNeeded =
          checkIfImageAnalysisRequestedColumnNeeded(data.simpleSamples);

        if (isMounted) {
          const newColumns = getColumns(
            isPathologyConsultationColumnNeeded,
            isImageAnalysisColumnNeeded,
          );
          setColumns(newColumns);
        }

        const allRows = serializeSlideServicesRowDataFromAPIResponse(data);
        setRows(allRows);
        setIsLoading(false);
      })
      .catch(() => showError(GETTING_DATA_ERROR));

    return () => {
      isMounted = false;
    };
  }, [orderUUID]);

  useEffect(() => {
    let isMounted = true;

    if (rows) {
      checkAllRowsValid(rows).then((validResults) => {
        if (isMounted) {
          setIsAllRowsValid(validResults.length === rows.length);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [rows]);

  const onGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const handleSaveAndContinue = (e) => {
    const isSaveBtn = e.currentTarget.getAttribute('id') === 'saveBtn';
    setIsSubmitting(true);

    const updatedRows = getAllUpdatedGridRows();

    Promise.all([
      submitRegularSlides(updatedRows, orderUUID),
      submitIHCSlides(updatedRows, orderUUID),
      submitSpecialStains(updatedRows, orderUUID),
      submitIFSlides(updatedRows, orderUUID),
    ])
      .then(() => {
        if (isSaveBtn) {
          showSuccess(CHANGES_SAVED_MESSAGE);
          setIsSubmitting(false);
        } else {
          const url = `/forms/order/${orderUUID}/prices/`;
          history.push(url);
        }
      })
      .catch(() => showError(UPDATE_ORDER_ERROR_MESSAGE));

    updateOrder(orderUUID, {
      last_order_form_page: LAST_ORDER_FORM_PAGE.SELECT_SLIDES,
    }).catch(() => showError(UPDATE_ORDER_ERROR_MESSAGE));
  };

  const getAllUpdatedGridRows = () => {
    // ag-grid doesnt update rows as it updates state, so here right before
    // submitting we need to get the latest rows from the grid
    let rowData = [];
    gridApi.forEachNode((node) => rowData.push(node.data));

    // in case a grouping was done, we want to remove those groupings out
    const rowDataSerialized = rowData.filter((row) => !!row);
    return rowDataSerialized;
  };

  const columnsToDisplay = isEditBlocked
    ? makeColumnsNonEditable(columns)
    : columns;

  return (
    <div className={classes.gridTable}>
      <div className={classes.header}>
        <Typography className={classes.headerTextStyle}>
          To update the number of requested slides for each sample, please{' '}
        </Typography>
        <Typography className={classes.editableTextStyle} display={'inline'}>
          <span>
            {' '}
            <b> double-click</b> on the colored cells.
          </span>
        </Typography>
        <DemoUserWarnIfEmailMatchDemo email={email} />
        {columns && (
          <div className={classes.buttonsContainer}>
            <div className={classes.topButton}>
              <Button
                onClick={handleSaveAndContinue}
                variant="contained"
                color={'primary'}
                disabled={!isAllRowsValid || isSubmitting}
              >
                <SubmittingIcon submitting={isSubmitting} />
                Save & Continue
              </Button>
            </div>
          </div>
        )}
      </div>
      <DemoUserWarnIfEmailMatchDemo email={email} />
      <div className={`ag-theme-balham ${classes.grid}`}>
        <AgGridReact
          onGridReady={onGridReady}
          rowData={rows}
          columnDefs={columnsToDisplay}
          rowSelection={'single'}
          enableFillHandle={true}
          fillHandleDirection={'y'}
          enableRangeSelection={true}
          singleClickEdit={false}
          defaultColDef={agGridDefaultColDef}
          suppressCopyRowsToClipboard={true}
          onCellDoubleClicked={handleFillHandleDoubleClicked}
          stopEditingWhenGridLosesFocus={true}
        />
      </div>
      <SlideServicesExportToExcel
        gridApi={gridApi}
        isLoading={isLoading}
        order={order}
      />
      <WarningModal isOpen={isOpen} handleClose={close} />
      <AdditionalSlideServicesFootnote />
    </div>
  );
};
