import { Box, Button, Typography } from '@mui/material';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import { CommonReviewBox } from 'components/TeamOverview/components/ConfirmActionModal/CommonReviewBox';
import {
  ConfirmActionModalStyles,
  useConfirmDeleteModalStyles,
} from 'components/TeamOverview/components/ConfirmActionModal/styles';
import { DeleteIcon } from 'images/icons/DeleteIcon';
import React from 'react';

export const BlocksModalWrapper = ({
  children,
  open,
  handleClose,
  actionType,
  disabled,
  text,
}) => {
  const { classes } = useConfirmDeleteModalStyles();

  return (
    <DefaultModal
      open={open}
      onClose={handleClose}
      styles={ConfirmActionModalStyles}
      closeIcon
    >
      <Card
        cardClass={classes.cardContainer}
        header={
          <Box padding="1.375rem">
            <Typography className={classes.cardTitle}>
              CONFIRM {actionType}
            </Typography>
          </Box>
        }
      >
        <Box paddingX="1.75rem" paddingBottom="2rem" paddingTop="1rem">
          <Box className={classes.alertBox}>
            <CommonReviewBox text={text} Icon={DeleteIcon} showWeight={false} />
          </Box>
          {children}
          <Box className={classes.middleButtonsSection}>
            <Button
              variant="outlined"
              color="pink"
              className={classes.bigButton}
              onClick={handleClose}
            >
              cancel action
            </Button>
            <Button
              variant="contained"
              color="pink"
              className={`${classes.bigButton} ${classes.confirmButton}`}
              disabled={disabled}
              //onClick={handleSubmit}
            >
              confirm {actionType}
            </Button>
          </Box>
        </Box>
      </Card>
    </DefaultModal>
  );
};
