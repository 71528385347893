import { loadStripe } from '@stripe/stripe-js';

export const OvernightShippingConstant = 'Priority Overnight';
export const OvernightShippingValue = 'fedex_priority_overnight';
export const TwoDayShippingConstant = 'Two-Day Shipping';
export const TwoDayShippingValue = 'fedex_2_day_am';
export const ShippingSpeedOptions = [
  {
    value: OvernightShippingValue,
    label: OvernightShippingConstant,
  },
  {
    value: TwoDayShippingValue,
    label: TwoDayShippingConstant,
  },
];

export const DEFAULT_BILLING_CONTACT_VALUES = {
  billingContactName: '',
  billingContactEmail: '',
  billingContactPhoneNumber: '',
  billingContactPONumber: '',
};
export const BLANKET_PURCHASE_ORDER_TAB_VALUE = 1;
export const EXISTING_BILLING_INFO_TAB_VALUE = 3;
export const CREDIT_CARD_TAB_VALUE = 2;
export const PO_NUMBER_INFO_TAB_VALUE = 0;

export const DEFAULT_PACKAGE_WIDTH = 10;
export const DEFAULT_PACKAGE_HEIGHT = 10;
export const DEFAULT_PACKAGE_LENGTH = 10;
export const DEFAULT_PACKAGE_WEIGHT = 3;

export const stripePromise = loadStripe('pk_live_uxMd5i9lKM32RYBBbPqSkkZU');

export const GENERATE_ADDITIONAL_LABEL = 'Generate additional label';
export const CREATE_FED_EX_LABEL = 'Create FedEx Label';
export const LABEL_CREATED_MESSAGE = 'FedEx Label was Created!';

export const UPDATE_PO_ERROR_MESSAGE = 'Error updating PO attachment';
export const UPDATE_PO_SUCCESS_MESSAGE = 'PO attachment updated';

export const SHIPPO_ERROR = 'Shippo Error: ';
export const SHIPPO_ERROR_MESSAGE = 'An error occurred during Shippo request!';
