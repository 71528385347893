import moment from 'moment';
import { DATE_REQUEST_FORMAT_WITH_SLASH_DELIMITER } from 'utilities/dates_and_times';
import {
  ALL_ORDERS_TAB_VALUE,
  ARCHIVED_ORDERS_TAB_VALUE,
  COMPLETED_ORDERS_TAB_VALUE,
  CONTRIBUTED_ORDERS_TAB_VALUE,
  DATA_MANAGEMENT_HISTORY_TABS_IDS,
  EXPORTED_ORDERS_TAB_VALUE,
  IN_PROGRESS_ORDERS_TAB_VALUE,
  ORDER_TABS_IDS,
  UNSUBMITTED_ORDERS_TAB_VALUE,
} from 'components/PathologyMap/constants/common';
import {
  BYTES,
  BYTES_IN_KB,
  CREATED_AT_SORT_FIELD,
  IF_SLIDES_COUNT_SORT_FIELD,
  PROJECT_NAME_SORT_FIELD,
  SIZES,
  SLIDES_COUNT_SORT_FIELD,
  SlideTypes,
} from 'components/UserProfile/constants';
import {
  BILLING,
  ORDER_INFO,
  PRICE_PREVIEW,
  REVIEW,
  SAMPLES,
  SCIENCE_INFO,
  SELECT_SLIDES,
} from 'components/OrderForm/constants';
import {
  IF_VIEWER_URL,
  ORDER_CHART_URL,
  ORDERS_SLIDE_LIST_URL,
  PLACE_ORDER_INFO_URL,
} from 'constants/urls';
import {
  ORDER_CANCELLED_TRACKER_STATE,
  unsubmittedOrderStates,
} from 'components/OrdersList/constants';
import {
  IA_ATTACHMENT,
  PATHOLOGY_CONSULTATION_ATTACHMENT,
} from 'components/OrderForm/SlideServicesForm/constants';
import { Button } from '@mui/material';
import React from 'react';
import { FILES_COM_PREFIX } from 'components/TeamOverview/constants';
import { nameSorter } from 'utilities/general';

export const getIframeUrl = (uploadKey) =>
  `https://jssdk.files.com/f-e/${uploadKey}?site=histowiz.files.com`.replace(
    FILES_COM_PREFIX,
    '',
  );

export const filterOrders = (orders, selectedProject) =>
  orders?.length
    ? orders
        ?.filter((order) => order.project.uuid === selectedProject.uuid)
        .sort(nameSorter)
    : [];

export const buildSubmittedUserLabel = (user, createdAt) => {
  const submittedUser = user ? `by ${user.first_name} ${user.last_name}` : '';

  const dateFormatted = moment(createdAt).format(
    DATE_REQUEST_FORMAT_WITH_SLASH_DELIMITER,
  );

  return `${dateFormatted} ${submittedUser}`;
};

export const buildSlideCommentLabel = (comment) => {
  const slide = comment?.slide;
  if (!slide) return '';

  const orderName = slide.order?.name ?? '';
  const slideName = slide.name ?? '';
  const projectName = comment.project_name ?? '';

  return (
    <span>
      {projectName} / {slideName} / <b>{orderName}</b>
    </span>
  );
};

export const buildOrderCommentLabel = (comment) => {
  const orderName = comment?.order?.name ?? '';
  const projectName = comment?.project_name ?? '';

  return (
    <span>
      {projectName} / <b>{orderName}</b>
    </span>
  );
};

export const getSelectedOrders = (selectedTab, splittedOrders, allOrders) => {
  return {
    [ORDER_TABS_IDS[ALL_ORDERS_TAB_VALUE]]: allOrders,
    [ORDER_TABS_IDS[COMPLETED_ORDERS_TAB_VALUE]]: splittedOrders.finishedOrders,
    [ORDER_TABS_IDS[IN_PROGRESS_ORDERS_TAB_VALUE]]:
      splittedOrders.inProgressOrders,
    [ORDER_TABS_IDS[UNSUBMITTED_ORDERS_TAB_VALUE]]:
      splittedOrders.unsubmittedOrders,
  }[selectedTab];
};

export const getSelectedHistoryTabs = (selectedTab, ordersHistory) =>
  ({
    [DATA_MANAGEMENT_HISTORY_TABS_IDS[ALL_ORDERS_TAB_VALUE]]:
      ordersHistory.allEvents,
    [DATA_MANAGEMENT_HISTORY_TABS_IDS[ARCHIVED_ORDERS_TAB_VALUE]]:
      ordersHistory.archivedEvents,
    [DATA_MANAGEMENT_HISTORY_TABS_IDS[CONTRIBUTED_ORDERS_TAB_VALUE]]:
      ordersHistory.contributedEvents,
    [DATA_MANAGEMENT_HISTORY_TABS_IDS[EXPORTED_ORDERS_TAB_VALUE]]:
      ordersHistory.exportedEvents,
  }[selectedTab]);

export const transformDataUsage = (
  dataUsage,
  minimalSize = BYTES,
  decimals = 2,
) => {
  if (!+dataUsage) return '0';

  const measurementStep = BYTES_IN_KB;
  const dm = decimals < 0 ? 0 : decimals;
  const minimalSizeIndex = SIZES.indexOf(minimalSize);
  const i = Math.floor(Math.log(dataUsage) / Math.log(measurementStep));

  const sizeValue = parseFloat(
    (dataUsage / Math.pow(measurementStep, i)).toFixed(dm),
  );
  const sizeLabel = SIZES[i + minimalSizeIndex] ?? SIZES[SIZES.length - 1];

  return `${sizeValue} ${sizeLabel}`;
};

export const ordersSorter = (sortBy, a, b) => {
  const sorts = {
    [CREATED_AT_SORT_FIELD]: new Date(b.created) - new Date(a.created),
    [PROJECT_NAME_SORT_FIELD]: b.project?.name?.localeCompare(a.project?.name),
    [SLIDES_COUNT_SORT_FIELD]: b.slides_count - a.slides_count,
    [IF_SLIDES_COUNT_SORT_FIELD]: b.if_slides_count - a.if_slides_count,
  };

  return sorts[sortBy];
};

export const buildTeamInfo = (team) => {
  if (!team) return '';

  const { city, country, state, organization } = team;
  const teamNameFragment = organization?.name?.length ? organization.name : '';
  const teamLocationParts = [city, state, country];
  const teamLocationFragment = teamLocationParts
    .filter((fragment) => fragment?.length)
    .join(', ');

  return teamNameFragment.length && teamLocationFragment.length
    ? `${teamNameFragment} - ${teamLocationFragment}`
    : teamNameFragment + teamLocationFragment;
};

export const filterOrdersByTeam = (orders = [], teams) => {
  if (!teams) return orders;

  return orders.filter((order) =>
    teams.find((team) => team?.uuid === order.team?.uuid),
  );
};

export const matchLastStepToUrl = {
  [ORDER_INFO]: '',
  [SAMPLES]: 'samples/',
  [SCIENCE_INFO]: 'samples_science/',
  [SELECT_SLIDES]: 'slides/',
  [PRICE_PREVIEW]: 'prices/',
  [BILLING]: 'billing/',
  [REVIEW]: 'review/',
};

export const buildLatestPageOrderFormLink = (order) =>
  order.highest_order_form_page
    ? `${PLACE_ORDER_INFO_URL}${
        matchLastStepToUrl[order.highest_order_form_page]
      }`
    : PLACE_ORDER_INFO_URL;

const buildSlideViewerUrl = (slideType) => {
  return {
    [SlideTypes.IF]: () => IF_VIEWER_URL.replace(':slideUUID', null),
    [SlideTypes.IHC]: () => ORDERS_SLIDE_LIST_URL,
  }[slideType]();
};

export const onSlidesClick = (order, history, slideType) => {
  const viewerUrl = buildSlideViewerUrl(slideType).replace(
    ':orderUUID',
    order.uuid,
  );
  history.push(viewerUrl);
};

export const onDoubleClick = (order, history) => {
  const isCancelledOrder = order.state === ORDER_CANCELLED_TRACKER_STATE;

  if (isCancelledOrder) return null;

  const isUnsubmittedOrder = unsubmittedOrderStates.includes(order.state);

  if (isUnsubmittedOrder) {
    const lastOrderFormStepUrl = buildLatestPageOrderFormLink(order).replace(
      ':orderUUID',
      order.uuid,
    );
    history.push(lastOrderFormStepUrl);
  }
};

export const getHeaderColumns = (classes) => [
  { xs: 0.75, text: 'DATE' },
  { xs: 1.25, text: 'ACTION PERFORMED' },
  { xs: 1, text: 'ORDERED BY' },
  { xs: 0.75, text: 'USED' },
  { xs: 1.75, text: 'PROJECT/ORDER' },
  { xs: 0.75, text: 'STATUS' },
  { xs: 1.5, text: 'CREATED DATE' },
  {
    xs: 0.75,
    text: 'SLIDES',
    className: `${classes.column} ${classes.centeredCell}`,
  },
  { xs: 0.75, text: 'IF SLIDES' },
  { xs: 1, text: 'PATH REPORT' },
  { xs: 0.75, text: 'IA REPORT' },
  { xs: 1, text: 'SLIDES CHART' },
];

const getAttachmentLink = (name, order, attachmentType, onClickAction) => {
  if (order[attachmentType]) {
    return (
      <Button size="small" onClick={onClickAction} color={'primary'}>
        {name}
      </Button>
    );
  }

  return '-';
};

export const getPathologyLink = (order, label = 'Link') => {
  return getAttachmentLink(
    label,
    order,
    PATHOLOGY_CONSULTATION_ATTACHMENT,
    () => {
      window.open(order.pathology_consultation_attachment, '_blank');
    },
  );
};

export const getIAReportLink = (order, label = 'Link') => {
  return getAttachmentLink(label, order, IA_ATTACHMENT, () => {
    window.open(order.image_analysis_attachment, '_blank');
  });
};

export const getIAChartLink = (order, history, label = 'Link') => {
  return getAttachmentLink(label, order, IA_ATTACHMENT, () => {
    history.push(ORDER_CHART_URL.replace(':orderUUID', order.uuid));
  });
};

export const getOrderParamsForUpload = (teamUUID) => ({
  last_order_form_page: 'upload_files',
  special_instructions: '',
  purpose_of_study: '',
  cutting_instructions: 'n/a',
  ihc_optimization_type: '',
  materials_at_histowiz: false,
  fixation_time_hours: 1,
  additional_steps_per_sample: 0,
  sections_per_slide: 1,
  section_thickness_microns: 4,
  expected_staining_pattern: '',
  experimental_treatment: '',
  expected_phenotype: '',
  cell_target: '',
  fixative_uuid: '31c70518-c363-4fa1-a837-720518fa9cd7',
  turnaround_days: 10,
  save_project_defaults: false,
  region_of_interest: '',
  team_uuid: teamUUID,
  species_uuid: 'c3e22036-e1a5-4ebb-8088-24a966552adc',
  state: 'client_uploading_slides',
});

export const checkIfFilesUploadLink = (link) => link.includes('files');
