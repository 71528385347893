import React, { Fragment, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { SimpleSlideView } from 'components/ImageViewer/SimpleSlideImageView';
import { FavoriteSlideIconButton } from 'components/utilities/FavoriteSlide/FavoriteSlideIconButton';
import { DEFAULT_FAVORITE_CELL_ICON_BUTTON_PROPS } from 'components/utilities/FavoriteSlide/constants';
import { ShowSpecialInstructions } from 'components/QCViewer/QCViewerComponents';
import { ViewAttachmentsModal } from 'components/ImageViewer/ViewAttachmentsModal';
import { SlideName } from 'components/SlideName/SlideName';

export const MULTI_SLIDE_HEIGHT_VH = 50;

const useStyles = makeStyles()((theme) => ({
  multiSlideWrapper: {
    height: `${MULTI_SLIDE_HEIGHT_VH}vh`,
    margin: `${theme.spacing(2)} 0`,
  },
}));

const MultiSlideWrapper = ({
  children,
  slide,
  setIsAttachmentsModalVisible,
  isFavoriteSlideFeatureEnabled,
  isAttachmentsViewerFeatureEnabled,
}) => {
  const { classes } = useStyles();
  const { uuid, name, order } = slide;

  const isSpecialInstructionExist =
    order?.special_instructions_attachment ||
    order?.special_instructions?.length;

  return (
    <Fragment key={uuid}>
      <Grid item xs={6} key={uuid}>
        <div className={classes.multiSlideWrapper}>
          <Grid container alignItems="center" justifyContent="space-between">
            <SlideName name={name}>
              {isFavoriteSlideFeatureEnabled && (
                <FavoriteSlideIconButton
                  slideUUID={uuid}
                  filledIconProps={DEFAULT_FAVORITE_CELL_ICON_BUTTON_PROPS}
                  outlineIconProps={DEFAULT_FAVORITE_CELL_ICON_BUTTON_PROPS}
                />
              )}
            </SlideName>
            {isAttachmentsViewerFeatureEnabled && isSpecialInstructionExist ? (
              <ShowSpecialInstructions
                setIsAttachmentsModalVisible={setIsAttachmentsModalVisible}
              />
            ) : null}
          </Grid>
          {children}
        </div>
      </Grid>
    </Fragment>
  );
};

const ViewerWrapper = ({
  isMulti,
  children,
  slide,
  setIsAttachmentsModalVisible,
  isFavoriteSlideFeatureEnabled,
  isAttachmentsViewerFeatureEnabled,
}) => {
  // in case if multiple slides display we use wrapper for multi slide (each slide as grid item)
  // otherwise using just a <Fragment/> as slide wrapper so slide fills the screen properly
  return isMulti ? (
    <MultiSlideWrapper
      slide={slide}
      setIsAttachmentsModalVisible={setIsAttachmentsModalVisible}
      isFavoriteSlideFeatureEnabled={isFavoriteSlideFeatureEnabled}
      isAttachmentsViewerFeatureEnabled={isAttachmentsViewerFeatureEnabled}
    >
      {children}
    </MultiSlideWrapper>
  ) : (
    <>{children}</>
  );
};

export const MultiSlideViewItem = ({
  slide,
  showNavigator,
  isMulti = true,
  isAttachmentsViewerFeatureEnabled,
  isFavoriteSlideFeatureEnabled = true,
  ...props
}) => {
  const isNavigatorVisible = !isMulti && showNavigator;
  const [isAttachmentsModalVisible, setIsAttachmentsModalVisible] =
    useState(false);

  return (
    <>
      <ViewerWrapper
        isMulti={isMulti}
        slide={slide}
        setIsAttachmentsModalVisible={setIsAttachmentsModalVisible}
        isFavoriteSlideFeatureEnabled={isFavoriteSlideFeatureEnabled}
        isAttachmentsViewerFeatureEnabled={isAttachmentsViewerFeatureEnabled}
      >
        <SimpleSlideView
          slide={slide}
          id={slide.uuid}
          showNavigator={isNavigatorVisible}
          {...props}
        />
      </ViewerWrapper>
      <ViewAttachmentsModal
        open={isAttachmentsModalVisible}
        onClose={() => setIsAttachmentsModalVisible(false)}
        order={slide?.order}
      />
    </>
  );
};
