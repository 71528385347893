import { ChatBubble } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState, memo } from 'react';
import { SlideCommentList } from 'components/SlideComments/SlideCommentsList';
import { SlideCommentInput } from 'components/ImageViewer/components/SlideCommentInput';
import { useAccordionWithCommentsStyles } from 'components/ImageViewer/styles';
import { useSelector } from 'react-redux';
import { isStaffSelector } from 'store/slices/userDetailsSlice';
import { MEDIUM_GREY } from 'utilities/colors';

export const AccordionWithComments = memo(
  ({ slideCommentsGroup, slide, handleDeleteComment, handleCreateComment }) => {
    const { classes } = useAccordionWithCommentsStyles();
    const isStaff = useSelector(isStaffSelector);
    const [isOpened, setIsOpened] = useState(
      slideCommentsGroup ? slideCommentsGroup.isOpened : false,
    );
    if (!slide || !slideCommentsGroup) return null;

    const comments = slideCommentsGroup.comments;
    const toggleAccordion = () => {
      setIsOpened((prev) => !prev);
    };

    return (
      <Accordion expanded={isOpened} className={classes.accordion}>
        <AccordionSummary
          onClick={toggleAccordion}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.summary}>
            {slide.name}
            <ChatBubble sx={{ color: MEDIUM_GREY }} />
            {!!comments?.length && (
              <Box className={classes.commentCount}>{comments.length}</Box>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SlideCommentInput
            classes={classes}
            isStaff={isStaff}
            slide={slide}
            handleCreateComment={(newSlideCommentText, slideUUID, isInternal) =>
              handleCreateComment(newSlideCommentText, slide, isInternal)
            }
          />
          <SlideCommentList
            comments={comments}
            handleDeleteComment={(commentToDelete) =>
              handleDeleteComment(commentToDelete, slide)
            }
          />
        </AccordionDetails>
      </Accordion>
    );
  },
);
