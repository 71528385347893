import { DefaultTabWrapper } from 'components/OrderDetails/Tabs/utilities';
import { Grid } from '@mui/material';
import { EmbeddingCuttingInstructions } from 'components/OrderForm/components/OrderDetailsTables';
import React from 'react';

export const EmbeddingAndCuttingInstructionsTab = ({ order }) => {
  return (
    <DefaultTabWrapper>
      <Grid item xs>
        <EmbeddingCuttingInstructions order={order} alternative={true} />
      </Grid>
    </DefaultTabWrapper>
  );
};
