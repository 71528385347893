import { REQUESTED_SERVICES_FIELD_NAME } from 'components/OrderForm/constants';
import { getExportParams } from 'components/OrderSlidesList/BaseOrderSlidesListView';
import { devLogger } from 'utilities/devLogger';

export const getSortedSelectedRows = (gridApi) => {
  if (!gridApi) return;
  const selectedRowNodesWithSort = [];
  gridApi.forEachNodeAfterFilterAndSort((node) => {
    if (node.selected && node.data) {
      selectedRowNodesWithSort.push(node.data);
    }
  });
  return selectedRowNodesWithSort;
};

export const handleFillHandleDoubleClicked = ({
  api,
  event,
  rowIndex,
  column: { colId: columnIndex },
}) => {
  if (event.target.className === 'ag-fill-handle') {
    api.stopEditing();
    copyCellValueDown(api, { rowIndex, columnIndex });
  }
};

export const GetSelectedRowIndex = (gridApi) => {
  let selectedRowIndex = -1;
  gridApi.forEachNode((node) => {
    if (node.selected === true) {
      selectedRowIndex = node.rowIndex;
    }
  });

  return selectedRowIndex;
};

export const SelectPreviousRow = (gridApi) => {
  if (gridApi) {
    const selectedRowIndex = GetSelectedRowIndex(gridApi);
    const previousRow = selectedRowIndex - 1;

    // unselect everything first
    gridApi.forEachNode((node) =>
      node.rowIndex === selectedRowIndex ? node.setSelected(false) : 0,
    );

    gridApi.forEachNode((node) =>
      node.rowIndex !== previousRow ? 0 : node.setSelected(true),
    );
  }
};

export const SelectNextRow = (gridApi) => {
  if (gridApi) {
    const selectedRowIndex = GetSelectedRowIndex(gridApi);
    const nextRowIndex = selectedRowIndex + 1;

    // select the next few ones
    gridApi.forEachNode((node) =>
      node.rowIndex === selectedRowIndex ? node.setSelected(false) : 0,
    );
    gridApi.forEachNode((node) =>
      node.rowIndex !== nextRowIndex ? 0 : node.setSelected(true),
    );
  }
};

export const selectGridRow = (gridApi, condition) => {
  let selected = false;

  gridApi.forEachNode((node) => {
    if (!selected && condition(node)) {
      selected = true;
      node.setSelected(true);
    }
  });
};

export const selectGridSingleRow = (gridApi, condition) => {
  gridApi.deselectAll();
  selectGridRow(gridApi, condition);
};

export const selectFirstRow = (gridApi, isGroup) => {
  if (gridApi) {
    const groupFirstRow = ({ key }) => !key;
    const firstRow = ({ rowIndex }) => rowIndex === 0;
    selectGridRow(gridApi, isGroup ? groupFirstRow : firstRow);
  }
};

// this is just used by the grid-layout, it's an arbitrary meaning of how many pixels each height unit is
export const rowHeight = 50;

export const getRowId = (params) => params?.data?.uuid;

export const getResizeableGridLayout2Column = (heightPixel, firstWidth = 8) => {
  const calculatedHeight = Math.floor(heightPixel / rowHeight);

  // because we have 100vh, sometimes it's too much, so adjust it by 150 pixels
  const calculatedHeightOffset = calculatedHeight - 3;

  const lastWidth = 12 - firstWidth;

  const layout = [
    {
      i: '0',
      x: 0,
      y: 0,
      w: firstWidth,
      h: calculatedHeightOffset,
      resizeable: true,
    },
    {
      i: '1',
      x: 8,
      y: 0,
      w: lastWidth,
      h: calculatedHeightOffset,
      resizeable: true,
    },
  ];

  return layout;
};

const APP_BAR_HEIGHT_PX = 64;

const calculateViewerHeightForOneRowOfSlides = () => {
  const screenHeight = document.documentElement.clientHeight;

  const extraSpace =
    (APP_BAR_HEIGHT_PX * 100) / Math.round(window.devicePixelRatio * 100);
  const minimalContainerSize = screenHeight - extraSpace;
  const calculatedHeight = Math.floor(minimalContainerSize / rowHeight);

  // because we have 100vh, sometimes it's too much, so adjust it by 100 pixels
  return calculatedHeight - 1;
};

export const calculateOptimalViewerHeightInRows = (slideRowsCount) => {
  const screenHeight = document.documentElement.clientHeight;
  const paddings = 32 + slideRowsCount * 50;
  const slideHeightInPx = (screenHeight + paddings) / 100;
  const slideHeightInRows = Math.ceil(slideHeightInPx);
  const minimalHeightInRows = slideRowsCount * slideHeightInRows;

  return slideRowsCount === 1
    ? calculateViewerHeightForOneRowOfSlides()
    : minimalHeightInRows;
};

export const getAgGridSelectedChildren = ({ gridAPI }) => {
  const mainSelectedNodes = gridAPI.getSelectedNodes();
  if (mainSelectedNodes.length > 0) {
    // Assume there's a single row selected in the main grid
    const mainSelectedNode = mainSelectedNodes[0];

    devLogger('mainSelectedNode', mainSelectedNode);

    // Get the detail grid API via the main node's `gridAPI.getDetailGridOptions` method
    const detailGridOptions = mainSelectedNode.getDetailGridOptions(
      mainSelectedNode.id,
    );

    devLogger('detailGridOptions', detailGridOptions);

    if (detailGridOptions) {
      // Get selected rows from the detail grid
      const detailSelectedNodes = gridAPI.getSelectedNodes();
      console.log(detailSelectedNodes);
      return detailSelectedNodes;
      // do something with detailSelectedNodes
    } else {
      return [];
    }
  }
};

export const getAgGridSelectedChildsUUIDs = ({ gridAPI }) => {
  const selected = [];
  gridAPI?.forEachDetailGridInfo((d) => {
    selected.push(...d.api.getSelectedRows().map((row) => row.uuid));
  });
  return selected;
};

export const agGridRowSelection = 'multiple';

export const agGridDefaultColDef = {
  // don't use flex 1, it forces everything to fit into 1, which makes too narrow of columns
  // and looks bad
  // flex: 1,
  enableValue: true,
  enablePivot: true,
  enableRowGroup: true,
  editable: false,
  sortable: true,
  filter: true,
  resizable: true,
  //floatingFilter: true,
};

const columnsToHideOnGroup = ['is_starred'];
//This function could be used for hiding columns listed in the array after grouping and showing them back after ungrouping
export const addHideColumnsOnGroupEvent = (columnApi) => {
  const allColumns = columnApi.getAllColumns();
  allColumns.forEach((column) => {
    if (!columnsToHideOnGroup.includes(column.colId)) {
      column.addEventListener('columnRowGroupChanged', () => {
        const isGroped = column.isRowGroupActive();
        columnApi.setColumnVisible(columnsToHideOnGroup, !isGroped);
      });
    }
  });
};

export const exportToExcelAction = (gridApi, fileName, customExportParams) => {
  if (gridApi) {
    const exportParams = customExportParams ?? getExportParams(fileName);
    gridApi.exportDataAsExcel(exportParams);
  }
};

export const agGridDefaultColDefFloatingFilter = {
  ...agGridDefaultColDef,
  floatingFilter: true,
  lockVisible: true,
};

export const resizeColumnsToFitScreen = ({ api }) => {
  api.sizeColumnsToFit();
};

// hack to resize table in case if its div resizing has animation
export const delayedResizeColumnsToFitScreen = (api, delay = 200) => {
  setTimeout(() => {
    resizeColumnsToFitScreen({ api });
  }, delay);
};

export const collapseGridRows = (gridApi, ...nodesToExclude) => {
  gridApi.forEachNode((gridNode) => {
    if (
      !nodesToExclude.includes(gridNode) &&
      gridNode.expanded &&
      !gridNode.allChildrenCount
    ) {
      gridNode.setExpanded(false);
    }
  });
};

export const getAllServicesRequested = (rows) => {
  const allServices = rows
    .map((row) => row.data[REQUESTED_SERVICES_FIELD_NAME])
    .flat();
  const uniqueServices = [...new Set(allServices)];

  return uniqueServices;
};

export const setColumnVisibleIfValueExists = (
  { api, newValue, columnApi },
  targetColID,
  valueToExist = 'any',
) => {
  const { rowsToDisplay } = api?.rowModel ?? {};
  if (!rowsToDisplay?.length) {
    return;
  }
  const requestedServices = getAllServicesRequested(rowsToDisplay);

  const shouldColumnBeVisible =
    valueToExist === 'any'
      ? !!newValue?.length
      : requestedServices.includes(valueToExist);

  const targetColumn = columnApi.getColumn(targetColID);

  if (targetColumn && shouldColumnBeVisible !== targetColumn.visible) {
    columnApi.setColumnVisible(targetColID, shouldColumnBeVisible);
  }
};

export const copyCellValueDown = (gridApi, { rowIndex, columnIndex }) => {
  const lastRowIndex = gridApi.getDisplayedRowCount() - 1;
  gridApi.clearRangeSelection();
  gridApi.addCellRange({
    rowStartIndex: rowIndex,
    rowEndIndex: lastRowIndex,
    columns: [columnIndex],
  });
  gridApi.copySelectedRangeDown();
  gridApi.clearRangeSelection();
};

export const limitRowSelection = (event, maxSize) => {
  const selectedRows = event.api.getSelectedNodes();
  const newArr = selectedRows.slice(maxSize);
  newArr.forEach((item) => item.setSelected(false));
};
