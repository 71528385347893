import { HORIZONTAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import { makeStyles } from 'tss-react/mui';

export const useOrderDetailsSlidesStyles = makeStyles()((theme) => ({
  root: {
    padding: '0rem',
    width: '100%',
  },
  tabStyle: {
    marginTop: '1rem',
    flexGrow: 1,
    width: '100%',
  },
  appBarStyle: {
    backgroundColor: theme.palette.secondary.light,
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      padding: '7px',
    },
  },
  slideView: {
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formInput: {
    marginTop: '0.25rem',
    padding: '0.5rem',
    fontSize: '14px',
    width: '50%',
    display: 'inline-block',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    marginBottom: '1rem',
  },
  slideViewer: {
    width: '90vw',
    marginBottom: '2rem',
  },
  header: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderColor: theme.palette.primary.light,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  missingSlides: {
    marginTop: '0.25rem',
    padding: '1rem',
  },
}));

export const useSamplesListStyles = makeStyles()((theme) => {
  return {
    alternativeHeader: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
      borderColor: theme.palette.secondary.light,
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    expansionDetails: {
      padding: 0,
      overflowX: 'auto',
      ...HORIZONTAL_SCROLL_STYLE,
    },
    accordionTitle: {
      fontSize: '1.125rem',
    },
  };
});

export const marginBottomStyle = { marginBottom: '0.5rem' };
export const marginTopStyle = { marginTop: '1rem' };
export const inlineBlockStyle = { display: 'inline-block' };
export const tabPadding = { padding: '1rem' };
export const gridStyle = { marginBottom: '2rem' };
export const typographySlideNameStyle = {
  cursor: 'pointer',
  color: 'blue',
  wordWrap: 'break-word',
};
