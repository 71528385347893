import React from 'react';
import { OrderDetailsView } from './OrderDetailsView';
import { MiniDrawerWithContext } from 'components/Layout/drawer';

export const OrderDetailsPage = () => {
  return (
    <MiniDrawerWithContext header={'Order Details'}>
      <OrderDetailsView />
    </MiniDrawerWithContext>
  );
};
