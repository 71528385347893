import { Box, Checkbox, Typography } from '@mui/material';
import { BlocksModalWrapper } from 'components/BlocksList/BlocksModals/BlocksModalWrapper';
import { SubmitBlocksTypes } from 'components/BlocksList/constants';
import { getBlocksProjectCount } from 'components/BlocksList/utilities';
import { useConfirmDeleteModalStyles } from 'components/TeamOverview/components/ConfirmActionModal/styles';
import { TermsAndConditionsReviewModal } from 'components/TeamOverview/components/TermsAndConditionsReviewModal/TermsAndConditionsReviewModal';
import React, { useState } from 'react';

export const ContributeBlocksModal = ({ open, handleClose, selectedRows }) => {
  const { classes } = useConfirmDeleteModalStyles();
  const [isFirstAgreementChecked, setIsFirstAgreementChecked] = useState(false);
  const [isSecondAgreementChecked, setIsSecondAgreementChecked] =
    useState(false);
  const [isThirdAgreementChecked, setIsThirdAgreementChecked] = useState(false);
  const [
    isTermsAndConditionsReviewModalOpen,
    setIsTermsAndConditionsReviewModalOpen,
  ] = useState(false);
  const [signedUserName, setSignedUserName] = useState('');
  const projectsCount = getBlocksProjectCount(selectedRows);
  const text = `YOU'RE CONTRIBUTING ${selectedRows.length} BLOCKS FROM ${projectsCount} PROJECTS`;

  const handleSignTermsAndConditions = (userName) => {
    setSignedUserName(userName);
    setIsTermsAndConditionsReviewModalOpen(false);
  };

  const handleOpenTermsAndConditionsModal = () => {
    setIsTermsAndConditionsReviewModalOpen(true);
  };

  const checkboxLabelStyles = `${classes.agreementText} ${
    !signedUserName ? classes.grayText : ''
  }`;

  return (
    <>
      <BlocksModalWrapper
        open={open}
        handleClose={handleClose}
        actionType={SubmitBlocksTypes.CONTRIBUTE}
        disabled={
          !isFirstAgreementChecked ||
          !isSecondAgreementChecked ||
          !isThirdAgreementChecked
        }
        text={text}
      >
        <Box>
          <Typography className={classes.warning}>
            Warning: Contribution of Blocks
          </Typography>
          <Typography>
            You are about to contribute the selected blocks and metadata to
            HistoWiz. This action cannot be undone.
          </Typography>
          <Typography mt={2}>
            HistoWiz will purchase these blocks from your organization and will
            send an offer via email once this contribution is confirmed and
            relevant metadata is uploaded through the email link that will be
            sent shortly. The value of these blocks will be decided by our team
            and you can choose to accept and continue with this contribution or
            decline this offer to maintain control of these blocks.
          </Typography>
          <ul>
            <Typography
              className={classes.termsAndConditionsLink}
              fontWeight={500}
              onClick={handleOpenTermsAndConditionsModal}
            >
              Review the Contribution Terms & Conditions before Proceeding
            </Typography>
          </ul>
          <Box marginTop={2} paddingLeft={1}>
            <Box>
              <Checkbox
                disabled={!signedUserName}
                size="medium"
                checked={isFirstAgreementChecked}
                onChange={() => setIsFirstAgreementChecked((prev) => !prev)}
              />
              <Typography component="span" className={checkboxLabelStyles}>
                By proceeding, you confirm your block management choices.
              </Typography>
            </Box>
            <Box>
              <Checkbox
                disabled={!signedUserName}
                size="medium"
                checked={isSecondAgreementChecked}
                onChange={() => setIsSecondAgreementChecked((prev) => !prev)}
              />
              <Typography component="span" className={checkboxLabelStyles}>
                I confirm that my experiment has concluded, that I have no
                regulatory storage requirements, and that these blocks will no
                longer be needed.
              </Typography>
            </Box>
            <Box>
              <Checkbox
                disabled={!signedUserName}
                size="medium"
                checked={isThirdAgreementChecked}
                onChange={() => setIsThirdAgreementChecked((prev) => !prev)}
              />
              <Typography component="span" className={checkboxLabelStyles}>
                I understand that the offer from HistoWiz may require me to
                upload associated metadata and that I will return this data in a
                timeline manner.
              </Typography>
            </Box>
          </Box>
        </Box>
      </BlocksModalWrapper>
      <TermsAndConditionsReviewModal
        isOpen={isTermsAndConditionsReviewModalOpen}
        handleClose={() => setIsTermsAndConditionsReviewModalOpen(false)}
        handleSubmit={handleSignTermsAndConditions}
      />
    </>
  );
};
