import {
  getPathologyConsultationFinalReportAttachments,
  uploadFinalReportTeamFileAttachmentsToPathologyConsultations,
} from 'services/resources/pathologyConsultations';
import React, { Fragment, useEffect } from 'react';
import { FILE_ATTACHMENTS_FAILED_TO_UPLOAD } from 'components/OrderDetails/constants';
import { errorLogger } from 'utilities/loggers';
import { FinalReportTeamFileAttachmentsTable } from 'components/PathologyConsultations/OrderForm/TeamFileAttachments';
import Dropzone from 'react-dropzone';
import { Box, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { makeStyles } from 'tss-react/mui';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles()({});

export const RenderFinalReportAttachments = ({ consultation }) => {
  const { cx } = useStyles();

  const [finalReportAttachments, setFinalReportAttachments] = React.useState(
    [],
  );
  const { showSuccess, showError } = useSnackbar();

  const getUpdatedConsultationAttachments = () => {
    if (!consultation) {
      return;
    }

    getPathologyConsultationFinalReportAttachments({
      consultUUID: consultation.uuid,
    }).then((response) => {
      setFinalReportAttachments(response);
    });
  };

  useEffect(() => {
    if (!consultation) {
      return;
    }

    getUpdatedConsultationAttachments();
  }, [consultation?.uuid]);

  if (!consultation) {
    return <div />;
  }

  const onFilesDrop = (acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append('files', file);
    });

    if (!consultation) {
      return;
    }

    formData.append('consultUUID', consultation.uuid);

    const requestDetails = {
      consultUUID: consultation.uuid,
      postParams: formData,
    };

    uploadFinalReportTeamFileAttachmentsToPathologyConsultations(requestDetails)
      .then(() => {
        showSuccess('Final Report Files Uploaded Successfully!');
        // now after success, get the most updated attachments
        getUpdatedConsultationAttachments();
      })
      .catch((error) => {
        showError(FILE_ATTACHMENTS_FAILED_TO_UPLOAD);
        errorLogger('error when uploading', error.response);
      });
  };

  return (
    <Fragment>
      <FinalReportTeamFileAttachmentsTable
        attachments={finalReportAttachments}
      />
      <Box mb={2}>
        <Dropzone onDrop={onFilesDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={cx('dropzone', {
                  'dropzone--isActive': isDragActive,
                })}
              >
                <input {...getInputProps()} />
                <Box
                  sx={{
                    p: 2,
                    border: '1px dashed grey',
                    width: '100%',
                  }}
                >
                  <Button startIcon={<UploadIcon />}>
                    Choose a file(s) to upload
                  </Button>
                </Box>
              </div>
            );
          }}
        </Dropzone>
      </Box>
    </Fragment>
  );
};
