import Grid from '@mui/material/Grid';
import React from 'react';
import { SlideViewerToolbarButton } from 'components/ImageViewer/components/ImageViewerToolbar/Buttons/SlideViewerToolbarButton';
import { SLIDE_NAVIGATION_TOOLTIPS } from 'components/ImageViewer/components/ImageViewerToolbar/constants';

export const SlidesNavigationButtonsGroup = ({
  handlePreviousSlide,
  handleNextSlide,
}) => {
  return (
    <Grid item container spacing={1}>
      <Grid item>
        <SlideViewerToolbarButton
          value="prevSlide"
          title={SLIDE_NAVIGATION_TOOLTIPS.PREVIOUS}
          onClick={() => handlePreviousSlide(false)}
        >
          prev
        </SlideViewerToolbarButton>
      </Grid>
      <Grid item>
        <SlideViewerToolbarButton
          value="nextSlide"
          title={SLIDE_NAVIGATION_TOOLTIPS.NEXT}
          onClick={() => handleNextSlide(false)}
        >
          next
        </SlideViewerToolbarButton>
      </Grid>
    </Grid>
  );
};
