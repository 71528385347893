import { CenterFocusStrong, ZoomIn, ZoomOut } from '@mui/icons-material';
import {
  SLIDE_VIEWER_ICON_ZOOM_IN_TOOLTIP,
  SLIDE_VIEWER_ICON_ZOOM_OUT_TOOLTIP,
  SLIDE_VIEWER_VIEW_WHOLE_SLIDE_TOOLTIP,
} from 'components/ImageViewer/constants';
import { SlideRotationTools } from 'components/ImageViewer/SlideRotation';
import React from 'react';
import { ToolbarIcon } from 'components/ImageViewer/utilities';

export const CustomMultiSlideToolbar = ({ id, viewer, isFullScreen }) => {
  const zoomInName = `zoom-in-${id}`;
  const zoomOutName = `zoom-out-${id}`;
  const fitScreen = `fit-screen-${id}`;

  const handleFitScreen = () => {
    viewer.viewport.fitVertically(true);
  };

  return (
    <ul className="ocd-toolbar">
      <ToolbarIcon
        id={zoomInName}
        icon={<ZoomIn />}
        title={SLIDE_VIEWER_ICON_ZOOM_IN_TOOLTIP}
        isFullScreen={isFullScreen}
      />
      <ToolbarIcon
        id={zoomOutName}
        icon={<ZoomOut />}
        title={SLIDE_VIEWER_ICON_ZOOM_OUT_TOOLTIP}
        isFullScreen={isFullScreen}
      />
      <SlideRotationTools id={id} viewer={viewer} isFullScreen={isFullScreen} />
      <ToolbarIcon
        id={fitScreen}
        icon={<CenterFocusStrong />}
        title={SLIDE_VIEWER_VIEW_WHOLE_SLIDE_TOOLTIP}
        onMouseDown={handleFitScreen}
        isFullScreen={isFullScreen}
      />
    </ul>
  );
};
