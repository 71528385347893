import { Grid } from '@mui/material';
import React from 'react';
import { TeamCard } from 'components/TeamOverview/components/TeamCard/TeamCard';
import { useTeamMembers } from 'components/UserProfile/hooks';
import { ContactsCard } from 'components/TeamOverview/components/ContactsCard/ContactsCard';

export const TeamSection = ({ team }) => {
  const { members, isLoading = true } = useTeamMembers(team);

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <TeamCard users={members} isLoading={isLoading} team={team} />
      </Grid>
      <Grid item xs={3}>
        <ContactsCard />
      </Grid>
    </Grid>
  );
};
