import { getSavedInfoRows } from 'components/OrderForm/Info/SavedInfoModal/helpers';
import { useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SavedInfoRow } from 'components/OrderForm/Info/SavedInfoModal/SavedInfoRaw/SavedInfoRow';
import { DefaultModal } from 'components/Modals/DefaultModal';
import {
  ModalStyles,
  useSavedInfoModalStyles,
} from 'components/OrderForm/Info/SavedInfoModal/styles';

export const SavedInfoModal = ({ project, isOpen, onDecline, onAccept }) => {
  const { classes } = useSavedInfoModalStyles();

  const enterSavedRows = useMemo(
    () =>
      getSavedInfoRows({
        ...project?.defaults,
        label: project?.label,
      }),
    [project],
  );

  const handleAccept = () => {
    onAccept({ ...project.defaults, project });
  };

  return (
    <DefaultModal open={isOpen} styles={ModalStyles} closeAfterTransition>
      <Typography className={classes.header}>
        Load previously saved configuration?
      </Typography>
      <Box className={classes.body}>
        <Box className={classes.content}>
          {enterSavedRows.map(({ label, value }) => (
            <SavedInfoRow key={label} label={label} value={value} />
          ))}
        </Box>
        <Box className={classes.actions}>
          <Button
            className={classes.decline}
            onClick={onDecline}
            variant="contained"
            color="inherit"
          >
            Decline
          </Button>
          <Button onClick={handleAccept} variant="contained" color="primary">
            Accept
          </Button>
        </Box>
      </Box>
    </DefaultModal>
  );
};
