import {
  RESET_PASSWORD_CONFIRM_NAME,
  RESET_PASSWORD_NAME,
} from 'services/resources/apiConstants';
import {
  LOGIN_RESOURCE_NAME,
  REGISTRATION_RESOURCE_NAME,
} from './apiConstants';
import { loginAPI, UnauthenticatedAPI } from '../backendAPI';

export const loginAPIUser = (postParams, updateIsStaff) => {
  return loginAPI
    .post(LOGIN_RESOURCE_NAME, postParams)
    .then(function (response) {
      // set context w/if this user is staff or not
      updateIsStaff(response.data.is_staff);
      const apiKey = response.data.key;
      // if we get the apiKey, localStorage to the key
      // localStorage.token = apiKey;
      global.localStorage.setItem('token', apiKey);

      return apiKey;
    });
};

export const registerUser = (postParams) => {
  return loginAPI.post(REGISTRATION_RESOURCE_NAME, postParams);
};

export const resetPassword = ({ postParams }) =>
  UnauthenticatedAPI.post(RESET_PASSWORD_NAME, postParams);

export const resetPasswordConfirm = ({ postParams }) =>
  UnauthenticatedAPI.post(RESET_PASSWORD_CONFIRM_NAME, postParams);
