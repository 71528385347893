import { useEffect, useMemo, useState } from 'react';
import useCollapse from 'react-collapsed';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrderTurnaroundTimeOptions } from 'components/OrderForm/constants';
import {
  CELL_TARGET,
  CREATE_PROJECT_ACTION,
  EXPECTED_PHENOTYPE,
  EXPECTED_STAINING_PATTERN,
  EXPERIMENTAL_TREATMENT,
  NO_TEAMS_ERROR_MESSAGE,
  UNABLE_CREATE_ORDER_MESSAGE,
} from 'components/OrderForm/Info/constants';
import {
  buildCreateOrderParams,
  buildOrderDetails,
  getOrderInfoValidationSchema,
  getOrderFormSamplesURL,
} from 'components/OrderForm/Info/utilities';
import { teamDropdownOnOrderForms } from 'constants/featuresEnabled';
import {
  useCreateOrderMutation,
  useUpdateOrderMutation,
} from 'store/apis/orderApi';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useCreateGlpOrderMutation } from 'store/apis/glpOrderApi';
import { PLACE_ORDER_REVIEW_FEEDBACK_URL } from 'constants/urls';

export const useOrderFormContainer = ({
  team,
  orderDetails,
  selectableTeams,
  isGLP,
}) => {
  const { isStaff } = useSelector(userDetailsSelector);
  const [isSavedModalOpen, setIsSavedModalOpen] = useState(false);

  const isOrderDetailsContainOptionalFields =
    orderDetails &&
    (orderDetails[EXPECTED_PHENOTYPE]?.length ||
      orderDetails[EXPERIMENTAL_TREATMENT]?.length ||
      orderDetails[EXPECTED_STAINING_PATTERN]?.length ||
      orderDetails[CELL_TARGET]?.length);
  const { push } = useHistory();
  const { showError } = useSnackbar();
  const [isOptionalVisible, setIsOptionalVisible] = useState(
    !!isOrderDetailsContainOptionalFields,
  );
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isOptionalVisible,
  });

  const noTeamsAvailable = !team && !selectableTeams.length;

  const [createOrderMutation] = useCreateOrderMutation();
  const [updateOrderMutation] = useUpdateOrderMutation();
  const [createGlpOrderMutation] = useCreateGlpOrderMutation();

  useEffect(() => {
    if (noTeamsAvailable) {
      showError(NO_TEAMS_ERROR_MESSAGE);
    }
  }, [noTeamsAvailable]);

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getOrderInfoValidationSchema(isGLP)),
    defaultValues: { ...orderDetails },
  });

  const onSubmit = (values, protocolFiles) => {
    if (noTeamsAvailable) return;

    const { uuid: orderUUID } = orderDetails;

    const postParams = buildCreateOrderParams(
      values,
      orderDetails,
      team,
      isGLP,
    );

    // check if uuid exists in orderDetails
    // if an order UUID already exists, then dont use the GLP order form
    // submit, we want our PI to enter the details in the regular order
    const orderUUIDExists = orderDetails.hasOwnProperty('uuid');

    const orderService = orderUUID
      ? (postParams) => updateOrderMutation({ orderUUID, postParams })
      : createOrderMutation;

    if (isGLP && !orderUUIDExists) {
      if (!protocolFiles.length) {
        return;
      }

      const formData = new FormData();

      protocolFiles.forEach((file) => formData.append('files', file));
      Object.keys(postParams).forEach((key) =>
        formData.append(key, postParams[key]),
      );

      createGlpOrderMutation(formData)
        .unwrap()
        .then(({ name }) =>
          push(
            PLACE_ORDER_REVIEW_FEEDBACK_URL.replace(
              ':orderID',
              name.replace('Order ', ''),
            ),
          ),
        )
        .catch(() => showError(UNABLE_CREATE_ORDER_MESSAGE));
    } else {
      orderService(postParams)
        .unwrap()
        .then(({ uuid }) => push(getOrderFormSamplesURL(uuid)))
        .catch(() => showError(UNABLE_CREATE_ORDER_MESSAGE));
    }
  };

  const toggleOptionals = () => {
    setIsOptionalVisible((prevState) => !prevState);
  };

  const [
    project,
    // pathologyMapContributorSelection,
    turnaroundTime,
    ihc_optimization_type,
    materials_at_histowiz,
  ] = methods.watch([
    'project',
    // "pathologyMapContributorSelection",
    'turnaroundTime',
    'ihc_optimization_type',
    'materials_at_histowiz',
  ]);

  const renderTeamSelection =
    teamDropdownOnOrderForms &&
    project?.value === CREATE_PROJECT_ACTION &&
    !!selectableTeams.length;

  // const pathContributorAndTATConflict =
  //   !!pathologyMapContributorSelection?.value && turnaroundTime?.value === 2;
  const renderOptionalIHC = !!ihc_optimization_type?.value;
  const materialsAtHistowiz = !!materials_at_histowiz?.value;

  const isSaveButtonDisabled = methods.formState.isSubmitting;
  // || pathContributorAndTATConflict;

  const turnaroundTimeOptions = useMemo(
    () =>
      isStaff || turnaroundTime?.value === 2
        ? OrderTurnaroundTimeOptions
        : OrderTurnaroundTimeOptions.filter((option) => option.value !== 2),
    [isStaff, turnaroundTime],
  );

  const openSavedModal = () => {
    setIsSavedModalOpen(true);
  };

  const handleDecline = () => {
    setIsSavedModalOpen(false);
  };

  const handleAccept = (fields) => {
    const serializedFields = buildOrderDetails({
      ...fields,
      project: { uuid: project.value, name: project.label },
    });

    methods.reset(serializedFields);
    setIsSavedModalOpen(false);
  };

  const previousOrdersMaterials =
    orderDetails.materials_at_histowiz_previous_orders_v2;

  return {
    methods,
    onSubmit,
    renderTeamSelection,
    noTeamsAvailable,
    turnaroundTimeOptions,
    // pathologyMapContributorSelection,
    // pathContributorAndTATConflict,
    materialsAtHistowiz,
    renderOptionalIHC,
    getToggleProps,
    toggleOptionals,
    isSaveButtonDisabled,
    isOptionalVisible,
    getCollapseProps,
    openSavedModal,
    handleDecline,
    handleAccept,
    project,
    isSavedModalOpen,
    previousOrdersMaterials,
  };
};
