import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useSlideInfoForPathologyMapStyles } from 'components/PathologyMap/constants/styles';
import { OrderInfo } from 'components/UserProfile/OrdersCard/OrderInfo';
import {
  COMPLETED_ORDERS_TAB_VALUE,
  ORDER_TABS_IDS,
} from 'components/PathologyMap/constants/common';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { useOrdersTabContentStyles } from 'components/UserProfile/styles';
import {
  ORDERS_CARD_TABS_LIST,
  SlideTypes,
} from 'components/UserProfile/constants';
import { HeaderTabs } from 'components/UserProfile/components/HeaderTabs';
import { CardHeader } from 'components/UserProfile/components/CardHeader';
import { OrdersSortTeamPage } from 'components/UserProfile/components/OrdersSortTeamPage';
import { usePrepareOrderForSelectedTab } from 'components/UserProfile/hooks';
import { sortOrder } from 'utilities/constants';
import { useHistory } from 'react-router';
import { onDoubleClick, onSlidesClick } from 'components/UserProfile/utilities';
import { ORDER_NAME_SORT_FIELD } from 'components/SlideGroups/utilities/constants';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import { UploadStepperModal } from 'components/UserProfile/Upload/UploadStepperModal';
import uploadImagesHintImage from 'images/newFeatures/upload_images_hint.gif';
import { HelpButtonWithTooltip } from 'components/UserProfile/components/HelpButtonWithTooltip';

export const OrdersCard = ({
  setSelectedOrder,
  selectedOrder,
  selectedTeam,
  setCurrentView,
  setUploadKey,
  selectedOrderForUpload,
  selectedProjectForUpload,
  setSelectedProjectForUpload,
  setSelectedOrderForUpload,
}) => {
  const { classes: classesFromPathologyMap } =
    useSlideInfoForPathologyMapStyles();
  const { classes: orderTabStyles } = useOrdersTabContentStyles();
  const classes = { ...classesFromPathologyMap, ...orderTabStyles };
  const history = useHistory();
  const [currentTabValue, setCurrentTabValue] = useState(
    COMPLETED_ORDERS_TAB_VALUE,
  );
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [sort, setSort] = useState({
    field: ORDER_NAME_SORT_FIELD,
    order: sortOrder.DESC,
  });

  const { selectedTabSortedOrders, isOrdersLoading } =
    usePrepareOrderForSelectedTab(
      selectedTeam,
      ORDER_TABS_IDS[currentTabValue],
      sort,
    );

  const handleTabChange = useCallback((_event, tabValue) => {
    setCurrentTabValue(tabValue);
  }, []);

  const selectFirstOrder = () => setSelectedOrder(selectedTabSortedOrders[0]);

  useEffect(() => {
    if (!selectedTabSortedOrders?.length) return;

    selectFirstOrder();
  }, [currentTabValue, selectedTeam]);

  useEffect(() => {
    if (selectedOrder || !selectedTabSortedOrders?.length) return;

    selectFirstOrder();
  }, [selectedTabSortedOrders]);

  const isDefaultTab = currentTabValue === COMPLETED_ORDERS_TAB_VALUE;
  const tooltipTitle = isOrdersLoading
    ? ''
    : `Upload Slides To Selected Project/Order: ${selectedOrder?.project?.name} / ${selectedOrder?.name}`;

  return (
    <Card
      header={
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box padding="1.25rem" display="flex" justifyContent="space-between">
            <CardHeader
              title="PROJECTS / ORDERS"
              subtitle=""
              RightSection={
                <HeaderTabs
                  currentTabValue={currentTabValue}
                  handleTabChange={handleTabChange}
                  tabsList={ORDERS_CARD_TABS_LIST}
                />
              }
            />
          </Box>
          <Box className={classes.uploadButtonsContainer}>
            <Tooltip title={tooltipTitle} placement="bottom" arrow>
              <Button
                onClick={() => setIsUploadModalOpen(true)}
                variant="outlined"
                color="primary"
                className={`${classes.uploadButton} ${classes.button}`}
                startIcon={<UploadFileIcon />}
                disabled={isOrdersLoading}
              >
                Upload
              </Button>
            </Tooltip>
            <Box marginTop={'1.25rem'}>
              <HelpButtonWithTooltip
                title={'How to upload slides?'}
                fileSrc={uploadImagesHintImage}
              />
            </Box>
          </Box>
        </Box>
      }
    >
      <Box className={classes.bodyColumn}>
        <OrdersSortTeamPage
          sort={sort}
          setSort={setSort}
          isDefaultTab={isDefaultTab}
        />
        <Box className={classes.ordersList} sx={{ minWidth: 600 }}>
          {isOrdersLoading ? (
            <Box className={classes.spinnerContainer}>
              <Spinner />
            </Box>
          ) : selectedTabSortedOrders.length ? (
            selectedTabSortedOrders.map((order) => (
              <OrderInfo
                onIFSlidesClick={() =>
                  onSlidesClick(order, history, SlideTypes.IF)
                }
                onDefaultSlidesClick={() =>
                  onSlidesClick(order, history, SlideTypes.IHC)
                }
                onDoubleClick={() => onDoubleClick(order, history)}
                isSelected={selectedOrder?.uuid === order.uuid}
                onRowClick={() => setSelectedOrder(order)}
                key={order.uuid}
                order={order}
                classes={classes}
                isDefaultTab={isDefaultTab}
              />
            ))
          ) : (
            <Box height="100%" display="flex" alignItems="center">
              <Typography>No such orders</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <UploadStepperModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        selectedOrder={selectedOrder}
        setCurrentView={setCurrentView}
        setUploadKey={setUploadKey}
        selectedOrderForUpload={selectedOrderForUpload}
        selectedProjectForUpload={selectedProjectForUpload}
        setSelectedProjectForUpload={setSelectedProjectForUpload}
        setSelectedOrderForUpload={setSelectedOrderForUpload}
        selectedTeam={selectedTeam}
      />
    </Card>
  );
};
