import {
  AI_MODEL_EVENTS,
  SHAPE_DRAW_TYPES,
} from 'components/AITools/constants/common';
import { MAP_AI_MODEL_TO_DISPLAY_COLOR } from 'components/AITools/constants/styles';
import {
  drawPolygonFlat,
  getViewerContainerPositionFromImagePosition,
} from 'components/ImageViewer/extAnnoUtils';

export class BaseAIModel {
  constructor(modelName, viewer, slide, modelsOpacitiesRef) {
    this.modelName = modelName;
    this.viewer = viewer;
    this.slide = slide;
    this.isShown = false;

    this.overlay = this.viewer.fabricjsOverlay({ scale: 1000 });
    this.canvas = this.overlay.fabricCanvas();
    this.ctx = this.canvas.upperCanvasEl.getContext('2d');

    this.modelsOpacitiesRef = modelsOpacitiesRef;

    this.update = this.update.bind(this);

    this.addEventListeners();
  }

  getFillStyle() {
    if (!MAP_AI_MODEL_TO_DISPLAY_COLOR[this.modelName]) {
      throw new Error(`Fill style not defined for ${this.modelName}`);
    }

    return MAP_AI_MODEL_TO_DISPLAY_COLOR[this.modelName];
  }

  applyStylesFilled(polygon) {
    throw new Error(
      `applyStylesFilled not implemented for ${this.modelName} ${polygon}`,
    );
  }

  applyStylesNotFilled() {
    this.ctx.save();
    this.ctx.clip();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();
  }

  applyStyles(polygon) {
    if (polygon.type === SHAPE_DRAW_TYPES.FILL) {
      this.applyStylesFilled(polygon);
    } else {
      this.applyStylesNotFilled();
    }
  }

  getOpacity() {
    if (!this.modelsOpacitiesRef.current) {
      throw new Error(`modelsOpacitiesRef not set for ${this.modelName}`);
    }

    return this.modelsOpacitiesRef.current;
  }

  // eslint-disable-next-line class-methods-use-this
  getPathPoint(x, y) {
    return { x, y };
  }

  calculateNewPath(region) {
    const newPath = [];
    region.polygon.forEach((value, index) => {
      if (index % 2 !== 0) return;
      const point = this.getPathPoint(value, region.polygon[index + 1]);

      // Pushing individual coordinates instead of the object.
      const position = getViewerContainerPositionFromImagePosition(
        this.viewer,
        point,
      );
      newPath.push(position.x, position.y);
    });

    return newPath;
  }

  processContours(contours) {
    return contours.map((region) => ({
      ...region,
      path: this.calculateNewPath(region),
      type: region.type,
    }));
  }

  getTissueContours() {
    throw new Error(`getTissueContours not implemented for ${this.modelName}`);
  }

  draw() {
    if (!this.viewer.source) {
      return;
    }

    const tissueSegmentations = this.getTissueContours();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    tissueSegmentations.forEach((polygon) => {
      drawPolygonFlat(this.ctx, polygon.path);
      this.applyStyles(polygon);
    });

    this.isShown = true;
  }

  update(params) {
    if (!this.isShown) return;

    if (params?.detail) {
      const {
        airaOpacity,
        aiosynOpacity,
        tissueOpacity,
        qcOpacity,
        segmentationOpacity,
      } = params.detail;
      this.modelsOpacitiesRef.current = {
        aira: airaOpacity,
        aiosyn: aiosynOpacity,
        qc: qcOpacity,
        tissue: tissueOpacity,
        segmentation: segmentationOpacity,
      };
    }

    this.draw();
  }

  hide() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.isShown = false;
  }

  addEventListeners() {
    this.viewer.addHandler('viewport-change', this.update);
    this.viewer.addHandler('fabricjs-resize-finished', this.update);
    document.addEventListener(AI_MODEL_EVENTS.OPACITY_CHANGE, this.update);
  }

  removeEventListeners() {
    this.viewer.removeHandler('viewport-change', this.update);
    this.viewer.removeHandler('fabricjs-resize-finished', this.update);
    document.removeEventListener(AI_MODEL_EVENTS.OPACITY_CHANGE, this.update);
  }

  destroy() {
    this.hide();
    this.removeEventListeners();
  }
}
