import React, {
  Fragment,
  useMemo,
  useContext,
  useState,
  useEffect,
} from 'react';
import { AppContext } from 'services/context';
import moment from 'moment';
import {
  QC_FAILED_STATE_CONSTANT,
  QC_PASSED_STATE_CONSTANT,
  ML_QC_OPTIONS_RECORDS,
  DEFAULT_QC_FAILED_CHECKS,
} from 'components/QCViewer/constants';
import {
  Accordion,
  AccordionDetails,
  IconButton,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';

import { DefaultDialog } from 'components/Modals/DefaultDialog';
import Box from '@mui/material/Box';
import { SimpleSlideView } from 'components/ImageViewer/SimpleSlideImageView';
import Divider from '@mui/material/Divider';
import {
  getLISAOrderLinkFromSlide,
  getLisaSlideLink,
} from 'components/utilities/getLinks';
import Checkbox from '@mui/material/Checkbox';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import { useSelector } from 'react-redux';
import { currentUserEmailSelector } from 'store/slices/userDetailsSlice';
import {
  DEFAULT_QC_REASON_BORDER_COLOR,
  DEFAULT_QC_REASON_BORDER_SIZE,
  QC_REASON_FAILED_BORDER_COLOR,
  QC_REASON_PASSED_BORDER_COLOR,
  QC_REASON_PASSED_OR_FAILED_BORDER_SIZE,
} from 'components/Modals/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  isQCScoreFailed,
  isQCScorePassed,
} from 'components/ImageViewer/utilities';
import { GridViewOutlined } from '@mui/icons-material';
import { serializeDataForCheckboxes } from 'components/utilities/gridDataSerializers';
import { Attachment } from 'components/ImageViewer/ViewAttachmentsModal';
import {
  REVIEW_ACTIVE_ORDER_NOTES_TEXT,
  REVIEW_SPECIAL_INSTRUCTIONS_TEXT,
} from 'components/Modals/QCBulkPassFailModal';
import { useQCPassFailModalHookStyles } from 'components/Modals/styles';
import { isGLPSlide } from 'utilities/slides';
import { usePostQCSlideMutation } from 'store/apis/slidesApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { LIGHT_GREY } from 'utilities/colors';

const modalContextName = 'modalQCPassFailOpen';

const inlineBlockNormalStyle = {
  display: 'inline-block',
  fontWeight: 'normal',
};
const inlineBlockBoldStyle = { display: 'inline-block', fontWeight: 'bold' };
const marginBottom05RemStyle = { marginBottom: '0.5rem' };

const AttributeLabel = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div style={{ marginBottom: '0.5rem' }}>
      <Typography style={inlineBlockNormalStyle}>
        {' '}
        {label}
        {' : '}
      </Typography>{' '}
      <Typography style={inlineBlockBoldStyle}>{value}</Typography>
    </div>
  );
};

const AttributeLink = ({ label, value, link }) => {
  if (!value) {
    return null;
  }

  if (!link) {
    return null;
  }
  return (
    <div style={marginBottom05RemStyle}>
      <Typography style={inlineBlockNormalStyle}>
        {' '}
        {label}
        {' : '}
      </Typography>{' '}
      <Typography style={inlineBlockBoldStyle}>
        <a
          href={link}
          target={'_blank'}
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          {value}
        </a>
      </Typography>
    </div>
  );
};

export const RenderQCSlideInfoFields = ({ slide }) => {
  let organName,
    speciesName,
    controlType,
    fixationTime,
    fixativeName = '';

  const stainName = slide.stain ? slide.stain : '';
  const { level, number, stain_type, thumbnail_ocr_text } = slide;

  const { sample } = slide;
  const sampleName = sample ? sample.name : '';
  if (sample) {
    organName = sample.organ ? sample.organ.name : '';
    speciesName = sample.species ? sample.species.name : '';
    controlType = sample.control_type ? sample.control_type : '';
    fixationTime = sample.fixation_time_hours ? sample.fixation_time_hours : '';
  }

  if (slide.order.fixative) {
    fixativeName = slide.order.fixative.name;
  }

  const lisaSlideLink = getLisaSlideLink({ slide });
  const lisaOrderLink = getLISAOrderLinkFromSlide({ slide });

  return (
    <Box mb={2}>
      <Grid container spacing={1} justifyContent="space-around">
        <Grid item xs={5}>
          <Box>
            <img
              src={slide.medium_macro_url}
              height={'auto'}
              width={'50%'}
              alt={'Macro Slide'}
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <AttributeLabel label={'Order Name'} value={slide.order.name} />
          <AttributeLabel label={'Sample Name'} value={sampleName} />
          <AttributeLabel label={'Stain Name'} value={stainName} />
          <AttributeLabel label={'Stain Type'} value={stain_type} />
          <AttributeLabel label={'Organ'} value={organName} />
          <AttributeLabel label={'Species'} value={speciesName} />
          <AttributeLabel label={'Level'} value={level} />
          <AttributeLabel label={'Number'} value={number} />
          <AttributeLabel label={'Control Type'} value={controlType} />
          <AttributeLabel
            label={'Fixation Time (Hours)'}
            value={fixationTime}
          />
          <AttributeLabel label={'Fixative'} value={fixativeName} />
          <AttributeLink label={'LIS'} value={'Slide'} link={lisaSlideLink} />
          <AttributeLink label={'LIS'} value={'Order'} link={lisaOrderLink} />
          <AttributeLabel label={'OCR Text'} value={thumbnail_ocr_text} />
        </Grid>
      </Grid>
    </Box>
  );
};

const getQCReasonCheckBoxBorderProps = (modelScore, isGLP = false) => {
  let borderColor = DEFAULT_QC_REASON_BORDER_COLOR;
  let borderSize = DEFAULT_QC_REASON_BORDER_SIZE;

  if (isGLP) {
    return {
      borderColor,
      borderSize,
    };
  }

  if (isQCScorePassed(modelScore) && modelScore !== null) {
    borderColor = QC_REASON_PASSED_BORDER_COLOR;
    borderSize = QC_REASON_PASSED_OR_FAILED_BORDER_SIZE;
  } else if (isQCScoreFailed(modelScore)) {
    borderColor = QC_REASON_FAILED_BORDER_COLOR;
    borderSize = QC_REASON_PASSED_OR_FAILED_BORDER_SIZE;
  }

  return {
    borderColor,
    borderSize,
  };
};

const QCReasonCheckbox = ({
  classes,
  check,
  handleCheckboxChanged,
  label,
  qcScore,
  isModelOverlayEnabled,
  handleModelOverlayClick,
  qcData,
  isGLP = false,
}) => {
  const modelScore = qcScore && Number(qcScore).toFixed(2);

  const { borderColor, borderSize } = getQCReasonCheckBoxBorderProps(
    modelScore,
    isGLP,
  );

  const isModelOverlayOptionVisible = !isGLP && qcData?.score !== undefined;
  const modelOverlayButtonStyles = useMemo(
    () => ({
      backgroundColor: isModelOverlayEnabled ? 'lightblue' : 'lightgray',
      marginRight: '0.5rem',
    }),
    [isModelOverlayEnabled],
  );

  const handleModelOverlayButtonClick = () => {
    handleModelOverlayClick(isModelOverlayEnabled);
  };

  return (
    <Fragment>
      <Box>
        <div className={classes.qcCheckFailContainer}>
          <Box
            border={borderSize}
            borderColor={borderColor}
            borderRadius={1}
            mt={1}
            className={classes.qcCheckFailContainer}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Checkbox
                  checked={check}
                  onChange={handleCheckboxChanged}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                {label}
              </Grid>
              {isModelOverlayOptionVisible && (
                <Grid item>
                  <IconButton
                    onClick={handleModelOverlayButtonClick}
                    size="small"
                    style={modelOverlayButtonStyles}
                    title="Model Overlay"
                  >
                    <GridViewOutlined />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Box>
        </div>
      </Box>
    </Fragment>
  );
};

export const QCPassFailModalHook = ({
  slide,
  handleQCReviewed,
  isSlideLoading,
  handleNextSlide,
  handlePreviousSlide,
  selectedRows,
  getNextRow,
}) => {
  const [specialInstructionsCheckboxes, setSpecialInstructionsCheckboxes] =
    useState(null);
  const { classes } = useQCPassFailModalHookStyles();
  const context = useContext(AppContext);
  const email = useSelector(currentUserEmailSelector);
  const { handleContextModalChange } = context;
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const [internal_notes, setInternalNotes] = useState('');
  const [external_comment, setExternalComment] = useState('');
  const [isActiveOrderNotes, setIsActiveOrderNotes] = useState(false);
  const [isActiveOrderNotesChecked, setIsActiveOrderNotesChecked] =
    useState(false);

  const [qcChecks, setQCChecks] = useState(DEFAULT_QC_FAILED_CHECKS);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentQcScoreDisplay, setCurrentQcScoreDisplay] = useState(null);

  const [postQCSlide] = usePostQCSlideMutation();

  const { showError } = useSnackbar();

  const isGLP = isGLPSlide(slide);

  const isSpecialInstructionsChecked = useMemo(
    () =>
      specialInstructionsCheckboxes?.every(
        (specialInstruction) => specialInstruction.checked,
      ),
    [specialInstructionsCheckboxes],
  );

  useEffect(() => {
    if (!slide?.order) return;

    setSpecialInstructionsCheckboxes(serializeDataForCheckboxes(slide.order));
  }, [slide]);

  const handleTextChange = (name) => (event) => {
    const mapInputNameToSetter = {
      external_comment: setExternalComment,
      internal_notes: setInternalNotes,
    };

    const setter = mapInputNameToSetter[name];
    setter(event.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    const postParams = getPostParams();

    postQCSlide({ slideUUID: slide.uuid, postParams })
      .unwrap()
      .then(() => {
        handleQCReviewed({ slide });
      })
      .catch(() => showError('Failed to submit QC review. Please try again.'))
      .finally(() => setIsSubmitting(false));

    if (selectedRows && selectedRows.length === 1) {
      // turn off modals if this is the last one
      handleContextModalChange(modalContextName)();
      // if only one is selected, then move onto the next one
      getNextRow();
    }
  };

  const checkIfFailedQC = () => {
    const result = Object.values(qcChecks).some((value) => value);
    return result;
  };

  const getPostParams = () => {
    const passedQC = !checkIfFailedQC();

    const postParams = {
      internal_notes,
      ...(external_comment && { external_comment }),
      ...(passedQC && { passed_qc_datetime: moment().toISOString() }),
      ...(!passedQC && getFailedQCPostParams()),
    };

    return postParams;
  };

  const getFailedQCPostParams = () => {
    const [primaryFailReason, redoAction] = getPrimaryFailReasonAndRedoAction();
    const qcPostStates = getQCPostStates();

    const params = {
      ...qcPostStates,
      failed_qc_reason: primaryFailReason,
      needs_redo: true,
      needs_redo_type: redoAction,
      failed_qc_datetime: moment().toISOString(),
    };

    return params;
  };

  const getQCPostStates = () => {
    const qcPostStates = ML_QC_OPTIONS_RECORDS.reduce(
      (prevValue, currValue) => {
        if (!currValue.stateAttribute) {
          return prevValue;
        }
        return {
          ...prevValue,
          [currValue.stateAttribute]: qcChecks[currValue.attribute]
            ? QC_FAILED_STATE_CONSTANT
            : QC_PASSED_STATE_CONSTANT,
        };
      },
      {},
    );

    return qcPostStates;
  };

  const getPrimaryFailReasonAndRedoAction = () => {
    const checkedOptions = ML_QC_OPTIONS_RECORDS.filter(
      ({ attribute }) => qcChecks[attribute],
    );
    const mainOption = checkedOptions.sort(
      (a, b) => b.reasonPriority - a.reasonPriority,
    )[0];
    return [mainOption.failConditionReasonName, mainOption.redoAction];
  };

  const modalActions = () => {
    let submitLabel, buttonStyle;

    const qc_failed = checkIfFailedQC();

    if (!qc_failed) {
      submitLabel = 'QC PASSED';
      buttonStyle = classes.passedQCButton;
    } else {
      submitLabel = 'QC FAILED';
      buttonStyle = classes.failedQCButton;
    }

    const submitTooltipTitle = isSpecialInstructionsChecked
      ? isActiveOrderNotes && isActiveOrderNotesChecked
        ? ''
        : REVIEW_ACTIVE_ORDER_NOTES_TEXT
      : REVIEW_SPECIAL_INSTRUCTIONS_TEXT;

    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        wrap="nowrap"
        className={classes.dialogActionsWrapper}
      >
        <Grid item container alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              color={'primary'}
              onClick={handlePreviousSlide}
              disabled={previousDisabled}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={'secondary'}
              onClick={handleNextSlide}
              style={{ marginLeft: '1rem' }}
              disabled={nextDisabled}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Button
              color="primary"
              onClick={handleContextModalChange(modalContextName)}
              style={{ marginRight: '1rem' }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Tooltip title={submitTooltipTitle}>
              <span>
                <Button
                  variant="contained"
                  color="secondary"
                  className={buttonStyle}
                  onClick={handleSubmit}
                  disabled={
                    isSubmitting ||
                    !isSpecialInstructionsChecked ||
                    (isActiveOrderNotes && !isActiveOrderNotesChecked)
                  }
                >
                  <SubmittingIcon submitting={isSubmitting} size={10} />
                  {submitLabel}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleCheckboxChanged = (optionName) => (e, value) => {
    setQCChecks((prevState) => ({
      ...prevState,
      [optionName]: value,
    }));
  };

  const handleModelOverlayClick = (optionName) => (isEnabled) => {
    const optionToSet = isEnabled ? null : optionName;
    setCurrentQcScoreDisplay(optionToSet);
  };

  const handleSpecialInstructionCheckboxChanged = (field) => {
    setSpecialInstructionsCheckboxes((prevState) =>
      prevState.map((checkbox) => {
        if (checkbox.field !== field) {
          return checkbox;
        } else {
          checkbox.checked = !checkbox.checked;
          return checkbox;
        }
      }),
    );
  };

  const SpecialInstructionLabel = React.forwardRef(function MyComponent(
    props,
    ref,
  ) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <b>{props.label}</b>
      </div>
    );
  });

  const SpecialInstructionTooltip = ({ children, specialInstruction }) => {
    const isAttachedFileField =
      specialInstruction.field === 'special_instructions_attachment';

    return (
      <Tooltip
        title={
          isAttachedFileField ? (
            <React.Fragment>
              <Attachment file={specialInstruction.text} />
            </React.Fragment>
          ) : (
            specialInstruction.text
          )
        }
        placement="right"
      >
        {children}
      </Tooltip>
    );
  };

  const handleCheckAll = () => {
    setSpecialInstructionsCheckboxes((prevValue) =>
      prevValue.map((checkbox) => {
        checkbox.checked = true;
        return checkbox;
      }),
    );
  };

  const renderSpecialInstructionsCheckboxes = () => {
    if (!specialInstructionsCheckboxes) return;

    return (
      <Grid container direction="column">
        {specialInstructionsCheckboxes.map((specialInstruction) => (
          <Grid
            key={specialInstruction.field}
            container
            direction="row"
            alignItems="center"
          >
            <Checkbox
              style={{
                color: specialInstruction.checked ? 'green' : 'red',
              }}
              checked={specialInstruction.checked}
              onChange={() =>
                handleSpecialInstructionCheckboxChanged(
                  specialInstruction.field,
                )
              }
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            {specialInstruction.field === 'special_instructions_attachment' ? (
              <SpecialInstructionTooltip
                specialInstruction={specialInstruction}
              >
                <SpecialInstructionLabel label={specialInstruction.label} />
              </SpecialInstructionTooltip>
            ) : (
              <>
                <b>{specialInstruction.label}: </b>
                <Typography className={classes.specialInstructionText}>
                  {specialInstruction.text}
                </Typography>
              </>
            )}
          </Grid>
        ))}
        {specialInstructionsCheckboxes.length > 1 ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCheckAll}
            className={classes.checkAllButton}
            disabled={isSpecialInstructionsChecked}
          >
            <DoneAllIcon />
            Check All
          </Button>
        ) : null}
      </Grid>
    );
  };

  const renderFailFormInputs = () => {
    return (
      <Fragment>
        <Typography variant={'subtitle1'} color={'inherit'}>
          <b>Check All That Apply:</b>
        </Typography>
        <Grid container spacing={1}>
          {ML_QC_OPTIONS_RECORDS.map((option) => (
            <Grid item key={option.attribute} xs={6}>
              <QCReasonCheckbox
                classes={classes}
                check={qcChecks[option.attribute]}
                label={option.label}
                handleCheckboxChanged={handleCheckboxChanged(option.attribute)}
                qcScore={slide[option.scoreAttribute]}
                isModelOverlayEnabled={
                  currentQcScoreDisplay === option.dataAttribute
                }
                handleModelOverlayClick={handleModelOverlayClick(
                  option.dataAttribute,
                )}
                qcData={slide.additional_data?.[option.dataAttribute]}
                isGLP={isGLP}
              />
            </Grid>
          ))}
        </Grid>
      </Fragment>
    );
  };

  const isOpen = context[modalContextName];

  if (!(slide && isOpen)) {
    return null;
  }

  const toggleAccordion = () => {
    setIsAccordionExpanded((prev) => !prev);
  };

  const onClose = handleContextModalChange(modalContextName);

  const modalSlideID = `${slide.uuid}-modal`;

  const selectedRowsUUIDs = selectedRows.map((slide) => slide.uuid);
  const currentSelectedLocation = selectedRowsUUIDs.indexOf(slide.uuid);
  const previousDisabled = currentSelectedLocation === 0;
  const nextDisabled = currentSelectedLocation === selectedRows.length - 1;

  const isShowModelOverlayEnabled = !isGLP && currentQcScoreDisplay !== null;

  const slideDetailsSampleInfo = slide.sample?.name
    ? ` - Sample: ${slide.sample?.name}`
    : '';
  const slideDetailsTitle = `Details: ${slide.order?.name}${slideDetailsSampleInfo}`;
  const specialInstructionsAccordionTitle = `Special Instructions for ${slide.order?.name}`;

  const ActiveOrderNotes = () => {
    const projectNotes = slide.order?.project?.active_order_notes || '';
    const teamNotes = slide.order?.project?.team?.active_order_notes || '';
    const organizationNotes =
      slide.order?.project?.team?.organization?.active_order_notes || '';

    if (!projectNotes && !teamNotes && !organizationNotes) {
      return null;
    }

    setIsActiveOrderNotes(projectNotes || teamNotes || organizationNotes);

    return (
      <Grid p={2} bgcolor={LIGHT_GREY}>
        <Typography variant="subtitle1" color="inherit" fontSize={20}>
          <b>Active Order Notes:</b>
        </Typography>
        <Grid container mb={3}>
          {projectNotes && (
            <Grid item xs={12}>
              <Typography variant="body1" marginTop={2} fontSize={18}>
                <b>Project Notes:</b> {projectNotes}
              </Typography>
            </Grid>
          )}
          {teamNotes && (
            <Grid item xs={12}>
              <Typography variant="body1" marginTop={2} fontSize={18}>
                <b>Team Notes:</b> {teamNotes}
              </Typography>
            </Grid>
          )}
          {organizationNotes && (
            <Grid item xs={12}>
              <Typography variant="body1" marginTop={2} fontSize={18}>
                <b>Organization Notes:</b> {organizationNotes}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container alignItems="center">
          <Grid item className={classes.activeOrderNotesCheckboxWrapper}>
            <Checkbox
              className={classes.activeOrderNotesCheckbox}
              color="success"
              checked={isActiveOrderNotesChecked}
              onChange={() =>
                setIsActiveOrderNotesChecked(!isActiveOrderNotesChecked)
              }
            />
            <Typography variant="body1" fontWeight="bold">
              I have read and understand the active order notes.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {!isSlideLoading && (
        <DefaultDialog
          open={isOpen}
          onClose={onClose}
          actions={modalActions}
          title={slide.name}
          className={classes.dialog}
        >
          <Box width={'90vw'} height={'80vh'}>
            <Grid
              container
              spacing={1}
              className={classes.dialogContentWrapper}
            >
              <Grid item xs={8}>
                <SimpleSlideView
                  slide={slide}
                  id={modalSlideID}
                  showNavigator={true}
                  showOverlay={isShowModelOverlayEnabled}
                  scoreToDisplay={currentQcScoreDisplay}
                  isQCViewer={true}
                />
              </Grid>
              <Grid item xs={4} className={classes.qcReviewSidebarWrapper}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{slideDetailsTitle}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {RenderQCSlideInfoFields({ slide })}
                  </AccordionDetails>
                </Accordion>
                <Box my={2}>
                  <Divider />
                </Box>
                <Grid item xs={12}>
                  <Typography
                    color={'inherit'}
                    style={{ marginBottom: '1rem' }}
                    variant={'subtitle1'}
                  >
                    Quality Control Review by {email}
                  </Typography>
                  <ActiveOrderNotes />
                  {specialInstructionsCheckboxes?.length ? (
                    <Accordion
                      expanded={isAccordionExpanded}
                      className={classes.accordion}
                    >
                      <AccordionSummary
                        onClick={toggleAccordion}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography>
                          {specialInstructionsAccordionTitle}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {renderSpecialInstructionsCheckboxes(slide.order)}
                      </AccordionDetails>
                    </Accordion>
                  ) : null}

                  {renderFailFormInputs()}
                  <TextField
                    id="standard-multiline-flexible"
                    label="Internal Slide Notes / Change Reason"
                    style={{ marginTop: '1rem' }}
                    placeholder="Internal Slide Notes / Change Reason"
                    fullWidth={true}
                    multiline
                    defaultValue={slide.internal_notes}
                    onChange={handleTextChange('internal_notes')}
                    className={classes.textField}
                  />
                  <TextField
                    id="standard-multiline-flexible"
                    label="External Slide Comment"
                    style={{ marginTop: '1rem' }}
                    placeholder="External Slide Comment"
                    fullWidth={true}
                    multiline
                    onChange={handleTextChange('external_comment')}
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DefaultDialog>
      )}
    </>
  );
};
