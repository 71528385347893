import { object, string } from 'yup';

const stringRequirements = string().trim();

export const suggestFeatureSchema = object().shape({
  title: stringRequirements,
  description: stringRequirements
    .required('A description is required.')
    .max(3000, 'Description must be less than 3000 characters.'),
});

export const SUCCESS_FEATURE_REQUEST = 'Submitted. Thanks for your request.';
export const FAIL_FEATURE_REQUEST = 'Something went wrong. Please try again.';
