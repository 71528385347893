import { makeStyles } from 'tss-react/mui';

export const useCommonCardStyles = makeStyles()({
  boxSearch: {
    top: 1,
    position: 'sticky',
  },
  slideCard: {
    // marginTop: "0.5rem",
    backgroundColor: '#ffffff4c',
    border: '1px solid',
    borderColor: '#0000000d',
    borderRadius: '4px',
    boxShadow: '0px 8px 20px 15px #0000000d',
    overflow: 'hidden',
  },
  bodyWithAction: {
    cursor: 'pointer',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    // TODO: remove after getting feedback regarding colors
    // border: "1px solid rgba(0, 0, 0, 0.05)",
    // background: "rgba(108,162,187,0.5)",
    background:
      'linear-gradient(180deg, rgba(108, 162, 187, 0.45), rgba(108, 162, 187, 0.10))',
    paddingLeft: '3rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingRight: '3rem',
  },
});
