import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const ProjectCellRenderer = ({
  cellData,
  collapsedProjectsList,
  setCollapsedProjectsList,
}) => {
  const currentFirstProject = collapsedProjectsList.find(
    (item) => item.id === cellData.project_uuid,
  );

  const toggleHandler = () => {
    if (!showToggleIcon) return;
    setCollapsedProjectsList((prev) => {
      return prev.map((item) =>
        item.id === cellData.project_uuid
          ? { ...item, collapse: !item.collapse }
          : item,
      );
    });
  };

  const showToggleIcon = currentFirstProject && cellData.isFirstInProject;
  const paddingStyles = !cellData.isFirstInProject
    ? '20px'
    : showToggleIcon
    ? 0
    : '20px';

  return (
    <span
      style={{
        opacity: cellData.isFirstInProject ? 1 : 0.7,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: paddingStyles,
      }}
      onClick={toggleHandler}
      id="no-edit"
    >
      {showToggleIcon &&
        (currentFirstProject.collapse ? (
          <KeyboardArrowRightIcon
            fontSize="small"
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <KeyboardArrowDownIcon
            fontSize="small"
            style={{ cursor: 'pointer' }}
          />
        ))}
      {cellData.project_name}
    </span>
  );
};
