import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useOrderTypesTabsStyles } from 'components/UserProfile/styles';

export const HeaderTabs = ({ currentTabValue, handleTabChange, tabsList }) => {
  const { classes } = useOrderTypesTabsStyles();

  return (
    <Tabs
      value={currentTabValue}
      onChange={handleTabChange}
      indicatorColor={'primary'}
      classes={{
        root: classes.tabs,
        indicator: classes.indicator,
      }}
    >
      {tabsList.map((tab) => (
        <Tab
          key={tab.label}
          classes={{
            root: classes.tab,
            selected: classes.selectedTab,
          }}
          value={tab.value}
          label={tab.label}
        />
      ))}
    </Tabs>
  );
};
