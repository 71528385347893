import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isStaffSelector } from 'store/slices/userDetailsSlice';

export const ShowStaffUserOnlyPage = ({ children }) => {
  const isStaff = useSelector(isStaffSelector);

  if (isStaff) {
    return children;
  }

  return <Redirect to="/" />;
};
