import { Box, Button, TextField, Typography } from '@mui/material';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { MODAL_WITHOUT_BACKGROUND } from 'components/SlideGroups/utilities/styles';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import React, { useState } from 'react';
import pdfFile from 'files/terms_and_conditions_hw.pdf';
import { useTermsAndConditionsReviewModalStyles } from 'components/TeamOverview/components/TermsAndConditionsReviewModal/styles';
import moment from 'moment/moment';
import { DATE_REQUEST_FORMAT } from 'utilities/dates_and_times';
import { Page, Document, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const TermsAndConditionsReviewModal = ({
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  const { classes } = useTermsAndConditionsReviewModalStyles();

  const [userName, setUserName] = useState('');

  return (
    <DefaultModal
      open={isOpen}
      onClose={handleClose}
      styles={{
        ...MODAL_WITHOUT_BACKGROUND,
        height: 'min-content',
        width: '1000px',
      }}
      closeIcon
    >
      <Card
        cardClass={classes.cardContainer}
        header={
          <Box padding="1.375rem">
            <Typography className={classes.cardTitle}>
              Terms and conditions
            </Typography>
          </Box>
        }
      >
        <Box className={classes.cardContent}>
          <Box className={classes.termAndConditionsContainer}>
            <Document file={pdfFile}>
              <Page
                className={classes.termsPage}
                pageNumber={1}
                wrap={false}
                scale={1.45}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          </Box>
          <Box>
            <Box className={classes.inputRow}>
              <Typography className={classes.inputLabel}>
                Enter your Name:
              </Typography>
              <TextField
                hiddenLabel={true}
                size={'small'}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                className={classes.input}
                id="outlined-basic"
                variant="outlined"
              />
            </Box>
            <Box className={classes.inputRow}>
              <Typography className={classes.inputLabel}>Date:</Typography>
              <TextField
                hiddenLabel={true}
                disabled={true}
                value={moment(Date.now()).format(DATE_REQUEST_FORMAT)}
                size={'small'}
                variant="outlined"
              />
            </Box>
          </Box>
          <Box className={classes.buttonsSection}>
            <Button
              variant="outlined"
              color="pink"
              className={classes.button}
              onClick={handleClose}
            >
              BACK
            </Button>
            <Button
              marginLeft="2rem"
              variant="contained"
              color="pink"
              className={classes.button}
              onClick={() => handleSubmit(userName)}
              disabled={!userName}
            >
              ACCEPT
            </Button>
          </Box>
        </Box>
      </Card>
    </DefaultModal>
  );
};
