import { memo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Box,
} from '@mui/material';

import { useChartStyles } from 'components/OrderSlidesChart/styles';

const SelectList = ({
  values,
  onChange,
  onChecked,
  labels,
  isLoading,
  allSelectable,
  allSlidesSelected,
  handleSelectAllSlides,
}) => {
  const { classes } = useChartStyles();

  return (
    <Box className={classes.listContainer}>
      {isLoading ? (
        <CircularProgress className={classes.spinner} />
      ) : (
        <FormGroup>
          <ul className={classes.list}>
            {allSelectable && (
              <li className={classes.listItem}>
                <FormControlLabel
                  id={'selectAllSlides'}
                  key={'selectAll'}
                  control={
                    <Checkbox
                      checked={allSlidesSelected}
                      onChange={handleSelectAllSlides}
                      name={'Select all'}
                    />
                  }
                  className={`${classes.selectAll} ${classes.label}`}
                  label={'Select all'}
                />
              </li>
            )}
            {values?.map((value, index) => (
              <li className={classes.listItem} key={value}>
                <FormControlLabel
                  className={classes.label}
                  key={value}
                  data-recording-click-props={
                    '{"chartOptionsSelection":"' + value + '"}'
                  }
                  control={
                    <Checkbox
                      checked={!!onChecked[value]}
                      onChange={onChange}
                      name={value}
                    />
                  }
                  label={labels ? labels[index] : value}
                />
              </li>
            ))}
          </ul>
        </FormGroup>
      )}
    </Box>
  );
};

export const SelectListComponent = memo(SelectList);
