import { Grid, Typography } from '@mui/material';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';
import React from 'react';
import { Link } from 'react-router-dom';
import { prettifyTrackerState } from 'components/OrderDetails/Tabs/OrderProgressTab/utilities';

export const DataRow = ({
  slideViewerUrl,
  orderName,
  slideName,
  toolType,
  cost,
  status,
  precessedOnDate,
  classes,
}) => {
  return (
    <Grid container className={classes.row}>
      <Grid item xs={1.5}>
        <Typography className={classes.defaultLabel}>{orderName}</Typography>
      </Grid>
      <Grid item xs={3} paddingRight={'0.5rem'}>
        <TextWithLimitedWidth value={slideName} textClass={classes.boldLabel} />
      </Grid>
      <Grid item xs={2.5}>
        <Typography className={classes.defaultLabel}>{toolType}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography className={classes.defaultLabel}>${cost}</Typography>
      </Grid>
      <Grid item xs={2}>
        {slideViewerUrl ? (
          <Link className={classes.link} to={slideViewerUrl}>
            {prettifyTrackerState(status)}
          </Link>
        ) : (
          <Typography className={classes.defaultLabel}>
            {prettifyTrackerState(status)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.defaultLabel}>
          {precessedOnDate}
        </Typography>
      </Grid>
    </Grid>
  );
};
