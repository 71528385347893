import { Box, Typography } from '@mui/material';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';
import { ToggleButtons } from 'components/AntibodiesList/ToggleButtons';
import { useAntibodyDetailsStyles } from 'components/AntibodiesList/styles';

export const DetailsHeader = ({
  selectedAntibody,
  selectedViewMode,
  setSelectedViewMode,
  thumbnail,
}) => {
  const handleToggle = (event, newSelected) => {
    setSelectedViewMode(newSelected);
  };

  const { classes } = useAntibodyDetailsStyles();

  return (
    <Box mb={1} className={classes.header}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>Antibodies Catalog</Typography>
        <Box className={classes.subTitle}>
          <TextWithLimitedWidth
            containerClass={classes.truncate}
            value={selectedAntibody?.display_name}
            tooltipPlacement={'bottom'}
          />
        </Box>
      </Box>
      {thumbnail && (
        <ToggleButtons
          handleToggle={handleToggle}
          selected={selectedViewMode}
          classes={classes}
        />
      )}
    </Box>
  );
};
