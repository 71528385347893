import { makeStyles } from 'tss-react/mui';
import { LIGHT_GREY, PRIMARY_WHITE } from 'utilities/colors';

export const useStyles = makeStyles()((_, { isIFViewer }) => ({
  title: {
    fontSize: '1.125rem',
  },
  slideName: {
    fontWeight: 'bold',
  },
  iconsWrapper: {
    display: 'flex',
    padding: isIFViewer ? '0.625rem 0' : '1rem 0',
    borderBottom: isIFViewer ? `1px solid ${LIGHT_GREY}` : 'none',
  },
  iconWrapper: {
    marginRight: isIFViewer ? '0.938rem' : '1.563rem',
    width: isIFViewer ? '45px' : '60px',
    height: isIFViewer ? '45px' : '60px',
    backgroundColor: PRIMARY_WHITE,
    borderRadius: '50%',
    position: 'relative',
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow: `0px 0px 7px 5px ${LIGHT_GREY}`,
    },
  },
  icon: {
    height: isIFViewer ? '24px' : '35px',
    width: isIFViewer ? '24px' : '35px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  inputWrapper: {
    width: isIFViewer ? 'calc(100% - 110px)' : '100%',
    '.MuiInputBase-root:focus-visible': {
      outline: 'none',
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline, ': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: isIFViewer ? '1.25rem' : '0.938rem',
  },
  inputLabel: {
    width: '14.3rem',
  },
  input: {
    padding: '6px 8px',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  copyButton: {
    marginLeft: '10px',
    padding: '5px 12px',
  },
  iframe: {
    width: '100%',
    marginTop: '1.25rem',
    border: '0px',
    height: '250px',
    overflow: 'hidden',
  },
}));
