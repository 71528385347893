import {
  allOrdersFinishedStates,
  FINISHED_ORDER,
  finishedOrderStates,
  ignoreOrderStates,
  IN_PROGRESS_ORDER,
  UNSUBMITTED_ORDER,
  unsubmittedOrderStates,
} from 'components/OrdersList/constants';
import { difference } from 'lodash';
import { isIAOrPCOrder } from 'utilities/orders';

const initialOrders = {
  finishedOrders: [],
  inProgressOrders: [],
  unsubmittedOrders: [],
};

const isUnsubmittedOrder = (order) =>
  unsubmittedOrderStates.includes(order.state);
const isFinishedOrder = (order) =>
  (finishedOrderStates.includes(order.state) && !isIAOrPCOrder(order)) ||
  allOrdersFinishedStates.includes(order.state);

export const splitOrders = (orders) => {
  if (!orders) return { ...initialOrders };

  const unsubmittedOrders = orders.filter(isUnsubmittedOrder);

  const finishedOrders = orders.filter(isFinishedOrder);

  const notClassifiedOrders = difference(orders, [
    ...unsubmittedOrders,
    ...finishedOrders,
  ]);

  const inProgressOrders = notClassifiedOrders.filter(
    (order) => !ignoreOrderStates.includes(order.state),
  );

  return {
    finishedOrders: [...finishedOrders],
    inProgressOrders: [...inProgressOrders],
    unsubmittedOrders: [...unsubmittedOrders],
  };
};

export const sorterForChangelog = (a, b) => new Date(b.date) - new Date(a.date);

export const getOrderStatus = (order) => {
  if (!order) return null;

  if (isUnsubmittedOrder(order)) return UNSUBMITTED_ORDER;
  if (isFinishedOrder(order)) return FINISHED_ORDER;
  return IN_PROGRESS_ORDER;
};
