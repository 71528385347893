import { useBillingShippingStyles } from 'components/OrderForm/BillingShipping/styles';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutFormV2 } from 'components/CheckoutForm/CheckoutFormV2';
import { stripePromise } from 'components/OrderForm/BillingShipping/utilities/constants';
import { useIsDemoUser } from 'components/utilities/hooks/useIsDemoUser';

export const AddCreditCardForm = ({ handleCreditCardUpdate }) => {
  const { classes } = useBillingShippingStyles();
  const isDemoAccount = useIsDemoUser();

  if (isDemoAccount) {
    return (
      <div>
        <br />
        Demo Users Are Not Allowed to Add Credit Cards
      </div>
    );
  }

  return (
    <div className={classes.selectForm}>
      <Elements stripe={stripePromise}>
        <CheckoutFormV2 onUpdate={handleCreditCardUpdate} />
      </Elements>
    </div>
  );
};
