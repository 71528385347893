import {
  ORDER_RESOURCE_NAME,
  SAMPLES_RESOURCE_NAME,
} from 'services/resources/apiConstants';
import { backendAPI } from 'services/backendAPI';

//TODO: switch ReviewView component to the functional and then use query from RTK.
export const getSamples = (orderUUID) => {
  const samplesURL = `${ORDER_RESOURCE_NAME}${orderUUID}/${SAMPLES_RESOURCE_NAME}`;

  return backendAPI.get(samplesURL, { cache: false }).then((response) => {
    // TODO - Refactor this to return response.data instead of response (find all the call points)
    return response;
  });
};
