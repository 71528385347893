import { OpenSeaDragonWithScalebar } from 'components/ImageViewer/OpenSeaDragonScalebar';
import {
  buildSlide,
  getFromLS,
  parseSlide,
  saveSlideToLS,
  SLIDES_BOOKMARKS,
} from 'components/OrderSlidesList/utilities';

(function ($) {
  if (!$) {
    console.error('[openseadragon-bookmark-url] requires OpenSeadragon');
    return;
  }

  // ----------
  $.Viewer.prototype.bookmarkUrl = function (options) {
    const slideId = options.id;
    options = options || {};
    var trackPage = options.trackPage || false;

    var self = this;

    var updateTimeout;

    const getSlidesFromHash = function () {
      const hash = window.location.hash.replace(/^#/, '');
      return hash ? hash.split(';') : [];
    };

    // This function split hash string into slides and parse them to json objects
    const parseHash = function () {
      let parsedSlides = {};
      const rawSlidesCoordinatesFromHash = getSlidesFromHash();
      if (rawSlidesCoordinatesFromHash.length) {
        const matchedSlide = rawSlidesCoordinatesFromHash.find((element) =>
          element.includes(slideId),
        );
        if (matchedSlide) {
          parsedSlides = parseSlide(matchedSlide);
        }
      }

      return parsedSlides;
    };

    const getViewersCount = () => self.world.getItemCount();

    const setZoomAndCenter = (x, y, zoom) => {
      self.viewport.zoomTo(zoom, null, false);
      self.viewport.panTo(new $.Point(x, y), true);
    };

    const updateUrl = function () {
      // We only update once it's settled, so we're not constantly flashing the URL.
      clearTimeout(updateTimeout);
      updateTimeout = setTimeout(function () {
        const zoom = self.viewport.getZoom();
        const pan = self.viewport.getCenter();
        const rotation = self.viewport.getRotation();
        const bookmarksFromLS = getFromLS(SLIDES_BOOKMARKS);
        const slides = getSlidesFromHash();
        let url;
        if (slides.length) {
          let isCurrentSlideExists = false;
          const updatedSlides = slides.map((slide) => {
            if (slide.includes(slideId)) {
              isCurrentSlideExists = true;
              const parsedSlide = parseSlide(slide);
              parsedSlide.x = pan.x;
              parsedSlide.y = pan.y;
              parsedSlide.zoom = zoom;
              parsedSlide.rotation = rotation;

              saveSlideToLS(parsedSlide);
              return buildSlide(parsedSlide);
            }

            return slide;
          });

          if (!isCurrentSlideExists) {
            const currentSlide = {
              zoom,
              x: pan.x,
              y: pan.y,
              slideId,
              rotation: 180,
            };

            const bookmark = bookmarksFromLS[slideId];

            if (bookmark) {
              setZoomAndCenter(bookmark.x, bookmark.y, bookmark.zoom);
              applyRotation(bookmark);
            }

            saveSlideToLS(currentSlide);
            updatedSlides.push(buildSlide(currentSlide));
          }

          url = `${window.location.pathname}#${updatedSlides.join(';')}`;
        } else if (getViewersCount() > 0) {
          const bookmark = bookmarksFromLS[slideId];

          if (bookmark) {
            setZoomAndCenter(bookmark.x, bookmark.y, bookmark.zoom);
            applyRotation(bookmark);

            url = `${window.location.pathname}#${buildSlide({
              x: bookmark.x,
              y: bookmark.y,
              zoom: bookmark.zoom,
              rotation,
              slideId,
            })}`;
          } else {
            url = `${window.location.pathname}#${buildSlide({
              x: pan.x,
              y: pan.y,
              zoom,
              rotation,
              slideId,
            })}`;
          }
        }

        window.history.replaceState({}, '', url);
      }, 100);
    };

    const applyPanCoordinates = (params) => {
      const pan = self.viewport.getCenter();

      if (
        params.x !== undefined &&
        params.y !== undefined &&
        (params.x !== pan.x || params.y !== pan.y)
      ) {
        self.viewport.panTo(new $.Point(params.x, params.y), true);
      }
    };

    const applyRotation = (params) => {
      if (params.rotation !== undefined && getViewersCount() > 0) {
        self.viewport.setRotation(params.rotation);
      }
    };

    const handleParams = function (params) {
      const zoom = self.viewport.getZoom();
      const page = self.currentPage();

      if (trackPage && params.page !== undefined && params.page !== page) {
        self.goToPage(params.page);
        self.addOnceHandler('open', function () {
          if (params.zoom !== undefined) {
            self.viewport.zoomTo(params.zoom, null, true);
          }
          applyPanCoordinates(params);
          applyRotation(params);
        });
      } else {
        if (params.zoom !== undefined && params.zoom !== zoom) {
          self.viewport.zoomTo(params.zoom, null, true);
        }
        applyPanCoordinates(params);
        applyRotation(params);
      }
    };

    const parsedSlides = parseHash();

    if (getViewersCount() === 0) {
      this.addOnceHandler('open', function () {
        handleParams(parsedSlides);
      });
    } else {
      handleParams(parsedSlides);
    }

    this.addHandler('viewport-change', updateUrl);
    this.addHandler('rotate', updateUrl);
    this.addHandler('zoom', updateUrl);

    if (trackPage) {
      this.addHandler('page', updateUrl);
    }

    // Note that out own replaceState calls don't trigger hashchange events, so this is only if
    // the user has modified the URL (by pasting one in, for instance).
    window.addEventListener(
      'hashchange',
      function () {
        handleParams(parseHash());
      },
      false,
    );
  };
})(OpenSeaDragonWithScalebar);

export const OpenSeaDragonWithBookmarkUrl = OpenSeaDragonWithScalebar;
