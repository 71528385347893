import React from 'react';
import { Grid } from '@mui/material';
import { ThumbnailsGroupPreview } from 'components/SlideGroups/Views/CardView/ThumbnailsGroupPreview';
import { Card } from 'components/common/Card/Card';
import { DefaultSlideGroupHeader } from 'components/SlideGroups/components/DefaultSlideGroupHeader';
import { FavoriteSlideGroupHeader } from 'components/SlideGroups/components/FavoriteGroupHeader';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import { handleClickOnSlideGroupCard } from 'components/SlideGroups/utilities/utilities';
import { FAVORITE_SLIDES_GROUP } from 'utilities/constants';

export const SlideGroupComponent = ({ slideGroup }) => {
  const { slides, name } = slideGroup;
  const isFavorite = name === FAVORITE_SLIDES_GROUP;
  const { isStaff } = useSelector(userDetailsSelector);

  return (
    <Grid item xs={12} md={6} laptop={4}>
      <Card
        handleBodyClick={() => handleClickOnSlideGroupCard(slideGroup, isStaff)}
        headerContent={
          isFavorite ? (
            <FavoriteSlideGroupHeader slideGroup={slideGroup} />
          ) : (
            <DefaultSlideGroupHeader slideGroup={slideGroup} />
          )
        }
        bodyContent={<ThumbnailsGroupPreview slides={slides} />}
      />
    </Grid>
  );
};
