import { ProgressItemCard } from 'components/OrderDetails/Tabs/OrderProgressTab/ProgressItemCard';

import { memo } from 'react';
import { ProgressStepper } from 'components/OrderDetails/Tabs/OrderProgressTab/ProgressStepper';
import {
  prepareSteppers,
  prepareSteppersForCancelledOrder,
} from 'components/OrderDetails/Tabs/OrderProgressTab/utilities';
import { ORDER_CANCELLED_TRACKER_STATE } from 'components/OrdersList/constants';

export const OrderTracker = memo(({ order, orderHistory, samples }) => {
  if (!order) return null;

  const isOrderCancelled =
    order.tracker_state === ORDER_CANCELLED_TRACKER_STATE;

  const steppers = isOrderCancelled
    ? prepareSteppersForCancelledOrder(order, orderHistory, samples)
    : prepareSteppers(order, orderHistory);

  return (
    <ProgressItemCard label={order.name || 'Order progress'} width={'unset'}>
      {steppers.map((stepper) => (
        <ProgressStepper key={stepper.name} stepper={stepper} />
      ))}
    </ProgressItemCard>
  );
});
