import React, { Fragment, useState } from 'react';
import { usePathologyMapListItemStyles } from 'components/PathologyMap/utilities/styles';
import Box from '@mui/material/Box';
import { PathologyMapSlideCardHeader } from 'components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideCardHeader';
import { Fade } from '@mui/material';
import { ListItem } from 'components/PathologyMap/PathologyMapSlideCards/Views/ListView/ListItem';

export const PathologyMapSlideListView = ({ hits, nbHits, items }) => {
  const { classes } = usePathologyMapListItemStyles();
  const [hoveredSlide, setHoveredSlide] = useState(null);

  return (
    <Fragment>
      {hoveredSlide ? (
        <Fade in={true} appear={true} timeout={400}>
          <Box className={classes.floatingThumbnailContainer}>
            <img
              src={hoveredSlide.medium_thumbnail_url}
              height={'100%'}
              alt={'Macro Slide'}
            />
          </Box>
        </Fade>
      ) : null}
      {hits.map((hit, index) => (
        <Fade key={hit.uuid} in={true} appear={true} timeout={750}>
          <Box width="100%" paddingLeft={3} marginTop={2}>
            <Box
              className={classes.slideInfoCardHeaderContainer}
              onMouseEnter={() => setHoveredSlide(hit)}
              onMouseLeave={() => setHoveredSlide(null)}
            >
              <PathologyMapSlideCardHeader
                slide={hit}
                resultsCount={nbHits}
                index={index}
              />
            </Box>
            <Box key={hit.uuid} position="relative" width="100%" height="6rem">
              <ListItem slide={hit} classes={classes} selectedFilters={items} />
            </Box>
          </Box>
        </Fade>
      ))}
    </Fragment>
  );
};
