import { useInstructionsSectionStyles } from 'components/UserProfile/Upload/styles';
import { Typography, Box } from '@mui/material';
import { UPLOAD_FILE_INSTRUCTIONS } from 'components/UserProfile/constants';

export const InstructionsSection = () => {
  const { classes } = useInstructionsSectionStyles();

  return (
    <Box className={classes.instructionsContainer}>
      {UPLOAD_FILE_INSTRUCTIONS.map(({ capture, text }) => (
        <Box key={capture} className={classes.instruction}>
          <Typography className={classes.instructionCapture}>
            {capture}
          </Typography>
          <Typography className={classes.instructionText}>{text}</Typography>
        </Box>
      ))}
    </Box>
  );
};
