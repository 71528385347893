import { FETCH_DROPBOXES_ERROR_MESSAGE } from 'constants/errorMessages';

import { useEffect, useState } from 'react';
import { getDropboxes } from 'services/resources/commonResources';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { useSnackbar } from 'notistack';

export const useGetDropboxes = () => {
  const [dropboxOptions, setDropboxOptions] = useState([]);
  const unmountIgnore = useUnmountIgnore();
  const { showError } = useSnackbar();

  useEffect(() => {
    getDropboxes()
      .then((response) => {
        if (unmountIgnore.current) return;

        const options = response.map((data) => {
          data['label'] = data['name'];
          data['value'] = data['uuid'];
          return data;
        });

        setDropboxOptions(options);
      })
      .catch(() => showError(FETCH_DROPBOXES_ERROR_MESSAGE));
  }, [unmountIgnore]);

  return dropboxOptions;
};
