import React from 'react';
import { Select, Grid } from '@mui/material';

import { COLORMAP_OPTIONS } from 'components/IFViewer/constants';
import {
  useImageSettingsStore,
  useViewerStore,
} from 'components/IFViewer/state';

const DEFAULT_COLOR_SCHEMA_NAME = 'Default colors';

export const ColormapSelect = () => {
  const colormap = useImageSettingsStore((store) => store.colormap);
  const isViewerLoading = useViewerStore((store) => store.isViewerLoading);
  return (
    <Grid container paddingTop="1rem">
      <Select
        native
        fullWidth
        onChange={(e) =>
          useImageSettingsStore.setState({
            colormap:
              e.target.value === DEFAULT_COLOR_SCHEMA_NAME
                ? ''
                : e.target.value,
          })
        }
        value={colormap}
        inputProps={{
          name: 'colormap',
          id: 'colormap-select',
        }}
        disabled={isViewerLoading}
      >
        <option value="">{DEFAULT_COLOR_SCHEMA_NAME}</option>
        {COLORMAP_OPTIONS.map((name) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </Select>
    </Grid>
  );
};
