import { makeStyles } from 'tss-react/mui';
import { SECONDARY_WHITE } from 'utilities/colors';

export const useOrderFormSamplesV2Styles = makeStyles()((theme) => ({
  page: {
    height: '100vh',
    backgroundColor: SECONDARY_WHITE,
  },
  navigator: {
    height: '15vh',
  },
  gridStyle: {
    height: '400px',
    width: '100%',
    backgroundColor: 'transparent',
  },
  rightAlign: {
    float: 'right',
    marginTop: '1rem',
    marginBottom: '.25rem',
  },
  leftAlign: {
    marginTop: '1rem',
    marginBottom: '.25rem',
    float: 'left',
  },
  button: {
    marginRight: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  rightGridAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveAndContinueButton: {
    marginRight: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  saveAndContinueButtonError: {
    marginRight: theme.spacing(0.5),
    textTransform: 'uppercase',
    backgroundColor: '#E0E0E0',
    color: 'red',
  },
  paper: {
    position: 'absolute',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  boldText: {
    fontWeight: 'bold',
  },
  spinner: {
    position: 'fixed',
    zIndex: 1,
    background: '#00000050',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
  },
  samplesSelectContainer: {
    height: '400px',
  },
  errorText: {
    color: 'red',
    fontSize: '1.25rem',
    display: 'inline-flex',
  },
  errorIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
}));

export const gridThemeStyle = {
  height: '450px',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: 'transparent',
};

export const spinnerThemeStyle = {
  height: '450px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const saveAndContinueErrorButtonStyle = {
  backgroundColor: '#E0E0E0',
  color: 'red',
};
