import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  pathologyConsultationGLPSchema,
  pathologyConsultationOrderSchema,
} from 'components/PathologyConsultations/OrderForm/schema';
import { getSerializedFormObjectFromTurnaroundTime } from 'components/OrderForm/components/InfoSelectDropdowns';
import { getFormSerializedValueForPathologyExpertise } from 'components/OrderForm/constants';
import React, { useEffect, useState } from 'react';
import {
  createPathologyConsultationOrder,
  getPathologyConsultationInstance,
  updatePathologyConsultationOrder,
} from 'services/resources/pathologyConsultations';
import { serializeNameAndUUID } from 'utilities/api';
import { useRouteMatch } from 'react-router';
import {
  BACKEND_STATE_NEW_QUESTIONNAIRE,
  BACKEND_STATE_SUBMITTED_FROM_APP_QUESTIONNAIRE_COMPLETE,
} from 'components/PathologyConsultations/constants';
import { newConsultationOrderFormStates } from 'components/OrdersList/constants';
import { ORDERS_LIST_URL } from 'constants/urls';
import { errorLogger } from 'utilities/loggers';
import { useHistory } from 'react-router-dom';
import { getTeams } from 'services/resources/commonResources';
import { FETCH_TEAMS_ERROR_MESSAGE } from 'constants/errorMessages';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { KEYS } from 'constants/keyboard';

export const usePathologyConsultation = () => {
  const history = useHistory();
  const unmountIgnore = useUnmountIgnore();
  const {
    params: { consultUUID: routeConsultUUID },
  } = useRouteMatch();
  const { showSuccess, showError } = useSnackbar();
  const [consultUUID, setConsultUUID] = useState(routeConsultUUID);
  const [consultation, setConsultation] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [protocolAttachments, setProtocolAttachments] = React.useState([]);
  const [isGLPConsultation, setIsGLPConsultation] = useState(false);
  const [team, setTeam] = useState();
  const [selectableTeams, setSelectableTeams] = useState([]);
  // have this be a component here and if the user only has 1 team don't
  // render as that's more confusing
  const [showTeamSelectionOptions, setShowTeamSelectionOptions] =
    useState(false);

  const submitText = consultation ? 'Update' : 'Submit';

  const turnaroundDaysProperSerialized =
    getSerializedFormObjectFromTurnaroundTime(consultation?.turnaround_days);
  const specialitiesProperSerialized =
    getFormSerializedValueForPathologyExpertise(consultation?.specialities);

  const defaultValues = {
    selectedTeam: null,
    turnaround_days: turnaroundDaysProperSerialized || {
      value: 10,
      label: 'Ten Days',
    },
    goal_description: consultation?.description || '',
    additional_details: consultation?.additional_details || '',
    specialities: specialitiesProperSerialized || [],
    desired_pathology_expertise:
      consultation?.desired_pathology_expertise || '',
    species_text: consultation?.species_text || '',
    type_of_analysis_scoring_required:
      consultation?.type_of_analysis_scoring_required || '',
    anticipated_findings: consultation?.anticipated_findings || '',
    control_animal: consultation?.control_animal || '',
    reference_papers: consultation?.reference_papers || '',
    requested_pathologist: consultation?.requested_pathologist || '',
    special_instructions: consultation?.special_instructions || '',
    is_glp_pathology_report: consultation?.is_glp_pathology_report || false,
  };

  const resolver = isGLPConsultation
    ? yupResolver(pathologyConsultationGLPSchema)
    : yupResolver(pathologyConsultationOrderSchema);

  const methods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: defaultValues,
  });

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { touchedFields, errors, submitCount },
  } = methods;

  // do this because it's hard to extract values from objects
  // when getting values from onsubmit
  const [turnaround_days] = watch(['turnaround_days']);

  // register selectedTeam so that the form knows about it
  // but don't show it to make it easier for users to register
  // and during registration, just default it to their 1st team
  register('selectedTeam');

  // use this to try to intelligently assume which team we may want it to be used to
  const [team_selection] = watch(['selectedTeam']);

  const onSubmit = ({ goal_description, ...values }) => {
    if (isGLPConsultation && !protocolAttachments.length) {
      showError('Please upload a GLP Protocols Document');
      return;
    }

    setIsSubmitting(true);

    const postParams = {
      slide_uuids: [],
      state: BACKEND_STATE_SUBMITTED_FROM_APP_QUESTIONNAIRE_COMPLETE,
      ...values,
      description: goal_description,
    };

    // if we are receiving a previous consultation, and it's in a later stage
    // don't re-update it, instead leave as-is
    if (
      consultation &&
      !!newConsultationOrderFormStates.includes(consultation.state)
    ) {
      postParams['state'] = consultation.state;
    }

    const specialitiesResponse = values?.specialities?.map((response) => {
      return response.value;
    });

    postParams['specialities'] = specialitiesResponse;
    postParams['is_glp_pathology_report'] = isGLPConsultation;
    // we don't want to use the values sent since this is stored as an object
    // instead with a watch, we can extract the value from the form directly
    postParams['turnaround_days'] = turnaround_days.value;
    postParams['team_uuid'] = values?.selectedTeam?.value;

    // decide here if you want to update the values or not
    if (consultation) {
      updatePathologyConsultationOrder({
        consultUUID,
        postParams,
      })
        .then((data) => {
          const pcoName = data['name'];
          showSuccess(
            `${pcoName} was updated successfully! We will be reaching out shortly.`,
          );
          history.push(ORDERS_LIST_URL);
        })
        .catch((error) => {
          setIsSubmitting(false);

          errorLogger(error?.response, 'error updating pathology consultation');
          errorLogger(error?.response?.data, 'errorResponseData');
        });
    } else {
      createPathologyConsultationOrder({ postParams })
        .then((data) => {
          const pcoName = data['name'];
          showSuccess(
            `${pcoName} was created successfully! Consultation can be found "In Progress".`,
          );
          history.push(ORDERS_LIST_URL);
        })
        .catch((error) => {
          setIsSubmitting(false);

          errorLogger(error?.response, 'error creating pathology consultation');
          errorLogger(error?.response?.data, 'errorResponseData');
        });
    }
  };

  const createConsultationForUploadedFiles = () => {
    // because we need a consultation to be able to store
    // file uploads to, when a file is created, we instead create
    // a temporary order to hold the file attachments
    if (!team_selection) {
      // nothing we can do with out team selection
      return;
    }

    const postParams = {
      slide_uuids: [],
      state: BACKEND_STATE_NEW_QUESTIONNAIRE,
    };

    // we will create the most basic path consult order
    // just enough that the backend wil allow

    postParams['is_glp_pathology_report'] = isGLPConsultation;
    // we don't want to use the values sent since this is stored as an object
    // instead with a watch, we can extract the value from the form directly
    postParams['turnaround_days'] = turnaround_days.value;
    postParams['team_uuid'] = team_selection.value;

    return createPathologyConsultationOrder({ postParams }).then((data) => {
      // now that we have this filled, set the consultation to be this data
      setConsultation(data);
      return data;
    });
  };

  const checkKeyDown = (e) => {
    if (e.code === KEYS.ENTER) e.preventDefault();
  };

  const defaultFormProps = {
    register,
    errors,
    checkmark: true,
    validated: false,
    touched: touchedFields,
    submitCount,
  };

  useEffect(() => {
    if (!consultUUID) {
      return;
    }

    getPathologyConsultationInstance({ consultUUID }).then((consult) => {
      setConsultation(consult);

      // serialized it so that is a k/v of label / value
      const serializedTeam = serializeNameAndUUID(consult.team);
      setTeam(serializedTeam);

      // if the consultation is GLP, we need to set the GLP switch to true
      setIsGLPConsultation(consult.is_glp_pathology_report);
    });
  }, [consultUUID]);

  useEffect(() => {
    getTeams()
      .then((response) => {
        const selectableTeams = response.data.map(serializeNameAndUUID);

        // this is used to default to a primary team when someone is making their order (most users will probably only have one team)
        const serializedTeam = serializeNameAndUUID(response.data[0]);

        if (!unmountIgnore.current) {
          // if a team hasn't already been selected by a consultation, then set the team
          // otherwise, we should default to what was selected from the consultation
          if (!team) {
            setTeam(serializedTeam);
            setValue('selectedTeam', serializedTeam);
          }

          setSelectableTeams(selectableTeams);
          // if the consultation doesn't already have a team we shouldn't show a new selection
          if (!consultation) {
            setShowTeamSelectionOptions(selectableTeams.length > 1);
          }
        }
      })
      .catch(() => showError(FETCH_TEAMS_ERROR_MESSAGE));
  }, [unmountIgnore]);

  useEffect(() => {
    reset(defaultValues);
    // after resetting, make sure we populate the selectedTeam
    setValue('selectedTeam', team);
  }, [consultation]);

  useEffect(() => {
    reset(defaultValues, {
      keepTouched: true,
      keepValues: true,
      keepDirtyValues: true,
      keepIsValid: true,
      keepDirty: true,
      keepDefaultValues: true,
      keepErrors: true,
      keepIsSubmitted: true,
    });
  }, [isGLPConsultation]);

  return {
    onSubmit,
    consultation,
    methods,
    handleSubmit,
    isGLPConsultation,
    consultUUID,
    selectableTeams,
    team,
    isSubmitting,
    submitText,
    checkKeyDown,
    defaultFormProps,
    setConsultUUID,
    setIsGLPConsultation,
    createConsultationForUploadedFiles,
    showTeamSelectionOptions,
    protocolAttachments,
    setProtocolAttachments,
  };
};
