import { makeStyles } from 'tss-react/mui';
import { TRANSPARENT_BLACK_26 } from 'utilities/colors';

export const useEditUserStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '100%',
  },
  wrapper: {
    maxWidth: '100%',
    padding: '1rem 0 !important',
    borderRadius: '5px',
    background: 'white',
    minHeight: '35rem',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem 2rem',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
    letterSpacing: '0.1em',
    marginBottom: '1rem',
  },
  loader: {
    width: '1.5rem !important',
    height: '1.5rem !important',
  },
  button: {
    width: '10rem',
    height: '2.1rem',
  },
  tabs: {
    padding: '0 2rem',
    borderBottom: `1px solid ${TRANSPARENT_BLACK_26}`,
    marginBottom: '1rem',
  },
  content: {
    padding: '0 2rem',
  },
}));
