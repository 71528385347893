import { Button, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import { ProgressItemCard } from 'components/OrderDetails/Tabs/OrderProgressTab/ProgressItemCard';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { Link } from 'react-router-dom';
import { ORDER_DETAILS_URL, PLACE_ORDER_INITIAL_URL } from 'constants/urls';
import { ORDER_PROGRESS_TAB_VALUE } from 'components/OrderDetails/constants';
import classNames from 'classnames';
import { ORDER_COMPLETE_TRACKER_STATE } from 'components/OrdersList/constants';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { useHistory } from 'react-router';
import { useOtherOrdersStyles } from 'components/OrderDetails/Tabs/OrderProgressTab/styles';
import { useOtherOrders } from 'components/OrderDetails/Tabs/OrderProgressTab/hooks';
import { prettifyTrackerState } from 'components/OrderDetails/Tabs/OrderProgressTab/utilities';

export const OtherOrders = ({ currentOrder }) => {
  const { classes } = useOtherOrdersStyles();
  const history = useHistory();

  const { otherOrders, isLoading } = useOtherOrders(currentOrder);

  const getOrderProgressLink = (order) => {
    return ORDER_DETAILS_URL.replace(':orderUUID', order.uuid).concat(
      `#${ORDER_PROGRESS_TAB_VALUE}`,
    );
  };

  const checkIfOrderComplete = (order) =>
    order.tracker_state === ORDER_COMPLETE_TRACKER_STATE;

  const navigateToOrderForm = () => {
    history.push(PLACE_ORDER_INITIAL_URL);
  };

  return (
    <ProgressItemCard label={'Your Other Orders'}>
      {isLoading ? (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          padding={'4rem'}
        >
          <Spinner />
        </Box>
      ) : otherOrders.length ? (
        otherOrders.map((order) => (
          <Fragment key={order.uuid}>
            <Box className={classes.orderItem}>
              <span
                className={classNames(classes.isFullfilledIndicator, {
                  [classes.fulfilledColor]: checkIfOrderComplete(order),
                })}
              />
              <Box className={classes.centralSection}>
                <Link to={getOrderProgressLink(order)}>
                  <Typography className={classes.orderName}>
                    {order.name}
                  </Typography>
                </Link>
                <Typography className={classes.orderStatus}>
                  {order.tracker_state &&
                  typeof order.tracker_state === 'string'
                    ? prettifyTrackerState(order.tracker_state)
                    : order.state_label}
                </Typography>
              </Box>
              <Typography className={classes.projectName}>
                {order.project.name}
              </Typography>
            </Box>
          </Fragment>
        ))
      ) : (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          padding={'4rem'}
        >
          <Typography className={classes.orderName}>
            You don't have other orders
          </Typography>
          <Button
            variant="contained"
            endIcon={<CreateNewFolderIcon />}
            onClick={navigateToOrderForm}
          >
            New Order
          </Button>
        </Box>
      )}
    </ProgressItemCard>
  );
};
