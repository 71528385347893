import { Box, Button, Typography } from '@mui/material';
import { useOrdersTabContentStyles } from 'components/UserProfile/styles';
import React from 'react';

export const ServiceCard = ({
  service,
  activeService,
  setActiveService,
  isDisabled,
}) => {
  const { classes } = useOrdersTabContentStyles();
  const isActive = () => activeService?.name === service.name;
  const activeClass = isDisabled
    ? classes.errorServiceButton
    : classes.activeServiceButton;
  const getButtonClass = () =>
    isActive(service)
      ? `${classes.serviceButton} ${activeClass}`
      : classes.serviceButton;
  const getIconClass = () =>
    isActive(service) && !isDisabled
      ? `${classes.serviceIcon} ${classes.activeServiceIcon}`
      : classes.serviceIcon;

  const toggleService = () => {
    if (isActive(service)) {
      setActiveService(null);
    } else {
      setActiveService(service);
    }
  };

  return (
    <Button
      className={getButtonClass(service)}
      onClick={() => toggleService(service)}
    >
      <Box className={getIconClass(service)}>
        <service.Icon />
      </Box>
      <Typography className={classes.serviceText}>{service.name}</Typography>
    </Button>
  );
};
