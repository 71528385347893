import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

import { useGlpFormStyles } from 'components/GlpForm/styles';

export const GlpSearch = ({ value, onChange, title, items, freeInput }) => {
  const { classes } = useGlpFormStyles();
  const { field, search } = classes;

  const handleFreeBlur = ({ target }) =>
    !value && freeInput && onChange({ name: target.value });

  return (
    <div className={`${field} ${search}`}>
      <Autocomplete
        freeSolo={freeInput}
        options={items}
        getOptionLabel={(item) => item?.name ?? ''}
        id={title}
        value={value}
        onChange={(_, newValue) => onChange(newValue)}
        renderOption={(props, option) => (
          <li {...props} key={option.uuid ?? option.name}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            onBlur={handleFreeBlur}
            required
            {...params}
            label={title}
            variant="standard"
          />
        )}
      />
    </div>
  );
};
