import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Typography, Box } from '@mui/material';
import { Close, Update as UpdateIcon } from '@mui/icons-material';
import {
  DropdownIndicator,
  ClearIndicator,
  Option,
  SingleValue,
} from 'components/common/Select/SelectComponents';
import { CustomDropdown } from 'components/TeamOverview/components/ManageDataCard/CustomDropdown';
import {
  selectStyles,
  useManageDataCardDefaultHeaderStyles,
} from 'components/TeamOverview/components/ManageDataCard/styles';
import { selectActionOptions } from 'components/TeamOverview/constants';
import { CHANGELOG_VIEW } from 'components/UserProfile/constants';
import { HelpButtonWithTooltip } from 'components/UserProfile/components/HelpButtonWithTooltip';
import uploadImagesHintVideo from 'files/upload_files.mp4';
import { useButtonWithIconStyles } from 'components/UserProfile/components/styles';

export const ManageDataCardDefaultHeader = ({
  selectedRowsCount,
  handleDeselect,
  setSelectedOrdersType,
  selectedOrdersType,
  isDemoAccount,
  setActiveAction,
  handleContinue,
  setViewMode,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { classes: buttonWithIconClasses } = useButtonWithIconStyles();
  const { classes } = useManageDataCardDefaultHeaderStyles();

  const handleChange = (option) => {
    if (!option) {
      setActiveAction(null);
      setSelectedOption(null);
      return;
    }

    setActiveAction(option.value);
    setSelectedOption(option);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      justifyContent="space-between"
    >
      <Typography className={classes.cardTitle}>Manage Data</Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        {!!selectedRowsCount && (
          <Box
            onClick={handleDeselect}
            className={classes.selectedOrdersButton}
          >
            <Close
              classes={{
                root: classes.closeIcon,
              }}
            />
            <Typography fontWeight={500} component="span">
              {selectedRowsCount} Selected
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Box display="flex" alignItems="center">
            <Box
              ml={2}
              width="25rem"
              position="relative"
              sx={{ cursor: isDemoAccount ? 'not-allowed' : 'pointer' }}
            >
              <Select
                value={selectedOption}
                onChange={handleChange}
                options={selectActionOptions(selectedOption)}
                placeholder="SELECT ACTION"
                styles={selectStyles}
                isClearable
                components={{
                  DropdownIndicator,
                  ClearIndicator,
                  Option,
                  SingleValue,
                }}
                isDisabled={isDemoAccount}
              />
              <Button
                variant="contained"
                size="small"
                className={classes.continueButton}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={handleContinue}
                disabled={!selectedRowsCount}
              >
                Review
              </Button>
            </Box>
          </Box>
        </Box>
        <Box marginLeft="0.25rem">
          <CustomDropdown
            setValue={setSelectedOrdersType}
            value={selectedOrdersType}
          />
        </Box>
        <Box marginLeft={'1rem'}>
          <Button
            variant="contained"
            size="small"
            className={buttonWithIconClasses.pinkButtonWithIcon}
            onClick={() => setViewMode(CHANGELOG_VIEW)}
          >
            <UpdateIcon className={classes.changeLogIcon} />
          </Button>
        </Box>
        <Box marginLeft={'1rem'}>
          <HelpButtonWithTooltip
            title={'How to upload slides?'}
            fileSrc={uploadImagesHintVideo}
          />
        </Box>
      </Box>
    </Box>
  );
};
