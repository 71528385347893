import React from 'react';
import Button from '@mui/material/Button';
import shallow from 'zustand/shallow';

import {
  useImageSettingsStore,
  useViewerStore,
} from 'components/IFViewer/state';

export const PanLockToggle = () => {
  const [togglePanLock, panLock] = useImageSettingsStore(
    (store) => [store.togglePanLock, store.panLock],
    shallow,
  );
  const isViewerLoading = useViewerStore((store) => store.isViewerLoading);
  return (
    <Button
      id={`if-${panLock ? 'un' : ''}lock-pan`}
      disabled={isViewerLoading}
      onClick={togglePanLock}
      variant="outlined"
      size="small"
      fullWidth
    >
      {panLock ? 'Unlock' : 'Lock'} Pan
    </Button>
  );
};
