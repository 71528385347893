import {
  ORDER_DETAILS_URL,
  PROJECT_SLIDES_URL,
  SLIDE_VIEWER_URL,
} from 'constants/urls';

export const ORDERS_GROUP_NAME = 'Orders';
export const SLIDES_GROUP_NAME = 'Slides';
export const SAMPLES_GROUP_NAME = 'Samples';
export const PROJECTS_GROUP_NAME = 'Projects';

export const GROUP_TO_LINK_DATA_MAP = {
  [ORDERS_GROUP_NAME]: {
    link: ORDER_DETAILS_URL,
    uuidName: ':orderUUID',
  },
  [SLIDES_GROUP_NAME]: {
    link: SLIDE_VIEWER_URL,
    uuidName: ':slideUUID',
  },
  [SAMPLES_GROUP_NAME]: undefined,
  [PROJECTS_GROUP_NAME]: {
    link: PROJECT_SLIDES_URL,
    uuidName: ':projectUUID',
  },
};
