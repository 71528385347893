import React, { Fragment, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { SubmittingIcon } from 'components/icons/LoadingIcon';
import { postStripeCard } from 'services/resources/orders';
import { Grid } from '@mui/material';

import stripe_logo from 'images/stripe_transparent.png';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import 'components/CheckoutForm/stripe.css';
import { POST_STRIPE_CARD_ERROR_MESSAGE } from 'constants/errorMessages';
import { useSelector } from 'react-redux';
import {
  currentUserEmailSelector,
  primaryTeamSelector,
} from 'store/slices/userDetailsSlice';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const CheckoutFormV2 = (props) => {
  // Get a reference to Stripe or Elements using hooks.
  const stripe = useStripe();
  const elements = useElements();
  const { showError } = useSnackbar();
  // eslint-disable-next-line
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stripeError, setStripeError] = useState('');
  const email = useSelector(currentUserEmailSelector);

  const primaryTeam = useSelector(primaryTeamSelector);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Use elements.getElement to get a reference to the mounted Element.
    const cardElement = elements.getElement(CardElement);

    // Pass the Element directly to other Stripe.js methods:
    // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
    const additionalTokenData = {
      name: email,
    };
    let { error, token } = await stripe.createToken(
      cardElement,
      additionalTokenData,
    );
    if (error) {
      setStripeError(error.message);
      showError(error.message);
    } else {
      const token_id = token.id;

      if (primaryTeam) {
        const postParams = {
          token: token_id,
          team_uuid: primaryTeam.uuid,
        };

        postStripeCard(postParams)
          .then(() => {
            props.onUpdate();
          })
          .catch(() => showError(POST_STRIPE_CARD_ERROR_MESSAGE));
      }
    }
  };

  const renderStripeError = () => {
    if (!stripeError) {
      return null;
    }

    return (
      <Fragment>
        <Typography variant="h6" color={'error'} gutterBottom>
          Error: {stripeError}
        </Typography>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item lg={12} xl={9}>
          <div className="Checkout">
            <label>
              <img
                src={stripe_logo}
                width="25%"
                height={'100%'}
                alt={'Stripe Logo'}
              />
              <p>
                Please enter your credit card information. Your credit card
                information will be securely handled and stored by Stripe.
              </p>
              <form onSubmit={handleSubmit}>
                <CardElement />
                <br />
                {renderStripeError()}
                {isSubmitting ? (
                  <SubmittingIcon submitting={true} />
                ) : (
                  <Fragment>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      // className={classes.downloadButton}
                    >
                      Connect With Stripe
                    </Button>
                  </Fragment>
                )}
              </form>
            </label>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};
