import {
  CuttingInstructionsOptionsMapping,
  OrderIHCOptimizationLabelDisplay,
} from 'components/OrderForm/constants';

export const getSavedInfoRows = (details) => [
  {
    label: 'Project Name',
    value: details.label,
  },
  {
    label: 'Pathology Map Contribution',
    value: details.pathology_map_consent ? 'Yes' : 'No',
  },
  {
    label: 'Histology Turnaround Time Request (Days)',
    value: details.turnaround_days,
  },
  {
    label: 'Fixative Order Solution',
    value: details.fixative?.name,
  },
  {
    label: 'Fixation Time',
    value: details.fixation_time_hours,
  },
  {
    label: 'Grossing Instructions',
    value: CuttingInstructionsOptionsMapping[details.cutting_instructions],
  },
  {
    label: 'Sections Per Slide',
    value: details.sections_per_slide,
  },
  {
    label: 'Additional Steps/Levels Per Sample',
    value: details.additional_steps_per_sample,
  },
  {
    label: 'Section Thickness (Microns)',
    value: details.section_thickness_microns,
  },
  {
    label: 'Species',
    value: details.species?.name,
  },
  {
    label: 'Samples Already At HistoWiz',
    value: details.materials_at_histowiz ? 'Yes' : 'No',
  },
  {
    label: 'Antibody Optimization Requested',
    value: OrderIHCOptimizationLabelDisplay[details.ihc_optimization_type],
  },
  {
    label: 'Purpose Of Study',
    value: details.purpose_of_study,
  },
  {
    label: 'Special Instructions',
    value: details.special_instructions,
  },
];
