export const downloadBarcodes = (selectedRows, selectedStains) => {
  const filteredSelectedRows = selectedRows.filter((row) =>
    selectedStains.includes(row.stainName),
  );
  const outputData = filteredSelectedRows
    .map((row) => row.sampleFullDetail)
    .join('\n');

  // Create a Blob with the output data
  const blob = new Blob([outputData], { type: 'text/plain' });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element and trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = 'output.conf';
  link.click();

  // Clean up the temporary URL
  URL.revokeObjectURL(url);
};

export const parseBarcodes = (data) =>
  data
    .trim()
    .split('\n')
    .map((sampleFullDetail) => {
      const [, , , , sampleName, stainName, slideNumber] =
        sampleFullDetail.split('~');
      return {
        sampleName,
        stainName: stainName?.trim(),
        slideNumber,
        sampleFullDetail,
      };
    });
