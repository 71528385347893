import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import {
  PLACE_ORDER_BILLING_STEP_URL,
  // eslint-disable-next-line no-unused-vars
  PLACE_ORDER_INFO_URL,
  PLACE_ORDER_INITIAL_URL,
  PLACE_ORDER_INITIAL_URL_V2,
  PLACE_ORDER_PRICE_REVIEW_STEP_URL,
  PLACE_ORDER_REVIEW_STEP_URL,
  // eslint-disable-next-line no-unused-vars
  PLACE_ORDER_REVIEW_STEP_URL_V2,
  PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL,
  PLACE_ORDER_SAMPLES_STEP_URL,
  PLACE_ORDER_SLIDES_STEP_URL,
} from 'constants/urls';
import { swapKeysAndValuesDictionary } from 'utilities/general';
import {
  BILLING_STEP_LABEL,
  INFO_STEP_LABEL,
  PRICE_REVIEW_STEP_LABEL,
  REVIEW_STEP_LABEL,
  SAMPLES_SCIENCE_INFO_STEP_LABEL,
  SAMPLES_STEP_LABEL,
  SLIDES_STEP_LABEL,
} from './constants';
import { Stepper, Step, StepButton } from '@mui/material';
import { AppContext } from 'services/context';

const styles = () => ({
  root: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  stepper: {
    padding: '24px',
    // backgroundColor: _theme.palette.background.default,
  },
});

const steps = [
  INFO_STEP_LABEL,
  SAMPLES_STEP_LABEL,
  SAMPLES_SCIENCE_INFO_STEP_LABEL,
  SLIDES_STEP_LABEL,
  PRICE_REVIEW_STEP_LABEL,
  BILLING_STEP_LABEL,
  REVIEW_STEP_LABEL,
];

const StepsURLMappingV2 = {
  0: PLACE_ORDER_INFO_URL,
  1: PLACE_ORDER_SAMPLES_STEP_URL,
  2: PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL,
  3: PLACE_ORDER_SLIDES_STEP_URL,
  4: PLACE_ORDER_PRICE_REVIEW_STEP_URL,
  5: PLACE_ORDER_BILLING_STEP_URL,
  6: PLACE_ORDER_REVIEW_STEP_URL_V2,
};

const StepsURLMapping = {
  0: PLACE_ORDER_INFO_URL,
  1: PLACE_ORDER_SAMPLES_STEP_URL,
  2: PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL,
  3: PLACE_ORDER_SLIDES_STEP_URL,
  4: PLACE_ORDER_PRICE_REVIEW_STEP_URL,
  5: PLACE_ORDER_BILLING_STEP_URL,
  6: PLACE_ORDER_REVIEW_STEP_URL,
};

const useStyles = makeStyles()(styles);

export const OrderFormWizardNavigator = (props) => {
  const { onBeforeNavigate } = props;
  const location = useLocation();
  const { selectedOrderFormVersion } = useContext(AppContext);
  const match = useRouteMatch();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const { classes } = useStyles();

  const orderFormSteps =
    selectedOrderFormVersion === 'v2' ? StepsURLMappingV2 : StepsURLMapping;

  const URLStepsMapping = swapKeysAndValuesDictionary(orderFormSteps);

  const handleStepClick = (step) => {
    // If this is from the initial placing order page, a user shouldn't be able
    // to click on anything until this is at least done
    if (
      location.pathname === PLACE_ORDER_INITIAL_URL ||
      location.pathname === PLACE_ORDER_INITIAL_URL_V2
    ) {
      return;
    }

    const orderUUID = match.params.orderUUID;
    const urlMapping = orderFormSteps[step];
    const url = urlMapping.replace(':orderUUID', orderUUID);

    const cancelNavigation = onBeforeNavigate?.({
      step,
      urlMapping,
      url,
      orderUUID,
    });

    if (cancelNavigation) return;

    history.push(url);
  };

  useEffect(() => {
    if (
      location.pathname === PLACE_ORDER_INITIAL_URL ||
      location.pathname === PLACE_ORDER_INITIAL_URL_V2
    ) {
      setActiveStep(0);
    } else {
      const url = match.path;
      setActiveStep(Number(URLStepsMapping[url]));
    }
  }, [location.pathname, match.path]);

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        classes={{
          root: classes.stepper,
        }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={() => handleStepClick(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {props.children}
    </div>
  );
};
