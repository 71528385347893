import { SvgIcon } from '@mui/material';

export const ExpandIcon = () => (
  <SvgIcon viewBox="0 0 12 12" width="12" height="12">
    <path
      d="M0.166748 11.8334V6.83342H1.83341V10.1667H5.16675V11.8334H0.166748ZM10.1667 5.16675V1.83341H6.83342V0.166748H11.8334V5.16675H10.1667Z"
      fill="#EC6A7A"
    />
  </SvgIcon>
);
