import {
  Check as CheckIcon,
  Clear as ClearIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import React from 'react';
import { components } from 'react-select';

export const Option = (props) => (
  <components.Option {...props}>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      zIndex="1000"
    >
      <Box display="flex" alignItems="center">
        {props.data.icon}
        <Box ml={1}>{props.data.label}</Box>
      </Box>
      {props.isSelected && <CheckIcon />}
    </Box>
  </components.Option>
);

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <Box display="flex" alignItems="center" width="100%">
      <Box flexGrow={1}>{children}</Box>
    </Box>
  </components.SingleValue>
);

export const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <KeyboardArrowDownIcon />
  </components.DropdownIndicator>
);

export const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <ClearIcon />
  </components.ClearIndicator>
);
