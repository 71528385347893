import Box from '@mui/material/Box';
import { Fade } from '@mui/material';
import { DefaultPathologyMapTypography } from 'components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography';
import { PATHOLOGY_MAP_RESULTS_COUNTER_TYPOGRAPHY_PROPS } from 'components/PathologyMap/constants/styles';
import { usePathologyMapResultsCounterStyles } from './styles';

export const PathologyMapResultsCounter = ({ count }) => {
  const { classes } = usePathologyMapResultsCounterStyles();

  return (
    <Fade in={true} appear={true} timeout={500}>
      <Box className={classes.counterContainer}>
        <DefaultPathologyMapTypography
          {...PATHOLOGY_MAP_RESULTS_COUNTER_TYPOGRAPHY_PROPS}
        >
          {count}
        </DefaultPathologyMapTypography>
      </Box>
    </Fade>
  );
};
