import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import { makeStyles } from 'tss-react/mui';
import {
  BACKGROUND_GREY,
  BACKGROUND_GREY_LIGHT,
  ERROR_ICON,
  MEDIUM_GREY,
} from 'utilities/colors';

export const useBlocksActionPanelStyles = makeStyles()(() => ({
  orderButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },
  orderButton: {
    padding: '0.5rem 2rem',
    borderRadius: 0,
    boxShadow: 'none',
  },
  orderButtonText: {
    fontSize: '0.875rem',
    marginTop: '0.125rem',
    lineHeight: '1.125rem',
  },
  index: {
    opacity: 0.4,
  },
  remove: {
    width: '0.875rem',
    cursor: 'pointer',
    height: '0.875rem',
    fill: ERROR_ICON,
    display: 'none',
  },
  sampleRow: {
    display: 'grid',
    gridTemplateColumns: '2rem 3rem auto 1rem',
    width: '100%',
    alignItems: 'center',
    padding: '0.25rem 1rem',

    '&:hover': {
      backgroundColor: `${BACKGROUND_GREY_LIGHT} !important`,

      '&>svg': {
        display: 'block',
      },
    },
  },
  queue: {
    width: '100%',
    padding: '0.5rem 1rem',

    '&>div:nth-of-type(odd)': {
      background: BACKGROUND_GREY,
    },
  },
  note: {
    padding: '0.25rem 1.5rem',
    fontSize: '0.75rem',
  },
  tabContainer: {
    ...VERTICAL_SCROLL_STYLE,
    overflowY: 'auto',
  },
  noData: {
    padding: '1rem',
    margin: '0 auto',
    textAlign: 'center',
  },
  sampleInfo: {
    fontSize: '0.75rem',
    color: MEDIUM_GREY,
  },
}));
