import { defaultSpinnerThemeStyle } from 'components/Loading/Spinner/Spinner';
import { makeStyles } from 'tss-react/mui';
import { WHITE } from 'utilities/colors';

export const useActionButtonsStyles = makeStyles()((theme) => ({
  button: {
    fontSize: '14px',
    fontWeight: '400',
    width: '100%',
    marginBottom: '15px',
    padding: '7px 0',
    backgroundColor: 'rgba(217, 217, 217, 0.4)',
    color: theme.palette.black.main,
    border: '1px solid rgba(0, 0, 0, 1)',
    '&:hover': {
      backgroundColor: '#bdbdbd',
    },
  },
  noMargin: {
    marginBottom: 0,
  },
  buttonIcon: {
    marginRight: '25px',
    '& > *:first-of-type': {
      fontSize: '30px',
      color: '#5e5e5d',
    },
  },
  actionButton: {
    padding: '10px 0',
    marginBottom: 0,
    '&:first-of-type': {
      marginRight: '18px',
    },
  },
  activeButton: {
    borderColor: '#5470FF',
    backgroundColor: 'rgba(66, 148, 213, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(66, 148, 213, 0.2)',
    },
  },
}));

export const useAnalysisModelsStyles = makeStyles()(() => ({
  wrapper: {
    maxHeight: '250px',
    overflow: 'hidden',
    ' -webkit-mask-image': 'linear-gradient(180deg, #000 90%, transparent)',

    '& > :not(h1)': {
      padding: '0 45px',
    },
    '& h1': {
      fontSize: '18px',
      width: '100%',
      padding: '5px 45px',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: 10,
      background:
        'linear-gradient(rgba(108, 162, 187, 0.6), rgba(108, 162, 187, 0))',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      margin: 0,
      marginBottom: '15px',
    },
    '& h2': {
      fontSize: '16px',
      margin: 0,
      marginTop: '8px',
      padding: 0,
    },
    '& h3': {
      fontSize: '14px',
      margin: 0,
      fontWeight: 400,
    },
    '& p': {
      fontSize: '16px',
      textAlign: 'justify',
      textIndent: '15px',
      margin: 0,
      padding: 0,
    },
    '& li::marker': {
      fontWeight: 700,
    },
    '& a': {
      color: 'black',
      textDecoration: 'underline',
    },
    '& img': {
      maxWidth: '50vw',
    },
    '& ul': {
      margin: '8px 0',
      padding: '0 20px',
    },
  },
  expandedText: {
    maxHeight: 'unset',
    ' -webkit-mask-image': 'unset',
  },
  readMore: {
    textDecoration: 'underline',
    fontSize: '16px',
    textTransform: 'uppercase',
    paddingLeft: '45px',
    cursor: 'pointer',
  },
}));

export const useGalleryStyles = makeStyles()(
  (_, { maxWidth, maxHeight, playing }) => ({
    root: {
      maxWidth,
      maxHeight,
      '&.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before':
        {
          color: 'black',
          borderRight: '3px solid black',
          borderBottom: '3px solid black',
          borderTop: 0,
          borderLeft: 0,
          transform: 'rotate(135deg)',
          width: '25px',
          height: '25px',
        },

      '& .carousel .control-next.control-arrow:before': {
        transform: 'rotate(-45deg)',
      },

      '& .carousel .control-arrow:hover': {
        background: 0,
      },

      '& .carousel > .slider-wrapper .slider': {
        aspectRatio: '16/9',
      },

      '& .carousel > .slider-wrapper': {
        height: '100%',
      },

      '& .carousel > .slider-wrapper .slider .slide': {
        maxHeight,
        '> img': {
          maxHeight,
        },
      },

      '& .carousel .thumbs-wrapper': {
        margin: 0,
        '& .control-prev::before, & .control-next::before': {
          color: 'black',
          borderRight: '2px solid black',
          borderBottom: '2px solid black',
          borderTop: 0,
          borderLeft: 0,
          transform: 'rotate(135deg)',
          width: '16px',
          height: '16px',
        },
        '& .control-next::before': {
          transform: 'rotate(-45deg)',
        },

        '& .thumbs': {
          paddingLeft: 0,
          margin: 0,
          marginBottom: '10px',
          '& .thumb': {
            '&:not(.selected)': {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
    video: {
      width: '100%',
      height: '100%',
      position: 'relative',
      pointerEvents: !playing ? 'none' : 'all',
    },
    playButton: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
      opacity: '70%',
      fill: WHITE,
      fontSize: '6.25rem',
      cursor: 'pointer',
    },
    slideImgWrapper: {
      position: 'relative',
      maxWidth,
      maxHeight,
      height: '100%',
      '& img': {
        maxWidth,
        height: '100%',
      },
    },
  }),
);

export const useFilteredSlidesStyles = makeStyles()(() => ({
  cardsWrapper: {
    paddingTop: '40px',
  },
  noSlides: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '50px',
    fontSize: '25px',
    fontWeight: 'bold',
    height: '30vh',
  },
  spinner: {
    ...defaultSpinnerThemeStyle,
    height: '30vh',
  },
}));

export const useSlideLabelStyles = makeStyles()(() => ({
  root: {
    position: 'absolute',
    width: '90%',
    bottom: 30,
    left: 0,
    right: 0,
    padding: '0.5rem 0',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    margin: '0 auto',
    transform: 'skew(-20deg)',
  },
  title: {
    transform: 'skew(20deg)',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
}));
