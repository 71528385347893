import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import { useGetOrdersQuery } from 'store/apis/orderApi';
import { FETCH_ORDERS_ERROR_MESSAGE } from 'constants/errorMessages';
import { filterOrders } from 'components/UserProfile/utilities';

export const OrderStepContent = ({
  classes,
  handlePrevStep,
  handleNextStep,
  selectedOrder,
  setSelectedOrder,
  selectedProject,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const {
    data: orders,
    isLoading: isOrdersLoading,
    error,
  } = useGetOrdersQuery();

  useQueryError(error, FETCH_ORDERS_ERROR_MESSAGE);

  const filteredOrders = selectedOrder
    ? filterOrders(orders, selectedProject)
    : [];

  useEffect(() => {
    setIsLoading(isOrdersLoading);
  }, [isOrdersLoading, setIsLoading]);

  useEffect(() => {
    if (!orders || !selectedOrder || !selectedProject) return;

    const isOrderFromSelectedProject =
      selectedProject.uuid === selectedOrder.project.uuid;

    if (!isOrderFromSelectedProject) {
      setSelectedOrder(filteredOrders[0] || null);
    }
  }, [orders, selectedProject, selectedOrder]);

  const onSelectionChange = (event) => {
    setSelectedOrder(
      filteredOrders.find((order) => order.uuid === event.target.value),
    );
  };

  const handleNewOrderToggle = () => {
    setIsNewOrder(!isNewOrder);
  };

  const handleClickNext = () => {
    if (isNewOrder) {
      setSelectedOrder(null);
    }
    handleNextStep();
  };

  return isLoading ? (
    <CircularProgress className={classes.spinner} />
  ) : (
    <Box display="flex" flexDirection="column" alignItems="center" p={8}>
      {selectedOrder && (
        <>
          <Select
            defaultValue=""
            variant="outlined"
            className={classes.selector}
            onChange={onSelectionChange}
            value={selectedOrder?.uuid}
            disabled={isNewOrder}
          >
            {filteredOrders?.map((order) => (
              <MenuItem key={order.uuid} value={order.uuid}>
                {order.name}
              </MenuItem>
            ))}
          </Select>
          <Box display="flex" alignItems="center" width="30rem" my={2}>
            <Divider flexItem className={classes.divider} />
            <Typography variant="body1" mx={1}>
              OR
            </Typography>
            <Divider flexItem className={classes.divider} />
          </Box>
        </>
      )}
      <Button
        className={classes.createOrderButton}
        variant={isNewOrder ? 'contained' : 'outlined'}
        onClick={handleNewOrderToggle}
      >
        + Create New Order Number
      </Button>
      <Box display="flex" justifyContent="space-between" width="30rem" mt={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePrevStep}
          className={classes.button}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickNext}
          className={classes.button}
          disabled={!selectedOrder && !isNewOrder}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
