import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const UserEditFormField = (props) => {
  const { fieldLabel, children, mr } = props;

  return (
    <Box mr={mr}>
      <Typography variant="subtitle1" color="textPrimary">
        {fieldLabel}
      </Typography>
      <Box mt="0.2rem">{children}</Box>
    </Box>
  );
};
