import { useCallback, useEffect, useRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useHistoryStyles } from 'components/SampleEditor/components/SamplesChangelog/styles';

const CustomTextInput = ({ value, onChange, placeholder }) => {
  const inputRef = useRef(null);
  const handleChange = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange],
  );

  const { classes } = useHistoryStyles();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [value]);

  return (
    <TextField
      inputRef={inputRef}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
      }}
      className={classes.textField}
    />
  );
};

export const FilterInput = ({ filterText, onChangeFilterText }) => (
  <CustomTextInput
    value={filterText}
    onChange={onChangeFilterText}
    placeholder="Filter by sample name, sample ID, project name, order"
  />
);
