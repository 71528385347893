import { Grid, Typography, Box } from '@mui/material';
import { useInvoiceStepStyles } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/styles';
import { AddressCard } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/AddressCard';
import { BottomButtonsSection } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/BottomButtonsSection';
import React from 'react';
import { useCommonStyles } from 'components/TeamOverview/styles';
import { getTotalCostItems } from 'components/TeamOverview/utilities';

export const InvoiceStep = ({
  selectedAddress,
  handlePrevStep,
  selectedOrders,
  handleSubmit,
}) => {
  const { classes } = useInvoiceStepStyles();
  const { classes: commonClasses } = useCommonStyles();

  const totalCostItems = getTotalCostItems({ orders: selectedOrders });

  return (
    <>
      <Grid container className={classes.invoiceStepContainer}>
        <Grid item xs={5}>
          <Typography className={classes.title}>
            The selected full-resolution files will be copied to a physical hard
            disk and shipped to the address below.
          </Typography>
          <AddressCard address={selectedAddress}></AddressCard>
        </Grid>
        <Box className={classes.verticalDivider} />
        <Grid item xs={5} paddingTop={2}>
          {totalCostItems.map((item) => (
            <Box className={classes.invoiceInfoRow} key={item.label}>
              <Typography className={classes.invoiceInfoLabel}>
                {item.label}
              </Typography>
              <Typography className={classes.invoiceInfoValue}>
                {item.value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
      <Box className={commonClasses.stepContainer}>
        <BottomButtonsSection
          cancelButtonClick={handlePrevStep}
          confirmButtonClick={handleSubmit}
          confirmButtonText="Submit"
        />
      </Box>
    </>
  );
};
