import React from 'react';
import { useHistory } from 'react-router';
import { Box, Button, IconButton } from '@mui/material';
import {
  Update as UpdateIcon,
  ImageSearch,
  Save as SaveIcon,
} from '@mui/icons-material';
import { BetaIcon } from 'components/Shared/Icons/BetaIcon';
import { useToolbarStyles } from 'components/SampleEditor/styles';
import { SaveChangesModal } from 'components/SampleEditor/SaveChangesModal';
import { ExcelActionButtons } from 'components/SampleEditor/components/ExcelActionButtons';
import { SAMPLES_CHANGELOG_URL } from 'constants/urls';
import { handleOpenAsGroup } from 'components/OrderSlidesList/utilities';

export const Toolbar = ({
  samplesToUpdate,
  updatedSamplesHandler,
  allSamples,
  exportSamplesIds,
  refetchSamples,
  isOpenModal,
  setIsOpenModal,
  nextPage,
  setNextPage,
  onChangePage,
  setExportSamplesIds,
  gridApi,
  fullColumns,
  slidesToView,
  editedSamplesSlides,
}) => {
  const history = useHistory();
  const { classes } = useToolbarStyles({ activeSave: samplesToUpdate.length });

  const closeModal = () => {
    setIsOpenModal(false);
    setNextPage(null);
  };

  const handleChangelog = () => {
    history.push(SAMPLES_CHANGELOG_URL);
  };

  return (
    <>
      <SaveChangesModal
        closeModal={closeModal}
        updatedSamplesHandler={updatedSamplesHandler}
        isOpenModal={isOpenModal}
        allSamples={allSamples}
        samplesToUpdate={samplesToUpdate}
        refetchSamples={refetchSamples}
        nextPage={nextPage}
        onChangePage={onChangePage}
        editedSamplesSlides={editedSamplesSlides}
      />
      <Box className={classes.wrapper}>
        <Button
          startIcon={<SaveIcon />}
          endIcon={<BetaIcon />}
          variant="contained"
          className={classes.button}
          disabled={!samplesToUpdate.length}
          onClick={() => setIsOpenModal(true)}
        >
          Save changes
        </Button>
        <Box className={classes.buttonsWrapper}>
          {slidesToView.length ? (
            <Button
              startIcon={<ImageSearch />}
              variant="contained"
              color="secondary"
              className={classes.viewButton}
              onClick={() => handleOpenAsGroup(slidesToView)}
            >
              {`View ${slidesToView.length} associated slides`}
            </Button>
          ) : null}
          <ExcelActionButtons
            gridAPI={gridApi}
            isDisabledExport={!exportSamplesIds.length}
            setSelectedRows={setExportSamplesIds}
            fileName="Samples"
            fullColumns={fullColumns}
          />
          <IconButton className={classes.changelog} onClick={handleChangelog}>
            <UpdateIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
