import { makeStyles } from 'tss-react/mui';

const ihcOptimizationStyles = (theme) => ({
  root: {
    height: '98vh',
    display: 'flex',
    width: '100%',
    // somehow adding a margin left stops the OSD from shaking randomly
    marginLeft: '0.5rem',
  },
  pageTitle: {
    //width: "100%",
    marginLeft: '2rem',
    paddingTop: '1rem',
  },
  floatingThumbnail: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    'z-index': 5,
    width: '14%',
  },
  floatingPaper: {
    height: '100%',
    width: '100%',
  },
  floatingImage: {
    width: '100%',
    height: '100%',
  },
  gridContainer: {
    height: '100%',
  },
  thumbnailSpan: {
    display: 'inline-block',
  },
  lockGrid: {
    maxWidth: theme.spacing(3),
  },
  lockButton: {
    border: 'none',
    outline: 'none!important',
    padding: 0,
    'z-index': 1,
    top: '3.5rem',
    position: 'absolute',
  },
  commentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const useIHCOptimizationStyles = makeStyles()(ihcOptimizationStyles);
