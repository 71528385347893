import { WHITE, BORDER_COLOR } from 'utilities/colors';
import { makeStyles } from 'tss-react/mui';

export const useSamplesStatusBarStyles = makeStyles()(() => ({
  root: {
    backgroundColor: WHITE,
    padding: '0.5rem',
    border: `1px solid ${BORDER_COLOR}`,
    borderTop: 'none',
  },
  value: {
    fontWeight: 700,
  },
}));
