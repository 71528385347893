import { makeStyles } from 'tss-react/mui';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import {
  DATA_MANAGEMENT_BLUE,
  TRANSPARENT_BLACK_10,
  TRANSPARENT_BLACK_26,
  TRANSPARENT_BLACK_50,
  TRANSPARENT_BLACK_80,
} from 'utilities/colors';

export const useAiComputeTransactionsCardStyles = makeStyles()(() => ({
  tabs: {
    height: '60px',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    fontSize: '1.125rem',
    textDecoration: 'underline',
    color: DATA_MANAGEMENT_BLUE,
  },
  label: {
    fontWeight: 500,
    color: TRANSPARENT_BLACK_50,
    fontSize: '0.75rem',
  },
  tableHeader: {
    borderBottom: `1px solid ${TRANSPARENT_BLACK_10}`,
    paddingRight: '1.75rem',
    paddingBlock: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableBody: {
    overflowY: 'scroll',
    height: '44vh',
    ...VERTICAL_SCROLL_STYLE,
  },
  row: {
    padding: '1rem 1.75rem',
    borderBottom: `1px solid ${TRANSPARENT_BLACK_10}`,
  },
  columnLabel: {
    color: TRANSPARENT_BLACK_50,
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.1em',
  },
  defaultLabel: {
    fontSize: '1.125rem',
    color: TRANSPARENT_BLACK_80,
  },
  boldLabel: {
    fontSize: '1.125rem',
    color: TRANSPARENT_BLACK_80,
    fontWeight: 500,
  },
}));

export const useSearchStyles = makeStyles()(() => ({
  searchField: {
    width: '100%',
    padding: '0.5rem 0.5rem 0.5rem 2.5rem',
    borderRadius: '4px',
    border: `1px solid ${TRANSPARENT_BLACK_26}`,
    boxSizing: 'border-box',
  },
  container: {
    width: '40%',
    position: 'relative',
  },
  icon: {
    color: TRANSPARENT_BLACK_50,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '0.5rem',
  },
}));
