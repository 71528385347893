import { Paper, Tab, Tabs } from '@mui/material';
import { useGetDropboxes } from 'components/OrderForm/BillingShipping/hooks/useGetDropboxes';
import { SelectedShippingDetailsTab } from 'components/OrderForm/BillingShipping/ShippingDetails/SelectedShippingDetailsTab';
import { ShippingLabelsTab } from 'components/OrderForm/BillingShipping/AdditionalQuestions/ShippingLabelsTab';
import { useBillingShippingStyles } from 'components/OrderForm/BillingShipping/styles';
import {
  SHIPPING_DETAILS_TAB_INDEX,
  SHIPPING_LABELS_TAB_INDEX,
} from 'components/OrderForm/constants';
import { TabPanel } from 'components/Shared/TabPanel';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_PACKAGE_HEIGHT as height,
  DEFAULT_PACKAGE_LENGTH as length,
  DEFAULT_PACKAGE_WEIGHT as weight,
  DEFAULT_PACKAGE_WIDTH as width,
} from 'components/OrderForm/BillingShipping/utilities/constants';
import {
  billingShippingSelectors,
  setDropboxSelected,
} from 'store/slices/billingShippingSlice/billingShippingSlice';

export const ShippingDetails = ({
  shippingLabels,
  submitFedExComponent,
  shippingOption,
  handleModalChange,
}) => {
  const { classes } = useBillingShippingStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const dropboxOptions = useGetDropboxes();
  const {
    shippingAddressSelected,
    shippingMethodSelected,
    dropboxSelected,
    submitting,
  } = useSelector(billingShippingSelectors.shippingDetails);
  const dispatch = useDispatch();

  const handleTabsChange = (e, value) => setCurrentTab(value);

  return (
    <Paper className={classes.paper}>
      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabsChange}
      >
        <Tab label="Shipping Details" />
        {!!shippingLabels?.length && <Tab label="Shipping Labels" />}
      </Tabs>
      <TabPanel value={currentTab} index={SHIPPING_DETAILS_TAB_INDEX}>
        <SelectedShippingDetailsTab
          submitting={submitting}
          submitFedExComponent={submitFedExComponent}
          width={width}
          height={height}
          length={length}
          weight={weight}
          shippingAddressSelected={shippingAddressSelected}
          shippingOption={shippingOption}
          shippingMethodSelected={shippingMethodSelected}
          dropboxOptions={dropboxOptions}
          shippingLabels={shippingLabels}
          dropboxSelected={dropboxSelected}
          handleDropboxChange={(value) => dispatch(setDropboxSelected(value))}
          handleModalChange={handleModalChange('shippingDimensionsModal')}
          handleTabsChange={handleTabsChange}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={SHIPPING_LABELS_TAB_INDEX}>
        <ShippingLabelsTab shippingLabels={shippingLabels} />
      </TabPanel>
    </Paper>
  );
};
