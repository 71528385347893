import { Button, Grid, Typography } from '@mui/material';
import { SlideAIToolsContent } from 'components/AITools/components/SlideAIToolsContent';
import {
  AI_MODELS,
  AI_MODELS_ITEMS_MENU_OPTIONS,
} from 'components/AITools/constants/common';
import { useContentStyles } from 'components/AITools/constants/styles';
import {
  isQCModelExists,
  isSlideTissueMLDataAvailable,
  raiseOpacityChangeEvent,
} from 'components/AITools/utilities/common';
import { CollapseIcon } from 'images/icons/CollapseIcon';
import { ExpandIcon } from 'images/icons/ExpandIcon';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAiosynModelsOpacity,
  selectAiraModelsOpacity,
  selectQCModelsOpacity,
  selectSegmentationModelsOpacity,
  selectTissueModelsOpacity,
  setAiosynModelsOpacity,
  setAiraModelsOpacity,
  setQCModelsOpacity,
  setSegmentationModelsOpacity,
  setTissueModelsOpacity,
} from 'store/slices/aiToolsSlice';
import {
  groupSelector,
  setExpanded,
} from 'store/slices/slidesListSlice/slidesListSlice';

export const SlideAiToolsWrapper = ({
  slide,
  slidesTableProps,
  isTop,
  isExpanded,
}) => {
  const { classes } = useContentStyles();
  const slideGroup = useSelector(groupSelector);
  const airaOpacity = useSelector(selectAiraModelsOpacity);
  const aiosynOpacity = useSelector(selectAiosynModelsOpacity);
  const qcOpacity = useSelector(selectQCModelsOpacity);
  const tissueOpacity = useSelector(selectTissueModelsOpacity);
  const segmentationOpacity = useSelector(selectSegmentationModelsOpacity);

  const dispatch = useDispatch();

  const slides = useMemo(
    () =>
      slidesTableProps?.selectedSlides.length
        ? slidesTableProps?.selectedSlides
        : [slide],
    [slide, slidesTableProps],
  );

  const selectedSlidesCount = slides?.length
    ? `${slides.length}/${slidesTableProps?.slides?.length}`
    : 'None Selected';
  const slidesLabel = slidesTableProps ? selectedSlidesCount : slides?.length;

  const group = slideGroup?.name || 'None';

  const handleRaiseOpacity = (changeObject) => {
    const opacities = {
      airaOpacity,
      aiosynOpacity,
      qcOpacity,
      tissueOpacity,
      segmentationOpacity,
    };

    raiseOpacityChangeEvent({ ...opacities, ...changeObject });
  };

  const handleMatrixOpacityChange = (_, newValue) => {
    handleRaiseOpacity({ airaOpacity: newValue });
    dispatch(setAiraModelsOpacity(newValue));
  };

  const handleAiosynOpacityChange = (_, newValue) => {
    handleRaiseOpacity({ aiosynOpacity: newValue });
    dispatch(setAiosynModelsOpacity(newValue));
  };

  const handleQCModelsOpacityChange = (_, newValue) => {
    handleRaiseOpacity({ qcOpacity: newValue });
    dispatch(setQCModelsOpacity(newValue));
  };

  const handleTissueModelsOpacityChange = (_, newValue) => {
    handleRaiseOpacity({ tissueOpacity: newValue });
    dispatch(setTissueModelsOpacity(newValue));
  };

  const handleSegmentationModelsOpacityChange = (_, newValue) => {
    handleRaiseOpacity({ segmentationOpacity: newValue });
    dispatch(setSegmentationModelsOpacity(newValue));
  };

  const airaOpacityOptions = useMemo(
    () => ({
      opacity: airaOpacity,
      handleChange: handleMatrixOpacityChange,
      disabled: (modelName) =>
        !slides.some((currentSlide) =>
          isSlideTissueMLDataAvailable(currentSlide, modelName),
        ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [airaOpacity, slides],
  );

  const aiosynOpacityOptions = useMemo(
    () => ({
      opacity: aiosynOpacity,
      handleChange: handleAiosynOpacityChange,
      disabled: (modelName) =>
        !slides.some((currentSlide) =>
          isSlideTissueMLDataAvailable(currentSlide, modelName),
        ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aiosynOpacity, slides],
  );

  const autoQCOpacityOptions = useMemo(
    () => ({
      opacity: qcOpacity,
      handleChange: handleQCModelsOpacityChange,
      disabled: (modelName) =>
        !slides.some((currentSlide) =>
          isQCModelExists(currentSlide, modelName),
        ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [qcOpacity, slides],
  );

  const tissueSegmentationOpacityOptions = useMemo(
    () => ({
      opacity: tissueOpacity,
      handleChange: handleTissueModelsOpacityChange,
      disabled: (modelName) =>
        !slides.some((currentSlide) =>
          isSlideTissueMLDataAvailable(currentSlide, modelName),
        ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tissueOpacity, slides],
  );

  const semanticSegmentationOpacityOptions = useMemo(
    () => ({
      opacity: segmentationOpacity,
      handleChange: handleSegmentationModelsOpacityChange,
      disabled: (modelName) =>
        !slides.some((currentSlide) =>
          isSlideTissueMLDataAvailable(currentSlide, modelName),
        ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [segmentationOpacity, slides],
  );

  const opacityOptions = {
    [AI_MODELS.aira]: airaOpacityOptions,
    [AI_MODELS.aiosyn]: aiosynOpacityOptions,
    [AI_MODELS.autoQC]: autoQCOpacityOptions,
    [AI_MODELS.tissueSegmentation]: tissueSegmentationOpacityOptions,
    [AI_MODELS.semanticSegmentation]: semanticSegmentationOpacityOptions,
  };

  return (
    <Grid container direction="column" mt={0} className={classes.aiPanel}>
      <Grid className={classes.headerContainer}>
        <Grid paddingLeft="1.75rem">
          <Typography className={classes.aiHeaderText}>
            Slides Selected :{' '}
            <span className={classes.aiHeaderValue}>{slidesLabel}</span>
          </Typography>
        </Grid>
        <Grid className={classes.rightAlign} paddingRight="1.75rem">
          <Typography className={classes.aiHeaderText}>
            Group : <span className={classes.aiHeaderValue}>{group}</span>
          </Typography>
          <Button
            onClick={() =>
              dispatch(setExpanded({ isExpanded: !isExpanded, isTop }))
            }
            variant="outlined"
            className={classes.expandButton}
          >
            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
          </Button>
        </Grid>
      </Grid>
      {AI_MODELS_ITEMS_MENU_OPTIONS.map((model) => (
        <SlideAIToolsContent
          slide={slide}
          key={model.id}
          slidesTableProps={slidesTableProps}
          model={model}
          opacityOptions={opacityOptions[model.id]}
        />
      ))}
    </Grid>
  );
};
