import slide_annotations_image from 'images/newFeatures/slide_annotations_tutorial.gif';
import { SLIDE_ANNOTATIONS_MODAL_TUTORIAL_FIELD } from 'components/ImageViewer/constants';
import { DefaultTutorialModal } from 'components/Modals/TutorialModals/DefaultTutorialModal';
import { HOW_TO_USE_ANNOTATIONS_OPTIONS } from 'components/Modals/TutorialModals/constants';

export const SlideAnnotationTutorialModal = (props) => {
  const headerText = 'Slide Annotations Tutorial';
  const tutorialImage = slide_annotations_image;
  const tutorialOptions = HOW_TO_USE_ANNOTATIONS_OPTIONS;
  const showTutorialName = SLIDE_ANNOTATIONS_MODAL_TUTORIAL_FIELD;

  return (
    <DefaultTutorialModal
      {...props}
      headerText={headerText}
      tutorialImage={tutorialImage}
      tutorialOptions={tutorialOptions}
      showTutorialName={showTutorialName}
    />
  );
};
