import { makeStyles } from 'tss-react/mui';
import { HISTOWIZ_PRIMARY_COLOR } from 'utilities/colors';

export const useProgressItemCardStyles = makeStyles()(() => ({
  container: {
    borderRadius: '0.5rem',
    marginTop: '3rem',
    backgroundColor: '#FFFFFF',
    overflow: 'hidden',
    height: 'min-content',
    margin: '3rem 0.5rem 0.5rem 0.5rem',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
  },
  cardHeader: {
    width: '100%',
    backgroundColor: '#A4CDDB',
    padding: '1rem',
    borderRadius: '0.5rem',
  },
  cardContent: {
    padding: '1rem',
  },
  label: {
    fontSize: '20px',
    marginBottom: '0.5rem',
    color: '#244D5B',
    margin: 0,
  },
}));

export const useOrderHistoryStyles = makeStyles()(() => ({
  stepIcon: {
    transform: 'scale(1.2)',
    '@media (max-width: 1600px)': {
      marginLeft: '0.25rem',
    },
  },
  stepper: {
    paddingInline: '0.5rem',
  },
  stepLabel: {
    fontSize: '20px',
    fontWeight: 400,
    marginLeft: '1rem',
  },
  stepContent: {
    fontSize: '1.125rem',
    marginLeft: '1rem',
  },
}));

export const useShipStyles = makeStyles()((_theme, { color }) => ({
  chip: {
    padding: '0.5rem',
    borderRadius: '2rem',
    backgroundColor: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: '1rem',
    marginBottom: '1rem',
  },
  chipText: {
    fontSize: '1.125rem',
  },
}));

export const useFulfillmentTimeExplainedStyles = makeStyles()(() => ({
  container: {
    width: '800px',
    border: '1px solid black',
    padding: '2rem',
    borderRadius: '0.5rem',
    marginTop: '3rem',
  },
  logo: {
    width: '35px',
    height: '31px',
    marginRight: '1rem',
  },
  timeExplainedPoint: {
    marginBlock: '0.25rem',
  },
  text: {
    fontSize: '18px',
  },
}));

export const useOrderProgressStyles = makeStyles()(() => ({
  contactUs: {
    fontSize: '1.2rem',
    marginTop: '2rem',
  },
}));

export const useOrderDetailsStyles = makeStyles()(() => ({
  requestedServicesLabel: {
    fontSize: '18px',
    marginBottom: '1rem',
  },
  tatDatetime: {
    marginLeft: '0.5rem',
    fontWeight: 500,
    fontSize: '18px',
  },
  chipContainers: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  },
}));

export const useOtherOrdersStyles = makeStyles()(() => ({
  orderItem: {
    padding: '0.25rem',
    display: 'flex',
    flexDirection: 'row',
  },
  centralSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '140px',
    marginLeft: '1.5rem',
  },
  isFullfilledIndicator: {
    height: '20px',
    width: '20px',
    borderRadius: '10px',
    border: '2px solid black',
    marginTop: '6px',
  },
  fulfilledColor: {
    backgroundColor: HISTOWIZ_PRIMARY_COLOR,
  },
  orderName: {
    fontSize: '20px',
    fontColor: '#0000F5',
    marginBottom: '0.5rem',
  },
  orderStatus: {
    fontSize: '18px',
    width: 'max-content',
  },
  projectName: {
    fontSize: '20px',
  },
}));

export const useProgressStepperStyles = makeStyles()(
  (_theme, { color, stepper }) => ({
    noTimelineStepperContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.5rem',
    },
    stepIcon: {
      zIndex: 1,
    },
    stepLabel: {
      fontSize: stepper?.number ? '0.85rem' : '1rem',
      fontWeight: '400 !important',
      textAlign: 'center',
      borderRadius: '0.5rem',
    },
    connector: {
      height: '3px',
      border: 0,
    },
    disabledConnector: {
      backgroundColor: '#D1D5DB',
    },
    activeConnector: {
      backgroundColor: color + ' !important',
    },
    completedConnector: {
      backgroundColor: color + ' !important',
    },
    step: {
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    stepRoot: {
      maxWidth: '180px',
    },
    activeStep: {
      border: `1px solid ${color}`,
      backgroundColor: 'white',
    },
    activeStepCenter: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: color,
    },
    inactiveStep: {
      border: '1px solid #D1D5DB',
      backgroundColor: 'white',
    },
    completeStep: {
      backgroundColor: color,
      transition: 'all 1.5s ease',
    },
    checkMark: {
      color: 'white',
      width: '28px',
      height: '28px',
    },
    stepContainers: {
      marginBlock: '1rem',
    },
    stepName: {
      fontSize: '18px',
      marginLeft: '0.5rem',
      fontWeight: 500,
    },
    chart: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
    },
    chartLine: {
      position: 'absolute',
      width: 'calc(100% - 85px)',
      margin: '0 42px',
      height: '0.19rem',
      zIndex: 0,
      top: '1.16rem',
      left: '0',
      backgroundColor: color,
    },
    labels: {
      display: 'flex',
    },
    stepperContainer: {
      padding: '0.5rem',
    },
    stepWrapper: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      gap: '0.75rem',
      flexDirection: 'column',
      alignItems: 'center',
      width: '85px',
    },
    skeletonStep: {
      width: '2.5rem',
      height: '2.5rem',
      position: 'absolute',
      backgroundColor: 'white',
      borderRadius: '50%',
    },
  }),
);
