import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  shareIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  shareButton: {
    '&.MuiButtonBase-root': {
      padding: '0.3rem 0.5rem',
    },
  },
  buttonGroup: {
    margin: '0 0.5rem 0 auto',
  },
  embedButton: {
    marginTop: '1.5rem',
  },
}));

export const useStyles2 = makeStyles()(() => ({
  shareIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  buttonGroup: {
    marginTop: '15px',
    width: '100%',
  },
  shareButton: {
    '&.MuiButtonBase-root': {
      fontSize: '0.75rem',
    },
  },
  shareLabel: {
    '&.MuiButtonBase-root': {
      fontSize: '0.75rem',
    },
    '&.Mui-disabled': {
      color: 'black',
    },
  },
}));
