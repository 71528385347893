import { makeStyles } from 'tss-react/mui';

export const useRowInfoStyles = makeStyles()(
  (theme, { noBorder, noBold, isSmall }) => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.625rem 0',
      borderBottom: noBorder
        ? 'none'
        : `1px solid ${theme.palette.black.main} `,
    },
    title: {
      fontSize: '0.75rem',
      fontWeight: 700,
      color: 'rgba(0, 0, 0, 0.6)',
      textTransform: 'uppercase',
      letterSpacing: 0.1,
    },
    info: {
      fontWeight: noBold ? 400 : 700,
      display: 'flex',
      color: 'rgba(0, 0, 0, 0.8)',
      alignItems: 'center',
      fontSize: '0.875rem',
      letterSpacing: 0.1,

      '& svg': {
        width: isSmall ? '10px' : '15px',
        aspectRatio: '1 / 1',
        marginRight: '0.375rem',
        fill: '#a9a9a9',
      },
    },
  }),
);

export const useAiToolStyles = makeStyles()(() => ({
  title: {
    background:
      'linear-gradient(180deg, rgba(108, 162, 187, 0.6) 0%, rgba(108, 162, 187, 0) 268%)',
    padding: '5px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1.5px solid #dde5e9',
    borderRadius: '5px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '& > span:first-of-type': {
      fontSize: '16px',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    cursor: 'pointer',
  },
  lastUpdated: {
    fontSize: '12px',
    color: '#53575a',
  },
  body: {
    padding: '15px',
    border: '0.5px solid rgba(255, 255, 255, 1)',
    background: 'rgba(255, 255, 255, 0.4)',
    borderRadius: '6px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: '5px',
  },
  menuWrapper: {
    width: '100%',
    cursor: 'pointer',
    '& > div': {
      paddingTop: 0,
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
  informationMenu: {
    '& > div:last-child': {
      borderBottom: 'none',
    },
  },
}));

export const useInformationMenuStyles = makeStyles()((_, { isSmall }) => ({
  root: {
    paddingTop: isSmall ? 0 : '20px',
  },
  logo: {
    width: '150px',
  },
  companyDescription: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    paddingBlock: '1rem',
  },
  text: {
    fontSize: '14px',
    lineHeight: '24px',
    paddingTop: '15px',
  },
  informationMenu: {
    paddingTop: '1rem',
    '& > div:last-child': {
      borderBottom: 'none',
    },
  },
  flexWrapper: {
    display: 'flex',
    paddingTop: '1rem',
  },
}));
