import { Spinner } from 'components/Loading/Spinner/Spinner';
import { useGenerateUploadLink } from 'components/UserProfile/hooks';
import { ServicesMenu } from 'components/UserProfile/Upload/ServicesMenu';
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useOrdersTabContentStyles } from 'components/UserProfile/styles';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { ORDERS_VIEW } from 'components/TeamOverview/constants';
import { getIframeUrl } from 'components/UserProfile/utilities';
import { useSelector } from 'react-redux';
import { useGetOrderByUUIDQuery } from 'store/apis/orderApi';
import { isStaffSelector } from 'store/slices/userDetailsSlice';

export const UploadSection = ({
  setCurrentView,
  selectedOrderForUpload,
  uploadKey,
}) => {
  const { classes } = useOrdersTabContentStyles();
  const isStaff = useSelector(isStaffSelector);
  const isOrderSelected = !!selectedOrderForUpload?.name;
  const orderUUID = selectedOrderForUpload?.uuid;
  const [isKeyGenerating, setIsKeyGenerating] = useState(false);
  const { data: fetchedOrder, isLoading: isOrderLoading } =
    useGetOrderByUUIDQuery(orderUUID, { skip: isOrderSelected });
  const order = isOrderSelected ? selectedOrderForUpload : fetchedOrder;

  const { handlePrepareUpload, uploadLink } = useGenerateUploadLink(
    order,
    setIsKeyGenerating,
  );
  const uploadLinkKey = uploadKey || order?.files_com_share_url || uploadLink;
  const isLoading = isOrderLoading || isKeyGenerating;

  useEffect(() => {
    if (!uploadLinkKey && order?.name) {
      handlePrepareUpload(order, { uuid: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Box className={isStaff ? classes.gridContainer : ''}>
      <Card
        header={
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box
              onClick={() => setCurrentView(ORDERS_VIEW)}
              className={classes.backContainer}
            >
              <ChevronLeftIcon fontSize="large" />
              <Typography className={classes.backLabel}>Back</Typography>
              <Typography className={classes.cardTitle}>
                {`${order?.project?.name || 'New Project'} / ${
                  order?.name || 'New Order'
                }`}
              </Typography>
            </Box>
          </Box>
        }
      >
        <Box className={classes.container}>
          {isLoading ? (
            <Box className={classes.spinnerWrapper}>
              <Spinner />
            </Box>
          ) : (
            <iframe
              src={getIframeUrl(uploadLinkKey)}
              className={classes.iframe}
              title="upload"
            />
          )}
        </Box>
      </Card>
      {isStaff && <ServicesMenu orderUUID={orderUUID} />}
    </Box>
  );
};
