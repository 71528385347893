import { Typography } from '@mui/material';
import { useAIModalRowStyles } from 'components/AITools/constants/styles';

export const SlideAIModelModalRow = ({ value, field, ...props }) => {
  const { classes } = useAIModalRowStyles();

  return (
    <Typography className={classes.text} {...props}>
      {field}
      {': '}
      <span className={classes.value}>{value}</span>
    </Typography>
  );
};
