export const SAMPLES_NAME_COLUMN_MAX_LIMIT = 12;
export const BONE_DECALCIFICATION = 'bone_decalcification';

export const BONE_ORGAN = 'Bone';

export const NON_VALID_SUBMISSIONS_FOR_BONE_ORGAN = [
  'unstained',
  'paraffin_block',
  'oct_block',
];
