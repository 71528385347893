import { MAX_FILTERS_SEARCH_COUNT } from 'components/PathologyMap/constants/common';
import { convertRefinementsListToFilters } from 'components/PathologyMap/utilities/common';
import { backendAPI } from 'services/backendAPI';

export const searchForAlgoliaFacets = ({
  algoliaIndex,
  searchValue,
  currentRefinement,
  currentRefinements,
}) => {
  const results = algoliaIndex?.searchForFacetValues(
    currentRefinement,
    searchValue,
    {
      maxFacetHits: MAX_FILTERS_SEARCH_COUNT,
      ...(currentRefinements.length && {
        filters: convertRefinementsListToFilters(
          currentRefinements,
          currentRefinement,
        ),
      }),
    },
  );
  return results;
};

// need to be removed after the new search is fully released
export const sendFeedbackToChris = (data) => {
  const url = '/search_2_chris_feedback/';
  return backendAPI.post(url, data);
};
