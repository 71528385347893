import { withStyles } from 'tss-react/mui';

import { _UserUploadedAttachment } from 'components/UserUploads/uploads';
import { withSnackbar } from 'notistack';

const dropZoneStyles = () => ({
  dropZoneContainer: {},
  dropZoneLabel: {
    margin: '0.75rem 0.5rem',
  },
  dropZone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

class _POUploadAttachment extends _UserUploadedAttachment {
  uploadAttachment = (formData) => {
    this.props.handleUpdatePOAttachment(formData);
  };
}

export const POUploadAttachment = withStyles(
  withSnackbar(_POUploadAttachment),
  dropZoneStyles,
);
