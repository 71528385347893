import { Grid } from '@mui/material';
import { useStats } from 'react-instantsearch-hooks-web';
import { PathologyMapResultsCounter } from 'components/PathologyMap/Shared/PathologyMapResultsCounter';
import { PathologyMapSectionHeaderWrapper } from 'components/PathologyMap/Shared/PathologyMapSectionHeaderWrapper';
import { HeaderPathologyMapTypography } from 'components/PathologyMap/Shared/PathologyMapTypography/HeaderPathologyMapTypography';

export const PathologyMapActiveFiltersHeader = () => {
  const { nbHits } = useStats();

  return (
    <PathologyMapSectionHeaderWrapper>
      <Grid item xs>
        <HeaderPathologyMapTypography>
          ACTIVE FILTERS
        </HeaderPathologyMapTypography>
      </Grid>
      <Grid
        item
        container
        xs
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item>
          <HeaderPathologyMapTypography>RESULTS</HeaderPathologyMapTypography>
        </Grid>
        <Grid item>
          <PathologyMapResultsCounter count={nbHits} />
        </Grid>
      </Grid>
    </PathologyMapSectionHeaderWrapper>
  );
};
