import { Box, Typography } from '@mui/material';
import React from 'react';

export const ContributedDataCardHeader = ({ classes }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      <Typography className={classes.cardTitle}>contributed data</Typography>
    </Box>
  );
};
