import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useSuccessSignupPageStyles } from 'components/pages/styles';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { TEAM_OVERVIEW_URL } from 'constants/urls';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const SuccessSignupPage = () => {
  const history = useHistory();
  const { classes } = useSuccessSignupPageStyles();
  const [timer, setTimer] = useState(5);
  useTitle('HistoWiz - Register Success');

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer > 0) {
        setTimer((timer) => timer - 1);
      }
    }, 1000);

    if (timer === 0) {
      history.push(TEAM_OVERVIEW_URL);
    }

    return () => clearInterval(timerInterval);
  }, [timer]);

  return (
    <Box className={classes.root}>
      <CheckCircleIcon className={classes.icon} color="inherit" />
      <Typography className={classes.title}>
        Success! You have signed up for HistoWiz! Thank you!
      </Typography>
      <Typography className={classes.timer}>
        Redirecting in {timer} seconds...
      </Typography>
    </Box>
  );
};
