export const SUMMARY_TAB_VALUE = 'summary';
export const ACTIVITY_TAB_VALUE = 'activity';
export const BILLING_AND_SHIPPING_TAB_VALUE = 'billing_and_shipping';
export const IMAGE_ANALYSIS_PATHOLOGY_CONSULTATION_TAB_VALUE =
  'image_analysis_and_pathology_consultation';
export const EMBEDDING_AND_CUTTING_TAB_VALUE = 'embedding_and_cutting';
export const PROCESSED_SLIDES_TAB_VALUE = 'processed_slides';
export const PROCESSED_IF_SLIDES_TAB_VALUE = 'processed_if_slides';
export const CONSOLIDATED_TAB_VALUE = 'consolidated';
export const SAMPLES_DETAILS_TAB_VALUE = 'samples_details';
export const ORDER_PROGRESS_TAB_VALUE = 'order_progress';
export const ORDER_FILES_UPLOAD_SUCCESS =
  'Files uploaded successfully! Please refresh this page.';
export const FILE_ATTACHMENTS_FAILED_TO_UPLOAD =
  'Files upload failed! Please try again.';

export const VALID_ORDER_DETAILS_TAB_VALUES = [
  SUMMARY_TAB_VALUE,
  ACTIVITY_TAB_VALUE,
  BILLING_AND_SHIPPING_TAB_VALUE,
  IMAGE_ANALYSIS_PATHOLOGY_CONSULTATION_TAB_VALUE,
  EMBEDDING_AND_CUTTING_TAB_VALUE,
  PROCESSED_SLIDES_TAB_VALUE,
  PROCESSED_IF_SLIDES_TAB_VALUE,
  CONSOLIDATED_TAB_VALUE,
  SAMPLES_DETAILS_TAB_VALUE,
  ORDER_PROGRESS_TAB_VALUE,
];
