import Box from '@mui/material/Box';
import { DiscountIcon } from 'images/icons/DiscountIcon';

const DISCOUNT_TOOLTIP = {
  title: 'Promo Priced Antibody',
  color: '#FFE6E5',
  text: 'Special rate for :name. ',
};

const HAS_PARENT_TOOLTIP = {
  title: 'Similar Antibody',
  color: '#F0E9FF',
  text: 'There are :children_count results matching :name.',
};

const getTooltipValue = (p) => {
  const isDiscount = p.data?.discount_level;
  const hasParent = p.node?.parent?.rowIndex;

  if (isDiscount) {
    return {
      ...DISCOUNT_TOOLTIP,
      text: DISCOUNT_TOOLTIP.text.replace(':name', p.data.name),
    };
  }
  if (hasParent) {
    const childrenCount = p.node?.parent?.allChildrenCount;

    return {
      ...HAS_PARENT_TOOLTIP,
      text: HAS_PARENT_TOOLTIP.text
        .replace(':children_count', childrenCount)
        .replace(':name', p.data.name),
    };
  }

  return null;
};

const isDiscount = (row) => row.discount_level === 1;

export const checkIfDiscountExists = (items) => items?.some(isDiscount);

const DiscountCellRenderer = ({ data }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
  >
    {isDiscount(data) ? <DiscountIcon /> : null}
  </Box>
);

const DiscountColumn = {
  headerName: 'Promo',
  field: 'discount_level',
  width: 64,
  cellRenderer: DiscountCellRenderer,
  tooltipValueGetter: (p) => getTooltipValue(p),
  suppressMenu: true,
  sortable: false,
};

export const columnsWithDiscountTooltip = (columns, rows, isForOrderForm) => {
  const isDiscountExist = checkIfDiscountExists(rows);
  let columnsToDisplay = columns;
  if (isDiscountExist) {
    const columnsCopy = [...columns];
    columnsCopy.splice(0, 0, DiscountColumn);
    columnsToDisplay = columnsCopy;
  }
  if (
    isForOrderForm &&
    !columnsToDisplay.some((column) => column.field === 'selected')
  ) {
    columnsToDisplay.splice(0, 0, {
      headerName: '',
      checkboxSelection: true,
      width: 35,
      filter: false,
      field: 'selected',
    });
  }

  return columnsToDisplay;
};
