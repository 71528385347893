import React from 'react';
import { withStyles } from 'tss-react/mui';
import { toTitleCase } from 'utilities/general';
import { SummaryTable } from './ReviewTables';
import PropTypes from 'prop-types';
import { ORDER_CHART_URL } from 'constants/urls';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

const _EmbeddingCuttingInstructionsTable = ({ order, alternative }) => {
  const cuttingInstructionsLabel = toTitleCase(order.cutting_instructions);
  const boneDecalcification = toTitleCase(
    order.bone_decalcification.toString(),
  );

  const data = [
    { name: 'Fixative', value: order.fixative.name },
    { name: 'Fixation Time', value: order.fixation_time_hours },
    { name: 'Cutting Instructions', value: cuttingInstructionsLabel },
    {
      name: 'Section Thickness (Microns)',
      value: order.section_thickness_microns,
    },
    { name: 'Embedding Instructions (***)', value: 'Embed - ***' },
    {
      name: 'Additional Steps Per Sample',
      value: order.additional_steps_per_sample,
    },
    { name: 'Bone Decalcification Requested', value: boneDecalcification },
  ];

  return (
    <SummaryTable
      data={data}
      label={'Embedding and Cutting Instructions'}
      alternative={alternative}
    />
  );
};

_EmbeddingCuttingInstructionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const EmbeddingCuttingInstructions = withStyles(
  _EmbeddingCuttingInstructionsTable,
  styles,
);

export const AdditionalServicesTable = ({ order, alternative }) => {
  const requestedPathologyConsultation = toTitleCase(
    order.requested_pathology_consultation.toString(),
  );
  const requestedImageAnalysis = toTitleCase(
    order.requested_image_analysis.toString(),
  );

  const data = [
    { name: 'Image Analysis Requested', value: requestedImageAnalysis },
  ];

  if (order.image_analysis_attachment) {
    const image_analysis_attachment_details = {
      name: 'Image Analysis Results',
      value: 'Download',
      url: order.image_analysis_attachment,
    };
    data.push(image_analysis_attachment_details);
    const slidesChartDetails = {
      name: 'View IA Results as Graphs',
      value: 'Open',
      url: ORDER_CHART_URL.replace(':orderUUID', order.uuid),
    };
    data.push(slidesChartDetails);
  }

  data.push({
    name: 'Pathology Services Requested',
    value: requestedPathologyConsultation,
  });

  if (order.pathology_consultation_attachment) {
    const pathology_consultation_attachment_details = {
      name: 'Pathology Consultation Report',
      value: 'Download',
      url: order.pathology_consultation_attachment,
    };
    data.push(pathology_consultation_attachment_details);
  }

  if (data.length > 0) {
    return (
      <SummaryTable
        data={data}
        label={'Additional Services'}
        alternative={alternative}
      />
    );
  } else {
    return null;
  }
};
