import { useState } from 'react';
import { useSelector } from 'react-redux';
import { currentUserPreferencesSelector } from 'store/slices/userDetailsSlice';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useUpdateUserPreferencesMutation } from 'store/apis/userDetailsApi';
import { CONTENT_DO_NOT_SHOW_AGAIN_SUCCESS_MESSAGE } from 'constants/successMessages';
import { CONTENT_DO_NOT_SHOW_AGAIN_ERROR_MESSAGE } from 'constants/errorMessages';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { Button, FormControlLabel, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useDefaultTutorialModalStyles } from './styles';

export const DefaultTutorialModal = ({
  open,
  forceOpen,
  onClose,
  headerText,
  tutorialImage,
  tutorialOptions,
  showTutorialName,
  renderBeforeImage,
  renderAfterImage,
  renderTutorialMedia,
}) => {
  const { classes } = useDefaultTutorialModalStyles();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const preferences = useSelector(currentUserPreferencesSelector);
  const showTutorial = (preferences[showTutorialName] && open) || forceOpen;
  const { showError, showSuccess } = useSnackbar();
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();

  const handleDoNotShowAgainChange = (e) => {
    setDoNotShowAgain(e.target.checked);
  };

  const handleClose = (...args) => {
    if (doNotShowAgain) {
      const postParams = {
        [showTutorialName]: false,
      };

      updateUserPreferences({ postParams, partial: true })
        .unwrap()
        .then(() => showSuccess(CONTENT_DO_NOT_SHOW_AGAIN_SUCCESS_MESSAGE))
        .catch(() => showError(CONTENT_DO_NOT_SHOW_AGAIN_ERROR_MESSAGE));
    }
    onClose(...args);
  };

  return (
    <DefaultModal open={showTutorial} onClose={handleClose}>
      <Typography
        align={'center'}
        variant={'h5'}
        gutterBottom={true}
        className={classes.headerText}
        color={'secondary'}
      >
        {headerText}
      </Typography>

      {renderBeforeImage && renderBeforeImage()}

      {tutorialImage && (
        <img
          src={tutorialImage}
          width={'100%'}
          alt={'Slide Annotations Tutorial'}
        />
      )}

      {renderTutorialMedia && renderTutorialMedia()}

      {tutorialOptions && (
        <div className={classes.howToUseWrapper}>
          <ul>
            {tutorialOptions.map((option, idx) => (
              <li key={idx}>
                <b>{option}</b>
              </li>
            ))}
          </ul>
        </div>
      )}

      {renderAfterImage && renderAfterImage()}

      {!forceOpen && (
        <Grid
          container
          className={classes.buttonsWrapper}
          direction={'column'}
          alignItems={'center'}
        >
          <Grid item>
            <Button
              onClick={handleClose}
              variant="contained"
              color={'secondary'}
              size={'small'}
            >
              Close
            </Button>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={doNotShowAgain}
                  onChange={handleDoNotShowAgainChange}
                />
              }
              label="Don't show again"
            />
          </Grid>
        </Grid>
      )}
    </DefaultModal>
  );
};
