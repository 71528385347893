import React from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CAPTION_GREY } from 'utilities/colors';
import { sortOrder } from 'utilities/constants';

const compareProps = (prev, next) =>
  prev.isActive === next.isActive && !next.isActive;

export const SortLabel = React.memo(
  ({ label, isActive = false, mode = sortOrder.DESC, onClick, classes }) => {
    const SortIcon =
      mode === sortOrder.DESC ? KeyboardArrowDownIcon : KeyboardArrowUpIcon;

    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={onClick}
      >
        <Typography className={classes.label}>{label}</Typography>
        <SortIcon
          cursor="pointer"
          color={isActive ? 'success' : CAPTION_GREY}
        />
      </Box>
    );
  },
  compareProps,
);
