import { makeStyles } from 'tss-react/mui';

export const useDefaultTutorialModalStyles = makeStyles()((theme) => ({
  buttonsWrapper: {
    marginTop: theme.spacing(3),
  },
  headerText: {
    marginBottom: '1rem',
  },
  howToUseWrapper: {
    marginTop: theme.spacing(2),
  },
}));
