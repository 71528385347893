import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SlideGroupNameInput } from 'components/SlideGroups/Modals/components/SlideGroupFormInputs';
import { FormInputTeamSelection } from 'components/OrderForm/components/InfoSelectDropdowns';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import {
  useCreateSlideGroup,
  useGetTeams,
} from 'components/SlideGroups/utilities/hooks';

// maybe after we've rolled this out and it's a little bit more stable
// otherwise, could be a bit more confusing for people to understand
// so default it to their 1st team
const showTeamSelectionOptions = false;

const addSlideGroupValidationSchema = object().shape({
  slideGroupName: string()
    .trim()
    .min(1, 'Requires at least 1 character.')
    .required('Required!'),
});

const FormStyles = () => ({});

const useStyles = makeStyles(FormStyles);

export const CreateSlideGroupForm = ({ onClose }, props) => {
  const classes = useStyles();

  const { createSlideGroup } = useCreateSlideGroup();

  // zero idea how setValidated does its magic
  const [validated] = useState(false);

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(addSlideGroupValidationSchema),
    defaultValues: {
      slideGroupName: '',
      selectedTeam: '',
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { touchedFields, errors, submitCount },
  } = methods;

  const { team, selectableTeams } = useGetTeams(setValue);

  const slideGroupNameWatched = watch('slideGroupName');

  // register selectedTeam so that the form knows about it
  // but don't show it to make it easier for users to register
  // and during registration, just default it to their 1st team
  register('selectedTeam');

  const onSubmit = (values) => {
    const { slideGroupName, selectedTeam } = values;

    createSlideGroup(slideGroupName, selectedTeam)?.then(() => {
      onClose();
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.formItem}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SlideGroupNameInput
              name={'slideGroupName'}
              register={register}
              errors={errors}
              checkmark
              validated={validated}
              touched={touchedFields}
              submitCount={submitCount}
            />
            {showTeamSelectionOptions ? (
              // keep this here for the day we will want to turn it back on
              <FormInputTeamSelection
                name="selectedTeam"
                selectableTeams={selectableTeams}
                team={team}
              />
            ) : null}
            <Box mt={2}>
              <Button
                disabled={!slideGroupNameWatched}
                variant="contained"
                color="primary"
                fullWidth={true}
                type={'submit'}
                {...props}
              >
                Create
              </Button>
            </Box>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
