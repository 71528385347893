import React, { useState } from 'react';
import { CreateSlideGroupModal } from 'components/SlideGroups/Modals/CreateSlideGroupModal';
import Box from '@mui/material/Box';
import { useGetSlideGroupsQuery } from 'store/apis/slideGroupsApi';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import {
  CREATED_DATE_SORT,
  ViewTypes,
} from 'components/SlideGroups/utilities/constants';
import { useSlideGroupsListViewStyles } from 'components/SlideGroups/utilities/styles';
import { sortArray } from 'utilities/general';
import { slideGroupsSorter } from 'components/SlideGroups/utilities/utilities';
import { LayoutHeaderSection } from 'components/SlideGroups/LayoutHeaderSection';
import { SlideGroupsCardView } from 'components/SlideGroups/Views/CardView/SlideGroupsCardView';
import { SlideGroupsListView } from 'components/SlideGroups/Views/ListView/SlideGroupsListView';

const getSlideGroupsView = (view) =>
  ({
    [ViewTypes.CARD_VIEW]: SlideGroupsCardView,
    [ViewTypes.LIST_VIEW]: SlideGroupsListView,
  }[view]);

export const SlideGroupsLayout = () => {
  const { classes } = useSlideGroupsListViewStyles();

  const [slideGroupModalOpen, setSlideGroupModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState(CREATED_DATE_SORT);
  const [view, setView] = useState(ViewTypes.CARD_VIEW);

  const { data: slideGroups, isFetching } = useGetSlideGroupsQuery();

  const sortedSLideGroups = sortArray(slideGroups, slideGroupsSorter, {
    field: sortBy,
  });

  const View = getSlideGroupsView(view);

  return (
    <>
      <LayoutHeaderSection
        classes={classes}
        sortBy={sortBy}
        setSortBy={setSortBy}
        view={view}
        setView={setView}
        slideGroups={slideGroups}
        setSlideGroupModalOpen={setSlideGroupModalOpen}
      />
      {isFetching ? (
        <Box className={classes.spinnerContainer}>
          <Spinner />
        </Box>
      ) : (
        <View slideGroups={sortedSLideGroups} classes={classes} />
      )}
      <CreateSlideGroupModal
        open={slideGroupModalOpen}
        onClose={() => setSlideGroupModalOpen(false)}
      />
    </>
  );
};
