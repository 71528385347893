import { getFormattedDate } from 'components/Projects/helpers/functions';
import { IS_TUMOR_FIELD_NAME } from 'components/OrderForm/constants';
import { backendURL } from 'services/backendAPI';

const getTumorType = (block) => {
  const displayedTumorType = {
    true: 'Positive',
    false: 'Negative',
    null: 'Unknown',
  };
  const tumorType = block.samples[0]?.[IS_TUMOR_FIELD_NAME];
  return displayedTumorType[tumorType];
};

const orderIDsGetter = (samples) => {
  const orderIDs = samples?.map((s) => s.order_id);
  const uniqueOrderIDs = [...new Set(orderIDs)];

  return uniqueOrderIDs.join(', ');
};

export const getBlockSlidesCount = (block) => {
  const slidesCount = block.samples.reduce((acc, sample) => {
    return acc + (sample.slides || []).filter((s) => s.is_public).length;
  }, 0);

  return slidesCount;
};

export const getBlocksProjectCount = (blocks) => {
  const projects = blocks.map((block) => block.projectName);

  const uniqProjects = [...new Set(projects)];

  return uniqProjects.length;
};

export const getRowData = (blocksData, projects) => {
  return blocksData
    .filter((block) => {
      const order = projects
        ? projects
            .flatMap((project) => project.orders)
            .find((order) =>
              order.name.includes(block.original_sample?.order_id),
            )
        : null;
      return order && order.state !== 'cancelled';
    })
    .map((block) => {
      const projectName = projects
        ? projects.find((project) =>
            project.orders.find((order) =>
              order.name.includes(block.original_sample?.order_id),
            ),
          )?.name
        : '';
      const orderName = orderIDsGetter(block.samples);
      const slidesCount = getBlockSlidesCount(block);

      return {
        order: [projectName, block.name],
        name: block.name,
        uuid: block.uuid,
        sampleUUID: block.original_sample?.uuid,
        atHistowiz: !block.samples[0]?.order.ship_back_samples,
        modified: getFormattedDate(block.modified),
        created: getFormattedDate(block.created),
        species: block.species?.name || 'N/A',
        submission: block.submission?.name || 'N/A',
        organ: block.organ?.name,
        tumorType: getTumorType(block),
        controlType: block.samples[0]?.control_type || 'N/A',
        slidesCount,
        block: { ...block, orderName, projectName },
      };
    });
};

export const getDataPath = (data) => {
  return data.order || null;
};

export const getSelectedSlides = (block) => {
  if (!block?.samples) return [];

  return block.samples
    .map((sample) => sample.slides)
    .flat()
    .filter((slide) => slide?.is_public)
    .map((slide) => {
      return {
        ...slide,
        orderName: block.orderName,
        projectName: block.projectName,
        blockName: block.name,
      };
    });
};

export const getSingleBlocksMenu = ({ row, samplesGridAPI, mergeSamples }) => {
  const lisaLink = `${backendURL}/lisa/core/block/${row.id}/change/`;
  const selectedSamplesUUIDs = samplesGridAPI
    .getSelectedRows()
    ?.map((sample) => sample.uuid);

  return [
    {
      name: 'Edit Block in LISA',
      action: () => window.open(lisaLink, '_blank'),
    },
    {
      name: 'Add selected samples to this block',
      disabled: !selectedSamplesUUIDs.length,
      action: mergeSamples,
    },
  ];
};

export const getMultiBlocksMenu = ({ mergeBlocks }) => [
  {
    name: 'Merge Blocks',
    action: mergeBlocks,
  },
];

export const getSingeSamplesMenu = ({ createBlock }) => [
  {
    name: 'Create Block from Sample',
    action: createBlock,
  },
];

export const getCurrentSlides = (selectedSlides, currentSample) =>
  selectedSlides.filter((slide) =>
    currentSample?.samples?.some((sample) =>
      sample.slides.some((s) => s.uuid === slide.uuid),
    ),
  );
