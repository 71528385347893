import { Button, Grid, Tooltip } from '@mui/material';
import React from 'react';

export const BottomButtonsSection = ({
  cancelButtonText = 'Back',
  cancelButtonClick,
  isCancelButtonDisabled = false,
  confirmButtonText = 'Next',
  confirmButtonClick,
  isConfirmButtonDisabled = false,
  isNextDisabled = false,
}) => (
  <Grid container direction="row" spacing={2} marginTop={2}>
    <Grid item xs={6}>
      <Button
        variant="outlined"
        color="pink"
        fullWidth={true}
        onClick={cancelButtonClick}
        disabled={isCancelButtonDisabled}
      >
        {cancelButtonText}
      </Button>
    </Grid>
    <Tooltip
      title={isNextDisabled ? 'Amount of data usage should be more than 0' : ''}
    >
      <Grid item xs={6}>
        <Button
          variant="contained"
          color="pink"
          fullWidth={true}
          type={'submit'}
          onClick={confirmButtonClick}
          disabled={isConfirmButtonDisabled || isNextDisabled}
        >
          {confirmButtonText}
        </Button>
      </Grid>
    </Tooltip>
  </Grid>
);
