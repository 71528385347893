import {
  CONTRIBUTE_ORDERS_RESOURCE_NAME,
  EXPORT_ORDERS_ON_HARD_DISK_RESOURCE_NAME,
  NOTIFY_CLIENT_ORDER_COMMENT_RESOURCE_NAME,
  ORDER_COMMENTS_RESOURCE_NAME,
} from 'services/resources/apiConstants';
import { baseCreateApi } from 'store/apis/baseCreateApi';
import { defaultActionAsyncHandler } from 'store/utilities';
import { setOrders } from 'store/slices/ordersSlice';

export const orderApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => ({
        url: 'orders/',
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getOrdersHistory: builder.query({
      query: () => ({
        url: 'orders/order_history/',
        method: 'GET',
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler({ dispatch, queryFulfilled }, setOrders);
      },
      keepUnusedDataFor: 0,
    }),
    getOrderByUUID: builder.query({
      query: (orderUUID) => ({
        url: `orders/${orderUUID}/`,
        method: 'GET',
      }),
      providesTags: ['getOrderByUUID'],
      keepUnusedDataFor: 0,
    }),
    getOrdersForManageData: builder.query({
      query: () => ({
        url: 'orders/',
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getSlidesByUUIDs: builder.query({
      query: (slideUUIDs) => ({
        url: 'slides_batch/',
        method: 'POST',
        body: { slides_uuids: slideUUIDs },
      }),
    }),
    createOrder: builder.mutation({
      query: (postParams) => ({
        url: 'orders/',
        method: 'POST',
        body: postParams,
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ orderUUID, postParams }) => ({
        url: `orders/${orderUUID}/`,
        method: 'PUT',
        body: postParams,
      }),
      //@todo: this is a hack to get the order to update after the mutation. The response doesn't contain the complete updated order.
      invalidatesTags: ['getOrderByUUID'],
    }),
    deleteOrder: builder.mutation({
      query: (orderUUID) => ({
        url: `orders/${orderUUID}/`,
        method: 'DELETE',
        body: { order_uuid: orderUUID },
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          baseCreateApi.util.updateQueryData(
            'getOrders',
            undefined,
            (draft) => {
              const index = draft.findIndex(
                (order) => order.uuid === queryArgs,
              );
              if (index !== -1) draft.splice(index, 1);
            },
          ),
        );
      },
    }),
    duplicateOrder: builder.mutation({
      query: (orderUUID) => ({
        url: `orders/${orderUUID}/duplicate_order/`,
        method: 'POST',
      }),
    }),
    notifyTeamMembers: builder.mutation({
      query: ({ postParams }) => ({
        url: CONTRIBUTE_ORDERS_RESOURCE_NAME,
        method: 'POST',
        body: postParams,
      }),
    }),
    contributeOrders: builder.mutation({
      query: ({ postParams }) => ({
        url: CONTRIBUTE_ORDERS_RESOURCE_NAME,
        method: 'POST',
        body: postParams,
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          baseCreateApi.util.updateQueryData(
            'getOrdersForManageData',
            undefined,
            (draft) => {
              queryArgs.postParams.orders.forEach(({ uuid: orderUUID }) => {
                const orderForUpdateIDX = draft.findIndex(
                  (order) => order.uuid === orderUUID,
                );
                if (orderForUpdateIDX !== -1)
                  draft[orderForUpdateIDX].pathology_map_consent = true;
              });
            },
          ),
        );
      },
      invalidatesTags: ['getTeams'],
    }),
    exportOrdersOnHardDisk: builder.mutation({
      query: ({ postParams }) => ({
        url: EXPORT_ORDERS_ON_HARD_DISK_RESOURCE_NAME,
        method: 'POST',
        body: postParams,
      }),
    }),
    getExportedOnHardDiskOrders: builder.query({
      query: () => ({
        url: EXPORT_ORDERS_ON_HARD_DISK_RESOURCE_NAME,
        method: 'GET',
      }),
    }),
    archiveOrders: builder.mutation({
      query: ({ queryParams }) => ({
        url: `orders/storage_order?${queryParams}`,
        method: 'GET',
      }),
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          baseCreateApi.util.updateQueryData(
            'getOrdersForManageData',
            undefined,
            (draft) => {
              queryArgs.orders.forEach(({ uuid: orderUUID }) => {
                const orderForUpdateIDX = draft.findIndex(
                  (order) => order.uuid === orderUUID,
                );
                if (orderForUpdateIDX !== -1)
                  draft[orderForUpdateIDX].is_storage_archived = true;
              });
            },
          ),
        );
      },
      invalidatesTags: ['getTeams'],
    }),
    getOrderComments: builder.query({
      query: ({ orderUUID }) => {
        const url = ORDER_COMMENTS_RESOURCE_NAME.replace(
          ':orderUUID',
          orderUUID,
        );
        return {
          url,
          method: 'GET',
        };
      },
    }),
    postOrderCommentWithSlides: builder.mutation({
      query: ({ orderUUID, postParams }) => {
        const url = ORDER_COMMENTS_RESOURCE_NAME.replace(
          ':orderUUID',
          orderUUID,
        );
        return {
          url,
          method: 'POST',
          body: postParams,
        };
      },
    }),
    postNotifyClientOrderComment: builder.mutation({
      query: ({ orderUUID, postParams }) => {
        const url = NOTIFY_CLIENT_ORDER_COMMENT_RESOURCE_NAME.replace(
          ':orderUUID',
          orderUUID,
        );
        return {
          url,
          method: 'POST',
          body: postParams,
        };
      },
    }),
    generateUploadLink: builder.mutation({
      query: ({ orderUUID }) => ({
        url: `orders/${orderUUID}/generate_order_client_uploads_share_link/`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useDuplicateOrderMutation,
  useGetSlidesByUUIDsMutation,
  useContributeOrdersMutation,
  useExportOrdersOnHardDiskMutation,
  useArchiveOrdersMutation,
  useLazyGetSlidesByUUIDsQuery,
  useGetOrdersQuery,
  useGetOrdersHistoryQuery,
  useGetExportedOnHardDiskOrdersQuery,
  useGetOrdersForManageDataQuery,
  useGetOrderByUUIDQuery,
  useLazyGetOrderByUUIDQuery,
  useLazyGetOrderCommentsQuery,
  usePostOrderCommentWithSlidesMutation,
  usePostNotifyClientOrderCommentMutation,
  useGenerateUploadLinkMutation,
} = orderApi;
