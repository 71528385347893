import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { RectangleIcon } from 'components/icons/RectangleIcon';
import { PolygonIcon } from 'components/icons/PolygonIcon';
import React from 'react';
import { useMainToolbarStyles } from 'components/ImageViewer/styles';
import { useDrawingToolButtonStyles } from 'components/ImageViewer/components/ImageViewerToolbar/styles';
import { DRAWING_TOOLS_TOOLTIPS } from 'components/ImageViewer/components/ImageViewerToolbar/constants';

export const DrawingToolsButtonGroup = ({
  handleDrawerToolChange,
  selectedDrawTool,
}) => {
  const { classes: mainToolbarClasses } = useMainToolbarStyles();
  const { classes: drawToolButtonClasses } = useDrawingToolButtonStyles();

  return (
    <ToggleButtonGroup
      onChange={handleDrawerToolChange}
      exclusive
      value={selectedDrawTool}
    >
      <ToggleButton
        value="rect"
        title={DRAWING_TOOLS_TOOLTIPS.RECTANGLE}
        className={`${drawToolButtonClasses.buttonWrapper} ${mainToolbarClasses.defaultButton}`}
      >
        <RectangleIcon />
      </ToggleButton>
      <ToggleButton
        value="polygon"
        title={DRAWING_TOOLS_TOOLTIPS.POLYGON}
        className={`${drawToolButtonClasses.buttonWrapper} ${mainToolbarClasses.defaultButton}`}
      >
        <PolygonIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
