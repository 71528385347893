import React, { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, Grid } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import { ExportToExcelButton } from 'components/OrderForm/Buttons/ExportToExcelButton';

const useStyles = makeStyles()((theme) => ({
  button: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  rightGridAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  imageIconWrapper: {
    marginBlock: '0.25rem',
  },
}));

export const SamplesGridBottomButtons = ({
  isLoading,
  tutorialAction,
  exportToExcelAction,
  renderSaveAndContinueButton,
}) => {
  const { classes } = useStyles();

  return (
    <Fragment>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={4}>
          {tutorialAction ? (
            <Button
              className={classes.button}
              variant="contained"
              color={'primary'}
              onClick={tutorialAction}
            >
              <Box
                display="flex"
                alignItems="center"
                mr={1}
                className={classes.imageIconWrapper}
              >
                <HelpOutline />
              </Box>
              Tutorial
            </Button>
          ) : null}
          <ExportToExcelButton
            isLoading={isLoading}
            onClick={exportToExcelAction}
            styles={classes.button}
          />
        </Grid>
        <Grid item xs={4} className={classes.rightGridAlign}>
          {renderSaveAndContinueButton()}
        </Grid>
      </Grid>
    </Fragment>
  );
};
