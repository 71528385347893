import { Skeleton, Typography, Box } from '@mui/material';
import { HISTOLOGY_STEPPER } from 'components/OrderDetails/Tabs/OrderProgressTab/constants';
import { StepIcon } from 'components/OrderDetails/Tabs/OrderProgressTab/StepIcon';
import { useProgressStepperStyles } from 'components/OrderDetails/Tabs/OrderProgressTab/styles';
import { LIGHT_GREY } from 'utilities/colors';

export const LinearStepper = ({ stepper, isLoading }) => {
  const color = isLoading || !stepper.color ? LIGHT_GREY : stepper.color;
  const { classes } = useProgressStepperStyles({ color, stepper });
  const steps = Object.values(stepper.steps);

  return (
    <Box className={classes.stepContainers}>
      <Typography className={classes.stepName} variant={'h4'}>
        {stepper.name}
      </Typography>
      <Box className={classes.stepperContainer}>
        <Box className={classes.chart}>
          {isLoading ? (
            <Skeleton variant="rectangular" className={classes.chartLine} />
          ) : (
            <Box className={classes.chartLine} />
          )}
          {steps.map((step, idx) => (
            <Box className={classes.stepWrapper} key={step}>
              {isLoading ? (
                <>
                  <Box className={classes.skeletonStep} />
                  <Skeleton variant="circular">
                    <StepIcon classes={classes} />
                  </Skeleton>
                  <Skeleton variant="text">
                    <Typography className={classes.stepLabel}>
                      {stepper.name === HISTOLOGY_STEPPER.name
                        ? 'Histology'
                        : 'Step Step Step'}
                    </Typography>
                  </Skeleton>
                </>
              ) : (
                <>
                  <StepIcon
                    key={step}
                    active={stepper.activeStepIDX === idx}
                    completed={stepper.activeStepIDX > idx}
                    classes={classes}
                    number={stepper.number ? idx + 1 : null}
                  />
                  <Typography className={classes.stepLabel}>{step}</Typography>
                </>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
