import { Button, Typography, Box } from '@mui/material';
import { DataRow } from 'components/TeamOverview/components/ConfirmActionModal/DataRow';
import { useOrderInfoStepStyles } from 'components/TeamOverview/components/ConfirmActionModal/styles';
import {
  parseSlides,
  renderCellValue,
} from 'components/TeamOverview/utilities';
import React from 'react';
import { SubmitActionTypes } from 'components/TeamOverview/constants';
import { Spinner } from 'components/Loading/Spinner/Spinner';

export const OrderInfoStep = ({
  order,
  actionType,
  isPrevStepExists,
  isStepSlidesLoading,
  currentStepIDX,
  ordersCount,
  stepSlides,
  handlePrevStep,
  handleNextStep,
}) => {
  const { classes } = useOrderInfoStepStyles();

  const parsedSlides = parseSlides(stepSlides);

  return (
    <>
      <Box display={'flex'} justifyContent="space-between">
        <Typography
          fontWeight={500}
          className={classes.orderInfo}
          component={'span'}
        >
          {renderCellValue(order?.project?.name)} /{' '}
          <b>{renderCellValue(order?.name)}</b>
        </Typography>
        {parsedSlides.length ? (
          <Typography>
            Review {parsedSlides.length} slides before{' '}
            {actionType === SubmitActionTypes.ARCHIVE_ACTION
              ? 'deleting'
              : 'contributing'}
          </Typography>
        ) : null}
      </Box>
      <Box className={classes.table}>
        <DataRow
          dataUsed="USED"
          slideName="SLIDE NAME"
          fixativeTime="FIXATIVE TIME"
          antibodyName="ANTIBODY"
          sampleName="SAMPLE"
          speciesName="SPECIES"
          stainType="STAIN TYPE"
          organName="ORGAN"
        />
        <Box className={classes.rowsScrollableContainer}>
          {isStepSlidesLoading ? (
            <Box className={classes.centeredContentContainer}>
              <Spinner size={40} />
            </Box>
          ) : parsedSlides.length ? (
            parsedSlides.map((slide) => <DataRow {...slide} />)
          ) : (
            <Box className={classes.centeredContentContainer}>
              <Typography>No slides</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.pagination}>
        <Box className={classes.currentPage}>
          <Typography>{currentStepIDX + 1}</Typography>
        </Box>
        <Typography>of</Typography>
        <Typography className={classes.pagesCount}>{ordersCount}</Typography>
      </Box>
      <Box className={classes.buttonsSection}>
        {isPrevStepExists && (
          <Button onClick={handlePrevStep} variant="outlined" color="pink">
            back
          </Button>
        )}
        <Box className={classes.nextButton}>
          <Button
            onClick={() => handleNextStep()}
            variant="contained"
            color="pink"
          >
            next
          </Button>
        </Box>
      </Box>
    </>
  );
};
