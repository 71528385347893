import { Grid, Typography, Box, Skeleton, Icon } from '@mui/material';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import { useCardsMenuSectionStyles } from 'components/TeamOverview/CardsMenuSection/styles';
import {
  COMPUTE_SECTION,
  DATA_MANAGEMENT_SECTION,
  ORDER_HISTORY_SECTION,
  TEAM_MEMBERS_SECTION,
} from 'components/TeamOverview/constants';
import { ORDERS_LIST_URL } from 'constants/urls';
import { FINALIZED_ORDER_LABEL } from 'components/OrdersList/constants';
import { HASH_PARAMS_DELIMITER } from 'utilities/constants';
import { useHistory } from 'react-router';
import { transformDataUsage } from 'components/UserProfile/utilities';
import { MB } from 'components/UserProfile/constants';
import {
  Grading,
  OpenInNew as OpenInNewIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { DatabaseIcon } from 'images/icons/DatabaseIcon';
import { AtrIcon } from 'images/icons/AtrIcon';
import { useSelector } from 'react-redux';
import { isStaffSelector } from 'store/slices/userDetailsSlice';

const ACTIVE_ICON_COLOR = 'rgba(84, 112, 255, 1)';
const YELLOW_ICON_COLOR = 'rgba(225, 113, 10, 1)';
export const GREEN_ICON_COLOR = 'rgba(15, 181, 72, 1)';
export const GREEN_TRANSPARENT_COLOR = 'rgba(15, 181, 72, 0.1)';
export const RED_ICON_COLOR = 'rgba(255, 88, 104, 1)';
export const RED_TRANSPARENT_COLOR = 'rgba(255, 88, 104, 0.1)';
export const PURPLE_ICON_COLOR = 'rgba(232, 43, 219, 1)';
export const PURPLE_TRANSPARENT_COLOR = 'rgba(232, 43, 219, 0.1)';

const MenuCard = ({ classes, isActive, children }) => (
  <Card cardClass={classes.fullHeight} isActive={isActive}>
    <Box className={`${classes.cardBody} ${isActive && 'active'}`}>
      {children}
      <KeyboardArrowDownIcon className={classes.downIcon} />
    </Box>
  </Card>
);

const CardDataSkeleton = ({ className }) => (
  <Skeleton
    variant="rectangular"
    height="1.75rem"
    width="7rem"
    className={className}
  />
);

const ManageDataCard = ({ classes, isActive, team }) => {
  const iconColor = isActive ? ACTIVE_ICON_COLOR : YELLOW_ICON_COLOR;
  const dataUsageLabel = transformDataUsage(team?.total_storage_used, MB);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.cardBodyTitle}>
          Data Management
        </Typography>
        <Box className={`${classes.iconBox} yellow ${isActive && 'active'}`}>
          <DatabaseIcon color={iconColor} />
        </Box>
      </Box>
      {team ? (
        <Typography className={classes.mainTextBold}>
          {dataUsageLabel}
        </Typography>
      ) : (
        <CardDataSkeleton className={classes.mainText} />
      )}
    </>
  );
};

const OrderHistoryCard = ({ classes, isActive, team }) => {
  const history = useHistory();

  const iconColor = isActive ? ACTIVE_ICON_COLOR : GREEN_ICON_COLOR;

  const redirectToOrdersPage = (teamUUID) => {
    const ordersPageUrl = `${ORDERS_LIST_URL}#${FINALIZED_ORDER_LABEL}${HASH_PARAMS_DELIMITER}${teamUUID}`;
    history.push(ordersPageUrl);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.cardBodyTitle}>Order History</Typography>
        <Box className={`${classes.iconBox} green ${isActive && 'active'}`}>
          <Grading sx={{ color: iconColor }} />
        </Box>
      </Box>
      {team ? (
        <Box
          className={classes.orderHistoryLink}
          onClick={() => redirectToOrdersPage(team.uuid)}
        >
          <Typography className={`${classes.mainText} ${classes.underlined}`}>
            <Typography component="span" className={classes.mainTextBold}>
              {team.total_orders_count}
            </Typography>{' '}
            Orders
          </Typography>
          <OpenInNewIcon className={classes.linkIcon} fontSize="small" />
        </Box>
      ) : (
        <CardDataSkeleton className={classes.mainText} />
      )}
    </>
  );
};

const ComputeCard = ({ classes, isActive, team }) => {
  const iconColor = isActive ? ACTIVE_ICON_COLOR : RED_ICON_COLOR;

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.cardBodyTitle}>Compute</Typography>
        <Box className={`${classes.iconBox} red ${isActive && 'active'}`}>
          <Icon sx={{ color: iconColor }}>attach_money</Icon>
        </Box>
      </Box>
      <Typography className={classes.mainText}>
        <Typography component="span" className={classes.mainTextBold}>
          {team?.total_ml_processing_slides_count}
        </Typography>{' '}
        Slides Processing
      </Typography>
    </>
  );
};

const TeamMembersCard = ({ classes, isActive, team }) => {
  const iconColor = isActive ? ACTIVE_ICON_COLOR : PURPLE_ICON_COLOR;

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.cardBodyTitle}>Team Members</Typography>
        <Box className={`${classes.iconBox} purple ${isActive && 'active'}`}>
          <AtrIcon color={iconColor} />
        </Box>
      </Box>
      {team ? (
        <Typography className={classes.mainText}>
          <Typography component="span" className={classes.mainTextBold}>
            {team.total_members_count}
          </Typography>{' '}
          Members
        </Typography>
      ) : (
        <CardDataSkeleton className={classes.mainText} />
      )}
    </>
  );
};

export const CardsMenuSection = ({
  selectedSection,
  setSelectedSection,
  selectedTeam,
}) => {
  const isStaff = useSelector(isStaffSelector);

  const { classes } = useCardsMenuSectionStyles();

  const COMPUTE_CARD = {
    name: COMPUTE_SECTION,
    Content: ComputeCard,
  };

  const MENU_CARDS = [
    {
      name: ORDER_HISTORY_SECTION,
      Content: OrderHistoryCard,
    },
    {
      name: DATA_MANAGEMENT_SECTION,
      Content: ManageDataCard,
    },
    {
      name: TEAM_MEMBERS_SECTION,
      Content: TeamMembersCard,
    },
  ];
  if (isStaff) {
    MENU_CARDS.splice(2, 0, COMPUTE_CARD);
  }

  return (
    <Grid item container spacing={2}>
      {MENU_CARDS.map(({ name, Content }) => (
        <Grid
          key={name}
          xs={isStaff ? 3 : 4}
          item
          onClick={() => setSelectedSection(name)}
          className={classes.menuCard}
        >
          <MenuCard isActive={selectedSection === name} classes={classes}>
            <Content
              classes={classes}
              isActive={selectedSection === name}
              team={selectedTeam}
            />
          </MenuCard>
        </Grid>
      ))}
    </Grid>
  );
};
