import React, { memo, useState } from 'react';
import {
  SLIDE_DATA_COPY_TEXT,
  SLIDE_DATA_COPY_TOGGLE_DELAY,
} from 'components/PathologyMap/constants/common';
import { Grid } from '@mui/material';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';

export const RowCell = memo(
  ({ value, classes, isSelected, isCopyEnabled = false }) => {
    const [isCopyStatusVisible, setIsCopyStatusVisible] = useState(false);
    const handleCopy = () => {
      if (!isCopyEnabled) return;

      navigator.clipboard.writeText(value).then(() => {
        setIsCopyStatusVisible(true);
        setTimeout(
          () => setIsCopyStatusVisible(false),
          SLIDE_DATA_COPY_TOGGLE_DELAY,
        );
      });
    };

    const shownContent = isCopyStatusVisible ? SLIDE_DATA_COPY_TEXT : value;

    return (
      <Grid
        onClick={handleCopy}
        className={`${classes.cell} ${
          isSelected ? classes.highlightedCell : ''
        }`}
      >
        <TextWithLimitedWidth
          value={shownContent}
          containerClass={classes.cell}
          textClass={isSelected ? classes.highlightedText : ''}
        />
      </Grid>
    );
  },
);
