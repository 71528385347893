import { SlideAIModalContent } from 'components/AITools/components/SlideAIModalContent';
import { SlideAIReportIssue } from 'components/AITools/components/SlideAIReportIssue';
import { useEffect, useState } from 'react';
import { ModalWithHeader } from 'components/Modals/ModalWithHeader';
import { useSelector } from 'react-redux';
import { aiToolsSelector } from 'store/slices/cmsSlice';
import { useGetCurrentAiModel } from 'components/pages/AppLibrary/AiModel/hooks';
import { MAP_MODEL_ID_TO_SPECIFIC_ORGAN } from 'components/AITools/constants/common';

export const SlideAIModelModal = ({
  selectedSlides,
  onClose,
  open,
  isProcessed = false,
  model,
  ...props
}) => {
  const [isReportOpen, setIsReportOpen] = useState(false);
  const aiToolsData = useSelector(aiToolsSelector);
  const [currentModel, setCurrentModel] = useState(
    aiToolsData.find((tool) => tool.toolId === open) || aiToolsData[0],
  );
  const { getCurrentModel } = useGetCurrentAiModel();

  useEffect(() => {
    if (currentModel || !open) {
      return;
    }

    getCurrentModel(open, setCurrentModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModel, getCurrentModel]);

  const handleReportOpen = () => {
    setIsReportOpen(true);
  };

  const handleReportClose = () => {
    setIsReportOpen(false);
  };

  const specificOrgan = MAP_MODEL_ID_TO_SPECIFIC_ORGAN[currentModel];
  const slidesToProcess = specificOrgan
    ? selectedSlides.filter(
      ({ sample }) => sample?.organ?.name === specificOrgan,
    )
    : selectedSlides;

  return currentModel ? (
    <ModalWithHeader
      header={currentModel?.title.toUpperCase()}
      isLimitedHeight
      showCloseIcon
      onClose={onClose}
      open={!!open}
      {...props}
    >
      {!isReportOpen ? (
        <SlideAIModalContent
          isProcessed={isProcessed}
          onClose={onClose}
          selectedSlides={slidesToProcess}
          currentModel={currentModel}
          handleReportOpen={handleReportOpen}
          model={model}
        />
      ) : (
        <SlideAIReportIssue
          onClose={onClose}
          handleReportClose={handleReportClose}
        />
      )}
    </ModalWithHeader>
  ) : null;
};
