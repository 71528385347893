import React, { useEffect, useMemo } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { UserMenu } from 'components/Layout/UserMenu';
import { LoginComponent } from 'components/Layout/loginButton';
import { useMiniDrawerWithContextStyles } from 'components/Layout/styles';
import classNames from 'classnames';
import {
  AppBar,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { getWhiteLabelLogo } from 'utilities/whitelabel';
import { GlobalSearch } from 'components/GlobalSearch/GlobalSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleDrawer as changeDrawerVisibility,
  minimizeDrawer,
  setIsDrawerOpen,
} from 'store/slices/userDetailsSlice';
import { updateCurrentUserPreferences } from 'services/resources/commonResources';
import { SHOW_DRAWER_FIELD } from 'components/ImageViewer/constants';
import { GENERAL_ERROR_MESSAGE } from 'constants/errorMessages';
import { matchPath, useHistory } from 'react-router';
import {
  IF_VIEWER_URL,
  ORDER_CHART_URL,
  ORDER_DETAILS_URL,
  ORDERS_SLIDE_LIST_URL,
  PATHOLOGIST_WORKLIST_URL,
  PATHOLOGY_MAP_SEARCH_2,
  SLIDE_NAVIGATOR_URL,
  SLIDE_VIEWER_URL,
  SLIDES_LIST_URL_2,
} from 'constants/urls';
import { usePreviousValue } from 'components/utilities/hooks/usePreiousValue';
import { Sidebar } from 'components/Layout/Sidebar';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useAuth0 } from '@auth0/auth0-react';
import { getLocalToken } from 'utilities/token';

const logoToUse = getWhiteLabelLogo();

const URLS_WHERE_DRAWER_SHOULD_BE_MINIMIZED = [
  SLIDE_VIEWER_URL,
  ORDERS_SLIDE_LIST_URL,
  ORDER_CHART_URL,
  SLIDE_NAVIGATOR_URL,
  ORDER_DETAILS_URL,
  IF_VIEWER_URL,
  PATHOLOGY_MAP_SEARCH_2,
  SLIDES_LIST_URL_2,
  PATHOLOGIST_WORKLIST_URL,
];

const URLS_WHERE_SEARCH_SHOULD_BE_HIDDEN = [
  PATHOLOGY_MAP_SEARCH_2,
  SLIDES_LIST_URL_2,
];
const MiniDrawer = ({
  classes,
  header,
  headerItalic,
  email,
  handleDrawerClick,
  isDrawerVisible,
  preferences,
  children,
  withoutBackground = false,
  isWithInfiniteScrollPage,
}) => {
  const history = useHistory();
  const prevEmail = usePreviousValue({ email });
  const dispatch = useDispatch();
  const { showError } = useSnackbar();

  const { isAuthenticated: isAuthenticatedWithJWT } = useAuth0();
  const isAuthenticatedWithLocalToken = getLocalToken();
  const shouldDrawerBeMinimized = useMemo(() => {
    const currentLocation = history.location.pathname;
    const filteredUrls = URLS_WHERE_DRAWER_SHOULD_BE_MINIMIZED.filter(
      (url) =>
        !!matchPath(currentLocation, {
          path: url,
          exact: true,
          strict: false,
        }),
    );

    return !!filteredUrls.length;
  }, [history.location.pathname]);

  const shouldSearchBeHidden = useMemo(() => {
    const currentLocation = history.location.pathname;
    const filteredUrls = URLS_WHERE_SEARCH_SHOULD_BE_HIDDEN.filter(
      (url) =>
        !!matchPath(currentLocation, {
          path: url,
          exact: true,
          strict: false,
        }),
    );

    return !!filteredUrls.length;
  }, [history.location.pathname]);

  useEffect(() => {
    if (shouldDrawerBeMinimized) {
      dispatch(minimizeDrawer());
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (prevEmail?.email !== undefined) {
      if (shouldDrawerBeMinimized && prevEmail.email !== email) {
        dispatch(minimizeDrawer());
      } else if (shouldDrawerBeMinimized && prevEmail === email) {
        dispatch(setIsDrawerOpen(isDrawerVisible));
      }
    }
  }, [email, isDrawerVisible, history]);

  const handleDrawer = () => {
    const copied = { ...preferences };
    copied[SHOW_DRAWER_FIELD] = !isDrawerVisible;

    const postParams = {
      preferences: {
        ...preferences,
        [SHOW_DRAWER_FIELD]: !isDrawerVisible,
      },
    };

    updateCurrentUserPreferences(postParams).catch(() => {
      showError(GENERAL_ERROR_MESSAGE);
    });

    handleDrawerClick();
  };

  const appBarPosition = shouldSearchBeHidden ? 'absolute' : 'fixed';

  let mainClassName = withoutBackground
    ? classes.clearContent
    : classes.content;

  if (isWithInfiniteScrollPage) {
    mainClassName += ` ${classes.noOverflowX}`;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position={appBarPosition}
        className={classNames(classes.appBar, {
          [classes.appBarShift]: isDrawerVisible,
        })}
      >
        <Toolbar
          disableGutters={!isDrawerVisible}
          className={classes.toolbarMui}
        >
          <Tooltip disableInteractive title="Sidebar" placement="right">
            <IconButton
              size="large"
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawer}
              className={classNames(classes.menuButton, {
                [classes.hide]: isDrawerVisible,
              })}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Grid className={classes.navGrid}>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.navHeader}
            >
              {header} {!!headerItalic && <i>{headerItalic}</i>}
            </Typography>
            {!shouldSearchBeHidden && (
              <div className={classes.searchWrapper}>
                <GlobalSearch />
              </div>
            )}
            <Typography
              className={classes.emailLabel}
              variant="h6"
              color="inherit"
              noWrap
            >
              {isAuthenticatedWithJWT || isAuthenticatedWithLocalToken ? (
                <UserMenu email={email} />
              ) : (
                <LoginComponent />
              )}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !isDrawerVisible && classes.drawerPaperClose,
          ),
        }}
        open={isDrawerVisible}
      >
        <div className={classes.toolbar}>
          <a
            href="/team_overview"
            rel="noopener noreferrer"
            className={classes.histowizToolbarSection}
          >
            <img src={logoToUse} alt="Logo" className={classes.logo} />
          </a>

          <IconButton size="large" onClick={handleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Sidebar isCollapsed={!isDrawerVisible} />
      </Drawer>

      <main className={mainClassName}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export const MiniDrawerWithContext = ({
  children,
  header,
  headerItalic,
  withoutBackground = false,
  hideDrawer = false,
  isWithInfiniteScrollPage = false,
}) => {
  const { classes } = useMiniDrawerWithContextStyles();
  const { email, showDrawer, preferences } = useSelector(
    (state) => state.userDetails,
  );

  const dispatch = useDispatch();

  const handleDrawerClick = () => {
    dispatch(changeDrawerVisibility());
  };

  if (hideDrawer) {
    return children;
  }

  return (
    <MiniDrawer
      classes={classes}
      email={email}
      header={header}
      headerItalic={headerItalic}
      handleDrawerClick={handleDrawerClick}
      isDrawerVisible={showDrawer}
      preferences={preferences}
      withoutBackground={withoutBackground}
      isWithInfiniteScrollPage={isWithInfiniteScrollPage}
    >
      {children}
    </MiniDrawer>
  );
};

export const MiniDrawerWithContextMemo = React.memo(MiniDrawerWithContext);
