import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import { useOrderTeamsSelectorStyles } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const OrderTeamsSelector = ({
  teams = [],
  selectedTeams = [],
  setSelectedTeams,
}) => {
  const { classes } = useOrderTeamsSelectorStyles();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedTeams(value);
  };

  const handleOnly = (event) => {
    event.stopPropagation();

    const {
      target: { id },
    } = event;

    const team = teams.find((team) => team.uuid === id);
    setSelectedTeams([team]);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Team filter</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedTeams}
          onChange={handleChange}
          input={<OutlinedInput label="Team filter" />}
          renderValue={(selected) =>
            selected.map(({ name }) => name).join(', ')
          }
          MenuProps={MenuProps}
        >
          {teams.map((team) => (
            <MenuItem key={team.uuid} value={team} className={classes.menuItem}>
              <Checkbox checked={selectedTeams.indexOf(team) > -1} />
              <ListItemText primary={team.name} />
              <Typography
                id={team.uuid}
                onClick={handleOnly}
                className={classes.onlyText}
              >
                Only
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
