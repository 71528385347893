import {
  ML_QC_BLADE_MARKS_CHECK_CONSTANT,
  ML_QC_CUT_DEEPER_ISSUE_CHECK_CONSTANT,
  ML_QC_FLOATERS_CHECK_CONSTANT,
  ML_QC_STAIN_CHECK_CONSTANT,
} from 'components/QCViewer/constants';

export const OrderCommentsNotifyClientModalName =
  'modalOrderCommentsNotifyClientOpen';
export const IHCOptimizationSlideSelectionOpenModalName =
  'modalIHCOptimizationSlideSelectionOpen';
export const ADD_SLIDE_TO_SLIDE_GROUP_MODAL_OPEN =
  'allSlideToSlideGroupModalOpen';

export const VIEW_SLIDE_MODAL_NAME = 'modalViewSlideOpen';

export const DeleteModalName = 'modalDeleteCommentOpen';

// legacy QC_CONSTANTS no longer important, or refactored into another view
// just stuck this here so i remember to remove
// 2022-10-23
// eslint-disable-next-line no-unused-vars
const UNUSUED_QC_CONSTANTS = [
  ML_QC_BLADE_MARKS_CHECK_CONSTANT,
  ML_QC_STAIN_CHECK_CONSTANT,
  ML_QC_CUT_DEEPER_ISSUE_CHECK_CONSTANT,
  ML_QC_FLOATERS_CHECK_CONSTANT,
];

export const DEFAULT_QC_REASON_BORDER_COLOR = 'gray.100';
export const DEFAULT_QC_REASON_BORDER_SIZE = 1;
export const MAX_SCORE_QC_PASSED = 0.4;
export const QC_REASON_PASSED_BORDER_COLOR = 'green';
export const MIN_SCORE_QC_FAILED = 0.6;
export const QC_REASON_FAILED_BORDER_COLOR = 'error.main';
export const QC_REASON_PASSED_OR_FAILED_BORDER_SIZE = 3;

export const DEFAULT_MODAL_SIZE = 'default';
export const LARGE_MODAL_SIZE = 'large';
export const SIMPLE_MODAL_SIZE = 'simple';
export const CUSTOM_WIDTH_MODAL_SIZE = 'custom';

export const ModalSize = {
  DEFAULT: DEFAULT_MODAL_SIZE,
  LARGE: LARGE_MODAL_SIZE,
  SIMPLE: SIMPLE_MODAL_SIZE,
};

export const PO_SUBMITTED_MESSAGE = 'PO successfully submitted';
export const PO_UPLOAD_REQUIRED_MESSAGE = 'Please specify and upload PO first';
export const PO_UPDATE_ERROR_MESSAGE = 'Error updating PO';

export const QC_BULK_RESET_FORM_ID = 'qcBulkResetForm';
