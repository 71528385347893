import { Fade, Box } from '@mui/material';
import { PathologyMapSlideCardHeader } from 'components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideCardHeader';
import { PathologyMapSlideCardContent } from 'components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideCardContent';
import { usePathologyMapSlideCardItemStyles } from 'components/PathologyMap/PathologyMapSlideCards/styles';
import { SLIDE_VIEWER_URL } from 'constants/urls';
import { FETCH_SLIDE_ERROR_MESSAGE } from 'constants/errorMessages';
import { useLazyGetSlideQuery } from 'store/apis/slidesApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { FullSlideInfoModal } from 'components/PathologyMap/Modals/FullSlideInfoModal/FullSlideInfoModal';
import { useState } from 'react';

export const PathologyMapSlideCardItem = ({
  slide,
  resultsCount,
  index,
  selectedFilters,
  isAppLibrary,
}) => {
  const [viewSlideModalOpen, setViewSlideModalOpen] = useState(false);
  const { classes } = usePathologyMapSlideCardItemStyles();
  const [getSlide, { data: fullSlide }] = useLazyGetSlideQuery();
  const { showError } = useSnackbar();

  const handleCloseModal = () => {
    setViewSlideModalOpen(false);
  };

  const handleOpenModal = () => {
    if (!slide.uuid) {
      return;
    }

    if (isAppLibrary) {
      const url = SLIDE_VIEWER_URL.replace(':slideUUID', slide.uuid);
      window.open(url, '_blank');

      return;
    }

    getSlide({ slideUUID: slide.uuid })
      .unwrap()
      .then(() => {
        setViewSlideModalOpen(true);
      })
      .catch(() => showError(FETCH_SLIDE_ERROR_MESSAGE));
  };

  return (
    <>
      <Fade in appear timeout={750}>
        <Box className={classes.slideCardsContainer}>
          <PathologyMapSlideCardHeader
            slide={slide}
            resultsCount={resultsCount}
            index={index}
            handleOpenModal={handleOpenModal}
          />
          <PathologyMapSlideCardContent
            slide={slide}
            selectedFilters={selectedFilters}
            handleOpenModal={handleOpenModal}
          />
        </Box>
      </Fade>
      {fullSlide && (
        <FullSlideInfoModal
          slide={slide}
          fullSlide={fullSlide}
          isOpen={viewSlideModalOpen}
          onClose={handleCloseModal}
          selectedFilters={selectedFilters}
        />
      )}
    </>
  );
};
