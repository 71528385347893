export const unsubmittedConsultationStates = ['new', ''];
export const upcomingConsultationStates = [];
export const finishedConsultationStates = [
  'fulfilled',
  'histowiz_review_finished_results',
  'paid',
  'finalized',
  'finished',
  'client_review',
  'completed',
  'fulfilled',
];

export const showActionsValidStates = [
  'submitted_from_app_questionnaire_complete',
  'histowiz_accepted',
  'pathologist_emailed',
  'pathologist_accepted',
];

export const BACKEND_STATE_SUBMITTED_FROM_APP_QUESTIONNAIRE_COMPLETE =
  'submitted_from_app_questionnaire_complete';

export const BACKEND_STATE_NEW_QUESTIONNAIRE = 'new';
