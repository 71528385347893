import { Typography, Box } from '@mui/material';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import React from 'react';
import { useDataUsageCardStyles } from 'components/TeamOverview/components/DataUsageCard/styles';
import { transformDataUsage } from 'components/UserProfile/utilities';
import { MB } from 'components/UserProfile/constants';
import {
  parseLegendIntoDatasets,
  prepareDataUsage,
} from 'components/TeamOverview/utilities';
import { CircularChartOverview } from 'components/TeamOverview/components/CircularChartOverview/CircularChartOverview';
import { Spinner } from 'components/Loading/Spinner/Spinner';

export const DataUsageCard = ({ team, isLoading }) => {
  const { classes } = useDataUsageCardStyles();

  const isDataUsageAvailable = team?.total_storage_used > 0;
  const totalDataUsage = isDataUsageAvailable
    ? transformDataUsage(team?.total_storage_used, MB)
    : 0;
  const dataUsageLegendData = prepareDataUsage(team);
  const dataset = parseLegendIntoDatasets(dataUsageLegendData);

  return (
    <Card
      header={<Typography className={classes.cardTitle}>Data Usage</Typography>}
    >
      {isLoading ? (
        <Box className={classes.noDataContainer}>
          <Spinner size={40} />
        </Box>
      ) : isDataUsageAvailable ? (
        <CircularChartOverview
          dataset={dataset}
          totalDataLabel={'Data Utilized'}
          totalData={totalDataUsage}
          legendData={dataUsageLegendData}
        />
      ) : (
        <Box className={classes.noDataContainer}>
          <Typography className={classes.greyText}>NO DATA USED</Typography>
        </Box>
      )}
    </Card>
  );
};
