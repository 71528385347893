import React, { useState } from 'react';
import { useEditUserDetails } from 'components/EditUserDetails/hooks/useEditUserDetails';
import { useEditUserStyles } from 'components/EditUserDetails/styles';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { EditUserTeamAddresses } from 'components/EditUserDetails/EditUserTeamAddresses';
import { TabPanel } from 'components/Shared/TabPanel';
import { UserEditFormField } from 'components/EditUserDetails/UserEditFormField';

export const EditUserPage = () => {
  const { classes } = useEditUserStyles();
  const {
    register,
    handleSubmit,
    handleCreateNewPassword,
    passwordRegister,
    onPasswordSubmit,
    passwordErrors,
    newPassword,
    passwordMatch,
    isDisabled,
    isDetailsUpdating,
    isPasswordUpdating,
    onSubmit,
    isLoaded,
  } = useEditUserDetails();
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabsChange = (e, value) => {
    setCurrentTab(value);
  };

  if (!isLoaded) {
    return <MiniDrawerWithContext header="Change User Details" />;
  }

  return (
    <MiniDrawerWithContext header="Change User Details">
      <Grid container spacing={8} ml="1rem" mt="1rem" className={classes.root}>
        <Grid item xs={12} md={9} lg={7} className={classes.wrapper}>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
            onChange={handleTabsChange}
          >
            <Tab label="Profile" />
            <Tab label="Team Addresses" />
          </Tabs>

          <Box className={classes.content}>
            <TabPanel value={currentTab} index={0}>
              <Typography className={classes.title}>Account Details</Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className={classes.form}>
                  <UserEditFormField fieldLabel="First Name">
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register('first_name', { required: true })}
                      size="small"
                    />
                  </UserEditFormField>
                  <UserEditFormField fieldLabel="Last Name">
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register('last_name', { required: true })}
                      size="small"
                    />
                  </UserEditFormField>
                  <UserEditFormField fieldLabel="Primary Phone #">
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register('phone_number', { required: false })}
                      size="small"
                    />
                  </UserEditFormField>
                  <UserEditFormField fieldLabel="Secondary Phone #">
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register('secondary_phone_number', {
                        required: false,
                      })}
                      size="small"
                    />
                  </UserEditFormField>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={false}
                      type="submit"
                      className={classes.button}
                      disabled={isDisabled}
                    >
                      {isDetailsUpdating ? (
                        <CircularProgress className={classes.loader} />
                      ) : (
                        'Update'
                      )}
                    </Button>
                  </Box>
                </Box>
              </form>
              <Typography className={classes.title} mt="1rem">
                Change Password
              </Typography>
              <form onSubmit={onPasswordSubmit(handleCreateNewPassword)}>
                <Box className={classes.form}>
                  <UserEditFormField fieldLabel="New Password">
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...passwordRegister('newPassword', {
                        required: false,
                        minLength: 6,
                      })}
                      type="password"
                      size="small"
                      defaultValue=""
                      helperText={
                        passwordErrors.newPassword &&
                        'Must Be At Least 6 Characters'
                      }
                      error={Boolean(
                        passwordErrors.newPassword &&
                          'Must Be At Least 6 Characters',
                      )}
                    />
                  </UserEditFormField>
                  <UserEditFormField fieldLabel="Confirm New Password">
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...passwordRegister('passwordMatch', {
                        required: false,
                        validate: (value) =>
                          value === newPassword || 'The Passwords Do Not Match',
                      })}
                      type="password"
                      size="small"
                      defaultValue=""
                      helperText={
                        passwordErrors.passwordMatch &&
                        passwordErrors.passwordMatch.message
                      }
                      error={Boolean(
                        passwordErrors.passwordMatch &&
                          passwordErrors.passwordMatch.message,
                      )}
                    />
                  </UserEditFormField>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.button}
                      fullWidth={false}
                      disabled={
                        isPasswordUpdating || !newPassword || !passwordMatch
                      }
                    >
                      {isPasswordUpdating ? (
                        <CircularProgress className={classes.loader} />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </Box>
                </Box>
              </form>
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <EditUserTeamAddresses />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </MiniDrawerWithContext>
  );
};
