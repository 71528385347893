import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { FavoriteSlideIconButton } from 'components/utilities/FavoriteSlide/FavoriteSlideIconButton';
import { DEFAULT_FAVORITE_CELL_ICON_BUTTON_PROPS } from 'components/utilities/FavoriteSlide/constants';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { histowizTheme } from 'themes/defaultMuiTheme';
import {
  ORDERS_SLIDE_LIST_URL,
  ORDER_DETAILS_URL,
  SLIDE_VIEWER_URL,
} from 'constants/urls';
import { slideNameSerializedRemoveOrderRemoveSVS } from './utilities';
import { DARK_GREY } from 'utilities/colors';
import { useOrderSlideBreadcrumbsHeaderStyles } from './styles';

export const OrderSlideBreadcrumbsHeader = ({ slide, showFavoriteIcon }) => {
  const { classes } = useOrderSlideBreadcrumbsHeaderStyles();

  const iconButtonProps = {
    className: classes.starIcon,
    ...DEFAULT_FAVORITE_CELL_ICON_BUTTON_PROPS,
  };

  const BreadcrumbsData = [
    {
      name: `${slide.order.name} Details`,
      color: histowizTheme.palette.primary.light,
      link: ORDER_DETAILS_URL.replace(':orderUUID', slide.order.uuid),
    },
    {
      name: 'Slides',
      color: histowizTheme.palette.secondary.light,
      link: ORDERS_SLIDE_LIST_URL.replace(':orderUUID', slide.order.uuid),
    },
    {
      name: slideNameSerializedRemoveOrderRemoveSVS({
        slide,
      }),
      color: histowizTheme.palette.primary.light,
      link: SLIDE_VIEWER_URL.replace(':slideUUID', slide.uuid),
    },
  ];

  return (
    <Box className={classes.container}>
      <Grid container className={classes.root}>
        <Grid item className={classes.container}>
          <Breadcrumbs
            data={BreadcrumbsData}
            divider=">"
            dividerColor={DARK_GREY}
          />
        </Grid>
        {showFavoriteIcon && (
          <Grid item>
            <Box ml={1}>
              <FavoriteSlideIconButton
                slideUUID={slide.uuid}
                className={classes.iconButton}
                filledIconProps={iconButtonProps}
                outlineIconProps={iconButtonProps}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
