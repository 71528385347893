import { useBlanketPurchaseOrderCardStyles } from 'components/OrderForm/BillingShipping/BillingTabs/BlanketPurchaseOrders/styles';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Box, Typography, Checkbox } from '@mui/material';
import { RemainingAmountIndicator } from 'components/OrderForm/BillingShipping/BillingTabs/BlanketPurchaseOrders/RemainingAmountIndicator';

export const BlanketPurchaseOrderCard = ({
  order,
  isSelected = false,
  handleSelect,
}) => {
  const { classes } = useBlanketPurchaseOrderCardStyles();

  const usedMoneyPercent =
    (parseFloat(order.used_amount) / parseFloat(order.amount)) * 100;

  return (
    <Box
      className={`${classes.card} ${isSelected && classes.selectedCard}`}
      onClick={() => !isSelected && handleSelect(order)}
    >
      <Box className={classes.checkboxContainer}>
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
          color="primary"
          checked={isSelected}
        />
      </Box>
      <Box className={classes.remainingAmountSection}>
        <Typography>REMAINING AMOUNT</Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography className={classes.remainingAmountMoneyCount}>
            ${order.used_amount}
          </Typography>
          <RemainingAmountIndicator fillPercent={usedMoneyPercent} />
        </Box>
      </Box>
      <Box className={classes.generalInfoSection}>
        <Typography className={classes.purchaseOrderName}>
          {order.number}
        </Typography>
        <Typography className={classes.label}>
          TOTAL AMOUNT: ${order.amount}
        </Typography>
      </Box>
    </Box>
  );
};
