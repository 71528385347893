import React, { Fragment, useEffect, useState } from 'react';
import { getTeamAddresses } from 'services/resources/commonResources';
import { postShipBackOrder } from 'services/resources/orders';
import Box from '@mui/material/Box';
import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Select from 'react-select';
import {
  CustomTableCell,
  DEFAULT_HISTOWIZ_FEDEX_LABEL,
  DROPBOX_REQUESTED_VALUE,
  SHIPPING_REQUESTED_VALUE,
  VALID_SHIPPABLE_RESHIPPABLE_STATES,
} from 'components/OrdersList/constants';
import { RequestShipBackMaterialsSnackbar } from 'components/Modals/RequestShipBackMaterialsSnackbar';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { DefaultDialog } from 'components/Modals/DefaultDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Grid from '@mui/material/Grid';
import { TeamAddressForm } from 'components/Shared/TeamAddress/TeamAddressFormV1';
import {
  COMMON_ERROR_MESSAGE,
  FETCH_TEAM_ADDRESSES_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { ShippingAddressDisplay } from 'components/OrderForm/BillingShipping/BillingShippingComponents';
import { SHIP_BACK_SUCCESS_MESSAGE } from 'constants/successMessages';
import { ShipBackButton } from 'components/OrdersList/Actions/ShipBackButton';
import { useStyles } from 'components/OrdersList/Actions/orderActionsStyles';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const ShipBackOrdersCustomTableActions = ({ order }) => {
  const { classes } = useStyles();
  const unmountIgnore = useUnmountIgnore();
  const { showError, showSuccess } = useSnackbar();

  const [isSample, setIsSample] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // we are support two different types of actions -- either a shipping back Blocks (aka samples)
  // or the user is clicking shipping back slides (unstained or stained)
  const [shipBackSamples, setShipBackSamples] = useState(false);
  const [shipBackStainedSlides, setShipBackStainedSlides] = useState(false);
  const [shipBackUnstainedSlides, setShipBackUnstainedSlides] = useState(false);

  const [shippingAddressOptions, setShippingAddressOptions] = useState([]);
  const [shippingAddress, setShippingAddress] = useState(null);

  const [shippingInstructions, setShippingInstructions] = useState('');

  const [clientFedexAccount, setClientFedexAccount] = useState('');
  const [useHistoWizFedExAccount, setUseHistoWizFedExAccount] = useState(false);

  const [shipWithDropbox, setShipWithDropbox] = useState(false);
  const {
    requested_stained_slides: isOrderWithStainedSlides,
    requested_unstained_slides: isOrderWithUnstainedSlides,
    contains_samples_after_2023: isOrderWithSamplesAfter2023,
  } = order;

  // if the ship back states from the samples/stained_slides/unstained_slides don't indicate
  // it's possible, then we will disable that option
  const [disableRequestSampleShipping, setDisableRequestSampleShipping] =
    useState(false);

  const [
    disableRequestStainedSlideShipping,
    setDisableRequestStainedSlideShipping,
  ] = useState(false);

  const [
    disableRequestedUnstainedSlideShipping,
    setDisableRequestedUnstainedSlideShipping,
  ] = useState(false);

  const [addressExpanded, setAddressExpanded] = useState(false);

  useEffect(() => {
    if (!modalOpen) {
      return;
    }

    getTeamAddresses({ cached: true })
      .then((addresses) => {
        if (!unmountIgnore.current) {
          setShippingAddressOptions(addresses);
        }
      })
      .catch(() => showError(FETCH_TEAM_ADDRESSES_ERROR_MESSAGE));
  }, [modalOpen]);

  useEffect(() => {
    // if the order has previously requested ship back samples, but is no longer possible, disable
    const unableToShipBackSamples =
      !VALID_SHIPPABLE_RESHIPPABLE_STATES.includes(
        order.ship_back_samples_state,
      );
    if (order.ship_back_samples && unableToShipBackSamples) {
      setDisableRequestSampleShipping(true);
    }

    // if the order has previously requested stained slides, but is no longer possible, disable
    const unableToShipBackStainedSlides =
      !VALID_SHIPPABLE_RESHIPPABLE_STATES.includes(
        order.ship_back_stained_slides_state,
      );
    if (order.ship_back_stained_slides && unableToShipBackStainedSlides) {
      setDisableRequestStainedSlideShipping(true);
    }

    // if the order has previously requested unstained slides, but is no longer possible, disable
    const unableToShipBackUnstainedSlides =
      !VALID_SHIPPABLE_RESHIPPABLE_STATES.includes(
        order.ship_back_unstained_slides_state,
      );
    if (order.ship_back_unstained_slides && unableToShipBackUnstainedSlides) {
      setDisableRequestedUnstainedSlideShipping(true);
    }
  }, [order, unmountIgnore]);

  const openCloseShipModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleAddressExpanded = () =>
    setAddressExpanded((prevState) => !prevState);

  const openCloseShipSlidesModal = ({ isStained }) => {
    setIsSample(false);
    setShipBackSamples(false);

    setShipBackStainedSlides(isStained);
    setShipBackUnstainedSlides(!isStained);

    setModalOpen(!modalOpen);
  };

  const openCloseShipSamplesModal = () => {
    setIsSample(true);
    setShipBackSamples(true);
    setShipBackStainedSlides(false);
    setShipBackUnstainedSlides(false);
    setModalOpen(!modalOpen);
  };

  const onSubmit = () => {
    setModalOpen(false);

    const shipBackMaterialsState = shipWithDropbox
      ? DROPBOX_REQUESTED_VALUE
      : SHIPPING_REQUESTED_VALUE;

    const postParams = {
      client_fedex_account: clientFedexAccount,
      shipping_instructions: shippingInstructions,
    };

    if (shippingAddress) {
      postParams['address_to_uuid'] = shippingAddress.uuid;
    }

    if (
      shipBackSamples &&
      VALID_SHIPPABLE_RESHIPPABLE_STATES.includes(order.ship_back_samples_state)
    ) {
      postParams['ship_back_samples'] = shipBackSamples;
      postParams['ship_back_samples_state'] = shipBackMaterialsState;
      setDisableRequestSampleShipping(true);
    }

    if (
      shipBackStainedSlides &&
      VALID_SHIPPABLE_RESHIPPABLE_STATES.includes(
        order.ship_back_stained_slides_state,
      )
    ) {
      postParams['ship_back_stained_slides'] = shipBackStainedSlides;
      postParams['ship_back_stained_slides_state'] = shipBackMaterialsState;
      setDisableRequestStainedSlideShipping(true);
    }

    if (
      shipBackUnstainedSlides &&
      VALID_SHIPPABLE_RESHIPPABLE_STATES.includes(
        order.ship_back_unstained_slides_state,
      )
    ) {
      postParams['ship_back_unstained_slides'] = shipBackUnstainedSlides;
      postParams['ship_back_unstained_slides_state'] = shipBackMaterialsState;
      setDisableRequestedUnstainedSlideShipping(true);
    }

    postShipBackOrder({ orderUUID: order.uuid, postParams })
      .then(() => showSuccess(SHIP_BACK_SUCCESS_MESSAGE))
      .catch(() => showError(COMMON_ERROR_MESSAGE));
  };

  const handleShippingAddressChange = (value) => {
    setShippingAddress(value);
  };

  const onClickUseDropbox = () => {
    setShipWithDropbox(!shipWithDropbox);
    // if using dropbox, reset the shipping address
    setShippingAddress(null);
    setClientFedexAccount('Please Use Dropbox');
  };

  const renderShipBackSamplesOrSlides = () => {
    if (isSample) {
      return (
        <Box mt={1}>
          <Checkbox checked={shipBackSamples} />
            Blocks
        </Box>
      );
    }
    
    return (
      <>
        {shipBackStainedSlides ? (
          <Box>
            <Checkbox checked={shipBackStainedSlides} /> Stained Slides
          </Box>
        ) : (
          <Box>
            <Checkbox checked={shipBackUnstainedSlides} /> Unstained Slides
          </Box>
        )}
      </>
    );
  };

  const renderDropboxSelection = () => {
    if (!order.dropbox) {
      return null;
    }

    return (
      <Box onClick={onClickUseDropbox}>
        <Checkbox
          checked={shipWithDropbox}
          onChange={onClickUseDropbox}
          value="use_dropbox"
          className={classes.useHistoWizFedExCheckbox}
        />
        Please Drop Off Materials at Dropbox ({order.dropbox.name}) Location
        Instead
      </Box>
    );
  };

  const onClickUseHistoWizFedex = () => {
    // by default, if it's not enabled, then we are enabling it again,
    // so update the text
    if (!useHistoWizFedExAccount) {
      setClientFedexAccount(DEFAULT_HISTOWIZ_FEDEX_LABEL);
    } else {
      setClientFedexAccount('');
    }
    setUseHistoWizFedExAccount(!useHistoWizFedExAccount);
  };

  const renderFedExSelection = () => {
    if (shipWithDropbox) {
      return null;
    }

    return (
      <Box mt={0} mb={2}>
        <Typography variant="h5" id="simple-modal-description">
          FedEx Number (Required)
        </Typography>

        <TextField
          fullWidth
          label="Please Include Your Client FedEx Number ... "
          name="FedExNumber"
          value={clientFedexAccount}
          disabled={useHistoWizFedExAccount}
          onChange={(e) => setClientFedexAccount(e.target.value)}
        />
        <Box onClick={onClickUseHistoWizFedex}>
          <Checkbox
            checked={useHistoWizFedExAccount}
            onChange={onClickUseHistoWizFedex}
            value="use_histowiz_fedex"
            className={classes.useHistoWizFedExCheckbox}
            disabled={shipWithDropbox}
          />
          Use HistoWiz FedEx Number
        </Box>
      </Box>
    );
  };

  const handleAddressCreated = (address) => {
    setShippingAddressOptions((prevState) => [...prevState, address]);
    setShippingAddress(address);
    setAddressExpanded(false);
  };

  const dialogActions = () => {
    const validReturnAddress = shippingAddress || shipWithDropbox;
    const validSlidesCondition =
      shipBackUnstainedSlides || shipBackStainedSlides;
    const shipButtonDisabled =
      !validReturnAddress ||
      !clientFedexAccount ||
      (!isSample && !validSlidesCondition);

    return (
      <span className={classes.deleteButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={shipButtonDisabled}
        >
          Ship
        </Button>
      </span>
    );
  };

  const renderShipBackOrderModal = () => 
    // both shipping address and client fedex number need to be filled in
    (
      <DefaultDialog
        open={modalOpen}
        onClose={openCloseShipModal}
        actions={dialogActions}
        title="Requesting Project Materials To Ship"
        scroll="body"
      >
        {renderFedExSelection()}
        <Box mb={1}>
          <Typography variant="h5" id="simple-modal-description">
            Please Send Materials To
          </Typography>
          {shipWithDropbox ? null : (
            <>
              <div className={classes.selectForm}>
                <Select
                  options={shippingAddressOptions}
                  isMulti={false}
                  onChange={handleShippingAddressChange}
                  value={shippingAddress}
                  clearable={false}
                  className={classes.selectForm}
                  getOptionLabel={(data) =>
                    `${data['name']} - ${data['street_1']}`
                  }
                  getOptionValue={(data) => data['uuid']}
                />
                <ShippingAddressDisplay address={shippingAddress} />
              </div>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography
                    variant="body2"
                    onClick={toggleAddressExpanded}
                    color="secondary"
                  >
                    Add new address
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={toggleAddressExpanded} size="small">
                    {addressExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Grid>
              </Grid>
              {addressExpanded && (
                <TeamAddressForm
                  onAfterSuccessSubmit={handleAddressCreated}
                  teamUUID={order.team.uuid}
                  submitButtonText="Add Address"
                />
              )}
            </>
          )}
          {renderDropboxSelection()}
        </Box>

        <Typography variant="h5" id="simple-modal-description">
          Materials To Ship Back
        </Typography>
        {renderShipBackSamplesOrSlides()}
        <Box mt={2}>
          <Typography variant="body1" id="simple-modal-description">
            Shipping Instructions (Optional)
          </Typography>
          <TextField
            fullWidth
            label="Shipping Instructions ... "
            name="shippingInstructions"
            onChange={(e) => setShippingInstructions(e.target.value)}
          />
        </Box>
      </DefaultDialog>
    )
  ;

  return (
    <CustomTableCell>
      {isOrderWithSamplesAfter2023 && (
        <ShipBackButton
          text="Ship Back Blocks"
          isDisabled={disableRequestSampleShipping}
          onClick={openCloseShipSamplesModal}
        />
      )}
      {isOrderWithStainedSlides && (
        <ShipBackButton
          text="Ship Back Stained Slides"
          isDisabled={disableRequestStainedSlideShipping}
          onClick={() => openCloseShipSlidesModal({ isStained: true })}
        />
      )}
      {isOrderWithUnstainedSlides && (
        <ShipBackButton
          text="Ship Back Unstained Slides"
          isDisabled={disableRequestedUnstainedSlideShipping}
          onClick={() => openCloseShipSlidesModal({ isStained: false })}
        />
      )}
      {renderShipBackOrderModal()}
      <RequestShipBackMaterialsSnackbar />
    </CustomTableCell>
  );
};
