import ClickAwayListener from '@mui/material/ClickAwayListener';
import React, { useState } from 'react';
import {
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { GROUP_TO_LINK_DATA_MAP } from 'components/GlobalSearch/constants';
import 'css/globalSearch.css';
import { SearchResults } from 'components/GlobalSearch/List';
import IconButton from '@mui/material/IconButton';
import { SearchOutlined } from '@mui/icons-material';
import { useGlobalSearchStyles } from 'components/GlobalSearch/styles';
import { useGlobalSearch } from 'components/GlobalSearch/hooks/useGlobalSearch';

export const GlobalSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const { isLoading, data: searchData } = useGlobalSearch(searchValue);
  const { classes } = useGlobalSearchStyles();

  const [searchExpanded, setSearchExpanded] = useState(false);

  const history = useHistory();

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchValue(value.trim());
  };

  const handleGoToItem = (e) => {
    const { target } = e;
    const { id } = target;
    const groupName = e.target.getAttribute('data-group');

    const modelLinkData = GROUP_TO_LINK_DATA_MAP[groupName];

    if (modelLinkData) {
      const { link, uuidName } = modelLinkData;
      const url = link.replace(uuidName, id);
      history.replace(url);
    }
  };

  return (
    <Accordion
      disableGutters
      expanded={!!(searchExpanded && searchValue) || isLoading}
      className={classes.accordion}
    >
      <AccordionSummary className={classes.summary}>
        <ClickAwayListener onClickAway={() => setSearchExpanded(false)}>
          <TextField
            fullWidth
            size="small"
            onClick={() => setSearchExpanded(true)}
            variant="outlined"
            placeholder="Type here to search..."
            onChange={handleInputChange}
            InputProps={{
              className: classes.searchInput,
              endAdornment: (
                <IconButton className={classes.searchIcon}>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />
        </ClickAwayListener>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress size={25} />
          </div>
        ) : (
          <SearchResults data={searchData} handleGoToItem={handleGoToItem} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
