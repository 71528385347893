import Grid from '@mui/material/Grid';
import { PathologyMapSlideCardItem } from 'components/PathologyMap/PathologyMapSlideCards/Views/CardView/PathologyMapSlideCardItem';
import { Fragment } from 'react';

export const PathologyMapSlideCardView = ({ hits, nbHits, items }) => {
  return (
    <Fragment>
      {hits.map((hit, index) => {
        return (
          <Grid item xs={12} md={6} laptop={4} display="flex" key={hit.uuid}>
            <PathologyMapSlideCardItem
              index={index}
              resultsCount={nbHits}
              slide={hit}
              selectedFilters={items}
            />
          </Grid>
        );
      })}
    </Fragment>
  );
};
