import { AIAiosynModel } from 'components/AITools/models/AIAiosynModel';
import { AIAiraModel } from 'components/AITools/models/AIAiraModel';
import { AIQualityControlModel } from 'components/AITools/models/AIQualityControlModel';
import { AISegmentationModel } from 'components/AITools/models/AISegmentationModel';
import { AITissueModel } from 'components/AITools/models/AITissueModel';
import { AI_MODEL_CHANGE_ACTIONS } from 'components/AITools/constants/common';
import {
  getShortModelName,
  isAiosynModel,
  isAiraModel,
  isQCModel,
  isTissueModel,
} from 'components/AITools/utilities/common';
import { store } from 'store/store';
import {
  removeCurrentSlideAIModel,
  setMLDataLoadingMap,
} from 'store/slices/aiToolsSlice';

const getModelClass = (modelName) => {
  switch (true) {
    case isQCModel(modelName):
      return AIQualityControlModel;

    case isAiraModel(modelName):
      return AIAiraModel;

    case isAiosynModel(modelName):
      return AIAiosynModel;

    case isTissueModel(modelName):
      return AISegmentationModel;

    default:
      return AITissueModel;
  }
};

export class AIDrawingManager {
  constructor(
    viewer,
    slide,
    modelsOpacitiesRef,
    mlDataGetter,
    handleGetDataFail,
    initialModels = [],
  ) {
    this.viewer = viewer;
    this.slide = slide;
    this.modelsOpacitiesRef = modelsOpacitiesRef;

    this.currentModels = [];

    this.mlDataGetter = mlDataGetter;
    this.handleGetDataFail = handleGetDataFail;

    this.createInitialModels(initialModels);
  }

  async updateSlideMLData(modelName) {
    const model = getShortModelName(modelName);

    if (this.slide[model]) {
      return;
    }

    store.dispatch(setMLDataLoadingMap({ modelName, isLoading: true }));

    try {
      const getterResponse = await this.mlDataGetter({
        slideUUID: this.slide.uuid,
        model,
      }).unwrap();

      this.updateSlide({ ...this.slide, ...getterResponse });
    } catch (e) {
      store.dispatch(removeCurrentSlideAIModel(modelName));
      this.handleGetDataFail(e);
    } finally {
      store.dispatch(setMLDataLoadingMap({ modelName, isLoading: false }));
    }
  }

  async modelUpdateHandler(model, modelName) {
    const isTissue = !isQCModel(modelName);

    if (isTissue) {
      await this.updateSlideMLData(modelName);
      model.draw();
    } else {
      model.draw();
    }
  }

  createInitialModels(initialModels) {
    initialModels.forEach(async (modelName) => {
      const model = this.getOrCreateModel(modelName);
      await this.modelUpdateHandler(model, modelName);
    });
  }

  updateSlide(slide) {
    this.slide = slide;

    this.currentModels.forEach((model) => {
      model.slide = slide;
    });
  }

  // cleaning it up since each model could be up to 1mb
  removeModelDataFromSlide(model) {
    const slide = { ...this.slide };
    delete slide[model];

    this.updateSlide(slide);
  }

  getModelByName(modelName) {
    const model = this.currentModels.find(
      (currentModel) => currentModel.modelName === modelName,
    );

    return model;
  }

  createModel(modelName) {
    const ModelClass = getModelClass(modelName);

    const model = new ModelClass(
      modelName,
      this.viewer,
      this.slide,
      this.modelsOpacitiesRef,
    );
    this.currentModels.push(model);

    return model;
  }

  getOrCreateModel(modelName) {
    let model = this.getModelByName(modelName);

    if (!model) {
      model = this.createModel(modelName);
    }

    return model;
  }

  async handleModelAdd(model) {
    const { modelName } = model;

    await this.modelUpdateHandler(model, modelName);
  }

  handleModelRemove(model) {
    const modelName = getShortModelName(model.modelName);

    this.removeModelDataFromSlide(modelName);

    model.hide();
  }

  handleModelChange(changeType, modelName) {
    const model = this.getOrCreateModel(modelName);

    if (changeType === AI_MODEL_CHANGE_ACTIONS.ADD) {
      this.handleModelAdd(model);
    } else if (changeType === AI_MODEL_CHANGE_ACTIONS.REMOVE) {
      this.handleModelRemove(model);
    }
  }

  destroy() {
    this.currentModels.forEach((model) => {
      model.destroy();
    });
  }
}
