import { useDispatch } from 'react-redux';
import { ErrorOutline } from '@mui/icons-material';
import React, { memo, useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, MenuItem, Skeleton } from '@mui/material';
import { CardHeader } from 'components/UserProfile/components/CardHeader';
import { buildTeamInfo } from 'components/UserProfile/utilities';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import Box from '@mui/material/Box';
import { setForceOpenTeamOverviewModal } from 'store/slices/userDetailsSlice';

export const TeamSelectionCard = memo(
  ({ selectedTeam, setSelectedTeam, teams }) => {
    const teamInfo = useMemo(() => buildTeamInfo(selectedTeam), [selectedTeam]);
    const dispatch = useDispatch();

    const teamSelector = selectedTeam ? (
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedTeam?.uuid}
          label="Age"
          onChange={(e) =>
            setSelectedTeam(teams.find((team) => team.uuid === e.target.value))
          }
        >
          {teams?.map((team) => (
            <MenuItem key={team.uuid} value={team.uuid}>
              {team.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <Skeleton variant="rectangular" width={150} height="2rem" />
    );

    const subtitle = selectedTeam ? (
      teamInfo
    ) : (
      <Skeleton variant="text" width={150} height="0.9375rem" />
    );

    const rightSection = (
      <ErrorOutline
        cursor="pointer"
        color="error"
        fontSize="large"
        onClick={() => dispatch(setForceOpenTeamOverviewModal(true))}
      />
    );

    return (
      <Grid item xs={12}>
        <Card>
          <Box paddingX="1.25rem" paddingY="0.75rem">
            <CardHeader
              title={teamSelector}
              subtitle={subtitle}
              RightSection={rightSection}
            />
          </Box>
        </Card>
      </Grid>
    );
  },
);
