import { OrderFormWizardNavigator } from 'components/OrderForm/WizardNavigator/WizardNavigatorView';
import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router';
import { getOrderByUUID, updateOrder } from 'services/resources/orders';
import { AgGridReact } from 'ag-grid-react';
import { agGridDefaultColDef } from 'components/utilities/grid';
import {
  serializeRequestedAntibodiesOnSample,
  serializeRequestedServicesOnSample,
  serializeRequestedSpecialStainsOnSample,
  serializeSamplesScienceInfoToBackendPostRequest,
  serializeScienceInfoOnSample,
} from 'components/OrderForm/SamplesFormV2/serializers';
import { PLACE_ORDER_SLIDES_STEP_URL } from 'constants/urls';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import {
  LAST_ORDER_FORM_PAGE,
  SUBMITTING_ERROR_MESSAGE,
} from 'components/OrderForm/constants';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import {
  FETCH_ORDER_ERROR_MESSAGE,
  GET_SAMPLES_ERROR,
  UPDATE_ORDER_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { PleaseCallHistoWiz } from 'components/Shared/PleaseCallHistoWiz';
import { SamplesCustomTooltip } from 'components/OrderForm/SamplesFormV2/components/SamplesCustomTooltip';
import { SamplesSubmitSaveErrorsModal } from 'components/OrderForm/SamplesFormV2/components/SamplesSubmitSaveErrorsModal';
import { getScienceInfoSamplesColumns } from 'components/OrderForm/SamplesFormV2/utilities';
import { RenderBackendValidationErrors } from 'components/OrderForm/SamplesFormV2/components/ValidationErrors';
import { SampleScienceInfoSaveButtons } from 'components/OrderFormV2/SampleScienceInfoV2/SampleScienceInfoSaveButtons';
import { gridThemeStyle } from 'components/OrderForm/SamplesFormV2/styles/js/common';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useScienceInfoStyles } from './styles';
import {
  useLazyGetSamplesByOrderIdQuery,
  usePostSamplesScienceInfoMutation,
} from 'store/apis/samplesApi';
import { cloneSamples } from 'components/OrderForm/SamplesFormV1/utilities';

const OrderRequiresScienceInfoHeader = ({ order }) => {
  const { classes } = useScienceInfoStyles();

  if (!order) {
    return null;
  }

  const { pathology_map_consent } = order;

  let requiredLabel;
  if (pathology_map_consent) {
    requiredLabel = <span className={classes.requiredLabel}>REQUIRED</span>;
  } else {
    requiredLabel = (
      <span className={classes.notRequiredLabel}>not required</span>
    );
  }

  return (
    <Fragment>
      <Box mb={1} mt={-1}>
        <Typography variant={'h3'} align={'center'}>
          Sample Science Information
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant={'body1'} align={'center'}>
          For {order.name}, the sample science form is {requiredLabel} to
          proceed.
        </Typography>
      </Box>
    </Fragment>
  );
};

export const SampleScienceInfoV2 = () => {
  const match = useRouteMatch();
  const { showError } = useSnackbar();
  const { classes } = useScienceInfoStyles();
  const history = useHistory();
  const unmountIgnore = useUnmountIgnore();

  const matchOrderUUID = match.params.orderUUID;

  const [orderUUID, setOrderUUID] = useState(matchOrderUUID);
  const [order, setOrder] = useState(null);
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([]);
  const [gridAPI, setGridAPI] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [backendIssueErrors, setBackendIssueErrors] = useState('');
  const [errorsListModalOpen, setErrorsListModalOpen] = useState(false);

  const [getSamplesByOrderId] = useLazyGetSamplesByOrderIdQuery();
  const [postSamplesScienceInfo] = usePostSamplesScienceInfoMutation();

  useEffect(() => {
    setOrderUUID(matchOrderUUID);
  }, [matchOrderUUID]);

  useEffect(() => {
    getOrderByUUID(orderUUID)
      .then((order) => {
        !unmountIgnore.current && setOrder(order);
      })
      .catch(() => showError(FETCH_ORDER_ERROR_MESSAGE));
  }, [orderUUID, unmountIgnore]);

  useEffect(() => {
    if (!order) {
      return;
    }

    const scienceInfoGridColumns = getScienceInfoSamplesColumns({ order });
    setColumns(scienceInfoGridColumns);
  }, [order]);

  useEffect(() => {
    getSamplesByOrderId({ orderUUID })
      .unwrap()
      .then((response) => {
        if (unmountIgnore.current) return;

        const samplesData = cloneSamples(response);

        samplesData.forEach((sample) => {
          serializeRequestedServicesOnSample(sample);
          serializeRequestedAntibodiesOnSample(sample);
          serializeRequestedSpecialStainsOnSample(sample);
          serializeScienceInfoOnSample(sample);
        });

        setRows(samplesData);
      })
      .catch(() => showError(GET_SAMPLES_ERROR));
  }, [orderUUID, unmountIgnore]);

  const handleSetErrorsListModalOpen = () => {
    setErrorsListModalOpen(!errorsListModalOpen);
  };

  const renderErrorModal = () => {
    // im not entirely sure what errors we should render for sample science
    // info, but might as well keep this here ...
    if (!gridAPI) {
      return;
    }

    return (
      <SamplesSubmitSaveErrorsModal
        open={errorsListModalOpen}
        onClose={handleSetErrorsListModalOpen}
      >
        <RenderBackendValidationErrors
          backendIssueErrors={backendIssueErrors}
        />
      </SamplesSubmitSaveErrorsModal>
    );
  };

  const onGridReady = (params) => {
    setGridAPI(params.api);
    setGridColumnApi(params.columnApi);

    // tool panels default to open, kind of annoying
    params.api.closeToolPanel();
  };

  const getGridData = () => {
    const allData = [];
    gridAPI.forEachNode((node) => {
      allData.push(node.data);
    });

    return allData;
  };

  const handleSaveAndContinue = () => {
    setIsSubmitting(true);

    const allData = getGridData();
    const postParams = allData.map((result) => {
      return serializeSamplesScienceInfoToBackendPostRequest(result);
    });

    postSamplesScienceInfo({ orderUUID, postParams })
      .then(() => {
        const url = PLACE_ORDER_SLIDES_STEP_URL.replace(
          ':orderUUID',
          orderUUID,
        );
        history.push(url);
      })
      .catch((error) => {
        const errorMessage = error.response
          ? JSON.stringify(error.response.data)
          : SUBMITTING_ERROR_MESSAGE;

        showError(errorMessage);

        setBackendIssueErrors(errorMessage);
        setErrorsListModalOpen(true);
        setIsSubmitting(false);
      });

    updateOrder(orderUUID, {
      last_order_form_page: LAST_ORDER_FORM_PAGE.SCIENCE_INFO,
    }).catch(() => showError(UPDATE_ORDER_ERROR_MESSAGE));
  };

  const renderSaveAndContinueButton = () => {
    return (
      <Button
        variant="contained"
        color={'primary'}
        onClick={handleSaveAndContinue}
        disabled={isSubmitting}
      >
        <SubmittingIcon submitting={isSubmitting} />
        Save & Continue
      </Button>
    );
  };

  const renderGrid = () => {
    // if we haven't loaded the data yet, don't preload
    if (columns.length === 0) {
      return null;
    }

    return (
      <div className="ag-theme-balham" style={gridThemeStyle}>
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columns}
          rowData={rows}
          enableColResize={true}
          rowSelection={'multiple'}
          enableFillHandle={true}
          fillHandleDirection={'y'}
          rowDragManaged={true}
          enableRangeSelection={true}
          editable={true}
          suppressClearOnFillReduction={true}
          defaultColDef={agGridDefaultColDef}
          tooltipShowDelay={500}
          components={{ customTooltip: SamplesCustomTooltip }}
          sideBar={true}
          // if we don't suppressRowClick, copy and paste keeps on trying to copy
          // the entire row and it really sucks for the entire experience
          suppressRowClickSelection={true}
        />
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <div className={classes.navigator}>
        <OrderFormWizardNavigator />
      </div>
      <Box mt={0} px={2}>
        <Box mb={2}>
          <OrderRequiresScienceInfoHeader order={order} />
        </Box>
        {renderGrid()}
        {renderErrorModal()}
        <Box mt={2} mb={2}>
          <SampleScienceInfoSaveButtons
            renderSaveAndContinueButton={renderSaveAndContinueButton}
          />
        </Box>
        <Box mt={1}>
          <PleaseCallHistoWiz />
        </Box>
      </Box>
    </div>
  );
};
