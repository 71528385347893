import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import {
  barcodesDefaultColumnSettings,
  orderBarcodeColumns,
} from 'components/OrderBarcodes/columns';
import { useHistory, useParams } from 'react-router-dom';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { pullOrderBarcodeLabels } from 'services/resources/commonResources';
import { getOrderIDtoUUIDMapping } from 'services/resources/orders';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import {
  downloadBarcodes,
  parseBarcodes,
} from 'components/OrderBarcodes/utils';
import {
  BARCODES_FETCH_ERROR_MESSAGE,
  FILTERED_COLUMN_NAME,
  ORDER_NOT_FOUND_MESSAGE,
} from 'components/OrderBarcodes/constants';
import { useBarcodePageStyles } from 'components/OrderBarcodes/styles';

export const OrderBarcodesView = () => {
  const [rowData, setRowData] = useState([]);
  const [stainFilters, setStainFilters] = useState([]);
  const [selectedStains, setSelectedStains] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const { orderUUID } = useParams();
  const { showError } = useSnackbar();
  const history = useHistory();
  const gridRef = React.useRef(null);

  const { classes } = useBarcodePageStyles();

  const setOrderUUIDToURL = (orderNumber) => {
    setIsLoading(true);
    getOrderIDtoUUIDMapping({ orderID: orderNumber })
      .then((uuid) => history.push(`/orders/${uuid}/barcodes`))
      .catch(() => showError(ORDER_NOT_FOUND_MESSAGE))
      .finally(() => setIsLoading(false));
  };

  const getBarcodes = (orderUUID) => {
    setIsLoading(true);
    pullOrderBarcodeLabels(orderUUID)
      .then((data = []) => {
        const parsedData = parseBarcodes(data);
        setRowData(parsedData);

        // Extract unique stain names for the filter
        const uniqueStains = [
          ...new Set(parsedData.map((row) => row.stainName)),
        ];
        setStainFilters(uniqueStains);
        setSelectedStains(uniqueStains);
      })
      .catch(() => {
        showError(BARCODES_FETCH_ERROR_MESSAGE);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getBarcodes(orderUUID);
  }, [orderUUID]);

  useEffect(() => {
    if (!gridRef?.current?.api) {
      return;
    }

    if (isLoading) {
      gridRef.current.api.showLoadingOverlay();
    } else {
      gridRef.current.api.hideOverlay();
    }
  }, [isLoading]);

  // Handle stain filter selection
  const handleStainFilterChange = (stain) => {
    setSelectedStains((prevSelected) =>
      prevSelected.includes(stain)
        ? prevSelected.filter((s) => s !== stain)
        : [...prevSelected, stain],
    );
  };

  // Handle print button click
  const handlePrintClick = () => {
    const selectedRowsData = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
      if (node.selected) {
        selectedRowsData.push(node.data);
      }
    });
    downloadBarcodes(selectedRowsData, selectedStains);
  };

  // Function to get the number of selected rows
  const getSelectedRowCount = () => {
    if (!gridRef?.current?.api) {
      return 0;
    }
    const selectedRows = gridRef.current.api.getSelectedRows();
    const filteredSelectedRows = selectedRows.filter((row) =>
      selectedStains.includes(row.stainName),
    );

    if (filteredSelectedRows.length !== selectedRowCount) {
      setSelectedRowCount(filteredSelectedRows.length);
    }
    return filteredSelectedRows.length;
  };

  const onSelectionChanged = () => {
    getSelectedRowCount();
  };

  useEffect(() => {
    if (!gridRef?.current?.api) {
      return;
    }

    const filterInstance =
      gridRef.current.api.getFilterInstance(FILTERED_COLUMN_NAME);
    filterInstance.setModel({ values: selectedStains });
    gridRef.current.api.onFilterChanged();
  }, [selectedStains]);

  return (
    <Box p={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom align="center">
            Samples
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Grid container alignItems="center" mt={1} spacing={2}>
              <Grid item>
                <TextField
                  label="Order number"
                  variant="outlined"
                  size="small"
                  value={orderId}
                  type="number"
                  className={classes.orderInput}
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOrderUUIDToURL(orderId)}
                  disabled={!orderId || isLoading}
                >
                  Get barcodes
                </Button>
              </Grid>
            </Grid>
            <Box mt={3} className={classes.filters}>
              <Typography variant="h6" gutterBottom>
                Stains to Show (Filter):
              </Typography>
              <FormGroup row>
                {stainFilters.map((stain) => (
                  <FormControlLabel
                    key={stain}
                    control={
                      <Checkbox
                        checked={selectedStains.includes(stain)}
                        onChange={() => handleStainFilterChange(stain)}
                      />
                    }
                    label={stain}
                  />
                ))}
              </FormGroup>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Selected Rows: {getSelectedRowCount()}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Use "Shift" / "Control" to select multiple rows.
              </Typography>
              <Button
                variant="contained"
                onClick={handlePrintClick}
                disabled={!selectedRowCount || isLoading}
              >
                PRINT
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <div className={`ag-theme-alpine ${classes.grid}`}>
            <AgGridReact
              enableSorting
              enableFilter
              enableColResize
              rowDragManaged
              rowGroupPanelShow="always"
              ref={gridRef}
              rowData={rowData}
              columnDefs={orderBarcodeColumns}
              defaultColDef={barcodesDefaultColumnSettings}
              rowSelection="multiple"
              onSelectionChanged={onSelectionChanged}
              groupSelectsChildren
              autoGroupColumnDef={{
                headerName: 'Group',
                minWidth: 200,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                  checkbox: true,
                },
              }}
              suppressRowClickSelection
              checkboxSelection
              headerCheckboxSelection
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
