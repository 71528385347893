import { FormProvider, useForm } from 'react-hook-form';
import { DefaultTeamAddressField } from 'components/Shared/TeamAddress/DefaultTeamAddressField';
import React, { useCallback, useEffect } from 'react';
import {
  addressesDefFieldsOptions,
  addressesValidationSchema,
} from 'components/Shared/TeamAddress/constants';
import { Button, Grid, Typography, Box } from '@mui/material';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import LocationOn from '@mui/icons-material/LocationOn';
import { useCurrentLocation } from 'components/Shared/hooks';
import { useShippingStepStyles } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/styles';
import { parseActualAddress } from 'utilities/general';
import { yupResolver } from '@hookform/resolvers/yup';
import { BottomButtonsSection } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/BottomButtonsSection';
import _ from 'lodash';

export const CreateAddressForm = ({
  handlePrevStep,
  handleCreateNewAddress,
}) => {
  const { classes } = useShippingStepStyles();
  const methods = useForm({
    resolver: yupResolver(addressesValidationSchema),
    mode: 'onBlur',
  });

  const { handleSubmit, setValue } = methods;

  const { detectCurrentLocation, currentLocation, setCurrentLocation } =
    useCurrentLocation();

  //This useEffect hook fill address form with fetched from google api actual address values
  useEffect(() => {
    if (!_.isEmpty(currentLocation)) {
      currentLocation &&
        Object.keys(currentLocation).forEach((key) => {
          setValue(key, currentLocation[key], { shouldValidate: true });
        });
    }
  }, [currentLocation]);

  const submitSelectedAddress = useCallback(
    (address) => {
      const actualUserAddress = parseActualAddress(address);
      setCurrentLocation({ ...actualUserAddress });
    },
    [setCurrentLocation],
  );

  const renderAddressField = (addressFieldOption) => {
    // allows to provide your own component to render address field
    // you can define it in addressesDefFieldsOptions arrays
    const AddressFieldComponent =
      addressFieldOption.component ?? DefaultTeamAddressField;

    return (
      <Grid item xs={addressFieldOption.columns || 12}>
        <AddressFieldComponent
          {...addressFieldOption}
          submitSelectedAddress={submitSelectedAddress}
          key={addressFieldOption.name}
        />
      </Grid>
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleCreateNewAddress)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.headingText}>
            ENTER NEW ADDRESS
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            fullWidth={false}
            onClick={detectCurrentLocation}
          >
            <SubmittingIcon submitting={false} />
            <LocationOn />
            Detect Current Location
          </Button>
        </Box>
        <Grid columnSpacing={2} container xs={12}>
          {addressesDefFieldsOptions.map(renderAddressField)}
        </Grid>
        <BottomButtonsSection
          cancelButtonClick={handlePrevStep}
          confirmButtonClick={handleSubmit}
          confirmButtonText={'Submit'}
        ></BottomButtonsSection>
      </form>
    </FormProvider>
  );
};
