import {
  SHARE_SLIDE_URL,
  SHARED_ORDER_SLIDE_LIST_URL,
  SHARED_IF_VIEWER_URL,
  COPY_PREIVEW_IF_SLIDE_URL,
  COPY_PREVIEW_SLIDE_URL,
  SHARED_GROUP_SLIDE_LIST_URL,
} from 'constants/urls';
import {
  AI_MODEL_NAME_FOR_SHARE,
  GROUP_NAME_FOR_SHARE,
  IF_SLIDE_NAME_FOR_SHARE,
  SLIDE_NAME_FOR_SHARE,
  SLIDE_SOCIAL_NAME_FOR_SHARE_IF_VIEWER,
  SLIDE_SOCIAL_NAME_FOR_SHARE_VIEWER,
} from 'components/Share/helpers/constants';

export const getItemSharedUrl = (itemName, shareId) => {
  const currentURL = window.location.origin;

  switch (itemName) {
    case SLIDE_NAME_FOR_SHARE:
      return `${currentURL}${SHARE_SLIDE_URL.replace(
        ':shareSlideUUID',
        shareId,
      )}`;
    case GROUP_NAME_FOR_SHARE:
      return `${currentURL}${SHARED_GROUP_SLIDE_LIST_URL.replace(
        ':shareUUID',
        shareId,
      )}`;
    case IF_SLIDE_NAME_FOR_SHARE:
      return `${currentURL}${SHARED_IF_VIEWER_URL.replace(
        ':shareUUID',
        shareId,
      )}`;
    case SLIDE_SOCIAL_NAME_FOR_SHARE_VIEWER:
      return `${currentURL}${COPY_PREVIEW_SLIDE_URL.replace(
        ':shareUUID',
        shareId,
      )}`;
    case SLIDE_SOCIAL_NAME_FOR_SHARE_IF_VIEWER:
      return `${currentURL}${COPY_PREIVEW_IF_SLIDE_URL.replace(
        ':shareUUID',
        shareId,
      )}`;
    case AI_MODEL_NAME_FOR_SHARE:
      return window.location.href;
    default:
      return `${currentURL}${SHARED_ORDER_SLIDE_LIST_URL.replace(
        ':shareUUID',
        shareId,
      )}`;
  }
};
