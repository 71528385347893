import { makeStyles } from 'tss-react/mui';

export const useBarcodePageStyles = makeStyles()(() => ({
  filters: { height: '32rem' },
  grid: { height: '50rem', width: '100%', padding: '1rem' },
  orderInput: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}));
