import { AI_MODELS } from 'components/AITools/constants/common';

const AI_PERMISSIONS_OBJECT = Object.values(AI_MODELS).reduce((acc, model) => {
  acc[model] = `${model}-view`;

  return acc;
}, []);

export const PERMISSIONS = {
  ...AI_PERMISSIONS_OBJECT,
};

export const AI_PERMISSIONS = [
  ...Object.values(AI_MODELS).map((model) => PERMISSIONS[model]),
];
