import { FormControl, Select, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { TeamUsersDropdownSelectorItem } from 'components/OrderForm/NotifyTeamMembersSelector/TeamUsersDropdownSelectorItem';

export const TeamUsersDropdownSelector = ({
  users = [],
  selectedUsers = [],
  notifiedUsers = [],
  handleSelect,
}) => {
  if (!users) {
    return null;
  }

  const notifiedUsersUUIDs = new Set(notifiedUsers.map((user) => user.uuid));

  return (
    <FormControl size="small">
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        variant={'outlined'}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={() => (
          <Typography color="gray">Select team members</Typography>
        )}
        sx={{
          width: '20rem',
          '& .MuiSelect-select': {
            display: 'flex',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
            fontWeight: 500,
          },
          '& .MuiSelect-select .MuiSvgIcon-root': {
            visibility: 'hidden',
          },
          '& fieldset': {
            border: '1px solid black',
          },
        }}
      >
        {users.map((user) => (
          <TeamUsersDropdownSelectorItem
            key={user.uuid}
            user={user}
            isNotified={notifiedUsersUUIDs.has(user.uuid)}
            isSelected={selectedUsers.includes(user)}
            handleSelect={handleSelect}
          />
        ))}
      </Select>
    </FormControl>
  );
};
