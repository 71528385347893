import React from 'react';
import { BetaIcon } from 'components/Shared/Icons/BetaIcon';
import PageView from '@mui/icons-material/Pageview';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

export const UserSlidesSearchIcon = () => {
  return (
    <>
      <PageView />
    </>
  );
};

export const SlideGroupsIcon = () => {
  return (
    <BetaIcon>
      <WorkspacesIcon />
    </BetaIcon>
  );
};
