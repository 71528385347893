import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import {
  getIAChartLink,
  getIAReportLink,
  getPathologyLink,
} from 'components/UserProfile/utilities';
import { useHistory } from 'react-router';
import { formatDateForChangeLog } from 'utilities/dates_and_times';
import { BYTES_IN_KB } from 'components/UserProfile/constants';
import { DATA_MANAGEMENT_BLUE } from 'utilities/colors';
import { ORDER_DETAILS_URL } from 'constants/urls';
import { ApprovalDelegationIcon } from 'images/icons/ApprovalDelegationIcon';
import { HardDriveIcon } from 'images/icons/HardDriveIcon';
import { DeleteIcon } from 'images/icons/DeleteIcon';
import {
  GREEN_ICON_COLOR,
  PURPLE_ICON_COLOR,
  RED_ICON_COLOR,
} from 'components/TeamOverview/CardsMenuSection/CardsMenuSection';

export const ChangeLogRow = ({
  event,
  classes,
  onIFSlidesClick,
  onDefaultSlidesClick,
}) => {
  const { date, action, orderedBy, created, order } = event;

  const {
    slides_data_used: used,
    project: { name: projectName },
    slides_count: slidesCount,
    if_slides_count: ifSlidesCount,
    state_label: status,
    name,
  } = order;

  const history = useHistory();

  const createdBy = `${formatDateForChangeLog(created)} by ${
    order.submitted_user?.first_name || ''
  } ${order.submitted_user?.last_name || ''} `;

  const usedGB = used ? `${(used / BYTES_IN_KB).toFixed(1)}GB` : '';
  const projectOrder = `${projectName} / ${name}`;

  const orderURL = ORDER_DETAILS_URL.replace(':orderUUID', order.uuid);

  const actionTypeButtons = {
    contribute: (
      <Button
        variant="outlined"
        className={[classes.actionButton, classes.contributeLabel].join(' ')}
        startIcon={<ApprovalDelegationIcon color={PURPLE_ICON_COLOR} />}
      >
        Contribute
      </Button>
    ),
    delete: (
      <Button
        variant="outlined"
        startIcon={<DeleteIcon color={RED_ICON_COLOR} />}
        className={[classes.actionButton, classes.deleteLabel].join(' ')}
      >
        Archived
      </Button>
    ),
    export: (
      <Button
        variant="outlined"
        startIcon={<HardDriveIcon color={GREEN_ICON_COLOR} />}
        className={[classes.actionButton, classes.exportLabel].join(' ')}
      >
        Data on hard disk
      </Button>
    ),
  };

  return (
    <Grid key={created} container className={classes.orderRow}>
      <Grid item xs={0.75} className={classes.orderCell}>
        <Typography fontSize="0.875rem">
          {formatDateForChangeLog(date)}
        </Typography>
      </Grid>
      <Grid item xs={1.25} className={classes.orderCell}>
        <Typography fontSize="0.875rem">{actionTypeButtons[action]}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.orderCell}>
        <Typography fontSize="0.875rem">{orderedBy}</Typography>
      </Grid>
      <Grid item xs={0.75} className={classes.orderCell}>
        <Typography fontSize="0.875rem">{usedGB}</Typography>
      </Grid>
      <Grid item xs={1.75} className={classes.orderCell}>
        <Tooltip title={projectOrder}>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="0.875rem"
            color={DATA_MANAGEMENT_BLUE}
          >
            <Link to={orderURL}>{projectOrder}</Link>
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={0.75} className={classes.orderCell}>
        <Tooltip title={status}>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="0.875rem"
          >
            {status}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={1.5} className={classes.orderCell}>
        <Tooltip title={createdBy}>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="0.875rem"
          >
            {createdBy}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={0.75}
        className={slidesCount ? classes.cellWithLink : ''}
        onClick={() => (slidesCount ? onDefaultSlidesClick() : null)}
      >
        <Typography textAlign="center" fontSize="0.875rem">
          {slidesCount}
        </Typography>
      </Grid>
      <Grid
        item
        xs={0.75}
        className={ifSlidesCount ? classes.cellWithLink : ''}
        onClick={() => (ifSlidesCount ? onIFSlidesClick() : null)}
      >
        <Typography textAlign="center" fontSize="0.875rem">
          {ifSlidesCount}
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.orderCell}>
        <Typography textAlign="center">{getPathologyLink(order)}</Typography>
      </Grid>
      <Grid item xs={0.75} className={classes.orderCell}>
        <Typography textAlign="center">{getIAReportLink(order)}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.orderCell}>
        <Typography textAlign="center">
          {getIAChartLink(order, history)}
        </Typography>
      </Grid>
    </Grid>
  );
};
