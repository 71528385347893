import { makeStyles } from 'tss-react/mui';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';

export const useTeamCardStyles = makeStyles()(() => ({
  usersList: {
    padding: '1rem 2rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  infoRow: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '1rem 0 1rem 2rem',
    width: '100%',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  inviteMemberButton: {
    marginLeft: 'auto',
  },
  usersContainer: {
    width: '100%',
    minHeight: '15vh',
    maxHeight: '40vh',
    overflowY: 'scroll',
    ...VERTICAL_SCROLL_STYLE,
  },
  spinnerContainer: {
    height: '30vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  darkGray: {
    opacity: 0.4,
  },
  plusIcon: {
    marginRight: '0.5rem',
  },
}));
