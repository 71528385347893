import chartsDemoGif from 'images/newFeatures/charts_demo.gif';
import { DefaultTutorialModal } from 'components/Modals/TutorialModals/DefaultTutorialModal';
import {
  MODAL_HEADER,
  SHOW_CHARTS_DEMO_MODAL_FIELD,
} from 'components/OrderSlidesChart/helpers/constants';

export const OrderSlidesChartDemoModal = (props) => (
  <DefaultTutorialModal
    {...props}
    headerText={MODAL_HEADER}
    tutorialImage={chartsDemoGif}
    showTutorialName={SHOW_CHARTS_DEMO_MODAL_FIELD}
  />
);
