import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';
import {
  AntibodyDescriptionAttachment,
  AntibodyDescriptionInput,
  AntibodyNameInput,
} from './AddAntibodyFormInputs';
import { postAntibody } from 'services/resources/commonResources';
import { ClonalitySelection } from './AddAntibodySelectDropdowns';
import { object, string } from 'yup';
import { Button, Typography } from '@mui/material';
import { CREATE_ANTIBODY_ERROR_MESSAGE } from 'constants/errorMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

const AddAntibodyFormStyles = () => ({
  container: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  formItem: {
    marginLeft: 'auto',
    marginRight: 'auto',
    flexBasis: '95%',
  },
  formLabel: {
    fontSize: '14px',
    color: 'black',
    display: 'block',
    marginTop: '1rem',
    marginBottom: '5px',
  },
  rightAlignButton: {
    float: 'right',
    marginTop: '1rem',
    marginBottom: '.25rem',
  },
});

const useStyles = makeStyles()(AddAntibodyFormStyles);

const RenderFileName = ({ classes, antibodyAttachment }) => {
  if (!antibodyAttachment) {
    return (
      <Typography>
        <label className={classes.formLabel}>OR ...</label>
      </Typography>
    );
  }

  return (
    <Typography>
      <label className={classes.formLabel}>
        File: {antibodyAttachment.label}
      </label>
    </Typography>
  );
};

const validationSchema = object().shape({
  antibodyName: string().min(2, 'Too Short!').required('Required'),
  antibodyDescription: string()
    .min(2, 'Too Short!')
    .required("Required! If using attachment, write 'attached'."),
  clonality: object().required('Required'),
});

export const AddAntibodyForm = (props) => {
  const {
    teamUUID,
    handleAddAntibodyModal,
    addAntibodyModalName,
    updateOptionsWithAdditionalAntibodyCallback,
    handleSnackbarChange,
  } = props;
  const { classes } = useStyles();
  const { showError } = useSnackbar();

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      antibodyName: addAntibodyModalName,
      antibodyDescription: '',
      clonality: null,
      antibodyAttachment: null,
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { touchedFields, errors, submitCount, isSubmitting },
  } = methods;

  const antibodyAttachment = watch('antibodyAttachment');

  const onSubmit = (values) => {
    let postParams = {
      team_uuid: teamUUID,
      name: values.antibodyName,
      uploaded_info: values.antibodyDescription,
      clonality: values.clonality.value,
    };

    if (values.antibodyAttachment) {
      postParams['uploaded_info_attachment_uuid'] =
        values.antibodyAttachment.value;
    }

    postAntibody(postParams)
      .then((response) => {
        // If we have new info, update the antibodies
        const antibodyUUID = response['uuid'];
        const antibodyName = response['name'];

        updateOptionsWithAdditionalAntibodyCallback(
          antibodyName,
          antibodyUUID,
          response,
        );
        // then close the modal
        handleAddAntibodyModal();
        // and then tell everyone we've updated the antibody
        handleSnackbarChange();
      })
      .catch(() => showError(CREATE_ANTIBODY_ERROR_MESSAGE));
  };

  return (
    <div className={classes.container}>
      <div className={classes.formItem}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AntibodyNameInput
              name="antibodyName"
              register={register}
              errors={errors}
              touched={touchedFields}
              submitCount={submitCount}
            />

            <ClonalitySelection name={'clonality'} />

            <AntibodyDescriptionInput
              name="antibodyDescription"
              register={register}
              errors={errors}
              touched={touchedFields}
              submitCount={submitCount}
            />

            <RenderFileName
              classes={classes}
              antibodyAttachment={antibodyAttachment}
            />

            <AntibodyDescriptionAttachment
              additionalContext={{ team_uuid: teamUUID }}
              setValue={setValue}
            />

            <Typography style={{ marginTop: '1rem' }}>
              <b>NOTE:</b> New antibodies <b>automatically</b> add six positive
              and six negative control slides to the final order. Please contact{' '}
              <a href="mailto:ihc@histowiz.com">ihc@histowiz.com</a> if there
              are any questions.
            </Typography>

            <span className={classes.rightAlignButton}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Upload Antibody
              </Button>
            </span>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
