import {
  FETCH_ORDERS_ERROR_MESSAGE,
  FETCHING_SLIDES_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { useLazyGetSlidesByOrderForManageDataQuery } from 'store/apis/slidesApi';
import { useState } from 'react';
import { useLazyGetIFSlidesByOrderForManageDataQuery } from 'store/apis/ifSlideApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import {
  useArchiveOrdersMutation,
  useContributeOrdersMutation,
  useExportOrdersOnHardDiskMutation,
  useGetExportedOnHardDiskOrdersQuery,
  useGetOrdersForManageDataQuery,
} from 'store/apis/orderApi';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import { filterOrdersByTeam } from 'components/UserProfile/utilities';
import { LOADING_STATE } from 'components/TeamOverview/constants';
import { ORDER_TYPE_FILTERS } from 'components/TeamOverview/utilities';

export const useSlidesForOrder = () => {
  const { showError } = useSnackbar();

  const [selectedRowsSlides, setSelectedRowsSlides] = useState(new Map());

  const [getSlidesByOrderForManageData] =
    useLazyGetSlidesByOrderForManageDataQuery();
  const [getIFSlidesByOrderForManageData] =
    useLazyGetIFSlidesByOrderForManageDataQuery();

  const fetchSlidesForOrder = async (order) => {
    try {
      setSelectedRowsSlides((prevMap) => {
        prevMap.set(order, LOADING_STATE);

        return new Map(prevMap);
      });

      const slides = await getSlidesByOrderForManageData({
        orderUUID: order.uuid,
      }).unwrap();
      const ifSlides = await getIFSlidesByOrderForManageData({
        orderUUID: order.uuid,
      }).unwrap();
      // TODO: ask Jeff and remove if not needed
      // const publicOnlySlides = slides
      //   ? slides.filter((slide) => slide.is_public)
      //   : [];
      // const publicOnlyIfSlides = ifSlides
      //   ? ifSlides.filter((ifSlide) => ifSlide.is_public)
      //   : [];

      setSelectedRowsSlides((prevMap) => {
        prevMap.set(order, {
          slides: slides || [],
          ifSlides: ifSlides || [],
        });

        return new Map(prevMap);
      });
    } catch (e) {
      showError(FETCHING_SLIDES_ERROR_MESSAGE);
    }
  };

  return {
    fetchSlidesForOrder,
    selectedRowsSlides,
  };
};

export const useSteps = (steps, defaultStepIdx = 0) => {
  const [stepIDX, setStepIDX] = useState(null);

  const handleNextStep = (step) => {
    setStepIDX(step || ((prevIDX) => prevIDX + 1));
  };

  const handlePrevStep = () => {
    setStepIDX((prevIDX) => (prevIDX - 1 < 0 ? 0 : prevIDX - 1));
  };

  const resetStep = () => {
    setStepIDX(defaultStepIdx);
  };

  const currentStep = steps[stepIDX];

  return {
    currentStep,
    currentStepIDX: stepIDX,
    handleNextStep,
    handlePrevStep,
    resetStep,
  };
};

export const useOrdersForDataManagement = (
  team,
  selectedOrdersType,
  isDemoAccount,
) => {
  const {
    data: rawOrders,
    isLoading,
    error,
  } = useGetOrdersForManageDataQuery();

  const { data: exportedOrders, error: HDDOrdersError } =
    useGetExportedOnHardDiskOrdersQuery(undefined, { skip: isDemoAccount });
  //Backend returns 404 for users with no permissions
  HDDOrdersError && console.log(HDDOrdersError);

  useQueryError(error, FETCH_ORDERS_ERROR_MESSAGE);

  const mergedWithExported = rawOrders?.map((order) => {
    const exportedOrdersHistory = exportedOrders?.filter((exportedOrder) =>
      exportedOrder.orders.some((o) => o.uuid === order.uuid),
    );

    return {
      ...order,
      exportedOrdersHistory: exportedOrdersHistory || [],
      exported: !!exportedOrdersHistory?.length,
    };
  });

  const ordersFilteredByTeam = filterOrdersByTeam(mergedWithExported, [team]);

  const ordersFilteredByType =
    ORDER_TYPE_FILTERS[selectedOrdersType](ordersFilteredByTeam);

  return {
    isLoading,
    orders: ordersFilteredByType,
    ordersForHistory: ordersFilteredByTeam,
  };
};

export const useOrdersActions = () => {
  const [contributeOrders] = useContributeOrdersMutation();
  const [exportOrdersOnHardDisk] = useExportOrdersOnHardDiskMutation();
  const [archiveOrders] = useArchiveOrdersMutation();

  const archive = async (selectedOrders) => {
    const orderUUIDs = selectedOrders.map(
      (order) => `order_uuids=${order.uuid}`,
    );
    const queryParams = orderUUIDs.join('&');

    return archiveOrders({
      queryParams,
      orders: selectedOrders,
    }).unwrap();
  };

  const contribute = (selectedOrders, team, userName) => {
    const ordersList = selectedOrders.map((order) => ({
      uuid: order.uuid,
      pathology_map_consent: 'True',
    }));

    const postParams = {
      orders: ordersList,
      client_name: userName,
      team_uuid: team.uuid,
    };

    return contributeOrders({
      postParams,
    }).unwrap();
  };

  const exportOrders = (selectedOrders, teamUUID, shipping_address_uuid) => {
    const postParams = {
      order_uuids: selectedOrders.map((order) => order.uuid),
      team_uuid: teamUUID,
      shipping_address_uuid,
    };

    return exportOrdersOnHardDisk({
      postParams,
    }).unwrap();
  };

  return {
    contribute,
    archive,
    exportOrders,
  };
};
