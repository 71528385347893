import { PATHOLOGY_MAP_SEARCH_2, SLIDES_LIST_URL_2 } from 'constants/urls';
import { getClearedSlideName } from 'utilities/slides';

// this has access to everything
// const AlgoliaPrimaryReadAPIKey = "9156a68daa475fce22debdafb5f2383c";

// public/demo key
// const AlgoliaDemoAPIKey =
//  "Y2FmNWQwNGNjMzNhZjVkMTg0ODAxZDA4YTc5Y2RiMDk2MjkwZDEwNDY3MDFmMGEyNDU5YTNjNzhkOWJiNzQyMWZpbHRlcnM9YWNjZXNzX2dyb3VwcyUzQXB1YmxpYw==";

// pathology_map key
// const AlgoliaPathologyMapKey =
//  "MTc3YWQ2YmY0NzA4NGFkNDI4Mzk1ODc1YjExZDIzNTFlNTQ1YjQ4YTQ1ZjVmOTRkODllYzcxMDE2MDkwNWZiZGZpbHRlcnM9YWNjZXNzX2dyb3VwcyUzQXBhdGhvbG9neV9tYXA=";

// staff key (sees everything)
// const AlgoliaStaffKey = "Mzk4OTJlNWJhOTZhMzU3MGJjMDU3N2U5Y2MyNjZhNDYyNjZjMGI0MzBiNzU4MWFlNWI0ZDU2MDAxMDVlZGEzZmZpbHRlcnM9YWNjZXNzX2dyb3VwcyUzQXN0YWZm"
const PROJECT_REFINEMENT = {
  title: 'Projects',
  refinementType: 'project_name',
  isBold: true,
};

export const SPECIES_REFINEMENT_TYPE = 'species_name';
export const ORGAN_NAME_REFINEMENT_TYPE = 'organ_name';
export const STAIN_TYPE_REFINEMENT_TYPE = 'stain_type_name';
export const ANTIBODY_NAME_REFINEMENT_TYPE = 'antibody_name';
export const SAMPLE_NAME_REFINEMENT_TYPE = 'science_info.Sample Name';

export const ALGOLIA_PATHOLOGY_MAP_INDEX = 'production_pathology_map';
export const HISTOWIZ_REFINEMENTS_OPTIONS = [
  PROJECT_REFINEMENT,
  {
    title: 'Orders',
    refinementType: 'order_id',
    isBold: true,
  },
  {
    title: 'Block',
    refinementType: 'block_name',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Stain Type',
    refinementType: STAIN_TYPE_REFINEMENT_TYPE,
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Special Stains',
    refinementType: 'special_stain_name',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Antibodies',
    refinementType: ANTIBODY_NAME_REFINEMENT_TYPE,
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Tumor Type',
    refinementType: 'science_info.tumor_type',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Control Type',
    refinementType: 'control_type_name',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Submission Type',
    refinementType: 'submission_type_name',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Species',
    refinementType: SPECIES_REFINEMENT_TYPE,
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Organ',
    refinementType: ORGAN_NAME_REFINEMENT_TYPE,
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Xenografts',
    refinementType: 'science_info.xenografts',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Gene Expression',
    refinementType: 'science_info.gene_expression',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Biosystems',
    refinementType: 'science_info.biosystems',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Experiments',
    refinementType: 'science_info.experiment_types',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Age',
    refinementType: 'science_info.age',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Gender',
    refinementType: 'science_info.gender',
    isGroupEnd: false,
    isBold: true,
  },
];

export const HISTOWIZ_ADMIN_REFINEMENTS_OPTIONS = [
  PROJECT_REFINEMENT,
  {
    title: 'Orders',
    refinementType: 'order_id',
    isBold: false,
  },
  {
    title: 'Species',
    refinementType: SPECIES_REFINEMENT_TYPE,
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Organ',
    refinementType: ORGAN_NAME_REFINEMENT_TYPE,
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Stain Type',
    refinementType: STAIN_TYPE_REFINEMENT_TYPE,
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Special Stains',
    refinementType: 'special_stain_name',
    isGroupEnd: true,
    isBold: false,
  },
  {
    title: 'Antibodies',
    refinementType: ANTIBODY_NAME_REFINEMENT_TYPE,
    isGroupEnd: false,
    isBold: true,
  },

  {
    title: 'Tumor Type',
    refinementType: 'science_info.tumor_type',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Fixative Name',
    refinementType: 'fixative_name',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Xenografts',
    refinementType: 'science_info.xenografts',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Gene Expression',
    refinementType: 'science_info.gene_expression',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Biosystems',
    refinementType: 'science_info.biosystems',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Experiments',
    refinementType: 'science_info.experiment_types',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Strains',
    refinementType: 'science_info.mouse_strains',
    isGroupEnd: true,
    isBold: false,
  },
  {
    title: 'Antibody Catalog',
    refinementType: 'catalog_number',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'GEMM',
    refinementType: 'science_info.genetically_engineered_mouse_model',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Promoters',
    refinementType: 'science_info.promoters',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Reporters',
    refinementType: 'science_info.reporters',
    isGroupEnd: true,
    isBold: false,
  },
  {
    title: 'Sample ID',
    refinementType: 'sample_id',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Failed QC Reason',
    refinementType: 'failed_qc_reason',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Needs Redo Type',
    refinementType: 'needs_redo_type',
    isGroupEnd: true,
    isBold: false,
  },
  {
    title: 'Age',
    refinementType: 'science_info.age',
    isGroupEnd: false,
    isBold: false,
  },
  {
    title: 'Gender',
    refinementType: 'science_info.gender',
    isGroupEnd: false,
    isBold: false,
  },
];

export const HISTOWIZ_ADMIN_REFINEMENTS_OPTIONS_PRESET_2 = [
  {
    title: 'Sample Name',
    refinementType: SAMPLE_NAME_REFINEMENT_TYPE,
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Sex',
    refinementType: 'science_info.Sex',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Species Strain / Background Name',
    refinementType: 'science_info.Species Strain / Background Name',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Genotype',
    refinementType: 'science_info.Genotype',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Origin of Host',
    refinementType: 'science_info.Origin of Host',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Genetically Engineered Model',
    refinementType: 'science_info.Genetically Engineered Model',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Technique',
    refinementType: 'science_info.Technique',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Implantation Type / Location',
    refinementType: 'science_info.Implantation Type / Location',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Expression',
    refinementType: 'Expression',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Organoids Source',
    refinementType: 'science_info.Organoids Source',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Drug Study Name(s)',
    refinementType: 'science_info.Drug Study Name(s)',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Drug Dosing Schedule',
    refinementType: 'science_info.Drug Dosing Schedule',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Drug Duration',
    refinementType: 'science_info.Drug Duration',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Drug Dosage',
    refinementType: 'science_info.Drug Dosage',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Drug Administration',
    refinementType: 'science_info.Drug Administration',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Outcome by Gross Examination',
    refinementType: 'science_info.Outcome by Gross Examination',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Survival Study',
    refinementType: 'science_info.Survival Study',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Instance ID',
    refinementType: 'science_info.Instance ID',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Accessioning ID',
    refinementType: 'science_info.Accessioning ID',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Experiment ID',
    refinementType: 'science_info.Experiment ID',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Passage ID',
    refinementType: 'science_info.Passage ID',
    isGroupEnd: true,
    isBold: true,
  },
  {
    title: 'Animal ID',
    refinementType: 'science_info.Animal ID',
    isGroupEnd: false,
    isBold: true,
  },
  {
    title: 'Surgery Type',
    refinementType: 'science_info.Surgery Type',
    isGroupEnd: false,
    isBold: true,
  },
];

export const HISTOWIZ_ADMIN_REFINEMENTS_OPTIONS_PRESET_2_WITH_PUBLIC = [
  ...HISTOWIZ_ADMIN_REFINEMENTS_OPTIONS_PRESET_2,
];

const DEFAULT_SEARCH_PLACEHOLDER =
  'Instantly Search Across 2,000,000 Slides (Mouse, Ki67, Trichrome, etc)';

export const DEFAULT_REFINEMENT_TYPE = 'order_id';

const SEARCH_PAGE_PRESETS = [
  HISTOWIZ_REFINEMENTS_OPTIONS,
  HISTOWIZ_ADMIN_REFINEMENTS_OPTIONS_PRESET_2,
];

const PATHOLOGY_MAP_PAGE_PRESETS = [
  HISTOWIZ_ADMIN_REFINEMENTS_OPTIONS,
  HISTOWIZ_ADMIN_REFINEMENTS_OPTIONS_PRESET_2_WITH_PUBLIC,
];

export const PATHOLOGY_MAP_SEARCH_PAGE_URL_MAP = {
  [SLIDES_LIST_URL_2]: {
    searchPlaceholder:
      'Instantly Search Across Your Slides (Mouse, Ki67, Trichrome, etc)',
    presets: SEARCH_PAGE_PRESETS,
    categoriesList: Array.from(new Set(SEARCH_PAGE_PRESETS.flat())),
    showTutorial: true,
  },
  [PATHOLOGY_MAP_SEARCH_2]: {
    searchPlaceholder: DEFAULT_SEARCH_PLACEHOLDER,
    presets: PATHOLOGY_MAP_PAGE_PRESETS,
    categoriesList: Array.from(new Set(PATHOLOGY_MAP_PAGE_PRESETS.flat())),
    showTutorial: false,
  },
};

export const PATHOLOGY_MAP_SLIDE_ATTRIBUTES_OPTIONS = [
  {
    label: 'Name',
    attribute: 'name',
    valueGetter: getClearedSlideName,
  },
  {
    label: 'Antibody',
    attribute: 'antibody_name',
  },
  {
    label: 'Control Type',
    attribute: 'control_type_name',
  },
  {
    label: 'Cut Type',
    attribute: 'cut_type',
  },
  {
    label: 'Fixation Time',
    attribute: 'fixation_time_hours',
  },
  {
    label: 'Fixative',
    attribute: 'fixative_name',
  },
  {
    label: 'Height',
    attribute: 'height',
  },
  {
    label: 'Order',
    attribute: 'order_name',
  },
  {
    label: 'Order Notes',
    attribute: 'order_external_notes',
  },
  {
    label: 'Organ',
    attribute: 'organ_name',
  },
  {
    label: 'Sample',
    attribute: 'sample_name',
  },
  {
    label: 'Block',
    attribute: 'block_name',
  },
  {
    label: 'Special Stain Name',
    attribute: 'special_stain_name',
  },
  {
    label: 'Species',
    attribute: 'species_name',
  },
  {
    label: 'Stain Type',
    attribute: 'stain_type_name',
  },
  {
    label: 'Submission Type',
    attribute: 'submission_type_name',
  },
];

export const MAX_FILTERS_COUNT = 1000;
export const MAX_FILTERS_SEARCH_COUNT = 100;
export const RESULTS_PER_PAGE_COUNT = 15;

export const SLIDE_NAME_CHARS_LIMIT = 25;

export const AVAILABLE_FILTERS_PER_PAGE_COUNT = 15;
export const AVAILABLE_FILTERS_CONTAINER_ID = 'available-filters-container';

export const ALGOLIA_STALLED_SEARCH_DELAY = 500;

export const INFINITY_FILTERS_SCROLL_THRESHOLD = 0.65;

export const SEARCH_FILTERS_PLACEHOLDER = 'Enter Keywords...';
export const MORE_SLIDES_FOUND_SNACKBAR_HIDE_DURATION = 2000;

export const SLIDE_DATA_COPY_TOGGLE_DELAY = 600;
export const SLIDE_DATA_COPY_TEXT = 'Copied!';

export const SLIDES_SEARCH_V2_NAME = 'Search 2.0';
export const PATHOLOGY_MAP_V2_NAME = 'Pathology Map 2.0 [BETA]';

export const USER_SLIDES_SEARCH_MODAL_TUTORIAL_FIELD =
  'showUserSlidesSearchTutorial';
export const SHOW_TEAM_OVERVIEW_MODAL_FIELD = 'forceOpenTeamOverviewModal';

export const SLIDE_METADATA_TAB_VALUE = 'metadata';
export const GROUP_TAB_VALUE = 'group';
export const ACTIONS_TAB_VALUE = 'actions';

export const ALL_ORDERS_TAB_VALUE = 'all';
export const COMPLETED_ORDERS_TAB_VALUE = 'completed';
export const IN_PROGRESS_ORDERS_TAB_VALUE = 'in_progress';
export const UNSUBMITTED_ORDERS_TAB_VALUE = 'unsubmitted';

export const ARCHIVED_ORDERS_TAB_VALUE = 'archived';
export const CONTRIBUTED_ORDERS_TAB_VALUE = 'contributed';
export const EXPORTED_ORDERS_TAB_VALUE = 'exported';

export const ORDER_TABS_IDS = {
  [ALL_ORDERS_TAB_VALUE]: 0,
  [COMPLETED_ORDERS_TAB_VALUE]: 1,
  [IN_PROGRESS_ORDERS_TAB_VALUE]: 2,
  [UNSUBMITTED_ORDERS_TAB_VALUE]: 3,
};

export const DATA_MANAGEMENT_HISTORY_TABS_IDS = {
  [ALL_ORDERS_TAB_VALUE]: 0,
  [ARCHIVED_ORDERS_TAB_VALUE]: 1,
  [CONTRIBUTED_ORDERS_TAB_VALUE]: 2,
  [EXPORTED_ORDERS_TAB_VALUE]: 3,
};
