import {
  AutoAwesomeMotionOutlined,
  MedicationOutlined,
  SegmentOutlined,
} from '@mui/icons-material';
import { viewSelectedSlides } from 'components/OrderDetails/Tabs/utilities';
import {
  ALL_ORDERS_TAB_VALUE,
  ARCHIVED_ORDERS_TAB_VALUE,
  COMPLETED_ORDERS_TAB_VALUE,
  CONTRIBUTED_ORDERS_TAB_VALUE,
  EXPORTED_ORDERS_TAB_VALUE,
  IN_PROGRESS_ORDERS_TAB_VALUE,
  UNSUBMITTED_ORDERS_TAB_VALUE,
} from 'components/PathologyMap/constants/common';
import { PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM } from 'constants/urls';
import { AutoQCIcon } from 'images/icons/AutoQCIcon';

export const SUPPORT_MAIL = 'getsupport@histowiz.com';
export const PROJECT_NAME_SORT_FIELD = 'name';
export const CREATED_AT_SORT_FIELD = 'create';
export const SLIDES_COUNT_SORT_FIELD = 'slides_count';
export const IF_SLIDES_COUNT_SORT_FIELD = 'if_slides_count';

export const BYTES = 'Bytes';
export const KB = 'KB';
export const MB = 'MB';
export const GB = 'GB';
export const TB = 'TB';
export const PB = 'PB';
export const SIZES = [BYTES, KB, MB, GB, TB, PB];

export const BYTES_IN_KB = 1024;

export const SLIDE_COMMENT_TYPE = 'slide_comment';
export const ORDER_COMMENT_TYPE = 'order_comment';

export const ORDERS_CARD_TABS_LIST = [
  {
    value: ALL_ORDERS_TAB_VALUE,
    label: 'all',
  },
  {
    value: COMPLETED_ORDERS_TAB_VALUE,
    label: 'completed',
  },
  {
    value: IN_PROGRESS_ORDERS_TAB_VALUE,
    label: 'in-progress',
  },
  {
    value: UNSUBMITTED_ORDERS_TAB_VALUE,
    label: 'unsubmitted',
  },
];

export const ORDERS_HISTORY_TABS_LIST = [
  {
    value: ALL_ORDERS_TAB_VALUE,
    label: 'all',
  },
  {
    value: ARCHIVED_ORDERS_TAB_VALUE,
    label: 'ARCHIVE',
  },
  {
    value: CONTRIBUTED_ORDERS_TAB_VALUE,
    label: 'CONTRIBUTED',
  },
  {
    value: EXPORTED_ORDERS_TAB_VALUE,
    label: 'HARD DRIVE BACKUP',
  },
];

export const IF = 'IF';
export const IHC = 'IHC';
export const SlideTypes = {
  IF,
  IHC,
};

export const DEFAULT_VIEW = 'default';
export const CHANGELOG_VIEW = 'changeLog';
export const ORDER_PREVIEW_VIEW = 'orderPreview';

export const UPLOAD_FILE_INSTRUCTIONS = [
  {
    capture: 'Image File Viewing Compatibility:',
    text: 'svs, qptiff, ome-tiff, tiff, ndpi, czi',
  },
  {
    capture: 'Metadata Compatibility:',
    text: 'xlsx, csv, json',
  },
];

export const videoFilesFormats = ['mov', 'mp4'];

export const consultationServices = [
  {
    name: 'Pathology CONSULTATION',
    Icon: MedicationOutlined,
    onClick: () => {
      window.open(PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM, '_blank');
    },
    buttonText: 'Request for Pathology Consultation',
  },
  {
    name: 'Image Analysis',
    Icon: AutoAwesomeMotionOutlined,
    onClick: () => {},
    buttonText: 'Request for Image Analysis',
  },
];

export const modelsServices = (uploadedSlides) => [
  {
    name: 'Auto-QC',
    Icon: AutoQCIcon,
    onClick: () => {
      viewSelectedSlides({
        selectedRows: uploadedSlides,
        openModel: 'auto-qc',
      });
    },
    buttonText: 'Run Auto-QC',
  },
  {
    name: 'TISSUE SEGMENTATION',
    Icon: SegmentOutlined,
    onClick: () => {
      viewSelectedSlides({
        selectedRows: uploadedSlides,
        openModel: 'tissue-segmentation',
      });
    },
    buttonText: 'Run Tissue Segmentation',
  },
];
