import React, { useLayoutEffect, useState } from 'react';
import { Matrix4 } from '@math.gl/core';
import quickselect from 'quickselect';

import { getChannelStats } from '@hms-dbmi/viv';

import {
  BRIGHTNESS_CUTOFF_PERCENTILE_8_BIT,
  BRIGHTNESS_CUTOFF_PERCENTILE_16_BIT,
  COLORMAP_SLIDER_CHECKBOX_COLOR,
  COLORS_MAP,
  DEFAULT_CONTRAST,
  DEFAULT_ZOOM_MIN,
  FILL_PIXEL_VALUE,
  GLOBAL_SLIDER_DIMENSION_FIELDS,
  MAX_ALLOWED_CHANNELS,
  MIN_CONTRAST,
  UINT8,
  NEGATIVE_MULTIPLIER,
} from 'components/IFViewer/constants';
import CircularProgress from '@mui/material/CircularProgress';
import {
  MAX_16_BIT_BRIGHTNESS,
  MAX_8_BIT_BRIGHTNESS,
} from 'components/IFViewer/components/Controller/constants';
import { fromUrl } from 'geotiff';

/**
 * Return the midpoint of the global dimensions as a default selection.
 *
 * @param { import('../../src/types').PixelSource<['t', 'z', 'c']> } pixelSource
 */
function getDefaultGlobalSelection({ labels, shape }) {
  const dims = labels
    .map((name, i) => [name, i])
    .filter((d) => GLOBAL_SLIDER_DIMENSION_FIELDS.includes(d[0]));

  /**
   * @type { { t: number, z: number, c: number  } }
   */
  const selection = {};
  dims.forEach(([name, index]) => {
    selection[name] = Math.floor((shape[index] || 0) / 2);
  });

  return selection;
}

/**
 * @param {Array.<number>} shape loader shape
 */
export function isInterleaved(shape) {
  const lastDimSize = shape[shape.length - 1];
  return lastDimSize === 3 || lastDimSize === 4;
}

// Create a default selection using the midpoint of the available global dimensions,
// and then the first four available selections from the first selectable channel.
/**
 *
 * @param { import('../../src/types').PixelSource<['t', 'z', 'c']> } pixelSource
 * @param customChannelOptions
 * @param channelOptions
 * @param slideUUID
 */
export function buildDefaultSelection(
  pixelSource,
  customChannelOptions,
  channelOptions,
  slideUUID,
) {
  let selection = [];
  const globalSelection = getDefaultGlobalSelection(pixelSource);
  // First non-global dimension with some sort of selectable values.

  const firstNonGlobalDimension = pixelSource.labels
    .map((name, i) => ({ name, size: pixelSource.shape[i] }))
    .find((d) => !GLOBAL_SLIDER_DIMENSION_FIELDS.includes(d.name) && d.size);

  const channelsToDisplay = Math.min(
    Object.keys(COLORS_MAP).length,
    firstNonGlobalDimension.size,
    MAX_ALLOWED_CHANNELS,
  );

  for (let i = 0; i < channelsToDisplay; i += 1) {
    selection.push({
      [firstNonGlobalDimension.name]: i,
      ...globalSelection,
      isNegative: checkIsChannelNegative(
        customChannelOptions,
        channelOptions,
        i,
        slideUUID,
      ),
    });
  }

  selection = isInterleaved(pixelSource.shape)
    ? [{ ...selection[0], c: 0 }]
    : selection;
  return selection;
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result.map((d) => parseInt(d, 16)).slice(1);
}

export function range(length) {
  return [...Array(length).keys()];
}

export function useWindowSize(scaleWidth = 1, scaleHeight = 0.87) {
  function getSize() {
    return {
      width: window.innerWidth * scaleWidth,
      height: window.innerHeight * scaleHeight,
    };
  }
  const [windowSize, setWindowSize] = useState(getSize());
  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scaleWidth, scaleHeight]);

  return windowSize;
}

const calculateBrightnessValue = (percentage, maxBrightness, median) => {
  if (percentage === DEFAULT_CONTRAST) {
    return median;
  } else if (percentage > DEFAULT_CONTRAST) {
    return (
      median -
      ((percentage - DEFAULT_CONTRAST) / (100 - DEFAULT_CONTRAST)) *
        (median - MIN_CONTRAST)
    );
  } else {
    return (
      median +
      ((DEFAULT_CONTRAST - percentage) / DEFAULT_CONTRAST) *
        (maxBrightness - median)
    );
  }
};

const getMedianBrightness = (
  data,
  percentage,
  maxBrightness,
  cutOffValue,
  isNegative,
) => {
  const cutoffArr = data.filter((i) => i > 0);

  const topCutoffLocation = Math.floor(cutoffArr.length * (1 - cutOffValue));
  const bottomCutoffLocation = Math.floor(cutoffArr.length * cutOffValue);

  quickselect(cutoffArr, topCutoffLocation);
  quickselect(cutoffArr, bottomCutoffLocation, 0, topCutoffLocation);

  const calculatedBrightness = calculateBrightnessValue(
    percentage,
    maxBrightness,
    cutoffArr[topCutoffLocation],
  );

  const negativeMultiplier = isNegative ? NEGATIVE_MULTIPLIER : 1;

  return [
    cutoffArr[bottomCutoffLocation] || 0,
    calculatedBrightness * negativeMultiplier,
  ];
};

export async function getSingleSelectionStats2D({
  loader,
  selection,
  brightnessCutOff,
  isNegative,
}) {
  const data = Array.isArray(loader) ? loader[loader.length - 1] : loader;
  const raster = await data.getRaster({ selection });
  const selectionStats = getChannelStats(raster.data);
  const is8Bit = loader[0]?.dtype === UINT8;
  const histoData = is8Bit
    ? new Uint8Array(raster.data)
    : new Uint16Array(raster.data);
  const maxBrightness = is8Bit
    ? MAX_8_BIT_BRIGHTNESS / 2
    : MAX_16_BIT_BRIGHTNESS;

  const cutOffValue = is8Bit
    ? BRIGHTNESS_CUTOFF_PERCENTILE_8_BIT
    : BRIGHTNESS_CUTOFF_PERCENTILE_16_BIT;

  const contrastLimits = getMedianBrightness(
    histoData,
    brightnessCutOff,
    maxBrightness,
    cutOffValue,
    isNegative,
  );

  const { domain } = selectionStats;

  return { domain, contrastLimits };
}

export async function getSingleSelectionStats3D({ loader, selection }) {
  const lowResSource = loader[loader.length - 1];
  const { shape, labels } = lowResSource;
  // eslint-disable-next-line no-bitwise
  const sizeZ = shape[labels.indexOf('z')] >> (loader.length - 1);
  const raster0 = await lowResSource.getRaster({
    selection: { ...selection, z: 0 },
  });
  const rasterMid = await lowResSource.getRaster({
    selection: { ...selection, z: Math.floor(sizeZ / 2) },
  });
  const rasterTop = await lowResSource.getRaster({
    selection: { ...selection, z: Math.max(0, sizeZ - 1) },
  });
  const stats0 = getChannelStats(raster0.data);
  const statsMid = getChannelStats(rasterMid.data);
  const statsTop = getChannelStats(rasterTop.data);
  return {
    domain: [
      Math.min(stats0.domain[0], statsMid.domain[0], statsTop.domain[0]),
      Math.max(stats0.domain[1], statsMid.domain[1], statsTop.domain[1]),
    ],
    contrastLimits: [
      Math.min(
        stats0.contrastLimits[0],
        statsMid.contrastLimits[0],
        statsTop.contrastLimits[0],
      ),
      Math.max(
        stats0.contrastLimits[1],
        statsMid.contrastLimits[1],
        statsTop.contrastLimits[1],
      ),
    ],
  };
}

export const getSingleSelectionStats = async ({
  loader,
  selection,
  use3d,
  brightnessCutOff,
  isNegative,
}) => {
  const getStats = use3d
    ? getSingleSelectionStats3D
    : getSingleSelectionStats2D;
  return getStats({
    loader,
    selection,
    brightnessCutOff,
    isNegative,
  });
};

export const getMultiSelectionStats = async ({
  loader,
  selections,
  use3d,
  brightnessCutOff,
}) => {
  const stats = await Promise.all(
    selections.map((selection) =>
      getSingleSelectionStats({
        loader,
        selection,
        use3d,
        brightnessCutOff,
        isNegative: selection.isNegative,
      }),
    ),
  );
  const domains = stats.map((stat) => stat.domain);
  const contrastLimits = stats.map((stat) => stat.contrastLimits);
  return { domains, contrastLimits };
};

/* eslint-disable no-useless-escape */
// https://stackoverflow.com/a/11381730
export function isMobileOrTablet() {
  let check = false;
  // eslint-disable-next-line func-names
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
/* eslint-disable no-useless-escape */

/**
 * @param { import('../../src/loaders/omexml').OMEXML[0] } imgMeta
 */
export function guessRgb({ Pixels }) {
  const numChannels = Pixels.Channels.length;
  const { SamplesPerPixel } = Pixels.Channels[0];

  const is3Channel8Bit = numChannels === 3 && Pixels.Type === 'uint8';
  const interleavedRgb =
    Pixels.SizeC === 3 && numChannels === 1 && Pixels.Interleaved;

  return SamplesPerPixel === 3 || is3Channel8Bit || interleavedRgb;
}
export function truncateDecimalNumber(value, maxLength) {
  if (!value && value !== 0) return '';
  const stringValue = value.toString();
  return stringValue.length > maxLength
    ? stringValue.substring(0, maxLength).replace(/\.$/, '')
    : stringValue;
}

/**
 * Get physical size scaling Matrix4
 * @param {Object} loader PixelSource
 */
export function getPhysicalSizeScalingMatrix(loader) {
  const { x, y, z } = loader?.meta?.physicalSizes ?? {};
  if (x?.size && y?.size && z?.size) {
    const min = Math.min(z.size, x.size, y.size);
    const ratio = [x.size / min, y.size / min, z.size / min];
    return new Matrix4().scale(ratio);
  }
  return new Matrix4().identity();
}

export function getBoundingCube(loader) {
  const source = Array.isArray(loader) ? loader[0] : loader;
  const { shape, labels } = source;
  const physicalSizeScalingMatrix = getPhysicalSizeScalingMatrix(source);
  const xSlice = [0, physicalSizeScalingMatrix[0] * shape[labels.indexOf('x')]];
  const ySlice = [0, physicalSizeScalingMatrix[5] * shape[labels.indexOf('y')]];
  const zSlice = [
    0,
    physicalSizeScalingMatrix[10] * shape[labels.indexOf('z')],
  ];
  return [xSlice, ySlice, zSlice];
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getStatsForResolution = (loader, resolution) => {
  const { shape, labels } = loader[resolution];
  const height = shape[labels.indexOf('y')];
  const width = shape[labels.indexOf('x')];
  const depth = shape[labels.indexOf('z')];
  // eslint-disable-next-line no-bitwise
  const depthDownsampled = Math.max(1, depth >> resolution);
  // Check memory allocation limits for Float32Array (used in XR3DLayer for rendering)
  const totalBytes = 4 * height * width * depthDownsampled;
  return { height, width, depthDownsampled, totalBytes };
};

export const canLoadResolution = (loader, resolution) => {
  const { totalBytes, height, width, depthDownsampled } = getStatsForResolution(
    loader,
    resolution,
  );
  const maxHeapSize =
    window.performance?.memory &&
    window.performance?.memory?.jsHeapSizeLimit / 2;
  const maxSize = maxHeapSize || 2 ** 31 - 1;
  // 2048 is a normal texture size limit although some browsers go larger.
  return (
    totalBytes < maxSize &&
    height < 2048 &&
    depthDownsampled < 2048 &&
    width < 2048 &&
    depthDownsampled > 1
  );
};

export const formatResolutionStatus = (current, total, shape) => {
  return `${current}/${total} [${shape.join(', ')}]`;
};

export const toRgb = (on, arr) => {
  const color = on ? COLORMAP_SLIDER_CHECKBOX_COLOR : arr;
  return `rgb(${color})`;
};

// If the channel is not on, display nothing.
// If the channel has a not-undefined value, show it.
// Otherwise, show a circular progress animation.
export const getPixelValueDisplay = (
  pixelValue,
  isLoading,
  shouldShowPixelValue,
) => {
  if (isLoading) {
    return <CircularProgress size="50%" />;
  }
  if (!shouldShowPixelValue) {
    return FILL_PIXEL_VALUE;
  }
  // Need to check if it's a number becaue 0 is falsy.
  if (pixelValue || typeof pixelValue === 'number') {
    return truncateDecimalNumber(pixelValue, 7);
  }
  return FILL_PIXEL_VALUE;
};

// Map a zoom value from a user-friendly format to viewer compatible and vice versa depending on the reverse flag.
// if reverse === true inputValue is a backOfZoom generated by Library so -6.5 unit ~ 1x zoom, -5.5 unit ~ 2x zoom, -4.5 unit ~ 4x zoom, -3.5 unit ~ 8x zoom etc.
// so in other words 1 unit of zoomBackOff (so a passed-in value of 1) corresponds to a 2x zooming out
// this sequence could be described using formula: b(n) = 2 ^ (n - 1) where n = (a − DEFAULT_ZOOM_MIN) + 1
// if reverse is not provided or equal to false inputValue is user-friendly zoom(40x, 30x, 7x, 2x etc) that we transform into backOfZoom that viv use
// this sequence could be described using formula: a(n) = DEFAULT_ZOOM_MIN + n - 1 where n = log2(b) + 1
// Visit http://viv.gehlenborglab.org/#getdefaultinitialviewstate to get more details
export const mapRange = (inputValue, reverse) => {
  if (reverse) {
    const n = inputValue - DEFAULT_ZOOM_MIN + 1;

    return Math.pow(2, n - 1);
  } else {
    const n = Math.log2(inputValue) + 1;
    const a = DEFAULT_ZOOM_MIN + n - 1;

    return inputValue ? a : DEFAULT_ZOOM_MIN;
  }
};

export const checkIsChannelNegative = (
  channelOptions,
  channelNames,
  index,
  slideUUID,
) => {
  const channel = channelOptions.options[slideUUID]?.[channelNames[index]];
  return channel?.isNegative;
};

export const getIsConvertedFromCZI = async ({ url }) => {
  const tiff = await fromUrl(url);
  const image = await tiff.getImage();
  const meta = image.getFileDirectory();
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(
    meta.ImageDescription,
    'application/xml',
  );
  const valueElement = xmlDoc.querySelector(
    'XMLAnnotation > Value > OriginalMetadata > Value',
  );
  const originalFileName = valueElement?.textContent || null;

  return originalFileName?.toLowerCase()?.includes('.czi');
};
