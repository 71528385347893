import { createSlice } from '@reduxjs/toolkit';
import { FAVORITE_SLIDES_GROUP } from 'utilities/constants';

export const slideGroupsSlice = createSlice({
  name: 'slideGroups',
  initialState: {
    groups: [],
    favoriteSlides: null,
    favoriteGroupUUID: null,
    isLoaded: false,
  },
  reducers: {
    setSlideGroups: (state, { payload }) => {
      const favoriteSlides = payload.find(
        (slideGroup) => slideGroup.name === FAVORITE_SLIDES_GROUP,
      );

      if (favoriteSlides) {
        const favoriteSlidesObj = {};

        favoriteSlides.slides.forEach((slide) => {
          favoriteSlidesObj[slide.uuid] = true;
        });

        state.favoriteGroupUUID = favoriteSlides.uuid;
        state.favoriteSlides = favoriteSlidesObj;
      }

      state.isLoaded = true;
      state.groups = payload;
    },
    createSlideGroup: (state, { payload }) => {
      state.groups.push(payload);
    },
    updateSlideGroup: (state, { payload }) => {
      const index = state.groups.findIndex(
        (slideGroup) => slideGroup.uuid === payload.uuid,
      );

      const favoriteSlides = {};

      if (payload.name === FAVORITE_SLIDES_GROUP) {
        payload.slides.forEach((slide) => {
          favoriteSlides[slide.uuid] = true;
        });

        state.favoriteSlides = favoriteSlides;
      }

      state.groups[index] = payload;
    },
    updateSlideGroupCache: (state, { payload }) => {
      const index = state.findIndex(
        (slideGroup) => slideGroup.uuid === payload.uuid,
      );

      state[index] = payload;
    },
    deleteSlideFromGroup: (state, { payload }) => {
      return state.filter((slideGroup) => slideGroup.uuid !== payload.uuid);
    },
    deleteSlideGroup: (state, { payload }) => {
      state.splice(
        state.findIndex((slideGroup) => slideGroup.uuid === payload.uuid),
        1,
      );
    },
  },
});

export const {
  setSlideGroups,
  createSlideGroup,
  updateSlideGroup,
  updateSlideGroupCache,
  deleteSlideGroup,
} = slideGroupsSlice.actions;

export const slideGroupsReducer = slideGroupsSlice.reducer;

export const slideGroupsSelector = (state) => state.slideGroups.groups;
export const favoriteSlidesSelector = (state) =>
  state.slideGroups.favoriteSlides;
export const isSlideGroupsLoadedSelector = (state) =>
  state.slideGroups.isLoaded;
export const favoriteGroupUUIDSelector = (state) =>
  state.slideGroups.favoriteGroupUUID;
