import { SortLabel } from 'components/SlideGroups/Modals/components/SortLabel';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useSlideGroupsVerticalContainer } from 'components/SlideGroups/utilities/styles';
import {
  CREATED_AT_SORT_FIELD,
  IF_SLIDES_COUNT_SORT_FIELD,
  PROJECT_NAME_SORT_FIELD,
  SLIDES_COUNT_SORT_FIELD,
} from 'components/UserProfile/constants';
import { handleSortClick } from 'components/SlideGroups/utilities/utilities';

export const OrdersSortTeamPage = ({ sort, setSort, isDefaultTab }) => {
  const { classes } = useSlideGroupsVerticalContainer();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={isDefaultTab ? 3 : 4} display="flex" alignItems="center">
        <SortLabel
          isActive={sort.field === PROJECT_NAME_SORT_FIELD}
          label={'PROJECT / ORDER'}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, PROJECT_NAME_SORT_FIELD)}
          classes={classes}
        />
      </Grid>
      <Grid item xs={isDefaultTab ? 1 : 2} className={classes.column}>
        <Typography fontSize="0.75em" lineHeight={2}>
          STATUS
        </Typography>
      </Grid>
      <Grid item xs={isDefaultTab ? 3 : 4} className={classes.column}>
        <SortLabel
          isActive={sort.field === CREATED_AT_SORT_FIELD}
          label={'CREATED DATE'}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, CREATED_AT_SORT_FIELD)}
          classes={classes}
        />
      </Grid>
      <Grid item xs={1} className={classes.column}>
        <SortLabel
          isActive={sort.field === SLIDES_COUNT_SORT_FIELD}
          label={'SLIDES'}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, SLIDES_COUNT_SORT_FIELD)}
          classes={classes}
        />
      </Grid>
      <Grid item xs={1} className={classes.column}>
        <SortLabel
          isActive={sort.field === IF_SLIDES_COUNT_SORT_FIELD}
          label={'IF SLIDES'}
          mode={sort.order}
          onClick={() => handleSortClick(setSort, IF_SLIDES_COUNT_SORT_FIELD)}
          classes={classes}
        />
      </Grid>
      {isDefaultTab && (
        <>
          <Grid item xs={1} className={classes.column}>
            <Typography fontSize="0.75em" lineHeight={2}>
              PATH REPORT
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.column}>
            <Typography fontSize="0.75em" lineHeight={2}>
              IA REPORT
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.column}>
            <Typography fontSize="0.75em" lineHeight={2}>
              SLIDES CHART
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
