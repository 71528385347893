import { ORGAN_NAME_FIELD } from 'components/SampleEditor/helpers/constants';
import {
  STEP_2_CARD,
  TOUR_CARD_READ_EVENT_NAME,
} from 'utilities/hooks/useProductFruitsEvent/constants';

const ensureOrganColumnVisibleEvent = (event, data, restProps) => {
  const { cardName } = data;
  const [gridApi] = restProps;

  if (
    event === TOUR_CARD_READ_EVENT_NAME &&
    cardName === STEP_2_CARD &&
    gridApi
  ) {
    gridApi.ensureColumnVisible(ORGAN_NAME_FIELD, 'end');
  }
};

export const ProductFruitsEvents = {
  ENSURE_ORGAN_COLUMN_EVENT: ensureOrganColumnVisibleEvent,
};
