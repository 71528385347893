import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { TypographyBold } from 'components/common';
import { ValidationErrorTable } from 'components/OrderForm/SamplesFormV2/components/ValidationErrorTable';

export const RenderValidationErrors = ({ issueMessages }) => {
  if (issueMessages.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <TypographyBold>ValidationErrors</TypographyBold>
      <ValidationErrorTable rows={issueMessages} />
    </Fragment>
  );
};

export const RenderDuplicateSameNames = ({ duplicatedSampleNames }) => {
  if (duplicatedSampleNames.length === 0) {
    return null;
  }

  const duplicateSampleNamesString = duplicatedSampleNames.join(', ');

  return (
    <Box my={2}>
      <TypographyBold>Sample Names must be unique.</TypographyBold>
      <Typography variant={'body2'} gutterBottom={true} display={'inline'}>
        Duplicated Sample Names |{' '}
      </Typography>
      <TypographyBold gutterBottom={true} display={'inline'}>
        {duplicateSampleNamesString}
      </TypographyBold>
    </Box>
  );
};

export const RenderBackendValidationErrors = ({ backendIssueErrors }) => {
  if (backendIssueErrors === '') {
    return null;
  }

  return (
    <Box my={2}>
      <TypographyBold gutterBottom={true}>
        Errors With Sample Details, Please Fix.
      </TypographyBold>
      <Typography variant={'body2'} gutterBottom={true} display={'inline'}>
        {backendIssueErrors}
      </Typography>
    </Box>
  );
};

export const RenderNoRowsError = ({ rows }) => {
  if (rows.length > 0) {
    return null;
  }

  return (
    <Box my={3}>
      <TypographyBold gutterBottom={true}>Data Cannot Be Blank</TypographyBold>
    </Box>
  );
};

export const RenderNoSpeciesInOrder = ({ order }) => {
  if (!order) {
    return null;
  }

  if (!order.species) {
    return (
      <Box>
        <Typography variant={'h6'} color={'error'}>
          Note: Species Needs To Be Populated On The First Page of the Order
          Form!
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
};
