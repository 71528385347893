import React, { memo, Fragment } from 'react';
import { RowCell } from 'components/PathologyMap/PathologyMapSlideCards/Views/ListView/RowCell';

export const InfoRow = memo(
  ({ classes, values, highlightedKeysIndexes, isCopyEnabled }) => {
    const checkIfHighlightedCell = (index) =>
      highlightedKeysIndexes.includes(index);

    return (
      <Fragment>
        {values.map((value, index) => (
          <RowCell
            key={value + index} //usage of index as part of the key is acceptable in this case
            value={value}
            classes={classes}
            isSelected={checkIfHighlightedCell(index)}
            isCopyEnabled={isCopyEnabled}
          />
        ))}
      </Fragment>
    );
  },
);
