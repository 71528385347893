import { useMainToolbarStyles } from 'components/ImageViewer/styles';
import { IconButton } from '@mui/material';
import { HelpOutlineOutlined } from '@mui/icons-material';
import React from 'react';

export const HelpIconButton = (props) => {
  const { classes } = useMainToolbarStyles();

  return (
    <IconButton color="primary" className={classes.helpButton} {...props}>
      <HelpOutlineOutlined fontSize={'small'} />
    </IconButton>
  );
};
