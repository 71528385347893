import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinkIcon from '@mui/icons-material/Link';
import { useGenerateUploadLink } from 'components/UserProfile/hooks';
import { InstructionsSection } from 'components/UserProfile/Upload/Steps/components/InstructionsSection';
import { SUPPORT_MAIL } from 'components/UserProfile/constants';

export const UploadStepContent = ({
  isLoading,
  classes,
  handleDone,
  handlePrevStep,
  selectedOrder,
  setSelectedOrder,
  selectedProject,
  setIsLoading,
  selectedTeam,
}) => {
  const { uploadLink, handlePrepareUpload } = useGenerateUploadLink(
    selectedOrder,
    setIsLoading,
  );

  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" p={8}>
        <CircularProgress />
        <Typography
          variant="body1"
          fontWeight={500}
          fontSize="1.5rem"
          letterSpacing="0.1rem"
          mt={3}
        >
          We&apos;re getting your order ready
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={8}>
      {uploadLink ? (
        <>
          <CheckCircleIcon className={classes.successMark} />
          <Typography
            variant="body1"
            fontWeight={500}
            fontSize="1.25rem"
            letterSpacing="0.1rem"
            mt={1}
          >
            All done. Ready to upload your files now.
          </Typography>
          <Typography className={classes.disclaimerText}>
            <i>Disclaimer:</i> This tool is designed for small batch uploads.
            For volumes exceeding 500GB, please contact{' '}
            <a
              href={`mailto:${SUPPORT_MAIL}?subject=Uploading Files Support`}
              className={classes.link}
            >
              customer service
            </a>{' '}
            to discuss alternative upload methods.
          </Typography>
          <InstructionsSection />
          <Box textAlign="center" width="100%" mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDone(uploadLink)}
              className={classes.proceedButton}
            >
              Proceed to Upload
            </Button>
          </Box>
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
          <Button
            className={classes.generateLinkButton}
            variant="contained"
            color="primary"
            onClick={() =>
              handlePrepareUpload(
                selectedOrder,
                selectedProject,
                selectedTeam,
                setSelectedOrder,
              )
            }
            startIcon={<LinkIcon className={classes.linkIcon} />}
          >
            Generate Upload Link
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handlePrevStep}
            className={classes.button}
          >
            Back
          </Button>
        </Box>
      )}
    </Box>
  );
};
