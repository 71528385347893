import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  slides: [],
  slide: null,
};

const ifSlidesSlice = createSlice({
  name: 'ifSlides',
  initialState,
  reducers: {
    setIFSlides: (state, { payload }) => {
      state.slides = payload;
    },
    setIFSlide: (state, { payload }) => {
      state.slide = payload;
    },
  },
});

export const { setIFSlides, setIFSlide } = ifSlidesSlice.actions;

export const ifSlidesReducer = ifSlidesSlice.reducer;

export const ifSlidesSelector = (state) => state.ifSlides.slides;
export const ifSlideSelector = (state) => state.ifSlides.slide;
