import React, { useEffect, useState, memo } from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { useSlideGroupShortInfoStyles } from 'components/SlideGroups/utilities/styles';
import { Counter } from 'components/SlideGroups/components/Counter';
import moment from 'moment/moment';
import { SHORT_MONTH_DAY_YEAR_WITH_COMMA_FORMAT } from 'utilities/dates_and_times';
import CheckIcon from '@mui/icons-material/Check';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { formatSlideGroupName } from 'utilities/groups';

const compareProps = (prev, next) => {
  return (
    prev.isHighlighted === next.isHighlighted &&
    prev.slideGroup === next.slideGroup
  );
};

export const SlideGroupShortInfo = memo(
  ({ isHighlighted, slideGroup, onClick, customClasses }) => {
    const { classes } = useSlideGroupShortInfoStyles({ isHighlighted });
    const [isLoading, setIsLoading] = useState(false);

    const createDateFormatted = moment(slideGroup.created).format(
      SHORT_MONTH_DAY_YEAR_WITH_COMMA_FORMAT,
    );

    useEffect(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, [isHighlighted]);

    const handleContainerClick = () => {
      setIsLoading(true);
      onClick();
    };

    const combinedClasses = { ...classes, ...customClasses };
    const name = formatSlideGroupName(slideGroup.name);

    return (
      <Box
        className={`${combinedClasses.container} ${
          isHighlighted ? combinedClasses.highlightedContainer : ''
        }`}
        onClick={handleContainerClick}
      >
        <Grid container>
          <Grid item xs={6}>
            <TextWithLimitedWidth
              value={name}
              containerClass={combinedClasses.groupNameContainer}
              textClass={combinedClasses.groupNameText}
            />
          </Grid>
          <Grid item xs={2} className={combinedClasses.centeredCell}>
            <Counter count={slideGroup.slides?.length || 0} />
          </Grid>
          <Grid item xs={3} className={combinedClasses.centeredCell}>
            {createDateFormatted}
          </Grid>
          <Grid
            item
            xs={1}
            height={'1.5rem'}
            className={combinedClasses.verticallyCenteredCell}
          >
            {isLoading ? (
              <Spinner size={27} color={'info'} />
            ) : isHighlighted ? (
              <Tooltip
                disableInteractive
                title={'Selected slides exist in this group'}
                placement="bottom"
              >
                <CheckIcon color={'success'} />
              </Tooltip>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    );
  },
  compareProps,
);
