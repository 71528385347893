import { useEffect, useState } from 'react';
import {
  getInvites,
  postInvite as inviteUser,
} from 'services/resources/invite';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const useInvites = () => {
  const [invitedUsers, setInvitedUsers] = useState([]);
  const { showError } = useSnackbar();

  const unmountIgnore = useUnmountIgnore();

  useEffect(() => {
    getInvites().then((response) => {
      if (!unmountIgnore.current) {
        setInvitedUsers(response);
      }
    });
  }, [unmountIgnore]);

  const postInvite = (invite) => {
    return inviteUser(invite)
      .then((response) => {
        setInvitedUsers((invitedUsers) => [response.data, ...invitedUsers]);
      })
      .catch((error) => {
        const { response = {}, message } = error;
        showError(response.data?.email || response.data || message);
      });
  };

  return {
    invitedUsers,
    postInvite,
  };
};
