import React, { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip } from 'chart.js';

import { transformDataUsage } from 'components/UserProfile/utilities';
import { MB } from 'components/UserProfile/constants';

ChartJS.register(Tooltip);

export const CircularChart = ({ dataset }) => {
  const chartRef = useRef(null);

  const data = {
    datasets: [dataset],
  };

  const options = {
    cutout: '85%',
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return ' ' + transformDataUsage(context.raw, MB);
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return <Doughnut options={options} data={data} ref={chartRef} />;
};
