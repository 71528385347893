import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = [];

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams: (state, { payload }) => payload,
  },
});

export const { setTeams } = teamsSlice.actions;

export const teamsReducer = teamsSlice.reducer;

export const teamsSelector = createSelector(
  (state) => state,
  (state) => state.teams,
);
