import {
  IDEXX_SECONDARY_COLOR,
  LOCALHOST_PRIMARY_COLOR,
  LOCALHOST_SECONDARY_COLOR,
} from 'utilities/colors';
import {
  ORDERS_GROUP_NAME,
  PROJECTS_GROUP_NAME,
  SLIDES_GROUP_NAME,
} from 'components/GlobalSearch/constants';
import { ORDERS_LIST_URL, SLIDES_LIST_URL } from 'constants/urls';

const groupBy = (data, key, target) => {
  data.forEach((item) => {
    if (!target[item[key]]) {
      target[item[key]] = [];
    }
    target[item[key]].push(item);
  });
};

export const serializeSearchResponse =
  (groupName) =>
  ({ order, name, uuid }) => ({
    orderName: order?.name,
    orderId: order?.uuid,
    name,
    uuid,
    groupName,
  });

export const serializeOrdersSearchResponse =
  (groupName) =>
  ({ name, uuid, project }) => ({
    name,
    uuid,
    projectName: project?.name,
    projectUUID: project?.uuid,
    groupName,
  });

export const serializeData = (ordersData, slidesData) => {
  const result = [];

  // Group orders by project name
  const ordersByProject = {};
  groupBy(ordersData, 'projectName', ordersByProject);

  // Create Projects -> Orders structure
  Object.keys(ordersByProject).forEach((projectName) => {
    const orders = ordersByProject[projectName];

    result.push({
      name: ORDERS_GROUP_NAME,
      children: [
        {
          color: LOCALHOST_PRIMARY_COLOR,
          name: projectName,
          groupName: PROJECTS_GROUP_NAME,
          uuid: orders[0]?.projectUUID,
          children: orders.map(({ uuid, name, groupName }) => ({
            uuid,
            name,
            groupName,
            color: LOCALHOST_SECONDARY_COLOR,
          })),
        },
      ],
    });
  });

  // Group slides by order name
  const slidesByOrder = {};
  groupBy(slidesData, 'orderName', slidesByOrder);

  // Create Orders -> Slides structure
  Object.keys(slidesByOrder).forEach((orderName) => {
    const slides = slidesByOrder[orderName];
    const orderId = slidesData.find(
      (slide) => slide.orderName === orderName,
    ).orderId;
    result.push({
      name: SLIDES_GROUP_NAME,
      children: [
        {
          orderId,
          groupName: ORDERS_GROUP_NAME,
          name: orderName,
          color: LOCALHOST_SECONDARY_COLOR,
          children: slides.map(({ uuid, name, groupName }) => ({
            uuid,
            name,
            groupName,
            color: IDEXX_SECONDARY_COLOR,
          })),
        },
      ],
    });
  });

  const tempResult = {};

  for (const item of result) {
    const { name, children } = item;
    if (!tempResult[name]) {
      tempResult[name] = children;
    } else {
      tempResult[name] = tempResult[name].concat(children);
    }
  }

  const groupedArr = [];

  for (const [name, children] of Object.entries(tempResult)) {
    const ordersSectionParams = {
      parent: {
        name: PROJECTS_GROUP_NAME,
        color: LOCALHOST_PRIMARY_COLOR,
      },
      child: {
        name: ORDERS_GROUP_NAME,
        color: LOCALHOST_SECONDARY_COLOR,
        url: ORDERS_LIST_URL,
      },
    };
    const slidesSectionParams = {
      parent: {
        name: ORDERS_GROUP_NAME,
        color: LOCALHOST_SECONDARY_COLOR,
      },
      child: {
        name: SLIDES_GROUP_NAME,
        color: IDEXX_SECONDARY_COLOR,
        url: SLIDES_LIST_URL,
      },
    };

    const section =
      name === ORDERS_GROUP_NAME ? ordersSectionParams : slidesSectionParams;

    groupedArr.push({ name, children, section });
  }

  return groupedArr;
};

export const truncateName = (name) =>
  name.length > 44 ? `${name.substring(0, 44)}...` : name;
