import React from 'react';
import {
  DATA_MANAGEMENT_BLUE,
  HISTOWIZ_PRIMARY_COLOR,
  MEDIUM_GREY,
  WHITE,
} from 'utilities/colors';
import { GridView, DoneAll } from '@mui/icons-material';
import { ApprovalDelegationIcon } from 'images/icons/ApprovalDelegationIcon';
import { HardDriveIcon } from 'images/icons/HardDriveIcon';
import { DeleteIcon } from 'images/icons/DeleteIcon';

export const IF = 'IF';
export const IHC = 'IHC';
export const H_AND_E = 'H&E';
export const SlideType = {
  IF,
  IHC,
  H_AND_E,
};

export const DATA_MANAGEMENT_SECTION = 'data_management';
export const ORDER_HISTORY_SECTION = 'order_history';
export const COMPUTE_SECTION = 'compute';
export const TEAM_MEMBERS_SECTION = 'team_members';

export const PRICE_ROUNDING_COEFFICIENT = 49;

const CONTRIBUTE_ACTION = 'contribute';
const ARCHIVE_ACTION = 'delete';
const EXPORT_ACTION = 'export';

export const SubmitActionTypes = {
  CONTRIBUTE_ACTION,
  ARCHIVE_ACTION,
  EXPORT_ACTION,
};

export const LOADING_STATE = 'loading';

export const ALL_ORDERS = 'all';
export const ACTIVE_ORDERS = 'active';
export const CONTRIBUTED_ORDERS = 'contributed';
export const ARCHIVED_ORDERS = 'archived';
export const ORDERS_ON_HARD_DISK = 'orders_on_hard_disk';

const getColor = (selectedType, isDefaultColor) => {
  if (isDefaultColor) {
    return WHITE;
  }
  switch (selectedType) {
    case ALL_ORDERS:
      return HISTOWIZ_PRIMARY_COLOR;
    case ACTIVE_ORDERS:
      return HISTOWIZ_PRIMARY_COLOR;
    case CONTRIBUTED_ORDERS:
      return HISTOWIZ_PRIMARY_COLOR;
    case ARCHIVED_ORDERS:
      return HISTOWIZ_PRIMARY_COLOR;
    case ORDERS_ON_HARD_DISK:
      return HISTOWIZ_PRIMARY_COLOR;
    default:
      return MEDIUM_GREY;
  }
};

export const getListItems = (selectedType, isDefaultColor) => [
  {
    value: ALL_ORDERS,
    label: 'View All',
    icon: (
      <GridView
        sx={{
          color: getColor(selectedType, isDefaultColor),
        }}
      />
    ),
  },
  {
    value: ACTIVE_ORDERS,
    label: 'Active',
    icon: (
      <DoneAll
        sx={{
          color: getColor(selectedType, isDefaultColor),
        }}
      />
    ),
  },
  {
    value: CONTRIBUTED_ORDERS,
    label: 'Contributed',
    icon: (
      <ApprovalDelegationIcon color={getColor(selectedType, isDefaultColor)} />
    ),
  },
  {
    value: ARCHIVED_ORDERS,
    label: 'Archived',
    icon: <DeleteIcon color={getColor(selectedType, isDefaultColor)} />,
  },
  {
    value: ORDERS_ON_HARD_DISK,
    label: 'Exported',
    icon: <HardDriveIcon color={getColor(selectedType, isDefaultColor)} />,
  },
];

export const ORDER_STEP = 'order';
export const SHIPPING_STEP = 'shipping';
export const INVOICE_STEP = 'invoice';

export const SELECT_STEP = 'select';
export const REVIEW_STEP = 'review';
export const CONFIRM_STEP = 'confirm';

export const PROJECT_STEP = 'project';
export const UPLOAD_STEP = 'upload';
export const FILES_COM_PREFIX = 'https://files.histowiz.com/f/';

export const ORDERS_VIEW = 'orders';
export const UPLOAD_VIEW = 'upload';

export const CONFIRM_EXPORT_DATA_ON_HARD_DISK_STEPPER = {
  name: '',
  color: DATA_MANAGEMENT_BLUE,
  steps: {
    [ORDER_STEP]: 'Order',
    [SHIPPING_STEP]: 'Shipping',
    [INVOICE_STEP]: 'Invoice',
  },
  activeStepIDX: 0,
};

export const CONFIRM_ACTION_STEPPER = {
  name: '',
  color: DATA_MANAGEMENT_BLUE,
  steps: {
    [SELECT_STEP]: 'Select',
    [REVIEW_STEP]: 'Review',
    [CONFIRM_STEP]: 'Confirm',
  },
  activeStepIDX: 0,
};

export const UPLOAD_STEPPER = {
  name: '',
  color: DATA_MANAGEMENT_BLUE,
  steps: {
    [PROJECT_STEP]: 'Project name',
    [ORDER_STEP]: 'Order number',
    [UPLOAD_STEP]: 'Upload',
  },
  activeStepIDX: 0,
};

export const selectActionOptions = (selectedOption) => [
  {
    value: 'delete',
    label: 'Delete',
    icon: (
      <DeleteIcon
        color={
          selectedOption?.value === 'delete' ? DATA_MANAGEMENT_BLUE : 'black'
        }
      />
    ),
  },
  {
    value: 'contribute',
    label: 'Contribute',
    icon: (
      <ApprovalDelegationIcon
        color={
          selectedOption?.value === 'contribute'
            ? DATA_MANAGEMENT_BLUE
            : 'black'
        }
      />
    ),
  },
  {
    value: 'export',
    label: 'Hard Drive Backup',
    icon: (
      <HardDriveIcon
        color={
          selectedOption?.value === 'export' ? DATA_MANAGEMENT_BLUE : 'black'
        }
      />
    ),
  },
];

export const MB_IN_TB = 1024 * 1024;
export const DELIVERY_COST_USD = 30;
export const ONE_TB_COST_USD = 500;

export const MAX_SELECTABLE_ORDERS_FOR_SELECT_ALL = 10;

export const TEAM_OVERVIEW_SECTIONS = [
  ORDER_HISTORY_SECTION,
  DATA_MANAGEMENT_SECTION,
  TEAM_MEMBERS_SECTION,
];
