import { BaseAIModel } from 'components/AITools/models/BaseAIModel';
import { getShortModelName } from 'components/AITools/utilities/common';

export const invertPoint = (point, viewer) => ({
  x: viewer.source.width - point.x,
  y: viewer.source.height - point.y,
});

export const getRGBAWithOpacity = (rgbaString, opacityPercent) => {
  const lastCommaIndex = rgbaString.lastIndexOf(',');
  const opacity = opacityPercent / 100;

  return `${rgbaString.substring(0, lastCommaIndex + 1)} ${opacity})`;
};

export class AITissueModel extends BaseAIModel {
  getPathPoint(x, y) {
    let point = super.getPathPoint(x, y);
    point = invertPoint(point, this.viewer);

    return point;
  }

  applyStylesFilled() {
    const opacity = this.getModelOpacity();
    const fillStyle = this.getFillStyle();

    // Set the fill style with the desired opacity
    this.ctx.fillStyle = getRGBAWithOpacity(fillStyle, opacity);
    this.ctx.fill();

    this.ctx.lineWidth = 2;
    // Set the stroke style to the same color without opacity
    this.ctx.strokeStyle = fillStyle;
    this.ctx.stroke();
  }

  getTissueContours() {
    const model = getShortModelName(this.modelName);

    let contours = this.slide?.[model]?.coordinates;

    if (typeof contours === 'string' || contours === undefined) {
      return [];
    }

    contours = contours.slice(1);
    contours = this.processContours(contours);

    return contours;
  }

  getOpacity() {
    return super.getOpacity();
  }

  getModelOpacity() {
    return this.getOpacity().tissue;
  }
}
