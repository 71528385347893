import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useCommonStyles } from 'components/TeamOverview/styles';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';
import { BottomButtonsSection } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/BottomButtonsSection';
import {
  getOrderDataUsageLabel,
  getOrderLabel,
} from 'components/TeamOverview/utilities';
import { useOrdersListStepStyles } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/styles';

const DataRow = ({ number, orderLabel, dataUsage }) => {
  const { classes } = useCommonStyles();

  return (
    <Grid container className={classes.row}>
      <Grid item xs={2} className={classes.cell}>
        <Typography className={classes.label}>{number}</Typography>
      </Grid>
      <Grid item xs={2} className={classes.cell}>
        <Typography className={classes.label}>{dataUsage}</Typography>
      </Grid>
      <Grid item xs={8} className={classes.cell}>
        <TextWithLimitedWidth
          value={orderLabel}
          containerClass={classes.textWithLimitedWidth}
          textClass={classes.label}
          tooltipPlacement={'bottom'}
        />
      </Grid>
    </Grid>
  );
};

export const OrdersListStep = ({ orders, handleNextStep, handlePrevStep }) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useOrdersListStepStyles();
  const dataUsage = orders.map(getOrderDataUsageLabel);
  const dataUsageMoreThanZero = dataUsage.some((usage) => usage !== '-');

  return (
    <Box className={commonClasses.stepContainer}>
      <Box className={commonClasses.table}>
        <DataRow
          number="S.NO"
          dataUsage={'USED'}
          orderLabel={'PROJECT/ORDER'}
        />
        <Box className={classes.ordersListContainer}>
          {orders.map((order, index) => (
            <DataRow
              key={order.id}
              number={index + 1}
              orderLabel={getOrderLabel(order)}
              dataUsage={dataUsage[index]}
            />
          ))}
        </Box>
      </Box>
      <BottomButtonsSection
        cancelButtonClick={handlePrevStep}
        cancelButtonText={'Cancel'}
        isNextDisabled={!dataUsageMoreThanZero}
        confirmButtonClick={handleNextStep}
      />
    </Box>
  );
};
