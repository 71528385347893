import { ModalSize } from 'components/Modals/constants';
import { Button, Typography, Box } from '@mui/material';
import { DefaultModal } from 'components/Modals/DefaultModal';
import React from 'react';

export const WarningModal = ({ isOpen, handleClose }) => {
  return (
    <DefaultModal open={isOpen} onClose={handleClose} size={ModalSize.DEFAULT}>
      <Typography fontSize="1.25rem" marginBottom={2}>
        <b>WARNING:</b> You cannot update slides for an order already
        in-progress
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" onClick={handleClose} color="primary">
          Close
        </Button>
      </Box>
    </DefaultModal>
  );
};
