import React from 'react';
import { PRIMARY_PINK } from 'utilities/colors';

export const DeleteIcon = ({ color = PRIMARY_PINK, size = 18 }) => {
  return (
    <svg width={size} height={size} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="m6.76 2.37-.01.37L3 2.75v1.5h.75c0 8.5.01 9.97.04 10.09l.11.34c.05.09.17.26.28.37.13.14.25.23.4.3.13.06.3.13.4.14.11.02 1.57.03 4.14.03h.02c3.9-.01 3.95-.01 4.12-.08a1.67 1.67 0 0 0 .92-.9l.07-.16.01-10.12h.76v-1.5h-3.74V2H6.77l-.01.37Zm-1.5 6.77v4.88h7.5V4.26h-7.5v4.88Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        d="M6.77 12.51V5.73l1.49.02.01 6.76h-1.5ZM9.78 12.51V5.73l1.49.02.01 6.76h-1.5Z"
      />
    </svg>
  );
};
