import { agGridDefaultColDef } from 'components/utilities/grid';
import { AntibodyTableTooltip } from 'components/AntibodiesList/AntibodyTableTooltip';

export const antibodiesTablesDefaultColDef = {
  ...agGridDefaultColDef,
  floatingFilter: true,
  tooltipComponent: AntibodyTableTooltip,
};

export const antibodiesDetailGridDefaultColDefs = {
  editable: false,
  sortable: false,
  filter: false,
  resizable: true,
};

export const SINGLE_ANTIBODY_TABLE_IDX = 0;
export const MULTIPLEX_ANTIBODY_TABLE_IDX = 1;
export const PANELS_TABLE_IDX = 2;
export const SPECIAL_STAINS_TABLE_IDX = 3;

export const GET_PANELS_ERROR_MESSAGE = 'Error occurred during fetching panels';

export const ANTIBODIES_TABLE_LEGEND = [
  {
    label: 'Grouped',
    color: '#AAADDA',
  },
  {
    label: 'On-going Promotions',
    color: '#FCE9EB',
  },
];

export const HEADER_TABS_PROPS = [
  {
    id: 'ihc-antibodies-tab',
    label: 'IHC',
  },
  {
    id: 'multiplex-antibodies-tab',
    label: 'Multiplex',
  },
  {
    id: 'if-panel-antibodies-tab',
    label: 'IF Panel',
  },
  {
    id: 'special-stains-antibodies-tab',
    label: 'Special Stains',
  },
];

export const DETAILS_VIEW_MODE = 'details';
export const VIEWER_VIEW_MODE = 'viewer';
