import React from 'react';
import {
  ORDERS_SLIDE_LIST_URL,
  PLACE_ORDER_INFO_URL,
  ORDER_DETAILS_URL,
} from 'constants/urls';
import {
  CustomTableCell,
  CustomTableCellNoTypography,
  editableOrderStates,
  FinishedOrderTableHeader,
  InProgressOrderTableHeader,
  NewOrderTableHeader,
} from 'components/OrdersList/constants';
import { Link } from 'react-router-dom';
import { OrderCustomTabActions } from 'components/OrdersList/Actions/OrderTableActions';
import moment from 'moment';
import { HORIZONTAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import {
  DATE_REQUEST_FORMAT,
  formatDatetimeStringForHistology,
} from 'utilities/dates_and_times';

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import { ShipBackOrdersCustomTableActions } from 'components/OrdersList/Actions/ShipBackSamplesSlidesTableActions';
import { makeStyles } from 'tss-react/mui';
import { PROCESSED_IF_SLIDES_TAB_VALUE } from 'components/OrderDetails/constants';
import { useOrderTablesStyles } from 'components/OrdersList/orderTablesStyles';
import { EditOrderButton } from 'components/OrdersList/EditOrderButton';
import { prettifyTrackerState } from 'components/OrderDetails/Tabs/OrderProgressTab/utilities';
import { ACTION_LINK_COLOR, WHITE } from 'utilities/colors';

const textDecorationStyle = { textDecoration: 'none', fontSize: '1rem' };
const minWidthOrderNameStyle = {
  whiteSpace: 'nowrap',
  color: ACTION_LINK_COLOR,
};

export const OrderTableRow = ({
  row,
  includeShipping,
  isInProgress,
  isFinished,
}) => {
  const { classes } = useOrderTablesStyles();

  let editableState = editableOrderStates.includes(row.state);
  if (row.process_review_datetime) {
    // if it's finished process reviewed, don't let it be editable
    editableState = false;
  }

  // have to make a temp fix for people submitting orders from the old system and completing in the new one
  // stop all 8000-7000 series, that should prob be a good enough patch
  const newLISOrder = !(
    row.name.includes('Order 8') || row.name.includes('Order 7')
  );

  let orderDetailsURL;
  // let orders be edited until they're after a certain state, then stop them
  // from being edited
  if (editableState) {
    orderDetailsURL = PLACE_ORDER_INFO_URL.replace(':orderUUID', row.uuid);
    if (!newLISOrder) {
      orderDetailsURL = '';
    }
  } else {
    orderDetailsURL = ORDERS_SLIDE_LIST_URL.replace(':orderUUID', row.uuid);
  }

  const orderDetailsIfSlidesTabUrl = ORDER_DETAILS_URL.replace(
    ':orderUUID',
    row.uuid,
  ).concat(`#${PROCESSED_IF_SLIDES_TAB_VALUE}`);

  const orderNameSerialized = `${row.name}`;

  const defaultSlidesCount = row.slides_count;
  const ifSlidesCount = row.if_slides_count;

  const defaultSlidesCountLabel = `${defaultSlidesCount} ${
    defaultSlidesCount === 1 ? 'Slide' : 'Slides'
  }`;
  const ifSlidesCountLabel = `${ifSlidesCount} ${
    ifSlidesCount === 1 ? 'IF Slide' : 'IF Slides'
  }`;

  const submittedUser = row.submitted_user
    ? `by ${row.submitted_user.first_name} ${row.submitted_user.last_name}`
    : '';

  const histologyStartTime = row.turnaround_start_datetime
    ? formatDatetimeStringForHistology(row.turnaround_start_datetime)
    : '-';
  const histologyEndTime = row.turnaround_due_datetime
    ? formatDatetimeStringForHistology(row.turnaround_due_datetime)
    : '-';

  const orderDetailsLinkForDefaultSlides = () => (
    <Box style={textDecorationStyle}>
      <Link to={orderDetailsURL}>
        <Box style={minWidthOrderNameStyle}>
          <Typography variant="caption">{defaultSlidesCountLabel}</Typography>
        </Box>
      </Link>
    </Box>
  );

  const orderDetailsLinkForIFSlides = () => (
    <Box style={textDecorationStyle}>
      <Link to={orderDetailsIfSlidesTabUrl}>
        <Box style={minWidthOrderNameStyle}>
          <Typography variant="caption">{ifSlidesCountLabel}</Typography>
        </Box>
      </Link>
    </Box>
  );

  return (
    <TableRow className={classes.row}>
      <CustomTableCellNoTypography>
        <Box>
          <Typography className={classes.rowCell}>
            {orderNameSerialized}{' '}
            {editableState && <EditOrderButton orderId={row.uuid} />}
          </Typography>
        </Box>
        <Box className={classes.slidesCount}>
          {orderDetailsURL && orderDetailsLinkForDefaultSlides()}
          {!!(ifSlidesCount && orderDetailsURL) && (
            <>
              <Typography className={classes.andLabel} variant="caption">
                {' '}
                and{' '}
              </Typography>
              {orderDetailsLinkForIFSlides()}
            </>
          )}
        </Box>
      </CustomTableCellNoTypography>

      <CustomTableCell>
        <Typography className={classes.projectCell}>
          {row.project.name}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>{row.team.name}</CustomTableCell>
      <OrderCustomTabActions row={row} />
      <CustomTableCellNoTypography>
        <Typography variant="body1" className={classes.rowCell}>
          {row.tracker_state
            ? prettifyTrackerState(row.tracker_state)
            : 'See Tracking Info'}
        </Typography>
      </CustomTableCellNoTypography>
      {(isFinished || isInProgress) && (
        <CustomTableCell>
          {row.po_attachment ? (
            <Link to={{ pathname: row.po_attachment }} target="_blank">
              PO Attachment
            </Link>
          ) : (
            '-'
          )}
        </CustomTableCell>
      )}
      {isInProgress && (
        <>
          <CustomTableCell>{histologyStartTime}</CustomTableCell>
          <CustomTableCell>{histologyEndTime}</CustomTableCell>
        </>
      )}
      <CustomTableCell>
        {moment(row.created).format(DATE_REQUEST_FORMAT)} {submittedUser}
      </CustomTableCell>
      {includeShipping && <ShipBackOrdersCustomTableActions order={row} />}
    </TableRow>
  );
};

export const useTableStyles = makeStyles()((theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    marginBottom: '2rem',
    position: 'relative',
    backgroundColor: WHITE,
    ...HORIZONTAL_SCROLL_STYLE,
  },
  table: {
    tableLayout: 'fixed',
    minWidth: 800,
  },
}));

export const OrderTable = ({
  orders,
  includeShipping,
  tableHeader,
  isInProgress,
  isFinished,
}) => {
  const { classes } = useTableStyles();

  return (
    <Paper className={classes.paper}>
      <Table className={classes.table}>
        {tableHeader}
        <TableBody>
          {orders.map((row) => (
            <OrderTableRow
              row={row}
              key={row.uuid}
              includeShipping={includeShipping}
              isInProgress={isInProgress}
              isFinished={isFinished}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const UnsubmittedOrdersTable = ({ orders }) => (
  <>
    <Box ml={0}>
      <Typography variant="h6" align="left">
        Unsubmitted Orders
      </Typography>
    </Box>
    <OrderTable
      orders={orders}
      tableHeader={NewOrderTableHeader}
      includeShipping={false}
    />
  </>
);

export const InProgressOrdersTable = ({ orders }) => (
  <>
    <Box mt={1}>
      <Typography variant="h6" align="left" color="secondary">
        Histology Orders (In Progress)
      </Typography>
    </Box>
    <OrderTable
      orders={orders}
      tableHeader={InProgressOrderTableHeader}
      includeShipping={false}
      isInProgress
    />
  </>
);

export const FinishedOrdersTable = ({ orders }) => (
  <>
    <Box ml={-0.5}>
      <Typography variant="h6">Finished Orders</Typography>
    </Box>
    <OrderTable
      orders={orders}
      tableHeader={FinishedOrderTableHeader}
      includeShipping
      isFinished
    />
  </>
);
