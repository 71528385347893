import { Fragment, memo } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { StepIcon } from 'components/OrderDetails/Tabs/OrderProgressTab/StepIcon';
import { useProgressStepperStyles } from 'components/OrderDetails/Tabs/OrderProgressTab/styles';

const Stepper = ({ stepper, isLoading }) => {
  const { steps, color, name } = stepper;

  const { classes } = useProgressStepperStyles({ color });

  return (
    <Box className={classes.stepContainers}>
      <Typography className={classes.stepName} variant="h4">
        {name}
      </Typography>
      <Box className={classes.noTimelineStepperContainer}>
        {steps.map((step) => (
          <Fragment key={step.name}>
            {isLoading ? (
              <Box className={classes.stepWrapper}>
                <Box className={classes.skeletonStep} />
                <Skeleton variant="circular">
                  <StepIcon classes={classes} />
                </Skeleton>
                <Skeleton variant="text">
                  <Typography className={classes.stepLabel} width="5rem">
                    Step Step Step
                  </Typography>
                </Skeleton>
              </Box>
            ) : (
              <Box className={classes.stepWrapper}>
                <StepIcon
                  active={step.isActive}
                  completed={step.isCompleted}
                  classes={classes}
                />
                <Typography className={classes.stepLabel}>
                  {step.name}
                </Typography>
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export const NonLinearStepper = memo(Stepper);
