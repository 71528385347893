import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { CircularProgress, Typography, Box } from '@mui/material';

import { useHistoryStyles } from 'components/SampleEditor/components/SamplesChangelog/styles';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { DARK_GREY } from 'utilities/colors';
import {
  useLazyGetChangedSamplesQuery,
  useLazyGetSampleHistoryQuery,
} from 'store/apis/samplesApi';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import { FilterInput } from 'components/SampleEditor/components/SamplesChangelog/FilterInput';
import { DatePickers } from 'components/SampleEditor/components/SamplesChangelog/DatePickers';
import { BREADCRUMBS } from 'components/SampleEditor/components/SamplesChangelog/constants';
import {
  getGroupedData,
  getInitialTables,
  handleExpandedRows,
  mapHistoryDetails,
} from 'components/SampleEditor/components/SamplesChangelog/utils';
import { DATE_REQUEST_FORMAT } from 'utilities/dates_and_times';
import { TimelineTables } from 'components/SampleEditor/components/SamplesChangelog/TimelineTables';

export const SampleChangeLog = () => {
  const { classes } = useHistoryStyles();
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [filterText, setFilterText] = useState('');
  const [tables, setTables] = useState([]);
  const [rowLoading, setRowLoading] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);

  const [
    getChangedSamples,
    { data: samplesData = [], error: fetchingSamplesError, isLoading },
  ] = useLazyGetChangedSamplesQuery();
  const [getSampleHistory, { error: fetchingHistoryError }] =
    useLazyGetSampleHistoryQuery();

  useQueryError(fetchingSamplesError || fetchingHistoryError);

  useEffect(() => {
    if (startDate.isAfter(endDate)) {
      setStartDate(endDate);
    }

    getChangedSamples({
      startDate: startDate.format(DATE_REQUEST_FORMAT),
      endDate: endDate.format(DATE_REQUEST_FORMAT),
    });
  }, [startDate, endDate]);

  const onChangeFilterText = useCallback((event) => {
    setFilterText(event.target.value);
  }, []);

  const groupedData = getGroupedData(samplesData, filterText);

  useEffect(() => {
    setTables(getInitialTables(groupedData));
  }, [JSON.stringify(groupedData)]);

  const assignHistoryDetails = (table, uuid) => {
    const item = table.data.find((item) => item.uuid === uuid);
    const dateUUID = `${uuid}-${table.date}`;
    if (item.historyDetails) {
      return handleExpandedRows(setExpandedRows, dateUUID);
    }

    setRowLoading(`${uuid}-${table.date}`);
    getSampleHistory({
      sampleId: uuid,
      date: dayjs(table.date).format(DATE_REQUEST_FORMAT),
    })
      .unwrap()
      .then((data) => {
        setTables((prevTables) =>
          mapHistoryDetails(prevTables, uuid, data, table.date),
        );
        setRowLoading('');
        handleExpandedRows(setExpandedRows, dateUUID);
      })
      .finally(() => {
        setRowLoading('');
      });
  };

  return (
    <MiniDrawerWithContext header="Samples Changelog">
      <Box className={classes.headerWrapper}>
        <Breadcrumbs
          data={BREADCRUMBS}
          size="medium"
          dividerColor={DARK_GREY}
        />
        <Box className={classes.inputsWrapper}>
          <DatePickers
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <FilterInput
            filterText={filterText}
            onChangeFilterText={onChangeFilterText}
          />
          <Typography className={classes.sortLabel}>
            SORT BY PROJECT/ORDER
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <CircularProgress className={classes.spinner} />
      ) : (
        <TimelineTables
          tables={tables}
          assignHistoryDetails={assignHistoryDetails}
          classes={classes}
          rowLoading={rowLoading}
          expandedRows={expandedRows}
        />
      )}
    </MiniDrawerWithContext>
  );
};
