import { useSelector } from 'react-redux';
import { algoliaSearchApiKeySelector } from 'store/slices/userDetailsSlice';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { PathologyMapMainContent } from 'components/PathologyMap/PathologyMapMainContent';
import { PATHOLOGY_MAP_V2_NAME } from 'components/PathologyMap/constants/common';
import { useTitle } from 'components/utilities/hooks/useTitle';

export const GlobalPathologyMapPage = () => {
  const algoliaSearchApiKey = useSelector(algoliaSearchApiKeySelector);
  useTitle(PATHOLOGY_MAP_V2_NAME);

  return (
    <MiniDrawerWithContext
      header={PATHOLOGY_MAP_V2_NAME}
      isWithInfiniteScrollPage
    >
      <PathologyMapMainContent algoliaSearchApiKey={algoliaSearchApiKey} />
    </MiniDrawerWithContext>
  );
};
