import { useSlidesListViewV2Styles } from 'components/OrderSlidesList/OrderSlidesListViewV2Styles';
import {
  buildSlidesIntoHash,
  parseSlidesCoordinatesFromUrl,
} from 'components/OrderSlidesList/utilities';
import {
  getSortedSelectedRows,
  selectGridSingleRow,
} from 'components/utilities/grid';
import { useAGGridOverlayControl } from 'components/utilities/hooks/grid/useAGGridOverlayControl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  isSharedPageSelector,
  isSlidesListLoadingSelector,
  projectUUIDSelector,
  selectedRowsSelector,
  setOpenSlideInFullscreen,
  setSelectedRows,
  updateSelectedRow,
} from 'store/slices/slidesListSlice/slidesListSlice';
import { setRowSelectionVisible } from 'components/ImageViewer/utilities';
import { slidesSelector, updateSlide } from 'store/slices/slidesSlice';
import {
  getShortModelName,
  isQCModel,
} from 'components/AITools/utilities/common';
import { useSlidesMlData } from 'components/AITools/hooks/useSlidesMlData';
import { selectCurrentSlideAIModels } from 'store/slices/aiToolsSlice';
import {
  useLazyGetSharedSlideQuery,
  useLazyGetSlideQuery,
} from 'store/apis/slidesApi';
import { FETCH_SLIDE_ERROR_MESSAGE } from 'constants/errorMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const useSlidesGrid = () => {
  const { classes } = useSlidesListViewV2Styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState();
  const [annotationsCount, setAnnotationsCount] = useState(null);
  const slides = useSelector(slidesSelector);
  const selectedRows = useSelector(selectedRowsSelector);
  const projectUUID = useSelector(projectUUIDSelector);
  const isSlidesLoading = useSelector(isSlidesListLoadingSelector);
  const isSharedPage = useSelector(isSharedPageSelector);
  const slide =
    !!selectedRows.length &&
    slides.find((s) => s.uuid === selectedRows[0].uuid);
  const { getSlidesMLData } = useSlidesMlData();
  const currentAIModels = useSelector(selectCurrentSlideAIModels);

  const { showError } = useSnackbar();

  const [getSlide] = useLazyGetSlideQuery();
  const [getSharedSlide] = useLazyGetSharedSlideQuery();

  useEffect(() => {
    if (gridApi) {
      const parsedSlidesFromURL = parseSlidesCoordinatesFromUrl();

      const synchronizedSlides = parsedSlidesFromURL.filter((parsedSlide) =>
        selectedRows.find(
          (selectedRow) => selectedRow.uuid === parsedSlide.slideId,
        ),
      );
      setRowSelectionVisible(gridApi);
      const hash = buildSlidesIntoHash(synchronizedSlides);

      history.replace(`${window.location.pathname}${hash}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    // updating by transaction cause using setRowData leads to resetting ag-grid filters, grouping etc.
    if (gridApi) {
      gridApi.applyTransaction({
        update: [...slides],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  useEffect(() => {
    // FavoriteCell not being re-rendered on rows update so forcing it
    if (gridApi) {
      gridApi.refreshCells();
    }
  }, [slides, gridApi]);

  const updateSlideMLData = (slideToUpdate) => {
    currentAIModels.forEach((model) => {
      const isQC = isQCModel(model);
      const shortModelName = getShortModelName(model);

      if (!isQC && slideToUpdate) {
        getSlidesMLData([slideToUpdate], shortModelName);
      }
    });
  };

  const updateSlideData = (currentSlide) => {
    const sliderGetter = isSharedPage ? getSharedSlide : getSlide;
    const fetchParams = isSharedPage
      ? { shareUUID: currentSlide.share_uuid }
      : { slideUUID: currentSlide.uuid };

    sliderGetter(fetchParams)
      .unwrap()
      .then((newSlide) => {
        dispatch(updateSlide(newSlide));
        dispatch(updateSelectedRow(newSlide));
      })
      .catch(() => showError(FETCH_SLIDE_ERROR_MESSAGE));
  };

  const updateSelectedRows = useCallback(
    (e) => {
      if (!gridApi) return;

      const currentSelectedRows = getSortedSelectedRows(gridApi);

      if (!currentSelectedRows?.length) return;

      const selectedSlide = e?.node?.data;

      if (selectedSlide && e?.node?.selected) {
        updateSlideData(selectedSlide);
        updateSlideMLData(selectedSlide);
      }
      dispatch(setSelectedRows(currentSelectedRows));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridApi],
  );

  const handlePreviousSlide = useCallback(
    (inFullScreen = false) => {
      const gridRows = gridApi?.getRenderedNodes();
      const gridSlides = gridRows ? gridRows.map((node) => node.data) : slides;

      const currentIndex = gridSlides.findIndex(
        ({ uuid }) => uuid === selectedRows[0].uuid,
      );
      const prevIndex =
        currentIndex === 0 ? gridSlides.length - 1 : currentIndex - 1;
      const prevSlideUUID = gridSlides[prevIndex].uuid;

      if (inFullScreen) {
        dispatch(setOpenSlideInFullscreen(true));
      }

      if (gridApi.destroyCalled) {
        const prevSlide = gridSlides.find(({ uuid }) => uuid === prevSlideUUID);
        dispatch(setSelectedRows([prevSlide]));
        if (prevSlide) {
          updateSlideData(prevSlide);
          updateSlideMLData(prevSlide);
        }

        return;
      }

      selectGridSingleRow(gridApi, ({ id }) => id === prevSlideUUID);
      gridApi.ensureIndexVisible(prevIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, gridApi],
  );

  const handleNextSlide = useCallback(
    (inFullScreen) => {
      const gridRows = gridApi?.getRenderedNodes();
      const gridSlides = gridRows ? gridRows.map((node) => node.data) : slides;

      const currentIndex = gridSlides.findIndex(
        ({ uuid }) => uuid === selectedRows[0].uuid,
      );
      const nextIndex =
        currentIndex === gridSlides.length - 1 ? 0 : currentIndex + 1;
      const nextSlideUUID = gridSlides[nextIndex].uuid;

      if (inFullScreen) {
        dispatch(setOpenSlideInFullscreen(true));
      }

      if (gridApi.destroyCalled) {
        const nextSlide = gridSlides.find(({ uuid }) => uuid === nextSlideUUID);
        dispatch(setSelectedRows([nextSlide]));
        if (nextSlide) {
          updateSlideData(nextSlide);
          updateSlideMLData(nextSlide);
        }

        return;
      }

      selectGridSingleRow(gridApi, ({ id }) => id === nextSlideUUID);
      gridApi.ensureIndexVisible(nextIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, gridApi],
  );

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.suppressNoRowsOverlay = true;
  }, []);

  const onRowSelected = useCallback(
    (e) => {
      updateSelectedRows(e);
    },
    [updateSelectedRows],
  );

  useAGGridOverlayControl(isSlidesLoading, slides, gridApi);

  const slidesViewerProps = useMemo(
    () => ({
      slides: selectedRows,
      handleNextSlide,
      handlePreviousSlide,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows],
  );

  const slidesGroupProps = useMemo(
    () => ({
      selectedSlides: selectedRows,
    }),
    [selectedRows],
  );

  const slidesTableProps = useMemo(
    () => ({
      isSlidesLoading,
      groupOrders: !!projectUUID,
      slides,
      onGridReady,
      onRowSelected,
      selectedSlides: selectedRows,
      updateSelectedRows,
      classes,
      gridApi,
    }),
    [
      isSlidesLoading,
      slides,
      projectUUID,
      selectedRows,
      classes,
      onGridReady,
      onRowSelected,
      updateSelectedRows,
      gridApi,
    ],
  );

  const slideCommentsProps = useMemo(
    () => ({
      slide,
      isSharedPage,
    }),
    [slide, isSharedPage],
  );

  return {
    handlePreviousSlide,
    handleNextSlide,
    selectedRows,
    annotationsCount,
    setAnnotationsCount,
    slide,
    slidesViewerProps,
    slidesGroupProps,
    slidesTableProps,
    slideCommentsProps,
  };
};
