import React from 'react';
import { Grid } from '@mui/material';
import {
  OrderAttachmentsTable,
  OrderSummaryTable,
  ServicesRequestedTable,
} from 'components/OrderForm/components/ReviewTables';
import { SampleList } from 'components/OrderDetails/SamplesListComponent';
import { OrderFileAttachmentsDropzone } from 'components/OrderDetails/OrderFileAttachmentsDropzone';
import { ShowStaffUserOnly } from 'components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly';

export const OrderSummaryTab = ({
  order,
  samples,
  attachments,
  onFilesDrop,
}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <OrderSummaryTable order={order} alternative={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ServicesRequestedTable order={order} samples={samples} />
          <OrderAttachmentsTable attachments={attachments} />
          <ShowStaffUserOnly>
            <OrderFileAttachmentsDropzone onFilesDrop={onFilesDrop} />
          </ShowStaffUserOnly>
        </Grid>

        <Grid item xs={12}>
          <SampleList samples={samples} />
        </Grid>
      </Grid>
    </>
  );
};
