import { useEffect, useState } from 'react';
import { getGlobalSearchResults } from 'services/resources/globalSearch';
import {
  ORDERS_GROUP_NAME,
  SLIDES_GROUP_NAME,
} from 'components/GlobalSearch/constants';
import {
  serializeData,
  serializeOrdersSearchResponse,
  serializeSearchResponse,
} from 'components/GlobalSearch/utilities';
import { useDebouncedValue } from 'utilities/hooks/useDebouncedValue';
import { SEARCH_DELAY } from 'utilities/hooks/useDebouncedValue/constants';

export const useGlobalSearch = (search) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearch = useDebouncedValue(search, SEARCH_DELAY);

  useEffect(() => {
    const isRequestNeeded = !!search && search !== debouncedSearch;
    setIsLoading(isRequestNeeded);
  }, [search]);

  useEffect(() => {
    const controller = new AbortController();

    if (debouncedSearch) {
      setIsLoading(true);
      getGlobalSearchResults(debouncedSearch, controller.signal)
        .then(([orders, slides]) => {
          const serializedOrders = orders.results.map(
            serializeOrdersSearchResponse(ORDERS_GROUP_NAME),
          );
          const serializedSlides = slides.results.map(
            serializeSearchResponse(SLIDES_GROUP_NAME),
          );

          setData(serializeData(serializedOrders, serializedSlides));
          setIsLoading(false);
        })
        .catch((error) => {
          if (error?.name === 'CanceledError') return;

          throw new Error(error);
        });
    } else {
      setData([]);
      setIsLoading(false);
    }

    return () => controller.abort();
  }, [debouncedSearch]);

  return {
    data,
    isLoading,
  };
};
