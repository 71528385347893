import { useAdditionalQuestionsForm } from 'components/OrderForm/BillingShipping/hooks/useAdditionalQuestionsForm';
import { FormProvider } from 'react-hook-form';
import { Fragment } from 'react';
import {
  ClientFedexAccountInput,
  ImageAnalysisAndPathologyConsultationTimeInput,
  ShipBackUnstainedSlidesInput,
} from 'components/OrderForm/components/AdditionalQuestionsFormInput';
import { Button, Grid, Typography, Paper, Tab, Tabs } from '@mui/material';
import { ClientFedexRequired } from 'components/OrderForm/BillingShipping/BillingShippingComponents';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import Box from '@mui/material/Box';
import Portal from '@mui/material/Portal';
import { TeamAddressForm } from 'components/Shared/TeamAddress/TeamAddressFormV1';
import { TabPanel } from 'components/Shared/TabPanel';
import { AddressSelect } from 'components/OrderForm/BillingShipping/ShippingTabs/AddressSelect';
import { useBillingShippingStyles } from 'components/OrderForm/BillingShipping/styles';

const marginStyle = { marginTop: '1rem' };

export const AdditionalQuestionsForm = () => {
  const { classes } = useBillingShippingStyles();
  const {
    methods,
    onSubmit,
    orderRequestedIAOrPC,
    unstainedSlidesRequested,
    handleShippingTabChange,
    shippingTabSelected,
    shippingBackAddressSelected,
    setShippingBackAddressSelected,
    shippingAddresses,
    isFedexVisible,
    setValue,
    client_fedex_account,
    handleAddTeamAddressSuccess,
    shippingAddressSelected,
    teamUUID,
    submitting,
    ship_back_unstained_slides,
    portalRef,
  } = useAdditionalQuestionsForm();

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/*If requested image analysis or path consult then ask followup how many days*/}
          {orderRequestedIAOrPC && (
            <ImageAnalysisAndPathologyConsultationTimeInput name="turnaround_image_analysis_pathology_consultation_days" />
          )}
          <Typography variant={'body1'} style={marginStyle}>
            Note: We are no longer shipping back blocks and stained slides
            immediately. If you would like this specific order to be sent back,
            please reach out to getsupport@histowiz.com after the order is
            fulfilled.
          </Typography>
          {unstainedSlidesRequested && (
            <>
              <ShipBackUnstainedSlidesInput name="ship_back_unstained_slides" />
              {ship_back_unstained_slides.value && (
                <Grid container direction="row" justifyContent="space-between">
                  {shippingAddressSelected ? (
                    <Grid item xs={7} className={classes.addressContainer}>
                      {/* <ShippingAddressDisplay address={shippingAddressSelected} /> */}
                      <Paper className={classes.paper}>
                        <Tabs
                          value={shippingTabSelected}
                          indicatorColor="secondary"
                          textColor="secondary"
                          onChange={handleShippingTabChange}
                        >
                          <Tab label="Add Address" />
                          <Tab label="Existing Ship Back Address" />
                        </Tabs>
                        <Box ref={portalRef} index={0} />
                        <TabPanel value={shippingTabSelected} index={1}>
                          <AddressSelect
                            value={shippingBackAddressSelected}
                            onChange={setShippingBackAddressSelected}
                            shippingAddresses={shippingAddresses}
                          />
                        </TabPanel>
                      </Paper>
                    </Grid>
                  ) : (
                    <p>Select shipping address first</p>
                  )}
                </Grid>
              )}
            </>
          )}
          {/*Conditional Logic to only show up if either ship back slides or ship back samples is yes*/}
          {isFedexVisible && (
            <Fragment>
              <ClientFedexAccountInput name="client_fedex_account" />
              {!client_fedex_account && (
                <ClientFedexRequired
                  name="client_fedex_account"
                  setValue={setValue}
                />
              )}
            </Fragment>
          )}
          <Grid container direction={'row-reverse'}>
            <div className={classes.saveButton}>
              <Button type="submit" variant="contained" color={'primary'}>
                <SubmittingIcon submitting={submitting} />
                Save & Continue
              </Button>
            </div>
          </Grid>
        </form>
      </FormProvider>
      {!shippingTabSelected && (
        <Portal container={portalRef.current}>
          <TeamAddressForm
            teamUUID={teamUUID}
            onAfterSuccessSubmit={handleAddTeamAddressSuccess}
          />
        </Portal>
      )}
    </Fragment>
  );
};
