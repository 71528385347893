export const TEAM_WORD = 'Team';

export const serializeNameAndUUID = ({ name, uuid }) => ({
  label: name,
  value: uuid,
});

export const createTeamLabel = (name, { name: teamName }) => {
  const hasTeamWord = name.includes(TEAM_WORD) || teamName.includes(TEAM_WORD);

  return `${name} - (${teamName}${hasTeamWord ? '' : ` ${TEAM_WORD}`})`;
};

export const serializeTeamName = ({ uuid, name, team, defaults }) => ({
  label: createTeamLabel(name, team),
  value: uuid,
  defaults: defaults,
});

export const serializeNameWithUrl = ({ name, uuid, url }) => ({
  label: name,
  value: uuid,
  url,
});
