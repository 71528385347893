import { makeStyles } from 'tss-react/mui';
import { CAPTION_GREY, DARK_GREY } from 'utilities/colors';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';

export const MODAL_WITHOUT_BACKGROUND = {
  minHeight: '45vh',
  backgroundColor: 'inherit',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
};

export const useSlideGroupsVerticalContainer = makeStyles()((theme) => ({
  slideGroupVerticalContainer: {
    maxHeight: '60vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    ...VERTICAL_SCROLL_STYLE,
  },
  container: {
    padding: '0.5rem 1rem 0.5rem 2rem',
    minWidth: 600,
  },
  centeredCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    padding: '0.5rem 1rem 0.5rem 1rem',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    lineHeight: 2,
    fontSize: '0.75em',
    cursor: 'pointer',
  },
  sortsContainer: {
    paddingBottom: '0.5rem',
  },
  longTextContainer: {
    width: '100%',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
  },
  addButton: {
    position: 'absolute',
    right: '1rem',
    bottom: '1rem',
    textTransform: 'uppercase',

    [theme.breakpoints.down('xxl')]: {
      fontSize: '0.5rem',
      padding: '0.5rem 1rem',
      bottom: '2rem',
    },
  },
}));

export const useSlideInfoRowStyles = makeStyles()(() => ({
  longTextContainer: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    '-webkit-line-clamp': '5',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
  },
  lineContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
    padding: '0.5rem 1rem 0.5rem 1rem',
  },
  headingLine: {
    backgroundColor: '#F2F7F9',
  },
  label: {
    lineHeight: 2,
    fontSize: '0.75em',
    cursor: 'pointer',
  },
  row: {
    padding: '0.5rem 1rem 0.5rem 1rem',
  },
}));

export const useAddSlidesToSlideGroupModalStyles = makeStyles()((theme) => ({
  centeredCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedSlideRow: {
    paddingBlock: '0.75rem',
    borderBottom: `1px solid ${DARK_GREY}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    fontSize: '1.125em',
    lineHeight: 1.25,
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalBackground: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: '0.25rem',
  },
  counter: {
    alignSelf: 'center',
  },
  container: {
    height: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 16px 0 32px',
  },
  button: {
    '&:hover': {
      background: '#E7E7E7',
    },
    '&:last-child': {
      borderRight: 'solid 1px #cccccc',
    },
  },
  slideGroupSelection: {
    display: 'flex',
    padding: '0.5rem',
    alignItems: 'center',
    '&:hover': {
      background: '#E7E7E7',
    },
    cursor: 'pointer',
    width: '100%',
  },
  sortsContainer: {
    paddingBottom: '0.5rem',
  },
}));

export const useSlideGroupsListViewStyles = makeStyles()(() => ({
  noSlideGroupsLabelContainer: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  viewMoreContainer: {
    cursor: 'pointer',
  },
  spinnerContainer: {
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sortByLabel: {
    marginRight: '1rem',
  },
  addSlideGroupButton: {
    marginLeft: '1rem',
    height: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#FAFAFA',
    border: '1px solid',
    borderColor: '#0000000d',
    borderRadius: '3px',
    boxShadow: '0px 8px 20px 15px #0000000d',
    padding: '0.5rem 2rem',
    flexWrap: 'wrap',
    gap: '10px 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  slidesLabel: {
    fontSize: '0.875rem',
    marginRight: '1rem',
    marginBottom: 0,
  },
}));

export const useViewsSelectionButtonsStyles = makeStyles()(() => ({
  largeViewButtonsSection: {
    width: '25rem',
  },
  smallViewButtonSection: {
    width: '10rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  changeViewButton: {
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: 'rgba(66, 148, 213, 0.2)',
    },
  },
  changeViewButtonIcon: {
    marginRight: '0.5rem',
  },
  selectedButton: {
    background:
      'linear-gradient(180deg, rgba(66, 148, 213, 0.35), rgba(66, 148, 213, 0.20))',
    color: 'black',
  },
  betaLabel: {
    backgroundColor: 'green',
    color: 'white',
    fontSize: '0.65rem',
    lineHeight: '1.125rem',
    borderRadius: '0.25rem',
    padding: '0 0.2rem',
    marginBottom: '0.05rem',
    marginLeft: '0.5rem',
  },
}));

export const useSlideThumbnailWithInfoStyles = makeStyles()(() => ({
  slideImagePreviewContainer: {
    height: '100%',
    position: 'relative',
    padding: '0.125rem',
    boxShadow: '0px 8px 15px -3px rgba(0,0,0,0.1)',
  },
  shortInfoAbsoluteContainer: {
    width: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  shortInfo: {
    width: '90%',
    margin: '8px auto',
    borderRadius: '0.5rem',
    backgroundColor: '#F8F8F88C',
    backdropFilter: 'blur(2px)',
    padding: '0.5rem 1rem',
  },

  slideImagePreview: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

export const useShortInfoBlockStyles = makeStyles()(() => ({
  shortInfoCaption: {
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    color: CAPTION_GREY,
  },
  shortInfoBlock: {
    marginBlock: '0.5rem',
    width: '50%',
  },
  shortInfoText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export const useCounterStyles = makeStyles()(() => ({
  counterContainer: {
    borderRadius: '0.5rem',
    background: 'rgba(0,0,0,0.4)',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    height: '1.25rem',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counterTypography: {
    color: '#FFF',
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 700,
    textAlign: 'center',
  },
}));

export const useThumbnailsGroupPreviewStyles = makeStyles()(() => ({
  root: {
    position: 'relative',
  },
  imagesWrapper: {
    margin: '0 !important',
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    aspectRatio: '1 / 0.90',
    backgroundColor: '#F5F5F5',
    padding: '0.5rem 1rem',
    ...VERTICAL_SCROLL_STYLE,
  },
  noSlidesContainer: {
    aspectRatio: '1 / 0.90',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  moreSlidesCount: {
    borderRadius: '0.25rem',
    right: '0.5rem',
    bottom: '0.5rem',
    border: '1px solid black',
    width: '120px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: '#F8F8F88C',
    backdropFilter: 'blur(2px)',
  },
}));

export const useSlideGroupShortInfoStyles = makeStyles()(
  (theme, { isHighlighted }) => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #CCCCCC',
      borderRadius: '4px',
      backgroundColor: isHighlighted ? '#4294D533' : '#F8F9FA',
      margin: '0.25rem 0.5rem',
      paddingBlock: '0.5rem',
      paddingLeft: '2rem',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid black',
      },
    },
    highlightedContainer: {
      backgroundColor: '#E1F6E1',
    },
    centeredCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    verticallyCenteredCell: {
      display: 'flex',
      alignItems: 'center',
    },
    groupNameContainer: {
      maxWidth: '45ch',
    },
    groupNameText: {
      fontSize: '1.125em',
    },
  }),
);

export const useTextWithLimitedWidthStyles = makeStyles()(() => ({
  alignToLeftContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  slideName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export const useSlideGroupButtonStyles = makeStyles()(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  slideGroupSelection: {
    display: 'flex',
    padding: '0.5rem',
    alignItems: 'center',
    '&:hover': {
      background: '#E7E7E7',
    },
    cursor: 'pointer',
    width: '100%',
    justifyContent: 'space-between',
  },
  slideGroupName: {
    marginLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const useDefaultSlideGroupHeaderStyles = makeStyles()(() => ({
  headerButton: {
    minWidth: '2rem',
    width: '2rem',
    marginLeft: '0.5rem',
    cursor: 'pointer',
  },
  groupNameInput: {
    marginBottom: '0.5rem',
    minWidth: '50%',
  },
  slidesLabel: {
    marginRight: '0.75rem',
    marginBottom: 0,
    fontSize: '1rem',
    letterSpacing: '0.2rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    marginBottom: 0,
  },
  actionIcon: {
    opacity: 0.2,
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
  fullWidth: {
    width: '100%',
  },
  headerTextContainer: {
    maxWidth: '100%',
    fontSize: '1rem',
    letterSpacing: '0.2rem',
  },
  redirectIcon: {
    opacity: 0.4,
    marginLeft: '0.25rem',
    cursor: 'pointer',
  },
  leftSection: {
    maxWidth: '60%',
    width: 'calc(100% - 10rem)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
