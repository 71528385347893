import { Button, Box } from '@mui/material';
import { DeleteIcon } from 'images/icons/DeleteIcon';
import React, { useState } from 'react';
import { SubmitActionTypes } from 'components/TeamOverview/constants';
import { ReviewContributeStepContent } from 'components/TeamOverview/components/ConfirmActionModal/ReviewContributeStepContent';
import { ReviewDeleteStepContent } from 'components/TeamOverview/components/ConfirmActionModal/ReviewDeleteStepContent';
import {
  calculateTotalDataValue,
  getSelectedSlidesCount,
  navigateToContactUs,
} from 'components/TeamOverview/utilities';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import { CommonReviewBox } from 'components/TeamOverview/components/ConfirmActionModal/CommonReviewBox';
import { ApprovalDelegationIcon } from 'images/icons/ApprovalDelegationIcon';

const ReviewStepContents = {
  [SubmitActionTypes.CONTRIBUTE_ACTION]: ReviewContributeStepContent,
  [SubmitActionTypes.ARCHIVE_ACTION]: ReviewDeleteStepContent,
};

export const ReviewActionStep = ({
  classes,
  isSigned,
  selectedRows,
  selectedRowsSlides,
  handleSubmit,
  handleClose,
  handlePrevStep,
  handleOpenTermsAndConditionsModal,
  actionType,
}) => {
  const userDetails = useSelector(userDetailsSelector);

  const [isFirstAgreementChecked, setIsFirstAgreementChecked] = useState(false);
  const [isSecondAgreementChecked, setIsSecondAgreementChecked] =
    useState(false);

  const projectsCount = selectedRows.length;

  const selectedSlidesSize = calculateTotalDataValue(selectedRows);

  const selectedSlidesCount = getSelectedSlidesCount(
    selectedRows,
    selectedRowsSlides,
  );

  const ReviewStepContent = ReviewStepContents[actionType];
  const isContributingAction =
    actionType === SubmitActionTypes.CONTRIBUTE_ACTION;
  const isArchivingAction = actionType === SubmitActionTypes.ARCHIVE_ACTION;
  const warningText = `YOU’RE ${
    isContributingAction ? 'CONTRIBUTING' : 'DELETING'
  } ${selectedSlidesCount} SLIDES FROM ${projectsCount} PROJECTS`;
  const warningIcon = isContributingAction
    ? ApprovalDelegationIcon
    : DeleteIcon;

  const isSubmitDisabled =
    !isFirstAgreementChecked ||
    (isArchivingAction && !isSecondAgreementChecked) ||
    !isSigned;

  return (
    <Box>
      <Box className={classes.alertBox}>
        <CommonReviewBox
          Icon={warningIcon}
          text={warningText}
          weight={selectedSlidesSize}
        />
      </Box>
      <ReviewStepContent
        classes={classes}
        isSigned={isSigned}
        isFirstAgreementChecked={isFirstAgreementChecked}
        setIsFirstAgreementChecked={setIsFirstAgreementChecked}
        isSecondAgreementChecked={isSecondAgreementChecked}
        setIsSecondAgreementChecked={setIsSecondAgreementChecked}
        handleOpenTermsAndConditionsModal={handleOpenTermsAndConditionsModal}
      />
      <Box className={classes.middleButtonsSection}>
        <Button
          variant="outlined"
          color="pink"
          className={classes.bigButton}
          onClick={handleClose}
        >
          cancel action
        </Button>
        <Button
          marginLeft="2rem"
          variant="contained"
          color="pink"
          className={`${classes.bigButton} ${classes.confirmButton}`}
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          confirm {actionType}
        </Button>
      </Box>
      <Box className={classes.bottomButtonsSection}>
        <Button
          variant="outlined"
          color="pink"
          className={classes.smallButton}
          onClick={() => navigateToContactUs(userDetails)}
        >
          contact support
        </Button>
        <Button
          className={classes.smallButton}
          onClick={handlePrevStep}
          variant="outlined"
          color="pink"
        >
          back
        </Button>
      </Box>
    </Box>
  );
};
