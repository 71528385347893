import { useEffect, useState } from 'react';
import OpenSeadragon from 'openseadragon';
import { AT2_SLIDE_SCANNER_NAME } from 'components/ImageViewer/constants';

export const GT450_SCANNER_FILTERS_SETTINGS = {
  gamma: OpenSeadragon.Filters.GAMMA(0.5),
};

export const useViewerFilters = (
  viewerRef,
  isGT450ScannerFiltersEnabled,
  slide,
) => {
  const [usedFilters, setUsedFilters] = useState({});
  const isAT2Slide = slide?.scanner_type === AT2_SLIDE_SCANNER_NAME;

  // removing filters
  useEffect(() => {
    if (!isGT450ScannerFiltersEnabled && isAT2Slide) {
      setUsedFilters((prevState) => {
        const newFilters = Object.entries(prevState).reduce(
          (filters, filter) => ({
            ...filters,
            ...(!(filter[0] in GT450_SCANNER_FILTERS_SETTINGS) && {
              [filter[0]]: filter[1],
            }),
          }),
          {},
        );

        return newFilters;
      });
    }
  }, [isGT450ScannerFiltersEnabled, isAT2Slide]);

  // setting filters
  useEffect(() => {
    if (isGT450ScannerFiltersEnabled && isAT2Slide) {
      setUsedFilters((prevState) => ({
        ...prevState,
        ...GT450_SCANNER_FILTERS_SETTINGS,
      }));
    }
  }, [isGT450ScannerFiltersEnabled, isAT2Slide]);

  useEffect(() => {
    const filters = Object.values(usedFilters);

    viewerRef.current?.setFilterOptions({
      filters: {
        processors: filters,
      },
    });
  }, [usedFilters]);
};
