import {
  EXTERNAL_COMMENT_INPUT_WARNING,
  INTERNAL_COMMENT_INPUT_WARNING,
} from 'components/PathologyMap/Modals/FullSlideInfoModal/constants';
import React, { Fragment, useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { ADD_SLIDE_COMMENT_ERROR_MESSAGE } from 'constants/errorMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useAddSlideCommentStyles } from './styles';
import { useCreateSlideCommentMutation } from 'store/apis/slideCommentsApi';

export const AddSlideComment = ({
  slide,
  slideComments,
  setSlideComments,
  handleAddComment,
  isStaff,
}) => {
  const { classes } = useAddSlideCommentStyles();
  const [newSlideCommentText, setNewSlideCommentText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { showError } = useSnackbar();
  const [isExternalComment, setIsExternalComment] = useState(false);
  const [createSlideCommentMutation] = useCreateSlideCommentMutation();

  const staffPlaceholder = isExternalComment
    ? EXTERNAL_COMMENT_INPUT_WARNING
    : INTERNAL_COMMENT_INPUT_WARNING;
  const isExternalStaff = isStaff && isExternalComment;
  const isInternalStaff = isStaff && !isExternalComment;

  if (!slide) {
    return null;
  }

  const handleChange = (event) => {
    setNewSlideCommentText(event.target.value);
  };

  const submitSlideComment = () => {
    setIsLoading(true);

    createSlideCommentMutation({
      slideUUID: slide.uuid,
      text: newSlideCommentText,
      isInternal: !!isInternalStaff,
    })
      .unwrap()
      .then((response) => {
        if (response) {
          const updatedSlideComments = [response, ...slideComments];
          setSlideComments(updatedSlideComments);
          setNewSlideCommentText('');

          if (handleAddComment) {
            handleAddComment(updatedSlideComments);
          }
        }
      })
      .catch(() => showError(ADD_SLIDE_COMMENT_ERROR_MESSAGE))
      .finally(() => setIsLoading(false));
  };

  const label = `${isStaff ? staffPlaceholder : ''}Leave a comment here...`;

  return (
    <Fragment>
      <TextField
        placeholder={label}
        fullWidth
        variant="outlined"
        multiline
        value={newSlideCommentText}
        onChange={handleChange}
        className={`${classes.newSlideComment} ${
          isExternalStaff ? classes.commentRedPlaceholder : ''
        }`}
        rows={3}
      />
      <Button
        className={classes.submit}
        variant="contained"
        color="primary"
        // if no rowData, can't add anything
        disabled={!newSlideCommentText || isLoading}
        onClick={submitSlideComment}
      >
        Submit
      </Button>
      {isStaff && (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              value={isExternalComment}
              onChange={({ target }) => setIsExternalComment(target.checked)}
            />
          }
          label="External Comment"
        />
      )}
    </Fragment>
  );
};
