import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  betaWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  betaLabel: {
    backgroundColor: 'green',
    color: 'white',
    fontSize: '0.5rem',
    borderRadius: '8px',
    padding: '0 0.2rem',
    marginBottom: '0.05rem',
  },
});

export const BetaIcon = ({ children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.betaWrapper}>
      <div className={classes.betaLabel}>beta</div>
      {children}
    </div>
  );
};
