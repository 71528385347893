import {
  GOOGLE_MAPS_AUTOCOMPLETE_SEARCH_RESOURCE_NAME,
  GOOGLE_MAPS_DETAILS_PLACE_NAME,
  GOOGLE_MAPS_LAT_LONG_DETAILS_NAME,
} from 'services/resources/apiConstants';
import { backendAPI } from 'services/backendAPI';

export const getCurrentLocation = (lat, lng) => {
  // test coordinates for 199 Burgin Parkway, Quincy MA
  // const xlat = "42.249560";
  // const xlng = "-71.004820";

  const googleMapsURL = `${process.env.REACT_APP_API_URL}/${GOOGLE_MAPS_LAT_LONG_DETAILS_NAME}`;
  const serializedURL = googleMapsURL.replace(':lat', lat).replace(':lng', lng);
  return backendAPI.get(serializedURL);
};

export const getPlacesBySubstring = (placeToSearch) => {
  const googleMapsURL = `${process.env.REACT_APP_API_URL}/${GOOGLE_MAPS_AUTOCOMPLETE_SEARCH_RESOURCE_NAME}`;
  const serializedURL = googleMapsURL.replace(':address', placeToSearch);
  return backendAPI.get(serializedURL);
};

export const getPlaceById = (placeId) => {
  const googleMapsURL = `${process.env.REACT_APP_API_URL}/${GOOGLE_MAPS_DETAILS_PLACE_NAME}`;
  const serializedURL = googleMapsURL.replace(':placeID', placeId);
  return backendAPI.get(serializedURL);
};
