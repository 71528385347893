import { MODAL_WITHOUT_BACKGROUND } from 'components/SlideGroups/utilities/styles';
import { DefaultModal } from 'components/Modals/DefaultModal';
import Box from '@mui/material/Box';
import { useExportHaloAnnotationsConfirmationModalStyles } from 'components/OrderSlidesList/styles';
import { useSelector } from 'react-redux';
import { annotationsSelector } from 'store/slices/annotationsSlice';
import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { ExportAnnotationRow } from 'components/OrderSlidesList/components/ExportAnnotationRow';
import { downloadOrderAnnotationsInHaloFormat } from 'utilities/download';

export const ExportHaloAnnotationsConfirmationModal = memo(
  ({ open, onClose, slides }) => {
    const { classes } = useExportHaloAnnotationsConfirmationModalStyles();
    const annotations = useSelector(annotationsSelector);
    const [checkboxes, setCheckboxes] = useState({});
    const { showError } = useSnackbar();

    useEffect(() => {
      if (!annotations) return;

      const initialCheckboxes = annotations.reduce((acc, annotation) => {
        return { ...acc, [annotation.slideUUID]: !!annotation.data?.length };
      }, {});

      setCheckboxes(initialCheckboxes);
    }, [annotations]);

    if (!slides) return null;

    const blockedCheckBoxes = annotations.reduce((acc, annotation) => {
      return { ...acc, [annotation.slideUUID]: !annotation.data.length };
    }, {});

    const uuidsForExport = Object.keys(checkboxes).filter(
      (slideUUID) => !!checkboxes[slideUUID],
    );

    const handleExport = () => {
      if (!uuidsForExport) return;

      downloadOrderAnnotationsInHaloFormat(uuidsForExport)
        .then(onClose)
        .catch(() => showError(''));
    };

    return (
      <DefaultModal
        open={open}
        onClose={onClose}
        width={'600px'}
        styles={MODAL_WITHOUT_BACKGROUND}
      >
        <Box className={classes.modalBackground}>
          <Box>
            <Typography className={classes.title}>
              Export Annotations
            </Typography>
            <Grid container>
              <Grid item container>
                <Grid item xs={10} className={classes.cell} fontWeight={500}>
                  Slide Name
                </Grid>
                <Grid item xs={2} className={classes.cell} fontWeight={500}>
                  Annotations
                </Grid>
              </Grid>
              <Box className={classes.container}>
                {slides.map((slide) => (
                  <ExportAnnotationRow
                    key={slide.uuid}
                    classes={classes}
                    slide={slide}
                    annotations={annotations}
                    checkboxes={checkboxes}
                    blockedCheckBoxes={blockedCheckBoxes}
                    setCheckboxes={setCheckboxes}
                  />
                ))}
              </Box>
            </Grid>
          </Box>
          <Button
            onClick={handleExport}
            variant="contained"
            color="primary"
            disabled={!uuidsForExport.length}
          >
            Export annotations
          </Button>
        </Box>
      </DefaultModal>
    );
  },
);
