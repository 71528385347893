import { makeStyles } from 'tss-react/mui';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import { PATHOLOGY_MAP_SECTION_HEADER_BG_COLOR } from 'components/PathologyMap/constants/styles';
import { CAPTION_GREY, WHITE } from 'utilities/colors';

export const useCommentsContainerStyles = makeStyles()(() => ({
  commentsLabel: { fontWeight: 600 },
  tabs: { margin: 0 },
  icon: {
    height: '1rem',
    position: 'relative',
    svg: { marginBottom: 0, height: '100%' },
    span: {
      alignItems: 'center',
      backgroundColor: '#ff6770',
      borderRadius: '50%',
      color: WHITE,
      display: 'flex',
      fontSize: '0.5rem',
      fontWeight: 600,
      height: '0.75rem',
      justifyContent: 'center',
      position: 'absolute',
      right: '-2px',
      top: '-4px',
      width: '0.75rem',
    },
  },
}));

export const useUserCommentsListStyles = makeStyles()(() => ({
  commentContainer: {
    padding: '0.5rem',
    borderBottom: `1px solid ${CAPTION_GREY}66`,
    '&:nth-of-type(even)': { backgroundColor: '#F2F7F9' },
    '&:last-of-type': { borderBottom: 'none' },
  },
  commentCaption: {
    fontFamily: 'monospace',
    fontSize: '0.8rem',
    color: CAPTION_GREY,
  },
  commentText: {
    fontFamily: 'monospace',
    fontSize: '1rem',
    color: CAPTION_GREY,
  },
  staffUserCommentsList: {
    height: 'calc(100% - 15rem)',
    overflowY: 'auto',
    ...VERTICAL_SCROLL_STYLE,
  },
  nonStaffUserCommentsList: {
    height: 'calc(100% - 12rem)',
    overflowY: 'auto',
    ...VERTICAL_SCROLL_STYLE,
  },
  stepper: {
    height: '3rem',
  },
  step: {
    height: '2rem',
  },
  noSlidesText: {
    textAlign: 'center',
    marginTop: '1.5rem',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '1rem',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    cursor: 'pointer',
    color: CAPTION_GREY,
    padding: '1rem 0',
    position: 'relative',
    top: '1px',
  },
  deleteButton: {
    cursor: 'pointer',
    color: 'red',
    opacity: '60%',
  },
}));

export const useAddSlidesToSlideGroupModalStyles = makeStyles()((theme) => ({
  modalBackground: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0.25rem',
    transition: 'opacity 880ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  modalRoot: {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
}));

export const useNewCardStyles = makeStyles()(() => ({
  slideCard: {
    backgroundColor: '#ffffff4c',
    borderRadius: '4px',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    alignItems: 'center',
    borderRadius: '4px',
    background: PATHOLOGY_MAP_SECTION_HEADER_BG_COLOR,
    display: 'flex',
    minHeight: '60px',
    padding: '0.5rem 0',
    boxShadow: '0 0 4px #14141440',
  },
}));

export const useSlideInfoSectionStyles = makeStyles()(() => ({
  slideGroupVerticalContainer: {
    height: 'calc(100% - 4.5rem)',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    ...VERTICAL_SCROLL_STYLE,
  },
  slideInfoTabContent: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    ...VERTICAL_SCROLL_STYLE,
  },
  label: {
    lineHeight: 2,
    fontSize: '0.75em',
    cursor: 'pointer',
  },
  sortsContainer: {
    paddingBottom: 0,
  },
  createGroupButton: {
    width: 'calc(100% - 1rem)',
    marginInline: 'auto',
  },
  actionItem: {
    borderBottom: '1px solid #757575',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  editButton: {
    margin: '5px 0',
    marginLeft: 'auto',
    fontSize: '0.75em',
    padding: '3px 10px',
  },
}));

export const useSlideInfoTabsStyles = makeStyles()(() => ({
  tabs: {
    height: '48px',
    minHeight: '48px',
    margin: '0 auto',
  },
  indicator: {
    display: 'none',
  },
  selectedTab: {
    backgroundColor: `${WHITE}99`,
    border: '2px solid #8897F3',
    borderRadius: '4px',
  },
  tabIcon: {
    marginBottom: '0 !important',
  },
}));

export const useSlideViewerSectionStyles = makeStyles()(() => ({
  breadcrumbTextClass: {
    display: 'inline',
    align: 'center',
    fontSize: '1.25rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.1rem',
  },
  breadcrumbContainerClass: {
    width: '100%',
    cursor: 'default',
  },
  breadcrumbPathTextClass: {
    display: 'inline',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    paddingBottom: '0.3rem',
  },
  measurementButton: {
    height: '3rem',
    width: '3rem',
    marginRight: '1rem',
  },
  slideName: {
    cursor: 'pointer',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    paddingInline: '1rem',
    paddingTop: '1rem',
  },
}));

export const useCommentInputStyles = makeStyles()(() => ({
  submitButton: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '10rem',
  },
  input: {
    padding: '0.5rem',
    color: 'hsl(0,0%,20%)',
    fontSize: '0.9rem',
    width: '100%',
    display: 'block',
    borderRadius: '0.25rem',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    backgroundColor: '#f8f9fa',
    resize: 'none',
    outline: 'none',
  },
}));
