import { makeStyles } from 'tss-react/mui';
import { SECONDARY_BLUE_3, TABS_BLUE } from 'utilities/colors';

export const useStyles = makeStyles()({
  root: {
    display: 'grid',
  },
  centered: {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tabs: {
    '.Mui-selected': {
      color: TABS_BLUE,
    },
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  button: {
    margin: '5px 0',
    marginLeft: 'auto',
    fontSize: '0.75rem',
    padding: '3px 10px',
  },
  viewSimilarSlidesLinkBox: {
    cursor: 'pointer',
    marginBlock: '1rem',
    marginLeft: '1rem',
    width: 'max-content',
    border: `1px solid ${SECONDARY_BLUE_3}`,
    borderRadius: '0.25rem',
    padding: '0.5rem 5rem 0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
  },
  viewSimilarSlidesLink: {
    fontSize: '0.75rem',
    color: SECONDARY_BLUE_3,
    textTransform: 'uppercase',
    textDecoration: 'underline',
    marginRight: '0.375rem',
    lineHeight: 0.75,
  },
  linkIcon: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: '0.6',
  },
});
