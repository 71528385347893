import { Typography, Box } from '@mui/material';
import { transformDataUsage } from 'components/UserProfile/utilities';
import { MB } from 'components/UserProfile/constants';
import React from 'react';
import { useCommonReviewBoxStyles } from 'components/TeamOverview/components/ConfirmActionModal/styles';

export const CommonReviewBox = ({ Icon, text, weight, showWeight = true }) => {
  const { classes } = useCommonReviewBoxStyles();

  return (
    <Box className={classes.commonReview}>
      <Icon />
      <Typography marginLeft="0.75rem" className={classes.warningText}>
        {text}
      </Typography>
      {showWeight && (
        <Typography marginLeft="auto" className={classes.warningText}>
          {weight > 0 ? transformDataUsage(weight, MB) : '0 MB'}
        </Typography>
      )}
    </Box>
  );
};
