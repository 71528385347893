import { useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import FileCopy from '@mui/icons-material/FileCopyRounded';
import ShareIcon from '@mui/icons-material/Share';
import CodeIcon from '@mui/icons-material/Code';
import {
  useGetItemShareRequest,
  useShareClipboard,
  useShareError,
} from 'components/Share/helpers/hooks';
import { ShareSlideModal } from 'components/Modals/ShareSlideModal/ShareSlideModal';
import { useStyles2 } from 'components/Share/styles';
import { ShareEmbedSlide } from 'components/Modals/ShareSlideModal/ShareEmbedSlide';

export const ShareV2 = ({ item, itemName, mutation, copyId }) => {
  const { classes } = useStyles2();
  const [isOpenSocial, setIsOpenSocial] = useState(false);
  const [isOpenEmbed, setIsOpenEmbed] = useState(false);

  const isSlideSharing = itemName.includes('Slide');

  const handleCopy = useShareClipboard(item, itemName);

  const { share_enabled, uuid } = item;

  const [isAllowed, setIsAllowed] = useState(share_enabled);

  const shareFunction = useGetItemShareRequest(!isAllowed, uuid, itemName);

  const showError = useShareError(itemName);

  const openShareSocialModal = () => {
    setIsOpenSocial(true);
  };

  const openShareEmbedModal = () => {
    setIsOpenEmbed(true);
  };

  const closeShareSocialModal = () => {
    setIsOpenSocial(false);
  };

  const closeShareEmbedModal = () => {
    setIsOpenEmbed(false);
  };

  const toggleShareHandler = () =>
    shareFunction()
      .then(() => {
        setIsAllowed((prev) => !prev);
      })
      .catch(showError);

  useEffect(() => {
    setIsAllowed(share_enabled);
  }, [share_enabled]);

  return (
    <>
      <ShareSlideModal
        isOpen={isOpenSocial}
        onClose={closeShareSocialModal}
        item={item}
      />

      <ShareEmbedSlide
        isOpen={isOpenEmbed}
        onClose={closeShareEmbedModal}
        item={item}
      />

      <Button
        variant="contained"
        color={isAllowed ? 'secondary' : 'default'}
        fullWidth
        onClick={mutation ?? toggleShareHandler}
        className={classes.shareButton}
      >
        Share {itemName}: {isAllowed ? 'On' : 'Off'}
      </Button>

      {isAllowed && (
        <ButtonGroup
          className={classes.buttonGroup}
          variant="contained"
          color={isAllowed ? 'secondary' : 'default'}
        >
          <Button
            fullWidth
            variant="text"
            disabled
            className={classes.shareLabel}
          >
            Share {itemName}
          </Button>
          <Button id={copyId} onClick={handleCopy}>
            <FileCopy className={classes.shareIcon} />
          </Button>
          {isSlideSharing && (
            <>
              <Button id={copyId} onClick={openShareSocialModal}>
                <ShareIcon className={classes.shareIcon} />
              </Button>
              <Button id={copyId} onClick={openShareEmbedModal}>
                <CodeIcon className={classes.shareIcon} />
              </Button>
            </>
          )}
        </ButtonGroup>
      )}
    </>
  );
};
