import { Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { SLIDE_VIEWER_URL } from 'constants/urls';
import { Link } from 'react-router-dom';

export const RenderSlideThumbnailInLayoutGrid = ({ slide, width = 100 }) => {
  let widthString = '';
  if (isNaN(width)) {
    widthString = width;
  } else {
    widthString = `${width}px`;
  }

  const slideViewerURL = SLIDE_VIEWER_URL.replace(':slideUUID', slide.uuid);

  return (
    <Fragment key={slide.uuid}>
      <Grid item xl={2} md={3} sm={3} xs={4}>
        <Link to={slideViewerURL} target={'_blank'}>
          <img
            src={slide.medium_macro_url}
            width={widthString}
            alt={'Macro Slide'}
          />
        </Link>
      </Grid>
    </Fragment>
  );
};
export const RenderSlideThumbnails = ({ slides, width = 150 }) => {
  if (!slides) {
    return null;
  }

  return (
    <Grid
      container
      spacing={0}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {slides.map((slide) => {
        return (
          <RenderSlideThumbnailInLayoutGrid
            key={slide.uuid}
            slide={slide}
            width={width}
          />
        );
      })}
    </Grid>
  );
};
