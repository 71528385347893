import React from 'react';
import Button from '@mui/material/Button';
import shallow from 'zustand/shallow';

import {
  useImageSettingsStore,
  useViewerStore,
} from 'components/IFViewer/state';

export const SlideNavigatorToggle = () => {
  const [isOverviewOn, toggleIsOverviewOn] = useImageSettingsStore(
    (store) => [store.isOverviewOn, store.toggleIsOverviewOn],
    shallow,
  );
  const [isViewerLoading, useLinkedView, use3d] = useViewerStore(
    (store) => [store.isViewerLoading, store.useLinkedView, store.use3d],
    shallow,
  );
  return (
    <Button
      id={`if-${isOverviewOn ? 'hide' : 'show'}-navigator`}
      disabled={isViewerLoading || useLinkedView || use3d}
      onClick={toggleIsOverviewOn}
      variant="outlined"
      size="small"
      fullWidth
    >
      {isOverviewOn ? 'Hide' : 'Show'} Navigator
    </Button>
  );
};
