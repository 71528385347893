import React, { useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Tooltip, Typography } from '@mui/material';

const styles = () => ({
  cellWithoutCheckBox: {
    marginRight: '1rem',
    color: 'red',
  },
  alignToLeftContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  slideName: {
    fontSize: '0.75rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const useStyles = makeStyles()(styles);

export const SlideNameCell = (params) => {
  const { value } = params;
  const { classes } = useStyles();

  const wrapperRef = useRef(null);
  const valueRef = useRef(null);

  const centeredContainerWidth = wrapperRef?.current?.offsetWidth;
  const cellContentWidth = valueRef?.current?.offsetWidth;
  return (
    <Tooltip
      title={cellContentWidth >= centeredContainerWidth ? value : ''}
      placement="left"
    >
      <div ref={wrapperRef} className={classes.alignToLeftContainer}>
        {value !== undefined ? (
          <Typography className={classes.slideName} ref={valueRef}>
            {value}
          </Typography>
        ) : (
          <div className={classes.cellWithoutCheckBox}>Grouped</div>
        )}
      </div>
    </Tooltip>
  );
};
