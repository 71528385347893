import React, { Fragment, useContext, useState } from 'react';
import { AppContext } from 'services/context';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { DefaultDialog } from 'components/Modals/DefaultDialog';
import Box from '@mui/material/Box';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import Checkbox from '@mui/material/Checkbox';
import { IHCOptimizationSlideSelectionOpenModalName } from 'components/Modals/constants';
import { RenderSlideThumbnailInLayoutGrid } from 'components/IHCOptimizationViewer/RenderSlideThumbnails';
import { useSelector } from 'react-redux';
import { currentUserEmailSelector } from 'store/slices/userDetailsSlice';
import { useSlideSelectionModalStyles } from './styles';
import {
  usePostNotifyClientOrderCommentMutation,
  usePostOrderCommentWithSlidesMutation,
} from 'store/apis/orderApi';
import { POST_ORDER_COMMENT_ERROR_MESSAGE } from 'constants/errorMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

const modalContextName = IHCOptimizationSlideSelectionOpenModalName;
const optimizationDilutionConstant = 'optimizationDilutionConstant';
const commentConstant = 'commentConstant';

export const SlideSelectionModal = ({
  order,
  orderUUID,
  selectedRows,
  onPostOrderCommentSuccess,
}) => {
  const { classes } = useSlideSelectionModalStyles();
  const context = useContext(AppContext);
  const email = useSelector(currentUserEmailSelector);
  const { showError } = useSnackbar();
  const { handleContextModalChange } = context;

  // set order comment that will be used with the slides selected
  const [textComment, setTextComment] = useState('');
  const [optimizationDilution, setOptimizationDilution] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendUpdateToClient, setSendUpdateToClient] = useState(false);

  const [postOrderCommentWithSlides] = usePostOrderCommentWithSlidesMutation();
  const [postNotifyClientOrderComment] =
    usePostNotifyClientOrderCommentMutation();

  const slideCount = selectedRows ? selectedRows.length : 0;

  const handleTextChange = (name) => (event) => {
    if (name === optimizationDilutionConstant) {
      setOptimizationDilution(event.target.value);
    } else if (name === commentConstant) {
      setTextComment(event.target.value);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    const slideUUIDs = selectedRows.map((row) => {
      return row.uuid;
    });

    let postParams = {};
    postParams['slide_uuids'] = slideUUIDs;
    postParams['text'] = textComment;
    postParams['optimization_dilution'] = optimizationDilution;

    postOrderCommentWithSlides({ orderUUID, postParams })
      .unwrap()
      .then((orderComment) => {
        // if checked off, sends a simple email to the client about IHC Optimizations
        if (sendUpdateToClient) {
          const postParams = {
            order_comment_uuids: [orderComment.uuid],
          };
          postNotifyClientOrderComment({ orderUUID, postParams });
        }
      })
      .catch(() => showError(POST_ORDER_COMMENT_ERROR_MESSAGE))
      .finally(() => {
        handleContextModalChange(modalContextName)();
        onPostOrderCommentSuccess();
        setIsSubmitting(false);
      });
  };

  const handleCheckboxChange = (event) => {
    setSendUpdateToClient(event.target.checked);
  };

  const modalActions = () => {
    const submitLabel = `Add Order Comment (${slideCount})`;

    return (
      <Typography align={'right'} className={classes.buttonTypography}>
        <span>
          <Button
            color="primary"
            onClick={handleContextModalChange(modalContextName)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            <SubmittingIcon submitting={isSubmitting} size={10} />
            {submitLabel}
          </Button>
        </span>
      </Typography>
    );
  };

  const getContentGrid = () => {
    const clientEmail = order?.submitted_user?.email;

    return (
      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        className={classes.grid}
      >
        <Grid item xs={8} className={classes.grid}>
          <Box
            height={'95%'}
            width={'95%'}
            p={0}
            m={0}
            className={classes.grid}
          >
            <Grid
              container
              spacing={0}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {selectedRows.map((slide) => {
                return (
                  <RenderSlideThumbnailInLayoutGrid
                    key={slide.uuid}
                    slide={slide}
                  />
                );
              })}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1} justifyContent="space-around">
            <Grid item xs={12}>
              <Typography
                color={'inherit'}
                className={classes.reviewTextLabel}
                variant={'h5'}
              >
                IHC Optimization by {email}
              </Typography>
              <TextField
                id="standard-multiline-flexible"
                label="Optimization Dilution"
                className={classes.externalTextField}
                placeholder=""
                fullWidth={true}
                multiline
                onChange={handleTextChange(optimizationDilutionConstant)}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Add Order Comment to Slide(s)"
                className={classes.externalTextField}
                placeholder=""
                fullWidth={true}
                multiline
                onChange={handleTextChange(commentConstant)}
              />
              <Typography
                color={'inherit'}
                variant={'body2'}
                display={'inline'}
                className={classes.questionText}
              >
                <Checkbox
                  checked={sendUpdateToClient}
                  onChange={handleCheckboxChange}
                  value="sendUpdateToClient"
                />
                Email {clientEmail} with selected slides ({slideCount}).
                Warning: Immediate.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderModal = () => {
    const isOpen = context[modalContextName];
    const onClose = handleContextModalChange(modalContextName);

    if (!selectedRows) {
      return null;
    }

    if (!isOpen) {
      return null;
    }

    const dialogTitle = `IHC Optimization Slides Selected (${slideCount})`;

    return (
      <DefaultDialog
        open={isOpen}
        onClose={onClose}
        actions={modalActions}
        title={dialogTitle}
      >
        <Box height={'50vh'} width={'70vw'}>
          {getContentGrid()}
        </Box>
      </DefaultDialog>
    );
  };

  return <Fragment>{renderModal()}</Fragment>;
};
