import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React from 'react';
import Checkbox from '@mui/material/Checkbox';

export const LegendRow = ({ name, color, visibility, toggleIsOn, classes }) => {
  return (
    <Box className={classes.channelInfo}>
      <Typography className={classes.channelName}>{name}</Typography>
      <Checkbox
        classes={{
          root: classes.checkboxRoot,
        }}
        style={{ color, maginLeft: '0.5rem', '&$checked': { color } }}
        size="small"
        checked={visibility}
        onChange={toggleIsOn}
      />
    </Box>
  );
};
