import { makeStyles } from 'tss-react/mui';
import {
  PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR,
  PATHOLOGY_MAP_FILTERS_SEARCH_BG_COLOR,
} from 'components/PathologyMap/constants/styles';
import { PRIMARY_WHITE } from 'utilities/colors';

export const useAddPreviousSamplesModalStyles = makeStyles()(() => ({
  rightAlign: {
    float: 'right',
    marginTop: '1rem',
    marginBottom: '.25rem',
  },
  boldText: {
    fontWeight: 'bold',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '5rem',
  },
  box: { display: 'flex', flexDirection: 'column' },
  previousSamplesContainer: { width: '65rem', height: '35rem' },
  previousSamplesGrid: { height: '26.6rem', width: '100%' },
  filterTextBox: {
    width: '63rem',
    padding: '0.25rem 1rem 0.25rem 1rem',
    borderRadius: '3px',
    background: PATHOLOGY_MAP_FILTERS_SEARCH_BG_COLOR,
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR}`,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background:
      'linear-gradient(180deg, rgba(108, 162, 187, 0.45), rgba(108, 162, 187, 0.10))',
    padding: '1rem',
    border: `0.2rem solid ${PRIMARY_WHITE}`,
  },
  samplesCount: {
    width: '2rem',
    height: '1.5rem',
    fontWeight: 'bold',
    background: 'white',
    borderRadius: '3px',
    margin: '0 0.5rem 0 0.5rem',
    color: '#6CA2BB',
  },
}));

export const modalStyle = {
  maxHeight: '35rem',
  overflowY: 'auto',
  padding: 0,
  borderRadius: '0.3rem',
  opacity: 0.93,
};
