import { FavoriteSlideIconButton } from 'components/utilities/FavoriteSlide/FavoriteSlideIconButton';
import {
  AntibodyAltNamesFormatter,
  AntibodyApplicationsFormatter,
  CloneNumberNATextFormatter,
  CreatedYearMonthDateFormatter,
  formatGLPStatus,
  HiddenOrganNameFormatter,
  IsTumorGetter,
  IsTumorValueGetterFromOrder,
  ModifiedDateFormatter,
  OrderDueDateFormatter,
  OrderNameFormatter,
  OrderNameGetter,
  OrganNameFormatter,
  OverallQCScoreGetter,
  ProjectNameFormatter,
  QCFailedReasonsCountGetter,
  ReactiveSpeciesFormatter,
  ResearchAreaFormatter,
  SampleNameFormatter,
  SampleNameRequiredCellStyler,
  SimpleValueNameGetterV3,
  SingleSpeciesFormatter,
  SlideCreatedDateFormatter,
  SpeciesNameFormatter,
  StainNameFormatter,
  TrueFalseFormatter,
  ValidatedSpeciesFormatter,
} from 'components/utilities/gridDataSerializers';

import { IS_TUMOR_FIELD_NAME } from 'components/OrderForm/constants';
import {
  ML_QC_BLURRY_SCORE_CONSTANT,
  ML_QC_COVERSLIP_SCORE_CONSTANT,
  ML_QC_DIRTY_SCORE_CONSTANT,
  ML_QC_FOLDS_TEARS_SCORE_CONSTANT,
  ML_QC_MICROVIBRATIONS_SCORE_CONSTANT,
  ML_TISSUE_CRACKING_SCORE_CONSTANT,
  ML_TISSUE_SEPARATION_SCORE_CONSTANT,
} from 'components/QCViewer/constants';
import { SlideNameCell } from 'components/utilities/components/SlideNameCell';

export const getSlideColumnDefs = () => [
  {
    headerName: 'Slide Name',
    field: 'name',
    cellRenderer: SlideNameCell,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    checkboxSelection: (params) => {
      return !!params.data;
    },
  },
  {
    headerName: 'Favorite',
    cellRenderer: ({ value }) => <FavoriteSlideIconButton slideUUID={value} />,
    field: 'uuid',
    width: 100,
  },
  {
    headerName: 'Sample',
    field: 'sample',
    valueGetter: SampleNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Stain Type',
    field: 'stainType',
    valueGetter: StainNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Stain',
    field: 'stain',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Species',
    field: 'species',
    valueGetter: SpeciesNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: OrganNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Uploaded Name',
    field: 'uploaded_name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Is Tumor',
    valueGetter: IsTumorValueGetterFromOrder,
    field: IS_TUMOR_FIELD_NAME,
    width: 100,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Level',
    field: 'level',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Number',
    field: 'number',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  // be a little bit careful changing the header name, fesariustherapeutics does depend on this
  // when they try to parse the CSV and download it into their database
  {
    headerName: 'Slide UUID',
    field: 'uuid',
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
  },
];

export const IHCOptimizationSlideColumnDefs = [
  {
    headerName: 'Slide Name',
    field: 'name',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: false,
    checkboxSelection: true,
    floatingFilter: true,
  },
  {
    headerName: 'Opt. Dilution',
    field: 'optimization_dilution',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: false,
    checkboxSelection: false,
    floatingFilter: true,
    width: 125,
  },
  {
    headerName: 'Sample',
    field: 'sample',
    valueGetter: SampleNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    width: 150,
  },
  {
    headerName: 'Stain',
    field: 'stain',
    enableRowGroup: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'TAT Date',
    field: 'order',
    valueGetter: OrderDueDateFormatter,
    enableRowGroup: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Created',
    field: 'created',
    valueGetter: SlideCreatedDateFormatter,
    enableRowGroup: true,
    floatingFilter: false,
    filter: 'agTextColumnFilter',
    width: 100,
  },

  {
    headerName: 'Species',
    field: 'species',
    valueGetter: SpeciesNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: OrganNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
];

export const GroupOrdersColumnDefs = [
  {
    headerName: 'Order Name',
    field: 'orderName',
    filter: 'agTextColumnFilter',
    rowGroup: true,
    hide: true,
    checkboxSelection: true,
  },
];
export const QCSlideColumnDefs = [
  {
    headerName: 'Order',
    field: 'order',
    valueGetter: OrderNameGetter,
    valueFormatter: OrderNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
    checkboxSelection: true,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: OrganNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },

  {
    headerName: 'Stain',
    field: 'stain',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Stain Type',
    field: 'stainType',
    valueGetter: StainNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },

  {
    headerName: 'Sample',
    field: 'sample',
    valueGetter: SampleNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'TAT Date',
    field: 'order',
    valueGetter: OrderDueDateFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Slide Name',
    field: 'name',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: false,

    // cellStyle: { 'white-space': 'normal' }
  },
  {
    headerName: 'Created',
    field: 'created',
    valueGetter: SlideCreatedDateFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Fail Issue Count',
    width: 100,
    valueGetter: QCFailedReasonsCountGetter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Is GLP',
    field: 'order',
    valueFormatter: formatGLPStatus,
    width: 125,
  },
  {
    headerName: 'QC Scores',
    width: 100,
    children: [
      {
        headerName: 'QC Overall Score',
        field: 'ml_qc_overall_score',
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        valueGetter: OverallQCScoreGetter,
        width: 100,
      },
      {
        headerName: 'QC Blurry Score',
        field: ML_QC_BLURRY_SCORE_CONSTANT,
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        width: 100,
        columnGroupShow: 'open',
      },
      {
        headerName: 'QC Folds Score',
        field: ML_QC_FOLDS_TEARS_SCORE_CONSTANT,
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        width: 100,
        columnGroupShow: 'open',
      },
      {
        headerName: 'QC Dirty Score',
        field: ML_QC_DIRTY_SCORE_CONSTANT,
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        width: 100,
        columnGroupShow: 'open',
      },
      {
        headerName: 'QC Coverslip Score',
        field: ML_QC_COVERSLIP_SCORE_CONSTANT,
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        width: 100,
        columnGroupShow: 'open',
      },
      {
        headerName: 'QC Tissue Cracking Score',
        field: ML_TISSUE_CRACKING_SCORE_CONSTANT,
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        width: 100,
        columnGroupShow: 'open',
      },
      {
        headerName: 'QC Tissue Separation Score',
        field: ML_TISSUE_SEPARATION_SCORE_CONSTANT,
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        width: 100,
        columnGroupShow: 'open',
      },
      {
        headerName: 'QC Microvibrations Score',
        field: ML_QC_MICROVIBRATIONS_SCORE_CONSTANT,
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        width: 100,
        columnGroupShow: 'open',
      },
    ],
  },
  {
    headerName: 'Species',
    field: 'species',
    valueGetter: SpeciesNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Uploaded Name',
    field: 'uploaded_name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Level',
    field: 'level',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Number',
    field: 'number',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Organization',
    field: 'organization_name',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 200,
  },
];

export const ResetQCSlideColumnDefs = [
  {
    headerName: 'Slide Name',
    field: 'name',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: false,
    checkboxSelection: true,
  },
  {
    headerName: 'Order',
    field: 'order',
    valueGetter: OrderNameGetter,
    valueFormatter: OrderNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Passed QC Time',
    field: 'passed_qc_datetime',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Failed QC Time',
    field: 'failed_qc_datetime',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Failed QC Reason',
    field: 'failed_qc_reason',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'TAT Date',
    field: 'order',
    valueGetter: OrderDueDateFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Stain Type',
    field: 'stainType',
    valueGetter: StainNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Created',
    field: 'created',
    valueGetter: SlideCreatedDateFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Sample',
    field: 'sample',
    valueGetter: SampleNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Stain',
    field: 'stain',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Species',
    field: 'species',
    valueGetter: SpeciesNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: OrganNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Uploaded Name',
    field: 'uploaded_name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Level',
    field: 'level',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Number',
    field: 'number',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
];
export const AllSlideListColumnDefs = [
  {
    headerName: 'Slide Name',
    field: 'name',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    checkboxSelection: true,
  },
  {
    headerName: 'Order',
    field: 'order',
    valueGetter: OrderNameGetter,
    valueFormatter: OrderNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Project',
    field: 'project',
    valueGetter: ProjectNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Sample',
    field: 'sample',
    valueGetter: SampleNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Level',
    field: 'level',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Number',
    field: 'number',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Stain Type',
    field: 'stainType',
    valueGetter: StainNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Stain',
    field: 'stain',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Species',
    field: 'species',
    valueGetter: SpeciesNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: OrganNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Is Tumor',
    field: 'is_tumor',
    valueGetter: IsTumorValueGetterFromOrder,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Uploaded Name',
    field: 'uploaded_name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
];

export const getSlideAdminColumnDefs = (handleMakeFavorite = undefined) => [
  {
    headerName: 'Slide Name',
    field: 'name',
    cellRenderer: SlideNameCell,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    checkboxSelection: (params) => {
      return !!params.data;
    },
  },
  {
    headerName: 'Favorite',
    cellRenderer: ({ value }) => <FavoriteSlideIconButton slideUUID={value} />,
    cellRendererParams: {
      handleMakeFavorite,
    },
    field: 'is_starred',
    width: 100,
  },
  {
    headerName: 'Is Public',
    field: 'is_public',
    valueGetter: TrueFalseFormatter('is_public'),
    enableRowGroup: true,
    width: 100,
  },
  // {
  //   headerName: "Is Uploaded",
  //   field: "uploaded",
  //   valueGetter: IsUploadedFormatter,
  //   enableRowGroup: true,
  //   filter: "agTextColumnFilter",
  //   width: 100
  // },
  {
    headerName: 'Needs QC',
    field: 'needs_qc',
    valueGetter: TrueFalseFormatter('needs_qc'),
    enableRowGroup: true,
    width: 100,
  },
  {
    headerName: 'Passed QC Time',
    field: 'passed_qc_datetime',
    enableRowGroup: false,
    width: 100,
  },
  {
    headerName: 'Failed QC Time',
    field: 'failed_qc_datetime',
    enableRowGroup: false,
    width: 100,
  },
  {
    headerName: 'Failed QC Reason',
    field: 'failed_qc_reason',
    enableRowGroup: true,
    width: 100,
  },

  {
    headerName: 'Sample',
    field: 'sample',
    valueGetter: SampleNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Level',
    field: 'level',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Number',
    field: 'number',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Stain Type',
    field: 'stainType',
    valueGetter: StainNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Stain',
    field: 'stain',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Species',
    field: 'species',
    valueGetter: SpeciesNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: OrganNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Uploaded Name',
    field: 'uploaded_name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  // be a little bit careful changing the header name, fesariustherapeutics does depend on this
  // when they try to parse the CSV and download it into their database
  {
    headerName: 'Slide UUID',
    field: 'uuid',
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
  },
  {
    headerName: 'Scanner Type',
    field: 'scanner_type',
    filter: 'agTextColumnFilter',
  },
];
export const NotUploadedSlideColumnDefs = [
  //{
  //  headerName: "Order Name",
  //  field: "order_name",
  //  suppressSizeToFit: true
  //},
  {
    headerName: 'Uploaded Name',
    field: 'uploaded_name',
    enableRowGroup: true,
    sortable: true,
    resizable: true,
  },
  {
    headerName: 'Slide Version Number',
    field: 'number',
    enableRowGroup: true,
    sortable: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Sample Name',
    field: 'sample_name',
    enableRowGroup: true,
    sortable: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Level',
    field: 'level',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Number',
    field: 'number',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Stain Name',
    field: 'stain_name',
    enableRowGroup: true,
    sortable: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Stain Type',
    field: 'stain_type',
    enableRowGroup: true,
    sortable: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Version',
    field: 'version',
    enableRowGroup: true,
    sortable: true,
    suppressSizeToFit: true,
  },
];

export const toolTipValueGetterForGrouped = (params) => {
  const count = params.node && params.node.allChildrenCount;

  if (count != null) {
    return `${params.value} (${count})`;
  }

  return params.value;
};

export const AntibodyColumnDefs = [
  {
    headerName: 'Name',
    field: 'name',
    filter: 'agTextColumnFilter',
    cellRenderer: 'agGroupCellRenderer',
    cellStyle: (params) =>
      params.node.parent.rowIndex >= 0 && { marginLeft: '0px' },
    suppressSizeToFit: true,
    rowGroup: true,
    tooltipValueGetter: toolTipValueGetterForGrouped,
  },
  {
    headerName: 'Catalog Number',
    field: 'catalog_number',
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    tooltipField: 'catalog_number',
    headerTooltip: 'Catalog number',
  },
  {
    headerName: 'Applications',
    field: 'applications',
    valueGetter: AntibodyApplicationsFormatter,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    headerTooltip: 'Applications',
    tooltipValueGetter: AntibodyApplicationsFormatter,
  },
  {
    headerName: 'HistoWiz Validated Species',
    field: 'histowiz_validated_species',
    valueGetter: ValidatedSpeciesFormatter,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    tooltipValueGetter: ValidatedSpeciesFormatter,
    headerTooltip: 'HistoWiz Validated Species',
  },
  {
    headerName: 'Vendor Reactive Species',
    field: 'reactive_species',
    valueGetter: ReactiveSpeciesFormatter,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    tooltipValueGetter: ReactiveSpeciesFormatter,
    headerTooltip: 'Vendor Reactive Species',
  },
  {
    headerName: 'Research Area',
    field: 'research_area',
    filter: 'agTextColumnFilter',
    valueGetter: ResearchAreaFormatter,
    enableRowGroup: true,
    tooltipField: 'research_area',
    headerTooltip: 'Research Area',
  },
  {
    headerName: 'Host Species',
    field: 'host_species',
    valueGetter: SingleSpeciesFormatter,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    tooltipValueGetter: SingleSpeciesFormatter,
    headerTooltip: 'Host Species',
  },
  {
    headerName: 'Clonality',
    field: 'clonality',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    tooltipField: 'clonality',
    headerTooltip: 'Clonality',
  },
  {
    headerName: 'Clone Number',
    field: 'clone_number',
    filter: 'agTextColumnFilter',
    valueGetter: CloneNumberNATextFormatter,
    enableRowGroup: true,
    tooltipField: 'clone_number',
    headerTooltip: 'Clone Number',
  },
  {
    headerName: 'Alternative Names',
    field: 'alternate_catalog_names',
    valueGetter: AntibodyAltNamesFormatter,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    tooltipValueGetter: AntibodyAltNamesFormatter,
    headerTooltip: 'Alternative Names',
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: HiddenOrganNameFormatter,
    filter: 'agTextColumnFilter',
    hide: true,
    tooltipValueGetter: HiddenOrganNameFormatter,
    headerTooltip: 'Organ',
  },
  //{
  //  headerName: "Target",
  //  field: "target",
  //  filter: "agTextColumnFilter",
  //  enableRowGroup: true
  //},
  // {
  //   headerName: "Positive Control Tissue",
  //   field: "positive_control_tissue",
  //   enableRowGroup: true,
  //   filter: "agTextColumnFilter"
  // },
  //{
  //  headerName: "In Stock",
  //  field: "in_stock",
  //  // valueGetter: TrueFalseFormatter("in_stock"),
  //  // filter: "agTextColumnFilter",
  //  enableRowGroup: true
  //}
];

export const MultiplexColumnDefs = [
  {
    headerName: 'Name',
    field: 'name',
    cellRenderer: 'agGroupCellRenderer',
    enableRowGroup: true,
    suppressSizeToFit: true,
    rowGroup: true,
    cellStyle: (params) =>
      params.node.parent.rowIndex >= 0 && {
        marginLeft: '16px',
        width: '184px',
      },
  },
  {
    headerName: 'Catalog Number',
    field: 'catalog_number',
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
  },
  {
    headerName: 'Clone Number',
    field: 'clone_number',
    filter: 'agTextColumnFilter',
    valueGetter: CloneNumberNATextFormatter,
    enableRowGroup: true,
  },
  {
    headerName: 'Clonality',
    field: 'clonality',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Application',
    field: 'applications',
    valueGetter: AntibodyApplicationsFormatter,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
  },
];

export const SpecialStainsColumnDefs = [
  {
    headerName: 'Name',
    field: 'name',
    cellRenderer: 'agGroupCellRenderer',
    // suppressSizeToFit: true,
    resizable: true,
  },
];

export const IFPanelColumnDefs = [
  {
    headerName: 'Name',
    field: 'name',
    cellRenderer: 'agGroupCellRenderer',
    enableRowGroup: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Description',
    field: 'description',
    enableRowGroup: true,
  },
];

export const validatedSlidesColumnDefs = [
  {
    headerName: 'Species',
    field: 'sample.species.name',
    width: 200,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Organ',
    field: 'sample.organ.name',
  },
];

export const ihcAdminColumnDefs = AntibodyColumnDefs.slice();

export const IFSlideColumnsDefs = [
  {
    headerName: 'Slide Name',
    field: 'name',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
  },
  {
    headerName: 'Sample',
    field: 'sample',
    valueGetter: SampleNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Species',
    field: 'species',
    valueGetter: SpeciesNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: OrganNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Uploaded Name',
    field: 'uploaded_name',
    width: 300,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'UUID',
    field: 'uuid',
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    width: 250,
  },
  {
    headerName: 'Created',
    field: 'created',
    //suppressSizeToFit: true,
    width: 250,
  },
];

export const IFSlideAdminColumnsDefs = [
  {
    headerName: 'Slide Name',
    field: 'name',
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
  },
  {
    headerName: 'Is Public',
    field: 'is_public',
    valueGetter: TrueFalseFormatter('is_public'),
    enableRowGroup: true,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Sample',
    field: 'sample',
    valueGetter: SampleNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Species',
    field: 'species',
    valueGetter: SpeciesNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: OrganNameFormatter,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    width: 125,
  },
  {
    headerName: 'Uploaded Name',
    field: 'uploaded_name',
    width: 300,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'UUID',
    field: 'uuid',
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    width: 250,
  },
  {
    headerName: 'Created',
    field: 'created',
    //suppressSizeToFit: true,
    width: 250,
  },
];

export const BlockSampleColumnsDefs = [
  {
    headerName: 'Sample Name',
    field: 'name',
    filter: 'agTextColumnFilter',
    enableRowGroup: false,
    checkboxSelection: true,
    suppressSizeToFit: true,
    cellStyle: SampleNameRequiredCellStyler,
    editable: true,
    pinned: 'left',
    minWidth: 125,
  },
  {
    headerName: 'Order ID',
    field: 'order.id',
    editable: false,
    filter: 'agNumberColumnFilter',
    floatFilter: true,
    width: 90,
    minWidth: 90,
  },
  {
    headerName: 'Submitted In',
    filter: 'agTextColumnFilter',
    field: 'submission',
    valueGetter: SimpleValueNameGetterV3,
    //cellStyle: RequiredCellStyler,
    enableRowGroup: true,
    editable: false,
    width: 125,
    minWidth: 125,
    //cellEditor: "agRichSelectCellEditor",
    //cellEditorParams: {
    //  values: getNameFromData(sampleSubmissionData),
    //},
    //cellEditorPopup: true,
  },
  {
    headerName: 'Organ',
    field: 'organ',
    valueGetter: SimpleValueNameGetterV3,
    //cellStyle: RequiredCellStyler,
    enableRowGroup: true,
    editable: false,
    width: 100,
    minWidth: 100,
  },
  {
    headerName: 'Is Tumor',
    field: 'is_tumor',
    valueGetter: IsTumorGetter,
    enableRowGroup: true,
    width: 100,
    minWidth: 100,
  },
  {
    headerName: ' Control Type',
    field: 'control_type',
    filter: 'agTextColumnFilter',
    //cellStyle: RequiredCellStyler,
    enableRowGroup: true,
    editable: false,
    width: 125,
    minWidth: 125,
  },
  {
    headerName: 'Created',
    field: 'created',
    //suppressSizeToFit: true,
    width: 125,
    valueGetter: CreatedYearMonthDateFormatter,
    sortable: true,
  },
  {
    headerName: 'Modified',
    field: 'modified',
    sortable: true,
    unSortIcon: true,
    sort: 'desc',
    width: 125,
    valueGetter: ModifiedDateFormatter,
  },
];
