import React from 'react';
import { Typography, Box, Checkbox } from '@mui/material';

export const ReviewDeleteStepContent = ({
  classes,
  isFirstAgreementChecked,
  setIsFirstAgreementChecked,
  isSecondAgreementChecked,
  setIsSecondAgreementChecked,
}) => {
  return (
    <Box>
      <Typography className={classes.warning}>
        Warning: Archiving of Files
      </Typography>
      <Typography>
        You are about to archive the selected files. Rather than deleting,
        HistoWiz will place these items into archive. Ensure you have backed up
        or no longer need access to these files before proceeding.
      </Typography>
      <ul>
        <Typography component="li">
          The files will be archived at no cost and can be restored to full
          access at any time for a fee.
        </Typography>
        <Typography component="li">
          Data management tools will remain functional. Access to
          full-resolution images will be replaced with thumbnails only.
          Downloading will no longer be an option.
        </Typography>
      </ul>
      <Box marginTop={2} paddingLeft={1}>
        <Box>
          <Checkbox
            size="medium"
            checked={isFirstAgreementChecked}
            onChange={() => setIsFirstAgreementChecked((prev) => !prev)}
          />
          <Typography component="span" className={classes.agreementText}>
            I confirm that my data has been backed up or is no longer needed and
            I would like to remove access to this data from the PathologyMap
            platform.
          </Typography>
        </Box>
        <Box>
          <Checkbox
            size="medium"
            checked={isSecondAgreementChecked}
            onChange={() => setIsSecondAgreementChecked((prev) => !prev)}
          />
          <Typography component="span" className={classes.agreementText}>
            I understand that although archiving is not a permanent action,
            returning archived data to full access will incur a per slide
            retrieval fee.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
