import { makeStyles } from 'tss-react/mui';

export const useAiModelsStyles = makeStyles()((theme) => ({
  wrapper: {
    maxWidth: '1440px',
    margin: '0 auto',
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '55% 40%',
    columnGap: '50px',
    justifyContent: 'space-between',
  },
  breadCrumbs: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.black.main,
    fontSize: '14px',
  },
  breadCrumbText: {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  spanDivider: {
    padding: '0 5px',
  },
}));

export const useAiModelSlidesStyles = makeStyles()(() => ({
  root: {
    paddingTop: '25px',
  },
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '24px',
  },
}));
