import React, { Fragment, useContext } from 'react';
import { AppContext } from 'services/context';
import { Button, Grid, Typography } from '@mui/material';
import { DefaultDialog } from 'components/Modals/DefaultDialog';
import Box from '@mui/material/Box';
import { useQCBulkResetModalStyles } from 'components/Modals/styles';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { isAnySlideFromGLPOrder } from 'utilities/slides';
import { QC_BULK_RESET_FORM_ID } from 'components/Modals/constants';
import { usePostResetBulkQCSlidesMutation } from 'store/apis/slidesApi';
import { BULK_RESET_QC_ERROR_MESSAGE } from 'constants/errorMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

const modalContextName = 'modalBulkResetQCOpen';

export const QCBulkResetModal = ({
  selectedRows,
  updateRecentlyChangedSlides,
}) => {
  const { classes } = useQCBulkResetModalStyles();
  const context = useContext(AppContext);
  const { handleContextModalChange } = context;
  const [postResetBulkQCSlides] = usePostResetBulkQCSlidesMutation();

  const { showError } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const isChangeReasonRequired = isAnySlideFromGLPOrder(selectedRows);
  const rowLength = selectedRows.length;

  const onSubmit = (data) => {
    const slideUUIDs = selectedRows.map((row) => {
      return row.uuid;
    });

    const postParams = {
      slide_uuids: slideUUIDs,
      change_reason: data['changeReason'],
    };

    postResetBulkQCSlides({ postParams })
      .unwrap()
      .then(() => {
        // turn off modals after success
        handleContextModalChange(modalContextName)();

        // just repoll the data after an update
        updateRecentlyChangedSlides();
      })
      .catch(() => showError(BULK_RESET_QC_ERROR_MESSAGE));
  };

  const modalActions = () => {
    const submitLabel = `RESET QC Slides (${rowLength})`;

    return (
      <Typography align={'right'} className={classes.buttonTypography}>
        <span>
          <Button
            color="primary"
            onClick={handleContextModalChange(modalContextName)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.passedQCButton}
            type={'submit'}
            form={QC_BULK_RESET_FORM_ID}
          >
            {submitLabel}
          </Button>
        </span>
      </Typography>
    );
  };

  const renderSlideThumbnail = (slide) => {
    return (
      <img
        src={slide.medium_macro_url}
        width={'150px'}
        alt={'Macro Slide'}
        className={classes.slideThumbnailImage}
      />
    );
  };

  const renderModal = () => {
    const isOpen = context[modalContextName];
    const onClose = handleContextModalChange(modalContextName);

    if (!(selectedRows && isOpen)) {
      return null;
    }

    const changeReasonInputRequiredParam =
      isChangeReasonRequired && 'Change Reason is required';

    return (
      <DefaultDialog
        open={isOpen}
        onClose={onClose}
        actions={modalActions}
        title={'Reset QC/Pass Fail'}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={QC_BULK_RESET_FORM_ID}>
          <Box height={'75vh'} width={'75vw'}>
            <Grid
              container
              spacing={1}
              alignItems="flex-start"
              className={classes.grid}
            >
              {selectedRows.map((slide) => (
                <Grid item xs={3} key={slide.uuid}>
                  {renderSlideThumbnail(slide)}
                </Grid>
              ))}

              <Grid item xs={12}>
                <TextField
                  label="Change Reason"
                  variant="outlined"
                  fullWidth
                  {...register('changeReason', {
                    required: changeReasonInputRequiredParam,
                  })}
                  margin="normal"
                  error={!!errors.changeReason}
                  helperText={
                    !!errors.changeReason && errors.changeReason.message
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </DefaultDialog>
    );
  };

  return <Fragment>{renderModal()}</Fragment>;
};
