import {
  TEAM_RESOURCE_NAME,
  USER_DETAILS_RESOURCE_NAME,
  USER_PREFERENCES_RESOURCE_NAME,
} from 'services/resources/apiConstants';
import {
  updatePreferences,
  updateTeamDetails,
  updateUserDetails,
} from 'store/slices/userDetailsSlice';
import {
  defaultActionAsyncHandler,
  forceStateUpdateOnQueryStarted,
} from 'store/utilities';
import { baseCreateApi } from 'store/apis/baseCreateApi';

export const userDetailsApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => ({
        url: USER_DETAILS_RESOURCE_NAME,
        method: 'GET',
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled },
          updateUserDetails,
        );
      },
      keepUnusedDataFor: 0,
    }),
    getTeamDetails: builder.query({
      query: () => ({
        url: TEAM_RESOURCE_NAME,
        method: 'GET',
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler(
          { dispatch, queryFulfilled },
          updateTeamDetails,
        );
      },
      keepUnusedDataFor: 0,
    }),
    updateUserPreferences: builder.mutation({
      query: ({ postParams, partial }) => ({
        url: USER_PREFERENCES_RESOURCE_NAME,
        method: partial ? 'PATCH' : 'POST',
        body: postParams,
      }),
      onQueryStarted(queryArgs, { dispatch }) {
        forceStateUpdateOnQueryStarted(
          dispatch,
          updatePreferences,
          queryArgs.postParams,
        );
      },
      transformResponse: (response) => response.preferences,
    }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  useGetTeamDetailsQuery,
  useUpdateUserPreferencesMutation,
} = userDetailsApi;
