import { useTitle } from 'components/utilities/hooks/useTitle';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Input,
  Paper,
  Typography,
} from '@mui/material';
import { loginAPIUser } from 'services/resources/auth';
import { Link, Redirect } from 'react-router-dom';
import {
  RESET_PASSWORD_URL,
  SIGNUP_URL,
  TEAM_OVERVIEW_URL,
} from 'constants/urls';
import { loginWithAuth0, isEmailValid } from 'utilities/auth';
import { DEMO_USER_EMAIL } from 'constants/emails';
import { AppContext } from 'services/context';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateIsStaff,
  userDetailsAuthTokenSelector,
} from 'store/slices/userDetailsSlice';
import { useLazyGetUserDetailsQuery } from 'store/apis/userDetailsApi';
import { KEYS } from 'constants/keyboard';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useLoginPageStyles } from './styles';
import {
  EMAIL_VALIDATION_MESSAGE,
  LOGIN_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { getLocalToken } from 'utilities/token';

export const LoginPage = ({ location, history, isDemo }) => {
  useTitle('HistoWiz - Login');
  const { classes } = useLoginPageStyles();
  const dispatch = useDispatch();
  const context = useContext(AppContext);
  const [getUserDetails] = useLazyGetUserDetailsQuery();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { showError } = useSnackbar();

  const jwtToken = useSelector(userDetailsAuthTokenSelector);
  const localToken = getLocalToken();

  useEffect(() => {
    const params = new URLSearchParams(location.hash);
    const error = params.get('#error');
    const errorDescription = params.get('error_description');
    if (error) {
      showError(`Error: ${error}\nDescription: ${errorDescription}`, '', {
        autoHideDuration: 10000,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(!!(location.pathname === '/' && location.hash));
  }, [location.pathname]);

  useEffect(() => {
    document.title = 'HistoWiz - Login';
    if (isDemo) {
      submitDemoLogin();
    }
    if (localToken || jwtToken) {
      history.push(TEAM_OVERVIEW_URL);
    }
  }, [jwtToken, localToken]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailError(false);
    setEmail(event.target.value);
  };

  const updateStaff = (value) => {
    dispatch(updateIsStaff(value));
  };

  const submitGuestSignin = () => {
    const postParams = {
      email: DEMO_USER_EMAIL,
      password: 'tree-swift-triangle',
    };

    loginAPIUser(postParams, updateStaff).then(() => {
      getUserDetails();
      context.updateIsLoggedIn();
      setRedirectToReferrer(true);
    });
  };

  const submitDemoLogin = () => {
    const postParams = {
      email: DEMO_USER_EMAIL,
      password: 'tree-swift-triangle',
    };

    loginAPIUser(postParams, updateStaff)
      .then(() => {
        getUserDetails();
        setRedirectToReferrer(true);
      })
      .catch((error) => {
        console.log(LOGIN_ERROR_MESSAGE);
        if (error.response) {
          console.log(error.response.data);
        }
      });
  };

  const submitSignin = () => {
    const isValidEmail = isEmailValid(email);
    if (!isValidEmail) {
      showError(EMAIL_VALIDATION_MESSAGE);
      setEmailError(true);
      return;
    }

    const postParams = {
      email,
      password,
    };

    loginAPIUser(postParams, updateStaff)
      .then(() => {
        getUserDetails();
        context.updateIsLoggedIn();

        setRedirectToReferrer(true);
      })
      .catch((error) => {
        let errorMessage,
          data = error.response?.data;
        if (data) {
          errorMessage = Object.values(data);
        }

        showError(errorMessage || LOGIN_ERROR_MESSAGE);
      });
  };

  const submitAuth0Signin = async () => {
    setIsLoading(true);
    try {
      await loginWithAuth0(email, password);
      getUserDetails();
    } catch (e) {
      showError(e.description);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === KEYS.ENTER) {
      submitAuth0Signin();
    }
  };

  const disabled = !(email && password) || emailError;
  const { from } = location.state || {
    from: { pathname: TEAM_OVERVIEW_URL },
  };

  if (redirectToReferrer === true) {
    return <Redirect to={from} />;
  }

  return (
    <Paper
      className={`${classes.paperContainer} ${
        isLoading ? classes.loadingState : {}
      }`}
    >
      {isLoading && <CircularProgress className={classes.spinner} />}
      <Grid
        className={classes.fullHeight}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={12} sm={9} md={8} lg={6} xl={6}>
          <Paper>
            <Paper className={classes.loginHeader}>
              <Typography align={'center'} variant={'h5'} color={'inherit'}>
                Login
              </Typography>
            </Paper>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <form className={classes.container} noValidate autoComplete="off">
                <Input
                  placeholder="Email ..."
                  className={classes.input}
                  value={email}
                  type="email"
                  onChange={handleEmailChange}
                  error={emailError}
                  autoComplete="username"
                  onKeyPress={handleKeyPress}
                />
                <Input
                  placeholder="Password ..."
                  className={classes.input}
                  value={password}
                  type="password"
                  id="standard-password-input"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  onKeyPress={handleKeyPress}
                />
                <div className={classes.signinButton}>
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Link to={SIGNUP_URL} className={classes.signUpLink}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={false}
                          disabled={isLoading}
                        >
                          Register?
                        </Button>
                      </Link>
                      <Link
                        to={RESET_PASSWORD_URL}
                        className={classes.resetPasswordLink}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth={false}
                          disabled={isLoading}
                        >
                          Forgot Pass?
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth={false}
                        onClick={submitGuestSignin}
                        disabled={isLoading}
                      >
                        Guest Access
                      </Button>
                      <Button
                        className={classes.signInRightButton}
                        variant="contained"
                        color="primary"
                        fullWidth={false}
                        disabled={disabled || isLoading}
                        onClick={submitSignin}
                      >
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};
