import React, { Fragment } from 'react';
import { primaryTeamSelector } from 'store/slices/userDetailsSlice';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

const GroupEmail = ({ label, email }) => {
  const emailHref = `mailto:${email}`;
  return (
    <Typography variant={'body1'}>
      {label} <a href={emailHref}>{email}</a>
    </Typography>
  );
};

const DedicatedAccountRep = ({ accountRep }) => {
  if (!accountRep) {
    return null;
  }

  const name = `${accountRep.first_name} ${accountRep.last_name}`;

  return (
    <Fragment>
      <Typography
        variant={'h3'}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        Your Dedicated Team Account Representative
      </Typography>
      <GroupEmail label={name} email={accountRep.email} />
      <Typography variant={'body1'} style={{ marginBottom: '1rem' }}>
        Phone: 347-221-1020
      </Typography>
    </Fragment>
  );
};

export const ContactView = () => {
  const primaryTeam = useSelector(primaryTeamSelector);
  const accountRep = primaryTeam?.account_rep;

  return (
    <div>
      <DedicatedAccountRep accountRep={accountRep} />
      <Typography variant={'h4'}>Contact Information</Typography>
      <Typography variant={'body1'} style={{ marginTop: '1rem' }}>
        Business Hours: Monday–Friday, 9am–5pm
      </Typography>
      <Typography variant={'body1'} style={{ marginBottom: '1rem' }}>
        Phone: 347-221-1020
      </Typography>
      <GroupEmail label={'General Inqurires'} email={'info@histowiz.com'} />
      <GroupEmail
        label={'Customer Service'}
        email={'getsupport@histowiz.com'}
      />
      <GroupEmail
        label={'Accounts Receivable'}
        email={'billing@histowiz.com'}
      />
      <GroupEmail label={'Accounts Payable'} email={'histowizinc@bill.com'} />
    </div>
  );
};
