import React, { memo } from 'react';
import { useOrderHistory } from 'components/OrderDetails/Tabs/OrderProgressTab/hooks';
import { ProgressStepper } from 'components/OrderDetails/Tabs/OrderProgressTab/ProgressStepper';
import { ORDER_CANCELLED_TRACKER_STATE } from 'components/OrdersList/constants';
import {
  prepareSteppers,
  prepareSteppersForCancelledOrder,
} from 'components/OrderDetails/Tabs/OrderProgressTab/utilities';
import { CardHeader } from 'components/UserProfile/components/CardHeader';
import { Typography } from '@mui/material';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import Box from '@mui/material/Box';
import { useOrderTrackingCardStyles } from 'components/UserProfile/styles';

export const OrderTrackingCard = memo(({ order }) => {
  const { classes } = useOrderTrackingCardStyles();

  const { orderHistory } = useOrderHistory(order);

  if (!order) return null;

  const isOrderCancelled =
    order.tracker_state === ORDER_CANCELLED_TRACKER_STATE;

  const steppers = isOrderCancelled
    ? prepareSteppersForCancelledOrder(order, orderHistory)
    : prepareSteppers(order, orderHistory);

  return (
    <Card
      cardClass={classes.card}
      header={
        <Box padding="1.05rem">
          <CardHeader
            title={order.state_label.toUpperCase()}
            subtitle={'ORDER TRACKING: ' + order.id}
          />
        </Box>
      }
    >
      <div>
        {isOrderCancelled ? (
          <Typography mt={4} mb={4} align="center" variant="h6">
            This order has been cancelled
          </Typography>
        ) : (
          steppers.map((stepper) => (
            <ProgressStepper key={stepper.name} stepper={stepper} />
          ))
        )}
      </div>
    </Card>
  );
});
