import {
  ANTIBODIES_ATTACHMENTS_RESOURCE_NAME,
  ANTIBODIES_RESOURCE_NAME,
  BILLING_CONTACTS_RESOURCE_NAME,
  DROPBOXES_RESOURCE_NAME,
  MULTIPLEX_ANTIBODIES_RESOURCE_NAME,
  NEWSFEED_RESOURCE_NAME,
  ORDER_ANTIBODIES_RESOURCE_NAME,
  ORDER_FIXATIVES_RESOURCE_NAME,
  ORDERS_FILE_ATTACHMENTS,
  ORDERS_PO_UPLOAD_ATTACHMENT,
  ORDERS_QUOTE_ATTACHMENT,
  ORDERS_SAMPLE_SCIENCE_INFO_ATTACHMENT,
  ORGAN_RESOURCE_NAME,
  ORGANIZATION_RESOURCE_NAME,
  PANELS_RESOURCE_NAME,
  PATHOLOGY_MAP_REQUESTED_SLIDE_INFO_RESOURCE_NAME,
  PROJECTS_RESOURCE_NAME,
  SAMPLE_SUBMISSIONS_RESOURCE_NAME,
  SIMPLE_PANELS_RESOURCE_NAME,
  SPECIAL_INSTRUCTIONS_ATTACHMENTS_RESOURCE_NAME,
  SPECIAL_STAINS_RESOURCE_NAME,
  SPECIES_RESOURCE_NAME,
  TEAM_ADDRESSES_RESOURCE_NAME,
  TEAM_RESOURCE_NAME,
  USER_DETAILS_RESOURCE_NAME,
  USER_PREFERENCES_RESOURCE_NAME,
  TEAM_NEWSFEED_RESOURCE_NAME,
  TEAM_MEMBERS_RESOURCE_NAME,
  ORDER_RESOURCE_NAME,
  TEAM_WALLET_TRANSACTIONS_NAME,
  WALLETS,
  ML_MODELS,
} from 'services/resources/apiConstants';
import {
  backendAPI,
  backendServiceDecorator,
  UnauthenticatedAPI,
} from 'services/backendAPI';

export const getAPIUserDetails = () => {
  return getGenericResource(USER_DETAILS_RESOURCE_NAME);
};

export const updateCurrentUserPreferences = (postParams) => {
  return backendAPI.post(USER_PREFERENCES_RESOURCE_NAME, postParams);
};

export const updateAPIUserDetails = (postParams) => {
  return backendAPI.post(USER_DETAILS_RESOURCE_NAME, postParams);
};

export const getOrganizations = () => {
  return UnauthenticatedAPI.get(ORGANIZATION_RESOURCE_NAME).then(
    (response) => response.data,
  );
};

export const createOrganization = (postParams) => {
  return UnauthenticatedAPI.post(ORGANIZATION_RESOURCE_NAME, postParams).then(
    (response) => response.data,
  );
};

export const getProjects = () => {
  return backendAPI
    .get(PROJECTS_RESOURCE_NAME)
    .then((response) => response.data);
};

export const getTeams = () => {
  return backendAPI.get(TEAM_RESOURCE_NAME);
};

export const getTeamMembers = (teamUUID) => {
  const url = TEAM_MEMBERS_RESOURCE_NAME.replace(':teamUUID', teamUUID);

  return backendAPI.get(url).then((response) => response.data);
};

export const getTeamNewsfeed = (teamUUID) => {
  const url = TEAM_NEWSFEED_RESOURCE_NAME.replace(':teamUUID', teamUUID);

  return backendAPI.get(url).then((response) => response.data);
};

export const getUserNewsfeedActivity = () => {
  return backendAPI
    .get(NEWSFEED_RESOURCE_NAME)
    .then((response) => response.data);
};

export const createProject = (postParams) => {
  return backendAPI
    .post(PROJECTS_RESOURCE_NAME, postParams)
    .then((response) => response.data);
};

export const createFixative = (postParams) => {
  return backendAPI
    .post(ORDER_FIXATIVES_RESOURCE_NAME, postParams)
    .then((response) => response.data);
};

export const getOrgans = () => {
  return backendAPI
    .get(ORGAN_RESOURCE_NAME, { cacheEnabled: true })
    .then((response) => response.data);
};

export const getSpecies = () => {
  return backendAPI
    .get(SPECIES_RESOURCE_NAME, { cacheEnabled: true })
    .then((response) => response.data);
};

export const getSampleSubmissions = (order = undefined) => {
  const requestConfig = {
    params: {
      materials_at_histowiz: !!order?.materials_at_histowiz,
    },
    cacheEnabled: true,
  };

  return backendAPI
    .get(SAMPLE_SUBMISSIONS_RESOURCE_NAME, requestConfig)
    .then((response) => response.data);
};

export const getMultiplexAntibodies = backendServiceDecorator(
  (backendAPIService) => {
    return backendAPIService
      .get(MULTIPLEX_ANTIBODIES_RESOURCE_NAME)
      .then((response) => response.data);
  },
);

export const getAntibodies = backendServiceDecorator((backendAPIService) => {
  return backendAPIService
    .get(ANTIBODIES_RESOURCE_NAME)
    .then((response) => response.data);
});

export const getOrderAntibodies = (orderUUID) => {
  const url = ORDER_ANTIBODIES_RESOURCE_NAME.replace(':orderUUID', orderUUID);

  return backendAPI.get(url).then((response) => response.data);
};

export const postAntibody = (postParams) => {
  return backendAPI
    .post(ANTIBODIES_RESOURCE_NAME, postParams)
    .then((response) => response.data);
};

export const getSpecialInstructionsAttachments = () => {
  return backendAPI
    .get(SPECIAL_INSTRUCTIONS_ATTACHMENTS_RESOURCE_NAME)
    .then((response) => response.data);
};

export const uploadSpecialInstructionsAttachments = (postParams) => {
  return backendAPI.post(
    SPECIAL_INSTRUCTIONS_ATTACHMENTS_RESOURCE_NAME,
    postParams,
  );
};

export const uploadAntibodyAttachments = (postParams) => {
  return backendAPI.post(ANTIBODIES_ATTACHMENTS_RESOURCE_NAME, postParams);
};

export const uploadSampleScienceInfoAttachment = (postParams) => {
  const orderUUID = postParams.get('order_uuid');
  const url = ORDERS_SAMPLE_SCIENCE_INFO_ATTACHMENT.replace(
    ':orderUUID',
    orderUUID,
  );

  return backendAPI.post(url, postParams).then((response) => response.data);
};

export const uploadQuoteAttachment = (postParams) => {
  const orderUUID = postParams.get('order_uuid');
  const url = ORDERS_QUOTE_ATTACHMENT.replace(':orderUUID', orderUUID);

  return backendAPI.post(url, postParams).then((response) => response.data);
};

export const updatePOAttachment = (data) => {
  const orderUUID = data.get('order_uuid');

  const url = ORDERS_PO_UPLOAD_ATTACHMENT.replace(':orderUUID', orderUUID);

  return backendAPI.post(url, data);
};

export const uploadOrderFileAttachments = (postParams) => {
  const orderUUID = postParams.get('order_uuid');
  const url = ORDERS_FILE_ATTACHMENTS.replace(':orderUUID', orderUUID);

  return backendAPI.post(url, postParams).then((response) => response.data);
};

export const getOrderFileAttachments = (orderUUID) => {
  const url = ORDERS_FILE_ATTACHMENTS.replace(':orderUUID', orderUUID);

  return backendAPI.get(url).then((response) => response.data);
};

export const getSpecialStains = () => {
  return backendAPI
    .get(SPECIAL_STAINS_RESOURCE_NAME, { cacheEnabled: true })
    .then((response) => response.data);
};

export const getPanels = backendServiceDecorator((backendAPIService) => {
  return backendAPIService
    .get(PANELS_RESOURCE_NAME)
    .then((response) => response.data);
});

export const getSimplePanels = () => {
  return backendAPI.get(SIMPLE_PANELS_RESOURCE_NAME).then(({ data }) => data);
};

export const getSpecialStainsIHC = backendServiceDecorator(
  (backendAPIService) => {
    return backendAPIService
      .get(SPECIAL_STAINS_RESOURCE_NAME, { cacheEnabled: true })
      .then((response) => response.data);
  },
);

export const createPanel = (data) => {
  return backendAPI
    .post(PANELS_RESOURCE_NAME, data)
    .then((response) => response.data);
};

export const getBillingContacts = () => {
  return backendAPI
    .get(BILLING_CONTACTS_RESOURCE_NAME)
    .then((response) => response.data);
};

export const postBillingContact = (postParams) => {
  return backendAPI
    .post(BILLING_CONTACTS_RESOURCE_NAME, postParams)
    .then((response) => response.data);
};

export const getTeamAddresses = ({ cached } = { cached: false }) => {
  return backendAPI
    .get(TEAM_ADDRESSES_RESOURCE_NAME, { cacheEnabled: cached })
    .then((response) => response.data);
};

export const getTeamWalletTransactions = (teamWalletUUID) => {
  const url = TEAM_WALLET_TRANSACTIONS_NAME.replace(
    ':teamWalletUUID',
    teamWalletUUID,
  );

  return backendAPI.get(url).then((response) => response.data);
};

export const getTeamWallets = () => {
  return backendAPI.get(WALLETS).then((res) => res.data);
};

export const getMLModels = () => {
  return backendAPI.get(ML_MODELS).then((res) => res.data);
};

export const postTeamAddress = (postParams) => {
  return backendAPI
    .post(TEAM_ADDRESSES_RESOURCE_NAME, postParams)
    .then((response) => response.data);
};

export const getGenericResource = (url) => {
  return backendAPI.get(url).then((response) => response.data);
};

export const getCachedGenericResource = (url) => {
  return backendAPI
    .get(url, { cacheEnabled: true })
    .then((response) => response.data);
};

export const getDropboxes = () => {
  return getGenericResource(DROPBOXES_RESOURCE_NAME);
};

export const postBuyUnstainedSlide = ({ slide }) => {
  let url = PATHOLOGY_MAP_REQUESTED_SLIDE_INFO_RESOURCE_NAME;
  url = url.replace(':slideUUID', slide.uuid);

  return backendAPI.post(url).then((response) => response.data);
};

export const pullOrderBarcodeLabels = (orderUUID) => {
  const url = `${ORDER_RESOURCE_NAME}${orderUUID}/slide_names.conf`;
  return backendAPI.get(url).then((response) => response.data);
};
