import { cmsCreateApi } from 'store/apis/cmsCreateApi';
import { setAITools } from 'store/slices/cmsSlice';
import { cmsApiActionHandler } from 'store/utilities';

export const cmsApi = cmsCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getAIToolsContent: builder.query({
      query: () => ({
        url: 'app-ai-tools/',
        method: 'GET',
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        cmsApiActionHandler({ dispatch, queryFulfilled }, setAITools);
      },
    }),
    getAIToolById: builder.query({
      query: (id) => ({
        url: `app-ai-tools/?filters[toolId][$eq]=${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response?.data?.attributes?.results,
    }),
  }),
});

export const { useGetAIToolsContentQuery, useLazyGetAIToolByIdQuery } = cmsApi;
