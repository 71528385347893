import React from 'react';

import { OrdersListView } from 'components/OrdersList/OrdersListView';
import { MiniDrawerWithContext } from 'components/Layout/drawer';

export const OrdersListPage = () => {
  return (
    <MiniDrawerWithContext header={'Orders'}>
      <OrdersListView />
    </MiniDrawerWithContext>
  );
};
