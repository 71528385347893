import classNames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';

export const StepIcon = (props) => {
  const { active, completed, classes, number } = props;
  const isNumericMark = number && !active && !completed;

  return (
    <div
      className={classNames(classes.step, {
        [classes.activeStep]: active,
        [classes.completeStep]: (completed && !active) || (active && number),
        [classes.inactiveStep]: !active && !completed,
      })}
    >
      {isNumericMark && <div>{number}</div>}
      {active &&
        (number ? (
          <CheckIcon className={classes.checkMark} />
        ) : (
          <div className={classes.activeStepCenter} />
        ))}
      {completed && !active && <CheckIcon className={classes.checkMark} />}
    </div>
  );
};
