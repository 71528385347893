import { useState, useEffect } from 'react';
import { DEFAULT_DELAY } from 'utilities/hooks/useDebouncedValue/constants';

export const useDebouncedValue = (value, delay = DEFAULT_DELAY) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
};
