import React from 'react';
import { PRIMARY_PINK } from 'utilities/colors';

export const ApprovalDelegationIcon = ({ color = PRIMARY_PINK, size = 18 }) => {
  return (
    <svg width={size} height={size} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.62 4.04 9.57 6.07 7.55 4.04c-.15.13-.38.36-.61.59l-.42.41L9.57 8.1A581.2 581.2 0 0 0 14.64 3s-.22-.24-.49-.5l-.49-.5-2.04 2.04ZM1 12v3.95l4.3.01.02-1.05 5.03 1.4c4.4-1.37 5.69-1.78 5.71-1.8.04-.02.04-.04.03-.52-.01-.41-.02-.55-.06-.71a2.3 2.3 0 0 0-.23-.61 2 2 0 0 0-.96-.84c-.1-.05-.25-.1-.35-.13-.16-.04-.2-.04-1.07-.05H12.49a2.17 2.17 0 0 0-.13-.78 2.28 2.28 0 0 0-.5-.75 2.26 2.26 0 0 0-.47-.33 127.16 127.16 0 0 0-4.61-1.7c-.15-.03-.32-.03-2.97-.03H1V12Zm1.43 0v2.51l1.44.01V9.5H2.44v2.51Zm2.88-.53v1.97l5 1.36 4.25-1.33c.01-.01-.03-.07-.12-.16a.7.7 0 0 0-.26-.18c-.12-.05-.12-.05-2.13-.05-1.2 0-2.12 0-2.28-.02a5.16 5.16 0 0 1-.43-.05 27.77 27.77 0 0 1-2.07-.67l.18-.67.03-.1.17-.6c0-.03.1 0 .8.23.43.14.87.28.98.3a8.35 8.35 0 0 0 1.34.13l.28.02a.91.91 0 0 0-.08-.32.6.6 0 0 0-.28-.29c-.03 0-1-.36-2.13-.78L6.49 9.5H5.32v1.97Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
