import { makeStyles } from 'tss-react/mui';
import { FORM_TEXT_COLOR, LIGHT_GREY } from 'utilities/colors';

export const SLIDES_LIST_HEADER_HEIGHT = '104px';

export const useSlidesListViewV2Styles = makeStyles()((theme) => ({
  root: {
    height: `calc(100vh - ${SLIDES_LIST_HEADER_HEIGHT})`,
    display: 'flex',
    // somehow adding a margin left stops the OSD from shaking randomly
    marginLeft: '0.5rem',
  },
  formInputNoLeftMargin: {
    marginTop: '0.25rem',
    padding: '0.5rem',
    fontSize: '14px',
    width: '50%',
    display: 'inline-block',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    marginBottom: '0rem',
  },
  removableContainer: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
  },
  searchWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  slideTableWrapper: {
    margin: '0 -1.5rem',
    height: 'calc(100% - 0.5rem)',
    display: 'grid',
    gridTemplateRows: 'min-content auto min-content',

    [theme.breakpoints.down('xl')]: {
      margin: '0rem',
    },
  },
  exportButton: {
    marginRight: '1rem',

    [theme.breakpoints.down('xl')]: {
      fontSize: '0.5rem',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  expandButton: {
    minWidth: 'unset',

    '&>svg': {
      width: '1.25rem',
      height: '1.25rem',
    },

    [theme.breakpoints.down('xl')]: {
      padding: '0.2rem 0.2rem',

      '&>svg': {
        width: '1rem',
        height: '1rem',
      },
    },
  },
  slideListStyle: {
    width: '100%',
  },
  slideListOptions: {
    border: `1px solid ${LIGHT_GREY}`,
    borderBottom: 'none',
  },
  slideListHeader: {
    fontWeight: 500,

    '& span': {
      borderBottom: `1px solid ${FORM_TEXT_COLOR}`,
    },
  },
  toolbarItemWrapper: {},
}));
