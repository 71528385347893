import { Fade, Grid } from '@mui/material';
import { usePathologyMapSectionWrapperStyles } from './styles';

export const PathologyMapSectionWrapper = ({
  children,
  isVerticallyAdjustable = false,
}) => {
  const { classes } = usePathologyMapSectionWrapperStyles();

  return (
    <Fade in={true} appear={true} timeout={550}>
      <Grid
        container
        direction="column"
        className={
          isVerticallyAdjustable
            ? `${classes.container} ${classes.verticallyAdjustableContainer}`
            : classes.container
        }
      >
        {children}
      </Grid>
    </Fade>
  );
};
