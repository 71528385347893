import moment from 'moment';

export const MONTH_DAY_YEAR_FORMAT = 'MMMM Do YYYY'; // January 1st 2020
export const MONTH_DAY_YEAR_SHORT_FORMAT = 'MMMM D, YYYY'; // Jan 1 2020
export const MONTH_DAY_YEAR_WITH_COMMA_FORMAT = 'MMMM Do, YYYY'; // January 1st, 2020
export const SHORT_MONTH_DAY_YEAR_WITH_COMMA_FORMAT = 'MMM Do, YYYY'; // Jan 1st, 2020
export const FULL_DAY_MONTH_YEAR_WITH_COMMA_FORMAT = 'D MMMM, YYYY'; // 1 June, 2020
export const READABLE_DATE_TIME_FORMAT = 'dddd, MMMM Do YYYY, h:mm:ss a';
export const READABLE_TIME_FORMAT = 'h:mm:ss a';
export const DATE_REQUEST_FORMAT = 'YYYY-MM-DD';
export const DATA_MANAGEMENT_HISTORY_DATE_FORMAT = 'DD/MM/YYYY';
export const TERMS_AND_CONDITIONS_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_REQUEST_FORMAT_WITH_SLASH_DELIMITER = 'YYYY/MM/DD';
export const DATE_REVERSE_FORMAT_WITH_SLASH_DELIMITER = 'MM/DD/YYYY';
export const DATE_REQUEST_FORMAT_WITH_TIME = 'YYYY-MM-DD LT';
export const DATETIME_CREATED_FORMAT = 'l - h:mm:ss a';
export const MONTH_DATE_FORMAT = 'MM-DD';
export const TEXT_TIME_FORMAT = 'LT'; // 9:32 PM
export const DATE_WITH_TIME_TEXT_FORMAT = 'MM-DD-YYYY, LT';
export const DATE_WITH_TIME_AND_DASH_TEXT_FORMAT = 'MM/DD/YY — LT';

// 12/5/2016 Tu (this is what it looks like)
export const ABBREVIATED_CHART_DATE = 'l dd';

export const minutesToHours = (minutes, decimal_places = 2) => {
  if (minutes) {
    return (minutes / 60).toFixed(decimal_places);
  }
};

export const getCurrentDateFormatted = () => {
  return moment().format(DATE_REQUEST_FORMAT);
};

export const getDateFormattedWithTime = (date) => {
  return moment(date).format(DATE_REQUEST_FORMAT_WITH_TIME);
};

export const formatDatetimeString = (datetime) => {
  if (!datetime) {
    return;
  }

  return moment(datetime).format(READABLE_DATE_TIME_FORMAT);
};

export const formatDatetimeStringToDates = (
  datetime,
  format = MONTH_DAY_YEAR_FORMAT,
) => {
  if (!datetime) {
    return;
  }

  return moment(datetime).format(format);
};

export const MonthDateFormatString = (datetime) => {
  if (!datetime) {
    return '';
  }

  return moment(datetime).format(MONTH_DATE_FORMAT);
};

// Transform hyphens to another char to prevent wrapping
export const formatDatetimeStringForHistology = (datetime) => {
  let formattedDate = '-';
  try {
    formattedDate = moment(datetime)
      .format(DATE_REQUEST_FORMAT)
      .replaceAll('-', '-\u2060');
  } catch (e) {
    console.error('Error parsing histology dates', e, datetime);
  }

  return formattedDate;
};

export const getCurrentDateMinusMinutes = (minutes) => {
  const MILLISECONDS_IN_MINUTE = 60000;
  return new Date(Date.now() - minutes * MILLISECONDS_IN_MINUTE);
};

export const checkIfDateWithinNMinutes = (date, minutesPeriod) => {
  return date.getTime() >= getCurrentDateMinusMinutes(minutesPeriod).getTime();
};

export const putDateIntoOrderHistoryDescription = (
  description,
  dateString,
  isClientUploadingState,
) => {
  const formattedDate = formatDatetimeStringToDates(
    dateString,
    isClientUploadingState
      ? DATE_REQUEST_FORMAT_WITH_TIME
      : MONTH_DAY_YEAR_WITH_COMMA_FORMAT,
  );

  return description.replace(':date', formattedDate);
};

export const formatDateForChangeLog = (date) => {
  return moment(date).format(DATA_MANAGEMENT_HISTORY_DATE_FORMAT);
};
