import moment from 'moment';
import {
  DATE_WITH_TIME_AND_DASH_TEXT_FORMAT,
  DATETIME_CREATED_FORMAT,
} from 'utilities/dates_and_times';

export const serializeSlideComments = (slideComments) =>
  slideComments.reduce(
    (acc, comment) =>
      Object.assign(acc, {
        [comment.slide.uuid]: (acc[comment.slide.uuid] ?? []).concat(comment),
      }),
    {},
  );

export const formatSlideComponentUserTimestampLabel = ({ comment }) => {
  const { user, created, is_internal } = comment;
  const { first_name, last_name } = user;
  const name = `${first_name} ${last_name}`;
  const formattedTimestamp = moment(created).format(DATETIME_CREATED_FORMAT);
  const type = is_internal ? ' - Internal Comment' : '';
  return `${name} - ${formattedTimestamp}${type}`;
};

export const formatCommentLabelForSlideSearch = ({ comment }) => {
  const { user, created } = comment;
  const { first_name, last_name } = user;
  const name = `${first_name} ${last_name}`;
  const formattedTimestamp = moment(created).format(
    DATE_WITH_TIME_AND_DASH_TEXT_FORMAT,
  );
  return `${name} — ${formattedTimestamp}`;
};
