import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect } from 'react';
import { postTeamAddress } from 'services/resources/commonResources';
import { DefaultTeamAddressField } from 'components/Shared/TeamAddress/DefaultTeamAddressField';
import {
  addressesDefFieldsOptions,
  addressesValidationSchema,
  DEFAULT_SUBMIT_BUTTON_TEXT,
  DEFAULT_ADRESS_VALUES,
  SUCCESS_TEAM_ADDRESS_UPDATE_MESSAGE,
} from 'components/Shared/TeamAddress/constants';
import { DefaultSubmitButton } from 'components/Shared/Buttons/DefaultSubmitButton';
import { parseActualAddress } from 'utilities/general';
import _ from 'lodash';
import { Button, Grid } from '@mui/material';
import LocationOn from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import { isMobileOrTablet } from 'components/IFViewer/utils';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useCurrentLocation } from 'components/Shared/hooks';

export const TeamAddressForm = ({
  address = DEFAULT_ADRESS_VALUES,
  SubmitButton = DefaultSubmitButton,
  submitButtonText = DEFAULT_SUBMIT_BUTTON_TEXT,
  onAfterSuccessSubmit,
  teamUUID,
  addressesFieldsOptions = addressesDefFieldsOptions,
}) => {
  const methods = useForm({
    resolver: yupResolver(addressesValidationSchema),
    mode: 'onBlur',
  });

  const { handleSubmit, reset, setValue } = methods;
  const {
    detectCurrentLocation,
    currentLocation,
    setCurrentLocation,
    isLoading: isCurrentLocationLoading,
  } = useCurrentLocation();

  const { showSuccess, showError } = useSnackbar();

  useEffect(() => {
    reset({ ...address });
  }, [address]);

  const onSubmit = (data) => {
    const postParams = {
      team_uuid: address.team?.uuid || teamUUID,
      ...(!!address.uuid && { uuid: address.uuid }),
      ...data,
    };

    postTeamAddress(postParams)
      .then((response) => {
        showSuccess(SUCCESS_TEAM_ADDRESS_UPDATE_MESSAGE);

        if (onAfterSuccessSubmit) {
          onAfterSuccessSubmit(response);
        }
      })
      .catch((err) => {
        showError(err.message);
      });
  };

  const submitSelectedAddress = useCallback(
    (address) => {
      const actualUserAddress = parseActualAddress(address);
      setCurrentLocation({ ...actualUserAddress });
    },
    [setCurrentLocation],
  );

  //This useEffect hook fill address form with fetched from google api actual address values
  useEffect(() => {
    if (!_.isEmpty(currentLocation)) {
      currentLocation &&
        Object.keys(currentLocation).forEach((key) => {
          setValue(key, currentLocation[key], { shouldValidate: true });
        });
    }
  }, [currentLocation]);

  const renderAddressField = (addressFieldOption) => {
    // allows to provide your own component to render address field
    // you can define it in addressesDefFieldsOptions arrays
    const AddressFieldComponent =
      addressFieldOption.component ?? DefaultTeamAddressField;

    return (
      <AddressFieldComponent
        {...addressFieldOption}
        submitSelectedAddress={submitSelectedAddress}
        key={addressFieldOption.name}
      />
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {addressesFieldsOptions.map(renderAddressField)}
        <Grid container direction="row" justifyContent="space-between">
          <Box display="flex" justifyContent="flex-start">
            {!isMobileOrTablet() && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={false}
                  onClick={detectCurrentLocation}
                  disabled={isCurrentLocationLoading}
                >
                  <SubmittingIcon submitting={isCurrentLocationLoading} />
                  <LocationOn />
                  Detect Current Location
                </Button>
              </Box>
            )}
          </Box>
          <SubmitButton children={submitButtonText} />
        </Grid>
      </form>
    </FormProvider>
  );
};
