import { blockActions } from 'components/BlocksList/constants';
import { PLACE_ORDER_INFO_URL } from 'constants/urls';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useAddBlocksToOrderMutation,
  useCreateOrderWithBlocksMutation,
} from 'store/apis/teamsApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const useProcessingQueue = (selectedRows) => {
  const [processingQueue, setProcessingQueue] = useState([]);
  const [selectedSamples, setSelectedSamples] = useState(new Set());
  const [blocksModalOpen, setBlocksModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(blockActions.imageData);
  const history = useHistory();
  const [createNewOrder] = useCreateOrderWithBlocksMutation();
  const [addToOrder] = useAddBlocksToOrderMutation();
  const { showError } = useSnackbar();

  const handleBlockModalClose = () => {
    setBlocksModalOpen(false);
  };

  const handleBlockModalOpen = () => {
    setBlocksModalOpen(true);
  };

  const processingQueueMap = useMemo(
    () =>
      processingQueue.reduce((acc, block) => {
        acc[block.uuid] = block;
        return acc;
      }, {}),
    [processingQueue],
  );

  const handleAddToQueue = () => {
    const newSelectedSamples = new Set(selectedSamples);
    selectedRows.forEach((sample) => newSelectedSamples.add(sample.uuid));

    setProcessingQueue([...processingQueue, ...selectedRows]);
    setSelectedSamples(newSelectedSamples);
    setActiveTab(blockActions.processingQueue);
  };

  const checkRowSelectable = (node) =>
    !processingQueueMap[node?.data?.uuid] && node?.data?.atHistowiz;

  const getSelectedSamplesNames = () => {
    if (!processingQueueMap) return;

    return [...selectedSamples].map((uuid) => processingQueueMap[uuid]);
  };

  const handleCreateNewOrder = () => {
    createNewOrder({
      blocksUUIDs: [...selectedSamples],
    })
      .then((res) => {
        history.push(PLACE_ORDER_INFO_URL.replace(':orderUUID', res.data.uuid));
      })
      .catch(() => showError('Failed to create new order'));
  };

  const handleAddToNewOrder = (orderUUID) => {
    addToOrder({
      blocksUUIDs: [...selectedSamples],
      orderUUID,
    })
      .then((res) => {
        history.push(PLACE_ORDER_INFO_URL.replace(':orderUUID', res.data.uuid));
      })
      .catch(() => showError('Failed to add blocks to order'));
  };

  return {
    handleAddToQueue,
    checkRowSelectable,
    processingQueue,
    setProcessingQueue,
    handleCreateNewOrder,
    activeTab,
    setActiveTab,
    selectedSamples,
    setSelectedSamples,
    blocksModalOpen,
    handleBlockModalClose,
    getSelectedSamplesNames,
    handleAddToNewOrder,
    handleBlockModalOpen,
    processingQueueMap,
  };
};
