import { useComputeWalletCardStyles } from 'components/TeamOverview/components/ComputeWalletCard/styles';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import { Typography, Box } from '@mui/material';
import React from 'react';

export const ComputeWalletCard = ({ team }) => {
  const { classes } = useComputeWalletCardStyles();

  //Temporary commented
  // const [mlModels, setMlModels] = useState(null);
  // useEffect(() => {
  //   getMLModels().then((res) => setMlModels(res));
  // }, []);
  // const aiSpendMoney = mlModels?.reduce((accumulator, currentValue) => {
  //   const completedPayments = currentValue.slide_ml_processing_logs.filter(
  //     (log) =>
  //       log.state === COMPLETED_ML_PAYMENT_STATE ||
  //       log.state === PARTLY_ML_PAYMENT_STATE,
  //   );
  //   return (
  //     accumulator + completedPayments.length * currentValue.price_per_slide
  //   );
  // }, 0);
  // useEffect(() => {
  //   if (!team?.team_wallet) return;
  //
  //   // getTeamWalletTransactions(team.team_wallet.uuid).then((r) =>
  //   //   console.log(r),
  //   // );
  // }, [team]);

  return (
    <Card
      cardClass={classes.card}
      header={
        <Box className={classes.centeredContainer}>
          <Typography
            height="max-content"
            fontWeight={500}
            fontSize={'1.125rem'}
            letterSpacing="0.1em"
          >
            COMPUTE WALLET
          </Typography>
        </Box>
      }
    >
      <Box className={classes.contentContainer}>
        <Box className={classes.remainingAmountContainer}>
          <Typography className={classes.greyLabel}>
            REMAINING AMOUNT
          </Typography>
          <Typography className={classes.remainingAccountValue}>
            ${team?.team_wallet?.balance || 0}
          </Typography>
        </Box>
        <Box className={classes.centeredContainer} paddingX="1.5rem">
          <Typography className={classes.alert}>
            This compute wallet is not active. Invoices will be generated when
            transaction totals reach $200.00
          </Typography>
        </Box>
        {/*Temporary commented*/}
        {/*<Box className={classes.amountSpentContainer}>*/}
        {/*  <Typography className={classes.label}>AMOUNT SPENT</Typography>*/}
        {/*  <Box className={classes.verticallyCenteredRow}>*/}
        {/*    <Box className={classes.roundedIconContainer}>*/}
        {/*      <AIIcon />*/}
        {/*    </Box>*/}
        {/*    <Typography className={classes.rowLabel}>AI TOOL LIBRARY</Typography>*/}
        {/*    /!*<Typography className={classes.rowValue}>${aiSpendMoney}</Typography>*!/*/}
        {/*  </Box>*/}
        {/*</Box>*/}
      </Box>
    </Card>
  );
};
