import { BaseOrderDropdownSelections } from 'components/OrderForm/components/BaseSelectDropdowns';
import { PathologyExpertiseOptions } from 'components/OrderForm/constants';
import React from 'react';

export const PathologySpecialitiesOptionsDropdown = ({ name, isDisabled }) => {
  return (
    <BaseOrderDropdownSelections
      name={name}
      multi
      title="Specialities"
      options={PathologyExpertiseOptions}
      checkmark
      isDisabled={isDisabled}
    />
  );
};
