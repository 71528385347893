import { Box, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  slider: {
    marginRight: '0.5rem',
  },
}));

export const PercentageSlider = ({ value, handleChange, className }) => {
  const { classes } = useStyles();

  const handleSliderClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Box display="flex" alignItems="center" width="100%" className={className}>
      <Slider
        value={value}
        onChange={handleChange}
        onClick={handleSliderClick}
        size={'small'}
        className={classes.slider}
      />
      <Typography variant="body2">{value}%</Typography>
    </Box>
  );
};
