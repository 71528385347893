import { Fragment, useEffect, useRef, useState } from 'react';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { Button, Typography } from '@mui/material';
import { POAttachmentSection } from 'components/OrderForm/BillingShipping/BillingShippingInputs';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import { useUpdatePOModalStyles } from 'components/Modals/UpdatePOModal/styles';

import { useLazyGetOrderByUUIDQuery } from 'store/apis/orderApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { ORDER_FETCHING_ERROR_MESSAGE } from 'components/OrderForm/constants';
import { usePOActions } from 'components/Modals/UpdatePOModal/hooks';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import Box from '@mui/material/Box';

export const UpdatePOModal = ({
  open,
  onClose,
  orderUUID,
  fullOrder = null,
}) => {
  const { classes } = useUpdatePOModalStyles();
  const { showError } = useSnackbar();
  const updatedPORef = useRef('');

  const [isPOUploaded, setIsPOUploaded] = useState(false);
  const [updatedPO, setUpdatedPO] = useState('');

  const [getOrderByUUID, { data: fetchedOrder, isLoading }] =
    useLazyGetOrderByUUIDQuery();

  const order = fullOrder || fetchedOrder;

  const { onSubmitPO, onSave } = usePOActions(
    order,
    updatedPO,
    setUpdatedPO,
    setIsPOUploaded,
  );

  useEffect(() => {
    if (!open || !orderUUID) return;

    getOrderByUUID(orderUUID)
      .unwrap()
      .catch(() => showError(ORDER_FETCHING_ERROR_MESSAGE));
  }, [open]);

  useEffect(() => {
    if (!order) return;

    const PO = order?.billing_contact?.po_number || '';
    setUpdatedPO(updatedPORef.current || PO);
  }, [order]);

  if (!order) return null;

  const handlePOChange = (e) => {
    const newValue = e.target.value;
    updatedPORef.current = newValue;
    setUpdatedPO(newValue);
  };

  const handleClearInput = () => {
    setUpdatedPO('');
    setIsPOUploaded(false);
    updatedPORef.current = '';
  };

  return (
    <DefaultModal size="large" open={open} onClose={onClose}>
      <Typography variant="h5">Submit PO</Typography>
      {isLoading ? (
        <Box className={classes.spinnerContainer}>
          <Spinner />
        </Box>
      ) : (
        <Fragment>
          <Typography variant="h5">
            <TextField
              className={classes.input}
              label="PO Number"
              variant="outlined"
              value={updatedPO}
              onChange={handlePOChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearInput}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {isPOUploaded && (
              <CheckCircleIcon
                color="success"
                data-testid="checkmark"
                className={classes.checkmark}
              />
            )}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            id="add-po-button"
            onClick={onSubmitPO}
          >
            ADD PO NUMBER
          </Button>
          <Box className={classes.attachment}>
            <POAttachmentSection orderUUID={order.uuid} />
          </Box>
          <Button
            id="save-po-button"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={onSave}
          >
            SAVE PO DOCUMENT
          </Button>
        </Fragment>
      )}
    </DefaultModal>
  );
};
