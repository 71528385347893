import { makeStyles } from 'tss-react/mui';

export const useContactsCardStyles = makeStyles()(() => ({
  card: {
    height: '100%',
  },
  hwLogo: {
    height: '2.4rem',
    width: 'auto',
  },
}));
