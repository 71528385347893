import { getWhiteLabelLogo } from 'utilities/whitelabel';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { OPENED_DRAWER_WIDTH_PX } from 'components/Layout/constants';

const useStyles = makeStyles()(() => ({
  logo: {
    width: OPENED_DRAWER_WIDTH_PX * 0.7,
    marginBottom: '1rem',
  },
}));

const errorMarginStyle = { margin: '3rem' };
const textDecorationNoneStyle = { textDecoration: 'none' };

const logoUse = getWhiteLabelLogo();
const homeURL = process.env.REACT_APP_URL;

export const ErrorBoundaryFallback = ({ error, componentStack }) => {
  const { classes } = useStyles();

  return (
    <div style={errorMarginStyle}>
      <a
        href="https://www.histowiz.com"
        target={'_blank'}
        rel="noopener noreferrer"
        style={textDecorationNoneStyle}
      >
        <div className={classes.logo}>
          <img src={logoUse} alt="Logo" className={classes.logo} />
        </div>
      </a>
      <h2>
        <div>Sorry! It looks like we've had an error.</div>
        <br />
        <div>
          Please contact us at 347-221-1020 or info@histowiz.com and we'll
          gladly help!
        </div>
        <br />
        <div>
          <a href={homeURL}>
            {' '}
            In the interim, please click here to go back to Home{' '}
          </a>
        </div>
      </h2>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error?.toString()}
        <br />
        {componentStack}
      </details>
    </div>
  );
};
