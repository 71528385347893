import { DownloadLink } from 'components/Shared/Links/DownloadLink';
import { ShowStaffUserOnly } from 'components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly';
import { AdminInstanceLink } from 'components/Shared/Links/AdminInstanceLink';
import { getLisaIFSlideLink } from 'components/utilities/getLinks';
import React from 'react';

export const OptionsTab = React.memo(({ ifSlide }) => {
  return (
    <>
      <DownloadLink
        id="download-qptiff"
        text={'Download as .qptiff'}
        urlAttribute={'qptiff_url'}
        url={ifSlide?.download_url}
      />
      <DownloadLink
        id="download-ometiff"
        text={'Download as ome.tiff'}
        urlAttribute={'ome_tiff_url'}
        url={ifSlide?.download_url}
      />
      <ShowStaffUserOnly>
        <AdminInstanceLink url={getLisaIFSlideLink(ifSlide)} />
      </ShowStaffUserOnly>
    </>
  );
});
