import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import { Avatar, Typography, Box } from '@mui/material';
import React from 'react';
import logoHighResolutionTile from 'images/logos/logoHighResolutionTile.png';
import { stringAvatar } from 'components/TeamOverview/utilities';
import { useContactsCardStyles } from 'components/TeamOverview/components/ContactsCard/styles';

const UserCard = ({ firstName, lastName, email }) => (
  <Box marginBottom={3} display="flex" flexDirection="row" alignItems="center">
    <Avatar {...stringAvatar(firstName, lastName)} />
    <Box marginLeft={1.5}>
      <Typography fontWeight={500} fontSize={'1.125rem'}>
        {firstName} {lastName}
      </Typography>
      <Typography fontWeight={400} fontSize={'1.125rem'}>
        {email}
      </Typography>
    </Box>
  </Box>
);

export const ContactsCard = React.memo(() => {
  const { classes } = useContactsCardStyles();

  return (
    <Card
      cardClass={classes.card}
      header={
        <Box
          paddingY="1rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography
            height="max-content"
            fontWeight={500}
            fontSize={'1.125rem'}
          >
            HistoWiz Contact
          </Typography>
          <img
            className={classes.hwLogo}
            src={logoHighResolutionTile}
            alt="HistoWiz Logo"
          />
        </Box>
      }
    >
      <Box paddingX={3} paddingY={2.5}>
        <Typography fontWeight={600} fontSize={'1.125rem'} marginBottom={2}>
          Sales Representative
        </Typography>
        <UserCard
          firstName="Gabrielle"
          lastName="Stankosh"
          email="gabrielle@histowiz.com"
        />
        <UserCard
          firstName="Jack"
          lastName="Wilson"
          email="jack.wilson@histowiz.com"
        />
        <Typography fontWeight={600} fontSize={'1.125rem'} marginBottom={2}>
          Account Manager
        </Typography>
        <UserCard firstName="Bryce" lastName="Li" email="bryce@histowiz.com" />
      </Box>
    </Card>
  );
});
