import { Grid, Typography } from '@mui/material';
import React from 'react';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';
import { useCommonStyles } from 'components/TeamOverview/styles';
import { renderCellValue } from 'components/TeamOverview/utilities';

export const DataRow = ({
  dataUsed,
  slideName,
  fixativeTime,
  antibodyName,
  sampleName,
  speciesName,
  stainType,
  organName,
}) => {
  const { classes } = useCommonStyles();

  return (
    <Grid container className={classes.row}>
      <Grid item xs={1} className={classes.cell}>
        <Typography className={classes.label}>{dataUsed}</Typography>
      </Grid>
      <Grid item xs={3} className={classes.cell}>
        <TextWithLimitedWidth
          value={slideName}
          containerClass={classes.textWithLimitedWidth}
          textClass={classes.label}
          tooltipPlacement={'bottom'}
        />
      </Grid>
      <Grid item xs={1.5} className={classes.cell}>
        <Typography className={classes.label}>
          {renderCellValue(fixativeTime)}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.cell}>
        <Typography className={classes.label}>
          {renderCellValue(antibodyName)}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.cell}>
        <Typography className={classes.label}>
          {renderCellValue(sampleName)}
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.cell}>
        <Typography className={classes.label}>
          {renderCellValue(speciesName)}
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.cell}>
        <Typography className={classes.label}>
          {renderCellValue(stainType)}
        </Typography>
      </Grid>
      <Grid item xs={0.5} className={classes.cell}>
        <Typography className={classes.label}>
          {renderCellValue(organName)}
        </Typography>
      </Grid>
    </Grid>
  );
};
