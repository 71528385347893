import { makeStyles } from 'tss-react/mui';
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const VIEWER_BACKGROUND_COLOR = '#121212';
export const useColorPaletteStyles = makeStyles()(() => ({
  container: {
    width: '70px',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: {
    padding: '3px',
    width: '16px',
    height: '16px',
  },
  icon: {
    width: '17px',
    height: '17px',
  },
}));

export const useLensSelectStyles = makeStyles()(() => ({
  checkBox: {
    color: 'rgb(255, 255, 255)',
    '&.Mui-checked': {
      color: 'rgb(255, 255, 255)',
    },
  },
}));

export const useIfViewerStyles = makeStyles()((theme, { viewerWidth }) => ({
  slidesCountLabel: { marginLeft: '1rem' },
  container: { height: '85vh' },
  table: { height: '100%', paddingLeft: '1rem' },
  wrapper: {
    background: VIEWER_BACKGROUND_COLOR,
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  viewerContainer: {
    width: '100%',
    height: '95%',
  },
  accordion: {
    backgroundColor: 'rgba(25, 25, 25, 1)',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    width: 'max-content',
    borderTopRightRadius: '0.25rem',
    borderTopLeftRadius: '0.25rem',
    margin: '0 !important',
  },
  accordionSummary: {
    height: '40px !important',
    minHeight: '40px !important',
  },
  backButton: { top: '4.5rem', left: '1rem', zIndex: 1 },
  // yo probably isn't the best way to deal with this, not responsive, going to lead to other issues down the line lol
  // probably swap them out a relative grid positioning
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 1,
  },
  optionsWrapper: {
    width: '25rem',
  },
  zoomContainer: {
    marginLeft: '0.75rem',
    boxSizing: 'border-box',
  },
  zoomContainerRight: {
    marginRight: '1rem',
    boxSizing: 'border-box',
  },
  zoomContainerRightFullscreen: {
    position: 'absolute',
    top: 0,
    left: 'calc(50vw + 0.75rem)',
    boxSizing: 'border-box',
  },
  zoomButton: {
    margin: '1rem 0.25rem 0 0.25rem',
    borderRadius: '0.25rem',
    minWidth: '1.7rem',
    width: '1.7rem',
    zIndex: 1,
    padding: '0.25rem 1.25rem !important',
  },
  zoomSelected: {
    border: `1px solid ${theme.palette.default.main}`,
  },
  headerContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  controlButtonsContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '10rem',
  },
  controlButtonsContainerRight: { left: viewerWidth * 0.5 },
  controlButtonsContainerRightFullscreen: { left: '50vw' },
  controlButton: {
    margin: '0.75rem 0.25rem 0 1rem',
    borderRadius: '0.25rem',
    minWidth: '1.7rem',
    width: '1.7rem',
    zIndex: 1,
  },
  currentZoom: {
    position: 'absolute',
    height: '2.5rem',
    width: '7rem',
    top: '1.25rem',
    left: '23rem',
    borderRadius: '0.25rem',
    color: theme.palette.default.dark,
    textAlign: 'center',
    zIndex: 1,
  },
  currentZoomRight: { left: 'auto', right: '370px' },
  currentZoomRightFullscreen: { left: '68vw !important' },
  currentZoomLeftFullscreen: { left: '370px' },
  hiddenTable: { display: 'none' },
}));

export const useCameraOptionsStyles = makeStyles()(() => ({
  enabled: {
    marginLeft: '4px',
  },

  disabled: {
    color: 'rgba(255, 255, 255, 0.5)',
    marginLeft: '4px',
  },

  gridContainer: { marginTop: 16 },

  gridCheckBox: {
    padding: 0,
  },
}));

export const useChannelOptionsStyles = makeStyles()((theme) => ({
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  span: {
    width: '70px',
    textAlign: 'center',
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  colors: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  switch: {
    '& .Mui-checked': {
      color: theme.palette.primary.main + ' !important',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  input: {
    width: '5rem',
  },
  inputs: {
    display: 'flex',
    gap: '1rem',
    padding: '0 0.5rem',
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const useSlicerStyles = makeStyles()(() => ({
  enabled: {},

  disabled: {
    color: 'rgba(255, 255, 255, 0.5)',
    // Because of the .5 opacity of the disabled color in the theme, and the fact
    // that there are multiple overlaid parts to the slider,
    // this needs to be set manually for the desired effect.
    '& .MuiSlider-thumb': {
      color: 'rgb(100, 100, 100, 1.0)',
    },
    '&  .MuiSlider-track': {
      color: 'rgb(100, 100, 100, 1.0)',
    },
  },

  sliceListItem: { marginBottom: 8 },
  sliceListItemText: { marginTop: 4 },
}));

export const useFooterStyles = makeStyles()((theme) => ({
  typography: {
    fontSize: '.8rem',
  },
  paper: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    borderRadius: 2,
  },
  wrapperBox: {
    position: 'fixed',
    marginTop: 'calc(5% + 60px)',
    bottom: 0,
  },
}));

export const useVolumeBtnStyles = makeStyles()(() => ({
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
  },
  span: {
    width: '70px',
    textAlign: 'center',
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  colors: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    paddingLeft: '2px',
    paddingRight: '2px',
  },
}));

export const useChannelsLegendStyles = makeStyles()(() => ({
  checkboxRoot: { padding: 0 },
  container: {
    position: 'absolute',
    right: '12px',
    top: '78px',
    width: 'max-content',
    borderRadius: '4px',
    backgroundColor: 'rgb(36 36 36)',
    backdropFilter: 'blur(12px)',
    padding: '0.5rem',
  },
  slideName: {
    color: 'white',
    textAlign: 'left',
    marginBottom: '0.5rem',
  },
  channelName: {
    color: 'white',
    textAlign: 'right',
    marginRight: '0.5rem',
  },
  channelInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginBlock: '0.25rem',
  },
}));

export const DARK_IF_THEME = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
        textColor: 'inherit',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
  palette: {
    mode: 'dark',
    default: { main: grey, dark: grey },
    primary: grey,
    secondary: grey,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75em',
      },
    },
  },
});

export const useBrightnessSliderStyles = makeStyles()(() => ({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    gap: '1.5rem',
    padding: '0 0 1rem 0.5rem',
  },
  button: { padding: '0.5rem', minWidth: 'fit-content' },
}));
