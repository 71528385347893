import Box from '@mui/material/Box';
import { SimpleSlideViewMemo } from 'components/ImageViewer/SimpleSlideImageView';
import React from 'react';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import {
  ANNOTATION_DRAW_MODE_ENABLED_FIELD,
  ANNOTATION_DRAW_TOOL_FIELD,
  DISTANCE_MEASURE_ENABLED_FIELD,
} from 'components/ImageViewer/constants';
import { useUpdateUserPreferencesMutation } from 'store/apis/userDetailsApi';
import { useSelector } from 'react-redux';
import { currentUserPreferencesSelector } from 'store/slices/userDetailsSlice';
import { MeasurementButton } from 'components/ImageViewer/components/MeasurementButton';
import classNames from 'classnames';
import { usePortal } from 'utilities/hooks/usePortal/usePortal';
import { createPortal } from 'react-dom';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ImageViewerToolbar } from 'components/ImageViewer/components/ImageViewerToolbar/ImageViewerToolbar';

export const SlideViewerSection = ({ slide, classes }) => {
  const preferences = useSelector(currentUserPreferencesSelector);
  const target = usePortal('scr-btn');

  const [updateUserPreferences] = useUpdateUserPreferencesMutation();

  const handleSetAnnotationDrawTool = (value) => {
    const updatedPreference = {
      [ANNOTATION_DRAW_TOOL_FIELD]: value,
    };
    updateUserPreferences({ postParams: updatedPreference, partial: true });
  };

  const handleSetDrawModeEnabled = (value) => {
    const updatedPreference = {
      [ANNOTATION_DRAW_MODE_ENABLED_FIELD]: value,
      ...(value && { [DISTANCE_MEASURE_ENABLED_FIELD]: false }),
    };
    updateUserPreferences({ postParams: updatedPreference, partial: true });
  };

  const ScreenshotBtn = () =>
    createPortal(
      <Button
        value="screenshot"
        variant={'contained'}
        color="default"
        className={classes.measurementButton}
      >
        <PhotoCameraIcon fontSize="medium" />
      </Button>,
      target,
    );

  return (
    <Box flexGrow={1} className={classNames(classes.column, classes.content)}>
      <Box display="flex" flexDirection="row" mb={1}>
        <Box>
          <ImageViewerToolbar
            selectedDrawTool={preferences[ANNOTATION_DRAW_TOOL_FIELD]}
            setDrawTool={handleSetAnnotationDrawTool}
            drawModeEnabled={preferences[ANNOTATION_DRAW_MODE_ENABLED_FIELD]}
            handleSetDrawModeEnabled={handleSetDrawModeEnabled}
          />
        </Box>
        <MeasurementButton buttonClass={classes.measurementButton} />
        <Grid item id="scr-btn"></Grid>
      </Box>
      <Box flexGrow={1}>
        <SimpleSlideViewMemo
          slide={slide}
          annotationsEnabled
          annotationDrawTool={preferences[ANNOTATION_DRAW_TOOL_FIELD]}
          annotationDrawModeEnabled={
            preferences[ANNOTATION_DRAW_MODE_ENABLED_FIELD]
          }
          isDistanceMeasureSupported={true}
          showNavigator={true}
          id={slide.uuid}
          key={slide.uuid}
          CustomScreenshotButton={ScreenshotBtn}
        />
      </Box>
    </Box>
  );
};
