import { useRef, useState, useEffect, useMemo } from 'react';

import {
  useGetTeamPreferencesQuery,
  useSetTeamPreferencesMutation,
} from 'store/apis/teamsApi';
import { useUpdateSharedIfSlideByOrderMutation } from 'store/apis/ifSlideApi';
import { IF_SLIDE_NAME_FOR_SHARE } from 'components/Share/helpers/constants';
import {
  useShareClipboard,
  useShareError,
} from 'components/Share/helpers/hooks';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useViewerStore } from 'components/IFViewer/state';

export const useIFTable = () => {
  const [hasTable, setHasTable] = useState(true);
  const [tableWidth, setTableWidth] = useState(0);

  const tableRef = useRef(null);
  const offset = tableRef.current?.offsetWidth ?? 0;

  useEffect(() => {
    if (hasTable) {
      setTableWidth(offset);
    }
  }, [hasTable, offset]);

  return { tableRef, tableWidth, setHasTable, hasTable };
};

export const useIFSlideShare = (slide, orderUUID) => {
  const [shareSlide] = useUpdateSharedIfSlideByOrderMutation();

  const { share_enabled, uuid } = slide ?? {};

  const handleCopy = useShareClipboard(slide, IF_SLIDE_NAME_FOR_SHARE);
  const showError = useShareError(IF_SLIDE_NAME_FOR_SHARE);

  const handleShare = () =>
    shareSlide({ slideUUID: uuid, isShareable: !share_enabled, orderUUID })
      .unwrap()
      .then(!share_enabled && handleCopy)
      .catch(showError);

  return handleShare;
};

export const useIFInitSource = (slide) => {
  const { direct_gcp_download_url } = slide ?? {};

  const initSource = useMemo(
    () => ({
      url: direct_gcp_download_url ?? '',
      description: 'IF Slide',
    }),
    [direct_gcp_download_url],
  );

  const { showError } = useSnackbar();

  useEffect(() => {
    if (slide && !initSource.url) {
      showError("URL for this slide doesn't exist");
    }

    if (!initSource.url) {
      return;
    }

    // TODO: refactor useViewerStore
    useViewerStore.setState({
      source: initSource,
    });

    return () => {
      useViewerStore.setState({
        source: null,
        metadata: null,
        loaderError: false,
        use3d: false,
      });
    };
  }, [initSource]);
};

export const useIfSlideBrightness = (id, value) => {
  const { data, isLoading } = useGetTeamPreferencesQuery();
  const [mutation, { isLoading: mutationLoading }] =
    useSetTeamPreferencesMutation();

  const { if_slides } = data?.preferences ?? {};
  const { brightness } = if_slides?.[id] ?? {};
  const { showSuccess, showError } = useSnackbar();

  const handleSave = async () => {
    try {
      await mutation({
        body: { if_slides: { ...if_slides, [id]: { brightness: value } } },
      }).unwrap();

      showSuccess(`The brightness for the slide has been saved`);
    } catch {
      showError(`Failed to save the brightness for the slide`);
    }
  };

  return { brightness, handleSave, loading: isLoading || mutationLoading };
};
