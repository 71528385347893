import React from 'react';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { ManageAccounts, PhotoSizeSelectActual } from '@mui/icons-material';
import { ActionsMenuBlock } from 'components/ImageViewer/SlideViewerHeader/ActionsMenuBlock';
import { useSlideViewerActionsStyles } from 'components/ImageViewer/SlideViewerHeader/styles';
import { useViewerActions } from 'components/ImageViewer/SlideViewerHeader/useViewerActions';
import { RequestPathologistModalLegacy } from 'components/PathologyConsultations/OrderForm/RequestPathologistModalLegacy';
import {
  ORDER_NAME_FOR_SHARE,
  SLIDE_NAME_FOR_SHARE,
  GROUP_NAME_FOR_SHARE,
} from 'components/Share/helpers/constants';
import { SlideViewerOptions } from 'components/ImageViewer/SlidesViewerHeader';
import { ShareV2 } from 'components/Share/ShareV2';
import { DeleteModal } from 'components/Modals/DeleteModal';

export const SlideViewerActions = ({ currentSlide, isSingleViewer }) => {
  const { classes } = useSlideViewerActionsStyles();
  const { pathname } = useLocation();
  const {
    showShareButton,
    slideTools,
    additionalTools,
    exportTools,
    selectedRows,
    order,
    isConfirmModalOpen,
    setIsConfirmModalOpen,
    handleDeleteAnnotationsAttachment,
    group,
  } = useViewerActions(currentSlide, isSingleViewer);

  const item = group ?? currentSlide;

  return (
    <>
      <RequestPathologistModalLegacy slidesSelected={selectedRows} />
      <Box className={classes.root}>
        <SlideViewerOptions currentSlide={currentSlide} />
        {showShareButton && (
          <Box className={`${classes.actionButtons}`}>
            {!pathname.includes('/slide/') && !group && (
              <Box className={classes.shareButton}>
                <ShareV2 item={order} itemName={ORDER_NAME_FOR_SHARE} />
              </Box>
            )}
            {item && (
              <Box className={classes.shareButton}>
                <ShareV2
                  item={item}
                  itemName={group ? GROUP_NAME_FOR_SHARE : SLIDE_NAME_FOR_SHARE}
                />
              </Box>
            )}
          </Box>
        )}
        <ActionsMenuBlock
          actions={slideTools}
          title="Slide Tools"
          Icon={PhotoSizeSelectActual}
        />
        <ActionsMenuBlock
          actions={exportTools}
          title="Export Tools"
          Icon={DownloadIcon}
        />
        <ActionsMenuBlock
          actions={additionalTools}
          title="Additional Services"
          Icon={ManageAccounts}
        />
      </Box>
      <DeleteModal
        label="Are you sure want to delete this annotations file?"
        open={!!isConfirmModalOpen}
        handleDeleteResult={handleDeleteAnnotationsAttachment}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </>
  );
};
