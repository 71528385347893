import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { BlocksPageV2 } from 'components/BlocksList/BlocksPageV2';

export const BlocksHomePage = () => {
  return (
    <MiniDrawerWithContext header="Blocks Home">
      <BlocksPageV2 />
    </MiniDrawerWithContext>
  );
};
