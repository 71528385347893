import React from 'react';
import { FormInputV2 } from 'components/OrderForm/components/InfoFormInputs';
import { withStyles } from 'tss-react/mui';
import { uploadAntibodyAttachments } from 'services/resources/commonResources';
import { _UserUploadedAttachment } from 'components/UserUploads/uploads';
import { withSnackbar } from 'notistack';
import { UPLOAD_ATTACHMENT_ERROR_MESSAGE } from 'constants/errorMessages';
import { DEFAULT_EXTRA_MESSAGE } from 'utilities/hooks/useSnackbar/constants';
import { getSnackbarOptions } from 'utilities/hooks/useSnackbar/useSnackbar';

const dropZoneStyles = () => ({
  dropZoneContainer: {
    marginTop: '1rem',
    border: '1px',
    borderStyle: 'dashed',
    borderColor: 'black',
  },
  dropZoneLabel: {
    margin: '1rem',
  },
  dropZone: {
    width: '100%',
  },
});

export const AntibodyNameInput = (props) => {
  const { name, register, errors, touched, submitCount } = props;

  return (
    <FormInputV2
      name={name}
      formLabel={'IHC Antibody Name'}
      formPlaceholder={'Name of Antibody ...'}
      formInput={'text'}
      register={register}
      errors={errors}
      touched={touched}
      submitCount={submitCount}
    />
  );
};

export const AntibodyDescriptionInput = (props) => {
  const { name, register, errors, touched, submitCount } = props;

  return (
    <FormInputV2
      name={name}
      formLabel={'Antibody Description (Host, IgG, Concentration)'}
      formPlaceholder={'Please detail host (species), IgG and concentration.'}
      formInput={'text'}
      register={register}
      errors={errors}
      touched={touched}
      submitCount={submitCount}
    />
  );
};

class _AntibodyDescriptionAttachment extends _UserUploadedAttachment {
  handleChange = (value) => {
    this.props.setValue('antibodyAttachment', value);
  };

  uploadAttachment = (formData) => {
    uploadAntibodyAttachments(formData)
      .then((response) => {
        const antibodyAttachment = {
          label: response.data.name,
          value: response.data.uuid,
        };

        this.handleChange(antibodyAttachment);
      })
      .catch(() =>
        this.props.enqueueSnackbar(
          `${UPLOAD_ATTACHMENT_ERROR_MESSAGE} \n ${DEFAULT_EXTRA_MESSAGE}`,
          getSnackbarOptions('error'),
        ),
      );
  };
}

export const AntibodyDescriptionAttachment = withStyles(
  withSnackbar(_AntibodyDescriptionAttachment),
  dropZoneStyles,
);
