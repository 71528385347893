import { tooltipClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { MEDIUM_GREY, FORM_TEXT_COLOR, PRIMARY_BLUE } from 'utilities/colors';

export const useSlideMetadataRowStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.2rem 1.6rem',
    margin: '0.1rem',

    '&:nth-of-type(even)': {
      backgroundColor: `${PRIMARY_BLUE}16`,
      borderTop: '1px solid rgba(0,0,0,0.1)',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
  },
  label: {
    fontSize: '0.875rem',
    lineHeight: '1.2rem',
    textDecoration: 'capitalize',
    color: MEDIUM_GREY,
    paddingRight: '1rem',
  },
  value: {
    color: FORM_TEXT_COLOR,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '25rem',

    [theme.breakpoints.down('xl')]: {
      fontSize: '0.875rem',
      maxWidth: '20rem',
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: '0.75rem',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '0.625rem',
    },
  },
  tooltip: {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  },
  link: {
    color: PRIMARY_BLUE,
    textDecoration: 'underline',
  },
}));
