import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Fragment, memo } from 'react';
import _ from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableContainer from '@mui/material/TableContainer';
import { SAMPLE_EDITOR_URL } from 'constants/urls';
import { formatLabelKey } from 'components/OrderSlidesList/utilities';
import { formatJSONString } from 'components/SampleEditor/components/SamplesChangelog/utils';
import {
  DISPLAY_NAME,
  SCIENCE_INFO,
} from 'components/SampleEditor/components/SamplesChangelog/constants';

const BaseCells = ({ row, tableHeader, handleLinkClick, classes }) =>
  Object.keys(tableHeader)
    .slice(0, 4)
    .map((key, cellIndex) => (
      <TableCell key={cellIndex}>
        {key === 'name' ? (
          <a
            className={classes.link}
            href={`${SAMPLE_EDITOR_URL}?search=${row.id}`}
            target="_blank"
            onClick={handleLinkClick}
            rel="noreferrer noopener"
          >
            {row[key]}
          </a>
        ) : (
          row[key]
        )}
      </TableCell>
    ));

const ParsedDetails = ({ change, author }) =>
  [
    ...new Set([
      ...Object.keys(formatJSONString(change.old)),
      ...Object.keys(formatJSONString(change.new)),
    ]),
  ].map((key) => {
    if (change.old === change.new) return null;
    const oldValue = formatJSONString(change.old)[key];
    const newValue = formatJSONString(change.new)[key];
    if (!_.isEqual(oldValue, newValue)) {
      return (
        <TableRow key={key}>
          <TableCell colSpan={5} />
          <TableCell>{formatLabelKey(key)}</TableCell>
          <TableCell>{oldValue || '-'}</TableCell>
          <TableCell>{newValue || '-'}</TableCell>
          <TableCell>{author}</TableCell>
        </TableRow>
      );
    }
    return null;
  });

export const HistoryDetails = ({ historyDetails }) => (
  <>
    {historyDetails?.map(({ author, changed_data }) =>
      changed_data
        ?.filter((change) => change.field !== DISPLAY_NAME)
        .map((change, cellIndex) => (
          <Fragment key={cellIndex}>
            {change.field !== SCIENCE_INFO ? (
              <TableRow>
                <TableCell colSpan={5} />
                <TableCell>{change.field}</TableCell>
                <TableCell>{change.old}</TableCell>
                <TableCell>{change.new}</TableCell>
                <TableCell>{author}</TableCell>
              </TableRow>
            ) : (
              <ParsedDetails change={change} author={author} />
            )}
          </Fragment>
        )),
    )}
  </>
);

const TableRowComponent = memo(
  ({
    row,
    dateUUID,
    rowIndex,
    tableHeader,
    classes,
    expandedRows,
    rowLoading,
    handleExpandClick,
  }) => (
    <Fragment key={rowIndex}>
      <TableRow>
        <TableCell className={classes.firstCell}>
          {rowLoading === dateUUID ? (
            <CircularProgress size={15} className={classes.rowSpinner} />
          ) : (
            <IconButton
              onClick={() => handleExpandClick(row.uuid)}
              className={classes.expandIcon}
            >
              {expandedRows.includes(dateUUID) ? (
                <ExpandMoreIcon className={classes.rotateIcon} />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <BaseCells
          row={row}
          tableHeader={tableHeader}
          handleLinkClick={(e) => e.stopPropagation()}
          classes={classes}
        />
        <TableCell colSpan={4} />
      </TableRow>
      {expandedRows.includes(dateUUID) && (
        <HistoryDetails historyDetails={row.historyDetails} classes={classes} />
      )}
    </Fragment>
  ),
);

const TableHeader = ({ tableHeader }) => (
  <TableHead>
    <TableRow>
      <TableCell sx={{ width: '2rem', padding: '0.5rem' }} />
      {Object.values(tableHeader).map((header, headerIndex) => (
        <TableCell
          key={headerIndex}
          sx={
            headerIndex === Object.values(tableHeader).length - 1
              ? { width: '10rem' }
              : {}
          }
        >
          {header}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export const SamplesHistoryTable = ({
  table,
  tableHeader,
  classes,
  rowLoading,
  assignHistoryDetails,
  expandedRows,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHeader tableHeader={tableHeader} />
        <TableBody>
          {table.data.map((row, rowIndex) => (
            <TableRowComponent
              key={rowIndex}
              row={row}
              dateUUID={`${row.uuid}-${table.date}`}
              rowIndex={rowIndex}
              tableHeader={tableHeader}
              classes={classes}
              rowLoading={rowLoading}
              expandedRows={expandedRows}
              assignHistoryDetails={assignHistoryDetails}
              handleExpandClick={() => assignHistoryDetails(table, row.uuid)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
