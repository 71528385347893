import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';

import 'ag-grid-enterprise';

import {
  getAntibodies,
  getMultiplexAntibodies,
  getPanels,
  getSpecialStainsIHC,
} from 'services/resources/commonResources';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { appURL, backendURL } from 'services/backendAPI';
import { Button, Collapse, Stack } from '@mui/material';
import {
  AntibodyColumnDefs,
  ihcAdminColumnDefs,
} from 'components/utilities/AgGridCols/AgGridColumns';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { PATHOLOGY_MAP_SEARCH_2 } from 'constants/urls';
import Grid from '@mui/material/Grid';
import { TabPanel } from 'components/Shared/TabPanel';
import { FormInput } from 'components/FormInputs/FormInput';
import { SingleAntibodyTable } from 'components/AntibodiesList/SingleAntibodyTable';
import { MultiplexAntibodyTable } from 'components/AntibodiesList/MultiplexAntibodyTable';
import { IFPanelTable } from 'components/AntibodiesList/IFPanelTable';
import {
  DETAILS_VIEW_MODE,
  HEADER_TABS_PROPS,
  MULTIPLEX_ANTIBODY_TABLE_IDX,
  PANELS_TABLE_IDX,
  SINGLE_ANTIBODY_TABLE_IDX,
  SPECIAL_STAINS_TABLE_IDX,
  VIEWER_VIEW_MODE,
} from 'components/AntibodiesList/constants';
import {
  FETCH_ANTIBODIES_ERROR_MESSAGE,
  FETCH_MULTIPLEX_ANTIBODIES_ERROR_MESSAGE,
  FETCH_SPECIAL_STAINS_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import { useSelector } from 'react-redux';
import { SpecialStainsTable } from 'components/AntibodiesList/SpecialStainsTable';
import { DATE_REQUEST_FORMAT } from 'utilities/dates_and_times';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { AntibodySummary } from 'components/AntibodiesList/AntibodySummary';
import { DetailsHeader } from 'components/AntibodiesList/DetailsTabHeader';
import { SimpleSlideView } from 'components/ImageViewer/SimpleSlideImageView';
import { useRouteMatch } from 'react-router-dom';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ShoppingCartOutlined as ShoppingCart,
} from '@mui/icons-material';
import { AntibodiesListLegend } from 'components/AntibodiesList/AntibodiesListLegend';
import { useAntibodiesListStyles } from 'components/AntibodiesList/antibodiesListStyles';

export const AntibodiesListView = ({ isForOrderForm, handleAddToOrder }) => {
  const unmountIgnore = useUnmountIgnore();
  const userDetails = useSelector(userDetailsSelector);
  const { showError } = useSnackbar();
  const match = useRouteMatch();
  const [columnDefs, setColumnDefs] = useState(AntibodyColumnDefs);
  /* eslint-disable-next-line no-unused-vars */
  const [currentDataLength, setCurrentDataLength] = useState(0);

  const [antibodiesRowData, setAntibodiesRowData] = useState([]);
  const [isAntibodiesLoading, setIsAntibodiesLoading] = useState(true);
  const [multiplexRowData, setMultiplexRowData] = useState([]);
  const [specialStains, setSpecialStains] = useState([]);
  const [isMultiplexAntibodiesLoading, setIsMultiplexAntibodiesLoading] =
    useState(true);
  const [isSpecialStainsLoading, setIsSpecialStainsLoading] = useState(true);
  const [panelsRowData, setPanelsRowData] = useState([]);
  const [isPanelsLoading, setIsPanelsLoading] = useState(true);
  const [isDetailsCollapsed, setIsDetailsCollapsed] = useState(false);
  const [selectedViewMode, setSelectedViewMode] = useState(DETAILS_VIEW_MODE);

  const [currentTab, setCurrentTab] = useState(SINGLE_ANTIBODY_TABLE_IDX);

  const [selectedAntibody, setSelectedAntibody] = useState(null);

  const [slide, setSlide] = useState();

  const isMainTab = currentTab === SINGLE_ANTIBODY_TABLE_IDX;
  const isDetailsView = selectedViewMode === DETAILS_VIEW_MODE;

  const { classes } = useAntibodiesListStyles({
    isFullTable: !slide && isMainTab,
    isDetailsCollapsed,
    isForOrderForm,
  });

  const [quickFilterText, setQuickFilterText] = useState('');

  const [isStaff, setIsStaff] = useState(false);
  const [singleAntibodyGridApi, setSingleAntibodyGridApi] = useState(null);
  const [, setSingleAntibodyDetailGridApi] = useState(null);

  const title = selectedAntibody
    ? `${selectedAntibody.name} - ${selectedAntibody.catalog_number}`
    : 'Antibodies Catalog';

  const antibodyUUIDFromUrl = match?.params['antibodyUUID'];

  useEffect(() => {
    if (!antibodiesRowData) return;
    if (antibodyUUIDFromUrl) {
      const antibody = antibodiesRowData.find(
        ({ uuid }) => uuid === antibodyUUIDFromUrl,
      );
      setSelectedAntibody(antibody);
      setSlide(antibody?.validated_slides[0]);
    } else {
      setSelectedAntibody(antibodiesRowData[0]);
      setSlide(antibodiesRowData[0]?.validated_slides[0]);
    }
  }, [antibodyUUIDFromUrl, antibodiesRowData]);

  useEffect(() => {
    if (singleAntibodyGridApi) {
      singleAntibodyGridApi.sizeColumnsToFit();
    }
  }, [singleAntibodyGridApi, isDetailsCollapsed]);

  useEffect(() => {
    getAntibodies()
      .then((response) => {
        if (!unmountIgnore.current) {
          setAntibodiesRowData(response);
        }
      })
      .catch(() => showError(FETCH_ANTIBODIES_ERROR_MESSAGE))
      .finally(() => setIsAntibodiesLoading(false));

    getPanels()
      .then((response) => {
        if (!unmountIgnore.current) {
          setPanelsRowData(response);
        }
      })
      .catch(() => showError(FETCH_MULTIPLEX_ANTIBODIES_ERROR_MESSAGE))
      .finally(() => setIsPanelsLoading(false));

    getMultiplexAntibodies()
      .then((response) => {
        if (!unmountIgnore.current) {
          setMultiplexRowData(response);
        }
      })
      .catch(() => showError(FETCH_MULTIPLEX_ANTIBODIES_ERROR_MESSAGE))
      .finally(() => setIsMultiplexAntibodiesLoading(false));

    getSpecialStainsIHC()
      .then((response) => {
        if (!unmountIgnore.current) {
          setSpecialStains(response);
        }
      })
      .catch(() => showError(FETCH_SPECIAL_STAINS_ERROR_MESSAGE))
      .finally(() => setIsSpecialStainsLoading(false));
  }, [unmountIgnore]);

  const handleExportToExcel = () => {
    const now = moment().format(DATE_REQUEST_FORMAT);
    const fileName = `Single-Antibodies-Catalog-${now}`;

    const exportParams = {
      columnGroups: true,
      fileName,
    };
    singleAntibodyGridApi.exportDataAsExcel(exportParams);
  };

  useEffect(() => {
    // ag-grid caches a some functions, which causes an issue because by default for context we say is not a staff
    if (userDetails.isStaff) {
      setColumnDefs(ihcAdminColumnDefs);
      setIsStaff(true);
    }
  }, [userDetails]);

  const getSingleContextMenuItems = ({ row }) => {
    const lisaLink = `${backendURL}/lisa/core/antibody/${row.id}/change/`;

    const antibodyName = row.name.toUpperCase();

    const pathologyMapSearchURL = `${appURL}${PATHOLOGY_MAP_SEARCH_2}?production_pathology_map[refinementList][antibody_name][0]=${antibodyName}`;

    return [
      {
        name: 'Edit Antibody in LISA',
        action: () => {
          if (row) {
            window.open(lisaLink, '_blank');
          }
        },
      },
      {
        name: 'Find Slides in PathologyMap',
        action: () => {
          if (row) {
            window.open(pathologyMapSearchURL, '_blank');
          }
        },
      },
    ];
  };

  const getContextMenuItems = (params) => {
    // this function gets cached by ag grid, which is a problem when we actually use
    // react context for is staff and that changes, so here, we pass it into ag-grid as a constantly
    // updating context
    const gridContext = params.context;
    const { isStaff: isUserStaff } = gridContext;

    if (!params.node?.data || !isUserStaff) {
      return null;
    }

    const row = params.node.data;

    return getSingleContextMenuItems({ row });
  };

  const onQuickFilterText = (event) => {
    setQuickFilterText(event.target.value);
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    setSlide(undefined);
    setSingleAntibodyGridApi(undefined);
    setSingleAntibodyGridApi(undefined);
  };

  const setDisplayRowLengthHandler = (event) => {
    setCurrentDataLength(
      event.api.rowModel.rowsToDisplay.filter((element) => !element.detail)
        .length,
    );
  };

  const thumbnail = slide?.large_thumbnail_url || '';
  const isFullWidth = (isDetailsCollapsed && isMainTab) || !isMainTab;
  const mainGridWidth = isFullWidth ? 11.8 : 5.8;
  const selectedRows = singleAntibodyGridApi?.getSelectedRows();
  const selectedRowsCount = selectedRows?.length;

  return (
    <>
      <Helmet>
        <title>{`${title} - HistoWiz`}</title>
        <meta
          name="description"
          content={`HistoWiz Antibodies Catalog: ${title}`}
        />
      </Helmet>
      <Grid container className={classes.wrapper}>
        <Grid item xs={mainGridWidth} className={classes.gridWrapper}>
          <Box className={classes.tabsWrapper}>
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabsChange}
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
              }}
            >
              {HEADER_TABS_PROPS.map(({ label, id }) => (
                <Tab
                  key={id + label}
                  label={label}
                  id={id}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                />
              ))}
            </Tabs>
            <AntibodiesListLegend />
          </Box>
          <Grid item>
            <Box
              ml={1}
              justifyContent="space-between"
              display="flex"
              alignItems="center"
            >
              <FormInput onChange={onQuickFilterText} />
              {isForOrderForm && selectedRowsCount > 1 && (
                <Button
                  variant="contained"
                  className={classes.addABToOrderButton}
                  startIcon={<ShoppingCart />}
                  onClick={() => handleAddToOrder(selectedRows)}
                >
                  Add
                  <Box
                    className={classes.addToOrderCounter}
                    key={selectedRowsCount}
                  >
                    {selectedRowsCount}
                  </Box>
                  Selected to Order
                </Button>
              )}
            </Box>

            <Box
              data-nosnippet="true"
              className={`ag-theme-balham ${classes.agGrid}`}
            >
              <TabPanel value={currentTab} index={SINGLE_ANTIBODY_TABLE_IDX}>
                <SingleAntibodyTable
                  setDisplayRowData={setDisplayRowLengthHandler}
                  rowData={antibodiesRowData}
                  isLoading={isAntibodiesLoading}
                  quickFilterText={quickFilterText}
                  columnDefs={columnDefs}
                  getContextMenuItems={getContextMenuItems}
                  isStaff={isStaff}
                  setSlide={setSlide}
                  setGridApi={setSingleAntibodyGridApi}
                  gridApi={singleAntibodyGridApi}
                  setDetailGridApi={setSingleAntibodyDetailGridApi}
                  selectedAntibody={selectedAntibody}
                  setSelectedAntibody={setSelectedAntibody}
                  isForOrderForm={isForOrderForm}
                />
                {isStaff && (
                  <Stack direction="row" justifyContent="end">
                    <Button onClick={handleExportToExcel}>
                      Export to Excel
                    </Button>
                  </Stack>
                )}
              </TabPanel>
              <TabPanel value={currentTab} index={MULTIPLEX_ANTIBODY_TABLE_IDX}>
                <MultiplexAntibodyTable
                  setDisplayRowData={setDisplayRowLengthHandler}
                  rowData={multiplexRowData}
                  isLoading={isMultiplexAntibodiesLoading}
                  quickFilterText={quickFilterText}
                  getContextMenuItems={getContextMenuItems}
                  isStaff={isStaff}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={PANELS_TABLE_IDX}>
                <IFPanelTable
                  rowData={panelsRowData}
                  isLoading={isPanelsLoading}
                  quickFilterText={quickFilterText}
                  isStaff={isStaff}
                  setDisplayRowData={setDisplayRowLengthHandler}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={SPECIAL_STAINS_TABLE_IDX}>
                <SpecialStainsTable
                  rowData={specialStains}
                  isLoading={isSpecialStainsLoading}
                  quickFilterText={quickFilterText}
                  setDisplayRowData={setDisplayRowLengthHandler}
                />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
        {isMainTab && (
          <Grid item xs={0.2}>
            <Button
              className={classes.collapseButton}
              onClick={() => setIsDetailsCollapsed(!isDetailsCollapsed)}
            >
              {isDetailsCollapsed ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          </Grid>
        )}
        {isMainTab && (
          <Grid item xs={6} className={classes.detailsWrapper}>
            <Collapse
              orientation="horizontal"
              in={!isDetailsCollapsed}
              className={classes.collapseContainer}
            >
              <Box className={classes.detailsCollapsibleContainer}>
                {!isDetailsCollapsed && (
                  <DetailsHeader
                    selectedAntibody={selectedAntibody}
                    selectedViewMode={selectedViewMode}
                    setSelectedViewMode={setSelectedViewMode}
                    thumbnail={thumbnail}
                  />
                )}
                <Grid
                  item
                  className={`${!isDetailsView && classes.viewerContainer}`}
                >
                  {selectedAntibody &&
                    (isDetailsView ? (
                      <AntibodySummary
                        selectedAntibody={selectedAntibody}
                        thumbnail={thumbnail}
                        classes={classes}
                        handleThumbnail={() =>
                          setSelectedViewMode(VIEWER_VIEW_MODE)
                        }
                        isFromOrderForm={isForOrderForm}
                        handleAddToOrder={handleAddToOrder}
                        selectedRowsCount={selectedRowsCount}
                      />
                    ) : (
                      <SimpleSlideView
                        slide={slide}
                        id={slide?.uuid}
                        showNavigator={false}
                      />
                    ))}
                </Grid>
              </Box>
            </Collapse>
          </Grid>
        )}
      </Grid>
    </>
  );
};
