import { makeStyles } from 'tss-react/mui';
import {
  HIGHLIGHTED_TEXT_BG_COLOR,
  HIGHLIGHTED_TEXT_FONT_WEIGHT,
} from 'components/Shared/constants/styles';
import { SECONDARY_WHITE } from 'utilities/colors';

export const useHighlightedTextStyles = makeStyles()({
  highlightedText: {
    backgroundColor: HIGHLIGHTED_TEXT_BG_COLOR,
    fontWeight: HIGHLIGHTED_TEXT_FONT_WEIGHT,
  },
});

export const useHeaderAndContentStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: SECONDARY_WHITE,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const useQuestionsStyles = makeStyles()((theme) => ({
  subHeader: {
    marginTop: theme.spacing(1),
  },
}));

const BLUE_BG_COLOR = 'rgba(66, 148, 213, 0.20)';

export const BLUE_BG_STYLES = {
  background: BLUE_BG_COLOR,
};

export const useBlueBackgroundStyles = makeStyles()(() => ({
  blueBackground: {
    ...BLUE_BG_STYLES,
  },
}));
