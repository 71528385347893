import { makeStyles } from 'tss-react/mui';
import { WHITE_SMOKE } from 'utilities/colors';

export const useGlobalSearchStyles = makeStyles()((theme) => ({
  accordion: { '&.Mui-expanded': { opacity: 0.93 } },
  summary: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    minHeight: '27px',
    height: '27px',
    padding: 0,
  },
  searchInput: {
    height: '35px',
    border: 'none',
    borderRadius: '0.5rem',
    backgroundColor: WHITE_SMOKE,
    fontSize: '1rem',
  },
  spinner: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: '10px',
  },
  searchIcon: {
    opacity: 0.6,
  },
}));

export const searchResultsStyles = {
  container: {
    '--border-color': '#E1E1E1',
    '--border-width': '2px',
    '--gap': '0.5rem',
    '--top-gap': 'calc(var(--gap)/2)',
  },
  list: {
    padding: 0,
    listStyle: 'none',
  },
  listItem: {
    position: 'relative',
    padding: 'var(--top-gap) 0 0 var(--gap)',
    '& > span': {
      '&:last-child, &:nth-last-of-type(1)': {
        '&:hover': {
          fontWeight: 'bold',
          cursor: 'pointer',
        },
      },
    },
    '&:last-of-type': {
      '&::before': {
        content: '""',
        borderLeft: 'var(--border-width) solid var(--border-color)',
        borderBottom: 'var(--border-width) solid var(--border-color)',
        borderBottomLeftRadius: '4px',
        position: 'absolute',
        left: '0',
        top: '0',
        width: 'calc(var(--gap)/1.5)',
        height: 'calc(var(--top-gap) + 1em/2)',
      },
    },
    '&:not(:last-child)': {
      // horizontal connector line
      backgroundImage: `linear-gradient(0, var(--border-color), var(--border-color))`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 calc(var(--top-gap) + 1em/2)',
      backgroundSize: 'calc(var(--gap)/1.5) var(--border-width)',
      // vertical connector line
      borderLeft: 'var(--border-width) solid var(--border-color)',
    },
  },
  group: {
    '&:hover': {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
  projectsGroup: {
    '&:hover': {
      fontWeight: 'normal !important',
      cursor: 'auto !important',
    },
  },
  noResults: {
    width: '100%',
    textAlign: 'center',
  },
};
