import {
  PATHOLOGY_MAP_CATEGORY_ITEM_BG_COLOR,
  PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR,
  PATHOLOGY_MAP_DISABLED_ITEM_BG_COLOR,
  PATHOLOGY_MAP_SELECTED_ITEM_BG_COLOR,
} from 'components/PathologyMap/constants/styles';
import { makeStyles } from 'tss-react/mui';

export const usePathologyMapCategoriesItemsStyles = makeStyles()(() => ({
  itemsWrapper: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export const usePathologyMapCategoryItemStyles = makeStyles()(() => ({
  hidden: {
    display: 'none !important',
  },
  categoryItem: {
    maxHeight: '2.5rem',
    background: PATHOLOGY_MAP_CATEGORY_ITEM_BG_COLOR,
    padding: '0.14rem 0.8rem 0.14rem 2.8rem',
    borderLeft: '0.2rem solid transparent',
    borderRight: '0.2rem solid transparent',
    flex: 1,
    transition: 'background-color .3s ease',
    '&:hover': {
      cursor: 'pointer',
      borderLeftColor: PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR,
      borderRightColor: PATHOLOGY_MAP_CATEGORY_ITEM_HOVER_BORDER_COLOR,
      transition: 'border .3s ease',
    },
  },
  selectedItem: {
    background: PATHOLOGY_MAP_SELECTED_ITEM_BG_COLOR,
  },
  itemGroupEnd: {
    borderBottom: '1px solid rgba(0,0,0,0.15)',
  },
  disabledItem: {
    background: PATHOLOGY_MAP_DISABLED_ITEM_BG_COLOR,
  },
  icon: {
    opacity: 0.2,
    margin: '-6px 0',
  },
  iconButton: {
    padding: 0,
    marginLeft: '0.5rem',
  },
  arrowWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-4px',
  },
  starIcon: {
    marginTop: '0.125rem',
    height: '1.125rem',
    width: '1.125rem',
  },
}));
