import { useHistory } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import { PLACE_ORDER_INFO_URL } from 'constants/urls';

import { useOrderTablesStyles } from 'components/OrdersList/orderTablesStyles';

export const EditOrderButton = ({ orderId }) => {
  const history = useHistory();
  const { classes } = useOrderTablesStyles();

  const onEditAction = () => {
    const url = PLACE_ORDER_INFO_URL.replace(':orderUUID', orderId);
    history.push(url);
  };

  return <EditIcon onClick={onEditAction} className={classes.actionIcon} />;
};
