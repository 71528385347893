import { Box, Typography } from '@mui/material';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { Link } from 'react-router-dom';
import { APP_LIBRARY } from 'constants/urls';
import { Blog } from 'components/pages/AppLibrary/Blog';
import { SlidesGallery } from 'components/pages/AppLibrary/SlidesGallery';
import { AiModelMenu } from 'components/pages/AppLibrary/AiModel/AiModelMenu';
import { useAiModelsStyles } from 'components/pages/AppLibrary/AiModel/styles';
import { AiModelSlides } from 'components/pages/AppLibrary/AiModel/AiModelSlides';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { aiToolsSelector } from 'store/slices/cmsSlice';
import { useGetCurrentAiModel } from 'components/pages/AppLibrary/AiModel/hooks';
import { getLocalToken } from 'utilities/token';

export const AiModel = () => {
  const { model } = useParams();
  const aiToolsData = useSelector(aiToolsSelector);
  const [currentModel, setCurrentModel] = useState(
    aiToolsData.find((tool) => tool?.toolId === model),
  );

  const { getCurrentModel } = useGetCurrentAiModel();

  const isLoggedIn = !!getLocalToken(); // TODO: temp solution, check auth0 for context

  useEffect(() => {
    if (currentModel) {
      return;
    }
    getCurrentModel(model, setCurrentModel);
  }, [model, currentModel, getCurrentModel]);

  const pageTitle = currentModel?.title?.replace('Histowiz', '');
  useTitle(`HistoWiz - ${pageTitle}`);
  const { classes } = useAiModelsStyles();

  return currentModel ? (
    <MiniDrawerWithContext header="App Library">
      <Box className={classes.wrapper}>
        <Box className={classes.breadCrumbs}>
          <Link to={APP_LIBRARY} className={classes.link}>
            APP LIBRARY
          </Link>
          <span className={classes.spanDivider}>/</span>
          <Typography className={classes.breadCrumbText}>
            {currentModel?.title}
          </Typography>
        </Box>
        <Box className={classes.root}>
          <Box>
            <SlidesGallery
              showArrows
              thumbWidth={150}
              content={[
                currentModel?.video,
                ...(currentModel?.toolImages?.[0].toolImage || []),
              ]}
            />
            <Blog model={currentModel} />
          </Box>
          <AiModelMenu model={currentModel} />
        </Box>
        {isLoggedIn && <AiModelSlides model={model} />}
      </Box>
    </MiniDrawerWithContext>
  ) : null;
};
