import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Help } from '@mui/icons-material';
import { HISTOWIZ_PRIMARY_COLOR } from 'utilities/colors';

const useStyles = makeStyles()(() => ({
  button: {
    '& svg': {
      fontSize: 16,
      fill: HISTOWIZ_PRIMARY_COLOR,
    },
    marginLeft: '0.25rem',
    padding: 0,
  },
}));

const useTooltipStyles = makeStyles()((theme) => ({
  arrow: {
    color: '#f5f5f9',
    '&:before': {
      border: '1px solid #dadde9',
    },
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginBottom: 5,
  },
}));

export const FormInputInfoTooltip = (props) => {
  const { infoText } = props;
  const { classes } = useStyles();
  const { classes: tooltipClasses } = useTooltipStyles();

  return (
    <Tooltip
      disableInteractive
      title={infoText}
      arrow
      classes={tooltipClasses}
      placement="top"
    >
      <IconButton size="large" className={classes.button}>
        <Help />
      </IconButton>
    </Tooltip>
  );
};
