import { Box, Typography, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useSlideMetadataRowStyles } from 'components/OrderSlidesList/SlideActionTabs/SlideMetadata/SlideMetadataRow/styles';

export const SlideMetadataRow = ({
  label,
  value,
  keyTooltip,
  valueTooltip,
  link,
}) => {
  const { classes } = useSlideMetadataRowStyles();
  const displayValue = value === 0 ? '0' : value || '—';

  const ValueWithTooltip = ({ className, value, tooltip, link }) => {
    const displayedValue = link ? (
      <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
        {value}
      </a>
    ) : (
      value
    );

    return (
      <Typography className={className}>
        {tooltip ? (
          <Tooltip title={tooltip} classes={{ popper: classes.tooltip }}>
            <span>{displayedValue}</span>
          </Tooltip>
        ) : (
          displayedValue
        )}
      </Typography>
    );
  };

  return (
    <Box className={classes.root}>
      <ValueWithTooltip
        className={classes.label}
        value={label}
        tooltip={keyTooltip}
      />
      <ValueWithTooltip
        className={classes.value}
        value={displayValue}
        tooltip={valueTooltip}
        link={link}
      />
    </Box>
  );
};

SlideMetadataRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keyTooltip: PropTypes.string,
  valueTooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
