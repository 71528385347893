import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SlideGroupListView } from 'components/SlideGroups/Views/ListView/SlideGroupListView';
import { useSlideGroupsListViewStyles } from 'components/SlideGroups/utilities/styles';

export const SlideGroupsListView = ({ slideGroups }) => {
  const { classes } = useSlideGroupsListViewStyles();

  return (
    <Grid container spacing={2}>
      {slideGroups.length ? (
        slideGroups.map((slideGroup) => (
          <SlideGroupListView
            classes={classes}
            key={slideGroup.uuid}
            slideGroup={slideGroup}
          />
        ))
      ) : (
        <Box className={classes.noSlideGroupsLabelContainer}>
          <Typography variant="h5">You don't have slide groups</Typography>
        </Box>
      )}
    </Grid>
  );
};
