import { DEFAULT_ADRESS_VALUES } from 'components/Shared/TeamAddress/constants';
import { HASH_PARAMS_DELIMITER, sortOrder } from 'utilities/constants';

const VALID_IPV4_REGEX =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

export const toTitleCase = (str) =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const getDuplicatesFromArray = (names) => {
  const count = (names) =>
    names.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});

  const duplicates = (dict) => Object.keys(dict).filter((a) => dict[a] > 1);

  return duplicates(count(names));
};

export const nameSorter = (a, b) => a.name.localeCompare(b.name);

export const swapKeysAndValuesDictionary = (dict) =>
  Object.entries(dict).reduce(
    (acc, [key, val]) => ({ ...acc, [val]: key }),
    {},
  );
export const sortArray = (arr, sorter, sort = {}) => {
  if (!arr) return [];
  if (!sorter || !sort.field) return arr;
  const field = sort.field;
  const order = sort.order || sortOrder.DESC;

  const sortedArray = arr.slice().sort((a, b) => sorter(field, a, b));

  return order === sortOrder.DESC ? sortedArray : sortedArray.reverse();
};

export class UpdateHandler {
  /*
   * This class is used to simplify class-based components updates handling.
   * prevProps, prevState, currentState, currentProps are passed to condition callback.
   * If condition callback returns True then handler will be called.
   * handler has to be encapsulated in component itself.
   */
  constructor(conditionCallback, handler) {
    this.conditionCallback = conditionCallback;
    this.handler = handler;
  }
}

// TODO - This should be removed once all components are converted to Functions
export const handleComponentUpdate = (
  updateHandlers,
  component,
  prevProps,
  prevState,
) => {
  /*
   * Takes UpdateHandler[], instance of component and previous props and state.
   * Should be called on componentDidUpdate.
   * Helps to handle class-based components updates.
   */
  updateHandlers.forEach((updateHandler) => {
    const conditionCallbackParams = {
      prevProps,
      prevState,
      currentState: component.state,
      currentProps: component.props,
    };

    if (updateHandler.conditionCallback(conditionCallbackParams)) {
      updateHandler.handler(component);
    }
  });
};

export const extendArrayIfConditionOrValueExists = (defaultArray, elements) => {
  const defaultArrayCopy = [...defaultArray];

  elements.forEach((element) => {
    const condition = element.options?.condition ?? element.value;
    const hasIndex = element.options && element.options.hasOwnProperty('index');

    if (!condition) {
      return;
    }

    if (hasIndex) {
      const insertIndex = element.options.index;
      defaultArrayCopy.splice(insertIndex, 0, element);
    } else {
      defaultArrayCopy.push(element);
    }
  });

  return defaultArrayCopy;
};

export const getFirstErrorMessage = (error) => {
  const errorProps = Object.keys(error);

  if (error?.message) {
    return error.message;
  }

  if (errorProps.length) {
    for (const prop of errorProps) {
      const errorMessage = getFirstErrorMessage(error[prop]);
      if (typeof errorMessage === 'string') {
        return errorMessage;
      }
    }
  }

  return undefined;
};

export const checkIfValidIPV4 = (ipV4Address) =>
  VALID_IPV4_REGEX.test(ipV4Address);

export const getAddress = (components, type) =>
  components.find((component) => component.types.includes(type))?.long_name;

export const buildStreetName = (route, streetNumber) =>
  route && streetNumber
    ? `${streetNumber} ${route}`
    : DEFAULT_ADRESS_VALUES.street_1;

export const parseActualAddress = ({ address_components, results }) => {
  const components = address_components ?? results[0]?.address_components;
  const street_1 = buildStreetName(
    getAddress(components, 'route'),
    getAddress(components, 'street_number'),
  );
  const state =
    getAddress(components, 'administrative_area_level_1') ||
    DEFAULT_ADRESS_VALUES.state;
  const zip_code =
    getAddress(components, 'postal_code') || DEFAULT_ADRESS_VALUES.zip_code;
  const city =
    getAddress(components, 'locality') ||
    getAddress(components, 'sublocality') ||
    DEFAULT_ADRESS_VALUES.city;
  const country = DEFAULT_ADRESS_VALUES.country;

  return { street_1, street_2: '', zip_code, city, state, country };
};

//TODO: refactor after approve of functionality
export const parseHash = (hash) => {
  const parts = hash.replace('#', '').split(HASH_PARAMS_DELIMITER);
  if (parts.length) {
    return parts.reduce((acc, part) => {
      const partValues = part.split('=');

      const key = partValues[0];
      const value = partValues[1];

      return Object.assign(acc, {
        [key]: value,
      });
    }, {});
  }

  return [];
};

export const getTabValueByHash = (hash, validTabs, defaultTab) => {
  const tabValue = hash.replace('#', '');

  return validTabs.includes(tabValue) ? tabValue : defaultTab;
};

export const getHashByTabValue = (tabValue) => `#${tabValue}`;

export const getTeamUUIDFromHash = (hash) => {
  const hashParams = hash.split(HASH_PARAMS_DELIMITER);
  return hashParams[1];
};

export const clearLS = () => {
  const keys = Object.keys(localStorage);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    localStorage.removeItem(key);
  }
};

export const generateRandomColor = () => {
  //by combination of right shift and bitwise AND operators we extract bits corresponding to a number not exceeding 255
  //R - is extracting from the first 8 bits, G from the next 8 bits and B from the last 8 bits
  //this method is a bit complex, but it allows decrease count of Math.round() calls to 1 instead of generating every shade separately
  const num = Math.round(0xffffff * Math.random());
  const r = num >> 16;
  const g = (num >> 8) & 255;
  const b = num & 255;

  return [r, g, b];
};
