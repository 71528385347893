import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { SlideInfoRow } from 'components/SlideGroups/Views/ListView/SlideInfoRow';
import Box from '@mui/material/Box';
import useCollapse from 'react-collapsed';
import { makeSlidesExpandable } from 'components/SlideGroups/utilities/utilities';
import { DISPLAYED_ROWS_COUNT } from 'components/SlideGroups/utilities/constants';

export const ExpandableSlidesList = ({ slides = [], classes }) => {
  const slidesCount = slides.length;
  const isExpandable = slidesCount > DISPLAYED_ROWS_COUNT;
  const [isExpanded, setIsExpanded] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  if (!slidesCount) return;

  const { visibleRows, invisibleRows } = makeSlidesExpandable(slides);

  const toggleOptionals = () => {
    setIsExpanded(true);
  };

  return (
    <Box>
      {visibleRows.map((slide, index) => (
        <SlideInfoRow index={index} key={slide.uuid} slide={slide} />
      ))}
      {isExpandable && !isExpanded && (
        <Box
          paddingX={'1rem'}
          paddingY={'0.5rem'}
          className={classes.viewMoreContainer}
          {...getToggleProps({ onClick: toggleOptionals })}
        >
          <Typography>View {invisibleRows.length} more ...</Typography>
        </Box>
      )}
      <Box {...getCollapseProps()}>
        {invisibleRows.map((slide, index) => (
          <SlideInfoRow
            index={DISPLAYED_ROWS_COUNT + index}
            key={slide.uuid}
            slide={slide}
          />
        ))}
      </Box>
    </Box>
  );
};
