import { makeStyles } from 'tss-react/mui';

export const useAntibodiesLegendStyles = makeStyles()(() => ({
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const useLegendItemStyles = makeStyles()((theme, { color }) => ({
  legendItemColor: {
    width: '0.75rem',
    height: '0.75rem',
    marginRight: '0.5rem',
    borderRadius: '50%',
    backgroundColor: color,
  },
  legendItem: {
    marginRight: '1rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const useAntibodyTableTooltipStyles = makeStyles()(
  (_theme, { color }) => ({
    pointer: {
      transform: 'rotate(45deg)',
      top: '-0.5rem',
      left: '50%',
      position: 'absolute',
      height: '1rem',
      width: '1rem',
      backgroundColor: color,
    },
    tooltip: {
      position: 'relative',
      backgroundColor: color,
      borderRadius: '5px',
      padding: '1rem',
    },
    tooltipTitle: {
      fontWeight: '500',
      marginBottom: '1rem',
    },
  }),
);

export const useAntibodyDetailsStyles = makeStyles()((theme) => ({
  header: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #e0e0e0',
    height: '4rem',
    width: '100%',
  },
  tabs: {
    padding: '0.5rem 1rem',
  },
  toggleButton: {
    padding: '0.5rem 1.25rem',
    textTransform: 'none',
    backgroundColor: 'white',
    color: '#000',
    '&:hover': {
      backgroundColor: '#d5d5d5',
    },
    height: '2.3rem',
    '&.Mui-selected': {
      backgroundColor: '#EC6A7A1A',
      color: theme.palette.primary.main,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: '#1976d2',
      },
    },
  },
  truncate: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  title: {
    padding: '0.25rem 0 0rem 0.75rem',
    fontSize: '0.75rem',
    color: 'grey',
    textTransform: 'uppercase',
  },
  subTitle: {
    paddingLeft: '0.75rem',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    display: 'flex',
    flexDirection: 'row',
  },
  titleContainer: {
    overflow: 'hidden',
  },
  icon: {
    marginRight: '0.5rem',
  },
}));
