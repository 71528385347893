import { useRemainingAmountIndicatorStyles } from 'components/OrderForm/BillingShipping/BillingTabs/BlanketPurchaseOrders/styles';
import { Box } from '@mui/material';

export const RemainingAmountIndicator = ({ fillPercent }) => {
  const validatedPercent = fillPercent > 100 ? 100 : fillPercent;

  const { classes } = useRemainingAmountIndicatorStyles({
    fillPercent: validatedPercent,
  });

  return (
    <Box className={classes.containerBar}>
      <Box className={classes.fillBar} />
    </Box>
  );
};
