import { Typography, Box } from '@mui/material';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import React from 'react';
import {
  parseLegendIntoDatasets,
  prepareContributedData,
} from 'components/TeamOverview/utilities';
import { useContributedDataStyles } from 'components/TeamOverview/components/ContributedDataCard/styles';
import { BulbIcon } from 'images/icons/BulbIcon';
import { CircularChartOverview } from 'components/TeamOverview/components/CircularChartOverview/CircularChartOverview';
import { transformDataUsage } from 'components/UserProfile/utilities';
import { MB } from 'components/UserProfile/constants';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { ContributedDataCardHeader } from 'components/TeamOverview/components/ContributedDataCard/ContributedDataCardHeader';

export const ContributedDataCard = ({ team, isLoading }) => {
  const { classes } = useContributedDataStyles();

  const isContributedDataAvailable = team?.total_contributed_storage_used > 0;
  const totalDataUsage = isContributedDataAvailable
    ? transformDataUsage(team?.total_contributed_storage_used, MB)
    : 0;
  const dataUsageLegendData = prepareContributedData(team);
  const dataset = parseLegendIntoDatasets(dataUsageLegendData);

  return (
    <Card
      cardClass={classes.contributedDataCard}
      header={<ContributedDataCardHeader classes={classes} />}
    >
      {isLoading ? (
        <Box className={classes.noDataContainer}>
          <Spinner size={40} />
        </Box>
      ) : (
        <>
          {isContributedDataAvailable ? (
            <CircularChartOverview
              dataset={dataset}
              totalDataLabel={'Contributed Data'}
              totalData={totalDataUsage}
              legendData={dataUsageLegendData}
            />
          ) : (
            <Box className={classes.noDataContainer}>
              <Typography className={classes.greyText}>
                NO CONTRIBUTED DATA
              </Typography>
            </Box>
          )}
          <Box
            className={
              isContributedDataAvailable
                ? classes.hintBox
                : classes.noDataHintBox
            }
          >
            <BulbIcon size={32} />
            <Typography className={classes.hintText}>
              This data will be used for internal training purposes and will not
              be shared externally.
            </Typography>
          </Box>
        </>
      )}
    </Card>
  );
};
