import shallow from 'zustand/shallow';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import {
  SideBySideViewer,
  PictureInPictureViewer,
  VolumeViewer,
  AdditiveColormapExtension,
  LensExtension,
} from '@hms-dbmi/viv';
import {
  useImageSettingsStore,
  useViewerStore,
  useChannelsStore,
  useLoader,
} from 'components/IFViewer/state';
import { useWindowSize, mapRange } from 'components/IFViewer/utils';
import {
  DEFAULT_OVERVIEW,
  DEFAULT_ZOOM_MIN,
  MAIN_VIEW_LAYER,
  OVERVIEW_LAYER,
  LEFT_VIEW_LAYER,
  RIGHT_VIEW_LAYER,
  DEFAULT_ZOOM_LEVEL,
  DEFAULT_ZOOM_MAX,
} from 'components/IFViewer/constants';
import { useResizeObserver } from 'components/utilities/hooks/useResizeObserver';
import { parseParamsForIFFromUrl } from 'components/OrderSlidesList/utilities';
import { updateHashParams } from '../helpers/functions';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const Viewer = forwardRef(
  (
    {
      deckRef,
      onRerender,
      zoomLevelForUI,
      setZoomLevelForUI,
      isFullscreen,
      pan,
      updateZoomLevel,
      zoomLevelLeftForUI,
      zoomLevelRightForUI,
    },
    ref,
  ) => {
    const { showWarning } = useSnackbar();

    const [useLinkedView, use3d, viewState] = useViewerStore(
      (store) => [store.useLinkedView, store.use3d, store.viewState],
      shallow,
    );
    const [colors, contrastLimits, channelsVisible, selections] =
      useChannelsStore(
        (store) => [
          store.colors,
          store.contrastLimits,
          store.channelsVisible,
          store.selections,
        ],
        shallow,
      );

    const fullscreenLayoutStyle = isFullscreen
      ? {
          position: 'relative',
          width: '100%',
          height: '100%',
        }
      : {};

    const loader = useLoader();
    const [x, setX] = useState(null);
    const [y, setY] = useState(null);

    const [zoomSelected, setZoomSelected] = useState(null);
    const [cursorCoordinates, setCursorCoordinates] = useState({ x: 0, y: 0 });
    const [detailsViewerPosition, setDetailsViewerPosition] = useState(null);
    const [isHoveringOverview, setIsHoveringOverview] = useState(false);
    const [coordinatesForPans, setCoordinatesForPans] = useState({
      left: { x: 0, y: 0 },
      right: { x: 0, y: 0 },
    });
    const [zoomForPans, setZoomForPans] = useState({
      left: DEFAULT_ZOOM_MIN,
      right: DEFAULT_ZOOM_MIN,
    });
    const [viewStateForPans, setViewStateForPans] = useState([]);
    const history = useHistory();
    const { pathname } = useLocation();

    const [viewerWidth, setViewerWidth] = useState(0);
    const [viewerHeight, setViewerHeight] = useState(0);
    const viewerContainerRef = useResizeObserver((wrapper) => {
      setViewerWidth(wrapper.offsetWidth);
      setViewerHeight(wrapper.offsetHeight);
    });
    const viewSize = useWindowSize(1, 0.86);

    const [
      lensSelection,
      colormap,
      renderingMode,
      xSlice,
      ySlice,
      zSlice,
      resolution,
      lensEnabled,
      zoomLock,
      panLock,
      isOverviewOn,
      onViewportLoad,
      useFixedAxis,
    ] = useImageSettingsStore(
      (store) => [
        store.lensSelection,
        store.colormap,
        store.renderingMode,
        store.xSlice,
        store.ySlice,
        store.zSlice,
        store.resolution,
        store.lensEnabled,
        store.zoomLock,
        store.panLock,
        store.isOverviewOn,
        store.onViewportLoad,
        store.useFixedAxis,
      ],
      shallow,
    );

    const setDefaultPosition = () => {
      setDetailsViewerPosition({
        x: xSlice[1] / 2,
        y: ySlice[1] / 2,
      });
      setZoomSelected(DEFAULT_ZOOM_MIN);
    };

    const setDefaultPositionForPans = () => {
      setCoordinatesForPans({
        left: {
          x: xSlice[1] / 2,
          y: ySlice[1] / 2,
        },
        right: {
          x: xSlice[1] / 2,
          y: ySlice[1] / 2,
        },
      });
    };

    const resetPositionForPans = (panLock, pan) => {
      if (panLock) {
        setDefaultPositionForPans();
      } else {
        setCoordinatesForPans({
          ...coordinatesForPans,
          [pan]: {
            x: xSlice[1] / 2,
            y: ySlice[1] / 2,
          },
        });
      }
    };

    const updateZoomForPans = (zoomLock, pan, zoomLevel) => {
      if (zoomLock) {
        setZoomForPans({
          left: zoomLevel,
          right: zoomLevel,
        });
        if (zoomLevel === DEFAULT_ZOOM_MIN) {
          updateZoomLevel(LEFT_VIEW_LAYER, DEFAULT_ZOOM_LEVEL);
          updateZoomLevel(RIGHT_VIEW_LAYER, DEFAULT_ZOOM_LEVEL);
        }
      } else {
        setZoomForPans({
          ...zoomForPans,
          [pan]: zoomLevel,
        });
        if (zoomLevel === DEFAULT_ZOOM_MIN) {
          updateZoomLevel(pan, DEFAULT_ZOOM_LEVEL);
        }
      }
    };

    const setDefaultPositionRight = () => {
      resetPositionForPans(panLock, RIGHT_VIEW_LAYER);
      updateZoomForPans(zoomLock, RIGHT_VIEW_LAYER, DEFAULT_ZOOM_MIN);
    };

    const setDefaultPositionLeft = () => {
      resetPositionForPans(panLock, LEFT_VIEW_LAYER);
      updateZoomForPans(zoomLock, LEFT_VIEW_LAYER, DEFAULT_ZOOM_MIN);
    };

    useImperativeHandle(ref, () => ({
      resetSlide(pan) {
        switch (pan) {
          case LEFT_VIEW_LAYER:
            setDefaultPositionLeft();
            break;
          case RIGHT_VIEW_LAYER:
            setDefaultPositionRight();
            break;
          default:
            setDefaultPosition();
            break;
        }
      },
    }));

    const setParamsToState = () => {
      const ifParams = parseParamsForIFFromUrl();
      const xParam = +ifParams.x;
      const yParam = +ifParams.y;

      setX(xParam);
      setY(yParam);
      setDetailsViewerPosition({ x: xParam, y: yParam });
      setZoomLevelForUI(+ifParams.zoom || DEFAULT_ZOOM_LEVEL);
    };

    useEffect(() => {
      setParamsToState();
    }, []);

    useEffect(() => {
      if (!useLinkedView) return;

      setTimeout(() => {
        setDefaultPositionForPans();
      }, 0);
    }, [useLinkedView]);

    useEffect(() => {
      if (!xSlice || !ySlice) return;

      x && setX(null);
      y && setY(null);

      const ifParams = parseParamsForIFFromUrl();
      // Set the x and y coordinates to center if no x and y coordinates are set and not exist in the URL

      !ifParams.x &&
        !ifParams.y &&
        !detailsViewerPosition &&
        setDefaultPosition();
      // Map the zoom level to the zoom range
      switch (pan) {
        case LEFT_VIEW_LAYER:
          if (!zoomLevelLeftForUI) return;
          updateZoomForPans(
            zoomLock,
            LEFT_VIEW_LAYER,
            mapRange(zoomLevelLeftForUI),
          );
          break;
        case RIGHT_VIEW_LAYER:
          if (!zoomLevelRightForUI) return;
          updateZoomForPans(
            zoomLock,
            RIGHT_VIEW_LAYER,
            mapRange(zoomLevelRightForUI),
          );
          break;
        default:
          setZoomSelected(mapRange(zoomLevelForUI));
      }
    }, [
      zoomLevelForUI,
      xSlice,
      ySlice,
      pan,
      zoomLevelLeftForUI,
      zoomLevelRightForUI,
    ]);

    const checkIfZoomValidAndShowSnackbar = (unverifiedZoom) => {
      if (
        unverifiedZoom > DEFAULT_ZOOM_MAX ||
        unverifiedZoom < DEFAULT_ZOOM_MIN
      ) {
        showWarning(
          `You have reached the ${
            unverifiedZoom > DEFAULT_ZOOM_MAX ? 'maximum' : 'minimum'
          } zoom level for this slide`,
          '',
          { preventDuplicate: true },
        );
      }
    };

    useEffect(() => {
      const leftPanCoordinates = coordinatesForPans[LEFT_VIEW_LAYER];
      const rightPanCoordinates = coordinatesForPans[RIGHT_VIEW_LAYER];
      const { x: leftPanX, y: leftPanY } = leftPanCoordinates || {};
      const { x: rightPanX, y: rightPanY } = rightPanCoordinates || {};

      const leftPanZoom = zoomForPans[LEFT_VIEW_LAYER];
      const rightPanZoom = zoomForPans[RIGHT_VIEW_LAYER];

      setViewStateForPans([
        {
          target: [leftPanX, leftPanY],
          zoom: leftPanZoom,
          id: LEFT_VIEW_LAYER,
        },
        {
          target: [rightPanX, rightPanY],
          zoom: rightPanZoom,
          id: RIGHT_VIEW_LAYER,
        },
      ]);
    }, [
      coordinatesForPans[LEFT_VIEW_LAYER],
      coordinatesForPans[RIGHT_VIEW_LAYER],
      zoomForPans[LEFT_VIEW_LAYER],
      zoomForPans[RIGHT_VIEW_LAYER],
    ]);

    const onViewStateChange = (props) => {
      const {
        viewState: { target, zoom: unverifiedZoom },
      } = props;

      if (!unverifiedZoom) return;

      const zoom =
        unverifiedZoom > DEFAULT_ZOOM_MAX
          ? DEFAULT_ZOOM_MAX
          : unverifiedZoom < DEFAULT_ZOOM_MIN
          ? DEFAULT_ZOOM_MIN
          : unverifiedZoom;

      // Set the x and y coordinates when panning
      setDetailsViewerPosition({
        x: target[0],
        y: target[1],
      });

      // Reset the x and y coordinates when zooming
      setX(null);
      setY(null);

      setZoomSelected(+zoom);
      setZoomLevelForUI(+mapRange(zoom, true).toFixed(1));

      const z = Math.min(Math.max(Math.round(-zoom), 0), loader.length - 1);

      useViewerStore.setState({
        pyramidResolution: z,
      });

      checkIfZoomValidAndShowSnackbar(unverifiedZoom);
    };

    const onViewStateChangeForSideBySide = (props) => {
      const {
        viewState: { target, zoom: unverifiedZoom },
        viewId,
      } = props;

      if (!unverifiedZoom) return;

      const zoom =
        unverifiedZoom > DEFAULT_ZOOM_MAX
          ? DEFAULT_ZOOM_MAX
          : unverifiedZoom < DEFAULT_ZOOM_MIN
          ? DEFAULT_ZOOM_MIN
          : unverifiedZoom;

      const zoomOffset = zoomForPans[viewId] - zoom;

      if (!zoomOffset) {
        // Set the x and y coordinates when panning
        if (panLock) {
          setCoordinatesForPans({
            left: {
              x: target[0],
              y: target[1],
            },
            right: {
              x: target[0],
              y: target[1],
            },
          });
        } else {
          setCoordinatesForPans({
            ...coordinatesForPans,
            [viewId]: {
              x: target[0],
              y: target[1],
            },
          });
        }
      }

      if (zoomLock) {
        setZoomForPans({
          [viewId]: zoom,
          [viewId === RIGHT_VIEW_LAYER ? LEFT_VIEW_LAYER : RIGHT_VIEW_LAYER]:
            zoom + zoomOffset,
        });

        updateZoomLevel(RIGHT_VIEW_LAYER, +mapRange(zoom, true).toFixed(1));
        updateZoomLevel(LEFT_VIEW_LAYER, +mapRange(zoom, true).toFixed(1));
      } else {
        setZoomForPans({
          ...zoomForPans,
          [viewId]: +zoom,
        });
        updateZoomLevel(viewId, +mapRange(zoom, true).toFixed(1));
      }

      checkIfZoomValidAndShowSnackbar(unverifiedZoom);
    };

    const deckGLprops = {
      ref: deckRef,
      onAfterRender: onRerender,
    };

    const onHover = (v) => {
      //  don't do anything if we're not hovering over the overview
      if (!(v.viewport?.id === OVERVIEW_LAYER) || !v.coordinate) {
        return setIsHoveringOverview(false);
      }
      // get the ratio of the overview to the detail view
      const center = v.viewport?.center;
      const ratio = xSlice[1] / (center[0] * 2);

      setIsHoveringOverview(true);
      // set the cursor coordinates to main view coordinates
      setCursorCoordinates({
        x: v.coordinate[0] * ratio,
        y: v.coordinate[1] * ratio,
      });
    };

    const handleClick = () => {
      if (isHoveringOverview) {
        setX(cursorCoordinates.x);
        setY(cursorCoordinates.y);
        // Set the x and y coordinates to the cursor coordinates to use that position while zooming
        setDetailsViewerPosition({
          x: cursorCoordinates.x,
          y: cursorCoordinates.y,
        });
      }
    };

    const viewStateToMove =
      x && y // View state to move to the selected position from overview
        ? {
            target: [x, y],
            zoom: zoomSelected || DEFAULT_ZOOM_LEVEL,
            id: MAIN_VIEW_LAYER,
          }
        : zoomSelected // View state to zoom to the selected position from zoom buttons
        ? {
            target: [detailsViewerPosition?.x, detailsViewerPosition?.y],
            zoom: zoomSelected,
            id: MAIN_VIEW_LAYER,
          }
        : {};

    const updateUrlHandler = () => {
      const zoom = zoomLevelForUI || DEFAULT_ZOOM_LEVEL;
      const x = viewStateToMove.target?.[0];
      const y = viewStateToMove.target?.[1];

      if (!zoom || !x || !y) return;

      const updatedHashParams = updateHashParams();
      const newUrl = `${pathname}#zoom=${zoom}&x=${x}&y=${y}${updatedHashParams}`;
      history.replace(newUrl);
    };

    useEffect(() => {
      updateUrlHandler();
    }, [zoomLevelForUI, detailsViewerPosition]);

    return (
      <div
        ref={viewerContainerRef}
        style={fullscreenLayoutStyle}
        onClick={handleClick}
      >
        {use3d ? (
          <VolumeViewer
            deckProps={deckGLprops}
            loader={loader}
            contrastLimits={contrastLimits}
            colors={colors}
            channelsVisible={channelsVisible}
            selections={selections}
            colormap={colormap}
            xSlice={xSlice}
            ySlice={ySlice}
            zSlice={zSlice}
            resolution={resolution}
            renderingMode={renderingMode}
            height={viewerHeight || viewSize.height}
            width={viewerWidth || viewSize.width}
            onViewportLoad={onViewportLoad}
            useFixedAxis={useFixedAxis}
            viewStates={[viewState]}
            snapScaleBar
            onViewStateChange={debounce(
              ({ viewState: newViewState, viewId }) =>
                useViewerStore.setState({
                  viewState: { ...newViewState, id: viewId },
                }),
              250,
              { trailing: true },
            )}
          />
        ) : useLinkedView ? (
          <SideBySideViewer
            deckProps={deckGLprops}
            loader={loader}
            contrastLimits={contrastLimits}
            colors={colors}
            channelsVisible={channelsVisible}
            selections={selections}
            height={viewerHeight || viewSize.height}
            width={viewerWidth || viewSize.width}
            zoomLock={zoomLock}
            panLock={panLock}
            hoverHooks={{
              handleValue: (v) => useViewerStore.setState({ pixelValues: v }),
            }}
            lensSelection={lensSelection}
            lensEnabled={lensEnabled}
            onViewportLoad={onViewportLoad}
            extensions={[
              colormap ? new AdditiveColormapExtension() : new LensExtension(),
            ]}
            colormap={colormap || 'viridis'}
            onViewStateChange={onViewStateChangeForSideBySide}
            viewStates={viewStateForPans}
            snapScaleBar
          />
        ) : (
          <PictureInPictureViewer
            deckProps={deckGLprops}
            loader={loader}
            contrastLimits={contrastLimits}
            colors={colors}
            channelsVisible={channelsVisible}
            selections={selections}
            height={viewerHeight || viewSize.height}
            width={viewerWidth || viewSize.width}
            overview={DEFAULT_OVERVIEW}
            overviewOn={isOverviewOn}
            hoverHooks={{
              handleValue: (v) => useViewerStore.setState({ pixelValues: v }),
            }}
            lensSelection={lensSelection}
            lensEnabled={lensEnabled}
            onViewportLoad={onViewportLoad}
            extensions={[
              colormap ? new AdditiveColormapExtension() : new LensExtension(),
            ]}
            colormap={colormap || 'viridis'}
            onViewStateChange={debounce(onViewStateChange, 200)}
            onHover={debounce(onHover, 100)}
            viewStates={[viewStateToMove]}
            snapScaleBar
          />
        )}
      </div>
    );
  },
);
