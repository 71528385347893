import { Typography } from '@mui/material';
import { ProgressItemCard } from 'components/OrderDetails/Tabs/OrderProgressTab/ProgressItemCard';
import Box from '@mui/material/Box';
import { Chip } from 'components/OrderDetails/Tabs/OrderProgressTab/Chip';
import { memo } from 'react';
import { prepareRequestServices } from 'components/OrderDetails/Tabs/OrderProgressTab/utilities';
import {
  formatDatetimeStringToDates,
  MONTH_DAY_YEAR_WITH_COMMA_FORMAT,
} from 'utilities/dates_and_times';
import { ORDER_CANCELLED_TRACKER_STATE } from 'components/OrdersList/constants';
import { useOrderDetailsStyles } from 'components/OrderDetails/Tabs/OrderProgressTab/styles';

export const OrderDetails = memo(({ order, samples }) => {
  const { classes } = useOrderDetailsStyles();

  const requestedServicesChips = prepareRequestServices(samples, order);

  if (!requestedServicesChips.length) return null;

  const histologyTurnaroundDatetime = formatDatetimeStringToDates(
    order.turnaround_due_datetime,
    MONTH_DAY_YEAR_WITH_COMMA_FORMAT,
  );

  const isOrderCancelled =
    order.tracker_state === ORDER_CANCELLED_TRACKER_STATE;

  return (
    <ProgressItemCard label={'Order Details'} width={'max-content'}>
      {!isOrderCancelled ? (
        <Box display={'flex'} flexDirection={'row'}>
          <Typography variant={'h5'} className={classes.requestedServicesLabel}>
            Estimated Completion Date:
          </Typography>
          <Typography variant={'h5'} className={classes.tatDatetime}>
            {histologyTurnaroundDatetime}
          </Typography>
        </Box>
      ) : null}

      <Typography variant={'h5'} className={classes.requestedServicesLabel}>
        Requested services:
      </Typography>
      <Box className={classes.chipContainers}>
        {requestedServicesChips.map((chip) => (
          <Chip key={chip.name} chipName={chip.name} color={chip.color} />
        ))}
      </Box>
    </ProgressItemCard>
  );
});
