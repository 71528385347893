import React from 'react';
import { PRIMARY_PINK } from 'utilities/colors';

export const HardDriveIcon = ({ color = PRIMARY_PINK, size = 18 }) => {
  return (
    <svg width={size} height={size} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="m3.26 1.02-.22.06a1.8 1.8 0 0 0-.36.18 2.1 2.1 0 0 0-.4.4l-.14.22c-.03.06-.08.18-.1.26L2 2.28v13c.06.21.1.33.14.4l.12.2a2.11 2.11 0 0 0 .39.4 1.8 1.8 0 0 0 .52.23c.12.04.32.04 5.83.04h5.71l.4-.14.2-.12a1.85 1.85 0 0 0 .46-.48 1.72 1.72 0 0 0 .21-.59c.01-.1.02-2.1.02-6.44 0-4.35 0-6.35-.02-6.45l-.07-.29a1.8 1.8 0 0 0-.14-.3 1.84 1.84 0 0 0-.47-.49 1.86 1.86 0 0 0-.45-.2L14.7 1C4.46 1 3.37 1 3.26 1.02Zm.29 5.03v3.5h10.9v-7H3.54v3.5Zm0 7V15h10.9v-3.89H3.54v1.95Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        d="m10.1 12.8.2-.1a.85.85 0 0 1 .65.07l.16.13c.05.05.1.13.13.18.03.06.06.14.07.2.02.06.02.17.01.25 0 .11-.02.17-.06.26a.8.8 0 0 1-.17.21c-.06.06-.14.12-.2.15l-.16.05-.18.02a.92.92 0 0 1-.4-.1A1.05 1.05 0 0 1 10 14a1.03 1.03 0 0 1-.15-.21c-.05-.11-.06-.13-.06-.33s0-.23.05-.32l.1-.18a.7.7 0 0 1 .17-.15ZM12.51 12.75l.17-.06a1.16 1.16 0 0 1 .51.03c.08.03.15.08.22.15a.77.77 0 0 1 .23.76l-.08.21a.92.92 0 0 1-.14.17.92.92 0 0 1-.17.12.92.92 0 0 1-.2.07l-.18.02c-.05 0-.14 0-.2-.02a.93.93 0 0 1-.2-.08.84.84 0 0 1-.15-.13.84.84 0 0 1-.14-.2c-.06-.13-.06-.14-.06-.34s0-.22.06-.34a.8.8 0 0 1 .33-.35Z"
      />
    </svg>
  );
};
