import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import React from 'react';

export const AddSlideGroupButton = ({ setSlideGroupModalOpen, classes }) => {
  const onClick = () => {
    setSlideGroupModalOpen(true);
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      className={classes.addSlideGroupButton}
    >
      <Button
        fullWidth={true}
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        Add Slide Group
      </Button>
    </Box>
  );
};
