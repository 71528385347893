import { Tab, Tabs } from '@mui/material';
import { SLIDE_SEARCH_TAB_STYLES } from 'components/PathologyMap/constants/styles';
import {
  ACTIONS_TAB_VALUE,
  GROUP_TAB_VALUE,
  SLIDE_METADATA_TAB_VALUE,
} from 'components/PathologyMap/constants/common';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useSlideInfoTabsStyles } from 'components/PathologyMap/Modals/FullSlideInfoModal/styles';

export const SlideInfoTabs = ({ currentTabValue, handleTabChange }) => {
  const { classes } = useSlideInfoTabsStyles();

  return (
    <Tabs
      value={currentTabValue}
      onChange={handleTabChange}
      indicatorColor={'primary'}
      classes={{
        root: classes.tabs,
        indicator: classes.indicator,
      }}
    >
      <Tab
        style={SLIDE_SEARCH_TAB_STYLES}
        classes={{
          selected: classes.selectedTab,
        }}
        value={SLIDE_METADATA_TAB_VALUE}
        icon={<BarChartIcon className={classes.tabIcon} />}
        label="metadata"
      />
      <Tab
        style={SLIDE_SEARCH_TAB_STYLES}
        classes={{
          selected: classes.selectedTab,
        }}
        value={GROUP_TAB_VALUE}
        icon={<AddIcon className={classes.tabIcon} />}
        label="groups"
      />
      <Tab
        style={SLIDE_SEARCH_TAB_STYLES}
        classes={{
          selected: classes.selectedTab,
        }}
        value={ACTIONS_TAB_VALUE}
        icon={<MoreVertIcon className={classes.tabIcon} />}
        label="actions"
      />
      {/*TODO: uncomment after AI Analysis releasing*/}
      {/*<Tab*/}
      {/*  style={SLIDE_SEARCH_TAB_STYLES}*/}
      {/*  classes={{*/}
      {/*    selected: classes.selectedTab,*/}
      {/*    labelIcon: classes.tabIcon,*/}
      {/*  }}*/}
      {/*  value={"lld"}*/}
      {/*  icon={<AIIcon />}*/}
      {/*  label="AI Analysis"*/}
      {/*/>*/}
    </Tabs>
  );
};
