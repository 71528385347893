import { Box, Tooltip, Typography } from '@mui/material';
import { useRowInfoStyles } from 'components/pages/AppLibrary/components/styles';
import InfoIcon from '@mui/icons-material/Info';
import { getFormattedValueForAITool } from 'components/pages/AppLibrary/utilities';

export const RowInfo = ({
  title,
  info,
  noBold = true,
  noTooltip = false,
  noBorder = false,
  isSmall = false,
}) => {
  const { classes } = useRowInfoStyles({ noBorder, noBold, isSmall });

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.info}>
        {!noTooltip && (
          <Tooltip title="test">
            <InfoIcon />
          </Tooltip>
        )}
        {getFormattedValueForAITool(title, info)}
      </Typography>
    </Box>
  );
};
