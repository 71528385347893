import React, { useContext } from 'react';
import CreateNewFolder from '@mui/icons-material/CreateNewFolder';
import { AddBox } from '@mui/icons-material';

import { AppContext } from 'services/context';
import {
  PLACE_ORDER_INITIAL_URL,
  PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM,
  TEAM_OVERVIEW_URL,
} from 'constants/urls';
import { ShowPathologistUserOnly } from 'components/ConditionalWrappers/ShowPathologistUserOnly';
import { SidebarListItem } from 'components/Layout/Sidebar/SidebarListItem';
import { SidebarList } from 'components/Layout/Sidebar/SidebarList';
import { TeamOverviewBetaIcon } from 'components/Layout/Sidebar/Icons/TeamOverviewBetaIcon';

export const NewOrderList = () => {
  const context = useContext(AppContext);

  const { updateSelectedOrderFormVersion } = context;

  return (
    <SidebarList>
      <SidebarListItem
        Icon={CreateNewFolder}
        name="New Order"
        path={PLACE_ORDER_INITIAL_URL}
        handler={() => updateSelectedOrderFormVersion('v1')}
      />
      <ShowPathologistUserOnly>
        <SidebarListItem
          Icon={AddBox}
          name="New Consultation"
          path={PLACE_PATHOLOGY_CONSULTATION_ORDER_FORM}
        />
      </ShowPathologistUserOnly>
      <SidebarListItem
        Icon={TeamOverviewBetaIcon}
        name="Team Overview"
        path={TEAM_OVERVIEW_URL}
      />
    </SidebarList>
  );
};
