import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = [];

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, { payload }) => payload,
  },
});

export const { setOrders } = ordersSlice.actions;

export const ordersReducer = ordersSlice.reducer;

export const ordersSelector = createSelector(
  (state) => state,
  (state) => state.orders,
);
