import {
  CustomTableCell,
  SamplesListCustomTableCell,
} from 'components/OrdersList/constants';
import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { textColor }) => ({
  slidesCount: {
    fontSize: '1rem',
    color: textColor,
  },
}));

export const SampleTableHeader = (
  <TableHead>
    <TableRow>
      <CustomTableCell padding={'none'}>Sample Name</CustomTableCell>
      <CustomTableCell padding={'none'}>H&E Requested</CustomTableCell>
      <CustomTableCell padding={'none'}>H&E Uploaded</CustomTableCell>
      <CustomTableCell padding={'none'}>
        Special Stains Requested
      </CustomTableCell>
      <CustomTableCell padding={'none'}>
        Special Stains Uploaded
      </CustomTableCell>
      <CustomTableCell padding={'none'}>IHC Requested</CustomTableCell>
      <CustomTableCell padding={'none'}>IHC Uploaded</CustomTableCell>
      <CustomTableCell padding={'none'}>Unstained Requested</CustomTableCell>
      <CustomTableCell padding={'none'}>Scan Only</CustomTableCell>
      <CustomTableCell padding={'none'}>IF Scan Only</CustomTableCell>
      <CustomTableCell padding={'none'}>Total Requested</CustomTableCell>
      <CustomTableCell padding={'none'}>Uploaded (Ign. Unst)</CustomTableCell>
    </TableRow>
  </TableHead>
);

export const SampleTableRow = ({ row }) => {
  const isMissing =
    row.uploaded_slides_count -
    row.total_slides_requested +
    row.requested_unstained_count;
  const colorPattern = isMissing ? 'red' : 'white';
  const textColorPattern = isMissing ? 'white' : 'black';

  const { classes } = useStyles({ textColor: textColorPattern });

  let samplesLISURL = '';
  const containsIDKey = !!row.id;

  if (containsIDKey) {
    samplesLISURL = `${process.env.REACT_APP_API_URL}/lisa/core/sample/${row.id}/change/`;
  }
  const url = (
    <a href={samplesLISURL} target="_blank" rel="noopener noreferrer">
      {row.name}
    </a>
  );

  return (
    <TableRow>
      <SamplesListCustomTableCell component="th" scope="row" padding="none">
        {containsIDKey ? url : row.name}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.requested_h_and_e_stain_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.uploaded_he_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.requested_special_stains_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.uploaded_special_stains_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.requested_antibodies_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.uploaded_antibodies_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.requested_unstained_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.requested_slide_scanning_only_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.requested_if_slide_scanning_only_count}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell padding="none">
        {row.total_slides_requested}
      </SamplesListCustomTableCell>
      <SamplesListCustomTableCell
        padding="none"
        style={{ backgroundColor: colorPattern }}
      >
        <Typography variant={'h5'} className={classes.slidesCount}>
          {row.uploaded_slides_count}
        </Typography>
      </SamplesListCustomTableCell>
    </TableRow>
  );
};

export const SamplesTable = ({ samples }) => (
  <Table>
    {SampleTableHeader}
    <TableBody>
      {samples.map((row) => {
        return <SampleTableRow row={row} key={row.uuid} />;
      })}
    </TableBody>
  </Table>
);
