import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { TeamSelectionCard } from 'components/UserProfile/TeamOverviewCard/TeamSelectionCard';
import { useLocation } from 'react-router';
import { useGetTeamsQuery } from 'store/apis/teamsApi';
import { CardsMenuSection } from 'components/TeamOverview/CardsMenuSection/CardsMenuSection';
import {
  COMPUTE_SECTION,
  DATA_MANAGEMENT_SECTION,
  ORDER_HISTORY_SECTION,
  PRICE_ROUNDING_COEFFICIENT,
  SlideType,
  TEAM_MEMBERS_SECTION,
  TEAM_OVERVIEW_SECTIONS,
} from 'components/TeamOverview/constants';
import { DataManagementSection } from 'components/TeamOverview/DataManagementSection/DataManagementSection';
import { OrderHistorySection } from 'components/TeamOverview/OrderHistorySection/OrderHistorySection';
import { TeamSection } from 'components/TeamOverview/TeamSection/TeamSection';
import { ComputeSection } from 'components/TeamOverview/ComputeSection/ComputeSection';
import { ChangesModal } from 'components/UserProfile/components/ChangesModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectForceOpenTeamOverviewModal,
  setForceOpenTeamOverviewModal,
} from 'store/slices/userDetailsSlice';
import { getTabValueByHash } from 'utilities/general';
import { useTabsNavigation } from 'utilities/hooks/useTabsNavigation/useTabsNavigation';

export const TeamOverviewV2 = () => {
  useTitle('HistoWiz - Team Overview');

  const showChangesModal = useSelector(selectForceOpenTeamOverviewModal);
  const dispatch = useDispatch();
  const location = useLocation();
  const [changesModalOpen, setChangesModalOpen] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [slideType, setSlideType] = useState(SlideType.IHC);
  const [selectedSection, setSelectedSection] = useState(
    getTabValueByHash(
      location.hash,
      TEAM_OVERVIEW_SECTIONS,
      ORDER_HISTORY_SECTION,
    ),
  );
  const { data: teams, isLoading: isTeamsLoading } = useGetTeamsQuery();

  useTabsNavigation(selectedSection);

  useEffect(() => {
    if (!teams?.length) return;

    if (!selectedTeam) {
      setSelectedTeam(teams[0]);
    } else {
      setSelectedTeam(teams.find((team) => team.uuid === selectedTeam.uuid));
    }
  }, [teams]);

  const handleCloseChangesModal = () => {
    setChangesModalOpen(false);
    dispatch(setForceOpenTeamOverviewModal(false));
  };

  const headerLabel = `${selectedTeam?.name || 'Team'} Overview`;

  const dataUsage = +(
    selectedTeam?.total_storage_used / Math.pow(1000, 2)
  ).toFixed(2);

  const price =
    dataUsage < 0.05 ? '0' : Math.ceil(dataUsage) * PRICE_ROUNDING_COEFFICIENT;

  const SECTIONS = {
    [ORDER_HISTORY_SECTION]: (
      <OrderHistorySection selectedTeam={selectedTeam} />
    ),
    [DATA_MANAGEMENT_SECTION]: (
      <DataManagementSection
        isTeamsLoading={isTeamsLoading}
        team={selectedTeam}
        slideType={slideType}
        setSlideType={setSlideType}
      />
    ),
    [COMPUTE_SECTION]: <ComputeSection team={selectedTeam} />,
    [TEAM_MEMBERS_SECTION]: <TeamSection team={selectedTeam} />,
  };

  return (
    <MiniDrawerWithContext header={headerLabel}>
      <Grid item container xs={12} rowSpacing={2}>
        <TeamSelectionCard
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          teams={teams}
        />
        <CardsMenuSection
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          selectedTeam={selectedTeam}
        />
        <Grid item container>
          {SECTIONS[selectedSection]}
        </Grid>
      </Grid>
      {selectedTeam && (
        <ChangesModal
          forceOpen={showChangesModal}
          open={changesModalOpen}
          onClose={handleCloseChangesModal}
          dataUsage={dataUsage}
          price={price}
          teamName={selectedTeam?.name}
        />
      )}
    </MiniDrawerWithContext>
  );
};
