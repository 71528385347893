import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import {
  ACCOUNT_REP,
  ORDER_NAME,
  ORGANIZATION,
  PATH_CONTRIBUTION,
  PHONE_NUMBER,
  PROJECT,
  TAT,
  TEAM,
  TEAM_MEMBERS,
} from 'components/OrderForm/components/constants';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  alternativeHeader: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    borderColor: theme.palette.secondary.light,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  row: {
    '&:nth-child(4n+1), &:nth-child(4n+2)': {
      backgroundColor: theme.palette.background.default,
    },
    height: '65px',
    padding: '0.5rem',
  },
  expansionDetails: {
    padding: 0,
  },
  rowName: { float: 'left', fontSize: '24px' },
  rowValue: { fontSize: '24px' },
});

const useStyles = makeStyles()(styles);

const renderOrderInfo = (data, classes) =>
  data.map(({ name, value }) => {
    return (
      <Grid key={name} className={classes.row} item xs={6}>
        <Typography className={classes.rowName}>{name}</Typography>
        <Typography className={classes.rowValue} align="right">
          {value.length > 25 ? (
            <Tooltip title={value}>
              <span>{value.substring(0, 25)}...</span>
            </Tooltip>
          ) : (
            value || '---'
          )}
        </Typography>
      </Grid>
    );
  });

const NewSummaryTable = ({ data, hidden }) => {
  const defaultExpanded = !hidden;

  const { classes } = useStyles();

  return (
    <Paper className={classes.root}>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          className={classes.alternativeHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h5" color={'inherit'}>
            Summary
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionDetails}>
          <Grid container>{renderOrderInfo(data, classes)}</Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export const NewOrderSummaryTable = ({ order }) => {
  const pathologyMapConsent = order.pathology_map_consent ? 'Yes' : 'No';

  const teamMembers = order.team.members.map(({ email }) => email);
  const teamMembersString = teamMembers.join(', ');

  const teamMembersWithPhone = order.team.members.filter((member) => {
    return member.phone_number;
  });
  const phoneNumbers = teamMembersWithPhone.map((member) => {
    return `${member.phone_number} (${member.first_name})`;
  });
  const phoneNumbersString = phoneNumbers.join(', ');

  const data = [
    { name: ORDER_NAME, value: order.name },
    { name: TEAM_MEMBERS, value: teamMembersString },
    { name: ORGANIZATION, value: order.organization.name },
    { name: PHONE_NUMBER, value: phoneNumbersString },
    { name: PROJECT, value: order.project.name },
    { name: TEAM, value: order.team.name },
    { name: ACCOUNT_REP, value: order.team.account_rep?.email || '' },
    { name: TAT, value: order.turnaround_days },
    {
      name: PATH_CONTRIBUTION,
      value: pathologyMapConsent,
    },
  ];

  return <NewSummaryTable data={data} />;
};
