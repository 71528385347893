import { Box, Link } from '@mui/material';
import React from 'react';
import { useStyles } from 'components/Modals/ShareSlideModal/styles.js';
import {
  SIMPLE_LINK,
  SOCIAL_ICONS,
} from 'components/Modals/ShareSlideModal/constants';
import { getSocialShareLink } from 'components/Modals/ShareSlideModal/helpers';

export const SocialIcons = ({ fullUrl, handleCopyLink, isIFViewer }) => {
  const { classes } = useStyles({ isIFViewer: isIFViewer });

  return (
    <Box className={classes.iconsWrapper}>
      {SOCIAL_ICONS.map((icon) => {
        const socialLink = getSocialShareLink(icon.id, fullUrl);
        const isSimpleLink = icon.id === SIMPLE_LINK;
        const SocialIcon = icon.icon;

        return (
          <Box
            component={Link}
            href={isSimpleLink ? undefined : socialLink}
            target="_blank"
            key={icon.id}
            className={classes.iconWrapper}
            onClick={isSimpleLink ? handleCopyLink : null}
          >
            <Box className={classes.icon}>
              <SocialIcon />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
