import { makeStyles } from 'tss-react/mui';

export const useCardStyles = makeStyles()({
  boxSearch: {
    top: 1,
    position: 'sticky',
  },
  cardContainer: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    boxShadow: '0px 0px 15px 7px rgba(166, 166, 166, 0.25)',
    backgroundColor: '#ffffff',
    overflow: 'hidden',

    '&.active': {
      backgroundColor: 'rgba(212, 226, 240, 1)',
      border: '1px solid rgba(84, 112, 255, 1)',
    },
  },
  bodyWithAction: {
    cursor: 'pointer',
  },
  header: {
    paddingInline: '1.375rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    height: '4.5rem',
    width: '100%',
  },
});
