import { Box } from '@mui/material';
import { MultiSlidesComments } from 'components/ImageViewer/components/MultiSLidesComments';
import { SlideComments } from 'components/ImageViewer/SlidesViewerComments';
import { SlideViewerActions } from 'components/ImageViewer/SlideViewerHeader/SlideViewerActions';
import { SlidesTable } from 'components/OrderSlidesList/OrderSlidesTable';
import { ActionTabsHeader } from 'components/OrderSlidesList/SlideActionTabs/ActionTabsHeader/ActionTabsHeader';
import { actionTabs } from 'components/OrderSlidesList/SlideActionTabs/constants';
import { SlideMetadata } from 'components/OrderSlidesList/SlideActionTabs/SlideMetadata/SlideMetadata';
import { SlideGroupsVerticalContainer } from 'components/SlideGroups/Modals/components/SlideGroupsVerticalContainer';
import React, { useEffect } from 'react';
import {
  useScrollStyles,
  useStyles,
} from 'components/OrderSlidesList/SlideActionTabs/styles';
import { ConditionalComponent } from 'components/ConditionalWrappers/ConditionalComponentWrapper/ConditionalComponent';
import { SlideViewerExtAnnotations } from 'components/OrderSlidesList/ExtAnnotations/ExtAnnotationsTab';
import { SlideAiToolsWrapper } from 'components/AITools/components/SlideAiToolsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import {
  isExpandedBottomSelector,
  isExpandedTopSelector,
  setExpanded,
} from 'store/slices/slidesListSlice/slidesListSlice';

export const SlideActionTabs = ({
  slidesViewerProps,
  slidesTableProps,
  slidesCommentsProps,
  isSingleViewerMode,
  slidesGroupProps,
  currentSlide,
  activeTab,
  setActiveTab,
  disabledTabs,
  annotationsLegend,
  isMultipleSelected,
  isTop,
}) => {
  const { classes } = useStyles();
  const { classes: scrollClasses } = useScrollStyles();
  const dispatch = useDispatch();
  const isSlidesList = activeTab === actionTabs.slidesList && slidesTableProps;
  const isAiTools = activeTab === actionTabs.aiTools;
  const isExpandedTop = useSelector(isExpandedTopSelector);
  const isExpandedBottom = useSelector(isExpandedBottomSelector);
  const isExpanded = isTop ? isExpandedTop : isExpandedBottom;
  const isHeaderDisabled = isSingleViewerMode
    ? false
    : !slidesTableProps.gridApi;

  const stylesTab = activeTab === actionTabs.aiTools ? { padding: 0 } : {};

  useEffect(() => {
    const isNotAllowedExpanded = isExpanded && !isAiTools && !isSlidesList;

    if (isNotAllowedExpanded) {
      dispatch(setExpanded({ isExpanded: false, isTop }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Box className={classes.root}>
      <ActionTabsHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showList={!!slidesTableProps}
        disabledTabs={disabledTabs}
        isDisabled={isHeaderDisabled}
        isMultipleSelected={isMultipleSelected}
      />
      <Box
        className={`${classes.tabContent} ${scrollClasses.root}`}
        style={stylesTab}
      >
        {activeTab === actionTabs.meta && (
          <SlideMetadata slide={currentSlide} />
        )}
        {activeTab === actionTabs.comments &&
          (slidesViewerProps.slides.length > 1 ? (
            <MultiSlidesComments {...slidesViewerProps} />
          ) : (
            !!slidesViewerProps.slides.length && (
              <SlideComments {...slidesCommentsProps} />
            )
          ))}
        {isSlidesList && (
          <SlidesTable
            {...slidesTableProps}
            isTop={isTop}
            isExpanded={isExpanded}
          />
        )}
        {activeTab === actionTabs.groups && (
          <SlideGroupsVerticalContainer
            {...slidesGroupProps}
            customClasses={{
              sortsContainer: classes.sortsContainer,
              label: classes.groupsSortLabel,
              groupsRowContainer: classes.groupsRowContainer,
              slideGroupVerticalContainer: classes.groupsRowsContainer,
            }}
            showCreateButton
          />
        )}
        <ConditionalComponent condition={isAiTools}>
          <SlideAiToolsWrapper
            slide={currentSlide}
            slidesTableProps={slidesTableProps}
            isTop={isTop}
            isExpanded={isExpanded}
          />
        </ConditionalComponent>
        <ConditionalComponent
          condition={activeTab === actionTabs.extAnnotations}
        >
          <Box padding={1} overflow="auto">
            <SlideViewerExtAnnotations
              currentSlide={currentSlide}
              isSingleViewer={isSingleViewerMode}
              annotationsLegend={annotationsLegend}
            />
          </Box>
        </ConditionalComponent>
        {activeTab === actionTabs.actions && (
          <SlideViewerActions
            currentSlide={currentSlide}
            isSingleViewer={!isMultipleSelected}
          />
        )}
      </Box>
    </Box>
  );
};
