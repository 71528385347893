import { Fade, Grid, IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { getRefinementLabelByType } from 'components/PathologyMap/utilities/common';
import { useCurrentPathologyMapManager } from 'components/PathologyMap/utilities/hooks';
import { DefaultPathologyMapTypography } from 'components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography';
import { usePathologyMapActiveFiltersItemStyles } from './styles';

export const PathologyMapActiveFiltersItem = ({ item, onClear }) => {
  const { classes } = usePathologyMapActiveFiltersItemStyles();
  const { categoriesList } = useCurrentPathologyMapManager();
  const currentRefinementName = getRefinementLabelByType(
    item.attribute,
    categoriesList,
  );

  const handleClearClick = () => {
    onClear();
  };

  return (
    <Fade in={true} appear={true} timeout={600}>
      <Grid
        item
        container
        xs={3}
        alignItems="center"
        className={classes.activeFilterItem}
      >
        <Grid item xs>
          <DefaultPathologyMapTypography textTransform="uppercase">
            {currentRefinementName}: {item.value}
          </DefaultPathologyMapTypography>
        </Grid>
        <Grid item>
          <IconButton onClick={handleClearClick} className={classes.icon}>
            <Clear fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </Fade>
  );
};
