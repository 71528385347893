import { makeStyles } from 'tss-react/mui';
import {
  PATHOLOGY_MAP_ACTIVE_FILTER_ITEM_BG_COLOR,
  PATHOLOGY_MAP_DEFAULT_BORDER_COLOR,
  PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR,
  PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR,
  PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR_HOVER,
  PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR_DISABLED,
} from 'components/PathologyMap/constants/styles';

export const usePathologyMapActiveFiltersItemStyles = makeStyles()(() => ({
  activeFilterItem: {
    borderRadius: '3px',
    border: `1px solid ${PATHOLOGY_MAP_DEFAULT_BORDER_COLOR}`,
    backgroundColor: PATHOLOGY_MAP_ACTIVE_FILTER_ITEM_BG_COLOR,
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR}`,
    margin: '1rem 0 0 3rem',
    padding: '0.3rem 0.3rem 0.3rem 1rem',
    height: 'fit-content',
  },
  icon: {
    opacity: 0.2,
    textAlign: 'end',
    marginTop: '0.3rem',
    padding: 0,
    margin: 0,
  },
}));

export const usePathologyMapActiveFiltersItemsStyles = makeStyles()(() => ({
  itemsWrapper: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  clearButtonWrapper: {
    marginRight: '1rem',
    marginBottom: '1rem',
  },
}));

export const usePathologyMapClearActiveFiltersButtonStyles = makeStyles()(
  () => ({
    clearRefinementsButton: {
      borderRadius: '3px',
      border: `1px solid ${PATHOLOGY_MAP_DEFAULT_BORDER_COLOR}`,
      background: PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR,
      boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_DEFAULT_BOX_SHADOW_COLOR}`,
      padding: '0.5rem 2rem',
      '&:hover': {
        background: PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR_HOVER,
      },
      '&.Mui-disabled': {
        background: PATHOLOGY_MAP_CLEAR_FILTERS_BUTTON_BG_COLOR_DISABLED,
      },
    },
  }),
);
