import { createStructuredSelector } from 'reselect';
export const shippingAddresses = (state) =>
  state.billingShipping.shippingAddresses;
export const shippingAddressSelected = (state) =>
  state.billingShipping.shippingAddressSelected;
export const shippingMethodSelected = (state) =>
  state.billingShipping.shippingMethodSelected;
export const selectedBlanketPurchase = (state) =>
  state.billingShipping.selectedBlanketPurchase;

export const blanketPurchaseOrders = (state) =>
  state.billingShipping.blanketPurchaseOrders;
export const billingContactSelected = (state) =>
  state.billingShipping.billingContactSelected;
export const dropboxSelected = (state) => state.billingShipping.dropboxSelected;
export const order = (state) => state.billingShipping.order;
export const teamUUID = (state) => state.billingShipping.teamUUID;
export const validated = (state) => state.billingShipping.validated;
export const submitting = (state) => state.billingShipping.submitting;

export const viewPage = createStructuredSelector({
  blanketPurchaseOrders,
  shippingAddressSelected,
  order,
});

export const billingTabs = createStructuredSelector({
  blanketPurchaseOrders,
  order,
  teamUUID,
});

export const shippingTabs = createStructuredSelector({
  shippingAddressSelected,
  shippingAddresses,
  teamUUID,
  validated,
});

export const billingContactForm = createStructuredSelector({
  validated,
  order,
  teamUUID,
});

export const shippingDetails = createStructuredSelector({
  shippingAddressSelected,
  shippingMethodSelected,
  dropboxSelected,
  submitting,
});

export const additionalQuestionsForm = createStructuredSelector({
  teamUUID,
  order,
  shippingAddressSelected,
  shippingMethodSelected,
  billingContactSelected,
  selectedBlanketPurchaseOrder: selectedBlanketPurchase,
  dropboxSelected,
  shippingAddresses,
  submitting,
});
