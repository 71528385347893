import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

export const ExportAnnotationRow = ({
  classes,
  slide,
  annotations,
  blockedCheckBoxes,
  setCheckboxes,
  checkboxes,
}) => {
  if (!slide) return null;

  const { name, uuid: slideUUID } = slide;

  const annotationsCount =
    annotations.find((annotation) => annotation.slideUUID === slideUUID)?.data
      ?.length || 0;

  const handleCheckboxToggle = (uuid) => {
    setCheckboxes((prev) => {
      return { ...prev, [uuid]: !prev[uuid] };
    });
  };

  const isRowBlocked = blockedCheckBoxes?.[slideUUID] || !annotationsCount;

  return (
    <Grid item container>
      <Grid item xs={1}>
        <Checkbox
          size="small"
          disabled={isRowBlocked}
          checked={checkboxes[slideUUID]}
          onChange={() => handleCheckboxToggle(slideUUID)}
        />
      </Grid>
      <Grid
        item
        xs={9}
        className={`${classes.cell} ${isRowBlocked && classes.greyText}`}
      >
        {name}
      </Grid>
      <Grid
        item
        xs={2}
        justifyContent={'center'}
        className={`${classes.cell} ${isRowBlocked && classes.greyText}`}
      >
        {annotationsCount}
      </Grid>
    </Grid>
  );
};
