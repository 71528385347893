import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useGetProjectsQuery } from 'store/apis/projectsApi';
import { useEffect, useState } from 'react';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import { FETCH_PROJECTS_ERROR_MESSAGE } from 'constants/errorMessages';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const ProjectStepContent = ({
  classes,
  handleClose,
  handleNextStep,
  isLoading,
  setIsLoading,
  selectedProject,
  setSelectedProject,
  setSelectedOrder,
}) => {
  const {
    data: projects,
    isLoading: isProjectsLoading,
    error,
  } = useGetProjectsQuery();

  const [newProjectName, setNewProjectName] = useState('');
  const [isNewProject, setIsNewProject] = useState(false);

  useQueryError(error, FETCH_PROJECTS_ERROR_MESSAGE);

  useEffect(() => {
    setIsLoading(isProjectsLoading);
  }, [isProjectsLoading, setIsLoading]);

  const handleNewProjectNameChange = (event) => {
    setNewProjectName(event.target.value);
    setSelectedProject(null);
  };

  const handleSelectNewProject = () => {
    setSelectedProject(null);
    setSelectedOrder(null);
    setIsNewProject(true);
  };

  const handleClickNext = () => {
    if (newProjectName) {
      setSelectedProject({ name: newProjectName });
      setSelectedOrder(null);
      handleNextStep(2);

      return;
    }

    handleNextStep();
  };

  const handleCancel = () => {
    setIsNewProject(false);
    setNewProjectName('');
    handleClose();
  };

  const onSelectionChange = (event) => {
    setSelectedProject(
      projects.find((project) => project.uuid === event.target.value),
    );
    setNewProjectName('');
  };

  return isLoading ? (
    <CircularProgress className={classes.spinner} />
  ) : (
    <Box display="flex" flexDirection="column" alignItems="center" p={8}>
      {!isNewProject ? (
        <Select
          defaultValue=""
          value={selectedProject?.uuid}
          variant="outlined"
          className={classes.selector}
          onChange={onSelectionChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            classes: { list: classes.menuList, paper: classes.menuPaper },
          }}
        >
          <MenuItem className={classes.topMenuItem}>
            <Button
              onClick={handleSelectNewProject}
              startIcon={<AddCircleIcon />}
              className={classes.newProjectButton}
            >
              Create New Project
            </Button>
          </MenuItem>
          {projects?.map((project) => (
            <MenuItem key={project.uuid} value={project.uuid}>
              {project.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField
          label="Enter new project name"
          variant="outlined"
          className={classes.input}
          value={newProjectName}
          onChange={handleNewProjectNameChange}
        />
      )}
      <Box display="flex" justifyContent="space-between" width="30rem" mt={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCancel}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickNext}
          className={classes.button}
          disabled={!selectedProject && !newProjectName}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
