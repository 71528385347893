import Grid from '@mui/material/Grid';
import { getSlideAttributesFromSlide } from 'components/PathologyMap/utilities/common';
import { usePathologyMapSlideCardContentStyles } from 'components/PathologyMap/PathologyMapSlideCards/styles';
import { SlideInfoTable } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/SlideInfoTable';

export const PathologyMapSlideCardContent = ({
  slide,
  selectedFilters,
  handleOpenModal,
}) => {
  const slideData = getSlideAttributesFromSlide({ slide });
  const { classes } = usePathologyMapSlideCardContentStyles();

  return (
    <Grid
      container
      direction="row"
      spacing={0.5}
      className={classes.contentWrapper}
    >
      <Grid
        item
        xs={6}
        className={classes.imageWrapper}
        onClick={handleOpenModal}
      >
        <img src={slide.large_thumbnail_url} alt="Slide" width="100%" />
      </Grid>
      <Grid item xs>
        <SlideInfoTable
          slide={slide}
          dataItems={slideData}
          selectedFilters={selectedFilters}
        />
      </Grid>
    </Grid>
  );
};
