const ASC = 'asc';
const DESC = 'desc';

export const sortOrder = {
  ASC,
  DESC,
};

export const HASH_PARAMS_DELIMITER = '&';

export const FAVORITE_SLIDES_GROUP = 'Favorite Slides';
export const FAVORITE_SLIDES_GROUP_UI = 'Favorite ⭐️';
