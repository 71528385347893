import { makeStyles } from 'tss-react/mui';
import { HISTOWIZ_SECONDARY_COLOR, TRANSPARENT_BLACK } from 'utilities/colors';

export const useHistoryStyles = makeStyles()((theme) => ({
  link: {
    color: HISTOWIZ_SECONDARY_COLOR,
    textDecoration: 'underline',
    '&:hover': {
      color: '#1C1B1F',
    },
  },
  searchIcon: { color: TRANSPARENT_BLACK },
  noDataLabel: {
    position: 'absolute',
    fontSize: '1.5rem',
    top: '50%',
    left: '50%',
  },
  timeline: {
    '& .MuiTimelineItem-root:before': {
      flex: 0,
      padding: 0,
      margin: 0,
    },
    padding: 0,
    margin: 0,
    '&.MuiTimeline-root': {
      padding: 0,
      margin: 0,
    },
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  timelineContent: { marginBottom: '-0.5rem' },
  expandIcon: {
    color: '#1C1B1F',
    padding: 0,
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  rowSpinner: {
    margin: '0.25rem 0 0 0.25rem',
  },
  tableWrapper: {
    marginTop: '1.25rem',
    marginBottom: '0.75rem',
  },
  mainCell: {
    padding: '0.35rem',
    width: '100%',
  },
  summaryRow: {
    width: '100%',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.625rem',
  },
  sortLabel: {
    marginTop: '0.625rem',
    fontWeight: 'bold',
    minWidth: '9.5rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
  },
  inputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      width: '30rem',
      maxHeight: '2.75rem',
      color: TRANSPARENT_BLACK,
      '& fieldset': {
        borderColor: TRANSPARENT_BLACK,
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.down('lg')]: {
        width: '20rem',
      },
      [theme.breakpoints.down('sm')]: {
        width: '15rem',
      },
    },
  },
  firstCell: { width: '2rem', padding: '0.5rem 0rem 0.5rem 1rem' },
  rotateIcon: { transform: 'rotate(-90deg)' },
}));
