import { Box, Button, Typography } from '@mui/material';
import { SlideAIModelModalRow } from 'components/AITools/components/SlideAIModelModalRow';
import { useAIModelModalStyles } from 'components/AITools/constants/styles';
import { COMPUTE_COST_LABEL } from 'components/pages/AppLibrary/constants';

export const SlideAIModalPaymentInfo = ({
  slidesCount,
  handleConfirmPayment,
  model,
  isConfirming,
  balance,
}) => {
  const { classes } = useAIModelModalStyles();
  const { toolDetails } = model;
  const price = toolDetails[0].toolDetail.find(
    (detail) => detail.label === COMPUTE_COST_LABEL,
  ).value;

  const numericFixedPrice = (+price).toFixed?.(2);
  const isPriceNumeric = numericFixedPrice && numericFixedPrice !== 'NaN';
  const totalCost = isPriceNumeric
    ? `$ ${(slidesCount * price).toFixed(2)}`
    : price;
  const slidesCost = isPriceNumeric ? `$ ${numericFixedPrice}` : '-';
  const amountInWallet = `$ ${balance.toFixed(2)}`;

  return (
    <Box>
      <Typography className={classes.title}>Payment Confirmation</Typography>
      <SlideAIModelModalRow
        field="TOTAL NO OF COMPATIBLE SLIDES"
        value={slidesCount}
        pt={2}
      />
      <SlideAIModelModalRow field="Compute Cost per Slide" value={slidesCost} />
      <SlideAIModelModalRow field="Total Cost" value={totalCost} />
      <SlideAIModelModalRow field="Amount in Wallet" value={amountInWallet} />
      <Button
        variant="contained"
        className={classes.confirmButton}
        onClick={handleConfirmPayment}
        disabled={!isPriceNumeric || isConfirming}
      >
        Confirm Payment
      </Button>
    </Box>
  );
};
