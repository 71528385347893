import React from 'react';
import { createRoot } from 'react-dom/client';

import 'css/index.css'; // This just sets margin to zero for body, small but worth it
import { unregister } from 'registerServiceWorker';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';

import { LicenseManager } from 'ag-grid-enterprise';

import { AppProvider } from 'services/context';

// if you don't import these, the ag-grids dont display correctly
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
// import css for grid-layouts
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import TagManager from 'react-gtm-module';
import { histowizTheme } from 'themes/defaultMuiTheme';
import { SnackbarProvider } from 'notistack';
import 'css/agGridHistoWiz.css';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'components/ErrorBoundary/ErrorBoundaryFallback';
import { AppVersion } from 'components/Layout/AppVersion';
import { RouterRoutes } from 'routes/routerRoutes';
import { Provider } from 'react-redux';
import { store as reduxStore } from 'store/store';
import { Auth0Provider } from '@auth0/auth0-react';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_REDIRECT_URI,
  REACT_APP_NETLIFY_CALLBACK_URL,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

const ignorePageadErrors = (event) => {
  if (event.request && event.request.url) {
    if (
      event.request.url.includes('https://pagead2.googlesyndication.com/pagead')
    ) {
      return null;
    }
  }
  return event;
};

const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  ignoreErrors: ['Non-Error promise rejection captured'],
  beforeSend: ignorePageadErrors,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: 'production-react@' + process.env.REACT_APP_COMMIT_REF,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
    }),
  ],
};

if (process.env.NODE_ENV === 'development') {
  sentryConfig.integrations = [
    ...sentryConfig.integrations,
    new Sentry.Integrations.Breadcrumbs({ console: false }),
  ];
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init(sentryConfig);
}

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-047544 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Histowiz, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Histowiz )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Histowiz )_need_to_be_licensed___( Histowiz )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 November 2024 )____[v2]_MTczMDkzNzYwMDAwMA==f4374eb2d3d94b7ec12c36c54ef241ea',
);

const tagManagerArgs = {
  gtmId: 'GTM-TVHBDN7',
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <HelmetProvider>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri:
          REACT_APP_AUTH0_REDIRECT_URI || REACT_APP_NETLIFY_CALLBACK_URL,
        audience: REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <MuiThemeProvider theme={histowizTheme}>
        <Sentry.ErrorBoundary
          fallback={(props) => <ErrorBoundaryFallback {...props} />}
        >
          <Provider store={reduxStore}>
            <SnackbarProvider classes={{ containerRoot: 'z-alert' }}>
              <AppProvider>
                <RouterRoutes />
                <AppVersion />
              </AppProvider>
            </SnackbarProvider>
          </Provider>
        </Sentry.ErrorBoundary>
      </MuiThemeProvider>
    </Auth0Provider>
  </HelmetProvider>,
);

unregister();
