import { baseCreateApi } from './baseCreateApi';

export const downloadSlideApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    downloadSlideBy: builder.query({
      query: ({ downloadURL }) => ({
        url: downloadURL,
        method: 'GET',
      }),
    }),
    downloadSlideSource: builder.query({
      query: ({ slide }) => ({
        url: slide.download_url,
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const { data } = await queryFulfilled;
        window.location.assign(data.url);
      },
    }),
    downloadIFSlideSourceQPTiff: builder.query({
      query: ({ IFSlide }) => ({
        url: IFSlide.download_url,
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const { data } = await queryFulfilled;
        window.location.assign(data['qptiff_url']);
      },
    }),
    downloadIFSlideSourceOMETiff: builder.query({
      query: ({ IFSlide }) => ({
        url: IFSlide.download_url,
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const { data } = await queryFulfilled;
        window.location.assign(data['ome_tiff_url']);
      },
    }),
  }),
});

export const {
  useDownloadSlideByQuery,
  useLazyDownloadSlideSourceQuery,
  useLazyDownloadIFSlideSourceQPTiffQuery,
  useLazyDownloadIFSlideSourceOMETiffQuery,
} = downloadSlideApi;
