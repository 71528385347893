import React from 'react';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';

import { useGlpFormStyles } from 'components/GlpForm/styles';

export const GlpSelect = ({ title, value, onChange, options, disabled }) => {
  const { classes } = useGlpFormStyles();
  const { field, select } = classes;

  return (
    <FormControl className={`${select} ${field}`}>
      <InputLabel id={title}>{`${title} *`}</InputLabel>
      <Select
        value={value}
        disabled={disabled}
        onChange={({ target: { value } }) => onChange(value)}
        label={title}
        required
      >
        {options.map((item) => (
          <MenuItem key={item.value ?? item} value={item.value ?? item}>
            {item.label ?? item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
