import {
  COMPUTE_COST_LABEL,
  ML_COMPLETION_MARKER_FIELDS,
} from 'components/pages/AppLibrary/constants';
import { AI_MODELS_STATES } from 'components/AITools/constants/common';

export const checkIfMLCompleted = (slide) => ML_COMPLETION_MARKER_FIELDS.some(
  (field) => slide[field] !== AI_MODELS_STATES.NA,
);

export const checkIfMLProcessing = (slide) => (
  ML_COMPLETION_MARKER_FIELDS.every(
    (field) => slide[field] === AI_MODELS_STATES.NA,
  ) && !!slide.ml_processing_requested_datetime
);

export const checkIfMLApplicable = (slide) => !!slide.sample;

export const getNumericValue = (value) => {
  const valueToFixed = (+value).toFixed?.(2);

  return valueToFixed && valueToFixed !== 'NaN'
    ? `$${valueToFixed}/slide`
    : value;
};

export const getFormattedValueForAITool = (title, value) => {
  const handleClick = (evt) => {
    evt.stopPropagation();
  };

  if (value.includes('http')) {
    return (
      <a href={value} onClick={handleClick} target="_blank" rel="noreferrer">
        <b>Link</b>
      </a>
    );
  }
  if (title === COMPUTE_COST_LABEL) {
    return getNumericValue(value);
  }

  return value;
};
