import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

import { useProjectsStyles } from 'components/Projects/styles';

export const ButtonGroup = ({ handleSubmit, handleCancel }) => {
  const { classes } = useProjectsStyles();
  const { btnGroup } = classes;

  return (
    <Box className={btnGroup}>
      <CheckIcon onClick={handleSubmit} />
      <CloseIcon onClick={handleCancel} />
    </Box>
  );
};
