const OTHER_TYPE = 'Other';

// COLUMN NAMES
const SPECIES_STRAIN_BACKGROUND_NAME = 'Species Strain / Background Name';
const SEX = 'Sex';
const AGE_MONTHS = 'Age (Months)';
const ORIGIN_OF_HOST = 'Origin of Host';
const VENDOR_CATALOG = 'Vendor Catalog #';
const CELL_LINE = 'Cell Line #';
const GENETICALLY_ENGINEERED_MODEL = 'Genetically Engineered Model';
const TECHNIQUE = 'Technique';
const GENOTYPE = 'Genotype';
const EXPRESSION = 'Expression';
const TUMOR_DETAILS = 'Tumor Details';
const LOCATION_TYPE_OF_IMPLANTATION = 'Location / Type of Implantation';
const ORGANOIDS_SOURCE = 'Organoids Source';

const PROCEDURE = 'Procedure';
const DIAGNOSIS = 'Diagnosis';
const TREATMENT_RECEIVED = 'Treatment Received';
const CLINICAL_TRIAL_FOLLOW_UP = 'Clinical Trial Follow Up';

const DRUG_STUDY_NAME = 'Drug Study Name(s)';
const DOSING_SCHEDULE = 'Drug Dosing Schedule';
const DRUG_DURATION = 'Drug Duration';
const DRUG_DOSAGE = 'Drug Dosage';
const DRUG_ADMINISTRATION = 'Drug Administration';
const OUTCOME_BY_GROSS_EXAMINATION = 'Outcome By Gross Examination';
const SURVIVAL_STUDY = 'Survival Study';

const INSTANCE_ID = 'Instance ID';
const ACCESSIONING_ID = 'Accessioning ID';
const EXPERIMENT_ID = 'Experiment ID';
const PASSAGE_ID = 'Passage ID';
const SURGERY_TYPE = 'Surgery Type';
const ANIMAL_ID = 'Animal ID';

const NOTES = 'Notes';

export const HUMAN_SPECIFIC_COLUMNS = [
  PROCEDURE,
  DIAGNOSIS,
  TREATMENT_RECEIVED,
];
export const NONHUMAN_SPECIFIC_COLUMNS = [
  NOTES,
  AGE_MONTHS,
  SEX,
  SPECIES_STRAIN_BACKGROUND_NAME,
  GENOTYPE,
  ORIGIN_OF_HOST,
  GENETICALLY_ENGINEERED_MODEL,
  TECHNIQUE,
  LOCATION_TYPE_OF_IMPLANTATION,
  EXPRESSION,
  ORGANOIDS_SOURCE,
  DRUG_STUDY_NAME,
  DOSING_SCHEDULE,
  DRUG_DURATION,
  DRUG_DOSAGE,
  DRUG_ADMINISTRATION,
  OUTCOME_BY_GROSS_EXAMINATION,
  SURVIVAL_STUDY,
  INSTANCE_ID,
  ACCESSIONING_ID,
  EXPERIMENT_ID,
  PASSAGE_ID,
  ANIMAL_ID,
  SURGERY_TYPE,
];

export const ALL_HUMAN_AND_NON_HUMAN_COLUMNS = NONHUMAN_SPECIFIC_COLUMNS.concat(
  HUMAN_SPECIFIC_COLUMNS,
);

export const COLUMN_TOOLTIPS = {
  [NOTES]:
    'Personal / Team Notes about this sample. HistoWiz does NOT look use these notes in lab operations. For any instructions, please include in the Special Instructions in Step 1 of the Order Form.',
  [SPECIES_STRAIN_BACKGROUND_NAME]: '',
  [SEX]: '',
  [AGE_MONTHS]: '',
  [ORIGIN_OF_HOST]: '',
  [VENDOR_CATALOG]: '',
  [CELL_LINE]: '',
  [GENETICALLY_ENGINEERED_MODEL]: '',
  [TECHNIQUE]: '',
  [GENOTYPE]: '',
  [EXPRESSION]: '',
  [TUMOR_DETAILS]: '',
  [LOCATION_TYPE_OF_IMPLANTATION]: '',
  [ORGANOIDS_SOURCE]: '',
  [DRUG_STUDY_NAME]:
    'Only Valid for Xenograft - Human Cells in Mouse / Organoids',
  [DOSING_SCHEDULE]:
    'Only Valid for Xenograft - Human Cells in Mouse / Organoids',
  [DRUG_DURATION]:
    'Only Valid for Xenograft - Human Cells in Mouse / Organoids',
  [DRUG_DOSAGE]: 'Only Valid for Xenograft - Human Cells in Mouse / Organoids',
  [DRUG_ADMINISTRATION]:
    'Only Valid for Xenograft - Human Cells in Mouse / Organoids',
  [OUTCOME_BY_GROSS_EXAMINATION]:
    'Only Valid for Xenograft - Human Cells in Mouse / Organoids',
  [SURVIVAL_STUDY]:
    'Only Valid for Xenograft - Human Cells in Mouse / Organoids',
  [PROCEDURE]: '',
  [DIAGNOSIS]: 'ICD11 Code',
  [TREATMENT_RECEIVED]: '',
  [ANIMAL_ID]:
    'Classifier for Animal, used to compare within same and separate animal(s)',
  [CLINICAL_TRIAL_FOLLOW_UP]: 'Free Text of Patient Response Data',
};

export const COLUMNS_LIST_VALIDATION_CRITERIA = {
  [SEX]: ['Male', 'Female', 'Unknown'],
  [ORIGIN_OF_HOST]: [
    'Homemade',
    'Jackson Labs',
    'Charles River',
    'Taconic',
    OTHER_TYPE,
  ],
  [GENETICALLY_ENGINEERED_MODEL]: [
    'Transgenic',
    'Knockin',
    'Knockdown',
    'Knockout',
    OTHER_TYPE,
  ],
  [TECHNIQUE]: [
    'RNAi',
    'Embryonal Stem Cell',
    'Pronuclear Microinjection',
    'shRNA',
    'miRNA',
    'CRISPR',
    'TALEN',
    'Chemically Induced',
    'Infectious (Viral/Bacterial)',
    'Mechanical',
    'Electroporation',
    OTHER_TYPE,
  ],
  [GENOTYPE]: ['Wild Type (WT)', 'Mutant'],
  [EXPRESSION]: [
    'Conditional / Inducible',
    'Temporal',
    'Spatial',
    'Cell Type Specific',
    'Tissue Specific',
    'Developmental Stage Specific',
    OTHER_TYPE,
  ],
  [TUMOR_DETAILS]: ['Primary', 'Metastasis', OTHER_TYPE],
  [LOCATION_TYPE_OF_IMPLANTATION]: [
    'Subcutaneous',
    'Orthotopic',
    'Disseminated',
    OTHER_TYPE,
  ],
  [ORGANOIDS_SOURCE]: [
    'Patient Derived',
    'Host Tissue',
    'Embryonic Stem Cells',
    'Pluripotent Stem Cells',
    'Induced Pluripotent Stem Cells',
    'Adult Stem Cells',
    'Circulating Tumor Cells',
    OTHER_TYPE,
  ],
  [SURVIVAL_STUDY]: ['Yes', 'No'],
  [PROCEDURE]: ['Biopsy', 'Resection', OTHER_TYPE],
  [TREATMENT_RECEIVED]: [
    'None',
    'Placebo',
    'Chemotherapy',
    'Radiotherapy',
    'Molecular Targeted Therapy - Monotherapy',
    'Molecular Targeted Therapy - Combination Therapy',
    'Immunotherapy',
    OTHER_TYPE,
  ],
  [SURGERY_TYPE]: ['Biopsy', 'Resection'],
};
