import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useAIModelModalStyles } from 'components/AITools/constants/styles';
import { useEffect, useState } from 'react';

export const SlideAIModalConfirmed = ({
  handleClose,
  isProcessed,
  handleReportOpen,
}) => {
  const { classes } = useAIModelModalStyles();
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    if (isProcessed) return undefined;

    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (seconds <= 0) {
      clearInterval(timer);
      setSeconds(10);
      handleClose();
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  const handleCheckboxChange = (event) => {
    setReceiveEmail(event.target.checked);
  };

  return (
    <Box className={classes.confirmed}>
      <Box className={classes.successIcon}>
        <Box className={classes.checkMark} />
      </Box>
      <Typography variant="h6" className={classes.confirmTitle}>
        {isProcessed ? 'Processing Complete' : 'Payment Confirmed'}
      </Typography>
      {!isProcessed ? (
        <>
          <Typography variant="body1" className={classes.confirmText} pb="2rem">
            Your slides are now being processed
            <br />
            Model may take up to 10 minutes to complete
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={receiveEmail}
                onChange={handleCheckboxChange}
              />
            }
            label="Receive an email once AI results are available?"
            sx={{ display: 'none' }} // TODO: waiting backend
            className={classes.checkboxLabel}
          />
          <Typography variant="body2" className={classes.timerText}>
            Automatically redirects to viewer in {seconds} seconds...
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1" className={classes.confirmText} pb="3rem">
            You can now start using HistoWiz Auto-QC Model
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.reportButton}
            onClick={handleReportOpen}
            sx={{ display: 'none' }} // TODO: waiting backend
          >
            report an issue
          </Button>
        </>
      )}
    </Box>
  );
};
