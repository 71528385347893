import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import { useTooltipWithImageStyles } from 'components/UserProfile/styles';
import { memo, useState } from 'react';
import { videoFilesFormats } from 'components/UserProfile/constants';

export const TooltipWithMedia = memo(
  ({ title = '', children, fileSrc, ...props }) => {
    const { classes } = useTooltipWithImageStyles();
    const isVideo = !!videoFilesFormats.find((format) =>
      fileSrc.endsWith(format),
    );
    const [isMediaLoading, setIsMediaLoading] = useState(true);

    const disableLoading = () => setIsMediaLoading(false);

    const MediaContent = () => {
      const mediaClassName = isMediaLoading
        ? classes.invisibleContent
        : classes.content;

      return (
        <>
          {isVideo ? (
            <video
              className={mediaClassName}
              src={fileSrc}
              rel="prefetch"
              autoPlay="autoplay"
              muted="muted"
              loop="loop"
              onCanPlayThrough={disableLoading}
            />
          ) : (
            <img
              src={fileSrc}
              alt="info"
              className={mediaClassName}
              onLoad={disableLoading}
            />
          )}
        </>
      );
    };

    return (
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        title={
          <>
            {!!title.length && (
              <Typography className={classes.title}>{title}</Typography>
            )}
            {isMediaLoading && (
              <Box className={classes.spinnerContainer}>
                <CircularProgress size={32} />
              </Box>
            )}
            <MediaContent />
          </>
        }
        {...props}
      >
        {children}
      </Tooltip>
    );
  },
);
