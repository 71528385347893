import { Box } from '@mui/material';

import { RowInfo } from 'components/pages/AppLibrary/components/RowInfo';
import { useInformationMenuStyles } from 'components/pages/AppLibrary/components/styles';

export const InformationMenu = ({ model, isSmall = false, className = '' }) => {
  const { classes } = useInformationMenuStyles({ isSmall });
  const rootClass = `${classes.root} ${className}`;

  return (
    <Box className={rootClass}>
      {model?.toolDetails[0]?.toolDetail.map(({ id, label, value }) => (
        <RowInfo
          key={id}
          title={label}
          info={value}
          noTooltip
          isSmall={isSmall}
        />
      ))}
    </Box>
  );
};
