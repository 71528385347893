import { baseCreateApi } from 'store/apis/baseCreateApi';
import { defaultActionAsyncHandler } from 'store/utilities';
import { setIFSlide, setIFSlides } from 'store/slices/ifSlidesSlice';
import { ORDERS_IF_SLIDE_LIST_URL } from 'constants/urls';
import { IF_SLIDES_RESOURCE_NAME } from 'services/resources/apiConstants';

export const ifSlideApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getIFSlides: builder.query({
      query: () => ({
        url: 'if_slides/',
        method: 'GET',
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler({ dispatch, queryFulfilled }, setIFSlides);
      },
      keepUnusedDataFor: 0,
    }),
    getIFSlidesByOrderForManageData: builder.query({
      query: ({ orderUUID }) => {
        //@todo: add ?pathology_map_consent=False after backend gets fixed
        const rawUrl = `${ORDERS_IF_SLIDE_LIST_URL}`;
        const url = rawUrl.replace(':orderUUID', orderUUID);
        return {
          url,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
    getIFSlidesByOrder: builder.query({
      query: (orderUUID) => ({
        url: ORDERS_IF_SLIDE_LIST_URL.replace(':orderUUID', orderUUID),
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    updateSharedIfSlideByOrder: builder.mutation({
      query: ({ slideUUID, isShareable }) => ({
        url: `${IF_SLIDES_RESOURCE_NAME}${slideUUID}/`,
        method: 'PUT',
        body: { share_enabled: isShareable },
      }),
      async onQueryStarted({ orderUUID }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        dispatch(
          baseCreateApi.util.updateQueryData(
            'getIFSlidesByOrder',
            orderUUID,
            (draft) => {
              draft[
                draft.findIndex(({ uuid }) => uuid === data.uuid)
              ].share_enabled = data.share_enabled;
            },
          ),
        );
      },
    }),
    getIFSlideByUUID: builder.query({
      query: (ifSlideUUID) => ({
        url: `if_slides/${ifSlideUUID}/`,
        method: 'GET',
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler({ dispatch, queryFulfilled }, setIFSlide);
      },
      keepUnusedDataFor: 0,
    }),
    getSharedIFSlideByUUID: builder.query({
      query: (ifSlideUUID) => ({
        url: `shared_if_slides/${ifSlideUUID}/`,
        method: 'GET',
      }),
      onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        defaultActionAsyncHandler({ dispatch, queryFulfilled }, setIFSlide);
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyGetIFSlidesByOrderForManageDataQuery,
  useLazyGetIFSlidesQuery,
  useLazyGetIFSlideByUUIDQuery,
  useLazyGetSharedIFSlideByUUIDQuery,
  useGetIFSlidesByOrderQuery,
  useUpdateSharedIfSlideByOrderMutation,
} = ifSlideApi;
