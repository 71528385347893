import {
  DARK_GREY,
  DATA_MANAGEMENT_BLUE_2,
  HISTOWIZ_PRIMARY_COLOR,
  LIGHT_BLUE,
  LIGHT_GREY,
  MEDIUM_GREY,
  SECONDARY_BLUE_2,
  WHITE,
} from 'utilities/colors';
import { PATHOLOGY_MAP_EVEN_SLIDE_DATA_BG_COLOR } from 'components/PathologyMap/constants/styles';
import { makeStyles } from 'tss-react/mui';

export const useAntibodiesListStyles = makeStyles()(
  (theme, { isFullTable, isDetailsCollapsed }) => ({
    addToOrderCounter: {
      fontSize: '0.85rem',
      margin: '0 0.5rem',
      width: '2.5rem',
      height: '1.5rem',
      color: WHITE,
      borderRadius: '0.25rem',
      backgroundColor: SECONDARY_BLUE_2,
      textAlign: 'center',
    },
    addABToOrderButton: {
      color: SECONDARY_BLUE_2,
      backgroundColor: LIGHT_BLUE,
      boxShadow: 'none',
      padding: '0.25rem 1rem',
      fontSize: '0.75rem',
      fontWeight: 400,
      height: '2.25rem',
      margin: '0 0.5rem 0.75rem 0',
      border: `1px solid ${LIGHT_BLUE}`,
      '&:hover': {
        backgroundColor: LIGHT_BLUE,
        border: `1px solid ${SECONDARY_BLUE_2}`,
        boxShadow: 'none',
      },
    },
    selectorContainer: {
      position: 'absolute',
      bottom: 0,
      height: '100%',
      backgroundColor: 'rgba(236, 106, 122, 0.08)',
      borderBottom: `4px solid ${HISTOWIZ_PRIMARY_COLOR}`,
      transition: 'left 0.3s ease-in-out, width 0.3s ease-in-out',
    },
    addToOrderButton: {
      width: '100%',
      height: '3rem',
      color: SECONDARY_BLUE_2,
      backgroundColor: LIGHT_BLUE,
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
      '&:hover': {
        backgroundColor: DATA_MANAGEMENT_BLUE_2,
      },
    },
    centeredButton: {
      width: '15rem',
      marginBottom: '1rem',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    collapseContainer: {
      '& .MuiCollapse-wrapper': {
        display: 'block',
      },
      '.MuiCollapse-wrapperInner': {
        overflowY: 'visible',
        overflowX: 'hidden',
        borderRadius: '0.5rem',
      },
    },
    collapseButton: {
      minWidth: 'unset',
      padding: 0,
      height: '100%',
    },
    formInput: {
      marginTop: '0.25rem',
      padding: '0.5rem',
      fontSize: '14px',
      width: '100%',
      display: 'block',
      borderRadius: '0.125rem',
      border: `1px solid ${MEDIUM_GREY}`,
      boxSizing: 'border-box',
      marginBottom: '1rem',
    },
    title: {
      padding: '0.25rem 0 0rem 0.75rem',
      fontSize: '0.75rem',
      color: 'grey',
      textTransform: 'uppercase',
    },
    subTitle: {
      paddingLeft: '0.75rem',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      display: 'flex',
      flexDirection: 'row',
    },
    gridWrapper: {
      height: 'calc(100% - 4rem) !important',
      flexWrap: 'nowrap',
      outline: `2px solid ${LIGHT_GREY}`,
      outlineOffset: '-2px',
      borderRadius: '0.5rem',
    },
    ABSummaryStyle: {
      display: 'grid',
      gridTemplateColumns: isFullTable ? '1fr' : '2fr 1fr',
      marginTop: '4rem',
      minHeight: '100%',
      backgroundColor: 'white',
    },
    cellStyle: {
      '&:nth-of-type(even)': {
        textAlign: 'right',
      },
      '&:nth-of-type(odd)': {
        width: '8rem',
      },
      padding: '0.5rem',
    },
    rowStyle: {
      '&:nth-of-type(even)': {
        backgroundColor: PATHOLOGY_MAP_EVEN_SLIDE_DATA_BG_COLOR,
      },
    },
    thumbnailStyle: {
      borderRadius: '0.75rem',
      cursor: 'pointer',
      border: 'none',
      padding: '0',
      marginTop: '1rem',
      width: '18rem',
      '&:hover img': { transform: 'scale(1.1)' },
      overflow: 'hidden',
      backgroundColor: 'transparent',
      img: {
        objectFit: 'cover',
        maxHeight: '18rem',
        transition: 'transform 0.2s ease-in-out',
        width: '100%',
      },
    },
    tabsWrapper: {
      backgroundColor: 'white',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '1.5rem',
      marginBottom: '0.5rem',
      overflow: 'hidden',
      height: '4rem',
      justifyContent: 'start',
      alignItems: 'center',
      borderRadius: '0.5rem 0.5rem 0 0',
      borderBottom: `1px solid ${LIGHT_GREY}`,
    },
    tabs: {
      backgroundColor: 'white',
      height: '2.3rem',
      minHeight: '2.3rem',
      marginLeft: '1rem',
    },
    indicator: {
      display: 'none',
    },
    tab: {
      textTransform: 'uppercase',
      minWidth: 'min-content',
      padding: '0.25rem 0.75rem',
      marginInline: '0.5rem',
      height: '2.3rem',
      minHeight: '2.3rem',
      fontWeight: 500,
      opacity: 1,
      border: '1px solid transparent',
      '&:hover': {
        color: SECONDARY_BLUE_2,
        border: `1px solid ${SECONDARY_BLUE_2}`,
        borderRadius: '4px',
      },
    },
    selectedTab: {
      backgroundColor: SECONDARY_BLUE_2,
      color: 'white !important',
      borderRadius: '4px',
      fontWeight: 400,
    },
    centered: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      textAlign: 'center',
      margin: 'auto',
      padding: '1rem',
    },
    detailsWrapper: {
      height: '85vh',
      display: isDetailsCollapsed ? 'none' : 'block',
      flexDirection: 'row',
      border: `2px solid ${LIGHT_GREY}`,
      borderRadius: '0.5rem',
      width: '100%',
    },
    detailsCollapsibleContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      height: '100%',
    },
    agGrid: {
      height: '69vh',
      backgroundColor: 'transparent',
    },
    baseTabSelector: {
      '& > span': {
        margin: '0',
      },
      color: `${DARK_GREY} !important`,
      display: 'flex',
      flexDirection: 'column',
      height: '2.9rem',
      width: '5rem',
    },
    tabSelectorActive: {
      borderRadius: '6px',
      border: '2px solid #8897F3',
      backgroundColor: 'transparent',
      minHeight: 'unset',
      boxShadow: '0px 4px 4px 0px #00000040',
      padding: '0.3rem',
    },
    rightFloat: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    viewerContainer: {
      height: '77vh',
      width: '45vw',
      backgroundColor: 'white',
    },
    ABDescription: {
      overflow: 'auto',
      borderBottom: 'none',
    },
  }),
);

export const useAntibodiesPageStyles = makeStyles()((theme) => ({
  root: {
    height: '100vh',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  logoWrapper: {
    marginBottom: theme.spacing(3),
  },
  logo: {
    width: '20%',
    marginBottom: '-1.5rem',
  },
  antibodiesListWrapper: {
    height: '85vh',
  },
}));
