import { useStats } from 'react-instantsearch-hooks-web';
import { Box, Button, Grid, Switch, Typography } from '@mui/material';
import { PathologyMapResultsCounter } from 'components/PathologyMap/Shared/PathologyMapResultsCounter';
import { DefaultPathologyMapTypography } from 'components/PathologyMap/Shared/PathologyMapTypography/DefaultPathologyMapTypography';
import React, { useState, useContext } from 'react';
import { ChrisFeedbackModal } from 'components/PathologyMap/ChrisFeedbackModal';
import { useDispatch } from 'react-redux';
import { setForceOpenSlidesSearchTutorial } from 'store/slices/userDetailsSlice';
import { useCurrentPathologyMapManager } from 'components/PathologyMap/utilities/hooks';
import { usePathologyMapToolbarStyles } from './styles';
import { ViewsSelectionButtons } from 'components/SlideGroups/Modals/components/ViewsSelectionButtons';
import { AppContext } from 'services/context';

export const PathologyMapToolbar = ({ view, setView }) => {
  const dispatch = useDispatch();
  const { processingTimeMS, nbHits } = useStats();
  const { classes } = usePathologyMapToolbarStyles();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const { showTutorial } = useCurrentPathologyMapManager();
  const { isPathMapFilterEnabled, setIsPathMapFilterEnabled } =
    useContext(AppContext);

  const toggleFilter = () => {
    setIsPathMapFilterEnabled(!isPathMapFilterEnabled);
  };

  return (
    <>
      <Grid container className={classes.toolbar} alignItems="center">
        <Grid item container xs={5} md={3} alignItems="center">
          <Grid item>
            <DefaultPathologyMapTypography fontSize="1rem">
              RESULTS
            </DefaultPathologyMapTypography>
          </Grid>
          <Grid item className={classes.counterWrapper}>
            <PathologyMapResultsCounter count={nbHits} />
          </Grid>
          <Grid item>
            <DefaultPathologyMapTypography fontSize="1rem">
              found in {processingTimeMS} ms
            </DefaultPathologyMapTypography>
          </Grid>
        </Grid>
        <Grid item xs={7} md={4} justifyContent="flex-end">
          <ViewsSelectionButtons
            view={view}
            setView={setView}
            externalClasses={{ buttonsSection: classes.buttonsSection }}
          />
        </Grid>
        {/*it's temp and should be removed further when new slides search fully released*/}
        <Grid item container xs={9} md={4} justifyContent="flex-end">
          <Box display="flex" flexDirection="row">
            <Typography
              marginRight="0.5rem"
              minWidth="15rem"
              paddingTop="0.25rem"
              textAlign="end"
            >
              Show Contributed Slides Only
            </Typography>
            <Box className={classes.switch}>
              <Switch
                checked={isPathMapFilterEnabled}
                onChange={toggleFilter}
                size="small"
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              onClick={() => setIsFeedbackModalOpen(true)}
            >
              Feedback
            </Button>
          </Box>
        </Grid>
        {showTutorial && (
          <Grid item container md={1} xs={3} justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              onClick={() => dispatch(setForceOpenSlidesSearchTutorial(true))}
            >
              Tutorial
            </Button>
          </Grid>
        )}
      </Grid>
      {/*it's temp and should be removed further when new slides search fully released*/}
      <ChrisFeedbackModal
        open={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
    </>
  );
};
