import { makeStyles } from 'tss-react/mui';
import { VERTICAL_SCROLL_STYLE } from 'themes/defaultMuiTheme';
import {
  BACKGROUND_GREY,
  DATA_MANAGEMENT_BLUE,
  DATA_MANAGEMENT_BLUE_2,
  HISTOWIZ_PRIMARY_COLOR,
  PRIMARY_PINK, PRIMARY_PINK_TRANSPARENT_8,
  WHITE,
} from 'utilities/colors';

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    borderColor: DATA_MANAGEMENT_BLUE,
    paddingLeft: '1.5rem',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    minHeight: '2.5rem',
    height: '2.5rem',
    cursor: state.isDisabled ? 'not-allowed !important' : 'pointer',
    '&:hover': {
      borderColor: DATA_MANAGEMENT_BLUE,
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    order: 1,
    position: 'absolute',
    right: '7rem',
    color: DATA_MANAGEMENT_BLUE,
    '&:hover': {
      color: DATA_MANAGEMENT_BLUE_2,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    order: 2,
    position: 'absolute',
    right: '22.5rem',
    color: DATA_MANAGEMENT_BLUE,
    '&:hover': {
      color: DATA_MANAGEMENT_BLUE_2,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: DATA_MANAGEMENT_BLUE,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: DATA_MANAGEMENT_BLUE,
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: state.isSelected ? DATA_MANAGEMENT_BLUE : '#000000',
    backgroundColor: state.isFocused ? DATA_MANAGEMENT_BLUE_2 : WHITE,
    '&:hover': {
      backgroundColor: DATA_MANAGEMENT_BLUE_2,
    },
  }),
};

export const useSlideMetricsCardStyles = makeStyles()(() => ({
  manageDataCard: {
    height: '100%',
  },
}));

export const useTeamOrdersOverviewStyles = makeStyles()(() => ({
  rowsContainer: {
    height: '44vh',
    overflowY: 'scroll',
    ...VERTICAL_SCROLL_STYLE,
  },
  slidesCountOverview: {
    display: 'flex',
    flexDirection: 'column',
  },
  slideType: {
    textAlign: 'center',
    marginInline: 0,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  slidesCount: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '1.75rem',
    marginTop: '0.75rem',
  },
  yearSelector: {
    width: '100px',
  },
  continueButton: { margin: '1rem 0.5rem 0 0', float: 'right' },
}));

export const useManageDataCardOrderPreviewHeaderStyles = makeStyles()(() => ({
  backContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  backLabel: {
    lineHeight: '0.875rem',
    fontWeight: 500,
    marginLeft: '0.25rem',
    marginRight: '1.75rem',
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '1.35rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
  orderName: {
    color: DATA_MANAGEMENT_BLUE,
    marginLeft: '0.375rem',
    fontWeight: 600,
  },
}));

export const useCustomDropdownStyles = makeStyles()(() => ({
  root: {
    backgroundColor: 'red',
  },
  menuItemRoot: {
    marginInline: '0.25rem',
    marginBlock: '0.25rem',
    paddingInline: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItemSelected: {
    backgroundColor: 'rgba(236, 106, 122, 0.2) !important',
    marginInline: '0.375rem',
    borderRadius: '0.25rem',
  },
  selected: {
    backgroundColor: 'red',
    color: 'green',
  },
  selectedItem: { display: 'flex', alignItems: 'center', color: PRIMARY_PINK },
}));

export const useManageDataCardDefaultHeaderStyles = makeStyles()(() => ({
  changeLogButton: {
    backgroundColor: PRIMARY_PINK_TRANSPARENT_8,
    border: `1px solid ${HISTOWIZ_PRIMARY_COLOR}`,
    borderRadius: '4px',
    height: '2.5rem',
    marginLeft: '1.25rem',
    padding: '0 !important',
    minWidth: '2.5rem',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: PRIMARY_PINK,
      color: WHITE,
      '> svg': {
        color: WHITE,
      },
    },
  },
  changeLogButtonActive: {
    backgroundColor: HISTOWIZ_PRIMARY_COLOR,
    color: WHITE,
    '&:hover': {
      backgroundColor: PRIMARY_PINK,
    },
  },
  changeLogIcon: {
    fontSize: '1.5rem',
    fontWeight: 'normal',
    color: HISTOWIZ_PRIMARY_COLOR,
  },
  closeIcon: {
    fontSize: '1.3rem',
    marginRight: '0.375rem',
  },
  cardTitle: {
    fontWeight: '600',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
  selectedOrdersButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgb(242, 242, 242)',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    borderRadius: '57px',
    padding: '0.325rem 2rem',
    height: 'max-content',
    fontSize: '0.75rem',
    fontWeight: 500,
  },
  actionButton: {
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    color: PRIMARY_PINK,
    border: `1px solid ${PRIMARY_PINK}`,
    borderRadius: '3px',
    marginLeft: '0.75rem',
    padding: '0.55rem 1rem',
    height: 'max-content',
    fontSize: '0.875rem',
  },
  actionButtonActive: {
    backgroundColor: PRIMARY_PINK,
    color: 'white',
    '&:hover': {
      backgroundColor: PRIMARY_PINK,
    },
  },
  buttonTitle: {
    fontWeight: 500,
    marginLeft: '0.5rem',
    lineHeight: '1rem',
  },
  continueButton: {
    position: 'absolute',
    backgroundColor: DATA_MANAGEMENT_BLUE,
    border: `${DATA_MANAGEMENT_BLUE} 1px solid`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
    right: 0,
    top: 0,
    fontSize: '1rem',
    letterSpacing: '0.05em',
    height: '100%',
    width: '7rem',
    boxShadow: 'none',
    ':disabled': {
      backgroundColor: DATA_MANAGEMENT_BLUE_2,
      color: WHITE,
    },
    '&:hover': {
      backgroundColor: DATA_MANAGEMENT_BLUE_2,
      color: DATA_MANAGEMENT_BLUE,
      boxShadow: 'none',
    },
  },
}));

export const useOrdersListStyles = makeStyles()(() => ({
  columnTitle: {
    fontWeight: 500,
    fontSize: '0.75rem',
  },
  selectedRow: {
    backgroundColor: '#e0dffb',
  },
  horizontallyCenteredCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2rem',
    fontSize: '1rem',
  },
  slideGroupVerticalContainer: {
    maxHeight: '60vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    ...VERTICAL_SCROLL_STYLE,
  },
  container: {
    padding: '0.325rem 1rem 0.325rem 1rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    minWidth: 600,
  },
  rowHovered: {
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: BACKGROUND_GREY,
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
  label: {
    lineHeight: 2,
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  textWithLimitedWidth: {
    width: 'calc(100% - 8px)',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
  },
  orderInfo: {
    fontSize: '1rem',
    color: DATA_MANAGEMENT_BLUE,
    cursor: 'pointer',
  },
  spinner: { padding: '0.25rem', margin: '0.5rem' },
}));
