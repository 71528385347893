export const getBearerToken = () => {
  const token = require('store/store').store.getState().userDetails?.authToken;

  return token ? `Bearer ${token}` : '';
};

export const getLocalToken = () => {
  const token = localStorage.getItem('token');

  return token ? `Token ${token}` : '';
};
