import React from 'react';
import { Grid, Typography, Checkbox, CircularProgress } from '@mui/material';
import { useOrdersListStyles } from 'components/TeamOverview/components/ManageDataCard/styles';
import { MAX_SELECTABLE_ORDERS_FOR_SELECT_ALL } from 'components/TeamOverview/constants';

export const OrdersListHeader = ({
  handleHeaderCheckboxClick,
  isAllRowsSelected,
  activeAction,
  isSlidesLoading,
  ordersCount,
}) => {
  const { classes } = useOrdersListStyles();
  const isSelectAllDisplayed =
    ordersCount <= MAX_SELECTABLE_ORDERS_FOR_SELECT_ALL;

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={0.25}
        className={`${classes.column} ${classes.horizontallyCenteredCell}`}
      >
        {activeAction && isSlidesLoading ? (
          <CircularProgress size={20} className={classes.spinner} />
        ) : activeAction && isSelectAllDisplayed ? (
          <Checkbox
            size="small"
            checked={isAllRowsSelected}
            onChange={handleHeaderCheckboxClick}
          />
        ) : null}
      </Grid>
      <Grid
        item
        xs={0.75}
        className={`${classes.column} ${classes.horizontallyCenteredCell}`}
      >
        <Typography className={classes.label}>ACTIVITY</Typography>
      </Grid>
      <Grid
        item
        xs={1}
        className={`${classes.column} ${classes.horizontallyCenteredCell}`}
      >
        <Typography className={classes.label}>USED</Typography>
      </Grid>
      <Grid item xs={3.25} className={classes.column}>
        <Typography className={classes.label}>PROJECT/ORDER</Typography>
      </Grid>
      <Grid item xs={1.75} className={classes.column}>
        <Typography className={classes.label}>STATUS</Typography>
      </Grid>
      <Grid item xs={2} className={classes.column}>
        <Typography className={classes.label}>CREATED DATE</Typography>
      </Grid>
      <Grid
        item
        xs={0.5}
        className={`${classes.column} ${classes.horizontallyCenteredCell}`}
      >
        <Typography className={classes.label}>SLIDES</Typography>
      </Grid>
      <Grid
        item
        xs={0.75}
        className={`${classes.column} ${classes.horizontallyCenteredCell}`}
      >
        <Typography className={classes.label}>IF SLIDES</Typography>
      </Grid>
      <Grid
        item
        xs={1.75}
        className={`${classes.column} ${classes.horizontallyCenteredCell}`}
      >
        <Typography className={classes.label}>ANALYSIS REPORTS</Typography>
      </Grid>
    </Grid>
  );
};
