import { Box, Checkbox, Typography } from '@mui/material';
import { BlocksModalWrapper } from 'components/BlocksList/BlocksModals/BlocksModalWrapper';
import { SubmitBlocksTypes } from 'components/BlocksList/constants';
import { getBlocksProjectCount } from 'components/BlocksList/utilities';
import { useConfirmDeleteModalStyles } from 'components/TeamOverview/components/ConfirmActionModal/styles';
import React, { useState } from 'react';

export const DisposeBlocksModal = ({ open, handleClose, selectedRows }) => {
  const { classes } = useConfirmDeleteModalStyles();
  const [isFirstAgreementChecked, setIsFirstAgreementChecked] = useState(false);
  const [isSecondAgreementChecked, setIsSecondAgreementChecked] =
    useState(false);
  const projectsCount = getBlocksProjectCount(selectedRows);
  const text = `YOU'RE DISPOSING ${selectedRows.length} BLOCKS FROM ${projectsCount} PROJECTS`;

  return (
    <BlocksModalWrapper
      open={open}
      handleClose={handleClose}
      actionType={SubmitBlocksTypes.DISPOSE}
      disabled={!isFirstAgreementChecked || !isSecondAgreementChecked}
      text={text}
    >
      <Box>
        <Typography className={classes.warning}>
          Warning: Disposal of Blocks
        </Typography>
        <Typography>
          You are about to dispose these selected blocks. Ensure your experiment
          has concluded and access to this data will no longer be required
          before proceeding. These blocks will be placed into a queue to be
          permanently disposed after confirming below.
        </Typography>
        <ul>
          <Typography component="li">
            You will no longer be able to view this block on the PathologyMap
            platform, and all associated hyperlinks will be converted to plain
            text.
          </Typography>
        </ul>
        <Box marginTop={2} paddingLeft={1}>
          <Box>
            <Checkbox
              size="medium"
              checked={isFirstAgreementChecked}
              onChange={() => setIsFirstAgreementChecked((prev) => !prev)}
            />
            <Typography component="span" className={classes.agreementText}>
              I confirm that my experiment has concluded, that I have no
              regulatory storage requirements, and that these blocks will no
              longer be needed.
            </Typography>
          </Box>
          <Box>
            <Checkbox
              size="medium"
              checked={isSecondAgreementChecked}
              onChange={() => setIsSecondAgreementChecked((prev) => !prev)}
            />
            <Typography component="span" className={classes.agreementText}>
              I understand that block disposal is a permanent action and this
              cannot be undone.
            </Typography>
          </Box>
        </Box>
      </Box>
    </BlocksModalWrapper>
  );
};
