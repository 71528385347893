import { ShippingAddressDisplay } from 'components/OrderForm/BillingShipping/BillingShippingComponents';
import { useBillingShippingStyles } from 'components/OrderForm/BillingShipping/styles';
import { CheckmarkProvider } from 'components/OrderForm/components/CheckmarkProvider';
import { createBorderStylesForReactSelect } from 'components/OrderForm/styles';
import { useState } from 'react';
import Select from 'react-select';

export const AddressSelect = ({
  value,
  onChange,
  shippingAddresses,
  validated,
}) => {
  const { classes } = useBillingShippingStyles();
  const [isTouched, setTouched] = useState(false);
  const isCheckmarkDisplayed = validated || isTouched;
  const isValid = !!value;
  const styles = {
    control: (base) =>
      isCheckmarkDisplayed
        ? createBorderStylesForReactSelect(base, isValid)
        : base,
  };

  const onBlur = () => {
    setTouched(true);
  };

  return (
    <div className={classes.selectForm}>
      <CheckmarkProvider
        isValid={isValid}
        validated={isCheckmarkDisplayed}
        visible
      >
        <Select
          options={shippingAddresses}
          isMulti={false}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          clearable={false}
          className={classes.selectForm}
          styles={styles}
        />
      </CheckmarkProvider>
      <ShippingAddressDisplay address={value} />
    </div>
  );
};
