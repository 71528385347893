import { RenderAdjustableGridOrderSlideList } from 'components/OrderSlidesList/OrderSlidesListViewAdjustableGrid';
import React, { useState } from 'react';
import { FloatingMacro } from 'components/OrderSlidesList/FloatingMacro';
import { useSelector } from 'react-redux';
import { isSharedPageSelector } from 'store/slices/slidesListSlice/slidesListSlice';
import { currentUserPreferencesSelector } from 'store/slices/userDetailsSlice';
import { THUMBNAIL_SWITCH_FIELD } from 'components/ImageViewer/constants';

export const SlideImageView = ({ slide }) => {
  const [annotationsCount, setAnnotationsCount] = useState(null);
  const isSharedPage = useSelector(isSharedPageSelector);
  const preferences = useSelector(currentUserPreferencesSelector);

  const viewerProps = {
    slides: [slide],
  };

  const groupsProps = {
    selectedSlides: [slide],
  };

  const commentsProps = {
    slide,
    isSharedPage,
  };

  return (
    <>
      <RenderAdjustableGridOrderSlideList
        showFavoriteIcon
        annotationsCount={annotationsCount}
        slidesViewerProps={viewerProps}
        setAnnotationsCount={setAnnotationsCount}
        currentSlide={slide}
        slidesGroupProps={groupsProps}
        slideCommentsProps={commentsProps}
        isSingleViewer
      />
      <FloatingMacro
        thumbnail={slide?.large_macro_url}
        isVisible={preferences[THUMBNAIL_SWITCH_FIELD]}
      />
    </>
  );
};
