import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import shallow from 'zustand/shallow';

import {
  useLoader,
  useImageSettingsStore,
  useViewerStore,
} from 'components/IFViewer/state';
import {
  getPixelValueDisplay,
  toRgb,
  truncateDecimalNumber,
} from 'components/IFViewer/utils';

import { ChannelOptions } from 'components/IFViewer/components/Controller/components/ChannelOptions';
import { makeStyles } from 'tss-react/mui';
import {
  MAX_16_BIT_BRIGHTNESS,
  MAX_8_BIT_BRIGHTNESS,
} from 'components/IFViewer/components/Controller/constants';

import { UINT8 } from 'components/IFViewer/constants';

const useStyles = makeStyles()({
  wrapper: {
    margin: 0,
  },
});

export const ChannelController = ({
  index,
  name,
  onSelectionChange,
  channelsVisible,
  toggleIsOn,
  handleSliderChange,
  domain,
  slider,
  color,
  handleRemoveChannel,
  handleColorSelect,
  isLoading,
  isColorLocked,
  handleLockColor,
  availableColors,
  onChannelOptionChange,
  customChannelOptions,
  slideUUID,
}) => {
  const { classes } = useStyles();

  const [pixelValues] = useViewerStore((store) => [store.pixelValues], shallow);

  const loader = useLoader();
  const colormap = useImageSettingsStore((store) => store.colormap);
  const [useLinkedView, use3d] = useViewerStore(
    (store) => [store.useLinkedView, store.use3d],
    shallow,
  );

  const rgbColor = toRgb(colormap, color);
  const [min, max] = domain;
  // If the min/max range is and the dtype is float, make the step size smaller so contrastLimits are smoother.
  const step =
    max - min < 500 && loader[0]?.dtype === 'Float32' ? (max - min) / 500 : 1;
  const shouldShowPixelValue = !useLinkedView && !use3d;

  const maxIntensity =
    loader[0]?.dtype === UINT8 ? MAX_8_BIT_BRIGHTNESS : MAX_16_BIT_BRIGHTNESS;

  const sliderData = {
    min,
    max: maxIntensity,
    step,
    isLoading,
    slider,
    handleSliderChange,
  };

  return (
    <Grid
      container
      direction="column"
      m={2}
      justifyContent="center"
      className={classes.wrapper}
    >
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={10}>
          {availableColors.length ? (
            <Select
              native
              value={name}
              onChange={({ target }) => onSelectionChange(target.value, index)}
            >
              {[name, ...availableColors].map((opt) => (
                <option disabled={isLoading} key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </Select>
          ) : (
            name
          )}
        </Grid>
        <Grid item>
          <ChannelOptions
            name={name}
            handleRemoveChannel={handleRemoveChannel}
            handleColorSelect={handleColorSelect}
            handleLockColor={handleLockColor}
            disabled={isLoading}
            isColorLocked={isColorLocked}
            onChannelOptionChange={onChannelOptionChange}
            customChannelOptions={customChannelOptions}
            slideUUID={slideUUID}
            sliderData={sliderData}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={2}>
          {getPixelValueDisplay(
            pixelValues[index],
            isLoading,
            shouldShowPixelValue,
          )}
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            onChange={toggleIsOn}
            disabled={isLoading}
            checked={channelsVisible}
            style={{
              color: rgbColor,
              '&$checked': {
                color: rgbColor,
              },
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <Slider
            disabled={isLoading}
            value={slider}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            getAriaLabel={() => `${name}-${color}-${slider}`}
            valueLabelFormat={(v) => truncateDecimalNumber(v, 5)}
            min={min}
            max={maxIntensity}
            size="small"
            step={step}
            orientation="horizontal"
            style={{
              color: rgbColor,
              marginTop: '7px',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
