import dayjs from 'dayjs';
import { DATE_FORMAT } from 'components/SampleEditor/components/SamplesChangelog/constants';

export const formatJSONString = (jsonString) =>
  JSON.parse(jsonString.replaceAll("'", '"'));

export const getGroupedData = (samplesData, filterText) =>
  samplesData
    .filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(filterText.toLowerCase()),
      ),
    )
    .reduce((acc, item) => {
      const date = dayjs(item?.history_date).format(DATE_FORMAT);
      if (!acc[date]) {
        acc[date] = [];
      }
      const isDuplicate = acc[date].some(
        (existingItem) => existingItem.uuid === item.uuid,
      );
      if (!isDuplicate) {
        acc[date].push(item);
      }
      acc[date] = acc[date].sort((a, b) =>
        a?.project_name?.localeCompare(b.project_name),
      );

      return acc;
    }, {});

export const getInitialTables = (groupedData) =>
  Object.entries(groupedData).map(([date, data]) => ({
    title: `Changes on ${date}`,
    data,
    date,
  }));

export const mapHistoryDetails = (prevTables, uuid, data, date) =>
  prevTables.map((table) => {
    if (table.data.some((item) => item.uuid === uuid && table.date === date)) {
      return {
        ...table,
        data: table.data.map((item) =>
          item.uuid === uuid ? { ...item, historyDetails: data } : item,
        ),
      };
    }
    return table;
  });

export const handleExpandedRows = (setExpandedRows, dateUUID) =>
  setExpandedRows((prevExpandedRows) =>
    prevExpandedRows.includes(dateUUID)
      ? prevExpandedRows.filter((item) => item !== dateUUID)
      : [...prevExpandedRows, dateUUID],
  );
