import React from 'react';
import { TableHead, TableRow, TableCell, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const AlternativeTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: '10px',
  },
  body: {
    padding: '10px',
    '& > span': {
      fontSize: '14px',
    },
  },
}));

export const AlternativeTableCellWithTypography = ({ children }) => (
  <AlternativeTableCell>
    <Typography component="span" variant="body1">
      {children}
    </Typography>
  </AlternativeTableCell>
);

export const CUSTOM_TABLE_CELL = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    padding: '10px',
  },
  body: {
    fontSize: '14px',
    padding: '10px',
  },
}));

export const CustomTableCellNoTypography = CUSTOM_TABLE_CELL;
export const CustomTableCell = ({ children }) => (
  <CUSTOM_TABLE_CELL>{children}</CUSTOM_TABLE_CELL>
);

export const SamplesListCustomTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: '1rem',
    border: 'solid',
    textAlign: 'center',
  },
}));

export const NewOrderTableHeader = (
  <TableHead>
    <TableRow>
      <CustomTableCell>Order Name</CustomTableCell>
      <CustomTableCell>Project</CustomTableCell>
      <CustomTableCell>Team</CustomTableCell>
      <CustomTableCell>Actions</CustomTableCell>
      <CustomTableCell>State</CustomTableCell>
      <CustomTableCell>Created</CustomTableCell>
    </TableRow>
  </TableHead>
);

export const InProgressOrderTableHeader = (
  <TableHead>
    <TableRow>
      <CustomTableCell>Order Name</CustomTableCell>
      <CustomTableCell>Project</CustomTableCell>
      <CustomTableCell>Team</CustomTableCell>
      <CustomTableCell>Actions</CustomTableCell>
      <CustomTableCell>State</CustomTableCell>
      <CustomTableCell>PO</CustomTableCell>
      <CustomTableCell>Histology Start Date</CustomTableCell>
      <CustomTableCell>Histology Due Date</CustomTableCell>
      <CustomTableCell>Created</CustomTableCell>
    </TableRow>
  </TableHead>
);

export const FinishedOrderTableHeader = (
  <TableHead>
    <TableRow>
      <CustomTableCell>Order Name</CustomTableCell>
      <CustomTableCell>Project</CustomTableCell>
      <CustomTableCell>Team</CustomTableCell>
      <CustomTableCell>Actions</CustomTableCell>
      <CustomTableCell>State</CustomTableCell>
      <CustomTableCell>PO</CustomTableCell>
      <CustomTableCell>Created</CustomTableCell>
      <CustomTableCell>Shipping</CustomTableCell>
    </TableRow>
  </TableHead>
);

export const UNSUBMITTED_ORDER_LABEL = 'unsubmitted';
export const FINALIZED_ORDER_LABEL = 'finalized';

export const UPCOMING_ORDER_LABEL = 'upcoming';
export const IN_PROGRESS_ORDER_LABEL = 'in_progress';

export const PENDING_SHIPPING_LABEL = 'pending_shipping';
export const WAITING_FOR_BILLING_LABEL = 'waiting_for_billing';
export const SLIDES_VISIBLE_TO_CUSTOMER_LABEL = 'slides_visible_to_customer';
export const SHIPPING_SAMPLES_AND_BLOCKS_STARTED_LABEL =
  'shipping_samples_and_blocks_started';
export const SHIPPING_SAMPLES_AND_BLOCKS_ENDED_LABEL =
  'shipping_samples_and_blocks_ended';
export const PATHOLOGY_AND_IMAGE_ANALYSIS_QUEUED_LABEL =
  'pathology_and_image_analysis_queued';
export const PATHOLOGY_AND_IMAGE_ANALYSIS_STARTED_LABEL =
  'pathology_and_image_analysis_started';
export const PATHOLOGY_AND_IMAGE_ANALYSIS_ENDED_LABEL =
  'pathology_and_image_analysis_ended';
export const COMPLETE_ORDER_LABEL = 'complete';
export const FINISHED_ORDER_LABEL = 'finished';

export const DEFAULT_HISTOWIZ_FEDEX_LABEL = 'HistoWiz FedEx Account - DEFAULT';

// updated states for order tracker
export const ORDER_FORM_SUBMITTED_TRACKER_STATE = 'order_form_submitted';
export const ORDER_ACCEPTED_TRACKER_STATE = 'accepted';
export const ORDER_SHIPPED_TRACKER_STATE = 'shipped';
export const ORDER_RECEIVED_TRACKER_STATE = 'received';
export const ORDER_REVIEW_TRACKER_STATE = 'review';
export const ORDER_PROCESSING_TRACKER_STATE = 'processing';
export const ORDER_QC_TRACKER_STATE = 'quality_control';
export const ORDER_COMPLETE_TRACKER_STATE = 'complete';
export const ORDER_IA_TRACKER_STATE = 'analysis';
export const ORDER_PREPARE_TO_SHIP_TRACKER_STATE = 'prepared_to_ship';
export const ORDER_CANCELLED_TRACKER_STATE = 'cancelled';
export const ORDER_NEW_TRACKER_STATE = 'new';
export const ORDER_CLIENT_UPLOADING_SLIDES_STATE = 'client_uploading_slides';

// the default state when shipping something back
export const SHIPPING_NO_VALUE = '';
export const SHIPPING_REQUESTED_VALUE = 'shipping_requested';
export const SHIPPING_SHIPPED_VALUE = 'shipping_ended';
export const SHIPPING_PARTIAL_VALUE = 'shipping_ended_partial';
export const DROPBOX_REQUESTED_VALUE = 'dropbox_requested';

// states where we allow a client to initiate/re-initate a shipping request
export const VALID_SHIPPABLE_RESHIPPABLE_STATES = [
  SHIPPING_NO_VALUE,
  SHIPPING_PARTIAL_VALUE,
];
// per ke's request, she wants any order that's finished from the lab to be on the finished orders tab
export const finishedOrderStates = [
  FINISHED_ORDER_LABEL,
  COMPLETE_ORDER_LABEL,
  FINALIZED_ORDER_LABEL,
  WAITING_FOR_BILLING_LABEL,
  SLIDES_VISIBLE_TO_CUSTOMER_LABEL,
  SHIPPING_SAMPLES_AND_BLOCKS_STARTED_LABEL,
  SHIPPING_SAMPLES_AND_BLOCKS_ENDED_LABEL,
  PATHOLOGY_AND_IMAGE_ANALYSIS_QUEUED_LABEL,
  PENDING_SHIPPING_LABEL,
  PATHOLOGY_AND_IMAGE_ANALYSIS_STARTED_LABEL,
  PATHOLOGY_AND_IMAGE_ANALYSIS_ENDED_LABEL,
];
// for all types of orders, including those with image analysis or path consultations, if they get here ...
// the order is done, so show it on the done tab
export const allOrdersFinishedStates = [
  FINISHED_ORDER_LABEL,
  FINALIZED_ORDER_LABEL,
  COMPLETE_ORDER_LABEL,
];
export const unsubmittedOrderStates = ['new', ''];
export const editableOrderStates = ['new', 'order_form_submitted', ''];
export const editableConsultationStates = [
  'new',
  'auto_generated_from_normal_order_form',
  'auto_generated_from_order',
  'submitted_from_app_questionnaire_complete',
  'submitted_from_order',
  'submitted',
  'client_email_sent_questionnaire',
  'client_email_responded',
  'client_email_sent',
  'client_email_responded',
  'pathologist_emailed', // this isn't true, but remove when ready to launch
];

// have a list of states that if someone submitting from the order form will change the
// state and move forward
export const newConsultationOrderFormStates = [
  'new',
  'auto_generated_from_normal_order_form',
  'auto_generated_from_order',
];

export const startableConsultationStates = ['pathologist_accepted'];

export const ignoreOrderStates = ['cancelled'];

export const VALID_ORDERS_LIST_TAB_VALUES = [
  FINALIZED_ORDER_LABEL,
  UNSUBMITTED_ORDER_LABEL,
  IN_PROGRESS_ORDER_LABEL,
];

export const ORDER_QUESTIONS_URL =
  'https://7330706.extforms.netsuite.com/app/site/crm/externalcasepage.nl?compid=7330706&formid=2&h=AAFdikaIVqd_ncejiUzGD8lPxdvBQ8OJcx2n_sOFs9ndpu1F0Bg';
export const UNSUBMITTED_ORDER = 'unsubmitted';
export const IN_PROGRESS_ORDER = 'in_progress';
export const FINISHED_ORDER = 'finished';
