import { getAnalysisType } from 'components/AITools/utilities/common';
import { baseCreateApi } from 'store/apis/baseCreateApi';

export const walletApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    confirmAIModel: builder.mutation({
      query: ({ teamUUID, walletUUID, modelId, slidesUUIDs }) => ({
        url: '/ml_compute_orders/',
        method: 'POST',
        body: {
          team_uuid: teamUUID,
          team_wallet_uuid: walletUUID,
          processing_type: getAnalysisType(modelId),
          slide_uuids: slidesUUIDs,
        },
      }),
    }),
    getMlComputeOrders: builder.query({
      query: () => ({
        url: '/ml_compute_orders/',
      }),
    }),
  }),
});

export const { useConfirmAIModelMutation, useGetMlComputeOrdersQuery } =
  walletApi;
