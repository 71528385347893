import {
  GROSS_PROCESS_EMBED_ONLY_SERVICE,
  H_AND_E_SERVICE,
  IF_SERVICE,
  IF_SCAN_ONLY_SERVICE,
  IHC_SERVICE,
  IMAGE_ANALYSIS_SERVICE,
  PATHOLOGY_CONSULTATION_SERVICE,
  REQUESTED_ANTIBODIES_FIELD_VALUE,
  REQUESTED_PANELS_FIELD_VALUE,
  REQUESTED_SERVICES_FIELD_NAME,
  REQUESTED_SPECIAL_STAINS_FIELD_VALUE,
  SCAN_ONLY_SERVICE,
  SPECIAL_STAIN_SERVICE,
  SPECIALIZED_BLOCK_SERVICE,
  UNSTAINED_SERVICE,
} from 'components/OrderForm/constants';
import { getValueFromLookupCache } from 'components/utilities/gridDataSerializers';
import { ALL_HUMAN_AND_NON_HUMAN_COLUMNS } from 'components/OrderFormV2/SampleScienceInfoV2/ScienceInfoConstants';
import {
  REQUESTED_GROSS_PROCESS_EMBED_ONLY,
  REQUESTED_H_AND_E_STAIN_COUNT,
  REQUESTED_H_AND_E_STAIN_IMG_ANALYSIS_COUNT,
  REQUESTED_H_AND_E_STAIN_PATH_CONSULT_COUNT,
  REQUESTED_IF_SLIDE_SCANNING_ONLY_COUNT,
  REQUESTED_IMAGE_ANALYSIS,
  REQUESTED_IMAGE_ANALYSIS_ONLY_COUNT,
  REQUESTED_PATHOLOGY_CONSULTATION,
  REQUESTED_PATHOLOGY_CONSULTATION_ONLY_COUNT,
  REQUESTED_SLIDE_SCANNING_ONLY_COUNT,
  REQUESTED_SPECIALIZED_BLOCK,
  REQUESTED_UNSTAINED_COUNT,
} from 'components/OrderForm/SlideServicesForm/constants';

export const serializeRequestedServicesOnSample = (sample) => {
  const servicesMap = {
    requested_h_and_e_stain_count: H_AND_E_SERVICE,
    requested_unstained_count: UNSTAINED_SERVICE,
    requested_pathology_consultation: PATHOLOGY_CONSULTATION_SERVICE,
    requested_image_analysis: IMAGE_ANALYSIS_SERVICE,
    requested_specialized_block: SPECIALIZED_BLOCK_SERVICE,
    requested_antibodies: IHC_SERVICE,
    requested_special_stains: SPECIAL_STAIN_SERVICE,
    requested_channel_filters: IF_SERVICE,
    requested_panels: IF_SERVICE,
    gross_process_embed_only: GROSS_PROCESS_EMBED_ONLY_SERVICE,
    requested_slide_scanning_only_count: SCAN_ONLY_SERVICE,
    requested_if_slide_scanning_only_count: IF_SCAN_ONLY_SERVICE,
  };

  const requestedServicesRowInfo = Object.keys(servicesMap).reduce(
    (acc, key) => {
      if (
        sample[key] &&
        (Array.isArray(sample[key]) ? sample[key].length > 0 : true)
      ) {
        acc.push(servicesMap[key]);
      }
      return acc;
    },
    [],
  );

  requestedServicesRowInfo.sort();

  sample[REQUESTED_SERVICES_FIELD_NAME] = [
    ...new Set(requestedServicesRowInfo),
  ];
};

export const serializeRequestedAntibodiesOnSample = (sample) => {
  const sampleRequestedAntibodies = sample[REQUESTED_ANTIBODIES_FIELD_VALUE];
  const sampleAntibodies = sampleRequestedAntibodies.map(
    ({ antibody }) => antibody.display_name,
  );

  sample[REQUESTED_ANTIBODIES_FIELD_VALUE] = sampleAntibodies;
};

export const serializeRequestedSpecialStainsOnSample = (sample) => {
  const sampleRequestedSpecialStains =
    sample[REQUESTED_SPECIAL_STAINS_FIELD_VALUE];
  const sampleSpecialStains = sampleRequestedSpecialStains.map(
    ({ special_stain }) => special_stain.name,
  );

  sample[REQUESTED_SPECIAL_STAINS_FIELD_VALUE] = sampleSpecialStains;
};

export const serializeRequestedPanelsOnSample = (sample) => {
  const sampleRequestedPanels = sample[REQUESTED_PANELS_FIELD_VALUE];
  const samplePanels = sampleRequestedPanels.map(
    ({ panel }) => panel.display_name,
  );

  sample[REQUESTED_PANELS_FIELD_VALUE] = samplePanels;
};

const serializeScienceInfoDetailsFromGridRow = (row) => {
  const sampleColumns = Object.keys(row);

  // we have a lot of additional columns for sample science info ... not all of them will be populated
  const sampleScienceColumnsPopulated = sampleColumns.filter((columnName) => {
    return ALL_HUMAN_AND_NON_HUMAN_COLUMNS.includes(columnName);
  });

  const sampleScienceInfoDetails = {};
  sampleScienceColumnsPopulated.forEach((columnName) => {
    sampleScienceInfoDetails[columnName] = row[columnName];
  });

  return sampleScienceInfoDetails;
};

export const serializeScienceInfoOnSample = (sample) => {
  const scienceInfoDetails = sample.science_info;

  // if it's blank, or it's an empty array (incorrect default we used have on sample, just skip)
  if (!scienceInfoDetails || Array.isArray(sample)) {
    return;
  }

  sample = Object.assign(sample, scienceInfoDetails);
  return sample;
};

const getSampleRequestedServiceCount = (sample, serviceName, serviceType) => {
  const isServiceRequested = sample.requested_services.includes(serviceType);
  const existingCount = isServiceRequested ? sample[serviceName] ?? 0 : 0;
  const count = isServiceRequested && existingCount === 0 ? 1 : existingCount;

  return count;
};

export const serializeSampleDetailsToBackendPostRequest = (
  row,
  order,
  lookupCache,
) => {
  // pass a row of data (how ag-grid stores)
  // and a cache that is used to look up values
  // like KSM67 --> and get the UUID of KSM67

  // API expects a RESTful endpoint, serialize the data from a row to be backend friendly
  let details = {
    name: row.name,
    submission_uuid: row.submission.uuid,
    order_uuid: order.uuid,
    is_tumor: row.is_tumor,
    control_type: row.control_type,
    organ_uuid: row.organ.uuid,
    species_uuid: row.species.uuid,
  };

  if (row.previous_sample) {
    details['previous_sample_uuid'] = row.previous_sample.uuid;
    delete row.uuid;
  }

  if (row.uuid) {
    details['uuid'] = row.uuid;
  }

  const servicesToFilterOutStandaloneIAOrPC = [
    H_AND_E_SERVICE,
    IHC_SERVICE,
    SPECIAL_STAIN_SERVICE,
    IF_SERVICE,
  ];

  const requestedServices = row.requested_services;

  // we have to do a LOT of custom logic to support orders that may only request image analysis or pathology consultation
  // but if only IA / PC is selected, in the specify slides page on the frontend, we want to only show
  // it as a count available for that section
  const isAnyService = servicesToFilterOutStandaloneIAOrPC.some((service) =>
    requestedServices.includes(service),
  );
  const isHAndEServicedSelected = requestedServices.includes(H_AND_E_SERVICE);

  if (!isAnyService) {
    details[REQUESTED_PATHOLOGY_CONSULTATION_ONLY_COUNT] =
      getSampleRequestedServiceCount(
        row,
        REQUESTED_PATHOLOGY_CONSULTATION_ONLY_COUNT,
        PATHOLOGY_CONSULTATION_SERVICE,
      );
    details[REQUESTED_IMAGE_ANALYSIS_ONLY_COUNT] =
      getSampleRequestedServiceCount(
        row,
        REQUESTED_IMAGE_ANALYSIS_ONLY_COUNT,
        IMAGE_ANALYSIS_SERVICE,
      );
  } else {
    details[REQUESTED_PATHOLOGY_CONSULTATION_ONLY_COUNT] = 0;
    details[REQUESTED_IMAGE_ANALYSIS_ONLY_COUNT] = 0;
  }

  if (isHAndEServicedSelected) {
    details[REQUESTED_H_AND_E_STAIN_IMG_ANALYSIS_COUNT] =
      getSampleRequestedServiceCount(
        row,
        REQUESTED_H_AND_E_STAIN_IMG_ANALYSIS_COUNT,
        IMAGE_ANALYSIS_SERVICE,
      );
    details[REQUESTED_H_AND_E_STAIN_PATH_CONSULT_COUNT] =
      getSampleRequestedServiceCount(
        row,
        REQUESTED_H_AND_E_STAIN_PATH_CONSULT_COUNT,
        PATHOLOGY_CONSULTATION_SERVICE,
      );
  } else {
    details[REQUESTED_H_AND_E_STAIN_IMG_ANALYSIS_COUNT] = 0;
    details[REQUESTED_H_AND_E_STAIN_PATH_CONSULT_COUNT] = 0;
  }

  details[REQUESTED_H_AND_E_STAIN_COUNT] = getSampleRequestedServiceCount(
    row,
    REQUESTED_H_AND_E_STAIN_COUNT,
    H_AND_E_SERVICE,
  );
  details[REQUESTED_UNSTAINED_COUNT] = getSampleRequestedServiceCount(
    row,
    REQUESTED_UNSTAINED_COUNT,
    UNSTAINED_SERVICE,
  );
  details[REQUESTED_SLIDE_SCANNING_ONLY_COUNT] = getSampleRequestedServiceCount(
    row,
    REQUESTED_SLIDE_SCANNING_ONLY_COUNT,
    SCAN_ONLY_SERVICE,
  );
  details[REQUESTED_IF_SLIDE_SCANNING_ONLY_COUNT] =
    getSampleRequestedServiceCount(
      row,
      REQUESTED_IF_SLIDE_SCANNING_ONLY_COUNT,
      IF_SCAN_ONLY_SERVICE,
    );

  details[REQUESTED_PATHOLOGY_CONSULTATION] = !!requestedServices.includes(
    PATHOLOGY_CONSULTATION_SERVICE,
  );
  details[REQUESTED_IMAGE_ANALYSIS] = !!requestedServices.includes(
    IMAGE_ANALYSIS_SERVICE,
  );
  details[REQUESTED_GROSS_PROCESS_EMBED_ONLY] = !!requestedServices.includes(
    GROSS_PROCESS_EMBED_ONLY_SERVICE,
  );
  details[REQUESTED_SPECIALIZED_BLOCK] = !!requestedServices.includes(
    SPECIALIZED_BLOCK_SERVICE,
  );

  const requestedAntibodies = row[REQUESTED_ANTIBODIES_FIELD_VALUE];

  if (requestedAntibodies?.length > 0) {
    const antibodiesData = lookupCache.antibodies;
    const requested_antibodies_uuid = getValueFromLookupCache(
      'display_name',
      antibodiesData,
      requestedAntibodies,
    );
    details['requested_antibodies_uuids'] = requested_antibodies_uuid;
  }

  const requestedSpecialStains = row[REQUESTED_SPECIAL_STAINS_FIELD_VALUE];
  if (requestedSpecialStains?.length > 0) {
    const specialStainsData = lookupCache.specialStains;
    const requested_antibodies_uuid = getValueFromLookupCache(
      'name',
      specialStainsData,
      requestedSpecialStains,
    );
    details['requested_special_stains_uuids'] = requested_antibodies_uuid;
  }

  const requestedIFPanels = row[REQUESTED_PANELS_FIELD_VALUE];
  if (requestedIFPanels?.length > 0) {
    const panelsData = lookupCache.panels;
    const requested_panels_uuid = getValueFromLookupCache(
      'name',
      panelsData,
      requestedIFPanels,
    );
    details['requested_panels_uuids'] = requested_panels_uuid;
  }

  const sampleScienceInfoDetails = serializeScienceInfoDetailsFromGridRow(row);
  details['science_info'] = sampleScienceInfoDetails;

  return details;
};

export const serializeSamplesScienceInfoToBackendPostRequest = (row) => {
  // for science info, we don't store directly on the samples model
  // but instead in a json blob called science_info on the samples
  let details = {
    uuid: row.uuid,
    name: row.name,
    order_uuid: row.order.uuid,
    organ_uuid: row.organ.uuid,
    species_uuid: row.species.uuid,
    submission_uuid: row.submission.uuid,
  };

  const sampleScienceInfoDetails = serializeScienceInfoDetailsFromGridRow(row);
  details['science_info'] = sampleScienceInfoDetails;

  return details;
};
