import { Button } from '@mui/material';
import { Straighten } from '@mui/icons-material';
import React from 'react';
import {
  ANNOTATION_DRAW_MODE_ENABLED_FIELD,
  DISTANCE_MEASURE_ENABLED_FIELD,
} from '../constants';
import { useUpdateUserPreferencesMutation } from 'store/apis/userDetailsApi';
import { useSelector } from 'react-redux';
import { currentUserPreferencesSelector } from 'store/slices/userDetailsSlice';

export const MeasurementButton = ({ buttonClass }) => {
  const preferences = useSelector(currentUserPreferencesSelector);

  const isMeasureEnabled = preferences[DISTANCE_MEASURE_ENABLED_FIELD];
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();

  const handleToggleButtonChange = () => {
    const updatedPreference = {
      [DISTANCE_MEASURE_ENABLED_FIELD]: !isMeasureEnabled,
      ...(!isMeasureEnabled && { [ANNOTATION_DRAW_MODE_ENABLED_FIELD]: false }),
    };

    updateUserPreferences({ postParams: updatedPreference, partial: true });
  };

  return (
    <Button
      value="measure"
      title="Select two points by clicking on image to measure the distance. Press 'Z' to cancel current selection."
      variant="contained"
      color={isMeasureEnabled ? 'secondary' : 'default'}
      selected={isMeasureEnabled}
      onClick={handleToggleButtonChange}
      className={buttonClass}
    >
      <Straighten fontSize="small" />
    </Button>
  );
};
