import histowizHighResolutionLogo from 'images/logos/lowResolutionLogo.png';
import histowizSignUpBackground from 'images/signup_background_4_optimized.jpg';
import miamiLogo from 'images/logos/noBackgroundLogos/miller_school.png';
import miamiSignupBackground from 'images/logos/whitelabels/background_miami.ashx';
import idexxLogo from 'images/logos/noBackgroundLogos/idexx.png';
import idexxBackground from 'images/logos/whitelabels/idexx_background.jpg';

import {
  HISTOWIZ_PRIMARY_COLOR,
  HISTOWIZ_SECONDARY_COLOR,
  IDEXX_PRIMARY_COLOR,
  IDEXX_SECONDARY_COLOR,
  LOCALHOST_PRIMARY_COLOR,
  LOCALHOST_PROD_API_PRIMARY_COLOR,
  LOCALHOST_SECONDARY_COLOR,
  UMIAMI_PRIMARY_COLOR,
  UMIAMI_SECONDARY_COLOR,
} from 'utilities/colors';

export const MIAMI_HREF = 'miami.histowiz';
export const IDEXX_HREF = 'idexx.histowiz';
export const HISTOWIZ_HREF = 'app.histowiz';
export const LOCALHOST_HREF = 'localhost';

const HISTOWIZ_THEME = {
  primary: HISTOWIZ_PRIMARY_COLOR,
  secondary: HISTOWIZ_SECONDARY_COLOR,
};
const MIAMI_THEME = {
  primary: UMIAMI_PRIMARY_COLOR,
  secondary: UMIAMI_SECONDARY_COLOR,
};
const IDEXX_THEME = {
  primary: IDEXX_PRIMARY_COLOR,
  secondary: IDEXX_SECONDARY_COLOR,
};

export const THEME_PALETTE = {
  [LOCALHOST_HREF]: HISTOWIZ_THEME,
  [HISTOWIZ_HREF]: HISTOWIZ_THEME,
  [MIAMI_HREF]: MIAMI_THEME,
  [IDEXX_HREF]: IDEXX_THEME,
};

export const WHITE_LABEL_BG = {
  [HISTOWIZ_HREF]: histowizSignUpBackground,
  [LOCALHOST_HREF]: histowizSignUpBackground,
  [MIAMI_HREF]: miamiSignupBackground,
  [IDEXX_HREF]: idexxBackground,
};

export const WHITE_LABEL_LOGO = {
  [HISTOWIZ_HREF]: histowizHighResolutionLogo,
  [LOCALHOST_HREF]: histowizHighResolutionLogo,
  [MIAMI_HREF]: miamiLogo,
  [IDEXX_HREF]: idexxLogo,
};

export const APP_URL = {
  [MIAMI_HREF]: `https://u${MIAMI_HREF}.com`,
  [HISTOWIZ_HREF]: `https://${HISTOWIZ_HREF}.com`,
  [IDEXX_HREF]: `https://${IDEXX_HREF}.com`,
  [LOCALHOST_HREF]: `http://${LOCALHOST_HREF}:3000`,
};

export const PROD_API = 'https://api.histowiz.com';

export const getWhiteLabelSiteName = () => {
  const currentURL = window.location.href;

  if (currentURL.includes(MIAMI_HREF)) {
    return MIAMI_HREF;
  }

  if (currentURL.includes(IDEXX_HREF)) {
    return IDEXX_HREF;
  }

  if (currentURL.includes(LOCALHOST_HREF)) {
    return LOCALHOST_HREF;
  }

  return HISTOWIZ_HREF;
};

export const getWhiteLabelAppURL = () => APP_URL[getWhiteLabelSiteName()];

export const getWhiteLabelLogo = () =>
  WHITE_LABEL_LOGO[getWhiteLabelSiteName()];

export const getWhiteLabelSignupBackground = () =>
  WHITE_LABEL_BG[getWhiteLabelSiteName()];

export const getWhiteLabelThemePalette = () => {
  const { REACT_APP_API_URL, NODE_ENV } = process.env;

  const primary =
    REACT_APP_API_URL === PROD_API
      ? LOCALHOST_PROD_API_PRIMARY_COLOR
      : LOCALHOST_PRIMARY_COLOR;
  const localhostTheme = { primary, secondary: LOCALHOST_SECONDARY_COLOR };

  return NODE_ENV === 'development'
    ? localhostTheme
    : THEME_PALETTE[getWhiteLabelSiteName()];
};
