import { HISTOWIZ_LIGHT_SECONDARY_COLOR } from 'utilities/colors';
import {
  IMAGE_ANALYSIS_SERVICE,
  PATHOLOGY_CONSULTATION_SERVICE,
  SCAN_ONLY_SERVICE,
  IF_SCAN_ONLY_SERVICE,
} from 'components/OrderForm/constants';
import { CountEditor } from 'components/OrderForm/SlideServicesForm/agGridCountEditor';

export const disabledCellStyle = {
  opacity: 0.5,
  backgroundColor: '#C0C0C0',
  fontWeight: 'bold',
};
export const enabledCellStyle = {
  backgroundColor: HISTOWIZ_LIGHT_SECONDARY_COLOR,
};

export const BASE_COLUMNS_FOR_SLIDE_SAMPLE_COUNTS = [
  {
    headerName: 'Sample Name',
    field: 'name',
    sortable: true,
    cellStyle: disabledCellStyle,
    width: 225,
  },
  {
    headerName: 'Service Type',
    field: 'serviceType',
    sortable: true,
    cellStyle: disabledCellStyle,
    width: 200,
  },
  {
    headerName: 'Stain',
    field: 'type',
    sortable: true,
    cellStyle: disabledCellStyle,
    width: 250,
  },
  {
    headerName: 'Count (Editable) 👇',
    field: 'count',
    editable: true,
    cellStyle: enabledCellStyle,
    cellEditor: CountEditor,
    width: 200,
  },
];

export const EnabledDisabledForIAorPCColumnCellRenderer = (params) => {
  const rowDataType = params?.data?.serviceType;
  const invalidTypes = [
    PATHOLOGY_CONSULTATION_SERVICE,
    IMAGE_ANALYSIS_SERVICE,
    SCAN_ONLY_SERVICE,
    IF_SCAN_ONLY_SERVICE,
  ];
  const disabled = invalidTypes.includes(rowDataType);

  if (disabled) {
    return <div style={disabledCellStyle}>N/A</div>;
  }

  return <span>{params.value}</span>;
};

const EnabledDisabledForIAorPCColumnCellStyleClass = (params) => {
  const invalidTypes = [
    PATHOLOGY_CONSULTATION_SERVICE,
    IMAGE_ANALYSIS_SERVICE,
    SCAN_ONLY_SERVICE,
    IF_SCAN_ONLY_SERVICE,
  ];
  const rowDataType = params?.data?.serviceType;
  const disabled = invalidTypes.includes(rowDataType);
  if (disabled) {
    return disabledCellStyle;
  }
  return enabledCellStyle;
};

export const PATHOLOGY_CONSULT_COUNT_COLUMN = {
  headerName: 'Pathology Consult # Request',
  field: 'pathology_consultation_count',
  editable: true,
  cellRenderer: EnabledDisabledForIAorPCColumnCellRenderer,
  cellStyle: EnabledDisabledForIAorPCColumnCellStyleClass,
  cellEditor: CountEditor,
  width: 200,
};

export const IMAGE_ANALYSIS_COUNT_COLUMN = {
  headerName: 'Image Analysis # Requested',
  field: 'image_analysis_count',
  editable: true,
  cellRenderer: EnabledDisabledForIAorPCColumnCellRenderer,
  cellStyle: EnabledDisabledForIAorPCColumnCellStyleClass,
  cellEditor: CountEditor,
  width: 250,
};
