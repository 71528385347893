import { Close, OpenInFull } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useCollapsedBarStyles } from 'components/common/CollapsedBar/styles';
import { UPDATE_USER_DETAILS_MESSAGE } from 'constants/errorMessages';

import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUserPreferences } from 'services/resources/commonResources';
import {
  currentUserPreferencesSelector,
  updatePreferences,
} from 'store/slices/userDetailsSlice';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const CollapsedBar = memo(
  ({ preferencesField, onExpand, title, positionClass }) => {
    const dispatch = useDispatch();
    const { showError } = useSnackbar();
    const preferences = useSelector(currentUserPreferencesSelector);
    const { classes } = useCollapsedBarStyles();
    const position = positionClass || classes.position;

    const handleCollapsedBarClick = () => {
      const updatedPreferences = {
        ...preferences,
        [preferencesField]: false,
      };

      dispatch(updatePreferences({ [preferencesField]: false }));

      updateCurrentUserPreferences({ preferences: updatedPreferences }).catch(
        () => {
          showError(UPDATE_USER_DETAILS_MESSAGE);
        },
      );
    };

    return (
      <Box className={`${classes.collapsedThumbnail} ${position}`}>
        {title}
        <Box className={classes.collapsedIcons}>
          <OpenInFull fontSize="small" onClick={onExpand} />
          <Close fontSize="small" onClick={handleCollapsedBarClick} />
        </Box>
      </Box>
    );
  },
);
