import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { DefaultModal } from 'components/Modals/DefaultModal';
import { CreateSlideGroupModal } from 'components/SlideGroups/Modals/CreateSlideGroupModal';
import { useGetSlideGroupsQuery } from 'store/apis/slideGroupsApi';
import {
  MODAL_WITHOUT_BACKGROUND,
  useAddSlidesToSlideGroupModalStyles,
} from 'components/SlideGroups/utilities/styles';
import { modalWindowMode } from 'components/SlideGroups/utilities/constants';
import { SelectedSlidesSections } from './components/SelectedSlidesSections';
import { SlideGroupsVerticalContainer } from './components/SlideGroupsVerticalContainer';

export const AddAndRemoveSelectedSlidesFromSlideGroupsModal = ({
  open,
  onClose,
  selectedSlides,
  mode = modalWindowMode.ADD,
}) => {
  const { classes } = useAddSlidesToSlideGroupModalStyles();

  const { data: slideGroups } = useGetSlideGroupsQuery();
  const [slideGroupModalOpen, setSlideGroupModalOpen] = useState(false);

  if (
    !open ||
    !selectedSlides.filter((slide) => !!slide).length ||
    !slideGroups
  )
    return null;

  const isDeleteMode = mode === modalWindowMode.REMOVE;
  const title = isDeleteMode ? 'Remove From Group' : 'Add To Group';

  return (
    <DefaultModal
      open={open}
      onClose={onClose}
      width={'1200px'}
      styles={MODAL_WITHOUT_BACKGROUND}
    >
      <Box mr={1} width={'30%'} className={classes.modalBackground}>
        <SelectedSlidesSections
          classes={classes}
          selectedSlides={selectedSlides}
        />
      </Box>
      <Box width={'70%'} className={classes.modalBackground}>
        <Box mb={2} display={'flex'} justifyContent={'space-between'}>
          <Typography variant="h5" color={'gray'}>
            {title}
          </Typography>
          {!isDeleteMode && (
            <Button
              variant={'contained'}
              onClick={() => setSlideGroupModalOpen(true)}
            >
              Create Slide Group
            </Button>
          )}
        </Box>
        <SlideGroupsVerticalContainer
          selectedSlides={selectedSlides}
          isDeleteMode={isDeleteMode}
          customClasses={classes}
        />
      </Box>
      <CreateSlideGroupModal
        open={slideGroupModalOpen}
        onClose={() => setSlideGroupModalOpen(false)}
      />
    </DefaultModal>
  );
};
