import { Typography, Box, Checkbox } from '@mui/material';
import React from 'react';

export const ReviewContributeStepContent = ({
  classes,
  isSigned,
  isFirstAgreementChecked,
  setIsFirstAgreementChecked,
  handleOpenTermsAndConditionsModal,
}) => {
  const checkboxLabelStyles = `${classes.agreementText} ${
    !isSigned ? classes.grayText : ''
  }`;

  return (
    <Box>
      <Typography className={classes.warning}>
        Warning: Contribution of Files
      </Typography>
      <Typography>
        You are about to contribute the selected files and metadata to the
        HistoWiz PathologyMap Database. This action cannot be undone.
      </Typography>
      <ul>
        <Typography component="li">
          By donating, you will no longer have private control over these files,
          but you will retain access to view these files without incurring any
          data management fees.
        </Typography>
        <Typography component="li">
          The files will be de-identified before entering our database and your
          organization will retain non-exclusive publishing rights regarding
          this data.
        </Typography>
        <Typography
          className={classes.termsAndConditionsLink}
          fontWeight={500}
          onClick={handleOpenTermsAndConditionsModal}
        >
          Review the Contribution Terms & Conditions before Proceeding
        </Typography>
      </ul>
      <Box marginTop={2} paddingLeft={1}>
        <Box>
          <Checkbox
            disabled={!isSigned}
            size="medium"
            checked={isFirstAgreementChecked}
            onChange={() => setIsFirstAgreementChecked((prev) => !prev)}
          />
          <Typography component="span" className={checkboxLabelStyles}>
            By proceeding, you confirm your final data management choices.
            Changes cannot be made after confirmation.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
