import { makeStyles } from 'tss-react/mui';
import { SECONDARY_WHITE } from 'utilities/colors';

export const useScienceInfoStyles = makeStyles()((theme) => ({
  page: {
    height: '100vh',
    backgroundColor: SECONDARY_WHITE,
  },
  navigator: {
    height: '15vh',
  },
  requiredLabel: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  notRequiredLabel: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));
