import { useAddressCardStyles } from 'components/TeamOverview/components/ConfirmDataOnHardDiskModal/styles';
import { Typography, Box } from '@mui/material';
import { buildAddressLine } from 'components/TeamOverview/utilities';

export const AddressCard = ({ address }) => {
  const { classes } = useAddressCardStyles();

  if (!address) return null;

  return (
    <Box className={classes.container}>
      <Box>
        <Typography className={classes.company}>{address.name}</Typography>
        <Typography className={classes.address}>
          {buildAddressLine(address)}
        </Typography>
        <Typography className={classes.phoneNumber}>
          {address.phone_number}
        </Typography>
      </Box>
    </Box>
  );
};
