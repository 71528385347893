import { createSlice } from '@reduxjs/toolkit';

export const annotationsSlice = createSlice({
  name: 'annotationsSlice',
  initialState: [],
  reducers: {
    setAnnotations: (state, { payload }) => {
      const { data, queryArgs } = payload;

      state.push({ slideUUID: queryArgs.slideUUID, data });
    },
    createAnnotation: (state, { payload }) => {
      const { data: annotation, queryArgs } = payload;
      const slideAnnotations = state.find(
        (slide) => slide.slideUUID === queryArgs.slideUUID,
      );
      if (slideAnnotations?.data) {
        slideAnnotations?.data.push(annotation);
      } else {
        state.push({ slideUUID: queryArgs.slideUUID, data: [annotation] });
      }
    },
    deleteAnnotation: (state, { payload }) => {
      const { annotationUUID, slideUUID } = payload.queryArgs;
      const slide = state.find((slide) => slide.slideUUID === slideUUID);
      const slideAnnotations = slide?.data;
      if (slideAnnotations) {
        slideAnnotations.splice(
          slideAnnotations.findIndex(
            (annotation) => annotation.uuid === annotationUUID,
          ),
          1,
        );
      }
    },
    createAnnotationComment: (state, { payload }) => {
      const {
        data: comment,
        queryArgs: { slideUUID, annotationUUID },
      } = payload;

      const slideAnnotations = state.find(
        (slide) => slide.slideUUID === slideUUID,
      );

      const annotation = slideAnnotations.data.find(
        (annotation) => annotation.uuid === annotationUUID,
      );

      if (annotation?.comments) {
        annotation.comments.push(comment);
      }
    },
  },
});

export const {
  setAnnotations,
  createAnnotation,
  deleteAnnotation,
  createAnnotationComment,
  updateAnnotation,
} = annotationsSlice.actions;

export const annotationsReducer = annotationsSlice.reducer;

export const annotationsSelector = (state) => state.annotations;
