import { DefaultModal } from 'components/Modals/DefaultModal';
import { useInviteTeamMembersModalStyles } from 'components/Modals/InviteTeamMembersModal/styles';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import {
  Autocomplete,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Controller, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { getTeams } from 'services/resources/commonResources';
import { FETCH_TEAMS_ERROR_MESSAGE } from 'constants/errorMessages';
import { InvitedMembersTable } from 'components/Modals/InviteTeamMembersModal/InvitedMembersTable';
import { EMAIL_ALREADY_SENT_MESSAGE } from 'components/Modals/InviteTeamMembersModal/constants';
import { validationSchema } from 'components/Modals/InviteTeamMembersModal/utilities';
import { useInvites } from 'components/Modals/InviteTeamMembersModal/hooks';

const InviteTeamMembersModalHeader = ({ classes, handleClose }) => (
  <Box className={classes.header}>
    <Box className={classes.row}>
      <PersonAddAlt1OutlinedIcon className={classes.personIcon} />
      <Typography className={classes.modalLabel}>
        INVITE NEW USER TO TEAM
      </Typography>
    </Box>
    <ClearOutlinedIcon onClick={handleClose} cursor="pointer" />
  </Box>
);

export const InviteTeamMembersModal = ({ open, handleClose }) => {
  const { classes } = useInviteTeamMembersModalStyles();

  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const unmountIgnore = useUnmountIgnore();
  const { showWarning, showError } = useSnackbar();
  const { invitedUsers, postInvite } = useInvites();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

  const handleTeamSelection = useCallback(
    (team) => {
      setSelectedTeam(team);
      setValue('teamUUID', team?.uuid);
    },
    [setSelectedTeam, setValue],
  );

  useEffect(() => {
    getTeams()
      .then((response) => {
        const { data } = response;

        if (!unmountIgnore.current) {
          setTeams([...data]);

          if (data.length === 1) {
            handleTeamSelection(data[0]);
          }
        }
      })
      .catch(() => showError(FETCH_TEAMS_ERROR_MESSAGE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unmountIgnore, handleTeamSelection]);

  const onSubmit = (values) => {
    if (invitedUsers.some(({ email }) => email === values.email)) {
      showWarning(EMAIL_ALREADY_SENT_MESSAGE);

      return;
    }

    postInvite(values);
  };

  return (
    <DefaultModal
      open={!!open}
      styles={{
        width: '1000px',
        padding: 0,
      }}
    >
      <Card
        header={
          <InviteTeamMembersModalHeader
            classes={classes}
            handleClose={handleClose}
          />
        }
      >
        <Box className={classes.cardBody}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.emailInput}>
              <TextField
                className={classes.inputForm}
                error={!!errors.email?.message}
                label="Email ..."
                {...register('email')}
                type="email"
              />
              {errors.email?.message && (
                <span className={classes.errorMessage} role="alert">
                  {errors.email.message}
                </span>
              )}
            </Box>
            <Controller
              control={control}
              name="teamUUID"
              render={({ field: { onBlur, ref } }) => (
                <Autocomplete
                  disableClearable
                  options={teams}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      label="Teams"
                      margin="normal"
                    />
                  )}
                  onBlur={onBlur}
                  className={classes.inputForm}
                  value={selectedTeam}
                  onChange={(e, value) => {
                    handleTeamSelection(value);
                  }}
                />
              )}
            />

            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="large"
              type="submit"
              disabled={!isValid}
            >
              Invite
            </Button>
          </form>
          {!!invitedUsers.length && (
            <Box className={classes.inviteTable}>
              <InvitedMembersTable invitedUsers={invitedUsers} />
            </Box>
          )}
        </Box>
      </Card>
    </DefaultModal>
  );
};
