import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSavedInfoRow } from 'components/OrderForm/Info/SavedInfoModal/SavedInfoRaw/styles';

export const SavedInfoRow = ({ label, value }) => {
  const { classes } = useSavedInfoRow();
  const displayValue = value === 0 ? '0' : value || '—';

  return (
    <Box className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{displayValue}</Typography>
    </Box>
  );
};

SavedInfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
