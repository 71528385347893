import { createSlice } from '@reduxjs/toolkit';
import { actionTabs } from 'components/OrderSlidesList/SlideActionTabs/constants';

export const initialValues = {
  selectedRows: [],
  isSharedPage: false,
  group: null,
  projectUUID: null,
  order: null,
  isSlidesListLoading: false,
  openSlideInFullscreen: false,
  selectedTabTop: actionTabs.meta,
  selectedTabBottom: actionTabs.comments,
  isExpandedBottom: false,
  isExpandedTop: false,
  forceOpenModel: null,
};

const slidesListSlice = createSlice({
  name: 'slidesList',
  initialState: initialValues,
  reducers: {
    setSelectedRows: (state, { payload }) => {
      state.selectedRows = payload;
    },
    updateSelectedRow: (state, { payload }) => {
      state.selectedRows = state.selectedRows.map((slide) =>
        slide.uuid === payload.uuid ? { ...slide, ...payload } : slide,
      );
    },
    setIsSharedPage: (state, { payload }) => {
      state.isSharedPage = payload;
    },
    setProjectUUID: (state, { payload }) => {
      state.projectUUID = payload;
    },
    setSlidesListOrder: (state, { payload }) => {
      state.order = payload;
    },
    setIsSlidesListLoading: (state, { payload }) => {
      state.isSlidesListLoading = payload;
    },
    setOpenSlideInFullscreen: (state, { payload }) => {
      state.openSlideInFullscreen = payload;
    },
    setSelectedTabTop: (state, { payload }) => {
      state.selectedTabTop = payload;
      state.isExpandedSlideList = false;
    },
    setSelectedTabBottom: (state, { payload }) => {
      state.selectedTabBottom = payload;
      state.isExpandedSlideList = false;
    },
    setExpanded: (state, { payload }) => {
      if (payload.isTop) {
        state.isExpandedTop = payload.isExpanded;
        state.isExpandedBottom = false;
      }

      if (!payload.isTop) {
        state.isExpandedBottom = payload.isExpanded;
        state.isExpandedTop = false;
      }
    },
    setGroup: (state, { payload }) => {
      state.group = payload;
    },
    clearSlidesList: () => initialValues,
    removeSlideAttributeFromList: (state, { payload: { uuid, attribute } }) => {
      state.selectedRows = state.selectedRows.map((slide) => {
        if (slide.uuid === uuid) {
          const updatedSlide = { ...slide };
          delete updatedSlide[attribute];

          return updatedSlide;
        }

        return slide;
      });
    },
    setForceOpenModel: (state, { payload }) => {
      state.forceOpenModel = payload;
    },
  },
});

export const {
  setSelectedRows,
  updateSelectedRow,
  setIsSharedPage,
  setProjectUUID,
  setSlidesListOrder,
  setIsSlidesListLoading,
  setOpenSlideInFullscreen,
  clearSlidesList,
  setExpanded,
  setSelectedTabBottom,
  setSelectedTabTop,
  setGroup,
  removeSlideAttributeFromList,
  setForceOpenModel,
} = slidesListSlice.actions;

export const slidesListReducer = slidesListSlice.reducer;

export const selectedRowsSelector = (state) => state.slidesList.selectedRows;

export const isSharedPageSelector = (state) => state.slidesList.isSharedPage;

export const projectUUIDSelector = (state) => state.slidesList.projectUUID;

export const groupSelector = (state) => state.slidesList.group;

export const slidesListOrderSelector = (state) => state.slidesList.order;

export const openSlideInFullscreenSelector = (state) =>
  state.slidesList.openSlideInFullscreen;

export const isSlidesListLoadingSelector = (state) =>
  state.slidesList.isSlidesListLoading;

export const selectedTabTopSelector = (state) =>
  state.slidesList.selectedTabTop;

export const selectedTabBottomSelector = (state) =>
  state.slidesList.selectedTabBottom;

export const isExpandedTopSelector = (state) => state.slidesList.isExpandedTop;

export const isExpandedBottomSelector = (state) =>
  state.slidesList.isExpandedBottom;

export const forceOpenModelSelector = (state) =>
  state.slidesList.forceOpenModel;
