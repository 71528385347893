import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import ReorderIcon from '@mui/icons-material/Reorder';
import React from 'react';
import { MUI_LAPTOP_BREAKPOINT_VALUE } from 'themes/defaultMuiTheme';
import { useViewsSelectionButtonsStyles } from 'components/SlideGroups/utilities/styles';
import { useWindowSize } from 'components/IFViewer/utils';
import { ViewTypes } from 'components/SlideGroups/utilities/constants';

export const ViewsSelectionButtons = ({
  externalClasses = {},
  view,
  setView,
}) => {
  const { width } = useWindowSize();
  const isLargeScreen = width > MUI_LAPTOP_BREAKPOINT_VALUE;
  const internalClasses = useViewsSelectionButtonsStyles().classes;
  const classes = { ...internalClasses, ...externalClasses };

  return (
    <Box
      className={`${classes.row} ${
        isLargeScreen
          ? classes.largeViewButtonsSection
          : classes.smallViewButtonsSection
      }`}
    >
      <Button
        fullWidth={true}
        variant="contained"
        color="inherit"
        className={`${classes.changeViewButton} ${
          view === ViewTypes.CARD_VIEW && classes.selectedButton
        }`}
        onClick={() => setView(ViewTypes.CARD_VIEW)}
      >
        <AppsIcon
          className={isLargeScreen ? classes.changeViewButtonIcon : ''}
        />
        {isLargeScreen && 'Card View'}
      </Button>
      <Button
        fullWidth={true}
        variant="contained"
        color="inherit"
        className={`${classes.changeViewButton} ${
          view === ViewTypes.LIST_VIEW && classes.selectedButton
        }`}
        onClick={() => setView(ViewTypes.LIST_VIEW)}
      >
        <ReorderIcon
          className={isLargeScreen ? classes.changeViewButtonIcon : ''}
        />
        {isLargeScreen && 'List View'}
        <Box className={classes.betaLabel}>beta</Box>
      </Button>
    </Box>
  );
};
