import React, { useRef, useState } from 'react';
import {
  Button,
  AccordionSummary,
  Typography,
  Accordion,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  TableRow,
  TableHead,
  Table,
  TableBody,
  TableCell,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useExpansionStyles } from 'components/OrderDetails/styles';
import {
  handleBatchUploadAnnotationsAttachment,
  isMatchingFile,
} from 'components/ImageViewer/SlideViewerHeader/utils';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import TableContainer from '@mui/material/TableContainer';
import Checkbox from '@mui/material/Checkbox';
import { slidesSelector } from 'store/slices/slidesSlice';
import {
  GEOJSON_FILE_EXTENSION,
  HALO_ANNOTATIONS_FILE_EXTENSION,
  HIDE_ANNOTATIONS_LIST_BUTTON_LABEL,
  IMAGE_SCOPE_ANNOTATIONS_FILE_EXTENSION,
  NO_MATCHING_SLIDES_MESSAGE,
  SHOW_ANNOTATIONS_LIST_BUTTON_LABEL,
} from 'components/ImageViewer/constants';
import { getDateFormattedWithTime } from 'utilities/dates_and_times';
import { findMatchedSlides } from 'components/OrderDetails/Tabs/utilities';

export const UploadHaloAnnotationsSection = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [matchedSlides, setMatchedSlides] = useState([]);
  const dispatch = useDispatch();
  const { showError, showSuccess, showInfo } = useSnackbar();
  const { classes } = useExpansionStyles();
  const slides = useSelector(slidesSelector);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).filter(
      (file) =>
        file.name.endsWith(GEOJSON_FILE_EXTENSION) ||
        file.name.endsWith(HALO_ANNOTATIONS_FILE_EXTENSION),
    );
    setSelectedFiles(files);
  };

  const fileInputRef = useRef(null);

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleUploadClick = () => {
    const matchedFiles = selectedFiles.filter((file) => {
      return slides.some((slide) => isMatchingFile(slide.name, file.name));
    });
    setFilesToUpload(matchedFiles);
    if (!matchedFiles.length) {
      showInfo(NO_MATCHING_SLIDES_MESSAGE);
      return;
    }
    const matchedSlidesList = findMatchedSlides(matchedFiles, slides);
    setMatchedSlides(matchedSlidesList);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    const files = filesToUpload.map((file) => {
      return {
        data: file,
        slideUUID: matchedSlides.find((slide) =>
          isMatchingFile(slide.name, file.name),
        ).uuid,
      };
    });
    await handleBatchUploadAnnotationsAttachment(
      files,
      dispatch,
      showError,
      showSuccess,
    );

    setSelectedFiles([]);
    resetFileInput();
    handleClose();
    setIsLoading(false);
  };

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary
        className={classes.alternativeHeader}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant={'body1'} color={'inherit'}>
          External Annotations
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.noPaddingAccordion}>
        <input
          ref={fileInputRef}
          type="file"
          multiple
          accept={[
            GEOJSON_FILE_EXTENSION,
            HALO_ANNOTATIONS_FILE_EXTENSION,
            IMAGE_SCOPE_ANNOTATIONS_FILE_EXTENSION,
          ]}
          onChange={handleFileChange}
          className={classes.defaultMargin}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleUploadClick}
          disabled={!selectedFiles.length}
          size="small"
          className={classes.defaultMargin}
        >
          Upload Annotations
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsTableVisible(!isTableVisible)}
          size="small"
          className={classes.defaultMargin}
        >
          {isTableVisible
            ? HIDE_ANNOTATIONS_LIST_BUTTON_LABEL
            : SHOW_ANNOTATIONS_LIST_BUTTON_LABEL}
        </Button>
        {isTableVisible && (
          <TableContainer
            component={Paper}
            className={classes.annotationTableContainer}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Slide Name</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell align="right">Annotations Uploaded</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slides.map((slide) => (
                  <TableRow key={slide.uuid}>
                    <TableCell component="th" scope="row">
                      {slide.name}
                    </TableCell>
                    <TableCell scope="row">
                      {getDateFormattedWithTime(slide.created)}
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        checked={!!slide.halo_annotation_url}
                        disabled
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Upload Annotations</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The following files will be uploaded:
            </DialogContentText>
            <List>
              {filesToUpload.map((file) => (
                <ListItem key={file.name}>
                  <ListItemText primary={file.name} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpload} color="primary" disabled={isLoading}>
              {isLoading ? <Spinner size={20} /> : 'Upload'}
            </Button>
          </DialogActions>
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};
