import { makeStyles } from 'tss-react/mui';

export const useInfoPageComponentsStyles = makeStyles()(() => ({
  pathologyMapImage: {
    maxHeight: '300px',
    overflow: 'hidden',
    marginBottom: '1rem',
  },
  centerInfoImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  clickHereLink: {
    display: 'inline-block',
    marginTop: '1rem',
    marginBottom: '0',
  },
}));
