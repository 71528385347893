import { baseCreateApi } from 'store/apis/baseCreateApi';
import { ORDERS_PO_UPLOAD_ATTACHMENT } from 'services/resources/apiConstants';

export const attachmentsApi = baseCreateApi.injectEndpoints({
  endpoints: (builder) => ({
    updatePOAttachment: builder.mutation({
      query: (formData) => {
        const orderUUID = formData.get('order_uuid');

        return {
          url: ORDERS_PO_UPLOAD_ATTACHMENT.replace(':orderUUID', orderUUID),
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['getOrderByUUID'],
    }),
  }),
});

export const { useUpdatePOAttachmentMutation } = attachmentsApi;
