import { backendAPI } from 'services/backendAPI';
import {
  SLIDE_URL_RESOURCE_NAME,
  GCP_RESOURCE_NAME,
  GCP_FILES_PROXY_PREFIX,
} from 'services/resources/apiConstants';

//TODO!!!
export const downloadMultiSlideSource = ({ slides }) => {
  slides.forEach((slide) => {
    backendAPI.get(slide.download_url).then((response) => {
      window.open(response.data.url); //TODO
    });
  });
};

//TODO!!!
export const downloadMultiIFSlideSourceByQPTIFF = ({ slides }) => {
  slides.forEach((slide) => {
    backendAPI.get(slide.download_url).then((response) => {
      const qptiffURL = response.data['qptiff_url'];
      window.open(qptiffURL);
    });
  });
};

//TODO!!!
export const downloadMultiIFSlideSourceByOMETIFF = ({ slides }) => {
  slides.forEach((slide) => {
    backendAPI.get(slide.download_url).then((response) => {
      const qptiffURL = response.data['ome_tiff_url'];
      window.open(qptiffURL);
    });
  });
};

export const updateSlide = (slideUUID, data) => {
  const url = SLIDE_URL_RESOURCE_NAME.replace(':slideUUID', slideUUID);

  return backendAPI.post(url, data);
};

export const getGCPData = (GCPUrl) => {
  const url = GCPUrl.replace(GCP_RESOURCE_NAME, GCP_FILES_PROXY_PREFIX);

  return fetch(url).then((response) => {
    const contentType = response.headers.get('Content-Type');
    if (contentType === 'application/geo+json') {
      return response.json();
    } else if (
      contentType === 'application/octet-stream' ||
      contentType === 'application/xml'
    ) {
      return response.text();
    }
  });
};
