import {
  FulfilledCellCheckbox,
  getPairedColumnDefs,
  sampleHyperlinkRenderer,
} from './utilities';

export const defaultColDef = {
  flex: 1,
  editable: false,
  sortable: true,
};

export const components = {
  sampleHyperlinkRenderer: sampleHyperlinkRenderer,
  fulfilledCellCheckbox: FulfilledCellCheckbox,
};

export const pairedColumns = [
  [
    'H&E',
    'requested_h_and_e_stain_count',
    'uploaded_he_count',
    'missing_he_count',
  ],
  [
    'Special Stains',
    'requested_special_stains_count',
    'uploaded_special_stains_count',
    'missing_special_stains_count',
    'special_stains_extra',
  ],
  [
    'IHC',
    'requested_antibodies_count',
    'uploaded_antibodies_count',
    'missing_antibodies_count',
    'ihc_extra',
  ],
  ['IF', 'requested_if_count', 'uploaded_if_count', 'missing_if_count'],
];

const groupCellRendererParams = {
  innerRenderer: 'sampleHyperlinkRenderer',
};

export const columnDefs = [
  {
    headerName: 'Sample Name',
    field: 'name',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: groupCellRendererParams,
  },
  ...getPairedColumnDefs(pairedColumns),
  {
    headerName: 'Unstained Requested',
    // we do this to make stain types be on the same table header level
    children: [
      {
        headerName: '',
        field: 'requested_unstained_count',
      },
    ],
  },
  {
    headerName: 'Scan Only',
    children: [
      {
        headerName: '',
        field: 'requested_slide_scanning_only_count',
      },
    ],
  },
  {
    headerName: 'IF Scan Only',
    children: [
      {
        headerName: '',
        field: 'requested_if_slide_scanning_only_count',
      },
    ],
  },
  {
    headerName: 'Total',
    children: [
      {
        headerName: 'Fulfilled',
        field: 'is_fulfilled',
        cellRenderer: 'fulfilledCellCheckbox',
        sort: 'asc',
      },
      {
        headerName: 'Uploaded',
        field: 'uploaded_slides_count',
        columnGroupShow: 'open',
      },
      {
        headerName: 'Requested',
        field: 'total_slides_requested',
        columnGroupShow: 'open',
      },
      {
        headerName: 'Missing',
        field: 'missing_total_slides',
        columnGroupShow: 'open',
      },
    ],
  },
];

export const detailColumnDefs = [
  {
    headerName: 'Name',
    field: 'name',
  },
  {
    headerName: 'Type',
    field: 'type',
  },
  {
    headerName: 'Requested',
    field: 'requested',
  },
  {
    headerName: 'Uploaded',
    field: 'uploaded',
  },
  {
    headerName: 'Missing',
    field: 'missing',
  },
  {
    headerName: 'Fulfilled',
    field: 'missing',
    valueGetter: ({ data, colDef }) => !data[colDef.field],
    cellRenderer: FulfilledCellCheckbox,
    sort: 'asc',
  },
];
