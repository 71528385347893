import { SUPPORT_MAIL } from 'components/UserProfile/constants';

export const generateMailtoLink = (team) => {
  const { name, uuid } = team;
  const to = 'getsupport@histowiz.com';
  const cc = encodeURIComponent(SUPPORT_MAIL);
  const subject = encodeURIComponent(
    `${name}, ${uuid} would like to create a PO`,
  );
  const body = encodeURIComponent(
    `Hello,\n\nI would like to create a Blanket PO for ${name} ${uuid} to use for future transactions.`,
  );

  return `mailto:${to}?cc=${cc}&subject=${subject}&body=${body}`;
};
