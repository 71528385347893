import { useEffect, useMemo, useState } from 'react';
import {
  getTeamMembers,
  getTeamNewsfeed,
} from 'services/resources/commonResources';
import {
  useCreateOrderMutation,
  useGenerateUploadLinkMutation,
  useGetOrdersHistoryQuery,
  useGetOrdersQuery,
} from 'store/apis/orderApi';
import {
  sorterForChangelog,
  splitOrders,
} from 'components/OrdersList/utilities/helpers';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import {
  FAIL_GENERATE_UPLOAD_LINK,
  FETCH_ORDER_HISTORY_ERROR_MESSAGE,
  FETCH_ORDERS_ERROR_MESSAGE,
} from 'constants/errorMessages';
import {
  filterOrdersByTeam,
  getOrderParamsForUpload,
  getSelectedHistoryTabs,
  getSelectedOrders,
  ordersSorter,
} from 'components/UserProfile/utilities';
import { sortArray } from 'utilities/general';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { UNABLE_CREATE_ORDER_MESSAGE } from 'components/OrderForm/Info/constants';

export const useTeamNewsFeed = (selectedTeam) => {
  const [newsfeeds, setNewsfeeds] = useState(new Map());

  useEffect(() => {
    if (!selectedTeam || !!newsfeeds.get(selectedTeam)) return;

    getTeamNewsfeed(selectedTeam.uuid).then((newsfeed) => {
      setNewsfeeds((prevNewsfeeds) => {
        prevNewsfeeds.set(selectedTeam, newsfeed);

        return new Map(prevNewsfeeds);
      });
    });
  }, [selectedTeam]);

  return {
    newsfeeds,
  };
};

export const useGenerateUploadLink = (selectedOrder, setIsLoading) => {
  const [uploadLink, setUploadLink] = useState(
    selectedOrder?.files_com_share_url,
  );
  const [generateLink] = useGenerateUploadLinkMutation();
  const [createOrderMutation] = useCreateOrderMutation();

  const { showError } = useSnackbar();

  const generateUploadLink = (selectedOrder) => {
    setIsLoading(true);
    generateLink({ orderUUID: selectedOrder.uuid })
      .unwrap()
      .then(({ files_com_share_url }) => {
        setUploadLink(files_com_share_url);
      })
      .catch(() => {
        showError(FAIL_GENERATE_UPLOAD_LINK);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePrepareUpload = (
    selectedOrder,
    selectedProject,
    selectedTeam,
    setSelectedOrder,
  ) => {
    if (selectedProject.uuid && selectedOrder) {
      generateUploadLink(selectedOrder);
      return;
    }
    setIsLoading(true);
    const postParams = getOrderParamsForUpload(selectedTeam.uuid);
    if (selectedProject.uuid) {
      postParams.project_uuid = selectedProject.uuid;
      delete postParams.team_uuid;
    } else {
      postParams.create_project_name = selectedProject.name;
    }
    createOrderMutation(postParams)
      .unwrap()
      .then((createdOrder) => {
        setSelectedOrder(createdOrder);
        generateUploadLink(createdOrder);
      })
      .catch(() => {
        showError(UNABLE_CREATE_ORDER_MESSAGE);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    uploadLink,
    handlePrepareUpload,
  };
};

export const useTeamMembers = (team) => {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!team) return;

    getTeamMembers(team.uuid)
      .then((res) => {
        setMembers(res);
      })
      .catch()
      .finally(() => setIsLoading(false));
  }, [team]);

  return {
    members,
    isLoading,
  };
};

export const usePrepareOrderForSelectedTab = (team, tab, sort) => {
  const {
    data: rawOrders,
    isLoading: isOrdersLoading,
    error,
  } = useGetOrdersQuery();
  useQueryError(error, FETCH_ORDERS_ERROR_MESSAGE);

  const filteredOrders = filterOrdersByTeam(rawOrders, [team]);

  const splittedOrders = useMemo(
    () => splitOrders(filteredOrders),
    [filteredOrders],
  );

  const selectedTabOrders = useMemo(
    () => getSelectedOrders(tab, splittedOrders, filteredOrders),
    [tab, splittedOrders],
  );

  const selectedTabSortedOrders = useMemo(
    () => sortArray(selectedTabOrders, ordersSorter, sort),
    [sort, selectedTabOrders],
  );

  return {
    selectedTabSortedOrders,
    isOrdersLoading,
  };
};

export const usePrepareChangeLog = (tab, orders) => {
  const {
    data: ordersHistory,
    error: getHistoryError,
    isLoading: isOrdersLoading,
  } = useGetOrdersHistoryQuery();

  useQueryError(getHistoryError, FETCH_ORDER_HISTORY_ERROR_MESSAGE);

  const filterEvents = (events) => {
    if (!events) return { archivedEvents: [], contributedEvents: [] };
    let archivedEvents = [];
    let contributedEvents = [];
    let previousStatus = {};

    events.forEach((event) => {
      const { uuid } = event;
      if (!previousStatus[uuid]) {
        previousStatus[uuid] = {
          isArchived: false,
          isContributed: false,
        };
      }
      const relatedOrder = orders.find((o) => o.uuid === uuid);
      if (event.is_storage_archived && !previousStatus[uuid].isArchived) {
        archivedEvents.push({
          date: event.history_date,
          action: 'delete',
          orderedBy: `${event.history_user.first_name} ${event.history_user.last_name}`,
          used: relatedOrder?.slides_data_used,
          projectName: relatedOrder?.project?.name,
          created: event.created,
          slidesCount: relatedOrder?.slides_count,
          ifSlidesCount: relatedOrder?.if_slides_count,
          order: relatedOrder,
        });
      }
      if (event.pathology_map_consent && !previousStatus[uuid].isContributed) {
        contributedEvents.push({
          date: event.history_date,
          action: 'contribute',
          orderedBy: `${event.history_user.first_name} ${event.history_user.last_name}`,
          used: relatedOrder?.slides_data_used,
          projectName: relatedOrder?.project?.name,
          created: event.created,
          slidesCount: relatedOrder?.slides_count,
          ifSlidesCount: relatedOrder?.if_slides_count,
          order: relatedOrder,
        });
      }

      previousStatus[uuid].isArchived = event.is_storage_archived;
      previousStatus[uuid].isContributed = event.pathology_map_consent;
    });

    return {
      archivedEvents: archivedEvents.sort(sorterForChangelog),
      contributedEvents: contributedEvents.sort(sorterForChangelog),
    };
  };

  const currentOrdersHistory = ordersHistory?.filter((order) =>
    orders.find((o) => o.uuid === order.uuid),
  );
  const { archivedEvents, contributedEvents } =
    filterEvents(currentOrdersHistory);

  const exportedEvents = orders
    ?.filter((order) => !!order.exportedOrdersHistory?.length)
    .map((order) => {
      const exportedOrder = order.exportedOrdersHistory[0];

      return {
        date: exportedOrder.created,
        action: 'export',
        orderedBy: `${exportedOrder.requested_by.first_name} ${exportedOrder.requested_by.last_name}`,
        used: order.slides_data_used,
        projectName: order.project?.name,
        created: exportedOrder.created,
        slidesCount: order.slides_count,
        ifSlidesCount: order.if_slides_count,
        order,
      };
    })
    .sort(sorterForChangelog);

  const allEvents = [
    ...archivedEvents,
    ...contributedEvents,
    ...exportedEvents,
  ].sort(sorterForChangelog);

  const selectedTabOrders = useMemo(
    () =>
      getSelectedHistoryTabs(tab, {
        allEvents,
        archivedEvents,
        contributedEvents,
        exportedEvents,
      }),
    [tab, allEvents],
  );

  return {
    selectedTabOrders,
    isOrdersLoading,
  };
};
