import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import {
  EXTERNAL_COMMENT_INPUT_WARNING,
  INTERNAL_COMMENT_INPUT_WARNING,
} from 'components/PathologyMap/Modals/FullSlideInfoModal/constants';
import React, { useState } from 'react';

export const SlideCommentInput = ({
  classes,
  slide,
  handleCreateComment,
  isStaff,
}) => {
  const [newSlideCommentText, setNewSlideCommentText] = useState('');
  const [isExternalComment, setIsExternalComment] = useState(false);

  const handleChange = (event) => {
    setNewSlideCommentText(event.target.value);
  };

  const staffPlaceholder = isExternalComment
    ? EXTERNAL_COMMENT_INPUT_WARNING
    : INTERNAL_COMMENT_INPUT_WARNING;
  const isExternalStaff = isStaff && isExternalComment;
  const isInternalStaff = isStaff && !isExternalComment;

  const label = `${isStaff ? staffPlaceholder : ''}Leave a comment here...`;

  return (
    <>
      <TextField
        placeholder={label}
        fullWidth
        variant="outlined"
        multiline
        value={newSlideCommentText}
        onChange={handleChange}
        className={`${classes.newSlideComment} ${
          isExternalStaff ? classes.commentRedPlaceholder : ''
        }`}
        rows={3}
      />
      <Button
        className={classes.submit}
        variant="contained"
        color="primary"
        disabled={!newSlideCommentText}
        onClick={() => {
          setNewSlideCommentText('');
          handleCreateComment(
            newSlideCommentText,
            slide.uuid,
            !!isInternalStaff,
          );
        }}
      >
        Submit
      </Button>
      {isStaff && (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              value={isExternalComment}
              onChange={({ target }) => setIsExternalComment(target.checked)}
            />
          }
          label="External Comment"
        />
      )}
    </>
  );
};
