import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import { SlideCommentList } from 'components/SlideComments/SlideCommentsList';
import { AddSlideComment } from 'components/OrderSlidesList/AddSlideComment';
import { SlideNotes } from 'components/SlideNotes/SlideNotes';
import { SlideCommentHeader } from 'components/SlideComments/SlideCommentHeader';
import { useSelector } from 'react-redux';
import { isStaffSelector } from 'store/slices/userDetailsSlice';

export const SlideCommentsListAddDetails = ({
  slide,
  slideComments,
  handleAddComment,
  handleDeleteComment,
  setSlideComments,
}) => {
  const slideCommentsLength = slideComments?.length;
  const isStaff = useSelector(isStaffSelector);

  return (
    <Fragment>
      <Box className="slide-comments-content">
        {!!slide && <SlideNotes slide={slide} />}
        {!!slide && !isStaff && (
          <SlideCommentHeader slideCommentsLength={slideCommentsLength} />
        )}
        <AddSlideComment
          slide={slide}
          slideComments={slideComments}
          setSlideComments={setSlideComments}
          handleAddComment={handleAddComment}
          isStaff={isStaff}
        />
        <SlideCommentList
          comments={slideComments}
          handleDeleteComment={handleDeleteComment}
        />
      </Box>
    </Fragment>
  );
};
