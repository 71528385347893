import {
  BILLING_TYPE_CREDIT_CARD,
  BILLING_TYPE_BLANKET_PURCHASE_ORDER,
  LAST_ORDER_FORM_PAGE,
} from 'components/OrderForm/constants';

export const serializeBillingContact = ({ billingContact }) => {
  const isCreditCard = billingContact.billing_type === BILLING_TYPE_CREDIT_CARD;
  const isPurchaseOrder =
    billingContact.billing_type === BILLING_TYPE_BLANKET_PURCHASE_ORDER;

  let billingLabel;
  if (isCreditCard) {
    billingLabel = `${billingContact.name} - #${billingContact.credit_card.last4}`;
  } else if (isPurchaseOrder) {
    billingLabel = billingContact.name;
  } else {
    if (!billingContact.name.includes('PO')) {
      billingLabel = `PO ${billingContact.name} - ${billingContact.po_number}`;
    } else {
      billingLabel = `${billingContact.name} - ${billingContact.po_number}`;
    }
  }

  return {
    name: billingContact.name,
    email: billingContact.email,
    phone_number: billingContact.phone_number,
    po_number: billingContact.po_number,
    billing_type: billingContact.billing_type,
    credit_card: billingContact.credit_card,
    purchase_order: billingContact.purchase_order,
    label: billingLabel,
    value: billingContact.uuid,
    uuid: billingContact.uuid,
  };
};
export const serializeTeamAddress = ({ address }) => {
  const addressLabel = `${address.name} - ${address.street_1}`;

  return {
    name: address.name,
    label: addressLabel,
    value: address.uuid,
    uuid: address.uuid,
    street_1: address.street_1,
    street_2: address.street_2,
    zip_code: address.zip_code,
    city: address.city,
    country: address.country,
    phone_number: address.phone_number,
  };
};

export const buildCreateBillingContactParams = (values, teamUUID) => ({
  team_uuid: teamUUID,
  name: values.billingContactName,
  email: values.billingContactEmail,
  phone_number: values.billingContactPhoneNumber,
  po_number: values.billingContactPONumber,
});

export const buildUpdateOrderParams = (
  values,
  {
    shippingAddressSelected,
    shippingMethodSelected,
    selectedBlanketPurchaseOrder,
    billingContactSelected,
    dropboxSelected,
  },
) => {
  const defaultParams = {
    address_from_uuid: shippingAddressSelected.uuid,
    shipment_method: shippingMethodSelected.value,
    ship_back_stained_slides: values.ship_back_stained_slides.value,
    ship_back_unstained_slides:
      values.ship_back_unstained_slides?.value || false,
    ship_back_samples: values.ship_back_samples.value,
    turnaround_image_analysis_pathology_consultation_days:
      values.turnaround_image_analysis_pathology_consultation_days.value,
    client_fedex_account: values.client_fedex_account,
    last_order_form_page: LAST_ORDER_FORM_PAGE.BILLING,
  };

  const optionalParams = {
    ...(!!billingContactSelected && {
      billing_contact_uuid: billingContactSelected.uuid,
    }),
    ...(!!selectedBlanketPurchaseOrder && {
      purchase_order_uuid: selectedBlanketPurchaseOrder.uuid,
    }),
    ...(!!dropboxSelected && { dropbox_uuid: dropboxSelected.value }),
  };

  return {
    ...defaultParams,
    ...optionalParams,
  };
};
