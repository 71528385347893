import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { useModalWithHeaderStyles } from 'components/Modals/styles';

export const ModalWithHeader = ({
  children,
  header,
  showCloseIcon,
  onClose,
  ...props
}) => {
  const { classes } = useModalWithHeaderStyles();

  return (
    <DefaultModal styles={{ padding: 0 }} {...props} onClose={onClose}>
      <Typography className={classes.title}>
        {header}{' '}
        {showCloseIcon && (
          <CloseIcon onClick={onClose} className={classes.close} />
        )}
      </Typography>
      {children}
    </DefaultModal>
  );
};
