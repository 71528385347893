import {
  getIsImageAnalysisRequestedCount,
  getIsImageAnalysisRequestedHandECount,
  getIsPathologyConsultationRequestedCount,
  getIsPathologyConsultationRequestedHandECount,
  getRowsFromAntibodies,
  getRowsFromIAOnly,
  getRowsFromPCOnly,
  getRowsFromSamplePanels,
  getRowsFromScanOnly,
  getRowsFromSimpleSamples,
  getRowsFromSpecialStains,
  getRowsFromUnstained,
} from 'components/OrderForm/SlideServicesForm/apiHelper';
import { SampleSlidesServicesSchema } from 'components/OrderForm/validators';
import {
  BASE_COLUMNS_FOR_SLIDE_SAMPLE_COUNTS,
  IMAGE_ANALYSIS_COUNT_COLUMN,
  PATHOLOGY_CONSULT_COUNT_COLUMN,
} from 'components/OrderForm/SlideServicesForm/agGridColumnsConstants';
import {
  REQUESTED_IF_SLIDE_SCANNING_ONLY_COUNT,
  REQUESTED_SLIDE_SCANNING_ONLY_COUNT,
} from 'components/OrderForm/SlideServicesForm/constants';
import {
  IF_SCAN_ONLY_SERVICE,
  SCAN_ONLY_SERVICE,
} from 'components/OrderForm/constants';
import {
  useLazyGetSampleAntibodiesRequestedQuery,
  useLazyGetSamplePanelsRequestedQuery,
  useLazyGetSampleSpecialStainsRequestedQuery,
  useLazyGetSimpleSamplesInfoQuery,
} from 'store/apis/samplesApi';

export const serializeSlideServicesRowDataFromAPIResponse = (data) => {
  const { simpleSamples, antibodies, specialStains, panels } = data;

  const simpleSampleRows = getRowsFromSimpleSamples(simpleSamples);
  const scanOnlySampleRows = getRowsFromScanOnly(
    simpleSamples,
    REQUESTED_SLIDE_SCANNING_ONLY_COUNT,
    SCAN_ONLY_SERVICE,
  );
  const IFScanOnlySampleRows = getRowsFromScanOnly(
    simpleSamples,
    REQUESTED_IF_SLIDE_SCANNING_ONLY_COUNT,
    IF_SCAN_ONLY_SERVICE,
  );
  const unstainedRows = getRowsFromUnstained(simpleSamples);
  const antibodyRows = getRowsFromAntibodies(antibodies);
  const specialStainsRows = getRowsFromSpecialStains(specialStains);
  const panelsRows = getRowsFromSamplePanels(panels);

  const pathologyConsultationsOnlyRows = getRowsFromPCOnly(simpleSamples);
  const imageAnalysisOnlyRows = getRowsFromIAOnly(simpleSamples);

  return [
    ...simpleSampleRows,
    ...unstainedRows,
    ...antibodyRows,
    ...specialStainsRows,
    ...panelsRows,
    ...scanOnlySampleRows,
    ...IFScanOnlySampleRows,
    ...pathologyConsultationsOnlyRows,
    ...imageAnalysisOnlyRows,
  ];
};

export const useGetSlidesData = () => {
  const [getSimpleSamplesInfo] = useLazyGetSimpleSamplesInfoQuery();
  const [getSampleAntibodiesRequested] =
    useLazyGetSampleAntibodiesRequestedQuery();
  const [getSampleSpecialStainsRequested] =
    useLazyGetSampleSpecialStainsRequestedQuery();
  const [getSamplePanelsRequested] = useLazyGetSamplePanelsRequestedQuery();

  const getSlidesData = async (orderUUID) => {
    const [simpleSamples, antibodies, specialStains, panels] =
      await Promise.all([
        getSimpleSamplesInfo({ orderUUID }).unwrap(),
        getSampleAntibodiesRequested({ orderUUID }).unwrap(),
        getSampleSpecialStainsRequested({ orderUUID }).unwrap(),
        getSamplePanelsRequested({ orderUUID }).unwrap(),
      ]);

    return {
      simpleSamples,
      antibodies,
      specialStains,
      panels,
    };
  };

  return getSlidesData;
};

export const getColumns = (
  needsPathologyConsultationColumn,
  needsImageAnalysisColumn,
) => {
  return [
    ...BASE_COLUMNS_FOR_SLIDE_SAMPLE_COUNTS,
    ...(needsPathologyConsultationColumn
      ? [PATHOLOGY_CONSULT_COUNT_COLUMN]
      : []),
    ...(needsImageAnalysisColumn ? [IMAGE_ANALYSIS_COUNT_COLUMN] : []),
  ];
};
export const checkAllRowsValid = async (rows) => {
  const yupValidatedRows = rows.map((row) => {
    return SampleSlidesServicesSchema.validate(row)
      .then(() => {
        return row;
      })
      .catch(() => {
        return false;
      });
  });

  const validResults = await Promise.all(yupValidatedRows).then((response) => {
    return response.filter((e) => {
      return e ? e : false;
    });
  });

  return validResults;
};

export const checkIfPathologyConsultationRequestedColumnNeeded = (samples) => {
  const isPathologyConsultationRequestedCount =
    getIsPathologyConsultationRequestedCount(samples);
  const pathologyConsultationsOnlyRows = getRowsFromPCOnly(samples);

  const isPathologyConsultationColumnNeeded =
    pathologyConsultationsOnlyRows.length !==
    isPathologyConsultationRequestedCount;

  // sometimes a customer may have requested pathology consultation for a sample, but then later changed
  // their mind and removed the pathology consultation request, in that case, still show column
  const previouslyRequestedHEPathologyConsultationSamplesCount =
    getIsPathologyConsultationRequestedHandECount(samples);

  return (
    isPathologyConsultationColumnNeeded ||
    previouslyRequestedHEPathologyConsultationSamplesCount
  );
};

export const checkIfImageAnalysisRequestedColumnNeeded = (samples) => {
  const isImageAnalysisRequestedCount =
    getIsImageAnalysisRequestedCount(samples);
  const imageAnalysisOnlyRows = getRowsFromIAOnly(samples);
  const isImageAnalysisColumnNeeded =
    imageAnalysisOnlyRows.length !== isImageAnalysisRequestedCount;

  const previouslyRequestedImageAnalysisHandESamplesCount =
    getIsImageAnalysisRequestedHandECount(samples);

  return (
    isImageAnalysisColumnNeeded ||
    previouslyRequestedImageAnalysisHandESamplesCount
  );
};
