import { Typography, Box } from '@mui/material';
import React from 'react';
import { useManageDataCardOrderPreviewHeaderStyles } from 'components/TeamOverview/components/ManageDataCard/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export const ManageDataCardOrderPreviewHeader = ({ handleBack, order }) => {
  const { classes } = useManageDataCardOrderPreviewHeaderStyles();

  if (!order) return;

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box onClick={handleBack} className={classes.backContainer}>
        <ChevronLeftIcon fontSize={'large'} />
        <Typography className={classes.backLabel}>BACK</Typography>
      </Box>
      <Typography className={classes.cardTitle}>MANAGE DATA /</Typography>
      <Typography className={classes.orderName}>{order.name}</Typography>
    </Box>
  );
};
