import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { CustomTableCell } from 'components/OrdersList/constants';
import moment from 'moment/moment';
import { DATE_REQUEST_FORMAT } from 'utilities/dates_and_times';
import React from 'react';
import { withStyles } from 'tss-react/mui';

export const HeaderCell = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#E6E6E6',
    padding: '10px',
    border: 'none',
    '&:first-child': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
}));

const InvitedTableRow = ({ row }) => (
  <TableRow>
    <HeaderCell component="th" scope="row">
      {row.email}
    </HeaderCell>
    <CustomTableCell component="th" scope="row">
      {row.team.name}
    </CustomTableCell>
    <CustomTableCell component="th" scope="row">
      {moment(row.created).format(DATE_REQUEST_FORMAT)}
    </CustomTableCell>
    <CustomTableCell component="th" scope="row">
      Sent
    </CustomTableCell>
  </TableRow>
);

export const InvitedMembersTable = ({ invitedUsers }) => (
  <Table>
    <TableHead>
      <TableRow>
        <HeaderCell>Email</HeaderCell>
        <HeaderCell>Team</HeaderCell>
        <HeaderCell>Sent On</HeaderCell>
        <HeaderCell>Status</HeaderCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {invitedUsers.map((row) => {
        return <InvitedTableRow row={row} key={row.uuid} />;
      })}
    </TableBody>
  </Table>
);
