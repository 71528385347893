import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { searchResultsStyles } from 'components/GlobalSearch/styles';
import { truncateName } from 'components/GlobalSearch/utilities';
import { makeStyles } from 'tss-react/mui';

const useSearchResultsStyles = makeStyles()(searchResultsStyles);

export const SearchResults = ({ data, handleGoToItem }) => {
  const { classes } = useSearchResultsStyles();
  const history = useHistory();

  if (!data.length)
    return (
      <Box className={classes.noResults}>
        <Typography>No results</Typography>
      </Box>
    );

  return (
    <Box className={classes.container}>
      <Box component="ul" className={classes.list}>
        {data.map(
          ({ name, uuid, children, orderId, groupName, section, color }) => {
            const itemName = section ? (
              <>
                <Typography
                  component="span"
                  sx={{
                    color: section.parent.color,
                  }}
                >
                  {section.parent.name}
                </Typography>{' '}
                →{' '}
                <Typography
                  component="span"
                  sx={{
                    color: section.child.color,
                  }}
                  onClick={() => history.push(section.child.url)}
                >
                  {section.child.name}
                </Typography>
              </>
            ) : (
              <Typography
                component="span"
                id={orderId || uuid}
                sx={{
                  color,
                }}
                data-group={groupName}
                onClick={handleGoToItem}
              >
                {truncateName(name)}
              </Typography>
            );

            return name || section ? (
              <Box
                component="li"
                className={classes.listItem}
                key={uuid || name}
              >
                {itemName}
                {children && (
                  <SearchResults
                    data={children}
                    handleGoToItem={handleGoToItem}
                  />
                )}
              </Box>
            ) : null;
          },
        )}
      </Box>
    </Box>
  );
};
