import { BlanketPurchaseOrderCard } from 'components/OrderForm/BillingShipping/BillingTabs/BlanketPurchaseOrders/PlunketPurchaseOrderCard';
import { Box, Button, Typography } from '@mui/material';
import { useBlanketPurchaseOrdersStyles } from 'components/OrderForm/BillingShipping/BillingTabs/BlanketPurchaseOrders/styles';
import { useSelector } from 'react-redux';
import { billingShippingSelectors } from 'store/slices/billingShippingSlice/billingShippingSlice';
import { generateMailtoLink } from 'components/OrderForm/BillingShipping/BillingTabs/BlanketPurchaseOrders/utilities';

export const BlanketPurchaseOrders = ({ onChange }) => {
  const { classes } = useBlanketPurchaseOrdersStyles();

  const { order: fullOrder } = useSelector(billingShippingSelectors.viewPage);

  const selectedPO = useSelector(
    billingShippingSelectors.selectedBlanketPurchase,
  );

  const blanketPurchaseOrders = useSelector(
    billingShippingSelectors.blanketPurchaseOrders,
  );

  return (
    <>
      {blanketPurchaseOrders.length ? (
        <Box className={classes.container}>
          {blanketPurchaseOrders.map((blanketPO) => (
            <BlanketPurchaseOrderCard
              key={blanketPO.uuid}
              handleSelect={onChange}
              order={blanketPO}
              isSelected={
                blanketPO.uuid === selectedPO?.uuid ||
                blanketPO.uuid === selectedPO?.purchase_order?.uuid
              }
            />
          ))}
        </Box>
      ) : (
        <Box className={classes.noDataContainer}>
          <Typography className={classes.noExistingPOLabel}>
            No existing PO for this account
          </Typography>
          {fullOrder?.team ? (
            <Button
              color="primary"
              variant="contained"
              href={generateMailtoLink(fullOrder?.team)}
              className={classes.contactBillingTeamButton}
            >
              Contact Billing Team
            </Button>
          ) : null}
        </Box>
      )}
    </>
  );
};
