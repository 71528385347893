import { Box, Typography } from '@mui/material';
import { DefaultModal } from 'components/Modals/DefaultModal';
import {
  ConfirmActionModalStyles,
  useConfirmDeleteModalStyles,
} from 'components/TeamOverview/components/ConfirmActionModal/styles';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import React, { useEffect, useMemo, useState } from 'react';
import { OrderInfoStep } from 'components/TeamOverview/components/ConfirmActionModal/OrderInfoStep';
import { ReviewActionStep } from 'components/TeamOverview/components/ConfirmActionModal/ReviewActionStep';
import { useOrdersActions, useSteps } from 'components/TeamOverview/hooks';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import {
  CONFIRM_ACTION_STEPPER,
  LOADING_STATE,
  SubmitActionTypes,
} from 'components/TeamOverview/constants';
import { flatOrderSlides } from 'components/TeamOverview/utilities';
import { TermsAndConditionsReviewModal } from 'components/TeamOverview/components/TermsAndConditionsReviewModal/TermsAndConditionsReviewModal';
import { ProgressStepper } from 'components/OrderDetails/Tabs/OrderProgressTab/ProgressStepper';

const REVIEW_STEP = 'review';
const DEFAULT_STEP_IDX = 1;

export const ConfirmActionModal = ({
  isOpen,
  onClose,
  selectedRowsSlides,
  selectedRows,
  setSelectedRows,
  actionType,
  team,
}) => {
  const { classes } = useConfirmDeleteModalStyles();
  const [
    isTermsAndConditionsReviewModalOpen,
    setIsTermsAndConditionsReviewModalOpen,
  ] = useState(false);
  const { showSuccess, showError } = useSnackbar();
  const { contribute, archive } = useOrdersActions();
  const steps = useMemo(
    () => [...selectedRows, REVIEW_STEP],
    [selectedRows.length],
  );
  const [signedUserName, setSignedUserName] = useState('');

  const {
    handleNextStep,
    handlePrevStep,
    resetStep,
    currentStep,
    currentStepIDX,
  } = useSteps(steps);

  const ACTION_TYPES = {
    [SubmitActionTypes.CONTRIBUTE_ACTION]: contribute,
    [SubmitActionTypes.ARCHIVE_ACTION]: archive,
  };

  const currentStepSlides = selectedRowsSlides?.get(currentStep) || {};

  const isFirstStep = currentStep === steps[0];
  const isReviewStep = currentStep === REVIEW_STEP;

  useEffect(() => {
    if (selectedRows?.length) {
      resetStep();
    }
  }, [selectedRows.length]);

  const handleClose = () => {
    resetStep();
    setSignedUserName('');
    onClose();
  };

  const handleSignTermsAndConditions = (userName) => {
    setSignedUserName(userName);
    setIsTermsAndConditionsReviewModalOpen(false);
  };

  const handleSubmit = () => {
    const ordersAction = ACTION_TYPES[actionType];
    ordersAction(selectedRows, team, signedUserName)
      .then(() => {
        setSignedUserName('');
        setSelectedRows([]);
        handleClose();
        showSuccess(
          `Orders successfully ${
            actionType === SubmitActionTypes.CONTRIBUTE_ACTION
              ? 'contributed'
              : 'deleted'
          }`,
        );
      })
      .catch(() => showError(`Failed to ${actionType.toLowerCase()} orders`));
  };

  const isTermsAndConditionsSigned =
    actionType === SubmitActionTypes.ARCHIVE_ACTION || !!signedUserName;

  const stepperSettings = {
    ...CONFIRM_ACTION_STEPPER,
    activeStepIDX: currentStep === REVIEW_STEP ? 1000 : DEFAULT_STEP_IDX,
  };

  return (
    <DefaultModal
      open={isOpen}
      onClose={handleClose}
      styles={ConfirmActionModalStyles}
      closeIcon
    >
      <Card
        cardClass={classes.cardContainer}
        header={
          <Box padding="1.375rem">
            <Typography className={classes.cardTitle}>
              CONFIRM {actionType}
            </Typography>
          </Box>
        }
      >
        <Box paddingX="1.75rem" paddingBottom="2rem" paddingTop="1rem">
          <Box className={classes.stepperWrapper}>
            <ProgressStepper isLoading={false} stepper={stepperSettings} />
          </Box>
          {isReviewStep ? (
            <ReviewActionStep
              isSigned={isTermsAndConditionsSigned}
              actionType={actionType}
              selectedRows={selectedRows}
              selectedRowsSlides={selectedRowsSlides}
              classes={classes}
              handleOpenTermsAndConditionsModal={() =>
                setIsTermsAndConditionsReviewModalOpen(true)
              }
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              handlePrevStep={handlePrevStep}
            />
          ) : (
            <OrderInfoStep
              order={currentStep}
              actionType={actionType}
              currentStepIDX={currentStepIDX}
              ordersCount={selectedRows.length}
              isPrevStepExists={!isFirstStep}
              isStepSlidesLoading={currentStepSlides === LOADING_STATE}
              stepSlides={
                currentStepSlides ? flatOrderSlides(currentStepSlides) : []
              }
              handlePrevStep={handlePrevStep}
              handleNextStep={handleNextStep}
            />
          )}
        </Box>
      </Card>
      <TermsAndConditionsReviewModal
        isOpen={isTermsAndConditionsReviewModalOpen}
        handleClose={() => setIsTermsAndConditionsReviewModalOpen(false)}
        handleSubmit={handleSignTermsAndConditions}
      />
    </DefaultModal>
  );
};
