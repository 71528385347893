import { useState } from 'react';
import { Button, DialogTitle, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DefaultModal } from 'components/Modals/DefaultModal';
import { useColumnModalStyles } from 'components/SampleEditor/styles';
import { useSetTeamPreferencesMutation } from 'store/apis/teamsApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const ColumnModal = ({
  handleClose,
  columnForDelete,
  gridApi,
  setColumns,
  buildColumn,
}) => {
  const [columnName, setColumnName] = useState(columnForDelete ?? '');

  const { showSuccess, showError } = useSnackbar();
  const { classes } = useColumnModalStyles();
  const [mutation, { isLoading }] = useSetTeamPreferencesMutation();

  const handler = () => {
    const column = columnName.replaceAll(' ', '_').toLowerCase();
    const columns = gridApi.getColumnDefs();
    const newColumns = [
      ...(columnForDelete
        ? columns.filter(
            ({ field }) => field !== `science_info.${columnForDelete}`,
          )
        : columns),
      ...(!columnForDelete && buildColumn
        ? [buildColumn(column, gridApi, setColumns)]
        : []),
    ];

    const columnNames = newColumns.reduce((acc, { field }) => {
      if (field.includes('science_info.')) {
        const formattedField = field
          .replace('science_info.', '')
          .replaceAll(' ', '_')
          .toLowerCase();

        return [...acc, formattedField];
      }

      return acc;
    }, []);

    gridApi.setColumnDefs(newColumns);
    setColumns(newColumns);
    mutation({ body: { visible_samples_editor_columns: columnNames } })
      .unwrap()
      .then(() =>
        showSuccess(
          `Column ${columnForDelete ? 'deleted' : 'added'} successfully`,
        ),
      )
      .catch(() =>
        showError(`Failed to ${columnForDelete ? 'delete' : 'add'} column`),
      );
    handleClose();
  };

  return (
    <DefaultModal
      open
      onClose={handleClose}
      className={classes.modal}
      width="30%"
    >
      <DialogTitle>
        {columnForDelete ? 'Delete' : 'Add New'} Column
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!columnForDelete ? (
        <TextField
          fullWidth
          placeholder="Enter column name"
          onChange={({ target }) => setColumnName(target.value)}
          value={columnName}
        />
      ) : (
        <div className={classes.deleteText}>
          Are you sure you want to delete column{' '}
          <strong>{columnForDelete}</strong>?
        </div>
      )}
      <Button
        variant={'outlined'}
        className={classes.addButton}
        disabled={!columnName || isLoading}
        onClick={handler}
      >
        {columnForDelete ? 'Delete' : 'Add'}
      </Button>
    </DefaultModal>
  );
};
