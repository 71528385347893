import React, { Fragment } from 'react';
import _ from 'lodash';
import { useSlidesGrid } from 'components/OrderSlidesList/hooks/useSlidesGrid';
import { useSlidesListData } from 'components/OrderSlidesList/hooks/useSlidesListData';
import { MiniDrawerWithContextMemo } from 'components/Layout/drawer';
import {
  SLIDES_FOR_TEMP_GROUP,
  TEMP_GROUP_TITLE,
  THUMBNAIL_SWITCH_FIELD,
} from 'components/ImageViewer/constants';
import { RenderAdjustableGridOrderSlideList } from 'components/OrderSlidesList/OrderSlidesListViewAdjustableGrid';
import { currentUserPreferencesSelector } from 'store/slices/userDetailsSlice';
import { useSelector } from 'react-redux';
import { useSlidesListViewV2Styles } from 'components/OrderSlidesList/OrderSlidesListViewV2Styles';
import {
  getFromLS,
  getSlideViewerPageTitle,
} from 'components/OrderSlidesList/utilities';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { ResizeableThumbnail } from 'components/ThumbnailQRCode/ResizeableThumbnail';
import Container from '@mui/material/Container';
import { ShortcutsPrompt } from 'components/OrderSlidesList/components/ShortcutsPrompt';

export const OrderSlidesListView2 = () => {
  const preferences = useSelector(currentUserPreferencesSelector);
  const slidesForTempGroup = getFromLS(SLIDES_FOR_TEMP_GROUP);
  const isSlidesForTempGroupNotEmpty =
    slidesForTempGroup && !_.isEmpty(slidesForTempGroup);
  const {
    handlePreviousSlide,
    handleNextSlide,
    selectedRows,
    annotationsCount,
    setAnnotationsCount,
    slide,
    slidesViewerProps,
    slidesGroupProps,
    slidesTableProps,
    slideCommentsProps,
  } = useSlidesGrid();
  const { projectUUID, slidesGroupName, slideGroupUUID } = useSlidesListData({
    slidesForTempGroup,
    isSlidesForTempGroupNotEmpty,
  });
  const { classes } = useSlidesListViewV2Styles();
  const pageTitle = getSlideViewerPageTitle(slide);
  useTitle(pageTitle);

  const drawerOrderHeader = slide
    ? `Order ${slide.order?.id} Slides List`
    : 'Order Slides List';

  const drawerProjectHeader = slide
    ? `${slide.project?.name} Slides List`
    : 'Project Slides List';

  const drawerHeader = projectUUID ? drawerProjectHeader : drawerOrderHeader;
  const header = isSlidesForTempGroupNotEmpty
    ? TEMP_GROUP_TITLE
    : slidesGroupName
    ? 'Group: '
    : drawerHeader;

  const isMultipleSelected = selectedRows.length > 1;

  return (
    <Fragment>
      <MiniDrawerWithContextMemo
        header={header}
        headerItalic={slidesGroupName}
        withoutBackground
      >
        <div className={classes.root}>
          <Container maxWidth={false} disableGutters>
            <RenderAdjustableGridOrderSlideList
              showFavoriteIcon={selectedRows.length === 1}
              isSlidesGroupViewer={!!slideGroupUUID}
              annotationsCount={annotationsCount}
              slidesViewerProps={slidesViewerProps}
              slidesGroupProps={slidesGroupProps}
              slidesTableProps={slidesTableProps}
              slideCommentsProps={slideCommentsProps}
              setAnnotationsCount={setAnnotationsCount}
              currentSlide={slide}
              handleNextSlide={handleNextSlide}
              handlePreviousSlide={handlePreviousSlide}
              isMultipleSelected={isMultipleSelected}
            />
          </Container>
          <ShortcutsPrompt />
        </div>
      </MiniDrawerWithContextMemo>
      <ResizeableThumbnail
        thumbnail={slide?.large_macro_url}
        isVisible={preferences[THUMBNAIL_SWITCH_FIELD]}
      />
    </Fragment>
  );
};
