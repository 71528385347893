import React from 'react';
import { NewCard } from 'components/PathologyMap/Modals/FullSlideInfoModal/components/NewCard';
import { useSlideViewerSectionStyles } from 'components/PathologyMap/Modals/FullSlideInfoModal/styles';
import { SlideViewerSection } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideViewerSection/SlideViewerSection';
import { FullSlideModalBreadcrumb } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideViewerSection/FullSlideModalBreadcrumb';

export const SlideViewerCard = ({ slide }) => {
  const { classes } = useSlideViewerSectionStyles();

  return (
    <NewCard
      header={<FullSlideModalBreadcrumb slide={slide} classes={classes} />}
      content={<SlideViewerSection slide={slide} classes={classes} />}
    />
  );
};
