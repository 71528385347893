import React from 'react';
import { getLocalToken } from 'utilities/token';
import {
  IHCOptimizationSlideSelectionOpenModalName,
  OrderCommentsNotifyClientModalName,
} from 'components/Modals/constants';
import { withSnackbar } from 'notistack';

export const AppContext = React.createContext();

class _AppProvider extends React.Component {
  state = {
    isLoggedIn: !!getLocalToken(),
    selectedOrderFormVersion: 'v1',
    updateSelectedOrderFormVersion: (version) => {
      this.updateSelectedOrderFormVersion(version);
    },
    updateIsStaff: (isStaff) => {
      this.updateIsStaff(isStaff);
    },
    updateIsBetaFuturesEnabled: (isBetaFutures) => {
      this.updateIsBetaFuturesEnabled(isBetaFutures);
    },
    modalPathologistConsultationOpen: false,
    modalAddSlideToSlideGroupOpen: false,
    modalQCPassFailOpen: false,
    modalBulkResetQCOpen: false,
    modalBulkQCPassFailOpen: false,
    snackbarPathologyConsultationOpen: false,
    snackbarCreatedFedExLabel: false,
    snackbarCreatedCreditCard: false,
    [OrderCommentsNotifyClientModalName]: false,
    [IHCOptimizationSlideSelectionOpenModalName]: false,
    algoliaClient: null,
    minimizeDrawer: () => {
      this._minimizeDrawer();
    },
    updateUserDetails: () => {
      this.updateUserDetails();
    },
    handleContextModalChange: (name) => () => {
      this.setState({ [name]: !this.state[name] });
    },
    updateIsLoggedIn: () => {
      this.updateIsLoggedIn();
    },
    setAlgoliaClient: (client) => {
      this.setState({
        algoliaClient: client,
      });
    },
    isPathMapFilterEnabled: false,
    setIsPathMapFilterEnabled: (isPathMapFilterEnabled) => {
      this.setState({ isPathMapFilterEnabled });
    },
  };

  updateIsStaff = (isStaff) => {
    this.setState({ isStaff });
  };

  updateSelectedOrderFormVersion = (version) => {
    this.setState({ selectedOrderFormVersion: version });
  };

  updateIsBetaFuturesEnabled = (isBetaFeaturesEnabled) => {
    this.setState({ isBetaFeaturesEnabled });
  };

  _minimizeDrawer = () => {
    this.setState({ showDrawer: false });
  };

  updateIsLoggedIn = () => {
    this.setState({ isLoggedIn: !!getLocalToken() });
  };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const AppProvider = withSnackbar(_AppProvider);
