import { Grid, Tooltip, Typography, Box } from '@mui/material';
import {
  buildSubmittedUserLabel,
  checkIfFilesUploadLink,
  getIAChartLink,
  getIAReportLink,
  getPathologyLink,
} from 'components/UserProfile/utilities';
import React, { memo } from 'react';
import { TextWithLimitedWidth } from 'components/SlideGroups/components/TextWithLimitedWidth';
import { useHistory } from 'react-router';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { SECONDARY_BLUE_2 } from 'utilities/colors';

const compareProps = (prev, next) => {
  return prev.order === next.order && prev.isSelected === next.isSelected;
};

const OrderName = ({ classes, order }) => {
  const isUploadLinkExists = checkIfFilesUploadLink(order.files_com_share_url);
  const handleOpenInNewTab = () => {
    const url = order.files_com_share_url;
    window.open(url, url);
  };

  const name = (
    <Typography
      component="span"
      display="flex"
      gap="0.25rem"
      marginRight="0.25rem"
    >
      <Typography component="span" overflow="hidden" textOverflow="ellipsis">
        {order.project.name}
      </Typography>
      /<b>{order.name}</b>
    </Typography>
  );

  return (
    <Box display="flex" flexDirection="row" paddingRight={'0.5rem'}>
      <TextWithLimitedWidth
        value={name}
        containerClass={classes.commentText}
        tooltipPlacement={'bottom'}
      />
      {isUploadLinkExists && (
        <Box
          display="flex"
          alignItems="center"
          color={SECONDARY_BLUE_2}
          onClick={handleOpenInNewTab}
        >
          <UploadFileIcon />
        </Box>
      )}
    </Box>
  );
};

export const OrderInfo = memo(
  ({
    order,
    classes,
    onRowClick,
    onIFSlidesClick,
    onDefaultSlidesClick,
    onDoubleClick,
    isSelected,
    isDefaultTab,
  }) => {
    const submittedUserLabel = buildSubmittedUserLabel(
      order.submitted_user,
      order.created,
    );
    const history = useHistory();
    const selectedRowClass = isSelected ? classes.selectedRow : '';

    return (
      <Grid
        container
        onClick={onRowClick}
        onDoubleClick={onDoubleClick}
        className={`${classes.slideDataContainer} ${classes.slideInfoRow} ${selectedRowClass}`}
      >
        <Grid item xs={isDefaultTab ? 3 : 4}>
          <OrderName classes={classes} order={order} />
        </Grid>
        <Grid item xs={isDefaultTab ? 1 : 2}>
          <Tooltip title={order.state_label}>
            <Typography
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              textAlign="center"
            >
              {order.state_label}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={isDefaultTab ? 3 : 4}>
          <Typography textAlign="center">{submittedUserLabel}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          className={order.slides_count ? classes.cellWithLink : ''}
          onClick={() => (order.slides_count ? onDefaultSlidesClick() : null)}
        >
          <Typography textAlign="center">{order.slides_count}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          className={order.if_slides_count ? classes.cellWithLink : ''}
          onClick={() => (order.if_slides_count ? onIFSlidesClick() : null)}
        >
          <Typography textAlign="center">{order.if_slides_count}</Typography>
        </Grid>
        {isDefaultTab && (
          <>
            {' '}
            <Grid item xs={1}>
              <Typography textAlign="center">
                {getPathologyLink(order)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography textAlign="center">
                {getIAReportLink(order)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography textAlign="center">
                {getIAChartLink(order, history)}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  },
  compareProps,
);
