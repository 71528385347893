import { makeStyles } from 'tss-react/mui';
import { TABS_BLUE } from 'utilities/colors';

export const useSlideMetadataWithScienceInfoStyles = makeStyles()({
  tabLabel: {
    fontSize: '0.875rem',
  },
  itemsCount: {
    lineHeight: 1.5,
    fontSize: '0.75rem',
    marginLeft: '0.5rem',
    border: `1px solid ${TABS_BLUE}`,
    paddingInline: '0.375rem',
    borderRadius: '0.25rem',
  },
  noInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10rem',
  },
  tabs: {
    '.Mui-selected': {
      color: TABS_BLUE,
    },
    position: 'sticky',
    top: 0,
    marginBottom: '0.5rem',
    backgroundColor: 'white',
    alignItems: 'center',
  },
});
