import React, { useReducer, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { useChannelOptionsStyles } from 'components/IFViewer/styles';
import { ColorPalette } from 'components/IFViewer/components/Controller/components/ColorPalette';
import { Box, Switch, TextField, Typography } from '@mui/material';
import { ShowStaffUserOnly } from 'components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly';

export const ChannelOptions = React.memo(
  ({
    handleRemoveChannel,
    handleColorSelect,
    handleLockColor,
    disabled,
    isColorLocked,
    customChannelOptions,
    onChannelOptionChange,
    name,
    slideUUID,
    sliderData,
  }) => {
    const [open, toggle] = useReducer((v) => !v, false);
    const anchorRef = useRef(null);
    const { min, max, isLoading, step, slider, handleSliderChange } =
      sliderData;

    const handleInputChange = (index, e) => {
      const formattedValue = e.target.value.replace(/[^0-9.]/g, '');
      const value = formattedValue === '' ? '' : Number(e.target.value);
      const newSlider = [...slider];
      newSlider[index] = value;
      handleSliderChange(e, newSlider);
    };

    const handleInputBlur = (e) => {
      const newSlider = [...slider].sort((a, b) => a - b);

      if (newSlider[0] < min) {
        newSlider[0] = min;
      }

      if (newSlider[1] > max) {
        newSlider[1] = max;
      }

      handleSliderChange(e, newSlider);
    };

    const { classes } = useChannelOptionsStyles();
    return (
      <>
        <IconButton
          aria-label="Remove channel"
          size="small"
          onClick={toggle}
          ref={anchorRef}
          disabled={disabled}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end">
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={toggle}>
              <MenuList id="channel-options">
                <MenuItem
                  dense
                  disableGutters
                  className={classes.menuContainer}
                >
                  <Typography>Channel values</Typography>
                  <Box className={classes.inputs}>
                    <TextField
                      value={slider[0]}
                      onChange={(e) => handleInputChange(0, e)}
                      onBlur={handleInputBlur}
                      disabled={isLoading}
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      inputProps={{ step, min, max }}
                    />
                    <TextField
                      value={slider[1]}
                      onChange={(e) => handleInputChange(1, e)}
                      disabled={isLoading}
                      onBlur={handleInputBlur}
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      inputProps={{ step, min, max }}
                    />
                  </Box>
                </MenuItem>
                <ShowStaffUserOnly>
                  <MenuItem
                    dense
                    disableGutters
                    className={classes.menuContainer}
                  >
                    <Switch
                      id="setIsNegative"
                      size="small"
                      className={classes.switch}
                      checked={
                        customChannelOptions?.options?.[slideUUID]?.[name]
                          ?.isNegative
                      }
                      onChange={(e) => onChannelOptionChange(e, name)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <div>Is Negative</div>
                  </MenuItem>
                </ShowStaffUserOnly>
                <MenuItem
                  dense
                  disableGutters
                  className={classes.menuContainer}
                >
                  <Switch
                    id="lockColor"
                    size="small"
                    className={classes.switch}
                    checked={isColorLocked}
                    onChange={handleLockColor}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <div>Lock intensity</div>
                </MenuItem>
                <MenuItem
                  dense
                  disableGutters
                  className={`${classes.colors} ${classes.menuContainer}`}
                >
                  <ColorPalette handleColorSelect={handleColorSelect} />
                </MenuItem>
                <MenuItem
                  dense
                  disableGutters
                  onClick={handleRemoveChannel}
                  className={classes.menuContainer}
                >
                  <span className={classes.span}>Remove</span>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </>
    );
  },
);
