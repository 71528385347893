import { ORDER_FETCHING_ERROR_MESSAGE } from 'components/OrderForm/constants';
import { DEFAULT_ORDER_DETAILS } from 'components/OrderForm/Info/constants';
import { buildOrderDetails } from 'components/OrderForm/Info/utilities';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useLazyGetOrderByUUIDQuery } from 'store/apis/orderApi';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';

export const useBuiltOrderDetails = () => {
  const unmountIgnore = useUnmountIgnore();
  const { showError } = useSnackbar();
  const {
    params: { orderUUID },
  } = useRouteMatch();
  const [getOrderByUUID] = useLazyGetOrderByUUIDQuery();
  const [orderDetails, setOrderDetails] = useState(DEFAULT_ORDER_DETAILS);
  const [ready, setReady] = useState(!orderUUID);

  useEffect(() => {
    if (orderUUID) {
      getOrderByUUID(orderUUID)
        .unwrap()
        .then((details) => {
          if (!unmountIgnore.current && details) {
            setOrderDetails(buildOrderDetails(details));
            setReady(true);
          }
        })
        .catch(() => showError(ORDER_FETCHING_ERROR_MESSAGE));
    }
  }, [orderUUID, unmountIgnore]);

  return {
    orderDetails,
    ready,
  };
};
