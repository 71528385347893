import { Paper, Typography, Box } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import { useLoginPageStyles } from 'components/Login/styles';

export const TokenFetchingPage = () => {
  const { classes } = useLoginPageStyles();

  return (
    <Paper className={classes.authLoadingContainer}>
      <Box className={classes.authContent}>
        <Typography className={classes.authText} variant="h5" component="span">
          Setting up secure connection...
        </Typography>
        <SecurityIcon className={classes.authIcon} />
      </Box>
    </Paper>
  );
};
