import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { useRouteMatch } from 'react-router';
import { SimpleSlideView } from 'components/ImageViewer/SimpleSlideImageView';
import Container from '@mui/material/Container';
import GridLayout from 'react-grid-layout';
import { rowHeight } from 'components/utilities/grid';

import { Box } from '@mui/material';
import { FETCH_SLIDE_ERROR_MESSAGE } from 'constants/errorMessages';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { histowizTheme } from 'themes/defaultMuiTheme';
import { ORDERS_SLIDE_LIST_URL, SLIDE_VIEWER_URL } from 'constants/urls';
import { slideNameSerializedRemoveOrderRemoveSVS } from 'components/OrderSlidesList/utilities';
import { useGetSlideQuery } from 'store/apis/slidesApi';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';

const osdStyle = { height: '90%', width: '100%' };

const getGridLayout = ({ showColumns }) => {
  const width = 6;
  const height = 10;
  const gridLayout = showColumns.map((column, index) => {
    const xLocation = (index % 2) * width;
    const yLocation = Math.floor(index / 2) * height;

    return {
      i: `SLIDE_VIEWER_KEY_${index}`,
      x: xLocation,
      y: yLocation,
      w: width,
      h: height,
      resizeable: true,
    };
  });

  return gridLayout.filter((data) => !!showColumns.includes(data.i));
};

const SlideComparisonOSD = ({ slideUUID, index }) => {
  const dataGridSizing = { x: index, y: 1, w: 1, h: 1 };

  const { data: slide, error } = useGetSlideQuery({ slideUUID });
  useQueryError(error, FETCH_SLIDE_ERROR_MESSAGE);

  const BreadcrumbData = [
    {
      name: slide?.order?.name,
      color: histowizTheme.palette.secondary.main,
    },
    {
      name: 'Slides',
      color: histowizTheme.palette.secondary.light,
      link: ORDERS_SLIDE_LIST_URL.replace(':orderUUID', slide?.order?.uuid),
      newWindow: true,
    },
    {
      name: slideNameSerializedRemoveOrderRemoveSVS({
        slide,
      }),
      color: histowizTheme.palette.primary.light,
      link: SLIDE_VIEWER_URL.replace(':slideUUID', slide?.uuid),
      newWindow: true,
    },
  ];

  if (!slide) {
    return <div key={index.toString()} data-grid={dataGridSizing} />;
  }

  return (
    <div key={index.toString()} style={osdStyle}>
      <Box textAlign="center">
        {slide && (
          <Breadcrumbs
            data={BreadcrumbData}
            size="medium"
            divider=">"
            dividerColor={histowizTheme.palette.secondary.main}
          />
        )}
      </Box>

      <SimpleSlideView slide={slide} id={slide.uuid} showNavigator={false} />
    </div>
  );
};

export const MultiSlideParamsView = () => {
  const match = useRouteMatch();
  const ref = useRef(null);
  const [layout, setLayout] = useState([]);

  const slideUUIDsString = match.params.slideUUIDs;
  const slideUUIDs = slideUUIDsString.split(',');
  const showColumns = useMemo(() => [], []);
  const currentColumns = slideUUIDs.reduce((columns, current, index) => {
    columns[`SLIDE_VIEWER_KEY_${index}`] = true;
    return columns;
  }, {});

  Object.keys(currentColumns).forEach(function (key) {
    const value = currentColumns[key];
    if (value) {
      showColumns.push(key);
    }
  });

  const onLayoutChange = (layoutUpdate) => {
    setLayout(layoutUpdate);
  };

  useEffect(() => {
    const serializedLayout = getGridLayout({ showColumns });
    setLayout(serializedLayout);
  }, [showColumns]);

  if (slideUUIDs.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <MiniDrawerWithContext header={'Multi Slide Viewer'}>
        <Container ref={ref} maxWidth="xl" disableGutters={true}>
          <GridLayout
            className="layout"
            layout={layout}
            // this kind of sucks on all corners, disable for now
            // this will keep it only to 1 bottom right corner
            //resizeHandles={["nw", "sw", "se", "ne"]}
            cols={12}
            rowHeight={rowHeight}
            draggableCancel={'.ag-theme-balham'}
            width={ref.current ? ref.current.offsetWidth : 800}
            onLayoutChange={(layoutUpdate) => onLayoutChange(layoutUpdate)}
          >
            {slideUUIDs.slice(0).map((slideUUID, index) => (
              <div key={'SLIDE_VIEWER_KEY_' + index}>
                <SlideComparisonOSD
                  key={slideUUID}
                  slideUUID={slideUUID}
                  index={index}
                />
              </div>
            ))}
          </GridLayout>
        </Container>
      </MiniDrawerWithContext>
    </Fragment>
  );
};
