import { makeStyles } from 'tss-react/mui';

export const useAppLibraryHomeStyles = makeStyles()(() => ({
  root: {
    maxWidth: '1440px',
    margin: '0 auto',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '25px',
    gap: '24px',

    '& button': {
      marginBottom: 0,
    },
  },
  titleWrapper: {
    background:
      'linear-gradient(180deg, rgba(108, 162, 187, 0.6) 0%, rgba(108, 162, 187, 0) 268%)',
    padding: '5px 45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1.5px solid #dde5e9',
    borderRadius: '5px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',

    '& span': {
      paddingRight: '10px',
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 700,
  },
  modelsWrapper: {
    paddingTop: '24px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
  },
  select: {
    borderRadius: '6px',
    background: 'rgba(217, 217, 217, 0.4)',

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '0.5px solid rgba(0, 0, 0, 1)',
    },

    '& > .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
      padding: '5px 50px',
      textTransform: 'uppercase',
    },

    '& legend': { display: 'none' },
    '& fieldset': {
      top: 0,
      border: '0.5px solid rgba(0, 0, 0, 1)',
      padding: 0,
    },
  },
}));
