import { ShoppingCartOutlined } from '@mui/icons-material';
import ImageOutlined from '@mui/icons-material/ImageOutlined';
import { ApprovalDelegationIcon } from 'images/icons/ApprovalDelegationIcon';
import { DeleteIcon } from 'images/icons/DeleteIcon';
import React from 'react';
import { DATA_MANAGEMENT_BLUE } from 'utilities/colors';
import { toolTipValueGetterForGrouped } from 'components/utilities/AgGridCols/AgGridColumns';

export const columnDefs = [
  {
    field: 'block.orderName',
    headerName: 'ORDER ID',
    filter: true,
    sortable: false,
    tooltipField: 'block.orderName',
  },
  {
    field: 'slidesCount',
    headerName: 'SLIDES',
    width: 120,
    filter: true,
    sortable: false,
  },
  {
    field: 'atHistowiz',
    headerName: 'AT HISTOWIZ',
    headerTooltip: 'Block at HistoWiz',
    valueGetter: ({ data }) => data && (data.atHistowiz ? 'Yes' : 'No'),
    filter: true,
    sortable: false,
  },
  {
    field: 'submission',
    headerName: 'SUBMISSION TYPE',
    headerTooltip: 'Submission type',
    filter: true,
    sortable: false,
    tooltipField: 'submission',
  },
  {
    field: 'organ',
    headerName: 'ORGAN',
    headerTooltip: 'Organ',
    filter: true,
    sortable: false,
  },
  {
    field: 'species',
    headerName: 'SPECIES',
    headerTooltip: 'Species',
    filter: true,
    sortable: false,
    tooltipField: 'species',
  },
  {
    field: 'tumorType',
    headerName: 'TUMOR TYPE',
    headerTooltip: 'Tumor type',
    filter: true,
    sortable: false,
  },
  {
    field: 'controlType',
    headerName: 'CONTROL TYPE',
    headerTooltip: 'Control type',
    filter: true,
    sortable: false,
  },
  {
    field: 'created',
    headerName: 'SUBMISSION DATE',
    headerTooltip: 'Submission date',
    filter: true,
  },
  {
    field: 'modified',
    headerName: 'LAST MODIFIED',
    headerTooltip: 'Last modified',
    filter: true,
    sortable: true,
    sort: 'desc',
  },
];

export const defaultColDef = {
  flex: 1,
  minWidth: 110,
  filter: true,
  sort: 'asc',
  sortable: true,
};
export const autoGroupColumnDef = {
  headerName: 'PROJECT/BLOCK',
  minWidth: 240,
  showDisabledCheckboxes: true,
  cellRendererParams: {
    suppressCount: true,
    checkbox: true,
  },
  tooltipValueGetter: toolTipValueGetterForGrouped,
};

export const SLIDES_SECTION_TITLE = 'SLIDES CREATED FROM BLOCK:';

export const blockActions = {
  imageData: 'image data',
  processingQueue: 'processing queue',
};

export const blockActionsIcons = {
  imageData: <ImageOutlined />,
  processingQueue: <ShoppingCartOutlined />,
};

const CONTRIBUTE = 'contribute';
const DISPOSE = 'dispose';
const SHIP_BACK = 'ship back';

export const SubmitBlocksTypes = {
  CONTRIBUTE,
  DISPOSE,
  SHIP_BACK,
};

export const selectBlockActionOptions = (selectedOption) => [
  {
    value: DISPOSE,
    label: 'Dispose',
    icon: (
      <DeleteIcon
        color={
          selectedOption?.value === 'delete' ? DATA_MANAGEMENT_BLUE : 'black'
        }
      />
    ),
  },
  {
    value: CONTRIBUTE,
    label: 'Contribute',
    icon: (
      <ApprovalDelegationIcon
        color={
          selectedOption?.value === 'contribute'
            ? DATA_MANAGEMENT_BLUE
            : 'black'
        }
      />
    ),
  },
  /*{
    value: SHIP_BACK,
    label: 'Ship Back',
    icon: (
      <HardDriveIcon
        color={
          selectedOption?.value === 'export' ? DATA_MANAGEMENT_BLUE : 'black'
        }
      />
    ),
  },*/
];
