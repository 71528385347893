import React, { useEffect, useState } from 'react';

import { SlideCommentsListAddDetails } from 'components/OrderSlidesList/SlideCommentsListAddDetails';
import { useFilteredComments } from 'components/utilities/hooks/useFilteredComments';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import {
  useDeleteSlideCommentMutation,
  useLazyGetSharedSlidesCommentsQuery,
  useLazyGetSlideCommentsQuery,
} from 'store/apis/slideCommentsApi';
import { FETCHING_COMMENTS_ERROR_MESSAGE } from 'constants/errorMessages';

export const SlideComments = React.memo(({ slide, isSharedPage }) => {
  const [slideComments, setSlideComments] = useState([]);
  const { showSuccess, showError } = useSnackbar();
  const [getSlideComments] = useLazyGetSlideCommentsQuery();
  const [deleteSlideComment] = useDeleteSlideCommentMutation();
  const [getSharedSlidesComments] = useLazyGetSharedSlidesCommentsQuery();

  useEffect(() => {
    if (!slide) {
      return;
    }

    const slideCommentGetter = isSharedPage
      ? getSharedSlidesComments({ shareUUID: slide.share_uuid }).unwrap()
      : getSlideComments({ slideUUID: slide.uuid }).unwrap();

    slideCommentGetter
      .then((slideCommentsResponse) => {
        if (slideCommentsResponse) {
          setSlideComments(slideCommentsResponse);
        }
      })
      .catch(() => showError(FETCHING_COMMENTS_ERROR_MESSAGE));
  }, [slide, isSharedPage]);

  const handleDeleteComment = (commentToDelete) => {
    deleteSlideComment({
      slideUUID: commentToDelete.slide.uuid,
      commentUUID: commentToDelete.uuid,
    })
      .unwrap()
      .then(() => {
        const newComments = slideComments.filter(
          (comment) => comment.uuid !== commentToDelete.uuid,
        );

        setSlideComments(newComments);

        showSuccess('Comment was deleted');
      })
      .catch(() => showError('Failed to delete comment'));
  };

  const filteredComments = useFilteredComments(slideComments);

  return (
    <SlideCommentsListAddDetails
      slide={slide}
      slideComments={filteredComments}
      setSlideComments={setSlideComments}
      handleDeleteComment={handleDeleteComment}
    />
  );
});
