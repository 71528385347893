import { Typography, Box, Button, CircularProgress } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import React, { useState } from 'react';
import { useNotifyTeamMembers } from 'components/OrderForm/NotifyTeamMembersSelector/hooks';
import { SelectedUserChip } from 'components/OrderForm/NotifyTeamMembersSelector/SelectedUserChip';
import { useNotifyTeamMembersStyles } from 'components/OrderForm/NotifyTeamMembersSelector/styles';
import { TeamUsersDropdownSelector } from 'components/OrderForm/NotifyTeamMembersSelector/TeamUsersDropdownSelector';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import { filterTeamMembers } from 'components/OrderForm/NotifyTeamMembersSelector/utilities';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { NOTIFY_TEAM_MEMBERS_SUCCESS_MESSAGE } from 'constants/successMessages';
import { NOTIFY_TEAM_MEMBERS_ERROR_MESSAGE } from 'constants/errorMessages';

export const NotifyUsers = ({ order }) => {
  const { classes } = useNotifyTeamMembersStyles();
  const userDetails = useSelector(userDetailsSelector);

  const { showError, showSuccess } = useSnackbar();
  const { isLoading, notifyTeamMembers } = useNotifyTeamMembers(order);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notifiedUsers, setNotifiedUsers] = useState(
    order?.external_subscribers || [],
  );

  const handleSelect = (user) => {
    if (selectedUsers.includes(user)) {
      setSelectedUsers(selectedUsers.filter((u) => u !== user));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const preparedTeamMembers = filterTeamMembers(
    order?.team?.members,
    userDetails,
  );

  const handleNotifyTeamMembers = () => {
    const usersToBeNotified = [...notifiedUsers, ...selectedUsers];
    notifyTeamMembers(usersToBeNotified)
      .then(() => {
        setNotifiedUsers(usersToBeNotified);
        setSelectedUsers([]);
        showSuccess(NOTIFY_TEAM_MEMBERS_SUCCESS_MESSAGE);
      })
      .catch(() => {
        showError(NOTIFY_TEAM_MEMBERS_ERROR_MESSAGE);
      });
  };

  return (
    <Box>
      <Typography variant={'h6'}>
        Notify Team Members Of Order Notifications
      </Typography>
      <Box display={'flex'} flexDirection={'row'} alignItems="center">
        <TeamUsersDropdownSelector
          users={preparedTeamMembers}
          notifiedUsers={notifiedUsers}
          selectedUsers={selectedUsers}
          handleSelect={handleSelect}
        />
        <Box marginLeft="1rem">
          <Button
            onClick={handleNotifyTeamMembers}
            variant="contained"
            color={'primary'}
            disabled={!selectedUsers.length}
          >
            <Box marginRight={'0.5rem'} display={'flex'} alignItems="center">
              {isLoading ? (
                <CircularProgress size={16} color={'info'} />
              ) : (
                <NotificationsNoneIcon />
              )}
            </Box>
            <Typography>Notify selected team members</Typography>
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        {selectedUsers.map((user) => (
          <SelectedUserChip
            key={user.uuid}
            classes={classes}
            user={user}
            handleDelete={() => handleSelect(user)}
          />
        ))}
      </Box>
    </Box>
  );
};
