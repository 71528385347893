import Box from '@mui/material/Box';
import { PathologyMapSearchBar } from 'components/PathologyMap/PathologyMapSearchBar';
import { Grid } from '@mui/material';
import { PathologyMapCategoriesSection } from 'components/PathologyMap/PathologyMapCategories/PathologyMapCategoriesSection';
import { PathologyMapFilterPropertiesSection } from 'components/PathologyMap/PathologyMapFilterProperties/PathologyMapFilterPropertiesSection';
import { PathologyMapActiveFiltersSection } from 'components/PathologyMap/PathologyMapActiveFilters/PathologyMapActiveFiltersSection';
import { PathologyMapToolbar } from 'components/PathologyMap/PathologyMapToolbar';
import { usePathologyMapLayoutStyles } from './styles';
import { ViewTypes } from 'components/SlideGroups/utilities/constants';
import { useState } from 'react';
import { PathologyMapSlideItems } from 'components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideItems';

export const PathologyMapLayout = () => {
  const [view, setView] = useState(ViewTypes.CARD_VIEW);
  const [presetIndex, setPresetIndex] = useState(0);

  const { classes } = usePathologyMapLayoutStyles();

  return (
    <div>
      <Box mb={2} className={classes.boxSearch}>
        <PathologyMapSearchBar />
      </Box>

      <Grid container spacing={3} direction="column">
        <Grid item container spacing={2} alignItems="stretch">
          <Grid item xs={6} laptop={3} display="flex">
            <PathologyMapCategoriesSection
              presetIndex={presetIndex}
              setPresetIndex={setPresetIndex}
            />
          </Grid>
          <Grid item xs={6} laptop={3} display="flex">
            <PathologyMapFilterPropertiesSection presetIndex={presetIndex} />
          </Grid>
          <Grid item xs={12} laptop={6} display="flex">
            <PathologyMapActiveFiltersSection />
          </Grid>
        </Grid>

        <Grid item>
          <PathologyMapToolbar view={view} setView={setView} />
        </Grid>
        <Grid item container>
          <PathologyMapSlideItems view={view} />
        </Grid>
      </Grid>
    </div>
  );
};
