import React, { Fragment } from 'react';
import {
  trueFalseOptions,
  OrderTurnaroundTimeOptions,
  CuttingInstructionsOptions,
  OrderIHCOptimizationTypes,
} from 'components/OrderForm/constants';
import { BaseOrderDropdownSelections } from 'components/OrderForm/components/BaseSelectDropdowns';
import { createFixative } from 'services/resources/commonResources';
import CreatableSelect from 'react-select/lib/Creatable';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import { useController, useFormContext } from 'react-hook-form';
import { shouldDisplayError } from 'components/common';
import { FormInputInfoTooltip } from 'components/OrderForm/components/FormInputInfoTooltip';
import { CREATE_FIXATIVE_ERROR_MESSAGE } from 'constants/errorMessages';
import { CheckmarkProvider } from 'components/OrderForm/components/CheckmarkProvider';
import {
  styles,
  selectStyles,
  createBorderStylesForReactSelect,
} from 'components/OrderForm/styles';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles()(styles);

export const PathologyMapContributorSelection = ({ name }) => (
  <BaseOrderDropdownSelections
    name={name}
    title="Pathology Map Contribution? (*)"
    options={trueFalseOptions}
    checkmark
  />
);

export const MaterialsAtHistoWizSelection = ({ name }) => (
  <BaseOrderDropdownSelections
    name={name}
    title={'Samples Already At HistoWiz?'}
    options={trueFalseOptions}
    checkmark
  />
);

export const IHCOptimizationTypeSelection = ({ name }) => (
  <BaseOrderDropdownSelections
    name={name}
    title={'Antibody Optimization Requested (*)'}
    options={OrderIHCOptimizationTypes}
    checkmark
  />
);

export const TurnaroundTimeSelection = ({ name, options }) => (
  <BaseOrderDropdownSelections
    name={name}
    title={'Histology Turnaround Time Request (Days)'}
    options={options}
    checkmark
  />
);

export const getSerializedFormObjectFromTurnaroundTime = (days) => {
  return OrderTurnaroundTimeOptions.find((option) => option.value === days);
};

export const CuttingInstructionsSelection = ({ name }) => {
  return (
    <BaseOrderDropdownSelections
      name={name}
      title={'Grossing Instructions'}
      options={CuttingInstructionsOptions}
      checkmark
    />
  );
};

export const CreateFixativeSelections = ({
  name,
  title,
  options,
  updateOrderFixatives,
  teamUUID,
  infoText,
  checkmark,
}) => {
  const { classes } = useStyles();
  const { showError } = useSnackbar();

  const {
    control,
    formState: { submitCount },
  } = useFormContext();

  const {
    field: { onChange, onBlur, value },
    fieldState: { isTouched, error },
  } = useController({
    name,
    control,
  });

  const isValidated = isTouched || submitCount !== 0;
  const isDisplayValidation = isValidated && checkmark;

  const isRequired = title.includes('(*)');
  const serializedTitle = title.replace('(*)', '');

  const handleCreateOrderFixative = (value) => {
    const postParams = {
      team_uuid: teamUUID,
      name: value,
    };

    createFixative(postParams)
      .then((response) => {
        onChange({
          value: response['uuid'],
          label: response['name'],
        });
        updateOrderFixatives();
      })
      .catch(() => showError(CREATE_FIXATIVE_ERROR_MESSAGE));
  };

  const selectStylesWithCheckmark = {
    control: (base) => createBorderStylesForReactSelect(base, value),
  };

  return (
    <div>
      <Typography>
        {/*<label className={classes.formLabel}>{this.props.title}</label>*/}
        {isRequired ? (
          <Fragment>
            <label className={classes.requiredLabel}>{serializedTitle}</label>
            <label className={classes.requiredAsterisk}>*</label>
            {infoText && <FormInputInfoTooltip infoText={infoText} />}
          </Fragment>
        ) : (
          <Fragment>
            <label className={classes.formLabel}>{serializedTitle}</label>
            {infoText && <FormInputInfoTooltip infoText={infoText} />}
          </Fragment>
        )}
      </Typography>
      <CheckmarkProvider
        isValid={!error}
        validated={isValidated}
        visible={isDisplayValidation}
      >
        <CreatableSelect
          options={options}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          clearable={false}
          onCreateOption={handleCreateOrderFixative}
          styles={isValidated ? selectStylesWithCheckmark : selectStyles}
        />
      </CheckmarkProvider>
      {shouldDisplayError(!!error, isTouched, submitCount) && (
        <div className={classes.errorFeedback}>{error.message}</div>
      )}
    </div>
  );
};

export const OrderInfoFixativeSelection = ({
  name,
  selectableOrderFixativeOptions,
  team,
  updateOrderFixatives,
}) => {
  return (
    <CreateFixativeSelections
      name={name}
      title={'Fixative Order Solution (*)'}
      options={selectableOrderFixativeOptions}
      teamUUID={team?.value}
      updateOrderFixatives={updateOrderFixatives}
      checkmark
    />
  );
};

export const SpecialInstructionsAttachmentSelection = ({
  selectableSpecialInstructionsAttachments,
  name,
}) => {
  const { watch } = useFormContext();
  const attachment = watch(name);

  return (
    <Fragment>
      <BaseOrderDropdownSelections
        name={name}
        title={'Special Instructions (file attachment)'}
        options={selectableSpecialInstructionsAttachments}
        checkmark
      />
      {/*Only show download if something has been selected*/}
      {attachment?.url ? (
        <div>
          Download -{' '}
          <a
            href={attachment.url}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.label}
          </a>
        </div>
      ) : null}
    </Fragment>
  );
};

export const OrderInfoSpeciesSelection = ({
  name,
  selectableSpecies,
  /* eslint-disable-next-line no-unused-vars */
  materialsAtHistowiz,
}) => {
  return (
    <Fragment>
      <BaseOrderDropdownSelections
        name={name}
        title={'Species (*)'}
        options={selectableSpecies}
        checkmark
        // don't disable because it's still getting flagged by yum to need
        //isDisabled={materialsAtHistowiz}
      />
    </Fragment>
  );
};

export const OrderInfoProjectTeamsSelection = ({
  name,
  selectableTeams,
  team,
}) => {
  return (
    <Box ml={10} mb={5}>
      <BaseOrderDropdownSelections
        name={name}
        title={
          'Project Team Owner (The above project will be created under this team)'
        }
        options={selectableTeams}
        defaultValue={team}
      />
    </Box>
  );
};

// upon second thought, don't actually show this and default to the default team ID
export const FormInputTeamSelection = ({ name, selectableTeams, team }) => {
  return (
    <>
      <BaseOrderDropdownSelections
        name={name}
        title={'Team'}
        options={selectableTeams}
        defaultValue={team}
      />
    </>
  );
};
