import { FormInputV2 } from 'components/OrderForm/components/InfoFormInputs';
import React from 'react';

export const BillingContactPONumberInput = ({ name, checkmark, validated }) => {
  return (
    <FormInputV2
      name={name}
      formLabel={'PO Number'}
      formPlaceholder={'PO Number ...'}
      formInput={'text'}
      checkmark={checkmark}
      validated={validated}
    />
  );
};
