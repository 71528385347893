import { makeStyles } from 'tss-react/mui';
import { WARNING_RED } from 'utilities/colors';

export const useComputeWalletCardStyles = makeStyles()(() => ({
  centeredContainer: {
    paddingBlock: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentContainer: {
    paddingBottom: '1rem',
    height: 'calc(100% - 4.5rem)',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  greyLabel: {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '0.75rem',
  },
  alert: {
    textAlign: 'center',
    fontSize: '1.125rem',
    color: WARNING_RED,
  },
  label: {
    fontWeight: 500,
    fontSize: '0.875rem',
    letterSpacing: '0.1em',
  },
  remainingAmountContainer: {
    paddingInline: '1.75rem',
    paddingBlock: '1.25rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  amountSpentContainer: {
    paddingInline: '1.75rem',
    paddingBlock: '1.25rem',
  },
  remainingAccountValue: {
    fontSize: '1.5rem',
    fontWeight: 500,
    marginTop: '0.5rem',
  },
  verticallyCenteredRow: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  roundedIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    backgroundColor: 'rgb(246, 246, 246)',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
  },
  rowLabel: {
    marginLeft: '1rem',
    fontSize: '0.875rem',
  },
  rowValue: {
    fontWeight: 500,
    fontSize: '1.125rem',
    marginLeft: 'auto',
  },
}));
