export const HISTOWIZ_PRIMARY_COLOR = '#EC6A7A';
export const HISTOWIZ_SECONDARY_COLOR = '#0990B2';
export const HISTOWIZ_LIGHT_SECONDARY_COLOR = '#ADD8E6';
export const UMIAMI_PRIMARY_COLOR = '#f47627';
export const UMIAMI_SECONDARY_COLOR = '#087434';
export const IDEXX_PRIMARY_COLOR = '#EF453F';
export const IDEXX_SECONDARY_COLOR = '#33A1AE';
export const LOCALHOST_PROD_API_PRIMARY_COLOR = '#E3160F';
export const LOCALHOST_PRIMARY_COLOR = '#2a4f9a';
export const LOCALHOST_SECONDARY_COLOR = '#9f269d';

const WHITE_100 = '#ffffff';
const WHITE_200 = '#fafafa';
const WHITE_300 = '#f2f2f2';

export const WHITE = WHITE_100;
export const PRIMARY_WHITE = WHITE_300;
export const SECONDARY_WHITE = WHITE_200;

export const PRIMARY_PINK = 'rgb(220, 114, 124)';
export const PRIMARY_PINK_TRANSPARENT_8 = 'rgb(220, 114, 124, 0.08)';
export const PRIMARY_BLUE = '#5AA4BE';
export const SECONDARY_BLUE = '#4F46E5';
export const DATA_MANAGEMENT_BLUE = '#6D6AF5';
export const DATA_MANAGEMENT_BLUE_2 = '#D2D1FB';
export const LIGHT_BLUE = '#EEF1FF';
export const SECONDARY_BLUE_2 = '#5470FF';
export const SECONDARY_BLUE_3 = '#4F84FF';
export const SECONDARY_BLUE_2_TRANSPARENT = 'rgba(84, 112, 255, 0.08)';
export const PRIMARY_PURPLE = '#816996';
export const PRIMARY_GREEN = '#77EEA7';

export const SECONDARY_VIEWER = '#0690B2';

export const BUTTON_GREY = '#D9D9D9';
export const BUTTON_GREY_2 = '#5E5E5E';
export const DARK_GREY = '#666666';
export const DARK_GREY_2 = '#474747';
export const LIGHT_GREY = '#e0e0e0';
export const LIGHT_GREY_1 = '#EAEAEA';
export const LIGHT_GREY_2 = '#A6A6A6';
export const LIGHT_GREY_3 = '#D9D9D9';
export const MEDIUM_GREY = '#838383';
export const BORDER_GREY = '#8C8C8C';
export const BORDER_GEY_2 = '#BCBCBC';
export const DARK_GREY_HOVER = '#727272';
export const BACKGROUND_GREY = '#f3f3f3';
export const BACKGROUND_GREY_LIGHT = '#F7F7F7';
export const WHITE_SMOKE = '#F5F5F5';
export const WHITE_SMOKE_2 = '#FBFBFB';
export const BLACK_ICON = '#1C1B1F';

export const ACTION_LINK_COLOR = '#2D6F86';
export const LINK_COLOR = '#6A7FEC';

export const CAPTION_GREY = '#7B7B7B';
export const TRANSPARENT_CAPTION_GREY_50 = '#7B7B7B80';

export const FORM_TEXT_COLOR = '#313131';
export const BORDER_COLOR = '#bdc3c7';

export const FORM_GREY = '#757575';
export const TRANSPARENT_FORM_GREY_50 = '#75757580';

export const VIEWER_ICONS_HOVER = 'rgba(158, 46, 0, 0.6)';
export const TABS_BLUE = '#697FE9';
export const ERROR_ICON = '#db0612';
export const WARNING_RED = 'rgb(242, 72, 34)';

export const TABS_GRADIENT =
  'linear-gradient(180deg, rgba(108, 162, 187, 0.45), rgba(108, 162, 187, 0.10))';
export const TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.4)';
export const TRANSPARENT_BLACK_10 = 'rgba(0, 0, 0, 0.1)';
export const TRANSPARENT_BLACK_15 = 'rgba(0, 0, 0, 0.15)';
export const TRANSPARENT_BLACK_26 = 'rgba(0, 0, 0, 0.26)';
export const TRANSPARENT_BLACK_50 = 'rgba(0, 0, 0, 0.50)';
export const TRANSPARENT_BLACK_80 = 'rgba(0, 0, 0, 0.8)';
