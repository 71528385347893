import { useMainToolbarStyles } from 'components/ImageViewer/styles';
import { Button } from '@mui/material';
import React from 'react';

export const SlideViewerToolbarButton = ({ className = '', ...props }) => {
  const { classes } = useMainToolbarStyles();

  const classNames = `${classes.defaultButton} ${className}`;

  return (
    <Button
      variant={'contained'}
      color={'default'}
      className={classNames}
      {...props}
    />
  );
};
