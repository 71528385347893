import React, { useMemo } from 'react';
import { Paper } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { useGetProjectsQuery } from 'store/apis/projectsApi';
import { useProjectsStyles } from 'components/Projects/styles';
import {
  getContextMenu,
  ORDERS_TABLE_PARAMS,
  PROJECTS_COLUMNS,
  RESPONSIVE_GRID,
} from 'components/Projects/helpers/constants';
import {
  getProjectDataForGrid,
  isRowMaster,
} from 'components/Projects/helpers/functions';

export const ProjectsOverview = () => {
  const { data: items } = useGetProjectsQuery();
  const { classes } = useProjectsStyles();
  const { agGridWrapper } = classes;

  const gridItems = useMemo(() => items?.map(getProjectDataForGrid), [items]);

  const getRowId = useMemo(() => {
    return (params) => params.data.uuid;
  }, []);

  useTitle('HistoWiz - Projects Overview');

  return (
    <MiniDrawerWithContext header="Projects">
      <Paper className={`ag-theme-balham ${agGridWrapper}`}>
        <AgGridReact
          masterDetail
          animateRows
          defaultColDef={RESPONSIVE_GRID}
          domLayout="autoHeight"
          rowData={gridItems}
          columnDefs={PROJECTS_COLUMNS}
          getContextMenuItems={getContextMenu}
          detailCellRendererParams={ORDERS_TABLE_PARAMS}
          isRowMaster={isRowMaster}
          getRowId={getRowId}
          detailRowAutoHeight
        />
      </Paper>
    </MiniDrawerWithContext>
  );
};
