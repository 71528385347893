import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';
import React from 'react';

const useStyles = makeStyles()(() => ({
  boldText: {
    fontWeight: 'bold',
  },
}));

// bolding text in Typography is weirdly annoying having to always import styles, deal with that by just having a component for this
export const TypographyBold = ({
  variant = 'body2',
  display = 'initial',
  gutterBottom = false,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <Typography
      variant={variant}
      gutterBottom={gutterBottom}
      className={classes.boldText}
      display={display}
    >
      {children}
    </Typography>
  );
};

export const shouldDisplayError = (hasError, isTouched, submitCount) =>
  !!((isTouched || submitCount) && hasError);
