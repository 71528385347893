import React, { useEffect, useState } from 'react';
import { Switch, FormControlLabel, Typography, Link, Box } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

import { useGlpFormStyles } from 'components/GlpForm/styles';
import { GLP_REQUIRED_TEXT } from 'components/GlpForm/helpers/constants';

export const GlpProtocol = ({
  handleProtocolFiles,
  isOpen,
  setIsOpen,
  hasError,
  initialGlp,
  initialFiles,
}) => {
  const [files, setFiles] = useState([]);

  const { classes } = useGlpFormStyles();
  const { protocol, label, input, fullWidth, error } = classes;

  const text = files.map(({ name }) => name).join(', ');

  useEffect(() => {
    if (files.length) {
      handleProtocolFiles(files);
    }
  }, [files]);

  const Toggle = (
    <Switch
      checked={isOpen}
      onChange={({ target }) => setIsOpen(target.checked)}
      color="primary"
      disabled={initialGlp}
    />
  );

  return (
    <section className={protocol}>
      <FormControlLabel
        className={fullWidth}
        control={Toggle}
        label="Is this a pre-clinical study that requires GLP? A GLP Protocols Document will be required if this option is selected"
      />
      {isOpen && (
        <>
          {initialFiles?.length && (
            <Box className={`${fullWidth} ${label}`}>
              {initialFiles.map(({ name, url }) => (
                <Link key={url} href={url} target="blank">
                  <Typography textTransform="uppercase" color="primary">
                    {name}
                  </Typography>
                </Link>
              ))}
            </Box>
          )}
          {!initialGlp && (
            <>
              <label
                className={`${fullWidth} ${label} ${
                  hasError && !files.length && error
                }`}
                htmlFor="protocol"
              >
                {!files.length && <UploadIcon color="primary" />}
                <Typography textTransform="uppercase" color="primary">
                  {files.length ? text : 'Choose files to upload'}
                </Typography>
                <input
                  onChange={({ target }) => setFiles(Array.from(target.files))}
                  className={input}
                  type="file"
                  id="protocol"
                  multiple
                />
              </label>
              <span className={fullWidth}>
                <b>{GLP_REQUIRED_TEXT}</b>
                <br />
                If you need any assistance, please contact our project managers.
                <br />A Principal Investigator from our team will contact you to
                complete your GLP order.
              </span>
            </>
          )}
        </>
      )}
    </section>
  );
};
