import Typography from '@mui/material/Typography';
import React from 'react';

export const OrderCancelled = () => {
  return (
    <div>
      <Typography align="center" variant={'h5'} marginTop={'3rem'}>
        This order has been cancelled
      </Typography>
    </div>
  );
};
