import {
  columnDefs,
  defaultColDef,
  autoGroupColumnDef,
} from 'components/OrderForm/SamplesFormV1/components/SelectExistingSamplesModal/constants';
import { formatDatetimeStringToDates } from 'utilities/dates_and_times';

export const prepareData = (data) => {
  const orders = {};
  data.forEach((order) => {
    const orderNumber = order.name.split(' ')[1];
    if (!orders[orderNumber]) {
      orders[orderNumber] = [];
    }

    order.samples.forEach((sample) => {
      const {
        name,
        submission,
        uuid,
        organ,
        id,
        control_type: controlType,
      } = sample;

      orders[orderNumber].push({
        order: ['Projects', order.projectName, order.name, name],
        name,
        submittedIn: submission.name,
        submittedAt: formatDatetimeStringToDates(sample.created),
        uuid,
        organ: organ?.name || '',
        id,
        controlType,
        sample,
      });
    });
  });

  const rowData = [];
  Object.keys(orders).forEach((order) => {
    orders[order].forEach((item) => {
      rowData.push(item);
    });
  });

  const getDataPath = (data) => {
    return data.order ? data.order : null;
  };

  return {
    rowData,
    columnDefs,
    defaultColDef,
    autoGroupColumnDef,
    getDataPath,
  };
};
