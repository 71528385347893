import { useTitle } from 'components/utilities/hooks/useTitle';
import { SignupForm } from 'components/SignupForm/SignupForm';
import { registerUser } from 'services/resources/auth';
import { ERROR_PROCESSING_SIGNUP_MESSAGE } from 'components/SignupForm/constants';
import { LOGIN_URL } from 'constants/urls';
import { useHistory } from 'react-router';
import { useSignupPageStyles } from 'components/pages/styles';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const SignupPage = () => {
  useTitle('HistoWiz - Register');
  const { classes } = useSignupPageStyles();
  const { showError, showSuccess } = useSnackbar();
  const history = useHistory();

  const onSubmit = (postParams) => {
    return registerUser(postParams)
      .then(() => {
        showSuccess('Account created successfully!');
        history.replace(LOGIN_URL);
      })
      .catch(function (error) {
        let errorMessage = '',
          data = error.response?.data;
        if (data) {
          if (typeof data === 'string' && data.includes('html')) {
            return showError(ERROR_PROCESSING_SIGNUP_MESSAGE);
          }
          const keys = Object.keys(data);
          if (keys.length > 1) {
            keys.forEach((key) => {
              errorMessage += `${key}: ${data[key]}. `;
            });
          } else {
            errorMessage = `${keys}: ${data[keys]}`;
          }
        }

        showError(errorMessage || ERROR_PROCESSING_SIGNUP_MESSAGE);
      });
  };

  return <SignupForm classes={classes} onSubmitAction={onSubmit} />;
};
