import React, { Fragment, useEffect, useRef } from 'react';

import { makeStyles } from 'tss-react/mui';

import * as typeformEmbed from '@typeform/embed';
import { MiniDrawerWithContext } from '../Layout/drawer';
import { useHistory, useParams } from 'react-router';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { ORDERS_LIST_URL } from 'constants/urls';

const useStyles = makeStyles()({
  root: {},
  returnButton: {},
});

export const OrderFeedbackTypeForm = ({ orderID }) => {
  const typeformRef = useRef(null);

  useEffect(() => {
    typeformEmbed.makeWidget(
      typeformRef.current,
      `https://research.typeform.com/to/L8e1QiOZ#orderid=${orderID}`,
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 50,
      },
    );
  }, [typeformRef, orderID]);

  return <div ref={typeformRef} style={{ height: '50vh', width: '100%' }} />;
};

const ReturnToMyOrdersButton = () => {
  const { classes } = useStyles();
  const history = useHistory();

  const onBackToOrders = () => {
    history.push(ORDERS_LIST_URL);
  };

  return (
    <Fragment>
      <Box mb={2}>
        <Button
          className={classes.returnButton}
          variant="contained"
          color="primary"
          fullWidth={false}
          onClick={onBackToOrders}
        >
          Return To My Orders
        </Button>
      </Box>
    </Fragment>
  );
};

export const OrderFormFeedbackPage = () => {
  const params = useParams();

  const editForm = () => {
    return <OrderFeedbackTypeForm orderID={params['orderID']} />;
  };
  return (
    <MiniDrawerWithContext header={'Order Form Feedback'}>
      <ReturnToMyOrdersButton />
      {editForm()}
    </MiniDrawerWithContext>
  );
};
