import React, { Fragment, useState } from 'react';
import { Tooltip, Grid, Zoom } from '@mui/material';
import { NewReleases } from '@mui/icons-material';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';

import { SimpleSlideView } from 'components/ImageViewer/SimpleSlideImageView';
import { ViewAttachmentsModal } from 'components/ImageViewer/ViewAttachmentsModal';
import { SlideName } from 'components/SlideName/SlideName';

export const QCViewerWarning = ({ user }) => (
  <Tooltip
    TransitionComponent={Zoom}
    TransitionProps={{ timeout: 300 }}
    title={
      user && user.first_name.length && user.last_name.length
        ? `${user.first_name} ${user.last_name} is currently doing QC`
        : 'Somebody is currently doing QC'
    }
    placement="right"
  >
    <WarningIcon fontSize={'medium'} color={'secondary'} />
  </Tooltip>
);

export const ShowSpecialInstructions = ({
  setIsAttachmentsModalVisible,
  classes,
}) => {
  return (
    <Grid item className={classes}>
      <Tooltip title="Special instructions for this slide">
        <NewReleases
          cursor="pointer"
          fontSize={'large'}
          color={'primary'}
          onClick={() => {
            setIsAttachmentsModalVisible(true);
          }}
        />
      </Tooltip>
    </Grid>
  );
};

export const SingleQCSlideView = ({
  slide,
  showSlideName = true,
  isAttachmentsViewerFeatureEnabled,
  ...props
}) => {
  const [isAttachmentsModalVisible, setIsAttachmentsModalVisible] =
    useState(false);

  const { order, name, uuid } = slide;

  const isSpecialInstructionExist =
    order?.special_instructions_attachment ||
    order?.special_instructions?.length;

  return (
    // if it's a single slide, use the entire col width
    <>
      <Fragment key={uuid}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box display={'flex'} alignItems={'center'}>
            {showSlideName && <SlideName name={name} />}
          </Box>
          {isAttachmentsViewerFeatureEnabled && isSpecialInstructionExist ? (
            <ShowSpecialInstructions
              setIsAttachmentsModalVisible={setIsAttachmentsModalVisible}
            />
          ) : null}
        </Grid>
        <Box height={'100%'} p={0} m={0}>
          <SimpleSlideView
            slide={slide}
            id={uuid}
            showNavigator={true}
            {...props}
          />
        </Box>
      </Fragment>
      <ViewAttachmentsModal
        open={isAttachmentsModalVisible}
        onClose={() => setIsAttachmentsModalVisible(false)}
        order={order}
      />
    </>
  );
};
