import Box from '@mui/material/Box';
import { useCardStyles } from 'components/TeamOverview/components/Common/Card/styles';

export const Card = ({
  cardClass = null,
  isActive = false,
  header,
  children,
}) => {
  const { classes } = useCardStyles();

  return (
    <Box
      className={`${classes.cardContainer} ${cardClass} ${
        isActive && 'active'
      }`}
    >
      {!!header && <Box className={classes.header}>{header}</Box>}
      {children}
    </Box>
  );
};
