import JSPDF from 'jspdf';
import {
  CHART_COLORS,
  MAIN_SLIDES_PARAMETERS,
  IMAGE_TAG_KEY,
  ANALYSIS_KEY,
  PIE,
} from 'components/OrderSlidesChart/helpers/constants';
import { PRIMARY_WHITE } from 'utilities/colors';

export const getSelectedChartValues = (
  data,
  paramsSelected,
  slidesSelected,
  slideNameParameter,
  chartType,
) => {
  if (!data || !paramsSelected) {
    return [];
  }

  const selectedChartValues = [];
  const selectedSlideNames = Object.keys(slidesSelected).filter(
    (key) => !!slidesSelected[key],
  );

  let colorIndex = 0;

  for (const prop in paramsSelected) {
    if (paramsSelected[prop]) {
      const selectedData = data
        .filter((item) => selectedSlideNames.includes(item[slideNameParameter]))
        .map((item) => item[prop]);

      selectedChartValues.push({
        label: prop,
        data: selectedData,
        backgroundColor: CHART_COLORS[colorIndex],
        borderColor:
          chartType === PIE ? PRIMARY_WHITE : CHART_COLORS[colorIndex],
      });
      if (colorIndex === CHART_COLORS.length - 1) {
        colorIndex = 0;
      } else {
        colorIndex++;
      }
    }
  }

  return selectedChartValues;
};

export const getElementColor = (chartValues) => {
  const currentColor = chartValues[chartValues.length - 1]?.backgroundColor;

  return CHART_COLORS[
    CHART_COLORS.indexOf(currentColor) === CHART_COLORS.length - 1
      ? 0
      : CHART_COLORS.indexOf(currentColor) + 1
  ];
};

const getChartLabel = (event, elements, isPie) => {
  const index = isPie ? elements[0]?.datasetIndex : elements[0]?.index;

  return isPie
    ? event.chart.data.datasets[index]?.label
    : event.chart.data.labels[index];
};

export const getSlideUUIDBySource = (slides, sourceName) =>
  slides.find(({ name }) => name === sourceName?.match(/.*\.svs/)[0])?.uuid;

const getSlideUUIDByEvent = (event, elements, slides, isPie) =>
  getSlideUUIDBySource(slides, getChartLabel(event, elements, isPie));

const handleHover = (event, elements, slides, isPie) => {
  const hasSlide = !!getSlideUUIDByEvent(event, elements, slides, isPie);

  event.chart.canvas.style.cursor = hasSlide ? 'pointer' : 'default';
};

const getChartAfterLabel = ({ label, dataset }, slides, isPie) =>
  getSlideUUIDBySource(slides, isPie ? dataset.label : label) &&
  'Click to view slide';

export const getChartOptions = (
  orderName,
  openSlideModal,
  showValues,
  slides,
  isPie,
) => ({
  onHover: (event, elements) => handleHover(event, elements, slides, isPie),
  onClick: (event, elements) =>
    openSlideModal(getSlideUUIDByEvent(event, elements, slides, isPie)),
  plugins: {
    tooltip: {
      callbacks: {
        afterLabel: (e) => getChartAfterLabel(e, slides, isPie),
        ...(isPie && {
          label: ({ dataset, label, formattedValue }) =>
            `${dataset.label} - ${label}: ${formattedValue}`,
        }),
      },
    },
    title: {
      display: true,
      text: `${orderName} - Slides Chart`,
    },
    legend: { position: 'top' },
    datalabels: {
      formatter: (value) => value.toFixed(3),
      display: showValues,
      align: 'end',
      anchor: 'end',
    },
  },
  ...(isPie ? { maintainAspectRatio: false } : { responsive: true }),
});

export const prepareLabelsAndSlides = (singleSlideData) => {
  const namesFromSlide = [];
  const labelsFromSlide = [];
  for (const slideParam in singleSlideData) {
    if (typeof singleSlideData[slideParam] === 'number') {
      labelsFromSlide.push(slideParam);
    } else if (MAIN_SLIDES_PARAMETERS.includes(slideParam)) {
      namesFromSlide.push(slideParam);
    }
  }
  return [labelsFromSlide, namesFromSlide];
};

export const getInitialValues = (labels, names) => {
  const initialLabels = {};
  labels.forEach((value) => {
    initialLabels[value] = false;
  });

  const initialSlides = {};
  names.forEach((value) => {
    initialSlides[value] = false;
  });

  return [initialLabels, initialSlides];
};

export const chart2PDF = (chartRef, orderName, chartType) => {
  const { canvas } = chartRef.current;
  const imgData = canvas.toDataURL('image/png');
  const pdf = new JSPDF('l', 'px', 'a4');
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  const widthRatio = pageWidth / canvas.width;
  const heightRatio = pageHeight / canvas.height;
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

  const canvasWidth = canvas.width * ratio;
  const canvasHeight = canvas.height * ratio;

  const marginX = (pageWidth - canvasWidth) / 2;
  const marginY = (pageHeight - canvasHeight) / 2;
  pdf.addImage(imgData, 'PNG', marginX, marginY, canvasWidth, canvasHeight);
  pdf.save(`${orderName}-${chartType}.pdf`);
};

export const replaceDollarSign = (item) =>
  Object.entries(item).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'string' ? value.replaceAll('$', '-') : value,
    }),
    {},
  );

export const transformSlide = (slide) => {
  const replaced = replaceDollarSign(slide);

  return {
    ...replaced,
    [IMAGE_TAG_KEY]: `${replaced[IMAGE_TAG_KEY]} (${replaced[ANALYSIS_KEY]})`,
  };
};
