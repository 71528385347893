import {
  FETCH_USER_DETAILS_MESSAGE,
  UPDATE_USER_DETAILS_MESSAGE,
} from 'constants/errorMessages';
import { USER_DETAILS_UPDATED_SUCCESS_MESSAGE } from 'constants/successMessages';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getAPIUserDetails,
  updateAPIUserDetails,
} from 'services/resources/commonResources';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';

export const useEditUserDetails = () => {
  const { register, handleSubmit, reset, watch } = useForm();

  const {
    register: passwordRegister,
    handleSubmit: onPasswordSubmit,
    formState: { errors: passwordErrors },
    reset: passwordReset,
    watch: passwordWatch,
  } = useForm();

  const [isLoaded, setIsLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isDetailsUpdating, setIsDetailsUpdating] = useState(false);
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);

  const { showError, showSuccess } = useSnackbar();

  const onSubmit = (data) => {
    setIsDetailsUpdating(true);

    const { first_name, last_name, phone_number, secondary_phone_number } =
      data;

    const postParams = {
      first_name,
      last_name,
      phone_number,
      secondary_phone_number,
    };

    updateAPIUserDetails(postParams)
      .then(() => {
        setInitialValues(postParams);
        showSuccess(USER_DETAILS_UPDATED_SUCCESS_MESSAGE);
      })
      .catch(() => showError(UPDATE_USER_DETAILS_MESSAGE))
      .finally(() => setIsDetailsUpdating(false));
  };

  const handleCreateNewPassword = (data) => {
    setIsPasswordUpdating(true);

    const postParams = {
      password: data.newPassword,
    };

    updateAPIUserDetails(postParams)
      .then(() => {
        setIsPasswordUpdating(false);
        showSuccess(USER_DETAILS_UPDATED_SUCCESS_MESSAGE);
        passwordReset();
      })
      .catch(() => showError(UPDATE_USER_DETAILS_MESSAGE))
      .finally(() => setIsPasswordUpdating(false));
  };

  useEffect(() => {
    let isMounted = true;

    getAPIUserDetails()
      .then((user) => {
        const { first_name, last_name, phone_number, secondary_phone_number } =
          user;

        const initialData = {
          first_name,
          last_name,
          phone_number,
          secondary_phone_number,
        };

        setInitialValues(initialData);
        reset(initialData);

        if (isMounted) {
          setIsLoaded(true);
        }
      })
      .catch(() => showError(FETCH_USER_DETAILS_MESSAGE));

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formValues = watch();
  const { newPassword, passwordMatch } = passwordWatch();

  const isIdentical = Object.keys(initialValues).every(
    (key) => formValues[key] === initialValues[key],
  );

  const isDisabled = isDetailsUpdating || !isLoaded || isIdentical;

  return {
    register,
    handleSubmit,
    handleCreateNewPassword,
    passwordRegister,
    onPasswordSubmit,
    passwordErrors,
    newPassword,
    passwordMatch,
    isDisabled,
    isDetailsUpdating,
    isPasswordUpdating,
    onSubmit,
    isLoaded,
  };
};
