import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Select, { components } from 'react-select';

const Option = ({ innerProps, ...props }) => {
  const { selectProps, value } = props;
  const tooltip = selectProps.tooltips?.find(
    (tooltip) => tooltip.value === value,
  );

  const extendedInnerProps = {
    ...innerProps,
    ...(!!tooltip && { title: tooltip.title }),
  };

  return <components.Option innerProps={extendedInnerProps} {...props} />;
};

export const ReactSelectEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    isPopup: () => true,
  }));

  const buttonElement =
    value?.length && value?.find((v) => typeof v === 'object');

  if (buttonElement) {
    setValue(value.filter((v) => v !== buttonElement));
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: '10px 28px 10px 10px',
      margin: '0 !important',
      width: '100%',
      textAlign: 'center',
    }),
  };

  return (
    <div style={{ width: props.column.actualWidth }}>
      <Select
        isMulti
        value={value}
        onChange={setValue}
        getOptionValue={(option) => option}
        getOptionLabel={(option) => option}
        options={props.values}
        components={{ Option }}
        tooltips={props.tooltips}
        styles={buttonElement ? customStyles : {}}
      />
    </div>
  );
});
