import { makeStyles } from 'tss-react/mui';

import { WHITE, PRIMARY_BLUE, WHITE_SMOKE, DARK_GREY } from 'utilities/colors';

export const useProjectsStyles = makeStyles()(() => ({
  agGridWrapper: {
    width: '100%',
    '.ag-root': { minHeight: 400 },
    '.ag-root .ag-root': { minHeight: 'unset' },
    '[ref=eMenu]': { display: 'none' },
    '.ag-header-row': {
      backgroundColor: PRIMARY_BLUE,
      fontSize: 14,
      color: WHITE,
    },
    '.ag-row-hover': { backgroundColor: `${PRIMARY_BLUE}44 !important` },
    '.ag-cell-focus': { borderColor: 'transparent !important' },
    '.ag-row-odd': {
      backgroundColor: `${PRIMARY_BLUE}16 !important`,
      '&.ag-row-hover': { backgroundColor: `${PRIMARY_BLUE}44 !important` },
    },
    '.ag-details-row': {
      '.ag-header-row': {
        backgroundColor: WHITE_SMOKE,
        color: DARK_GREY,
        fontSize: 12,
      },
    },
    '.ag-header-cell': {
      '.ag-icon': { color: WHITE },
    },
    '.ag-header-cell-label': { justifyContent: 'center' },
    '.ag-cell': {
      textAlign: 'center',
      '&:hover svg': { display: 'block' },
    },
    '& .context-font': {
      fontSize: '0.875rem',
      opacity: 0.6,
      display: 'flex',
      width: '1rem',
      height: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .ag-details-row-auto-height .ag-center-cols-viewport': {
      minHeight: 28,
    },
  },
  nameText: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  editIcon: {
    color: DARK_GREY,
    fontSize: 14,
    position: 'absolute',
    top: 6,
    right: -2,
    cursor: 'pointer',
    display: 'none',
    opacity: 0.4,
  },
  textField: {
    width: '100%',
    input: {
      padding: '4px 0 0',
      fontSize: 12,
    },
  },
  btnGroup: {
    display: 'flex',
    padding: '0 4px',
    cursor: 'pointer',
    svg: { fontSize: 14, color: DARK_GREY },
  },
}));
