import React from 'react';
import Select from 'react-select';
import { makeStyles } from 'tss-react/mui';
import { useController, useFormContext } from 'react-hook-form';
import { FormInputInfoTooltip } from 'components/OrderForm/components/FormInputInfoTooltip';
import { CheckmarkProvider } from 'components/OrderForm/components/CheckmarkProvider';
import { inputStyles, baseSelectStyles } from 'components/OrderForm/styles';

const useStyles = makeStyles()(inputStyles);

export const BaseOrderDropdownSelections = ({
  name,
  title,
  options,
  multi,
  closeMenuOnSelect,
  isDisabled,
  infoText,
  defaultValue,
  checkmark,
  children,
}) => {
  const { classes } = useStyles();

  const {
    control,
    formState: { submitCount },
  } = useFormContext();

  const {
    field: { onChange, onBlur, value },
    fieldState: { isTouched, error },
  } = useController({
    name,
    control,
  });

  const isValidated = isTouched || submitCount !== 0;
  const isDisplayValidation = isValidated && checkmark && !isDisabled;

  // do some slightly odd stuff to make the asterisks appear as red
  const isRequired = title.includes('(*)');
  const serializedTitle = title.replace('(*)', '');
  const styles = baseSelectStyles(!error, isDisplayValidation, isDisabled);

  return (
    <div>
      <label htmlFor={name}>
        <span className={classes.formLabel}>{serializedTitle}</span>
        {isRequired && <span className={classes.requiredAsterisk}>*</span>}
        {infoText && <FormInputInfoTooltip infoText={infoText} />}
      </label>
      <div className={isDisabled ? classes.disabledCursor : ''}>
        {children}
        <CheckmarkProvider
          isValid={!error}
          validated={isValidated}
          visible={isDisplayValidation}
        >
          <Select
            inputId={name}
            options={options}
            isMulti={multi}
            isDisabled={isDisabled}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            clearable={false}
            closeMenuOnSelect={closeMenuOnSelect}
            styles={styles}
            defaultValue={defaultValue}
          />
        </CheckmarkProvider>
      </div>

      {isDisplayValidation && error && (
        <div data-testid="error" className={classes.errorFeedback}>
          {error?.message}
        </div>
      )}
    </div>
  );
};
