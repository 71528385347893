import { makeStyles } from 'tss-react/mui';
import {
  LIGHT_GREY,
  TRANSPARENT_CAPTION_GREY_50,
  TRANSPARENT_FORM_GREY_50,
} from 'utilities/colors';

export const useBlanketPurchaseOrdersStyles = makeStyles()(() => ({
  noExistingPOLabel: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: TRANSPARENT_CAPTION_GREY_50,
    marginBottom: '0.5rem',
  },
  contactBillingTeamButton: {
    width: '100%',
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  },
  noDataContainer: {
    margin: '0 auto',
    width: '200px',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

export const useRemainingAmountIndicatorStyles = makeStyles()(
  (_theme, { fillPercent }) => ({
    containerBar: {
      backgroundColor: '#E8E8E8',
      borderRadius: '0.3rem',
      height: '0.625rem',
      width: '10rem',
    },
    fillBar: {
      backgroundColor: '#EC6A7A',
      borderRadius: '0.3rem',
      height: '100%',
      width: `${fillPercent}%`,
    },
  }),
);

export const useBlanketPurchaseOrderCardStyles = makeStyles()(() => ({
  remainingAmountMoneyCount: {
    fontSize: '1.25rem',
    fontWeight: 500,
    marginRight: '0.75rem',
  },
  selectedCard: {
    border: '1px solid #EC6A7A',
    backgroundColor: '#EC6A7A14',
  },
  checkboxContainer: {
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    height: '8rem',
    borderRadius: '12px',
    backgroundColor: '#F9F9F9',
    border: '1px solid #00000033',
    padding: '1.75rem',
    marginBottom: '1.25rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  remainingAmountSection: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  generalInfoSection: {
    alignItems: 'flex-end',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    marginLeft: 'auto',
  },
  label: {
    fontSize: '0.75rem',
  },
}));
