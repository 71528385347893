import { useSelector } from 'react-redux';
import { permissionsSelector } from 'store/slices/userDetailsSlice';

export const useCheckPermission = (permission) => {
  const permissions = useSelector(permissionsSelector);
  const hasPermission = permissions.some((p) => p.code === permission);

  return hasPermission;
};

export const useCheckPermissions = (permissions, needAll = false) => {
  const permissionsFromStore = useSelector(permissionsSelector);
  const hasPermissions = permissionsFromStore.filter((p) =>
    permissions.includes(p.code),
  );

  if (needAll) {
    return hasPermissions.length === permissions.length;
  }

  return hasPermissions.length > 0;
};
