import { OrderSlidesView } from 'components/OrderForm/SlideServicesForm/SlideServicesView';
import { OrderFormWizardNavigator } from 'components/OrderForm/WizardNavigator/WizardNavigatorView';
import React, { Fragment } from 'react';
import { SECONDARY_WHITE } from 'utilities/colors';

const PlaceOrderSlidesViewStyle = {
  height: '100vh',
  backgroundColor: SECONDARY_WHITE,
};
const FormWizardNavigatorStyle = { height: '20vh' };
const SlidesMarginTopStyle = { marginTop: '-1rem' };

export const PlaceOrderSlidesView = () => (
  <Fragment>
    <div style={PlaceOrderSlidesViewStyle}>
      <div style={FormWizardNavigatorStyle}>
        <OrderFormWizardNavigator />
      </div>
      <div style={SlidesMarginTopStyle}>
        <OrderSlidesView />
      </div>
    </div>
  </Fragment>
);
