import { Typography } from '@mui/material';
import path_map from 'images/orderForm/smartmockups_pathology_map_3.jpg';
import { DefaultModal } from 'components/Modals/DefaultModal';
import React, { Fragment } from 'react';
import cutting_instructions from 'images/orderForm/CuttingInstructions/cutting_instructions.png';
import { useInfoPageComponentsStyles } from './styles';

export const PathologyMapHelperModal = ({ open, onClose }) => {
  const { classes } = useInfoPageComponentsStyles();

  return (
    <DefaultModal open={open} onClose={onClose}>
      <Typography variant="h6" color={'inherit'} align={'center'}>
        What is{' '}
        <a
          href="https://home.histowiz.com/pathology_map/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pathology Map?
        </a>
      </Typography>
      <div className={classes.pathologyMapImage}>
        <a
          href="https://home.histowiz.com/pathology_map/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={path_map} width={'100%'} alt={'PathologyMap'} />
        </a>
      </div>
      <Typography
        variant={'body1'}
        gutterBottom
        style={{ marginBottom: '2rem' }}
      >
        Pathology Map is the world’s largest online preclinical histopathology
        database. Our database is composed of over <b>30,000</b> annotated whole
        slide images from <b>850+</b> top academic labs and biotechs around the
        world, and is growing at <b>200%</b> a year.
      </Typography>
      <Typography
        variant={'body1'}
        gutterBottom
        style={{ marginBottom: '2rem' }}
      >
        Contributing your order permits us to share your slides and metadata
        with members of the scientific community one year after you sign the
        agreement. Contributors receive an average of a <b>20%</b> discount for
        all services and free online viewing of their digital slides
        indefinitely. You can read the entire submission agreement when you
        submit your order.
      </Typography>
      <Typography variant={'body1'} gutterBottom>
        Contributors are required to fill out metadata associated with your
        samples that takes just an additional three minutes a sample.
      </Typography>
    </DefaultModal>
  );
};

export const CuttingInstructionsModal = ({ open, onClose }) => {
  const { classes } = useInfoPageComponentsStyles();

  return (
    <DefaultModal open={open} onClose={onClose}>
      <img
        src={cutting_instructions}
        className={classes.centerInfoImage}
        alt={'Grossing instructions'}
        height={'600px'}
        width={'750px'}
      />
    </DefaultModal>
  );
};
export const ContributorAndTwoDayConflict = () => {
  return (
    <p style={{ color: 'red' }}>
      ERROR: Pathology Map Contributor Orders Minimum Turnaround Time Is 3 Days.
    </p>
  );
};

export const InfoOrderFormHeader = () => {
  const { classes } = useInfoPageComponentsStyles();

  return (
    <Fragment>
      <a
        href={
          'https://storage.googleapis.com/production-histowiz-public/website/HistoWiz%20Sample%20Preparation%20and%20Shipping%20Instructions.pdf'
        }
        target={'_blank'}
        rel="noopener noreferrer"
      >
        <p className={classes.clickHereLink}>
          For shipping instructions, please click here.
        </p>
      </a>
      {/*<p style={{ color: "red" }}>All fields marked with * are REQUIRED.</p>*/}
    </Fragment>
  );
};
