import { useSamplesListStyles } from 'components/OrderDetails/orderDetailsSlidesStyles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { SamplesTable } from 'components/SamplesList/SampleTables';
import React from 'react';

export const SampleList = ({ samples }) => {
  const { classes } = useSamplesListStyles();
  const headerStyle = classes.alternativeHeader;
  /* eslint-disable-next-line*/
  let organsList = samples.map((sample) => {
    if (sample.organ) {
      return sample.organ.name;
    }
  });
  organsList = [...new Set(organsList.filter((x) => x))];

  /* eslint-disable-next-line*/
  let speciesList = samples.map((sample) => {
    if (sample.species) {
      return sample.species.name;
    }
  });
  speciesList = [...new Set(speciesList.filter((x) => x))];

  const organsListLabel = organsList.join(', ');
  const speciesListLabel = speciesList.join(', ');

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary className={headerStyle}>
        <Typography
          className={classes.accordionTitle}
          variant={'h5'}
          color={'inherit'}
        >
          Samples List ({samples.length}) - Species: {speciesListLabel}, Organ:{' '}
          {organsListLabel}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetails}>
        <SamplesTable samples={samples} />
      </AccordionDetails>
    </Accordion>
  );
};
