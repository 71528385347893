import { Grid, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import { SlideThumbnailWithInfo } from 'components/SlideGroups/Views/CardView/SlideThumbnailWithInfo';
import { useThumbnailsGroupPreviewStyles } from 'components/SlideGroups/utilities/styles';
import { prepareVisibleItems } from 'components/SlideGroups/utilities/utilities';

export const ThumbnailsGroupPreview = ({ slides }) => {
  const { classes } = useThumbnailsGroupPreviewStyles();

  const [visibleItems, setVisibleItems] = useState(prepareVisibleItems(slides));

  const handleRender = (uuid, isVisible) => {
    setVisibleItems((p) => {
      p[uuid] = isVisible;
      return { ...p };
    });
  };

  const countOfNotRenderedGroups = Object.values(visibleItems).filter(
    (item) => !item,
  ).length;

  return (
    <Fragment>
      {slides.length ? (
        <Box className={classes.root}>
          <Grid container className={classes.imagesWrapper}>
            {slides.map((slide) => (
              <SlideThumbnailWithInfo
                key={slide.uuid}
                handleRender={handleRender}
                slide={slide}
              />
            ))}
          </Grid>
          {!!countOfNotRenderedGroups && (
            <Box className={classes.moreSlidesCount}>
              <Typography>+{countOfNotRenderedGroups} more</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box className={classes.noSlidesContainer}>
          <Typography display={'inline'} variant={'h5'}>
            No slides
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};
