import {
  SHOW_TEAM_OVERVIEW_MODAL_FIELD,
  USER_SLIDES_SEARCH_MODAL_TUTORIAL_FIELD,
} from 'components/PathologyMap/constants/common';
import { SHOW_CHARTS_DEMO_MODAL_FIELD } from 'components/OrderSlidesChart/helpers/constants';

export const SLIDE_VIEWER_PIXELS_PER_METER = 3937007;
export const DEFAULT_OVERLAYS_NUMBER = 100;

export const ZOOM_LIST_LABELS = [1, 2, 4, 5, 10, 20, 40];

export const DEFAULT_ANNOTATION_DRAW_TOOL = 'rect';

export const SLIDE_VIEWER_LAYOUT_KEY = 'SLIDE_VIEWER_LAYOUT_KEY';
export const SLIDE_VIEWER_PREFERENCES = 'SLIDE_VIEWER_PREFERENCES';

export const NAVIGATOR_SWITCH_LABEL = 'Navigator';
export const THUMBNAIL_SWITCH_LABEL = 'Slide Label';
export const SHORTCUTS_PROMPT_SWITCH_LABEL = 'Shortcuts Prompt';

export const SLIDE_LIST_SWITCH_FIELD = 'show_slides_list';
export const SHORTCUTS_PROMPT_SWITCH_FIELD = 'shortcuts_prompt';
export const EXTERNAL_ANNOTATIONS_SWITCH_FIELD = 'show_external_annotations';
export const NAVIGATOR_SWITCH_FIELD = 'show_navigator';
export const THUMBNAIL_SWITCH_FIELD = 'show_thumbnail';
export const COMMENTS_SWITCH_FIELD = 'show_comments';
export const ANNOTATIONS_SWITCH_FIELD = 'show_annotations';
export const GT450_SWITCH_FIELD = 'show_GT450_filter';
export const SHOW_DRAWER_FIELD = 'show_drawer';
export const ANNOTATION_DRAW_TOOL_FIELD = 'annotationDrawTool';
export const ANNOTATION_DRAW_MODE_ENABLED_FIELD = 'annotationDrawModeEnabled';
export const GRID_LAYOUT_DRAGGABLE_FIELD = 'isLayoutDraggable';
export const DISTANCE_MEASURE_ENABLED_FIELD = 'isDistanceMeasureEnabled';
export const SLIDE_ANNOTATIONS_MODAL_TUTORIAL_FIELD =
  'showSlideAnnotationsTutorial';
export const MEASUREMENT_TOOL_MODAL_TUTORIAL_FIELD =
  'showMeasurementToolTutorial';
export const HALO_ANNOTATION_FIELD = 'halo_annotation';
export const UPLOAD_ANNOTATIONS_SUCCESS_MESSAGE =
  'Annotation(s) uploaded successfully';
export const UPLOAD_ANNOTATIONS_ERROR_MESSAGE = 'Failed to upload annotations';
export const DELETE_ANNOTATIONS_SUCCESS_MESSAGE =
  'Annotation deleted successfully';
export const DELETE_ANNOTATIONS_ERROR_MESSAGE = 'Failed to delete annotation';
export const FILE_TYPE_ERROR_MESSAGE =
  'Only GeoJSON or ANNOTATIONS files are allowed';
export const GEOJSON_FILE_EXTENSION = '.geojson';
export const HALO_ANNOTATIONS_FILE_EXTENSION = '.annotations';
export const IMAGE_SCOPE_ANNOTATIONS_FILE_EXTENSION = '.xml';
export const SVS_FILE_EXTENSION = '.svs';
export const NO_MATCHING_SLIDES_MESSAGE =
  'No matching slides found for the selected files';
export const SHOW_ANNOTATIONS_LIST_BUTTON_LABEL = 'Show Uploaded Annotations';
export const HIDE_ANNOTATIONS_LIST_BUTTON_LABEL = 'Hide Uploaded Annotations';

export const defaultShowPreferences = {
  [NAVIGATOR_SWITCH_FIELD]: true,
  [THUMBNAIL_SWITCH_FIELD]: true,
  [COMMENTS_SWITCH_FIELD]: true,
  [ANNOTATIONS_SWITCH_FIELD]: true,
  [SLIDE_LIST_SWITCH_FIELD]: true,
  [SHORTCUTS_PROMPT_SWITCH_FIELD]: true,
  [GT450_SWITCH_FIELD]: false,
  [SHOW_DRAWER_FIELD]: true,
  [ANNOTATION_DRAW_TOOL_FIELD]: DEFAULT_ANNOTATION_DRAW_TOOL,
  [ANNOTATION_DRAW_MODE_ENABLED_FIELD]: false,
  [GRID_LAYOUT_DRAGGABLE_FIELD]: false,
  [DISTANCE_MEASURE_ENABLED_FIELD]: false,
  [SLIDE_ANNOTATIONS_MODAL_TUTORIAL_FIELD]: true,
  [MEASUREMENT_TOOL_MODAL_TUTORIAL_FIELD]: true,
  [USER_SLIDES_SEARCH_MODAL_TUTORIAL_FIELD]: true,
  [SHOW_TEAM_OVERVIEW_MODAL_FIELD]: true,
  [SHOW_CHARTS_DEMO_MODAL_FIELD]: true,
};

export const AT2_SLIDE_SCANNER_NAME = 'at2';
export const GT450_SLIDE_SCANNER_NAME = 'gt450';

// javascript gives us the key code typed, and we map Z to be the cancel
export const CANCEL_CURRENT_MEASURE_KEY_CODE = 'Z'.charCodeAt(0);

export const ANNOTATION_COMMENTS_INITIAL_OFFSET_Y = 24;
export const ANNOTATION_COMMENT_AUTHOR_OFFSET_Y = 4;
export const ANNOTATION_COMMENT_CONTENT_FONT = '24px serif';
export const ANNOTATION_COMMENT_AUTHOR_FONT = '12px serif';
export const MAX_COMMENT_ONE_LINE_LENGTH = 32;
export const ANNOTATION_COMMENT_LINE_OFFSET_Y = 20;
export const ANNOTATION_COMMENT_NEW_COMMENT_OFFSET_Y = 30;
export const ANNOTATION_OBJECT_LINE_WIDTH = 3;
export const ANNOTATION_OBJECT_LINE_COLOR = 'black';
export const MIN_SCORE_TO_FAIL_QC_ML = 0.75;
export const MAX_SCORE_TO_SUCCESS_QC_ML = 0.25;
export const ML_QC_OVERLAY_PADDING_COEFFICIENT = 0.03;

export const OSD_FABRIC_RESIZES_DEBOUNCE_TIME = 0;
export const DEFAULT_VIEWER_ANGLE = 180;

export const SLIDE_VIEWER_ICON_ZOOM_IN_TOOLTIP = 'Zoom In';
export const SLIDE_VIEWER_ICON_ZOOM_OUT_TOOLTIP = 'Zoom Out';
export const SLIDE_VIEWER_ICON_ROTATE_LEFT_TOOLTIP = 'Rotate Left';
export const SLIDE_VIEWER_ICON_ROTATE_RIGHT_TOOLTIP = 'Rotate Right';
export const SLIDE_VIEWER_SET_TO_DEFAULT = 'Set To Default';
export const SLIDE_VIEWER_VIEW_WHOLE_SLIDE_TOOLTIP = 'View Whole Slide';

export const SLIDES_FOR_TEMP_GROUP = 'slidesForTempGroup';
export const TEMP_GROUP_TITLE = 'Untitled Group';
export const TEMP_GROUP_PLACEHOLDER = 'temp';

export const DIMENSIONS_MENU = [
  {
    title: 'Small',
    width: 1024,
    height: 768,
    multiply: 0.5,
  },
  {
    title: 'Medium',
    width: 1280,
    height: 720,
    multiply: 2,
  },
  {
    title: 'Large',
    width: 1920,
    height: 1080,
    multiply: 3,
  },
  {
    title: '4K',
    width: 3840,
    height: 2160,
    multiply: 6, //4K = 2x from HD (medium)
  },
  {
    title: 'Original Size',
    multiply: null,
  },
];

export const ROTATE_DIRECTIONS = {
  RIGHT: {
    name: 'right',
    className: 'fa fa-redo',
  },
  LEFT: {
    name: 'left',
    className: 'fa fa-undo',
  },
};

export const ANNOTATION_TYPES = {
  ARROW: '3',
  ELLIPSE: '2',
};
