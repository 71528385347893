import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import { Counter } from 'components/SlideGroups/components/Counter';
import { YELLOW_MARKED } from 'components/utilities/constants';
import StarIcon from '@mui/icons-material/StarRounded';

export const slideGroupDetailsModalStyles = () => ({
  headerButton: {
    marginLeft: '1rem',
    cursor: 'pointer',
  },
  groupNameInput: {
    marginBottom: '0.5rem',
  },
  slidesLabel: {
    fontSize: '0.875rem',
    marginRight: '0.75rem',
    marginBottom: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    marginBottom: 0,
    marginLeft: '1rem',
  },
  actionIcon: {
    opacity: 0.2,
    cursor: 'pointer',
    marginLeft: '0.5rem',
  },
});

const useSlideGroupDetailsModalStyles = makeStyles()(
  slideGroupDetailsModalStyles,
);

export const FavoriteSlideGroupHeader = ({ slideGroup }) => {
  const { classes } = useSlideGroupDetailsModalStyles();

  return (
    <Fragment>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <Box className={classes.row}>
          <Typography variant="h6" gutterBottom className={classes.headerText}>
            Favorites
          </Typography>
          <StarIcon htmlColor={YELLOW_MARKED} />
        </Box>
        <Box className={classes.row}>
          <Typography variant="h6" gutterBottom className={classes.slidesLabel}>
            SLIDES
          </Typography>
          <Counter count={slideGroup.slides.length} />
        </Box>
      </Box>
    </Fragment>
  );
};
