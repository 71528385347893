import React, { Fragment, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Card } from 'components/common/Card/Card';
import { DefaultSlideGroupHeader } from 'components/SlideGroups/components/DefaultSlideGroupHeader';
import { FavoriteSlideGroupHeader } from 'components/SlideGroups/components/FavoriteGroupHeader';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from 'store/slices/userDetailsSlice';
import {
  handleClickOnSlideGroupCard,
  slidesSorter,
} from 'components/SlideGroups/utilities/utilities';
import { SlidesSort } from 'components/SlideGroups/Views/ListView/SlidesSort';
import Box from '@mui/material/Box';
import { CREATED_AT_SORT_FIELD } from 'components/UserProfile/constants';
import { FAVORITE_SLIDES_GROUP, sortOrder } from 'utilities/constants';
import { ExpandableSlidesList } from 'components/SlideGroups/Views/ListView/ExpandableSlidesList';
import { sortArray } from 'utilities/general';

export const SlideGroupListView = ({ slideGroup, classes }) => {
  const { slides, name } = slideGroup;
  const isFavorite = name === FAVORITE_SLIDES_GROUP;
  const { isStaff } = useSelector(userDetailsSelector);

  const [sort, setSort] = useState({
    field: CREATED_AT_SORT_FIELD,
    order: sortOrder.DESC,
  });

  const sortedSlides = useMemo(
    () => sortArray(slides, slidesSorter, sort),
    [sort, slides],
  );

  return (
    <Grid item xs={12}>
      <Card
        headerContent={
          isFavorite ? (
            <FavoriteSlideGroupHeader slideGroup={slideGroup} />
          ) : (
            <DefaultSlideGroupHeader
              slideGroup={slideGroup}
              redirectAction={() =>
                handleClickOnSlideGroupCard(slideGroup, isStaff)
              }
            />
          )
        }
        bodyContent={
          sortedSlides?.length ? (
            <Fragment>
              <SlidesSort sort={sort} setSort={setSort} />
              <ExpandableSlidesList slides={sortedSlides} classes={classes} />
            </Fragment>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="4rem"
            >
              <Typography>No slides</Typography>
            </Box>
          )
        }
      />
    </Grid>
  );
};
