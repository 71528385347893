import { Button } from '@mui/material';
import { ProcessingIcon } from 'components/pages/AppLibrary/Icons/ProcessingIcon';

export const ProcessingButton = ({ onClick, disabled, className }) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant="contained"
      startIcon={<ProcessingIcon />}
      disabled={disabled}
    >
      VIEW IN PROCESSING
    </Button>
  );
};
