// Used as a one-off for ThermoFisher to View
import React from 'react';
import { MiniDrawerWithContext } from '../Layout/drawer';
import { withStyles } from 'tss-react/mui';
import { MultiSlideView } from 'components/ImageViewer/MultiSlideView';

const styles = () => ({
  viewer: {
    height: '90vh',
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    marginRight: 0,
    paddingRight: 0,
  },
});

const _MultiSlidePage = ({ classes }) => (
  <MiniDrawerWithContext header={'Multi Slide Viewer'}>
    <div className={classes.viewer}>
      <MultiSlideView />
    </div>
  </MiniDrawerWithContext>
);

export const MultiSlidePage = withStyles(_MultiSlidePage, styles);
