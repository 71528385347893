import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadResourceByUrl } from 'utilities/download';

export const DownloadLink = ({ text, url, urlAttribute, id }) => {
  const handleClick = () => {
    downloadResourceByUrl(url, urlAttribute);
  };

  return (
    <span onClick={handleClick} id={id}>
      <Tooltip disableInteractive title={text} placement="right">
        <ListItem button>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      </Tooltip>
    </span>
  );
};
