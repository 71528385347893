import { Typography, Box } from '@mui/material';
import {
  actionTabs,
  actionTabsIcons,
  AI_TOOLS_TAB,
  ANNOTATIONS_TAB,
  SLIDES_LIST_TAB,
} from 'components/OrderSlidesList/SlideActionTabs/constants';
import { useStyles } from 'components/OrderSlidesList/SlideActionTabs/ActionTabsHeader/styles';
import { AI_PERMISSIONS } from 'constants/permissions';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isStaffSelector } from 'store/slices/userDetailsSlice';
import { useCheckPermissions } from 'utilities/hooks/useCheckPermission/useCheckPermission';

export const TabsHeader = ({
  activeTab,
  setActiveTab,
  showList,
  disabledTabs,
  isDisabled = false,
  isMultipleSelected,
}) => {
  const { classes } = useStyles();
  const isStaff = useSelector(isStaffSelector);
  const hasAIPermissions = useCheckPermissions(AI_PERMISSIONS);

  const tabs = useMemo(
    () =>
      Object.keys(actionTabs).filter(
        (tab) =>
          (tab !== SLIDES_LIST_TAB || showList) &&
          (tab !== AI_TOOLS_TAB || isStaff || hasAIPermissions) &&
          (tab !== ANNOTATIONS_TAB || !isMultipleSelected),
      ),
    [showList, isStaff, isMultipleSelected, hasAIPermissions],
  );

  const tabClassName = (tab) =>
    actionTabs[tab] === activeTab
      ? `${classes.tab} ${classes.activeTab}`
      : classes.tab;

  const isTabDisabled = (tab) => disabledTabs.includes(actionTabs[tab]);

  const handleTabClick = (tab) => {
    if (!isTabDisabled(tab)) {
      setActiveTab(actionTabs[tab]);
    }
  };

  const headerClass = `${classes.root} ${
    isDisabled ? classes.disabledTabHeader : ''
  }`;

  return (
    <Box className={headerClass}>
      {tabs.map((tab) => (
        <Box
          className={`${tabClassName(tab)} ${
            isTabDisabled(tab) ? classes.disabledTab : ''
          }`}
          key={tab}
          title={actionTabs[tab]}
          onClick={() => handleTabClick(tab)}
          disabled={disabledTabs.includes(actionTabs[tab])}
        >
          {actionTabsIcons[tab]}
          <Typography component="span" className={classes.label}>
            {actionTabs[tab]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export const ActionTabsHeader = memo(TabsHeader);
