import { Grid } from '@mui/material';
import { PathologyMapSlideCardItem } from 'components/PathologyMap/PathologyMapSlideCards/Views/CardView/PathologyMapSlideCardItem';

export const SlidesList = ({ classes, slides, resultsCount, isAppLibrary }) => (
  <Grid
    container
    spacing={3}
    alignItems="stretch"
    className={classes.cardsWrapper}
  >
    {slides?.map((slide, index) => (
      <Grid item xs={6} laptop={4} display="flex" key={slide.uuid}>
        <PathologyMapSlideCardItem
          index={index}
          resultsCount={resultsCount}
          slide={{ ...slide, order_id: slide.order.id }}
          selectedFilters={[]}
          isAppLibrary={isAppLibrary}
        />
      </Grid>
    ))}
  </Grid>
);
