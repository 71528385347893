import Dropzone from 'react-dropzone';
import { Box, Button } from '@mui/material';
import React from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import { useDropzoneStyles } from './styles';

const RenderDefaultFileAttachmentsTextInstructions = () => {
  return (
    <Box
      sx={{
        p: 2,
        border: '1px dashed grey',
        width: '100%',
      }}
    >
      <Button startIcon={<UploadIcon />}>Choose a file(s) to upload</Button>
    </Box>
  );
};

export const OrderFileAttachmentsDropzone = ({ onFilesDrop, children }) => {
  const { classes, cx } = useDropzoneStyles();

  return (
    <div className={classes.dropZoneContainer}>
      <Dropzone
        className={classes.dropZone}
        onDrop={onFilesDrop}
        multiple={true}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={cx('dropzone', {
                'dropzone--isActive': isDragActive,
              })}
            >
              <input {...getInputProps()} />
              {children
                ? children
                : RenderDefaultFileAttachmentsTextInstructions()}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};
