import React, { memo } from 'react';
import {
  getClearedFiltersAttributes,
  getSlideAttributesFromSlide,
} from 'components/PathologyMap/utilities/common';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { InfoRow } from 'components/PathologyMap/PathologyMapSlideCards/Views/ListView/InfoRow';

export const ListItem = memo(({ slide, selectedFilters, classes }) => {
  const slideData = getSlideAttributesFromSlide({ slide });

  const keys = slideData.map((data) => data.label);
  const values = slideData.map((data) => data.value);

  const clearedFiltersAttributes = getClearedFiltersAttributes(selectedFilters);

  const highlightedKeysIndexes = slideData
    .map(({ attribute }, index) =>
      !!clearedFiltersAttributes.includes(attribute) ? index : null,
    )
    .filter((item) => !!item);

  return (
    <Box className={classes.slideCardsContainer}>
      <Grid
        item
        className={`${classes.lineContainer} ${classes.headingLine}`}
        xs={12}
      >
        <InfoRow
          classes={classes}
          values={keys}
          highlightedKeysIndexes={highlightedKeysIndexes}
        />
      </Grid>
      <Grid item className={classes.lineContainer} xs={12}>
        <InfoRow
          isCopyEnabled={true}
          classes={classes}
          values={values}
          highlightedKeysIndexes={highlightedKeysIndexes}
        />
      </Grid>
    </Box>
  );
});
