import { Button, Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { ExcelIcon } from 'components/SampleEditor/icons/ExcelIcon';

import {
  useImportFileSamplesMutation,
  useLazyGetSamplesPaginatedQuery,
} from 'store/apis/samplesApi';
import { IMPORT_SAMPLES_FILE_SUCCESS_MESSAGE } from 'constants/successMessages';
import {
  EXPORT_SAMPLES_FILE_ERROR_MESSAGE,
  IMPORT_SAMPLES_FILE_ERROR_MESSAGE,
  WRONG_FILE_TYPE_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { Spinner } from 'components/Loading/Spinner/Spinner';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { getAllGridData } from 'components/OrderForm/SamplesFormV2/utilities';
import { getExportParams } from 'components/OrderSlidesList/BaseOrderSlidesListView';
import { NOTE_EXCEL_SAMPLES } from 'components/OrderForm/SamplesFormV2/constants';
import {
  buildSamplesState,
  formatDisplayEmptyValue,
} from 'components/SampleEditor/helpers/functions';
import { useExcelStyles } from 'components/SampleEditor/components/styles';
import { useLocation } from 'react-router-dom';
import { SAMPLE_EDITOR_URL } from 'constants/urls';
import { ExportAllGrid } from 'components/SampleEditor/ExportAllGrid';

export const ExcelActionButtons = ({
  setSelectedRows = () => {},
  isDisabledExport = false,
  gridAPI = false,
  fileName = '',
  sheetName = 'Samples',
  refetchHandler = () => {},
  disabled = false,
  fullColumns = [],
}) => {
  const { classes } = useExcelStyles();
  const fileInputRef = useRef(null);

  const { pathname } = useLocation();

  const isSampleEditorPage = pathname.includes(SAMPLE_EDITOR_URL);

  const [file, setFile] = useState(null);
  const [exportGridApi, setExportGridApi] = useState(null);
  const [isGenerating, setIsGenerating] = useState();

  const [importFileSamples, { isLoading: isUploading }] =
    useImportFileSamplesMutation();
  const [getExportSamples] = useLazyGetSamplesPaginatedQuery();

  const { showError, showSuccess } = useSnackbar();

  const exportSamplesCount = gridAPI?.getSelectedRows().length;

  const importHandler = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    event.target.value = '';
  };

  const deselectHandler = () => {
    const selectedRows = gridAPI.getSelectedRows();
    setSelectedRows([]);
    gridAPI.deselectAll();
    gridAPI.redrawRows(selectedRows);
  };

  const baseEexportParams = {
    ...getExportParams(fileName),
    shouldRowBeSkipped: (params) => {
      return !params.node.data.name;
    },
    allColumns: false,
    sheetName,
    prependContent: NOTE_EXCEL_SAMPLES,
    processCellCallback: ({ value }) => formatDisplayEmptyValue(value),
  };

  const exportHandler = () => {
    if (!gridAPI) return;
    const selectedRows = gridAPI.getSelectedRows();
    const allTableData = getAllGridData(gridAPI);

    if (!allTableData.length) return;

    //do not export first empty column with checkbox
    const columns = gridAPI.columnModel.displayedColumns.slice(1);
    const uuidColumn = gridAPI.columnModel.gridColumns.find(
      (column) => column.colId === 'uuid',
    );

    const exportParams = {
      ...baseEexportParams,
      onlySelected: !!selectedRows.length,
      columnKeys: [...columns, uuidColumn],
    };

    gridAPI.exportDataAsExcel(exportParams);
    deselectHandler();
  };

  const exportAllHandler = async () => {
    setIsGenerating(true);
    const { data, error } = await getExportSamples({
      page: null,
      limit: 100000000,
    });

    if (error) {
      showError(EXPORT_SAMPLES_FILE_ERROR_MESSAGE);
      setIsGenerating(false);
      return;
    }

    if (!exportGridApi) return;

    const samples = data?.results;

    exportGridApi.gos.api.setRowData(buildSamplesState(samples ?? []));

    const allTableData = getAllGridData(exportGridApi);

    if (!allTableData.length) return;

    //do not export first empty column with checkbox
    const columns = exportGridApi.columnModel.displayedColumns.slice(1);
    const uuidColumn = exportGridApi.columnModel.gridColumns.find(
      (column) => column.colId === 'uuid',
    );

    const exportParams = {
      ...baseEexportParams,
      onlySelected: false,
      columnKeys: [...columns, uuidColumn],
    };

    exportGridApi.exportDataAsExcel(exportParams);
    setIsGenerating(false);
  };

  const uploadXLSX = () => {
    const formData = new FormData();
    formData.append('file', file);

    importFileSamples(formData)
      .unwrap()
      .then(() => {
        refetchHandler?.();
        showSuccess(IMPORT_SAMPLES_FILE_SUCCESS_MESSAGE);
      })
      .catch(() => {
        showError(IMPORT_SAMPLES_FILE_ERROR_MESSAGE);
      })
      .finally(() => {
        setFile(null);
      });
  };

  useEffect(() => {
    if (!file) return;
    const isExcelFormat = file.name.split('.')[1].includes('xls');

    if (!isExcelFormat) {
      showError(WRONG_FILE_TYPE_ERROR_MESSAGE);
      setFile(null);
      return;
    }
    uploadXLSX();
  }, [file]);

  const exportButtonTitle = ` EXPORT ${exportSamplesCount || ''} SAMPLE${
    exportSamplesCount === 1 ? '' : 'S'
  } TO XLSX`;

  return (
    <Box className={classes.flexWrapper}>
      {isSampleEditorPage && (
        <>
          <ExportAllGrid
            setExportGridApi={setExportGridApi}
            fullColumns={fullColumns}
          />
          <Button
            startIcon={
              isGenerating ? (
                <Spinner size={20} />
              ) : (
                <ExcelIcon disabled={isGenerating} />
              )
            }
            variant="contained"
            color="secondary"
            className={classes.excelButton}
            disabled={isGenerating}
            onClick={exportAllHandler}
          >
            {isGenerating ? 'GENERATING' : 'EXPORT ALL SAMPLES TO XLSX'}
          </Button>
        </>
      )}

      <Button
        startIcon={<ExcelIcon disabled={isDisabledExport || disabled} />}
        variant="contained"
        color="secondary"
        className={classes.excelButton}
        disabled={isDisabledExport || disabled}
        onClick={exportHandler}
      >
        {exportButtonTitle}
      </Button>
      <Button
        startIcon={<ExcelIcon disabled={isUploading || disabled} />}
        variant="contained"
        color="secondary"
        className={classes.excelButton}
        onClick={importHandler}
        disabled={isUploading || disabled}
        endIcon={isUploading && <Spinner size={20} />}
      >
        IMPORT XLSX TO SAMPLES
      </Button>
      <input
        onChange={handleChange}
        type="file"
        ref={fileInputRef}
        className={classes.excelInput}
      />
    </Box>
  );
};
