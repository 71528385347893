import { createSlice } from '@reduxjs/toolkit';

export const slideCommentsSlice = createSlice({
  name: 'slideComments',
  initialState: {},
  reducers: {
    setSlideComments: (state, { payload }) => {
      const { data, queryArgs } = payload;
      state[queryArgs?.slideUUID] = data;
    },
    createSlideComment: (state, { payload }) => {
      const { data } = payload;
      const slideUUID = data.slide.uuid;
      state[slideUUID].push(data);
    },
    deleteSlideComment: (state, { payload }) => {
      const { queryArgs } = payload;
      const comments = state[queryArgs.slideUUID];
      const deletedCommentIDX = comments.findIndex(
        (comment) => comment.uuid === queryArgs.commentUUID,
      );
      comments.splice(deletedCommentIDX, 1);
    },
  },
});

export const { setSlideComments, createSlideComment, deleteSlideComment } =
  slideCommentsSlice.actions;

export const slideCommentsReducer = slideCommentsSlice.reducer;

export const slideCommentsSelector = (slideUUID) => (state) =>
  state.slideComments[slideUUID] || [];
