import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TRANSPARENT_BLACK } from 'utilities/colors';

const CustomDatePicker = ({ value, onChange, label, minDate, maxDate }) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      slotProps={{
        textField: {
          variant: 'outlined',
          sx: {
            '& .MuiOutlinedInput-root': {
              width: '10rem',
              maxHeight: '2.75rem',
              color: TRANSPARENT_BLACK,
              '& fieldset': {
                borderColor: TRANSPARENT_BLACK,
              },
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'secondary',
              },
              '& .MuiSvgIcon-root': {
                color: TRANSPARENT_BLACK,
              },
            },
          },
        },
      }}
    />
  );
};

export const DatePickers = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date && endDate && date > endDate) {
      setEndDate(null);
    }
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (date && startDate && date < startDate) {
      setStartDate(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomDatePicker
        value={startDate}
        onChange={handleStartDateChange}
        maxDate={endDate}
        label="Start Date"
      />
      <CustomDatePicker
        value={endDate}
        onChange={handleEndDateChange}
        label="End Date"
        minDate={startDate}
      />
    </LocalizationProvider>
  );
};
