import React, { useState } from 'react';
import { TextField } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { useUpdateProjectMutation } from 'store/apis/projectsApi';
import { useProjectsStyles } from 'components/Projects/styles';
import { ButtonGroup } from 'components/Projects/NameEdit/ButtonGroup';
import { KEYS } from 'constants/keyboard';

export const NameEdit = ({ name, uuid }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [nameValue, setNameValue] = useState(name);
  const [mutation] = useUpdateProjectMutation();

  const { classes } = useProjectsStyles();
  const { editIcon, nameText, textField } = classes;

  const handleSubmit = () => {
    setIsEditMode(false);
    mutation({ uuid, body: { name: nameValue } });
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setNameValue(name);
  };

  return isEditMode ? (
    <TextField
      value={nameValue}
      className={textField}
      onChange={({ target }) => setNameValue(target.value)}
      onKeyDown={({ key }) => key === KEYS.ENTER && handleSubmit()}
      InputProps={{
        endAdornment: (
          <ButtonGroup
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
          />
        ),
      }}
    />
  ) : (
    <span className={nameText}>
      {nameValue}
      <ModeEditIcon onClick={() => setIsEditMode(true)} className={editIcon} />
    </span>
  );
};
