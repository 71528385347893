import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, TextField } from '@mui/material';
import {
  getAllOrdersIds,
  getOrderIDtoUUIDMapping,
} from 'services/resources/orders';
import {
  IHC_OPTIMIZATION_URL,
  ORDER_DETAILS_URL,
  PLACE_ORDER_INFO_URL,
} from 'constants/urls';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  COMMON_ERROR_MESSAGE,
  FETCH_ORDER_IDS_INFO_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { KEYS } from 'constants/keyboard';
import { useGoToOrderComponentStyles } from './styles';

const filterOptions = createFilterOptions({
  limit: 300,
  matchFrom: 'start',
});

export const GoToOrderComponent = () => {
  const { classes } = useGoToOrderComponentStyles();
  const history = useHistory();
  const { showError } = useSnackbar();

  const [orderID, setOrderId] = useState('');
  const [orders, setOrders] = useState([]);

  const [shouldNavigateOnOrderUpdate, setShouldNavigateOnOrderUpdate] =
    useState(false);

  const [shouldGetOrders, setShouldGetOrders] = useState(false);
  const shouldNavigationBeAvailable = () => orders.includes(orderID);

  useEffect(() => {
    if (shouldGetOrders && orderID.length) {
      const orderIdStartsWith = orderID[0];

      getAllOrdersIds(orderIdStartsWith)
        .then((orders) => {
          setOrders(orders.map((order) => order.toString()));
        })
        .catch(() => showError(FETCH_ORDER_IDS_INFO_ERROR_MESSAGE));

      setShouldGetOrders(false);
    }
  }, [orderID, shouldGetOrders]);

  useEffect(() => {
    if (!orderID) {
      setOrders([]);
    }
  }, [orderID]);

  const navigateByOrderID = useCallback(
    (path) => {
      getOrderIDtoUUIDMapping({ orderID })
        .then((updatedUUID) => {
          const url = path.replace(':orderUUID', updatedUUID);
          history.push(url);
        })
        .catch(() => showError(COMMON_ERROR_MESSAGE));
    },
    [orderID],
  );

  useEffect(() => {
    if (shouldNavigateOnOrderUpdate) {
      navigateByOrderID(ORDER_DETAILS_URL);
      setShouldNavigateOnOrderUpdate(false);
    }
  }, [shouldNavigateOnOrderUpdate, navigateByOrderID]);

  const navigateToOrderOnLIS = () => {
    const adminPath = process.env.REACT_APP_API_URL;
    const adminURL = `${adminPath}/lisa/core/order/${orderID}/change/`;
    window.open(adminURL, '_blank');
  };

  const handleOrderInputKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault();

      if (shouldNavigationBeAvailable()) {
        navigateByOrderID(ORDER_DETAILS_URL);
      }
    }
  };

  const handleOrderIDChange = (e, value) => {
    setOrderId(value);

    const needOrdersUpdated = orderID[0] !== value[0];

    if (needOrdersUpdated) {
      setShouldGetOrders(true);
    }
  };

  const handleAutocompleteChanged = (e, value, reason) => {
    if (
      e.type === 'keydown' &&
      reason === 'selectOption' &&
      e.key === KEYS.ENTER
    ) {
      setShouldNavigateOnOrderUpdate(true);
    }
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        value={orderID}
        label="Jump to Order"
        variant="outlined"
        className={classes.autocompleteField}
      />
    );
  };

  return (
    <Fragment>
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onKeyPress={null}
        onSubmit={null}
      >
        <Autocomplete
          disableClearable
          clearOnBlur={false}
          options={orders}
          filterOptions={filterOptions}
          renderInput={renderInput}
          onInputChange={handleOrderIDChange}
          noOptionsText="Please Enter Order ID"
          className={classes.autoComplete}
          onKeyPress={handleOrderInputKeyPress}
          onChange={handleAutocompleteChanged}
        />

        <Button
          variant="contained"
          color="primary"
          size={'small'}
          className={classes.navigateOrderButton}
          onClick={() => navigateByOrderID(ORDER_DETAILS_URL)}
          disabled={!shouldNavigationBeAvailable()}
        >
          App - Order Details
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.navigateOrderButton}
          onClick={() => navigateByOrderID(PLACE_ORDER_INFO_URL)}
          disabled={!shouldNavigationBeAvailable()}
        >
          App - Order Form
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.navigateOrderButton}
          onClick={navigateToOrderOnLIS}
          disabled={!shouldNavigationBeAvailable()}
        >
          LIS
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.navigateOrderButton}
          onClick={() => navigateByOrderID(IHC_OPTIMIZATION_URL)}
          disabled={!shouldNavigationBeAvailable()}
        >
          IHC Optimizations
        </Button>
      </form>
    </Fragment>
  );
};
