import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { AddShoppingCart } from '@mui/icons-material';

export const AntibodySummary = ({
  selectedAntibody,
  handleThumbnail,
  thumbnail,
  classes,
  isFromOrderForm,
  handleAddToOrder,
  selectedRowsCount,
}) => {
  const {
    clone_number,
    clonality,
    host_species,
    histowiz_validated_species,
    reactive_species,
    applications,
    display_name,
    description,
    alternate_catalog_names: altNames,
  } = selectedAntibody;

  const {
    rowStyle,
    cellStyle,
    thumbnailStyle,
    ABSummaryStyle,
    centered,
    ABDescription,
  } = classes;

  const stringifyNames = (arr) => arr.map(({ name }) => name).join(', ');

  const renderAddButton = (className) => (
    <Button
      onClick={() => handleAddToOrder([selectedAntibody])}
      className={className}
      startIcon={<AddShoppingCart />}
      disabled={selectedRowsCount !== 1}
    >
      Add to Order
    </Button>
  );

  const ABDescriptionClassName = `${cellStyle} ${ABDescription}`;

  return (
    <Box className={ABSummaryStyle}>
      <Table>
        <TableBody>
          <TableRow className={rowStyle}>
            <TableCell className={cellStyle}>Clone Number:</TableCell>
            <TableCell className={cellStyle}>{clone_number || 'N/A'}</TableCell>
          </TableRow>
          <TableRow className={rowStyle}>
            <TableCell className={cellStyle}>Clonality:</TableCell>
            <TableCell className={cellStyle}>{clonality}</TableCell>
          </TableRow>
          <TableRow className={rowStyle}>
            <TableCell className={cellStyle}>Host Species:</TableCell>
            <TableCell className={cellStyle}>{host_species?.name}</TableCell>
          </TableRow>
          <TableRow className={rowStyle}>
            <TableCell className={cellStyle}>
              Histowiz Validated Species:
            </TableCell>
            <TableCell className={cellStyle}>
              {stringifyNames(histowiz_validated_species)}
            </TableCell>
          </TableRow>
          <TableRow className={rowStyle}>
            <TableCell className={cellStyle}>
              Vendor Reactive Species:
            </TableCell>
            <TableCell className={cellStyle}>
              {stringifyNames(reactive_species)}
            </TableCell>
          </TableRow>
          <TableRow className={rowStyle}>
            <TableCell className={cellStyle}>Application:</TableCell>
            <TableCell className={cellStyle}>
              {stringifyNames(applications)}
            </TableCell>
          </TableRow>
          {!!altNames?.length && (
            <TableRow className={rowStyle}>
              <TableCell className={cellStyle}>Alternate Names:</TableCell>
              <TableCell className={cellStyle}>{altNames.join(', ')}</TableCell>
            </TableRow>
          )}
          {description && (
            <TableRow className={rowStyle}>
              <TableCell className={ABDescriptionClassName}>
                Description:
              </TableCell>
              <TableCell className={ABDescriptionClassName}>
                {description}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!thumbnail && isFromOrderForm && (
        <Box display="flex" justifyContent="center" width="100%">
          {renderAddButton(
            `${classes.addToOrderButton} ${classes.centeredButton}`,
          )}
        </Box>
      )}
      {thumbnail && (
        <Box className={centered}>
          <Typography mt={1}>{display_name}</Typography>
          <Button onClick={handleThumbnail} className={thumbnailStyle}>
            <img src={thumbnail} alt={display_name} />
          </Button>
          {isFromOrderForm && renderAddButton(classes.addToOrderButton)}
        </Box>
      )}
    </Box>
  );
};
