import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { getCurrentSlides } from 'components/BlocksList/utilities';

export const ImageSection = ({
  classes,
  selectedSlides,
  Content,
  title,
  slidesCount,
  onHeaderClick,
  currentSample,
}) => {
  const [thumbnailModalOpen, setThumbnailModalOpen] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [slideName, setSlideName] = useState(null);
  const handleThumbnailOpen = (thumbnail, slideName) => {
    setSlideName(slideName);
    setThumbnailUrl(thumbnail);
    setThumbnailModalOpen(true);
  };

  const handleThumbnailClose = () => {
    setThumbnailModalOpen(false);
    setThumbnailUrl(null);
    setSlideName(null);
  };

  let headerClassName = classes.slideSectionText;
  onHeaderClick && (headerClassName += ` ${classes.clickable}`);

  const slidesForCurrentSample = getCurrentSlides(
    selectedSlides,
    currentSample,
  );
  const currentSlidesCount = slidesForCurrentSample.length;

  return (
    <>
      <Box mb={1} ml={4}>
        {!!currentSlidesCount && Content && (
          <Box
            className={classes.imageSectionHeader}
            onClick={() => onHeaderClick(slidesForCurrentSample)}
          >
            <Typography className={headerClassName}>{title}</Typography>
            <Box className={classes.counter}>{currentSlidesCount}</Box>
          </Box>
        )}
        <Box mt={2} mr={4}>
          <Grid container spacing={2} className={classes.images}>
            {slidesForCurrentSample.map((slide, index) => (
              <Grid
                item
                xs={4}
                sm={3}
                md={4}
                key={slide.uuid}
                className={classes.slideCard}
              >
                {Content ? (
                  <>
                    <Content isBrief slide={slide} />
                    <Typography className={classes.slideName}>
                      <Typography
                        component="span"
                        className={classes.orderName}
                      >
                        {index + 1}. {slide.blockName} /{' '}
                      </Typography>
                      {slide.name}
                    </Typography>
                  </>
                ) : (
                  <Box className={classes.barcodeContainer}>
                    <img
                      src={slide.small_macro_url}
                      alt={slide.name}
                      onClick={() =>
                        handleThumbnailOpen(slide.large_macro_url, slide.name)
                      }
                      width="100%"
                    />
                  </Box>
                )}
                {!slidesCount && (
                  <Typography className={classes.barcodeText}>
                    {`${slide.projectName} / ${slide.blockName} / ${slide.name}`}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <DefaultModal
        open={thumbnailModalOpen}
        onClose={handleThumbnailClose}
        isLimitedHeight
      >
        <Box className={classes.centered}>
          <img
            className={classes.fullImage}
            src={thumbnailUrl}
            alt={slideName}
          />
        </Box>
      </DefaultModal>
    </>
  );
};
