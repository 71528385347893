import { makeStyles } from 'tss-react/mui';
import { HISTOWIZ_PRIMARY_COLOR, WARNING_RED } from 'utilities/colors';

export const useNotifyTeamMembersStyles = makeStyles()(() => ({
  root: {
    backgroundColor: 'red',
  },
  menuItemRoot: {
    marginInline: '0.25rem',
    marginBlock: '0.25rem',
    paddingInline: '0.5rem',
    display: 'flex',
  },
  selected: {
    backgroundColor: WARNING_RED,
    color: 'green',
  },
  chipContainer: {
    border: `1px solid ${HISTOWIZ_PRIMARY_COLOR}`,
    borderRadius: '3rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F9ECED',
    padding: '0.5rem 1rem',
    margin: '0.5rem',
    width: 'max-content',
  },
  chipIcon: {
    color: HISTOWIZ_PRIMARY_COLOR,
  },
  chipText: {
    fontSize: '1.125rem',
    lineHeight: 1.25,
    fontWeight: 500,
    color: HISTOWIZ_PRIMARY_COLOR,
    marginRight: '1rem',
  },
  disabledRow: {
    color: 'gray',
  },
}));
