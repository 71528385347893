import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/GetApp';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  imageIconWrapper: {
    marginBlock: '0.25rem',
  },
}));

export const ExportToExcelButton = ({
  onClick,
  isLoading = false,
  color = 'secondary',
  styles,
}) => {
  const { classes } = useStyles();

  return (
    <Button
      className={styles}
      variant="contained"
      color={color}
      disabled={isLoading}
      onClick={onClick}
    >
      <Box
        display="flex"
        alignItems="center"
        mr={1}
        className={classes.imageIconWrapper}
      >
        <DownloadIcon />
      </Box>
      Export to Excel
    </Button>
  );
};
