import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import {
  reportIssueSchema,
  reportIssueTypes,
} from 'components/AITools/constants/common';
import { useAIReportIssueStyles } from 'components/AITools/constants/styles';
import { BaseOrderDropdownSelections } from 'components/OrderForm/components/BaseSelectDropdowns';
import { TextFormInput } from 'components/OrderForm/components/InfoFormInputs';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const SlideAIReportIssue = ({ handleReportClose }) => {
  const { classes } = useAIReportIssueStyles();
  const [isContactSelected, setIsContactSelected] = useState(true);

  const methods = useForm({
    resolver: yupResolver(reportIssueSchema),
    mode: 'onBlur',
    defaultValues: {
      type: reportIssueTypes[0],
    },
  });

  const {
    register,
    formState: { errors, touchedFields, submitCount },
  } = methods;

  const defaultFormProps = {
    register,
    errors,
    checkmark: true,
    touched: touchedFields,
    submitCount,
  };

  const handleSubmit = (data) => {
    console.log(data);
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.backButton} onClick={handleReportClose}>
        <KeyboardArrowLeft /> BACK
      </Typography>
      <FormProvider {...methods}>
        <form
          className={classes.formContainer}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <Typography className={classes.title}>REPORT AN ISSUE</Typography>
          <Box mb={2}>
            <BaseOrderDropdownSelections
              name="type"
              title="Type of issue(*)"
              options={reportIssueTypes}
              checkmark
            />
          </Box>
          <TextFormInput
            {...defaultFormProps}
            formLabel="Describe the Issue"
            formPlaceholder="Describe the issue..."
            isRequired
            name="description"
            className={classes.textField}
          />
          <Typography className={classes.label}>
            What would you like to do next?
            <span className={classes.required}>*</span>
          </Typography>
          <Box className={classes.buttonGroup}>
            <Button
              variant="outlined"
              color={isContactSelected ? 'primary' : 'inherit'}
              className={classes.button}
              size="small"
              onClick={() => setIsContactSelected(true)}
            >
              Contact Support
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              color={!isContactSelected ? 'primary' : 'inherit'}
              size="small"
              onClick={() => setIsContactSelected(false)}
            >
              Request Refund
            </Button>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submitButton}
          >
            Submit
          </Button>
        </form>
      </FormProvider>
    </Box>
  );
};
