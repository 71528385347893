import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Input, Box, Stack, Typography } from '@mui/material';
import { getOrders, updateOrder } from 'services/resources/orders';
import { OrderFormWizardNavigator } from 'components/OrderForm/WizardNavigator/WizardNavigatorView';
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import CopyIcon from '@mui/icons-material/FileCopy';
import { FormProvider } from 'react-hook-form';
import {
  IS_TUMOR_FIELD_NAME,
  LAST_ORDER_FORM_PAGE,
  REQUESTED_ANTIBODIES_FIELD_VALUE,
  REQUESTED_PANELS_FIELD_VALUE,
  REQUESTED_SERVICES_FIELD_NAME,
  REQUESTED_SPECIAL_STAINS_FIELD_VALUE,
  SUBMITTING_ERROR_MESSAGE,
} from 'components/OrderForm/constants';
import {
  getAllGridData,
  getSamplesColumnsData,
  handleAddNewColumnOption,
  handleDynamicColumnsVisibility,
  cloneSamples,
  makeColumnsNonEditable,
} from 'components/OrderForm/SamplesFormV1/utilities';
import { SamplesStatusBar } from 'components/OrderForm/SamplesFormV1/components/SamplesStatusBar/SamplesStatusBar';
import { DefaultModal } from 'components/Modals/DefaultModal';
import {
  gridThemeStyle,
  useOrderFormSamplesV2Styles,
} from 'components/OrderForm/SamplesFormV1/styles/js/common';
import {
  samplesListValidation,
  yupSamplesValidation,
} from 'components/OrderForm/SamplesFormV1/validators';
import { getDuplicatesFromArray } from 'utilities/general';
import {
  PLACE_ORDER_PRICE_REVIEW_STEP_URL,
  PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL,
  PLACE_ORDER_SLIDES_STEP_URL,
} from 'constants/urls';
import {
  agGridDefaultColDef,
  exportToExcelAction,
  handleFillHandleDoubleClicked,
} from 'components/utilities/grid';
import { SamplesGridBottomButtons } from 'components/OrderForm/SamplesFormV1/components/SamplesGridBottomButtons';
import {
  RenderBackendValidationErrors,
  RenderDuplicateSameNames,
  RenderNoRowsError,
  RenderNoSpeciesInOrder,
  RenderValidationErrors,
} from 'components/OrderForm/SamplesFormV1/components/ValidationErrors';
import { useTitle } from 'components/utilities/hooks/useTitle';
import { CreatePanelModal } from 'components/Modals/CreatePanelModal';
import { CreateAntibodyModal } from 'components/Modals/CreateAntibodyModal';
import { debounce } from 'lodash';
import { SubmittingIcon } from 'components/icons/LoadingIcon';
import { useAGGridOverlayControl } from 'components/utilities/hooks/grid/useAGGridOverlayControl';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import {
  serializeRequestedAntibodiesOnSample,
  serializeRequestedPanelsOnSample,
  serializeRequestedServicesOnSample,
  serializeRequestedSpecialStainsOnSample,
  serializeSampleDetailsToBackendPostRequest,
  serializeScienceInfoOnSample,
} from 'components/OrderForm/SamplesFormV1/serializers';
import { useGetProjectsQuery } from 'store/apis/projectsApi';
import {
  DELETE_ORDER_ERROR_MESSAGE,
  FETCH_DATA_ERROR_MESSAGE,
  UPDATE_ORDER_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { ModalSize } from 'components/Modals/constants';
import { AddPreviousSamplesModal } from 'components/OrderForm/SamplesFormV1/components/SelectExistingSamplesModal/SelectExistingSamplesModal';
import { isStaffSelector } from 'store/slices/userDetailsSlice';

import { PleaseCallHistoWiz } from 'components/Shared/PleaseCallHistoWiz';
import { SamplesCustomTooltip } from 'components/OrderForm/SamplesFormV1/components/SamplesCustomTooltip';
import { ReactSelectEditor } from 'components/OrderForm/SamplesFormV1/components/ReactSelectEditor';
import { SamplesSubmitSaveErrorsModal } from 'components/OrderForm/SamplesFormV1/components/SamplesSubmitSaveErrorsModal';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { IF_SERVICE_TYPE } from 'components/OrderForm/SlideServicesForm/constants';
import { SelectedServiceWarning } from 'components/OrderForm/SamplesFormV1/components/SelectedServiceWarning';
import { finishedOrderStates } from 'components/OrdersList/constants';
import { BoneDecalcificationInput } from 'components/OrderForm/components/AdditionalQuestionsFormInput';
import {
  BONE_DECALCIFICATION,
  BONE_ORGAN,
  NON_VALID_SUBMISSIONS_FOR_BONE_ORGAN,
} from 'components/OrderForm/SamplesFormV1/constants';
import { useBoneDecalcificationForm } from 'components/OrderForm/SamplesFormV1/hooks';
import {
  useDeleteSamplesMutation,
  useLazyGetSamplesByOrderIdQuery,
  useLazyGetSamplesFromOrdersQuery,
  usePostSamplesMutation,
} from 'store/apis/samplesApi';
import { AddAntibodyButton } from 'components/OrderForm/components/AddAntibodyButton';
import { AntibodiesListModal } from 'components/OrderForm/components/AntibodiesListModal';

const frameworkComponents = {
  customTooltip: SamplesCustomTooltip,
  reactSelectEditor: ReactSelectEditor,
};
export const SamplesPageV1 = ({ orderUUID, order, isEditBlocked }) => {
  useTitle('Place Order - Samples');
  const { classes } = useOrderFormSamplesV2Styles();
  const history = useHistory();
  const unmountIgnore = useUnmountIgnore();
  const [rows, setRows] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [columns, setColumns] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isSamplesLoading, setIsSamplesLoading] = useState(false);
  const [isColumnsLoading, setIsColumnsLoading] = useState(false);
  const [gridAPI, setGridAPI] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [isAntibodiesListModalOpen, setIsAntibodiesListModalOpen] =
    useState(false);

  const [newSamplesText, setNewSamplesText] = useState('');
  const [addNewSamplesModalOpen, setAddSamplesModalOpen] = useState(false);
  const [addPreviousSamplesModalOpen, setAddPreviousSamplesModalOpen] =
    useState(false);

  const [errorsListModalOpen, setErrorsListModalOpen] = useState(false);
  const [validationErrorsFound, setValidationErrorsFound] = useState(false);
  const [duplicatedSampleNames, setDuplicatedSampleNames] = useState([]);
  const [backendIssueErrors, setBackendIssueErrors] = useState('');
  const [ordersAndSamples, setOrdersAndSamples] = useState([]);
  const [sampleSubmissions, setSampleSubmissions] = useState([]);
  const [previousOrders, setPreviousOrders] = useState([]);
  const { showError } = useSnackbar();

  const isStaff = useSelector(isStaffSelector);

  const { data: projects, isLoading: isProjectsLoading } =
    useGetProjectsQuery();
  const [getSamplesByOrderId] = useLazyGetSamplesByOrderIdQuery();
  const [getSamplesFromOrders, { data: samplesFromOrders }] =
    useLazyGetSamplesFromOrdersQuery();
  const [postSamples] = usePostSamplesMutation();
  const [deleteSamples] = useDeleteSamplesMutation();

  const { materials_at_histowiz: materialsAtHistowiz } = order || {};

  const handleAntibodiesListModalOpen = () =>
    setIsAntibodiesListModalOpen(true);
  const handleAntibodiesListModalClose = () =>
    setIsAntibodiesListModalOpen(false);

  const handleAddToOrder = (antibodies) => {
    setIsAntibodiesListModalOpen(false);
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      const antibodiesColumn = newColumns.find(
        (column) => column.field === REQUESTED_ANTIBODIES_FIELD_VALUE,
      );

      if (antibodiesColumn) {
        antibodies.forEach((antibody) => {
          antibodiesColumn.cellEditorParams.values.push(antibody.display_name);
        });
      }
      gridAPI.setColumnDefs(newColumns);

      return newColumns;
    });
    queueMicrotask(() =>
      gridColumnApi.setColumnVisible(REQUESTED_ANTIBODIES_FIELD_VALUE, true),
    );
  };

  const fetchOrdersAndSamples = async () => {
    setIsOrdersLoading(true);
    try {
      await getSamplesFromOrders({
        orderUUIDs: previousOrders.map(({ uuid }) => uuid).join(','),
      });

      const ordersResponse = await getOrders();
      if (!unmountIgnore.current) {
        const orders = ordersResponse.data.filter(
          ({ uuid, contains_samples, ship_back_samples, state }) => {
            const differentOrder = uuid !== order.uuid;
            const samplesStillAtHistoWiz = !ship_back_samples;
            const orderInValidState = finishedOrderStates.includes(state);

            return (
              orderInValidState &&
              differentOrder &&
              contains_samples &&
              samplesStillAtHistoWiz
            );
          },
        );

        setPreviousOrders(orders.map(({ name, uuid }) => ({ name, uuid })));
      }
    } catch (error) {
      showError(error.message);
    } finally {
      if (!unmountIgnore.current) {
        setIsOrdersLoading(false);
      }
    }
  };

  useEffect(() => {
    setAddPreviousSamplesModalOpen(!!materialsAtHistowiz);
  }, [materialsAtHistowiz]);

  useEffect(() => {
    if (!materialsAtHistowiz) return;

    fetchOrdersAndSamples();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unmountIgnore, materialsAtHistowiz]);

  const [isCreatePanelModalVisible, setIsCreatePanelModalVisible] =
    useState(false);

  const { bone_decalcification, methods } = useBoneDecalcificationForm(order);

  // A bit of a hack, but have a cache to know what value ag-Grid is referring to,
  // for UI and Ag-Grid Reference, we store based on names of Antibodies / Special Stains
  // so now we need a way to look back the original thing for the UUID
  // use of react-context / redux would be useful here
  const [lookupCache, setLookupCache] = useState({});

  const is3DaysTAT = order?.turnaround_days === 3;

  const isIFServiceRequested = rows.some((row) =>
    row.requested_services?.includes(IF_SERVICE_TYPE),
  );

  const [isCreateAntibodyModalVisible, setIsCreateAntibodyModalVisible] =
    useState(false);

  const [isChangesSaved, setIsChangesSaved] = useState(true);

  const allData = getAllGridData(gridAPI);
  const isValidBoneSampleExists = useMemo(
    () =>
      allData.find(
        (sample) =>
          sample.organ?.name === BONE_ORGAN &&
          !NON_VALID_SUBMISSIONS_FOR_BONE_ORGAN.includes(
            sample.submission?.service_type,
          ),
      ),
    [allData],
  );

  const handleGridRowDataUpdated = () => {
    setIsChangesSaved(false);
  };

  useEffect(() => {
    if (
      !previousOrders.length ||
      ordersAndSamples.length ||
      !projects?.length ||
      !samplesFromOrders?.length
    )
      return;

    const ordersWithSamples = previousOrders.map((prevOrder) => {
      const samples = samplesFromOrders?.filter(
        (sample) => sample.order.uuid === prevOrder.uuid,
      );

      return {
        ...prevOrder,
        projectName: projects?.find((project) =>
          project.orders?.find((o) => o.uuid === prevOrder.uuid),
        )?.name,
        checked: false,
        samples: samples.map((sample) => ({ ...sample, checked: false })),
      };
    });

    setOrdersAndSamples(
      ordersWithSamples.filter((ord) => !!ord.samples.length),
    );
  }, [previousOrders, projects, samplesFromOrders, ordersAndSamples?.length]);

  const handleWizardNavigatorBeforeNavigate = () => {
    if (isChangesSaved) return false;

    const promptText = 'Changes you made may not be saved. Leave page?';

    // eslint-disable-next-line no-alert
    return !window.confirm(promptText);
  };

  const toggleCreateAntibodyModal = () =>
    setIsCreateAntibodyModalVisible((prevState) => !prevState);

  // in the slim chance that the order UUID is changed from react, the orderUUID needs to be updated

  useEffect(() => {
    if (!order || columns.length) {
      return;
    }

    // a bit critical but for ag-grid to fill out the columns, we need to get all the possible options
    // in the dropdowns, this assembles that and then only should ag-grid appear
    setIsColumnsLoading(true);
    getSamplesColumnsData({
      order,
      isStaff,
      AddAntibodyButton,
      handleAntibodiesListModalOpen,
    })
      .then((response) => {
        if (!unmountIgnore.current) {
          setColumns(response.columns);
          setSampleSubmissions(response.submissions);
          setLookupCache(response);
        }
      })
      .catch(() => {
        showError(FETCH_DATA_ERROR_MESSAGE);
      })
      .finally(() => {
        setIsColumnsLoading(false);
      });
  }, [order, unmountIgnore]);

  useEffect(() => {
    setIsSamplesLoading(true);

    getSamplesByOrderId({ orderUUID })
      .unwrap()
      .then((response) => {
        if (unmountIgnore.current) return;

        const samplesData = cloneSamples(response);

        if (!unmountIgnore.current) {
          samplesData.forEach((sample) => {
            serializeRequestedServicesOnSample(sample);
            serializeRequestedAntibodiesOnSample(sample);
            serializeRequestedSpecialStainsOnSample(sample);
            serializeRequestedPanelsOnSample(sample);
            serializeScienceInfoOnSample(sample);
          });

          setRows(samplesData);
        }
      })
      .catch(() => showError(FETCH_DATA_ERROR_MESSAGE))
      .finally(() => setIsSamplesLoading(false));
  }, [orderUUID, unmountIgnore]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isChangesSaved) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [history, isChangesSaved]);

  const toggleCreatePanelModal = () =>
    setIsCreatePanelModalVisible((prevState) => !prevState);

  const handleSaveAndContinue = () => {
    if (validationErrorsFound) {
      setErrorsListModalOpen(true);

      return;
    }

    // if we get this far, disable the save and continue so people will not click
    // it multiple times - for a large amount of samples, this can create a lock
    // on the samples table, causing app issues ... will refactor backend in future
    setIsSubmitting(true);

    const gridData = getAllGridData(gridAPI);
    const postParams = gridData.map((result) =>
      serializeSampleDetailsToBackendPostRequest(result, order, lookupCache),
    );

    postSamples(postParams)
      .unwrap()
      .then(() => {
        // pathology map consent orders have to fill out sample science
        // route an order to either fill out sample science info
        let url;
        if (order.pathology_map_consent) {
          url = PLACE_ORDER_SAMPLES_SCIENCE_STEP_URL.replace(
            ':orderUUID',
            orderUUID,
          );
        } else {
          url = PLACE_ORDER_SLIDES_STEP_URL.replace(':orderUUID', orderUUID);
        }

        const isGPE = !postParams.filter(
          (sample) => !sample.gross_process_embed_only,
        ).length;

        if (isGPE) {
          url = PLACE_ORDER_PRICE_REVIEW_STEP_URL.replace(
            ':orderUUID',
            orderUUID,
          );
        }

        setIsChangesSaved(true);

        history.push(url);
      })
      .catch((error) => {
        const backendError = error.data?.length
          ? error.data
            .map((errObj) => Object.values(errObj).join(', '))
            .join(' ')
          : '';
        const errorMessage =
          backendError ||
          (error.response
            ? JSON.stringify(error.response.data)
            : SUBMITTING_ERROR_MESSAGE);

        setBackendIssueErrors(errorMessage);
        setErrorsListModalOpen(true);
        setIsSubmitting(false);
      });

    const previousOrdersUUIDs = [
      ...new Set(
        allData
          .filter((sample) => sample.previous_sample)
          .map((sample) => sample.order.uuid),
      ),
    ];

    updateOrder(orderUUID, {
      bone_decalcification: isValidBoneSampleExists
        ? bone_decalcification.value
        : false,
      last_order_form_page: LAST_ORDER_FORM_PAGE.SAMPLES,
      materials_at_histowiz_previous_orders_v2_uuids: previousOrdersUUIDs,
    }).catch(() => showError(UPDATE_ORDER_ERROR_MESSAGE));
  };

  const checkAllRowsAreValid = debounce(() => {
    const gridData = getAllGridData(gridAPI).filter((data) => !!data);

    // get sample names trimmed and then check for duplicates
    const sampleNames = gridData.map(({ name }) =>
      name ? name.trim().toUpperCase() : '',
    );
    // get all samples that are just not just empty spaces
    const sampleNamesNoSpaces = sampleNames.filter((name) => !!name);

    // if we find duplicates, store it so that we can reference it later
    const duplicatedSamplesNames = getDuplicatesFromArray(sampleNamesNoSpaces);
    setDuplicatedSampleNames(duplicatedSamplesNames);

    if (duplicatedSamplesNames.length > 0 || gridData.length === 0) {
      setValidationErrorsFound(true);
    }

    // if the order is missing species, dont let the user go to the next page
    const missingSpeciesError = !order?.species;

    samplesListValidation
      .validate(gridData)
      .then(() => {
        // the additional if statement here is a check because
        // we should only let validation pass if
        // there are no duplicate samples names
        // and there is at least some data
        if (
          duplicatedSampleNames.length > 0 ||
          gridData.length === 0 ||
          missingSpeciesError
        ) {
          return;
        }
        setValidationErrorsFound(false);
      })
      .catch(() => {
        setValidationErrorsFound(true);
      });
  }, 300);

  const deleteSelectedRows = () => {
    if (!gridAPI) return false;

    const selectedRows = gridAPI.getSelectedRows();

    // if it contains a UUID, this record exists on the backend and we should delete
    const deletedRowsAlreadySaved = selectedRows
      .filter((response) => !!response['uuid'])
      .map(({ uuid }) => uuid);

    gridAPI.applyTransaction({ remove: selectedRows });

    deleteSamples({ orderUUID, sample_uuids: deletedRowsAlreadySaved })
      .then(() => checkAllRowsAreValid())
      .catch(() => showError(DELETE_ORDER_ERROR_MESSAGE));

    return true;
  };

  const addNewRow = () => {
    if (!gridAPI) return;
    // new rows should always know what orderUUID it belongs to
    const newRow = {
      name: '',
      order_uuid: orderUUID,
      [REQUESTED_SERVICES_FIELD_NAME]: [],
      [REQUESTED_ANTIBODIES_FIELD_VALUE]: [],
      [REQUESTED_SPECIAL_STAINS_FIELD_VALUE]: [],
      [REQUESTED_PANELS_FIELD_VALUE]: [],
      [IS_TUMOR_FIELD_NAME]: null,
    };

    // if defaulted species is on an order, then always populate it here
    if (order?.species?.name) {
      newRow.species = order.species.name;
    }

    gridAPI.applyTransaction({ add: [newRow] });
    checkAllRowsAreValid();
  };

  const handleAdditionalSamplesTextChange = (event) => {
    setNewSamplesText(event.target.value);
  };

  const addUploadedSampleLines = () => {
    if (!gridAPI) return;
    const newSampleNames = newSamplesText.split(/\r?\n/);

    const speciesName = order?.species?.name ? order.species.name : '';

    const newSamplesData = newSampleNames.map((name) => ({
      name,
      order_uuid: orderUUID,
      species: speciesName,
      [REQUESTED_SERVICES_FIELD_NAME]: [],
      [REQUESTED_ANTIBODIES_FIELD_VALUE]: [],
      [REQUESTED_PANELS_FIELD_VALUE]: [],
      [REQUESTED_SPECIAL_STAINS_FIELD_VALUE]: [],
      [IS_TUMOR_FIELD_NAME]: null,
    }));

    gridAPI.applyTransaction({ add: newSamplesData });
    setAddSamplesModalOpen(false);
    checkAllRowsAreValid();
  };

  const handleSetAddSamplesModal = () => {
    setAddSamplesModalOpen(!addNewSamplesModalOpen);
  };

  const handleSetAddPreviousSamplesModal = () => {
    setAddPreviousSamplesModalOpen(!addPreviousSamplesModalOpen);
  };

  const resizeGridColumns = () => {
    // eslint-disable-next-line
    gridColumnApi?.autoSizeAllColumns(columns);
  };

  const handleDuplicateRows = () => {
    const rowsToDuplicate = gridAPI.getSelectedRows();

    const duplicateRowsCopy = rowsToDuplicate.map((row) => {
      const newRow = { ...row };
      newRow['uuid'] = null;
      newRow['name'] = '';

      return newRow;
    });

    gridAPI.applyTransaction({ add: duplicateRowsCopy });
    checkAllRowsAreValid();
  };

  const onGridReady = (params) => {
    setGridAPI(params.api);
    setGridColumnApi(params.columnApi);

    // tool panels default to open, kind of annoying
    params.api.closeToolPanel();
  };

  const tutorialAction = () => {
    window.open(
      'https://app.tango.us/app/workflow/64484b24-39ae-4fec-998a-e91772a9a25b',
    );
  };

  const onPasteEnd = () => {
    // Redraw rows for validation to run again ...
    gridAPI.redrawRows();
  };

  const autoSizeColumnsDebounced = debounce(() => {
    gridColumnApi.autoSizeAllColumns(columns);
  }, 300);

  const onCellValueChanged = () => {
    setIsChangesSaved(false);

    // Automatically resize columns that everything is visible, especially for multiple
    // antibodies, etc.
    autoSizeColumnsDebounced();
    checkAllRowsAreValid();
  };

  const handleSetErrorsListModalOpen = () => {
    setErrorsListModalOpen(!errorsListModalOpen);
  };

  const renderErrorModal = () => {
    if (!gridAPI) {
      return null;
    }

    const gridData = getAllGridData(gridAPI);
    const issueMessages = [];
    gridData.forEach((row, index) => {
      try {
        yupSamplesValidation.validateSync(row, { abortEarly: false });

        return row;
      } catch (validationError) {
        const { errors } = validationError;
        const sampleName = row.name;

        // humans dont count by 0
        const humanRowIndex = index + 1;

        // create an array of dicts describing the fail reason
        errors.forEach((errorMessage) => {
          const errorDetail = {
            rowIndex: humanRowIndex,
            name: sampleName,
          };

          errorDetail['message'] = errorMessage;
          issueMessages.push(errorDetail);
        });

        return null;
      }
    });

    return (
      <SamplesSubmitSaveErrorsModal
        open={errorsListModalOpen}
        onClose={handleSetErrorsListModalOpen}
      >
        <RenderValidationErrors issueMessages={issueMessages} />
        <RenderDuplicateSameNames
          duplicatedSampleNames={duplicatedSampleNames}
        />
        <RenderNoRowsError rows={allData} />
        <RenderBackendValidationErrors
          backendIssueErrors={backendIssueErrors}
        />
      </SamplesSubmitSaveErrorsModal>
    );
  };

  const renderAddSamplesModal = () => (
    <DefaultModal
      open={addNewSamplesModalOpen}
      onClose={handleSetAddSamplesModal}
      size={ModalSize.LARGE}
    >
      <div className={classes.boldText}>
        Please paste a list of Sample (names) to add. <br />
        <br />
        Only ONE NAME per each line!
      </div>
      <Input
        onChange={handleAdditionalSamplesTextChange}
        value={newSamplesText}
        fullWidth
        multiline
        // this sets the initial rows that are shown, but more can be created
        rows={8}
        placeholder={
          '\nSample Name One\nSample Name Two\nSample Name Three\netc.'
        }
      />
      <div className={classes.rightAlign}>
        <Button
          variant="contained"
          onClick={addUploadedSampleLines}
          color="primary"
        >
          Upload Sample Names
        </Button>
      </div>
    </DefaultModal>
  );

  const renderSaveAndContinueButton = () => {
    const continueText = validationErrorsFound
      ? 'Save & Continue (!)'
      : 'Save & Continue';

    return (
      <Button
        className={
          validationErrorsFound
            ? classes.saveAndContinueButtonError
            : classes.saveAndContinueButton
        }
        variant="contained"
        color="primary"
        disabled={isSubmitting || !order?.species}
        onClick={handleSaveAndContinue}
      >
        <SubmittingIcon submitting={isSubmitting} />
        {continueText}
      </Button>
    );
  };

  const renderTopButtons = () => (
    <Grid container justifyContent="space-between" alignItems="flex-end">
      {is3DaysTAT && isIFServiceRequested && (
        <SelectedServiceWarning classes={classes} />
      )}
      <Grid item xs={8}>
        <Button
          onClick={deleteSelectedRows}
          className={classes.button}
          variant="contained"
          color="secondary"
          size="small"
        >
          <ClearIcon />
          Delete
        </Button>
        <Button
          onClick={handleDuplicateRows}
          className={classes.button}
          variant="contained"
          color="secondary"
          size="small"
        >
          <CopyIcon />
          Duplicate
        </Button>
      </Grid>
      <Grid item xs={4} className={classes.rightGridAlign}>
        {isStaff && (
          <Button
            className={classes.button}
            onClick={toggleCreateAntibodyModal}
            variant="contained"
            color="secondary"
          >
            Add New Antibody
          </Button>
        )}
        <Button
          className={classes.button}
          onClick={toggleCreatePanelModal}
          variant="contained"
          color="secondary"
        >
          Add New Panel
        </Button>
        {materialsAtHistowiz ? (
          <Button
            className={classes.button}
            onClick={handleSetAddPreviousSamplesModal}
            variant="contained"
            color="secondary"
          >
            Add Samples from order
          </Button>
        ) : (
          <Button
            className={classes.button}
            onClick={handleSetAddSamplesModal}
            variant="contained"
            color="secondary"
          >
            Add Samples
          </Button>
        )}
        <Button
          onClick={addNewRow}
          className={classes.button}
          variant="contained"
          color="secondary"
        >
          Add New Row
        </Button>
      </Grid>
    </Grid>
  );

  const handleFirstDataRendered = (e) => {
    handleDynamicColumnsVisibility(e, true);
    resizeGridColumns();
  };

  const isColumnsLoaded = !!gridColumnApi?.getColumns()?.length;

  const isLoading =
    columns.length === 0 ||
    isProjectsLoading ||
    isSamplesLoading ||
    isColumnsLoading ||
    isOrdersLoading;

  useEffect(() => {
    if (!columns.length && !isColumnsLoaded) return;

    handleFirstDataRendered({ api: gridAPI, columnApi: gridColumnApi });
  }, [
    columns.length,
    isColumnsLoading,
    isColumnsLoaded,
    gridAPI,
    gridColumnApi,
    handleFirstDataRendered,
  ]);

  const handleSuccessOptionCreate =
    (columnName, columnId) => (createdOption) => {
      handleAddNewColumnOption({
        gridAPI,
        setColumns,
        setLookupCache,
        createdOption,
        columnName,
        columnId,
      });
    };

  useAGGridOverlayControl(isLoading, rows, gridAPI);

  const columnsToDisplay = isEditBlocked
    ? makeColumnsNonEditable(columns)
    : columns;

  const renderGrid = () => (
    <div className="ag-theme-balham" style={gridThemeStyle}>
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnsToDisplay}
        rowData={rows}
        enableColResize
        rowSelection="multiple"
        enableFillHandle
        // want the grid to stop editing when focus leaves the cell or the grid
        stopEditingWhenCellsLoseFocus
        fillHandleDirection="y"
        rowDragManaged
        rowDrag
        enableRangeSelection
        editable
        suppressClearOnFillReduction
        defaultColDef={agGridDefaultColDef}
        onCellValueChanged={onCellValueChanged}
        tooltipShowDelay={0}
        components={frameworkComponents}
        sideBar
        onPasteEnd={onPasteEnd}
        onRowDataUpdated={handleGridRowDataUpdated}
        onCellDoubleClicked={handleFillHandleDoubleClicked}
        overlayNoRowsTemplate="<span>No data</span>"
        // if we don't suppressRowClick, copy and paste keeps on trying to copy
        // the entire row and it really sucks for the entire experience
        suppressRowClickSelection
        suppressCopyRowsToClipboard
        suppressNoRowsOverlay={isLoading}
      />
    </div>
  );

  return (
    <>
      <div className={classes.page}>
        <div className={classes.navigator}>
          <OrderFormWizardNavigator
            onBeforeNavigate={handleWizardNavigatorBeforeNavigate}
          />
        </div>
        <Box mt={2} px={2}>
          {/* This render no species is from a while back before we used to mandate */}
          {/* species be filled in the order form. */}
          <RenderNoSpeciesInOrder order={order} />
          {!isEditBlocked && <Box mb={1}>{renderTopButtons()}</Box>}
          {renderGrid()}
          {columns.length !== 0 && (
            <SamplesStatusBar count={gridAPI?.getDisplayedRowCount()} />
          )}
          {isValidBoneSampleExists && (
            <FormProvider {...methods}>
              <BoneDecalcificationInput name={BONE_DECALCIFICATION} />
            </FormProvider>
          )}
          {renderAddSamplesModal()}
          {!isLoading && !isEditBlocked && (
            <AddPreviousSamplesModal
              ordersAndSamples={ordersAndSamples}
              gridAPI={gridAPI}
              setAddPreviousSamplesModalOpen={setAddPreviousSamplesModalOpen}
              addPreviousSamplesModalOpen={addPreviousSamplesModalOpen}
              handleSetAddPreviousSamplesModal={
                handleSetAddPreviousSamplesModal
              }
              checkAllRowsAreValid={checkAllRowsAreValid}
              sampleSubmissions={sampleSubmissions}
            />
          )}
          {renderErrorModal()}
          <Box mt={1} mb={2}>
            <SamplesGridBottomButtons
              isLoading={isLoading}
              exportToExcelAction={() =>
                exportToExcelAction(
                  gridAPI,
                  `${order?.name}-HistoWiz-Order-Form-Samples-Details`,
                )
              }
              renderSaveAndContinueButton={renderSaveAndContinueButton}
              resizeGridColumns={resizeGridColumns}
              tutorialAction={tutorialAction}
            />
          </Box>

          <PleaseCallHistoWiz />
          <Box mt={1}>
            <Stack direction="row" justifyContent="center">
              <Typography variant="body2">{order?.name}</Typography>
            </Stack>
          </Box>
        </Box>
      </div>
      <CreatePanelModal
        antibodies={lookupCache.antibodies}
        open={isCreatePanelModalVisible}
        onClose={toggleCreatePanelModal}
        onAfterSuccessCreate={handleSuccessOptionCreate(
          'panels',
          REQUESTED_PANELS_FIELD_VALUE,
        )}
        teamUUID={order?.team.uuid}
      />
      <CreateAntibodyModal
        open={isCreateAntibodyModalVisible}
        onClose={toggleCreateAntibodyModal}
        onAfterSuccessCreate={handleSuccessOptionCreate(
          'antibodies',
          REQUESTED_ANTIBODIES_FIELD_VALUE,
        )}
        teamUUID={order?.team.uuid}
      />
      <AntibodiesListModal
        handleAddToOrder={handleAddToOrder}
        isAntibodiesListModalOpen={isAntibodiesListModalOpen}
        handleAntibodiesListModalClose={handleAntibodiesListModalClose}
      />
    </>
  );
};
