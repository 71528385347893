import { SvgIcon } from '@mui/material';

export const DownloadIcon = () => (
  <SvgIcon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width: '1.25rem', height: '1.25rem' }}
  >
    <g>
      <path
        d="M9.99967 13.3333L5.83301 9.16665L6.99967 7.95831L9.16634 10.125V3.33331H10.833V10.125L12.9997 7.95831L14.1663 9.16665L9.99967 13.3333ZM4.99967 16.6666C4.54134 16.6666 4.14898 16.5035 3.82259 16.1771C3.4962 15.8507 3.33301 15.4583 3.33301 15V12.5H4.99967V15H14.9997V12.5H16.6663V15C16.6663 15.4583 16.5031 15.8507 16.1768 16.1771C15.8504 16.5035 15.458 16.6666 14.9997 16.6666H4.99967Z"
        fill="inherit"
      />
    </g>
  </SvgIcon>
);
