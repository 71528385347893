import React, { useEffect, useState } from 'react';

import {
  getCustomQuoteForOrder,
  getOrderBilling,
  updateOrder,
} from 'services/resources/orders';
import { useHistory, useRouteMatch } from 'react-router';
import { ServiceSlidesTable } from 'components/OrderForm/components/BillingViewTables';

import { Box, Button } from '@mui/material';
import {
  A_LA_CARTE,
  ADDITIONAL_BILLING,
  AUTOMATED_IMAGE_ANALYSIS,
  BUTTON_TEXT_CONTACT_SALES,
  BUTTON_TEXT_SUBMITTED_REQUEST,
  DATA_MANAGEMENT_SERVICES,
  EXPERT_DIAGNOSIS,
  IHC_OPTIMIZATION,
  initialState,
  OCT_BLOCK_SLIDES,
  PARAFFIN_BLOCK_SLIDES,
  PRICE_REVIEW_PAGE_TITLE,
  SERVICE_TYPES,
  UNSTAINED_SLIDES,
  WET_TISSUE_SLIDES,
} from 'components/OrderForm/PricesReviewView/constants';
import {
  backendServiceTypesIHCOptimizations,
  LAST_ORDER_FORM_PAGE,
  ORDER_FETCHING_ERROR_MESSAGE,
} from 'components/OrderForm/constants';
import { useUnmountIgnore } from 'utilities/useUnmountIgnore';
import {
  QUOTE_SUBMITTING_ERROR_MESSAGE,
  UPDATE_ORDER_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { PLACE_ORDER_BILLING_STEP_URL } from 'constants/urls';
import { QUOTE_SUBMITTING_SUCCESS_MESSAGE } from 'constants/successMessages';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { usePricesReviewViewStyles } from './styles';
import { useTitle } from 'components/utilities/hooks/useTitle';

export const PricesReviewView = () => {
  const { classes } = usePricesReviewViewStyles();
  const history = useHistory();
  const unmountIgnore = useUnmountIgnore();
  const { showSuccess, showError } = useSnackbar();
  useTitle(PRICE_REVIEW_PAGE_TITLE);

  const {
    params: { orderUUID },
  } = useRouteMatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingCustomQuoteRequest, setSubmittingCustomQuoteRequest] =
    useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    getOrderBilling(orderUUID)
      .then((response) => {
        if (unmountIgnore.current) return;
        let updatedState = { ...initialState };
        response.forEach((item) => {
          if (backendServiceTypesIHCOptimizations.includes(item.service_type)) {
            updatedState = {
              ...updatedState,
              ihcOptimization: [...updatedState.ihcOptimization, item],
            };
          } else {
            const type = SERVICE_TYPES[item.service_type];
            updatedState = {
              ...updatedState,
              [type]: [...updatedState[type], item],
            };
          }
        });
        setState(updatedState);
      })
      .catch(() => showError(ORDER_FETCHING_ERROR_MESSAGE));
  }, [initialState, orderUUID, unmountIgnore]);

  const handleContinue = () => {
    setIsSubmitting(true);

    updateOrder(orderUUID, {
      last_order_form_page: LAST_ORDER_FORM_PAGE.PRICE_REVIEW,
    }).catch(() => showError(UPDATE_ORDER_ERROR_MESSAGE));

    const url = PLACE_ORDER_BILLING_STEP_URL.replace(':orderUUID', orderUUID);

    history.push(url);
  };

  const handleCustomQuote = () => {
    setSubmittingCustomQuoteRequest(true);

    getCustomQuoteForOrder({ orderUUID })
      .then(() => showSuccess(QUOTE_SUBMITTING_SUCCESS_MESSAGE))
      .catch(() => showError(QUOTE_SUBMITTING_ERROR_MESSAGE));
  };

  const buttonText = !submittingCustomQuoteRequest
    ? BUTTON_TEXT_CONTACT_SALES
    : BUTTON_TEXT_SUBMITTED_REQUEST;
  const buttonColor = !submittingCustomQuoteRequest ? 'primary' : 'secondary';

  const serviceSlidesTables = [
    { title: WET_TISSUE_SLIDES, orders: state.wetTissueSlides },
    { title: OCT_BLOCK_SLIDES, orders: state.octBlockSlides },
    {
      title: PARAFFIN_BLOCK_SLIDES,
      orders: state.paraffinBlockSlides,
    },
    {
      title: UNSTAINED_SLIDES,
      orders: state.unstainedServiceSlides,
    },
    { title: DATA_MANAGEMENT_SERVICES, orders: state.dataManagementServices },
    { title: AUTOMATED_IMAGE_ANALYSIS, orders: state.automatedImageAnalysis },
    {
      title: EXPERT_DIAGNOSIS,
      orders: state.expertPathologistDiagnosis,
    },
    { title: IHC_OPTIMIZATION, orders: state.ihcOptimization },
    { title: A_LA_CARTE, orders: state.aLaCarteServices },
    { title: ADDITIONAL_BILLING, orders: state.additionalBillingItems },
  ];

  return (
    <>
      <Box className={classes.noteMargin}>
        NOTE: Contact sales for custom quotes; in all other cases, you will
        receive a quote after order submission. Orders can only be started with
        a valid Credit Card or PO number.
        <Box>
          <Button
            onClick={handleCustomQuote}
            variant="contained"
            color={buttonColor}
            disabled={submittingCustomQuoteRequest}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
      <Box className={classes.noteMargin}>
        Orders must be larger than $250 USD to continue. Please Note: This
        estimate does not include any immunofluorescence slides if selected.
        Please contact billing separately in regards to IF quotes.
      </Box>
      {serviceSlidesTables.map(({ title, orders }) => {
        return (
          <ServiceSlidesTable
            key={title}
            classes={classes}
            orders={orders}
            title={title}
          />
        );
      })}
      <Box className={classes.saveButton}>
        <Button
          onClick={handleContinue}
          variant="contained"
          color={'primary'}
          disabled={isSubmitting}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};
