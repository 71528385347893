import { PathologyMapSlideCardInfo } from 'components/PathologyMap/PathologyMapSlideCards/PathologyMapSlideCardInfo';
import React from 'react';

export const SlideInfoTable = ({ slide, dataItems, selectedFilters = [] }) => {
  return dataItems.map((item) => (
    <PathologyMapSlideCardInfo
      key={`${item.attribute}-${item.value}`}
      title={item.label}
      content={item.value}
      slide={slide}
      attribute={item.attribute}
      selectedFilters={selectedFilters}
    />
  ));
};
