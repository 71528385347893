import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';

export const SelectedServiceWarning = ({ classes }) => (
  <div className={classes.errorText}>
    <Box mb={2}>
      <ErrorIcon color="error" className={classes.errorIcon} />
      <div>
        For turnaround times of 3 Days for Immunofluorescence, please contact
        your account manager after placing your order. Otherwise, your order
        will be submitted with a turnaround time of 10 days. No further action
        is needed. Please contact support desk for more information.
      </div>
    </Box>
  </div>
);
