import { Box, Typography } from '@mui/material';
import { DefaultModal } from 'components/Modals/DefaultModal';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import React, { useEffect, useState } from 'react';
import { useSteps } from 'components/TeamOverview/hooks';
import {
  UPLOAD_STEPPER,
  UPLOAD_STEP,
  PROJECT_STEP,
  ORDER_STEP,
  UPLOAD_VIEW,
} from 'components/TeamOverview/constants';
import { ProgressStepper } from 'components/OrderDetails/Tabs/OrderProgressTab/ProgressStepper';
import {
  uploadModalWrapperStyles,
  useUploadModalStyles,
} from 'components/UserProfile/Upload/styles';
import { OrderStepContent } from 'components/UserProfile/Upload/Steps/OrderSelectionStep';
import { ProjectStepContent } from 'components/UserProfile/Upload/Steps/ProjectSelectionStep';
import { UploadStepContent } from 'components/UserProfile/Upload/Steps/UploadStep';

export const UploadStepperModal = ({
  isOpen,
  onClose,
  selectedOrder,
  setCurrentView,
  setUploadKey,
  selectedOrderForUpload,
  selectedProjectForUpload,
  setSelectedOrderForUpload,
  setSelectedProjectForUpload,
  selectedTeam,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { classes } = useUploadModalStyles();

  useEffect(() => {
    if (selectedOrder) {
      setSelectedOrderForUpload(selectedOrder);
      setSelectedProjectForUpload(selectedOrder.project);
    }
  }, [selectedOrder]);

  const steps = [PROJECT_STEP, ORDER_STEP, UPLOAD_STEP];

  const { handleNextStep, resetStep, currentStep, handlePrevStep } =
    useSteps(steps);

  const handleClose = () => {
    resetStep();
    onClose();
  };

  const handleDone = (key) => {
    resetStep();
    onClose();
    setUploadKey(key);
    setCurrentView(UPLOAD_VIEW);
  };

  const activeStepIDX = currentStep ? steps.indexOf(currentStep) : 0;

  const stepperSettings = {
    ...UPLOAD_STEPPER,
    activeStepIDX,
    number: true,
  };

  const STEPS_CONTENT = {
    [UPLOAD_STEP]: (
      <UploadStepContent
        handleDone={handleDone}
        handlePrevStep={handlePrevStep}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        classes={classes}
        selectedOrder={selectedOrderForUpload}
        setSelectedOrder={setSelectedOrderForUpload}
        selectedProject={selectedProjectForUpload}
        setSelectedProject={setSelectedProjectForUpload}
        selectedTeam={selectedTeam}
      />
    ),
    [PROJECT_STEP]: (
      <ProjectStepContent
        handleNextStep={handleNextStep}
        classes={classes}
        handleClose={handleClose}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        selectedOrder={selectedOrderForUpload}
        selectedProject={selectedProjectForUpload}
        setSelectedProject={setSelectedProjectForUpload}
        setSelectedOrder={setSelectedOrderForUpload}
      />
    ),
    [ORDER_STEP]: (
      <OrderStepContent
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        classes={classes}
        handleClose={handleClose}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        selectedOrder={selectedOrderForUpload}
        selectedProject={selectedProjectForUpload}
        setSelectedProject={setSelectedProjectForUpload}
        setSelectedOrder={setSelectedOrderForUpload}
      />
    ),
  };

  const selectedProjectName = selectedProjectForUpload?.name;

  const isNewProjectCreated =
    !selectedProjectName && (!currentStep || currentStep === PROJECT_STEP);

  return (
    <DefaultModal
      open={isOpen}
      onClose={handleClose}
      styles={uploadModalWrapperStyles}
      closeIcon
    >
      <Card
        cardClass={classes.cardContainer}
        header={
          <Box padding="1.375rem">
            {selectedProjectName && (
              <Typography className={classes.cardTitle}>
                Uploading Files To {selectedProjectName}
              </Typography>
            )}
            {isNewProjectCreated && (
              <Typography className={classes.cardTitle}>
                ENTER NEW PROJECT NAME
              </Typography>
            )}
          </Box>
        }
      >
        <Box paddingX="1.75rem" paddingBottom="2rem" paddingTop="1rem">
          <Box className={classes.stepperWrapper}>
            <ProgressStepper isLoading={false} stepper={stepperSettings} />
          </Box>
          {STEPS_CONTENT[currentStep || PROJECT_STEP]}
        </Box>
      </Card>
    </DefaultModal>
  );
};
