import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseCreateApi = createApi({
  reducerPath: 'apis',
  baseQuery: fetchBaseQuery({
    baseUrl: require('services/backendAPI').backendURL,
    prepareHeaders: (headers) => {
      headers.set(
        'Authorization',
        require('utilities/token').getBearerToken() ||
          require('utilities/token').getLocalToken(),
      );

      return headers;
    },
  }),
  tagTypes: ['getOrders', 'getOrderByUUID', 'getTeams'],
  endpoints: () => ({}),
});
