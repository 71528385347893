export const ProcessingIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2.25rem"
      height="2.25rem"
      viewBox="0 0 36.000000 36.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,36.000000) scale(0.100000,-0.100000)"
        fill="#5d5d5d"
        stroke="none"
      >
        <path d="M105 307 c-25 -19 -3 -30 29 -16 14 7 26 16 26 21 0 13 -34 9 -55 -5z" />
        <path
          d="M200 311 c0 -5 14 -14 31 -21 90 -38 90 -182 0 -220 -35 -14 -41 -30
-11 -30 10 0 35 14 55 31 68 60 68 158 0 218 -34 29 -75 42 -75 22z"
        />
        <path d="M51 252 c-13 -25 -14 -52 -3 -52 5 0 14 12 21 27 15 32 -2 56 -18 25z" />
        <path
          d="M205 190 c-36 -37 -40 -39 -57 -23 -28 25 -35 5 -7 -22 l25 -25 48
49 c26 27 44 51 39 54 -6 4 -27 -12 -48 -33z"
        />
        <path d="M40 142 c0 -25 25 -58 34 -44 7 12 -13 62 -26 62 -4 0 -8 -8 -8 -18z" />
        <path d="M95 71 c-6 -11 26 -31 49 -31 24 0 19 15 -9 28 -31 14 -32 14 -40 3z" />
      </g>
    </svg>
  );
};
