import { useProcessingQueue } from 'components/BlocksList/hooks/useProcessingQueue';
import { getRowData, getSelectedSlides } from 'components/BlocksList/utilities';
import {
  ORDER_TABS_IDS,
  UNSUBMITTED_ORDERS_TAB_VALUE,
} from 'components/PathologyMap/constants/common';
import { ORDER_NAME_SORT_FIELD } from 'components/SlideGroups/utilities/constants';
import { usePrepareOrderForSelectedTab } from 'components/UserProfile/hooks';
import { buildTeamInfo } from 'components/UserProfile/utilities';
import {
  FETCH_PROJECTS_ERROR_MESSAGE,
  FETCH_TEAM_BLOCKS_ERROR_MESSAGE,
  FETCH_TEAMS_ERROR_MESSAGE,
} from 'constants/errorMessages';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetProjectsQuery } from 'store/apis/projectsApi';
import {
  useGetTeamsQuery,
  useLazyGetTeamBlocksQuery,
} from 'store/apis/teamsApi';
import { useGetTeamDetailsQuery } from 'store/apis/userDetailsApi';
import { sortOrder } from 'utilities/constants';
import { useQueryError } from 'utilities/hooks/useQueryError/useQueryError';
import { useLocation } from 'react-router';

export const useBlocksPage = () => {
  const { data: teams } = useGetTeamsQuery();

  const {
    data: projects,
    isLoading: isProjectsLoading,
    error: projectsError,
  } = useGetProjectsQuery();

  const { error, isLoading: isTeamsLoading } =
    useGetTeamDetailsQuery(undefined);
  useQueryError(error, FETCH_TEAMS_ERROR_MESSAGE);
  useQueryError(projectsError, FETCH_PROJECTS_ERROR_MESSAGE);

  const [
    getTeamBlocks,
    { data: blocksData, error: blocksDataError, isLoading: isBlocksLoading },
  ] = useLazyGetTeamBlocksQuery();

  const [sort, setSort] = useState({
    field: ORDER_NAME_SORT_FIELD,
    order: sortOrder.DESC,
  });

  useQueryError(blocksDataError, FETCH_TEAM_BLOCKS_ERROR_MESSAGE);

  const [gridAPI, setGridAPI] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSlides, setSelectedSlides] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const isLoading = isBlocksLoading || isTeamsLoading || isProjectsLoading;
  const [isOnlyWithSlides, setIsOnlyWithSlides] = useState(false);

  const {
    handleAddToQueue,
    checkRowSelectable,
    processingQueue,
    setProcessingQueue,
    handleCreateNewOrder,
    activeTab,
    setActiveTab,
    selectedSamples,
    setSelectedSamples,
    blocksModalOpen,
    handleBlockModalClose,
    getSelectedSamplesNames,
    handleAddToNewOrder,
    handleBlockModalOpen,
    processingQueueMap,
  } = useProcessingQueue(selectedRows);

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const blockUUID = searchParams.get('blockUUID');

  useEffect(() => {
    if (blockUUID && blocksData) {
      const block = blocksData.find((block) => block.uuid === blockUUID);
      if (block) {
        setSelectedRows([block]);
      }
    }
  }, [blockUUID, blocksData]);

  const getNodeByUUID = (gridApi, uuid) => {
    let matchingRowNode;

    gridApi.forEachNode((node) => {
      if (node.data?.uuid === uuid) {
        matchingRowNode = node;
      }
    });

    return matchingRowNode;
  };

  const handleFirstDataRendered = () => {
    if (blockUUID) {
      const matchingRowNode = getNodeByUUID(gridAPI, blockUUID);
      const parentRowNode = matchingRowNode?.parent;
      if (matchingRowNode) {
        if (parentRowNode) {
          parentRowNode.setExpanded(true);
        }
        matchingRowNode.setSelected(true);
        gridAPI.ensureNodeVisible(matchingRowNode.rowIndex, 'top');
        setSelectedSlides(getSelectedSlides(blocksData, [matchingRowNode]));
      }
    }
  };

  const onRowGroupOpened = (event) => {
    if (event.node.expanded) {
      const childCount = event.node.childrenAfterFilter.length;
      const index = event.node.rowIndex;
      gridAPI.ensureIndexVisible(index + childCount);
    }
  };

  const { selectedTabSortedOrders: orders, isOrdersLoading } =
    usePrepareOrderForSelectedTab(
      selectedTeam,
      ORDER_TABS_IDS[UNSUBMITTED_ORDERS_TAB_VALUE],
      sort,
    );

  const onGridReady = useCallback(
    (params) => {
      setGridAPI(params.api);
    },
    [setGridAPI],
  );

  useEffect(() => {
    if (selectedTeam || !teams?.length) return;

    setSelectedTeam(teams[0]);
  }, [teams]);

  useEffect(() => {
    if (!gridAPI) {
      return;
    }

    if (isLoading) {
      gridAPI.showLoadingOverlay();
    }
  });

  useEffect(() => {
    if (!selectedTeam) {
      return;
    }

    getTeamBlocks({ teamUUID: selectedTeam.uuid });
  }, [selectedTeam]);

  const onQuickFilterText = (event) => {
    setQuickFilterText(event.target.value);
  };

  const onRowSelected = (event) => {
    const { data: { block } = '' } = event.node;
    if (!block) return;

    setSelectedRows(
      gridAPI?.getSelectedNodes().map((node) => node.data.block) || [],
    );

    if (event.node.selected) {
      setSelectedSlides((prev) => [...prev, ...getSelectedSlides(block)]);
    } else {
      setSelectedSlides((prev) => {
        return prev.filter((slide) => slide.blockName !== block.name);
      });
    }
  };

  const handleSwitchChange = (event) => {
    setIsOnlyWithSlides(event.target.checked);
  };

  const teamInfo = useMemo(() => buildTeamInfo(selectedTeam), [selectedTeam]);

  const getSelectedBlockTitle = () => {
    const block = gridAPI?.getSelectedNodes()[0]?.data;

    return block?.order?.join(' / ') || '';
  };

  const tableTitle =
    selectedRows.length === 1
      ? getSelectedBlockTitle()
      : `${selectedRows.length} samples selected`;

  const rowData = useMemo(
    () =>
      getRowData(blocksData ?? [], projects, isOnlyWithSlides).filter(
        (block) => block.atHistowiz,
      ),
    [projects, blocksData, processingQueueMap],
  );

  const filteredRowData = useMemo(
    () =>
      isOnlyWithSlides ? rowData.filter((row) => row.slidesCount) : rowData,
    [isOnlyWithSlides, rowData],
  );

  return {
    selectedTeam,
    teamInfo,
    setSelectedTeam,
    teams,
    blocksData,
    tableTitle,
    selectedRows,
    onQuickFilterText,
    handleSwitchChange,
    filteredRowData,
    onGridReady,
    onRowSelected,
    quickFilterText,
    isOnlyWithSlides,
    selectedSlides,
    handleAddToQueue,
    checkRowSelectable,
    processingQueue,
    setProcessingQueue,
    handleCreateNewOrder,
    activeTab,
    setActiveTab,
    selectedSamples,
    setSelectedSamples,
    blocksModalOpen,
    handleBlockModalClose,
    getSelectedSamplesNames,
    handleAddToNewOrder,
    handleBlockModalOpen,
    orders,
    isOrdersLoading,
    sort,
    setSort,
    handleFirstDataRendered,
    onRowGroupOpened,
  };
};
