import React, { useEffect } from 'react';
import { Button, Slider, Tooltip } from '@mui/material';
import { Save } from '@mui/icons-material';

import {
  MIN_CONTRAST,
  MAX_CONTRAST,
  CONTRAST_STEP,
} from 'components/IFViewer/constants';
import { useIfSlideBrightness } from 'components/IFViewer/helpers/hooks';
import { useBrightnessSliderStyles } from 'components/IFViewer/styles';
import { Spinner } from 'components/Loading/Spinner/Spinner';

export const BrightnessSlider = ({ handleChange, value, id }) => {
  const { brightness, loading, handleSave } = useIfSlideBrightness(id, value);
  const { classes } = useBrightnessSliderStyles();
  const { wrapper, button } = classes;

  useEffect(() => {
    if (!loading && brightness) {
      handleChange(brightness);
    }
  }, [loading, brightness]);

  return (
    <>
      Brightness
      <div className={wrapper}>
        <Slider
          disabled={loading}
          value={value}
          valueLabelDisplay="auto"
          onChange={({ target }) => handleChange(target.value)}
          size="small"
          min={MIN_CONTRAST}
          max={MAX_CONTRAST}
          step={CONTRAST_STEP}
        />
        {loading ? (
          <Spinner size={34} />
        ) : (
          <Tooltip
            title={`Save current brightness setting for this slide. Current brightness: ${value}`}
          >
            <Button onClick={handleSave} className={button}>
              <Save />
            </Button>
          </Tooltip>
        )}
      </div>
    </>
  );
};
