import { PathologyMapCategoriesHeader } from 'components/PathologyMap/PathologyMapCategories/PathologyMapCategoriesHeader';
import { PathologyMapCategoriesItems } from 'components/PathologyMap/PathologyMapCategories/PathologyMapCategoriesItems';
import { PathologyMapSectionWrapper } from 'components/PathologyMap/Shared/PathologyMapSectionWrapper';

export const PathologyMapCategoriesSection = ({
  presetIndex,
  setPresetIndex,
}) => {
  return (
    <PathologyMapSectionWrapper>
      <PathologyMapCategoriesHeader
        presetIndex={presetIndex}
        setPresetIndex={setPresetIndex}
      />
      <PathologyMapCategoriesItems presetIndex={presetIndex} />
    </PathologyMapSectionWrapper>
  );
};
