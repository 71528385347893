import React from 'react';
import { SuggestFeature } from 'components/Layout/Sidebar/Lists/SuggestFeature';
import {
  AdminList,
  MainList,
  NewOrderList,
  PathologistList,
} from 'components/Layout/Sidebar/Lists';
import { useSidebarStyles } from 'components/Layout/Sidebar/styles';
import { ContactUsList } from 'components/Layout/Sidebar/Lists/ContactUsList';

export const Sidebar = ({ isCollapsed }) => {
  const { classes } = useSidebarStyles();
  const { sidebar, sidebarCollapsed } = classes;

  return (
    <aside className={isCollapsed ? sidebarCollapsed : sidebar}>
      <NewOrderList />
      <MainList />
      <PathologistList />
      <AdminList />
      <ContactUsList />
      <SuggestFeature />
    </aside>
  );
};
