import React from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

import { chart2PDF } from 'components/OrderSlidesChart/helpers/utils';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useOrderSlidesChartHeaderStyles } from './styles';

export const OrderSlidesChartHeader = ({
  chart,
  orderName,
  chartType,
  sources,
  selectedSource,
  handleSource,
}) => {
  const { classes } = useOrderSlidesChartHeaderStyles();
  const { header, source } = classes;
  const { showSuccess } = useSnackbar();

  const handleCopy = () =>
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => showSuccess('Link copied to clipboard'));

  const onSourceChange = ({ target }) =>
    handleSource(
      sources.find(({ uuid }) => uuid === target.value),
      true,
    );

  return (
    <Box className={header}>
      <Button onClick={handleCopy} variant="contained" color="secondary">
        Copy chart link
      </Button>
      <Button
        variant="contained"
        color="secondary"
        disabled={!chart || !orderName || !chartType}
        onClick={() => chart2PDF(chart, orderName, chartType)}
      >
        Export to pdf
      </Button>
      <Box className={source}>
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="selected-source">Selected Source</InputLabel>
          <Select
            labelId="selected-source"
            value={selectedSource.uuid}
            label="Selected Source"
            onChange={onSourceChange}
            variant="filled"
            color="primary"
          >
            {sources.map(({ uuid, name }) => (
              <MenuItem key={uuid} value={uuid}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
