import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ArrowRightAlt as RightIcon } from 'images/icons/arrowRightAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { Card } from 'components/TeamOverview/components/Common/Card/Card';
import { consultationServices } from 'components/UserProfile/constants';
import { useOrdersTabContentStyles } from 'components/UserProfile/styles';
import { ServiceCard } from 'components/UserProfile/Upload/ServiceCard';
import { DownloadIcon } from 'images/icons/DownloadIcon';
import React, { useMemo, useState } from 'react';
import { useGetSlidesByOrderQuery } from 'store/apis/slidesApi';

export const ServicesMenu = ({ orderUUID }) => {
  const { classes } = useOrdersTabContentStyles();
  const [activeService, setActiveService] = useState(null);
  const { data } = useGetSlidesByOrderQuery({ orderUUID });

  const uploadedSlides = useMemo(() => {
    if (!data) return [];

    return data.filter((slide) => slide.uploaded_from === 'external');
  }, [data]);

  const uploadedCount = uploadedSlides.length;
  const convertedCount = uploadedSlides.filter(
    (slide) => slide.state === 'converted',
  ).length;

  const isDisabled = uploadedCount === 0 || convertedCount < uploadedCount;

  return (
    <Card
      header={
        <Typography className={classes.servicesTitle}>
          Menu of services
        </Typography>
      }
      cardClass={classes.servicesContainer}
    >
      <Typography className={classes.proceedNote}>
        {isDisabled ? (
          <>
            <ErrorIcon color="error" fontSize="small" />
            Slides are still uploading. Please wait until the upload is complete
            before selecting a service.
          </>
        ) : (
          <>
            <CheckCircleIcon color="success" fontSize="small" />
            The slides have finished uploading. You can now proceed with
            selecting a service.
          </>
        )}
      </Typography>
      <Box>
        <Accordion disableGutters elevation={0} square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
          >
            Consultation
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Box className={classes.services}>
              {consultationServices.slice(0, 1).map((service) => (
                <ServiceCard
                  key={service.name}
                  service={service}
                  activeService={activeService}
                  setActiveService={setActiveService}
                  isDisabled={isDisabled}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion disableGutters elevation={0} square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
          >
            AI Tools
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Box className={classes.services}>
              {modelsServices(uploadedSlides).map((service) => (
                <ServiceCard
                  key={service.name}
                  service={service}
                  activeService={activeService}
                  setActiveService={setActiveService}
                  isDisabled={isDisabled}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion> */}
      </Box>
      <Box className={classes.proceedContainer}>
        {activeService && isDisabled && (
          <Typography className={classes.errorText}>
            Error: Please wait until the slide upload is complete.
          </Typography>
        )}
        <Box className={classes.uploadedContainer}>
          <DownloadIcon />
          <Typography className={classes.uploadedText}>
            Uploaded files
          </Typography>
          <Typography className={classes.uploadedText}>
            {convertedCount}/{uploadedCount}
          </Typography>
        </Box>
        {activeService && !isDisabled && (
          <Button
            endIcon={<RightIcon />}
            variant="contained"
            className={classes.proceedButton}
            onClick={activeService?.onClick}
          >
            <span className={classes.proceedText}>
              {activeService?.buttonText || 'Proceed'}
            </span>
          </Button>
        )}
      </Box>
    </Card>
  );
};
