import { makeStyles } from 'tss-react/mui';
import { PRIMARY_WHITE } from 'utilities/colors';

export const useAddressInputWithAutofillingStyles = makeStyles()(() => ({
  suggestionsDropdown: {
    position: 'absolute',
    marginTop: '0.5rem',
    'z-index': 10,
    backgroundColor: 'white',
    border: '1px solid',
    borderRadius: '0.25rem',
  },
  suggestion: {
    height: '2rem',
    padding: '0.25rem 1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: PRIMARY_WHITE,
    },
  },
  address: {
    fontSize: '1rem',
  },
}));
