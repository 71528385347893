import { BetaIcon } from 'components/Shared/Icons/BetaIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import React from 'react';

export const TeamOverviewBetaIcon = () => {
  return (
    <BetaIcon>
      <AccountCircleIcon />
    </BetaIcon>
  );
};
