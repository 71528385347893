import { Box } from '@mui/material';
import { THUMBNAIL_SWITCH_FIELD } from 'components/ImageViewer/constants';
import { MiniDrawerWithContext } from 'components/Layout/drawer';
import { useSlidesGrid } from 'components/OrderSlidesList/hooks/useSlidesGrid';
import { RenderAdjustableGridOrderSlideList } from 'components/OrderSlidesList/OrderSlidesListViewAdjustableGrid';
import { useSlidesListViewV2Styles } from 'components/OrderSlidesList/OrderSlidesListViewV2Styles';
import { ResizeableThumbnail } from 'components/ThumbnailQRCode/ResizeableThumbnail';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetSlidesByUUIDsQuery } from 'store/apis/orderApi';
import {
  clearSlidesList,
  setForceOpenModel,
  setGroup,
  setIsSlidesListLoading,
  setSlidesListOrder,
} from 'store/slices/slidesListSlice/slidesListSlice';
import { setSlides } from 'store/slices/slidesSlice';
import { currentUserPreferencesSelector } from 'store/slices/userDetailsSlice';

export const CustomSlidesListView = () => {
  const [getSlides] = useLazyGetSlidesByUUIDsQuery();
  const preferences = useSelector(currentUserPreferencesSelector);
  const { classes } = useSlidesListViewV2Styles();
  const dispatch = useDispatch();
  const {
    selectedRows,
    annotationsCount,
    setAnnotationsCount,
    slide,
    slidesTableProps,
    slidesGroupProps,
    slideCommentsProps,
    slidesViewerProps,
    handleNextSlide,
    handlePreviousSlide,
  } = useSlidesGrid();

  useEffect(() => {
    const customGroupUUIDs = localStorage.getItem('customGroupUUIDs');

    if (!customGroupUUIDs) {
      localStorage.setItem('customGroupUUIDs', JSON.stringify([]));

      return null;
    }

    const openModel = localStorage.getItem('customGroupOpenModel');

    if (openModel) {
      localStorage.removeItem('customGroupOpenModel');
      dispatch(setForceOpenModel(openModel));
    }

    const slideUUIDs = JSON.parse(customGroupUUIDs);

    dispatch(setIsSlidesListLoading(true));

    getSlides(slideUUIDs).then((res) => {
      if (res.data) {
        dispatch(setGroup({ custom: true }));
        dispatch(setIsSlidesListLoading(false));
        dispatch(setSlides(res.data));
        dispatch(setSlidesListOrder(res.data[0].order));
      }
    });

    return () => {
      dispatch(clearSlidesList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MiniDrawerWithContext header="Untitled Group" withoutBackground>
      <Box className={classes.root}>
        <RenderAdjustableGridOrderSlideList
          showFavoriteIcon={selectedRows.length === 1}
          annotationsCount={annotationsCount}
          setAnnotationsCount={setAnnotationsCount}
          slidesGroupProps={slidesGroupProps}
          slidesViewerProps={slidesViewerProps}
          slideCommentsProps={slideCommentsProps}
          slidesTableProps={{ ...slidesTableProps, isCustomGroup: true }}
          currentSlide={slide}
          handlePreviousSlide={handlePreviousSlide}
          handleNextSlide={handleNextSlide}
        />
        <ResizeableThumbnail
          isVisible={preferences[THUMBNAIL_SWITCH_FIELD]}
          thumbnail={slide?.large_macro_url}
        />
      </Box>
    </MiniDrawerWithContext>
  );
};
