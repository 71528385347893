import { Button } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';

export const AiToolsButton = ({ onClick, disabled, className }) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant="contained"
      startIcon={<SmartToyIcon />}
      disabled={disabled}
    >
      AI TOOLS
    </Button>
  );
};
