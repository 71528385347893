import { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { getSlideMeta } from 'components/OrderSlidesList/SlideActionTabs/constants';
import { SlideMetadataRow } from 'components/OrderSlidesList/SlideActionTabs/SlideMetadata/SlideMetadataRow/SlideMetadataRow';
import { useStyles } from 'components/OrderSlidesList/SlideActionTabs/SlideMetadata/styles';
import { useSelector } from 'react-redux';
import { slidesListOrderSelector } from 'store/slices/slidesListSlice/slidesListSlice';
import _ from 'lodash';
import {
  buildSearchLinkWithProps,
  getSampleScienceInfo,
} from 'components/OrderSlidesList/utilities';
import { SlideMetadataHeader } from 'components/PathologyMap/Modals/FullSlideInfoModal/SlideInfoSection/SlideMetadataHeader';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const SlideMetadata = ({ slide }) => {
  const { classes } = useStyles();
  const order = useSelector(slidesListOrderSelector);
  const [currentTab, setCurrentTab] = useState(0);
  const [sampleData, setSampleData] = useState([]);

  const scienceInfo = slide?.sample?.science_info || {};
  const metaData = getSlideMeta(slide, order);
  const scienceInfoItems = getSampleScienceInfo(scienceInfo);

  useEffect(() => {
    if (!slide.sample || !currentTab || sampleData.length) return;

    !_.isEmpty(scienceInfo) && setSampleData(scienceInfoItems);
  }, [currentTab, slide.sample, sampleData]);

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleNavigateToSearchPage = () => {
    const pathologyMapSearchURL = buildSearchLinkWithProps(slide);

    window.open(pathologyMapSearchURL, '_blank');
  };

  const MetadataTable = ({ data }) => (
    <Box className={classes.root}>
      {data.map(({ label, value, keyTooltip, valueTooltip, link }) => (
        <SlideMetadataRow
          key={label}
          label={label}
          value={value}
          keyTooltip={keyTooltip}
          valueTooltip={valueTooltip}
          link={link}
        />
      ))}
    </Box>
  );

  return (
    <>
      {slide.sample ? (
        <>
          <SlideMetadataHeader
            slide={slide}
            currentTab={currentTab}
            handleTabsChange={handleTabsChange}
            standardMetadataItemsCount={metaData?.length}
            scienceInfoItemsCount={scienceInfoItems.length}
          />
          {!currentTab ? (
            <MetadataTable data={metaData} />
          ) : sampleData.length ? (
            <MetadataTable data={sampleData} />
          ) : (
            <Box className={classes.centered}>No science info available</Box>
          )}
        </>
      ) : (
        <MetadataTable data={metaData} />
      )}
      <Tooltip title="View Similar Slides in Search 2.0 (New Tab)">
        <Box
          className={classes.viewSimilarSlidesLinkBox}
          onClick={handleNavigateToSearchPage}
        >
          <Typography className={classes.viewSimilarSlidesLink}>
            View similar slides
          </Typography>
          <OpenInNewIcon className={classes.linkIcon} />
        </Box>
      </Tooltip>
    </>
  );
};
