import { CONTACT_US_URL } from 'components/Layout/Sidebar/constants';

export const buildContactUsLink = (userDetails, additionalParams = {}) => {
  const params = [
    {
      field: 'firstName',
      linkParameter: 'firstname',
    },
    {
      field: 'lastName',
      linkParameter: 'lastname',
    },
    {
      field: 'email',
      linkParameter: 'email',
    },
    {
      field: 'phoneNumber',
      linkParameter: 'phone',
    },
  ];

  const extendedUserDetails = { ...userDetails, ...additionalParams };

  for (const [key] of Object.entries(additionalParams)) {
    params.push({
      field: key,
      linkParameter: key,
    });
  }

  let finalLink = CONTACT_US_URL;

  params.forEach((param) => {
    const key = param.linkParameter;
    const value = extendedUserDetails[param.field];
    finalLink = finalLink + `&${key}=${value}`;
  });

  return finalLink;
};
