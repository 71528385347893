export const ALL_TAB = 'all';
export const COMPLETED_TAB = 'completed';
export const IN_PROGRESS_TAB = 'in_progress';

export const AI_COMPUTE_TRANSACTIONS_TABS_LIST = [
  {
    value: ALL_TAB,
    label: 'all',
  },
  {
    value: COMPLETED_TAB,
    label: 'completed',
  },
  {
    value: IN_PROGRESS_TAB,
    label: 'in progress',
  },
];

export const REQUESTED_LOG_STATUS = 'requested';
export const PROCESSING_LOG_STATUS = 'processing';
export const RESPONSE_FAILED_LOG_STATUS = 'response_failed';
export const REQUEST_FAILED_LOG_STATUS = 'request_failed';
export const COMPLETED_LOG_STATUS = 'completed';
export const PARTLY_COMPLETED_LOG_STATUS = 'partly_completed';

export const IN_PROGRESS_STATUSES = [
  REQUESTED_LOG_STATUS,
  PROCESSING_LOG_STATUS,
];
export const COMPLETED_STATUSES = [
  COMPLETED_LOG_STATUS,
  PARTLY_COMPLETED_LOG_STATUS,
];
