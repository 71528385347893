import React, { useEffect, useState } from 'react';
import { ToolbarIcon } from './utilities';
import {
  DEFAULT_VIEWER_ANGLE,
  ROTATE_DIRECTIONS,
  SLIDE_VIEWER_ICON_ROTATE_LEFT_TOOLTIP,
  SLIDE_VIEWER_ICON_ROTATE_RIGHT_TOOLTIP,
  SLIDE_VIEWER_SET_TO_DEFAULT,
} from 'components/ImageViewer/constants';
import { Loop } from '@mui/icons-material';
import { useSideRotation } from 'components/ImageViewer/hooks';

const RotateIcon = ({ id, direction, viewer, title = '', isFullScreen }) => {
  const rotateClick = useSideRotation(viewer, direction, id);

  const toolBarParams = {
    id: `rotate-${direction.name}-${id}`,
    className: direction.className,
    title: title,
  };

  return (
    <ToolbarIcon
      {...toolBarParams}
      {...rotateClick}
      isFullScreen={isFullScreen}
      isHighlighted={false}
    />
  );
};

export const SlideRotationTools = ({ id, viewer, isFullScreen }) => {
  const [currentRotation, setCurrentRotation] = useState(DEFAULT_VIEWER_ANGLE);
  const defaultPositionId = `default-position-${id}`;
  const setSlideDefaultPosition = () => {
    viewer.viewport.goHome();
    viewer.viewport.setRotation(DEFAULT_VIEWER_ANGLE);
  };

  useEffect(() => {
    if (!viewer) return;

    const updateRotation = (e) => setCurrentRotation(e.degrees);

    viewer.addHandler('rotate', updateRotation);

    return () => {
      viewer.removeHandler('rotate', updateRotation);
    };
  }, [viewer]);

  const isDefaultAngle = currentRotation === DEFAULT_VIEWER_ANGLE;

  return (
    <>
      <RotateIcon
        direction={ROTATE_DIRECTIONS.LEFT}
        id={id}
        viewer={viewer}
        title={SLIDE_VIEWER_ICON_ROTATE_LEFT_TOOLTIP}
        isFullScreen={isFullScreen}
      />
      <RotateIcon
        direction={ROTATE_DIRECTIONS.RIGHT}
        id={id}
        viewer={viewer}
        title={SLIDE_VIEWER_ICON_ROTATE_RIGHT_TOOLTIP}
        isFullScreen={isFullScreen}
      />
      <ToolbarIcon
        id={defaultPositionId}
        isSeparate
        className={'fa fa-circle'}
        icon={<Loop />}
        title={SLIDE_VIEWER_SET_TO_DEFAULT}
        isHighlighted={!isDefaultAngle}
        onMouseDown={setSlideDefaultPosition}
        isFullScreen={isFullScreen}
      />
    </>
  );
};
