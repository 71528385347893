import {
  PATHOLOGY_MAP_COUNTER_CONTAINER_BG_COLOR,
  PATHOLOGY_MAP_SECTION_HEADER_BG_COLOR,
  PATHOLOGY_MAP_SECTION_BG_COLOR,
  PATHOLOGY_MAP_TRANSPARENT_BLACK,
} from 'components/PathologyMap/constants/styles';
import { makeStyles } from 'tss-react/mui';

export const usePathologyMapResultsCounterStyles = makeStyles()(() => ({
  counterContainer: {
    borderRadius: '2.5px',
    background: PATHOLOGY_MAP_COUNTER_CONTAINER_BG_COLOR,
    padding: '0 0.6rem',
    width: 'fit-content',
  },
}));

export const usePathologyMapSectionHeaderWrapperStyles = makeStyles()(() => ({
  header: {
    position: 'relative',
    borderRadius: '3px',
    padding: '0.5rem 1rem 0.5rem 3rem',
    border: '1.5px solid rgba(108, 162, 187, 0.2)',
    backdropFilter: 'blur(2.5px)',
    overflow: 'hidden', // Prevent the pseudo-element from overflowing the border-radius
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      background: PATHOLOGY_MAP_SECTION_HEADER_BG_COLOR,
      zIndex: -1,
    },
  },
}));

export const usePathologyMapSectionWrapperStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    background: PATHOLOGY_MAP_SECTION_BG_COLOR,
    border: `1px solid ${PATHOLOGY_MAP_TRANSPARENT_BLACK}`,
    borderRadius: '3px',
    boxShadow: `0px 8px 20px 15px ${PATHOLOGY_MAP_TRANSPARENT_BLACK}`,
    width: '100%',
    height: '600px',
  },
  verticallyAdjustableContainer: {
    height: 'max-content !important',
    minHeight: '200px',
    maxHeight: '600px',

    [theme.breakpoints.up('laptop')]: {
      height: '600px !important',
    },
  },
}));
