import { fabric } from 'fabric';
import {
  DEFAULT_FABRIC_POINT_COLOR,
  DEFAULT_FABRIC_POINT_SIZE,
  DEFAULT_SHADOW,
} from 'components/ImageViewer/fabric/constants';

export const createFabricPoint = (
  x,
  y,
  size = DEFAULT_FABRIC_POINT_SIZE,
  color = DEFAULT_FABRIC_POINT_COLOR,
) => {
  const point = new fabric.Circle({
    radius: size,
    fill: color,
    left: x,
    top: y,
    originX: 'center',
    originY: 'center',
    shadow: DEFAULT_SHADOW,
  });

  return point;
};
