import useClipboard from 'react-use-clipboard';

import { getItemSharedUrl } from 'components/Share/helpers/functions';
import { useSnackbar } from 'utilities/hooks/useSnackbar/useSnackbar';
import { useMakeSlideShareableMutation } from 'store/apis/slidesApi';
import { GROUP_NAME_FOR_SHARE, SLIDE_NAME_FOR_SHARE } from './constants';
import {
  makeGroupShareable,
  makeOrderShareable,
} from 'services/resources/orders';

export const useShareError = (itemName) => {
  const { showError } = useSnackbar();

  return () =>
    showError(`An Error occurred during making ${itemName} shareable!`);
};

export const useCopyIntoClipboard = (content, successMessage) => {
  const { showSuccess } = useSnackbar();

  const [, setCopied] = useClipboard(content, { successDuration: 2000 });

  const handleCopy = () => {
    setCopied();
    showSuccess(successMessage);
  };

  return handleCopy;
};

export const useShareClipboard = (item, itemName) => {
  const { showSuccess } = useSnackbar();
  const { share_uuid } = item ?? {};

  const url = getItemSharedUrl(itemName, share_uuid);

  const currentUrl = window.location.href;
  const currentHash = window.location.hash;

  const completeUrl = currentUrl.includes('if_viewer')
    ? `${url}${currentHash}`
    : url;

  const [, setCopied] = useClipboard(completeUrl, { successDuration: 2000 });

  const handleCopy = () => {
    setCopied();
    showSuccess(`${itemName} Share Link Copied!`);
  };

  return handleCopy;
};

export const useSlideLinkClipboard = (iframeSrc) => {
  const { showSuccess } = useSnackbar();
  const [, setCopied] = useClipboard(iframeSrc, { successDuration: 2000 });

  const handleCopy = () => {
    setCopied();
    showSuccess(`Embed Code Copied!`);
  };

  return handleCopy;
};

export const useGetItemShareRequest = (isShareable, id, itemName) => {
  const [makeSlideShareable] = useMakeSlideShareableMutation();

  switch (itemName) {
    case SLIDE_NAME_FOR_SHARE:
      return () => makeSlideShareable({ slideUUID: id, isShareable });
    case GROUP_NAME_FOR_SHARE:
      return () => makeGroupShareable({ groupUUID: id, isShareable });
    default:
      return () => makeOrderShareable({ orderUUID: id, isShareable });
  }
};
