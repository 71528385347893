import { useTableStyles } from 'components/OrdersList/OrderTables';
import { Paper, Table, TableBody } from '@mui/material';
import { ConsultationTableRow } from 'components/PathologyConsultations/PathologyConsultationsTableRow';
import React from 'react';

export const PathologyConsultationTable = ({ consultations, tableHeader }) => {
  const { classes } = useTableStyles();

  return (
    <Paper className={classes.paper}>
      <Table>
        {tableHeader}
        <TableBody>
          {consultations.map((row) => {
            return <ConsultationTableRow row={row} key={row.uuid} />;
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};
