import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSlideViewerActionsStyles } from 'components/ImageViewer/SlideViewerHeader/styles';

export const ActionsMenuBlock = memo(({ actions, title, Icon }) => {
  const { classes } = useSlideViewerActionsStyles();

  const showMenu = actions.some(({ condition = true }) => !!condition);

  if (!showMenu) return null;

  return (
    <Box className={classes.menuBlock}>
      <Box className={classes.menuTitle}>
        <Icon className={classes.menuIcon} />
        <Typography className={classes.menuLabel}>{title}</Typography>
      </Box>
      {actions.map(
        ({ onClick, name, condition = true, Icon: RowIcon, onChange }) =>
          !!condition && (
            <Box
              className={classes.menuRow}
              onClick={!onChange ? onClick : undefined}
              key={name}
            >
              {RowIcon && <RowIcon className={classes.menuRowIcon} />}
              <Typography className={classes.menuRowLabel}>{name}</Typography>
            </Box>
          ),
      )}
    </Box>
  );
});
