import { parseParamsForIFFromUrl } from 'components/OrderSlidesList/utilities';
import html2canvas from 'html2canvas';
import { VIEWER_BACKGROUND_COLOR } from 'components/IFViewer/styles';
import { getCurrentDateFormatted } from 'utilities/dates_and_times';
import FileSaver from 'file-saver';
import { ERROR_MAKING_SCREENSHOT } from 'components/IFViewer/constants';

export const areLayersLoaded = (deckRef) =>
  deckRef?.current?.deck.props.layers
    .flatMap((lay) => lay)
    .every(({ isLoaded }) => isLoaded);

export const isOverlayLayerExist = (deckRef) =>
  !!deckRef?.current?.deck.props.layers
    .flatMap((lay) => lay)
    .find((layer) => layer.id === '-#overview#');

export const getAvaiableColorsMessage = (colors) => {
  if (!colors.length) {
    return '';
  }

  const isMultiple = colors.length > 1;

  return `${colors.join(', ')} ${
    isMultiple ? 'are' : 'is'
  } not visible due to the channel limit. You can select ${
    isMultiple ? 'them' : 'it'
  } in any of the visible channels.`;
};

export const updateHashParams = () => {
  const urlParams = parseParamsForIFFromUrl();

  const newHashValues = Object.entries(urlParams).reduce(
    (prev, [key, value]) => {
      if (key === 'zoom' || key === 'x' || key === 'y') return prev;
      return `${prev}&${key}=${value}`;
    },
    '',
  );

  return newHashValues;
};

export const makeAndDownloadScreenshot = (
  deckRef,
  setAreLayersLoading,
  order,
  selectedSlide,
  setIsDownloadLoading,
  showError,
) => {
  const deck = deckRef.current.deck;

  setAreLayersLoading(false);

  setIsDownloadLoading(true);
  deck.redraw(true);
  const canvasWrapper = document.getElementById('deckgl-wrapper');

  html2canvas(canvasWrapper, {
    onclone: (clone) => {
      const clonedWrapper = clone.getElementById('deckgl-wrapper');
      clonedWrapper.style.backgroundColor = VIEWER_BACKGROUND_COLOR;
    },
  })
    .then((canvas) => {
      canvas.toBlob((blob) => {
        const currentDateFormatted = getCurrentDateFormatted();
        const slideName = `${order.name}_${selectedSlide.name.replace(
          '.qptiff',
          '',
        )}`.replace('Order ', '$');
        const fileName = `${currentDateFormatted}_${slideName}.png`;

        FileSaver.saveAs(blob, fileName);
      });
    })
    .catch(() => {
      showError(ERROR_MAKING_SCREENSHOT);
    })
    .finally(() => {
      setIsDownloadLoading(false);
    });
};
