import { useEffect, useRef } from 'react';

export const useUnmountIgnore = () => {
  const ignore = useRef(false);

  useEffect(() => {
    return () => {
      ignore.current = true;
    };
  }, []);

  return ignore;
};
