import {
  DefaultTabWrapper,
  RenderSlideMacro,
} from 'components/OrderDetails/Tabs/utilities';
import { Grid, Switch, Typography } from '@mui/material';
import { OrderDetailsSlidesListView } from 'components/OrderDetails/OrderDetailsSlidesListView';
import { inlineBlockStyle } from 'components/OrderDetails/orderDetailsSlidesStyles';
import React from 'react';
import { useProcessedSlideStyles } from 'components/OrderDetails/styles';
import { NotUploadedSlidesExpansionGrid } from 'components/OrderDetails/NotUploadedSlidesGrid';
import Box from '@mui/material/Box';
import { ShowStaffUserOnly } from 'components/ConditionalWrappers/IsStaffWrapper/ShowStaffUserOnly';
import { UploadHaloAnnotationsSection } from 'components/OrderDetails/UploadHaloAnnotationsSection';
import { nameSorter } from 'utilities/general';

export const ProcessedSlidesTab = (props) => {
  const {
    slides = [],
    isSlidesLoading,
    showFailedQCSlides,
    order,
    quickFilterText,
    onQuickFilterText,
    handleToggleShowFailedQCSlides,
    notUploadedSlides,
  } = props;

  const { classes } = useProcessedSlideStyles();

  // non-admin users won't have as many fields though
  let slidesToShow = slides;

  if (!showFailedQCSlides) {
    slidesToShow = slides.filter((slide) => {
      return !slide.failed_qc_datetime;
    });
  }

  slidesToShow = slidesToShow.filter((slide) => {
    return slide.is_public;
  });

  slidesToShow.sort(nameSorter);

  return (
    <DefaultTabWrapper>
      <Grid item xs={12}>
        <ShowStaffUserOnly>
          <NotUploadedSlidesExpansionGrid
            notUploadedSlides={notUploadedSlides}
          />
          <UploadHaloAnnotationsSection />
        </ShowStaffUserOnly>
        <Box className={classes.slideView}>
          <Typography variant={'h5'}>Slides</Typography>
        </Box>
        <Box mb={11}>
          <OrderDetailsSlidesListView
            order={order}
            quickFilterText={quickFilterText}
            onQuickFilterText={onQuickFilterText}
            isSlidesLoading={isSlidesLoading}
            rowData={slides}
          />
        </Box>

        <Box mt={1}>
          <Typography variant={'h5'}>Slide Thumbnails</Typography>
        </Box>

        <Box>
          <Switch
            checked={showFailedQCSlides}
            onChange={handleToggleShowFailedQCSlides}
            value="showFailedQCSlides"
          />
          <Typography variant={'body1'} style={inlineBlockStyle}>
            Show Failed QC Slides
          </Typography>
        </Box>
        <input
          className={classes.formInput}
          type="text"
          value={quickFilterText}
          onChange={onQuickFilterText}
          placeholder="Slide Name Filter ..."
        />
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          {slidesToShow.map((slide) => {
            return (
              <RenderSlideMacro
                slide={slide}
                key={slide.uuid}
                quickFilterText={quickFilterText}
              />
            );
          })}
        </Grid>
      </Grid>
    </DefaultTabWrapper>
  );
};
